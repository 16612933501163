// Panels
export const CMD_PANEL_CLEAR = 'canvas-clear';
export const CMD_PANEL_CORE_REDO = 'core:redo';
export const CMD_PANEL_CORE_UNDO = 'core:undo';
export const CMD_PANEL_DEVICE_DESKTOP = 'set-device-desktop';
export const CMD_PANEL_DEVICE_MOBILE = 'set-device-mobile';
export const CMD_PANEL_DEVICE_TABLET = 'set-device-tablet';
export const CMD_PANEL_IMPORT = 'gjs-open-import-webpage';
export const CMD_PANEL_OPEN_BLOCKS = 'open-blocks';
export const CMD_PANEL_OPEN_COMPONENT_ITEMS = 'open-component-items';
export const CMD_PANEL_OPEN_LAYERS = 'open-layers';
export const CMD_PANEL_OPEN_TRAITS = 'open-tm';
export const CMD_PANEL_OUTLINE_VISIBILITY = 'sw-visibility';
// export const PANEL_ openComponentItemsPanel = 'openComponentItemsPanel';
// export const PANEL_ openComponentItemsPanel = 'open-component-items-panel';
// export const PANEL_ osm = 'open-sm';
// export const PANEL_ createComponent = 'create-comp';
// export const PANEL_ exportTemplate = 'export-template';
// export const PANEL_ fullScreen = 'fullscreen';
// export const PANEL_ moveComponent = 'move-comp';
// export const PANEL_ preview = 'preview';





// Canvas / Item Toolbar
export const CMD_DEFAULT_ITEM_TOOLBAR = [{
    attributes: { class: 'fa fa-arrow-up' },
    command: 'select-parent'
}, {
    attributes: { class: 'fa fa-arrows' },
    command: 'tlb-move'
}, {
    attributes: { class: 'fa fa-clone' },
    command: 'tlb-clone',
}, {
    attributes: { class: 'fa fa-trash' },
    command: 'tlb-delete'
}];
export const CMD_CANVAS_ITEM_SCROLL_LEFT = 'canvas-item-scroll-left';
export const CMD_ITEM_TOOLBAR_SCROLL_LEFT = {
    attributes: { class: 'fa fa-arrow-left' },
    command: CMD_CANVAS_ITEM_SCROLL_LEFT
}
export const CMD_CANVAS_ITEM_SCROLL_RIGHT = 'canvas-item-scroll-right';
export const CMD_ITEM_TOOLBAR_SCROLL_RIGHT = {
    attributes: { class: 'fa fa-arrow-right' },
    command: CMD_CANVAS_ITEM_SCROLL_RIGHT
};

export const CMD_ITEM_TOOLBAR_SELECT_PARENT = {
    attributes: { class: 'fa fa-arrow-up' },
    command: 'select-parent'
}
export const CMD_ITEM_TOOLBAR_MOVE = {
    attributes: { class: 'fa fa-arrows' },
    command: 'tlb-move'
}

// Open modals;
export const CMD_OPEN_FILE_SELECTOR = 'openFileSelector';
export const CMD_OPEN_ICON_SELECTOR = 'openIconSelector';
export const CMD_OPEN_IMAGE_SELECTOR = 'openImageSelector';
export const CMD_OPEN_INLINE_PAGE_ITEM_SELECTOR = 'openInlinePageItemSelector';
export const CMD_OPEN_ITEM_PERMS_SELECTOR = 'openItemPermsSelector';
export const CMD_OPEN_PAGE_ITEM_SELECTOR = 'openPageItemPermsSelector';
export const CMD_OPEN_TEXT_EDITOR = 'openTextEditor';

// Update formgroup;
export const CMD_UPDATE_PAGE_FORM_ITEMS = 'updatePageFormItems';
export const CMD_REMOVE_FROM_PAGE_FORM_ITEMS = 'removeFromPageFormItems';

// Update perms;
export const CMD_UPDATE_PAGE_FORM_ITEM_PERMS = 'updatePerms';
export const CMD_REMOVE_FROM_PAGE_ITEM_PERMS = 'removeFromPerms';

// Snapshot;
export const CMD_GET_ITEM_SNAPSHOT = 'getItemSnapshot';
export const CMD_UPDATE_ITEMS_SNAPSHOTS = 'updateItemsSnapshot';

// Page sections
export const CMD_UPDATE_PAGE_SECTIONS = 'updatePageSections';
export const CMD_REMOVE_FROM_PAGE_SECTIONS = 'removeFromPageSections';
