export const CUSTOM_FIELD_FILTER_FIELDS = [
  {
    type: 'text',
    name: 'name',
    label: 'Name',
    value: ''
  },
  {
    type: 'dropdown',
    name: 'type',
    label: 'Type',
    value: '',
    options: [
      { value: 'group', text: 'Group'},
      { value: 'string', text: 'String'},
      { value: 'text', text: 'Text'},
      { value: 'integer', text: 'Integer'},
      { value: 'numeric', text: 'Numeric'},
      { value: 'url', text: 'Url'},
      { value: 'email', text: 'Email'},
      { value: 'date', text: 'Date'},
      { value: 'datetime', text: 'Datetime'},
    ]
  }
];
