import { AfterViewInit, Component, ContentChildren, Input, OnInit, QueryList, TemplateRef } from '@angular/core';
import { State } from '@app/state';
import { TabPanelComponent } from '@elements/tabs/panel/tab-panel.component';
@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit, AfterViewInit {
  @ContentChildren(TabPanelComponent) tabPanelsList: QueryList<TabPanelComponent>;
  @ContentChildren(TemplateRef) templatesList: QueryList<TemplateRef<any>>;
  @Input() tabs = '';

  private tabPanels: Array<any> = [];
  private templates: Array<any> = [];

  constructor(public state: State) { }

  public parsedTabs: Array<object> = [];
  public tabIndex = 1;

  ngOnInit() {
    this.parsedTabs = JSON.parse(this.tabs);
  }

  ngAfterViewInit() {
    this.templatesList.forEach(template => this.templates.push(template));
    this.tabPanelsList.forEach(tabPanel => this.tabPanels.push(tabPanel));
    setTimeout(() => {
      this.tabPanels[0].insertIntoTab(this.templates[0]);
    });
  }

  setActiveTab(index) {
    this.tabIndex = index + 1;
    this.tabPanels[index].insertIntoTab(this.templates[index]);
  }
}
