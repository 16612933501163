import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { MatTableDataSource } from '@angular/material';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { of as observableOf } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { FolderNode } from '@app/models/view-models/folder';
import { DocumentService } from '@app/services/document.service';
import { FolderService } from '@app/services/folder.service';

@Component({
  selector: 'single-document-move',
  templateUrl: './single-document-move.component.html',
  styleUrls: ['./single-document-move.component.scss'],
  providers: [SidebarService],
})
export class SingleDocumentMoveComponent extends BaseComponent implements OnInit {
  // Local variables
  public documentFormGroup: FormGroup;
  public dataLoaded = false;
  public dsDocument: any = [];
  public treeControl: FlatTreeControl<any>;
  public treeFlattener: MatTreeFlattener<any, any>;
  public matTreeDataSource: MatTreeFlatDataSource<any, any>;
  public title = '';
  private id = '';
  // Input parameters
  // Onput events
  // Contractor
  constructor(
    public activatedRoute: ActivatedRoute,
    public documentService: DocumentService,
    public folderService: FolderService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: folderService,
    });
    this.setPageVars({
      sidebar: 'folders',
    });
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<any>(this.getLevel, this.isExpandable);
    this.matTreeDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  ngOnInit(): void {
    this.id = this.routeParams['id'];
    this.initialFormGroup();
    if (window.history !== undefined) {
      this.title = window.history.state.name;
    }
    this.modelService.getAll().subscribe((result) => {
      if (result.data !== undefined) {
        const row = result.data.find((r) => r.uuid === this.id);
        if (row !== undefined) {
          this.dsDocument = new MatTableDataSource<any>(row.documents);
          this.documentFormGroup.patchValue(row);
        }
        this.dataLoaded = true;
        // exclude the current selected folder row from the target list.
        this.matTreeDataSource.data = this.excludeCurrentRow(result.data, this.id) as any;
      }
    });
  }

  get controls(): any {
    return this.documentFormGroup.controls;
  }

  public save(): void {
    this.documentService.patchById(this.id, this.documentFormGroup.value).subscribe((result) => this.SavedMessage(result));
  }

  public cancel(): void {
    this.documentFormGroup.reset();
  }

  public transformer(node: any, level: number) {
    return {
      name: node.name,
      level: level,
      expandable: !!node.sub_folders,
      uuid: node.uuid,
    };
  }

  public getLevel(node: FolderNode) {
    return node.level;
  }

  public isExpandable(node: FolderNode) {
    return node.expandable;
  }

  public getChildren(node: FolderNode) {
    return observableOf(node.sub_folders);
  }

  public hasChild(index: number, node: FolderNode) {
    return node.expandable;
  }

  public selectedTargetFolder(event): void {
    this.documentFormGroup.patchValue({
      folders: [event.uuid],
    });
  }

  private SavedMessage(data): void {
    // Use this method to validate the result from server after our request.
    this.state.message({ ['FolderIndexUpdate']: true });
    this.closeSlideout();
  }

  // All the private methods (Private method should be after all the public methods)
  public closeSlideout(): void {
    this.state.message({ slideoutHide: true });
  }

  private initialFormGroup(): void {
    // setup the initial form for a document entity
    this.documentFormGroup = this.formBuilder.group({
      uuid: [this.id],
      id: [null],
      folders: [null],
    });
  }

  private excludeCurrentRow(data, uuid) {
    for (let i = 0; i <= data.length - 1; i++) {
      if (data[i].uuid === uuid) {
        data.splice(i, 1);
      }
    }
    return data;
  }
}
