// Layout
export const ICON_LAYOUT_ACCORDION = 'view_day';
export const ICON_LAYOUT_DIVIDER = 'power_input';
export const ICON_LAYOUT_PLACEHOLDER = 'crop_free';
export const ICON_LAYOUT_ROW = 'table_rows';
export const ICON_LAYOUT_PAGE_SECTION = 'filter_none';
export const ICON_LAYOUT_SECTION = 'layers';
// Text
export const ICON_TEXT_HEADING = 'text_fields';
export const ICON_TEXT_TEXT_BLOCK = 'format_align_justify';
// Media
export const ICON_MEDIA_IMAGE = 'insert_photo';
export const ICON_MEDIA_IMAGE_SLIDER = 'linear_scale';
export const ICON_MEDIA_VIDEO = 'ondemand_video';
export const ICON_MEDIA_VIDEO_PLAYLIST = 'video_library';
// Buttons
export const ICON_BUTTON_APP_COMPONENT = 'widgets';
export const ICON_BUTTON_APP_PAGE = 'dynamic_feed';
export const ICON_BUTTON_ARTICLE = 'post_add';
export const ICON_BUTTON_ARTICLE_CATEGORY = 'view_list';
export const ICON_BUTTON_BUNDLE = 'library_books';
export const ICON_BUTTON_CALENDAR = 'calendar_today';
export const ICON_BUTTON_CHAT = 'forum';
export const ICON_BUTTON_CONTACT_GROUP = 'group';
export const ICON_BUTTON_DIRECTORY_ITEM = 'contact_page';
export const ICON_BUTTON_DIRECTORY_CATEGORY = 'import_contacts';
export const ICON_BUTTON_DOCUMENT = 'description';
export const ICON_BUTTON_EVENT = 'event';
export const ICON_BUTTON_FILE = 'publish';
export const ICON_BUTTON_FOLDER = 'folder_open';
export const ICON_BUTTON_PERIODISATION_CALENDAR = 'date_range';
export const ICON_BUTTON_PLACEHOLDER = 'crop_free';
export const ICON_BUTTON_PROFILE_GROUP = 'groups';
export const ICON_BUTTON_URL = 'link';
export const ICON_BUTTON_PRODUCT_URL = 'account_balance';
export const ICON_BUTTON_USER_CIRCLE = 'panorama_fish_eye';
// Widget
export const ICON_WIDGET_EMBED = 'highlight_alt';
export const ICON_WIDGET_MAP = 'explore';
// Misc
export const ICON_MISC_EMAIL = 'alternate_email';
export const ICON_MISC_MAP = 'explore';
export const ICON_MISC_PHONE = 'phone';
export const ICON_MISC_URL = 'public';
export const ICON_PERMS = 'lock';