import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { MaintenanceService } from  '@services/maintenance.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
@Component({
  selector: 'app-maintenance-index',
  templateUrl: './maintenance-index.component.html',
  styleUrls: ['./maintenance-index.component.scss'],
})
export class MaintenanceIndexComponent extends BaseComponent implements OnInit
{
  public node: Breadcrumb;
  public log: string = '';
  public dataLoaded: boolean = false;

  constructor(
    public roleService: MaintenanceService,
    public router: Router,
    public state: State,
    public snackbarService: SnackBarService,
  ) {
    super();
    this.setObjects({
      modelService: roleService,
      router,
      state,
      snackbarService
    });
    // this.modelService.getLog().subscribe(result => {
    //   this.log = result.log;
    //   this._processLog();
    //   this.dataLoaded = true;
    // });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.node = {
      label: 'Maintenance',
      link: 'maintenance',
      title: 'Maintenance'
    };
    this.buildSettingsBreadcrumb(this.node);
  }

  migrateTwilioDeviceTokens(): void {
    this.modelService.migrateTwilioDeviceTokens()
      .subscribe(result => this.snackbarService.success({ message: `Tokens migrated` }));
  }

  resetAlldayEventEndTimes(): void {
    this.modelService.resetAlldayEventEndTimes()
      .subscribe(result => this.snackbarService.success({ message: `End dates corrected` }));
  }

  private _processLog(): void {
    const dates = this.log.match(/[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/ig);
    if (dates) {
      dates.forEach(date => this.log = (this.log as any).replaceAll(date, `<span class="green">${date}</span>`));
    }

    this.log = (this.log as any).replaceAll('ERROR: SQLSTATE', `<span class="red">ERROR: SQLSTATE</span>`);
    this.log = (this.log as any).replaceAll('.ERROR', `.<span class="red">ERROR</span>`);
  }
}
