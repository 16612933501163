import { Component, Injectable, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FileInfo } from '@app/models/entities/fileInfo';
import { DocumentService } from '@app/services/document.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
@Component({
  selector: 'document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.scss'],
  providers: [SidebarService]
})

@Injectable()
export class DocumentFormComponent extends BaseComponent {
  // Local variables
  documentFormGroup: FormGroup;
  tagCols: any;
  userCols: any;
  dataLoaded = false;
  formSubmitted = false;
  selectedTagIds = [];
  selectedUserIds = [];
  @ViewChild('users', {static: false}) private advancedSelectUsersInput;
  @ViewChild('tags', {static: false}) private advancedSelectTagsInput;
  constructor(
    public activatedRoute: ActivatedRoute,
    public documentService: DocumentService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: documentService
    });

    this.setPageVars({
      sidebar: 'documents',
    });


    this.initialFormGroup();
    // Once the form is initialed then patch the values if user is coming for edit mode. It should be strongly typed!!!
    const id = this.routeParams['id'];
    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result) => {
        this.populateInitialData(result);
        this.documentFormGroup.patchValue(result);
        this.documentFormGroup.patchValue({
          send_as_email: null,
          send_as_sms: null,
          send_as_push: null,
        });
        this.dataLoaded = true;
      });
    }

    // Setup the columns settings for the multi-selection table (Tags)
    this.tagCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` }
    ];

    // Setup the columns settings for the multi-selection table (Users)
    this.userCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` },
    ];
  }

  // // NG - life cycle hocks
  // ngOnInit(): void {

  // }

  get controls(): any {
    return this.documentFormGroup.controls;
  }

  public selectedIds(row): any {
    row.tags.forEach(el => {
      this.selectedTagIds.push(el.uuid);
    });

    row.users.forEach(el => {
      this.selectedUserIds.push(el);
    });
    return this.selectedUserIds;
  }

  public save(): void {
    this.formSubmitted = true;
    const id = this.controls.id.value;
    const uuid = this.controls.uuid.value;
    this.replaceBooleanFieldsWithByte();

    if (this.controls.public.value === '0') {
      this.documentFormGroup.patchValue({
        'users': this.getSelectedUsers(),
        'tags': this.getSelectedTags(),
      });
    } else {
      this.documentFormGroup.patchValue({
        'users': [],
        'tags': [],
        'user_circles': [],
        'send_as_email': 0,
        'send_as_push': 0,
        'send_as_sms': 0
      });
    }

    if (this.getParentFolders() !== null) {
      this.documentFormGroup.patchValue({
        'folders': this.getParentFolders()
      });
    }

    if (this.documentFormGroup.get('folders').value === null) {
      this.documentFormGroup.removeControl('folders');
    }

    if (id || uuid) {
      this.documentService.patchById(uuid ? uuid : id, this.documentFormGroup.value)
        .subscribe(result => this.SavedMessage(result));
    } else {
      this.documentService.create(this.documentFormGroup.value).subscribe(result => this.SavedMessage(result));
    }
    // Rollback the changes so all the checkboxes will bound correctly
    this.replaceByteFieldsWithBoolean();

    setTimeout (() => {
      this.formSubmitted = false;
    }, 2000);
  }

  public cancel(): void {
    this.documentFormGroup.reset();
  }

  public getUploadedFileInfo($fileInfo: FileInfo) {
    if ($fileInfo == null) {
      return;
    }
    this.documentFormGroup.patchValue({
      'file_family': $fileInfo.fileFamily,
      'file_name': $fileInfo.filename,
      'file_size': $fileInfo.filesize + '',
      'width': $fileInfo.width,
      'height': $fileInfo.height,
      'file_type': $fileInfo.mimeType,
      'file_extension': $fileInfo.filename.split('?')[0].split('.').pop(),
      'cdn_uuid': $fileInfo.uuid
    });
  }

  // All the private methods (Private method should be after all the public methods)
  // Replace all the boolean fields with byte
  private replaceBooleanFieldsWithByte(): void {
    this.documentFormGroup.patchValue({
      'send_as_email': this.controls.send_as_email.value ? '1' : '0',
      'send_as_push': this.controls.send_as_push.value ? '1' : '0',
      'send_as_sms': this.controls.send_as_sms.value ? '1' : '0',
      'public': this.controls.public.value ? '1' : '0'
      // 'notify_new_users_only': this.controls.notify_new_users_only.value ? '1' : '0'
    });
  }

  // Replace all the boolean fields with byte
  private replaceByteFieldsWithBoolean(): void {
    this.documentFormGroup.patchValue({
      'send_as_email': this.controls.send_as_email.value === '1' ? true : false,
      'send_as_push': this.controls.send_as_push.value === '1' ? true : false,
      'send_as_sms': this.controls.send_as_sms.value === '1' ? true : false,
      'public': this.controls.public.value === '1' ? true : false
      // 'notify_new_users_only': this.controls.notify_new_users_only.value === '1' ? true : false
    });
  }

  private populateInitialData(row): void {
    if (row !== undefined) {
      this.selectedIds(row);
    }
  }

  private initialFormGroup(): void {
    // setup the initial form for document entity
    this.documentFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      name: [null, [Validators.required]],
      file_family: [null, [Validators.required]],
      file_name: [null, [Validators.required]],
      file_size: [null, [Validators.required]],
      file_type: [null, [Validators.required]],
      file_extension: [null, [Validators.required]],
      width: [null],
      height: [null],
      cdn_uuid: [null, [Validators.required]],
      tags: [],
      users: [],
      user_circles: [[]],
      send_as_email: [null],
      send_as_sms: [null],
      send_as_push: [null],
      public: [null],
      // notify_new_users_only: [null],
      message: [null],
      folders: []
      // ucareupload_uuid: null
    });
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  private getSelectedUsers(): any[] {
    const value: any[] = this.advancedSelectUsersInput.getSelectedIds();
    return value === null ? null : value;
  }

  private getSelectedTags(): any[] {
    const value: any[] = this.advancedSelectTagsInput.getSelectedIds();
    return value === null ? null : value;
  }

  private SavedMessage(data) {
    // Use this method to validate the result from server after our request.
    this.state.message({
      ['DocumentIndexUpdate']: true,
      ['FolderIndexUpdate']: true
    });
    this.closeSlideout();
  }

  private getParentFolders() {
    const parent_uuid = this.activatedRoute.snapshot.paramMap.get('parent_uuid');
    if (parent_uuid !== null) {
      const value: any[] = [];
      value.push(parent_uuid);
      return value === null ? null : value;
    } else {
      return null;
    }
  }

}
