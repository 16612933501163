import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ColorEvent } from 'ngx-color';
@Component({
  selector: 'core-form-color-picker',
  templateUrl: './core-form-color-picker.component.html',
  styleUrls: ['./core-form-color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreFormColorPickerComponent),
      multi: true
    }
  ]
})

export class CoreFormColorPickerComponent implements ControlValueAccessor {
  // tslint:disable-next-line: no-input-rename
  @Input('value') val: any;
  constructor() {
  }

  handleChange($event: ColorEvent) {
  }

  handleChangeComplete($event: ColorEvent) {
    return this.value = $event.color.hex;
  }

  // Both onChange and onTouched are functions
  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }
  // We implement this method to keep a reference to the onChange
  // callback function passed by the forms API
  registerOnChange(fn) {
    this.onChange = fn;
  }
  // We implement this method to keep a reference to the onTouched
  // callback function passed by the forms API
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  // This is a basic setter that the forms API is going to use
  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }
}
