import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FIREBASE_REFERENCES } from '../../../firebase.module';
import { UserService } from '@services/user.service';
import * as firebase from 'firebase';
import { Environment } from '@environments/environment';

@Component({
  selector: 'app-add-chat-users',
  templateUrl: './add-chat-users.component.html',
  styleUrls: ['./add-chat-users.component.scss'],
})
export class AddChatUsersComponent implements OnInit
{
  public userGroups: any = [];
  public groupUsers: any = [];
  public productID: string;
  public groupChatID: string;
  public groupUuid: string;
  public fbPrefix: string = '';
  public filteredUserGroups: any = null;

  @ViewChild('searchUsers', { static: true }) private searchUsersInput;

  constructor(
    public userService: UserService,
    @Inject(FIREBASE_REFERENCES.TWO_FIRESTORE) private readonly chat: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.userGroups = this.data.userGroups;
    this.productID = this.data.productID;
    this.groupChatID = this.data.groupChatID;

    if(Environment.stage) {
      this.fbPrefix = 'stage_';
    }
  }

  ngOnInit() {}

  onNoClick(): void
  {
    this.data.close();
  }

  showGroupUsers(i)
  {
    this.searchUsersInput.nativeElement.value = '';
    this.filteredUserGroups = null;
    this.groupUsers = this.userGroups[i].list;
    this.groupUuid = this.userGroups[i].uuid;
  }

  searchContacts(event: any)
  {
    const value = event.target.value;

    if (value === '' && value.length < 3) {
      this.filteredUserGroups = null;
      return;
    }

    this.filteredUserGroups = this.userGroups;
    this.filteredUserGroups.map(userGroup => {
      userGroup.filteredUsers = userGroup.list.filter(x =>
        x.name.toLowerCase().includes(value.toLowerCase()));
      return userGroup;
    })
    .filter(userGroup => userGroup.filteredUsers.length);
  }

  public addAll()
  {
    this.chat
      .collection(this.fbPrefix + 'chatgroup')
      .doc(this.productID)
      .collection('groups')
      .doc(this.groupChatID)
      .update({ groups_id: firebase.firestore.FieldValue.arrayUnion(this.groupUuid) });
    this.groupUsers.forEach(groupMember => this.addUserToGroup(groupMember));
  }

  public addUserToGroup(groupMember, associatedSubject:any = false)
  {
    if(associatedSubject !== false) {
      associatedSubject = Object.assign({}, associatedSubject);
    }
    const uuid = associatedSubject ? groupMember.recipient_uuid : groupMember.uuid;

    this.chat
      .collection(this.fbPrefix + 'chatgroup')
      .doc(this.productID)
      .collection('groups')
      .doc(this.groupChatID)
      .update({
        members: firebase.firestore.FieldValue.arrayUnion(uuid),
        [`viewedMembers.${uuid}`]: { viewed: 0 }
      });

    var data:any = {
      firstname: groupMember.firstname,
      lastname: groupMember.lastname,
      email: groupMember.email,
      uuid: uuid,
      avatar: groupMember.avatar,
      group: groupMember.group,
      viewedMessages: 0,
    };

    if(!! groupMember.associated_user_recipients && groupMember.associated_user_recipients.length > 0) {
      const associatedUsers = groupMember.associated_user_recipients
        .filter(associatedUser => associatedUser.link_chats === true);

      if(associatedUsers.length > 0) {
        data.associated_recipients = groupMember.associated_user_recipients;
      }
    }

    if(associatedSubject) {
      delete associatedSubject.associated_user_recipients;
      data.associated_subject = associatedSubject;
      data.isAssociatedUser = true;
    }

    this.chat.collection(this.fbPrefix + 'chatgroup')
      .doc(this.productID)
      .collection('groups')
      .doc(this.groupChatID)
      .collection('invited')
      .doc(uuid)
      .set(data);

    if(!! groupMember.associated_user_recipients && groupMember.associated_user_recipients.length > 0) {
      groupMember.associated_user_recipients.forEach(associatedUser => {
        if(associatedUser.link_chats) {
          this.addUserToGroup(associatedUser, groupMember);
        }
      });
    }
  }
}