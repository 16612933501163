import { COMP_LOREM_IPSUM } from './../../form/const/components';
import { error } from '@angular/compiler/src/util';
import { getSecureUrl } from './../editor/utils';

const newInlineItem = {
    displayAs: 'link',
    imageUUID: null,
    itemFloat: 'none',
    itemText: COMP_LOREM_IPSUM,
    itemType: null,
    itemUUID: null
}

const newInlineUrl = {
    displayAs: 'link',
    imageUUID: null,
    itemFloat: 'none',
    itemText: COMP_LOREM_IPSUM,
    openInAppBrowser: false,
    url: null
}

const newInlineImage = {
    imageFloat: 'none',
    imageUUID: null
}

export const tinyMceInit = function(baseUrl, isSecureStorage, indexDb = null) {
    var init:any = {
        paste_data_images: true,
        inline: true,
        auto_focus: true,
        menubar: false,
        plugins: 'lists textcolor colorpicker paste',
        statusbar: false,
        object_resizing: false,
        a11y_advanced_options: true,
        fixed_toolbar_container: "#tiny-mce-toolbar",
        extended_valid_elements : `img[src|class|style|imageuuid|imagedetails|imagefloat|imagefloatright|imagefloatleft|ucareimage],a[href|class|style|itemuuid|itemtype|inlineitemlink|imageuuid|imagedetails|displayas|itemtext|itemfloatleft|itemfloatright|itemfloat|contenteditable|openinappbrowser|url|inlineurl]`,
        toolbar:`
undo redo removeformat | formatselect | bold italic underline forecolor |
bullist numlist indent outdent |
alignleft aligncenter alignright alignjustify |
inlineImage inlineItem inlineUrl`,

        content_style: `
img {
    max-width: 100%;
}
img.image-float-right {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
}
a.item-float-right {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
}
img.image-float-left {
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
}
a.item-float-left {
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
}
.tox-tinymce-aux {
    position: relative !important;
    z-index: 1600;
}`
    }

    init.setup = editor => {

        this.editor = editor;
        this.editor.baseUrl = baseUrl;
        this.editor.isSecureStorage = isSecureStorage;
        if(isSecureStorage) {
            this.editor.indexDb = indexDb;
        }
        const PluginManager = editor.editorManager.PluginManager;

        editor.ui.registry.addButton('inlineItem', {
            icon: 'embed-page',
            onAction: () => inlineItemDialog.call(this, editor)
        });

        editor.ui.registry.addButton('inlineImage', {
            icon: 'image',
            onAction: () => inlineImageDialog.call(this, editor)
        });

        editor.ui.registry.addButton('inlineUrl', {
            icon: 'link',
            onAction: () => inlineUrlDialog.call(this, editor)
        });

        // Double click events on editor items
        editor.on('dblclick', event => {
            const clickTarget = event.target;

            handleInlineImages.call(this, editor, clickTarget);
            handleInlineItems.call(this, editor, clickTarget);
            handleInlineUrls.call(this, editor, clickTarget);
        });
    }

    return init;
}

// Handle inline images
const handleInlineImages = function(editor, imageToEdit) {
    if (imageToEdit.hasAttribute('ucareimage')) {
        const options:any = { editing: true, imageToEdit: imageToEdit };

        options.imageUUID = imageToEdit.getAttribute('imageuuid');
        options.imageFloat = imageToEdit.getAttribute('imagefloat');

        inlineImageDialog.call(this, editor, options);
    }
}

// Handle inline items
const handleInlineItems = function(editor, itemToEdit) {
    if (itemToEdit.hasAttribute('inlineitemlink')) {
        const options:any = { editing: true, itemToEdit: itemToEdit };

        options.displayAs = itemToEdit.getAttribute('displayas');
        options.imageUUID = itemToEdit.getAttribute('imageuuid');
        options.itemFloat = itemToEdit.getAttribute('itemfloat');
        options.itemText = itemToEdit.getAttribute('itemtext');
        options.itemType = itemToEdit.getAttribute('itemtype');
        options.itemUUID = itemToEdit.getAttribute('itemuuid');

        inlineItemDialog.call(this, editor, options);
    }
}

// Handle inline urls
const handleInlineUrls = function(editor, urlToEdit) {
    if (urlToEdit.hasAttribute('inlineurl')) {
        const options:any = { editing: true, urlToEdit: urlToEdit };

        options.displayAs = urlToEdit.getAttribute('displayas');
        options.imageUUID = urlToEdit.getAttribute('imageuuid');
        options.itemFloat = urlToEdit.getAttribute('itemfloat');
        options.itemText = urlToEdit.getAttribute('itemtext');
        options.openInAppBrowser = urlToEdit.getAttribute('openinappbrowser');
        options.url = urlToEdit.getAttribute('url');

        inlineUrlDialog.call(this, editor, options);
    }
}

// Open inline item dialog
export const inlineItemDialog = function(editor, options:any = {}) {
    const { editing } = options;

    if (!editing) {
        options = newInlineItem;
    }

    this.pageForm.inlineItemDialog(options)
        .then(itemResult => {
            if (!! itemResult) {
                if (editing) {
                    editItem.call(this, itemResult, options);
                } else {
                    itemToInsert.call(this, itemResult)
                        .then(itemToInsertResult => editor.insertContent(itemToInsertResult))
                }
            }
        });
}

// Open inline url dialog
export const inlineUrlDialog = function(editor, options:any = {}) {
    const { editing } = options;

    if (!editing) {
        options = newInlineUrl;
    }

    this.pageForm.inlineUrlDialog(options)
        .then(urlResult => {
            if (!! urlResult) {
                if (editing) {
                    editUrl.call(this, urlResult, options);
                } else {
                    urlToInsert.call(this, urlResult)
                        .then(urlToInsertResult => editor.insertContent(urlToInsertResult));
                }
            }
        });
}

// Open inline image dialog
export const inlineImageDialog = function(editor, options:any = {}) {
    const { editing } = options;

    if (!editing) {
        options = newInlineImage;
    }

    this.pageForm.inlineImageDialog(options)
        .then(imageResult => {
            if (!! imageResult) {
                if (editing) {
                    editImage.call(this, imageResult, options);
                } else {
                    imageToInsert.call(this, imageResult)
                        .then(imageToInsertResult => {
                            editor.insertContent(imageToInsertResult)
                        });
                }
            }
        });
}

// Edit url element
const editUrl = function(urlResult, options) {
    const {
        displayAs,
        imageUUID,
        itemFloat,
        itemText,
        itemType,
        openInAppBrowser,
        url
    } = urlResult;
    const { urlToEdit } = options;

    if (urlToEdit) {
        var linkContent = itemText;
        var href = openInAppBrowser
            ? 'inAppBrowser:' + url
            : 'system:' + url;

        urlToEdit.classList = '';
        urlToEdit.setAttribute('url', url);
        urlToEdit.setAttribute('imageuuid', imageUUID);
        urlToEdit.setAttribute('openinappbrowser', openInAppBrowser);
        urlToEdit.setAttribute('itemtext', itemText);
        urlToEdit.setAttribute('displayas', displayAs);
        urlToEdit.setAttribute('itemfloat', itemFloat);
        urlToEdit.setAttribute('href', href);

        if (itemFloat) {
            urlToEdit.classList.add(`item-float-${itemFloat}`);
            urlToEdit.style.cssFloat = itemFloat;
        } else {
            urlToEdit.style.cssFloat = 'none';
        }

        if (displayAs === 'image') {
            imageToInsert.call(this, urlResult)
                .then(imageToInsertResult => urlToEdit.innerHTML = imageToInsertResult);
        } else {
            urlToEdit.innerHTML = linkContent;
        }
    }
}

// Edit item element
const editItem = function(itemResult, options) {
    const {
        itemUUID, itemText, displayAs, itemFloat, itemType, imageDetails, imageUUID
    } = itemResult;
    const { itemToEdit } = options;

    if (itemToEdit) {
        var linkContent = itemText;

        itemToEdit.classList = '';
        itemToEdit.setAttribute('itemuuid', itemUUID);
        itemToEdit.setAttribute('imageuuid', imageUUID);
        itemToEdit.setAttribute('itemtype', itemType);
        itemToEdit.setAttribute('itemtext', itemText);
        itemToEdit.setAttribute('displayas', displayAs);
        itemToEdit.setAttribute('itemfloat', itemFloat);
        itemToEdit.setAttribute('href', `inAppLink:${itemType}/${itemUUID}`);

        if (itemFloat) {
            itemToEdit.classList.add(`item-float-${itemFloat}`);
            itemToEdit.style.cssFloat = itemFloat;
        } else {
            itemToEdit.style.cssFloat = 'none';
        }

        if (displayAs === 'image') {
            imageToInsert.call(this, itemResult)
                .then(imageToInsertResult => itemToEdit.innerHTML = imageToInsertResult);
        } else {
            itemToEdit.innerHTML = linkContent;
        }
    }
}

// Edit image element
const editImage = function(imageResult, options) {
    const { imageUUID } = imageResult;
    const { imageToEdit } = options;
    const url = `${this.editor.baseUrl}${imageUUID}/`;

    if (imageToEdit) {
        if(this.editor.isSecureStorage) {
            getSecureUrl(url+'url')
                .then(resultUrl => imageToEditHtml(imageResult, imageToEdit, resultUrl));
        } else {
            imageToEditHtml(imageResult, imageToEdit, url);
        }
    }
}

const imageToEditHtml = (imageResult, imageToEdit, url) => {
    const { imageUUID, imageDetails, imageFloat } = imageResult;

    imageToEdit.classList = '';
    imageToEdit.setAttribute('imageuuid', imageUUID);
    imageToEdit.setAttribute('imagefloat', imageFloat);
    imageToEdit.setAttribute('src', url)

    if (imageFloat) {
        imageToEdit.classList.add(`image-float-${imageFloat}`);
        imageToEdit.style.cssFloat = imageFloat;
    } else {
        imageToEdit.style.cssFloat = 'none';
    }
}

// Create new image element
const imageToInsert = function(imageResult) {
    return new Promise(resolve => {
        const { imageUUID } = imageResult;
        const url = `${this.editor.baseUrl}${imageUUID}/`;
        if(this.editor.isSecureStorage) {
            getSecureUrl(url+'url')
                .then(resultUrl => resolve(imageToInsertHtml(imageResult, resultUrl)));
        } else {
            resolve(imageToInsertHtml(imageResult, url));
        }
    });
}

const imageToInsertHtml = (imageResult, url) => {
    const { imageUUID, imageDetails, imageFloat } = imageResult;
    var imageToInsert = `<img `
    var style = [];
    var classes = [];
    var attrs = [
        `imageuuid="${imageUUID}"`,
        'ucareimage="true"',
        // `imagedetails="${JSON.stringify(imageDetails)}"`,
        `src="${url}"`
    ];
    if (imageFloat) {
        classes.push(`image-float-${imageFloat}`);
        style.push(`float:${imageFloat}`);
    }

    imageToInsert += attrs.join(' ');

    if (style.length > 0) {
        imageToInsert += ` style="${style.join(',')}"`;
    }

    if (classes.length > 0) {
        imageToInsert += ` class="${classes.join(',')}"`;
    }

    imageToInsert += `>`;

    return imageToInsert;
}

// Create new url element
const urlToInsert = function(urlResult) {
    return new Promise(resolve => {
        const {
            displayAs,
            imageUUID,
            itemFloat,
            itemText,
            itemType,
            openInAppBrowser,
            url
        } = urlResult;

        var linkContent = itemText;
        var href = openInAppBrowser
            ? 'inAppBrowser:' + url
            : 'system:' + url;
        var urlToInsert = `<a `
        var style = [];
        var classes = [];
        var attrs = [
            `contenteditable="false"`,
            `href="${href}"`,
            `url="${url}"`,
            `imageuuid="${imageUUID}"`,
            `openinappbrowser="${openInAppBrowser}"`,
            `itemtext="${itemText}"`,
            `itemfloat="${itemFloat}"`,
            `displayas="${displayAs}"`,
            'inlineurl="true"'
        ];

        if (itemFloat) {
            classes.push(`item-float-${itemFloat}`);
            style.push(`float:${itemFloat}`);
        }

        urlToInsert += attrs.join(' ');

        if (style.length > 0) {
            urlToInsert += ` style="${style.join(',')}"`;
        }

        if (classes.length > 0) {
            urlToInsert += ` class="${classes.join(',')}"`;
        }

        if (displayAs === 'image') {
            imageToInsert.call(this, urlResult)
                .then(imageToInsertResult => {
                    urlToInsert += `>${imageToInsertResult}</a>`;
                    resolve(urlToInsert);
                });
        } else {
            urlToInsert += `>${linkContent}</a>`;
            resolve(urlToInsert);
        }
    });
}

// Create new item element
const itemToInsert = function(itemResult) {
    return new Promise(resolve => {
        const {
            displayAs, itemUUID, itemType, itemText, imageUUID, imageDetails, itemFloat
        } = itemResult;

        var linkContent = itemText;
        var itemToInsert = `<a `
        var style = [];
        var classes = [];
        var attrs = [
            `contenteditable="false"`,
            `href="inAppLink:${itemType}/${itemUUID}"`,
            `itemuuid="${itemUUID}"`,
            `imageuuid="${imageUUID}"`,
            `itemtype="${itemType}"`,
            `itemtext="${itemText}"`,
            `itemfloat="${itemFloat}"`,
            `displayas="${displayAs}"`,
            'inlineitemlink="true"'
        ];

        if (itemFloat) {
            classes.push(`item-float-${itemFloat}`);
            style.push(`float:${itemFloat}`);
        }

        itemToInsert += attrs.join(' ');

        if (style.length > 0) {
            itemToInsert += ` style="${style.join(',')}"`;
        }

        if (classes.length > 0) {
            itemToInsert += ` class="${classes.join(',')}"`;
        }

        if (displayAs === 'image') {
            imageToInsert.call(this, itemResult)
                .then(imageToInsertResult => {
                    itemToInsert += `>${imageToInsertResult}</a>`;
                    resolve(itemToInsert);
                });
        } else {
            itemToInsert += `>${linkContent}</a>`;
            resolve(itemToInsert);
        }
    });
}