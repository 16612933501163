import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { MessageService } from '@services/message.service';
import { SidebarService } from '@services/sidebar.service';
import { MESSAGES_FILTER_FIELDS } from './filter-fields';
import { FilterLink } from '@app/models/view-models/filter-link';
import { SidebarFilterLink } from '@app/services/sidebar-filter-link.service';
import { Environment } from '@environments/environment';

@Component({
  selector: 'app-messages-index',
  templateUrl: './messages-index.component.html',
  styleUrls: ['./messages-index.component.scss', './messages-index-700.component.scss'],
  providers: [SidebarService]
})
export class MessagesIndexComponent extends BaseComponent implements OnInit {
  public data: any;
  public dataLoaded = false;
  public displayedColumns = ['select', 'avatar', 'created_by_user.name', 'created_at', 'title', 'recipient_count', 'sent_date', 'delivery', 'actions'];
  public eventCols: any;
  public fields: any = MESSAGES_FILTER_FIELDS;
  public links: FilterLink[] = [];

  constructor(
    public messageService: MessageService,
    public router: Router,
    public sidebarService: SidebarService,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog,
    private sidebarFilterLink: SidebarFilterLink
  ) {
    super();
    this.setObjects({
      modelService: messageService,
      router,
      sidebarService,
      state,
      snackbarService,
      dialog
    });

    this.setPageVars({
      sidebar: 'messages',
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => {
       this.dataLoaded = true;
    }
   );

    // Setup the columns settings for the multi-selection table (Events)
    this.eventCols = [
      { columnDef: 'selected', header: 'Selected', selectedOnclick: true, cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', header: 'Name', selectedOnclick: true, cell: (element: any) => `${element.name}` },
      {
        columnDef: 'start_time', header: 'Start Time', selectedOnclick: false,
        cell: (element: any) => `${element.start_time}`
      },
      {
        columnDef: 'end_time', header: 'End Time', selectedOnclick: false,
        cell: (element: any) => `${element.end_time}`
      },
    ];
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.links = this.sidebarFilterLink.buildMessageFilterLinks();
  }

  createMessage() {
    this.openSlideOutWithLink('messages', 'createForm');
  }

  viewMessage(row) {
    this.openSlideOutWithLink('messages', 'view/' + row.uuid);
  }

  previewMessage(row) {
    const url = Environment.apiBaseURL + 'admin/message/' + row.uuid + '/preview';
    window.open(url, '_blank');
  }

  editMessage(row) {
    this.openSlideOutWithLink('messages', 'editForm/' + row.uuid);
  }

  sortArticles(row) {
    this.openSlideOutWithLink('messages', 'sort-articles/' + row.uuid);
  }

  sortDocuments(row) {
    this.openSlideOutWithLink('messages', 'sort-documents/' + row.uuid);
  }

  public onFilterClicked(link: FilterLink): void {
    if (link.route) {
      this.router.navigate([link.route]);
    }
  }
}
