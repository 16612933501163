import { Component, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LocationService } from '@app/services/location.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';

@Component({
  selector: 'location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss'],
  providers: [SidebarService]
})

@Injectable()
export class LocationFormComponent extends BaseComponent {

  // Local variables
  locationFormGroup: FormGroup;
  dataLoaded = false;

  // Input parameters

  // Onput events

  // Contractor
  constructor(
    public activatedRoute: ActivatedRoute,
    public locationService: LocationService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: locationService
    });

    this.setPageVars({
      sidebar: ''
    });

    this.initialFormGroupSetup();
    const id = this.routeParams['id'];
    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result: any) => {
        this.dataLoaded = true;
        this.locationFormGroup.patchValue(result);
        this.controls.country_uuid.setValue(result.country.uuid);
      });
    }
  }

  // All the public methods
  get controls(): any {
    return this.locationFormGroup.controls;
  }

  public save(): void {
    const id = this.controls.id.value;
    const uuid = this.controls.uuid.value;
    this.controls.country.setValue(this.controls.country.uuid);
    if (id || uuid) {
      // Update the existing row
      this.locationService.patchById(uuid ? uuid : id, this.locationFormGroup.value)
        .subscribe(result => {
          this.SavedMessage(result);
        });
    } else {
      // Create the new row
      this.locationService.create(this.locationFormGroup.value).subscribe(result => this.SavedMessage(result));
    }
  }

  // All the private methods
  private initialFormGroupSetup(): void {
    // setup the initial form for row entity
    this.locationFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      name: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      address: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      address2: [null, [Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      address3: [null, [Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      postcode: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      country_uuid: [null, [Validators.required]],
      country: null
    });
  }

  private SavedMessage(data) {
    this.state.message({ ['locationIndexUpdate']: true });
    this.closeSlideout();
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

}
