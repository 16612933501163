import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { NotificationService } from '@services/notification.service';
import { SidebarService } from '@services/sidebar.service';
import { NOTIFICATION_FILTER_FIELDS } from './filter-fields';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';

@Component({
  selector: 'app-notifications-index',
  templateUrl: './notifications-index.component.html',
  styleUrls: ['./notifications-index.component.scss', './notifications-index-700.component.scss'],
  providers: [SidebarService]
})
export class NotificationsIndexComponent extends BaseComponent implements OnInit {
  // public dataSource: any;
  public data: any;
  public displayedColumns = [
    'select',
    'avatar',
    'created_by_user',
    'recipient',
    // 'created_at',
    'title',
    'sent_at',
    'read_at',
    // 'notifiable_type',
    'type',
    'actions'
  ];
  public dataLoaded = false;
  public fields: any = NOTIFICATION_FILTER_FIELDS;
  public node: Breadcrumb;

  constructor(
    public notificationService: NotificationService,
    public router: Router,
    public sidebarService: SidebarService,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog,
  ) {
    super();
    this.setObjects({
      modelService: notificationService,
      router,
      sidebarService,
      state,
      snackbarService,
      dialog
    });

    this.setPageVars({
      sidebar: 'notifications',
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => {
      this.dataLoaded = true;
    }
   );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.nodes = [];
    this.nodes.push({
      label: 'Messages',
      link: 'messages',
      title: 'Messages'
    },
    {
      label: 'Delivery Reports',
      link: 'notifications',
      title: 'Delivery Reports'
    });
  }

  viewNotification(row) {
    this.openSlideOutWithLink('notifications', 'view/' + row.uuid);
  }

}
