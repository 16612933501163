import { AfterContentInit, Component, ContentChildren, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { State } from '@app/state';
import { FormSelectComponent } from './select/form-select.component';
import { CoreFormAdvancedSelectComponent } from '@app/modules/common/core/components/form-advanced-select/core-form-advanced-select.component';
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
// TODO - We should remove this component once all the pages will be converted to reactive form.
export class FormComponent implements AfterContentInit, OnInit {
  @ContentChildren(FormSelectComponent) private selectInputs: QueryList<Component>;
  @ContentChildren(CoreFormAdvancedSelectComponent) private advancedSelectInputs: QueryList<Component>;
  @HostBinding('class.is-content-editable-form') contentEditableFormClass = false;

  @Input() updateActionOverride?: any = false;
  @Input() createActionOverride?: any = false;
  @Input() id = '';
  @Input() uuid = '';
  @Input() editing = false;
  @Input() expectReturnResultAfterSave = false;
  @Input() refreshIndex = true;
  @Input() closeSlideoutAfterSave = true;
  @Input() contentEditableForm = false;
  @Input() formService: any;
  @Input() name = '';
  @Input() saveButtonTitle: string;
  @Input() showCancelButton = false;
  @Input() showCancelAndCloseButton = false;
  @Input() updateFromState?: any;
  @Input() stateMessage?: any = false;

  @Output() refreshIndexInParentComponent: EventEmitter<string> = new EventEmitter<string>();

  private form: any;
  private inputs: Array<any> = [];
  private postData: object = {};

  constructor(public state: State, public formElement: ElementRef, public router: Router) {
    this.contentEditableFormClass = this.contentEditableForm;
    this.form = this.formElement.nativeElement;
    this.state.subscription().subscribe(message => {
      if (!!this.name) {
        if (message['resetForm-' + this.name]) {
          this.resetForm();
        }
      }
    });
  }

  ngOnInit() {
    // append api url with return=true to send back saved result of form submission
    this.formService.expectReturnResultAfterSave = this.expectReturnResultAfterSave;
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.selectInputs.forEach(selectInput => this.inputs.push(selectInput));
      this.advancedSelectInputs.forEach(advanceSelectInput => this.inputs.push(advanceSelectInput));
    });
  }

  save() {
    // update state with response?
    this.inputs.forEach(input => this.setPostDataRow(input));
    this.submitData();
  }

  saveContentEditableForm() {
    const inputs = this.form.querySelectorAll('[data-model]');
    inputs.forEach(element => this.setPostDataContentEditableRow(element));
    this.submitData();
  }

  setPostDataRow(input) {
    if (input.name.includes('.')) {
      let model, field;
      [model, field] = input.name.split('.');
      const value = this.inputValue(input);
      if (value !== null) {
        if (input.constructor.name === 'FormUploadComponent') {
          this.postData = this.uploadValues(model, this.postData, value);
        } else {
          if (typeof this.postData[model] === 'undefined') {
            this.postData[model] = {};
          }
          this.postData[model][field] = value;
        }
      }
    } else {
      const value = this.inputValue(input);
      if (!this.isNullOrUndefined(value)) {
        if (typeof this.postData[input.name] === 'undefined') {
          this.postData[input.name] = {};
        }
        this.postData[input.name] = value;
      }
    }
  }

  uploadValues(model: string = '', data: any, value: any) {
    if (model !== '') {
      data[model].file_family = value.fileFamily;
      data[model].filename = value.filename;
      data[model].filesize = value.filesize.toString();
      data[model].filetype = value.mimeType;
      if (!!value.width) {
        data[model].width = value.width;
        data[model].height = value.height;
      }
      data[model].ucareupload_uuid = value.uuid;
    } else {
      data.file_family = value.fileFamily;
      data.file_name = value.filename;
      data.file_size = value.filesize.toString();
      data.file_type = value.mimeType;
      data.file_extension = value.filename.split('.').pop();
      if (!!value.width) {
        data.width = value.width;
        data.height = value.height;
      }
      data.cdn_uuid = value.uuid;
    }
    return data;
  }

  inputValue(input) {
    const value: any = input.data();
    return value === (null || 'Invalid date') ? null : value;
  }

  setPostDataContentEditableRow(element) {
    if (element['dataset']['model'].includes('.')) {
      let model, field;
      [model, field] = element['dataset']['model'].split('.');
      if (typeof this.postData[model] === 'undefined') {
        this.postData[model] = {};
      }
      this.postData[model][field] = element['innerText'].trim();
    } else {
      if (typeof this.postData[element['dataset']['model']] === 'undefined') {
        this.postData[element['dataset']['model']] = {};
      }
      this.postData[element['dataset']['model']] = element['innerText'].trim();
    }
  }

  submitData() {
    if (this.id || this.uuid) {
      // put / patch requests
      this.formService
        .patchById(this.uuid ? this.uuid : this.id, this.postData, this.updateActionOverride)
        .subscribe(data => this.afterSaveEvents(data));
    } else {
      // create using post
      this.formService.create(this.postData, this.createActionOverride).subscribe(data => this.afterSaveEvents(data));
    }
  }

  afterSaveEvents(data) {
    // update with info from state
    if (!!this.updateFromState) {
      this.state.message({ [this.updateFromState]: data });
    }

    // stop form from going to service index after save
    if (!!this.refreshIndex) {
      this.state.message({ [this.formService.modelTitle + 'IndexUpdate']: true });
    }
    // set content edtiable for to editing
    if (!!this.contentEditableForm) {
      this.state.message({ informationBarEdit: true });
    }
    // if form is in slideout then close it after save
    if (!!this.closeSlideoutAfterSave) {
      this.closeSlideout();
    }
    // stop form from going to service index after save
    if (!!this.stateMessage) {
      this.stateMessage.forEach(key => this.state.message({ [key]: data }));
    }
  }

  closeSlideout() {
    this.state.message({ slideoutHide: true });
    if (document.getElementById('sidebar')) {
      document.getElementById('sidebar').classList.remove('open');
    }
  }

  resetForm() {
    // this.inputs.forEach(input => input.resetValue());
  }

  clearFormAndClose() {
    this.resetForm();
    this.closeSlideout();
  }
  isNullOrUndefined(value) {
    return value === null || value === undefined;
  }
  fileUploadedCreateDocument(event) { }
}
