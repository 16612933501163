import { Component, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ContactService } from '@services/contact.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
@Component({
  selector: 'secondary-contact-form',
  templateUrl: './secondary-contact-form.component.html',
  styleUrls: ['./secondary-contact-form.component.scss'],
  providers: [SidebarService]
})

@Injectable()
export class SecondaryContactFormComponent extends BaseComponent {

  // Local variables
  contactFormGroup: FormGroup;
  dataLoaded = false;
  globalUtil = GlobalUtil;

  // Contractor
  constructor(
    public activatedRoute: ActivatedRoute,
    public contactService: ContactService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: contactService
    });

    this.setPageVars({
      sidebar: ''
    });

    this.initialFormGroupSetup();

    const id = this.routeParams['id'];

    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.contactService.getOne(id).subscribe((result: any) => {
        this.contactFormGroup.patchValue(result);
        this.dataLoaded = true;
      });
    }
  }

  // All the public methods
  get controls(): any {
    return this.contactFormGroup.controls;
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  public save(): void {
    const id = this.controls.id.value;
    const uuid = this.controls.uuid.value;

    this.contactFormGroup.patchValue({
      // 'user_uuid': this.getSelectedUser()
    });

    this.replaceBooleanFieldsWithByte();
    if (id || uuid) {
      // Update the existing row
      this.contactService.patchById(uuid ? uuid : id, this.contactFormGroup.value)
        .subscribe(result => {
          this.SavedMessage(result);
        });
    } else {
      // Create the new row
      this.contactService.create(this.contactFormGroup.value).subscribe(result => this.SavedMessage(result));
    }
    // Rollback the changes so all the checkboxes will bound correctly
    this.replaceByteFieldsWithBoolean();
  }


  // All the private methods
  private initialFormGroupSetup(): void {
    this.contactFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      name: [null, [
      //Validators.required,
      Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      firstname: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      lastname: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      user_uuid: [null, [Validators.required]],
      contact_type_uuid: [null, [Validators.required]],
      email: [null, [Validators.email]],
      mobile: [null],
      town_city: [null],
      postcode_zip: [null],
      is_primary_carer: [null],
      is_emergency_contact: [null],
      send_to_contact: [null],
      country_uuid: [null]
    });
  }

  private SavedMessage(data) {
    this.state.message({ ['ContactIndexUpdate']: true });
    this.closeSlideout();
  }

  // All the private methods (Private method should be after all the public methods)
  private replaceBooleanFieldsWithByte(): void {
    // Replace all the boolean fields with byte
    this.contactFormGroup.patchValue({
      'is_primary_carer': this.controls.is_primary_carer.value ? '1' : '0',
      'is_emergency_contact': this.controls.is_emergency_contact.value ? '1' : '0',
      'send_to_contact': this.controls.send_to_contact.value ? '1' : '0'
    });
  }

  private replaceByteFieldsWithBoolean(): void {
    // Replace all the boolean fields with byte
    this.contactFormGroup.patchValue({
      'is_primary_carer': this.controls.is_primary_carer.value === '1' ? true : false,
      'is_emergency_contact': this.controls.is_emergency_contact.value === '1' ? true : false,
      'send_to_contact': this.controls.send_to_contact.value === '1' ? true : false,
    });
  }
}
