import { Component, Input, OnChanges } from '@angular/core';
import { State } from '@app/state';
@Component({
  selector: 'rightbar',
  templateUrl: './rightbar.component.html',
  styleUrls: ['./rightbar.component.scss', './rightbar-700.component.scss']
})
export class RightbarComponent implements OnChanges {
  constructor(private state: State) { }
  ngOnChanges() {
  }
}
