import { Component, Injectable, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FileInfo } from '@app/models/entities/fileInfo';
import { AppService } from '@app/services/app.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';

@Component({
  selector: 'app-app-form',
  templateUrl: './app-form.component.html',
  styleUrls: ['./app-form.component.scss']
})
export class AppFormComponent extends BaseComponent {
  appFormGroup: FormGroup;
  tagCols: any;
  documentsCols: any;
  dataLoaded = false;
  formSubmitted = false;
  selectedTagIds = [];
  selectedDocumentIds = [];
  private config = GlobalUtil.Configuration;

  constructor(
    public activatedRoute: ActivatedRoute,
    public appService: AppService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      state: state,
      modelService: appService
    });

    this.initialFormGroup();
    const id = this.routeParams['id'];
    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getFromApi('app-v2/' + id).subscribe((result: any) => {
        this.appFormGroup.patchValue(result);
        this.appFormGroup.patchValue({
          homepage_uuid: result.homepage ? result.homepage.uuid : null,
        });
        this.dataLoaded = true;
      });
    }
  }

  private initialFormGroup(): void {
    this.appFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      name: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      teaser: [null],
      published: [null],
      google_ua_code: [null],
      has_schedule: [null],
      has_sms_inbox: [null],
      has_chats: [null],
      has_notifications: [null],
      has_files: [null],
      has_contacts: [null],
      has_profiles: [null],
      homepage_uuid: [null],
      cover_image_cdn_uuid: [null],
      default_style_color: [null, [Validators.required, Validators.maxLength(7)]],
      light_style_color: [null, [Validators.required, Validators.maxLength(7)]],
      dark_style_color: [null, [Validators.required, Validators.maxLength(7)]],
      default_style_primary: [null, [Validators.required, Validators.maxLength(7)]],
      default_style_secondary: [null, [Validators.required, Validators.maxLength(7)]],
      default_style_tertiary: [null, [Validators.required, Validators.maxLength(7)]],
      light_style_primary: [null, [Validators.required, Validators.maxLength(7)]],
      light_style_secondary: [null, [Validators.required, Validators.maxLength(7)]],
      light_style_tertiary: [null, [Validators.required, Validators.maxLength(7)]],
      dark_style_primary: [null, [Validators.required, Validators.maxLength(7)]],
      dark_style_secondary: [null, [Validators.required, Validators.maxLength(7)]],
      dark_style_tertiary: [null, [Validators.required, Validators.maxLength(7)]],
    });
  }

  // Listens for FileInfo from the upload component
  public getUploadedFileInfo($fileInfo: FileInfo) {
    if ($fileInfo) {
      this.appFormGroup.patchValue({
        'cover_image_cdn_uuid': $fileInfo.uuid
      });
    } else {
      this.appFormGroup.patchValue({
        'cover_image_cdn_uuid': null
      });
    }
  }

  get controls(): any {
    return this.appFormGroup.controls;
  }

  public save(): void {
    const uuid = this.controls.uuid.value;
    this.formSubmitted = true;
    this.replaceBooleanFieldsWithByte();

    if (uuid) {
      this.appService.patchById(uuid, this.appFormGroup.value, 'app-v2').subscribe(result => this.SavedMessage(result));
    } else {
      this.appService.create(this.appFormGroup.value, 'apps-v2').subscribe(result => this.SavedMessage(result));
    }

    // Rollback the changes so all the checkboxes will bound correctly
    this.replaceByteFieldsWithBoolean();
    setTimeout (() => {
      this.formSubmitted = false;
    }, 1000);
  }

  private SavedMessage(data) {
    this.state.message({ ['AppV2IndexUpdate']: true });
    this.closeSlideout();
  }

  public cancel(): void {
    this.appFormGroup.reset();
  }

  // All the private methods (Private method should be after all the public methods)
  private replaceBooleanFieldsWithByte(): void {
    this.appFormGroup.patchValue({
      published: this.controls.published.value ? '1' : '0',
      has_schedule: this.controls.has_schedule.value ? '1' : '0',
      has_sms_inbox: this.controls.has_sms_inbox.value ? '1' : '0',
      has_chats: this.controls.has_chats.value ? '1' : '0',
      has_notifications: this.controls.has_notifications.value ? '1' : '0',
      has_files: this.controls.has_files.value ? '1' : '0',
      has_contacts: this.controls.has_contacts.value ? '1' : '0',
      has_profiles: this.controls.has_profiles.value ? '1' : '0'
    });
  }

  private replaceByteFieldsWithBoolean(): void {
    this.appFormGroup.patchValue({
      published: this.controls.published.value === '1' ? true : false,
      has_schedule: this.controls.has_schedule.value === '1' ? true : false,
      has_sms_inbox: this.controls.has_sms_inbox.value === '1' ? true : false,
      has_chats: this.controls.has_chats.value === '1' ? true : false,
      has_notifications: this.controls.has_notifications.value === '1' ? true : false,
      has_files: this.controls.has_files.value === '1' ? true : false,
      has_contacts: this.controls.has_contacts.value === '1' ? true : false,
      has_profiles: this.controls.has_profiles.value === '1' ? true : false
    });
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }
}
