import {
  InlineUrlDefaults,
  InlineUrlTraits
} from './shared';

import { COMP_TEXT_INLINE_URL } from './../../../const/components';

import { ICON_BUTTON_URL } from './../../../const/icons';

import { PageFormLinkedItem } from './../page-form-linked-item';

export const InlineUrl = (editor, options:any = {}) => {

  return PageFormLinkedItem(editor, {
    componentName: COMP_TEXT_INLINE_URL,
    displayIcon: ICON_BUTTON_URL,
    model: {
      defaults: { ...InlineUrlDefaults,
        name: 'Url',
        traits: [ ...InlineUrlTraits]
      }
    }
  });
}