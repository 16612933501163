import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { EventService } from '@services/event.service';
import { SidebarService } from '@services/sidebar.service';
import { Subscription } from 'rxjs';
import { EVENTS_FILTER_FIELDS } from './filter-fields';
import { EVENTS_NO_CALENDARS_FILTER_FIELDS } from './no-calendars-filter-fields';
@Component({
  selector: 'app-events-index',
  templateUrl: './events-index.component.html',
  styleUrls: ['./events-index.component.scss'],
  providers: [SidebarService]
})
export class EventsIndexComponent extends BaseComponent implements OnInit, OnDestroy {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['select', 'start_time', 'end_time', 'title', 'category', 'calendars', 'location', 'actions'];
  public dataLoaded = false;
  public isCalView = false;
  public fields: any = EVENTS_FILTER_FIELDS;
  public fieldsNoCalendars: any = EVENTS_NO_CALENDARS_FILTER_FIELDS;
  public defaultTimezoneName: string;
  public showFilter = false;
  public baseUrl = 'events';

  private stateSubscription: Subscription;
  constructor(
    public activatedRoute: ActivatedRoute,
    public eventService: EventService,
    public sidebarService: SidebarService,
    public router: Router,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      modelService: eventService,
      sidebarService: sidebarService,
      router: router,
      state: state
    });

    var queryVars = { scope: 'hidePeriodization' };
    if (!! this.routeData.periodization && this.routeData.periodization === true) {
      queryVars.scope = 'periodization';
    }

    if (!! this.routeParams['calendar_uuid']) {
      this.isCalView = true;
      this.fieldsNoCalendars.push({
        type: 'hidden',
        name: 'calendar_uuid',
        value: this.routeParams['calendar_uuid']
      });
      this.queryStringFilter = this.createFilterParams({ 'calendar_uuid': this.routeParams['calendar_uuid'] });
      this.baseUrl = 'events/calendar/' + this.routeParams['calendar_uuid'];
    }

    this.state.message({ slideoutHide: true });

    this.stateSubscription = this.state.subscription()
      .subscribe((stateData: { pageLoaded: any; }) => {
        if (stateData.pageLoaded) {
          this.dataLoaded = true;
        }
      });

    this.setPageVars({
      sidebar: 'events',
      listenForIndexUpdate: true,
      indexUpdateQueryVars: queryVars
    });
    this.modelServiceGetAll(1, null, null, queryVars);
  }

  ngOnInit() {
    // this.openSlideOutWithLink('events', 'create');
    // this.openSlideOutWithLink('events', 'edit/8464fe72-e00a-11ea-b62d-080027c2f5bb');
  }

  ngOnDestroy() {
    this.stateSubscription.unsubscribe();
  }
  /**
   * Called from Small screen devices to show hide the Sidebar
   *
   */
  toggleFilter(): void {
    this.showFilter = !this.showFilter;
     if (this.showFilter) {
      document.getElementById('sidebar').classList.add('open-cal-filter');
    } else {
      document.getElementById('sidebar').classList.remove('open-cal-filter');
    }
  }
}
