import { Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { MessageService } from '@services/message.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
@Component({
  selector: 'app-user-message-form',
  templateUrl: './user-message-form.component.html',
  styleUrls: [],
})

export class UserMessageFormComponent extends BaseComponent implements OnInit {
  messageFormGroup: FormGroup;
  user_uuid: string;
  documentCols: any;
  articleCols: any;
  eventCols: any;
  dataLoaded = false;
  formSubmitted = false;
  selectedDocumentIds = [];
  selectedArticleIds = [];
  selectedEventIds = [];
  oneToManyColumnsDef = [];
  ids = [];

  @ViewChild('documents', {static: false}) private documentsInput: { getSelectedIds: () => any[]; };
  @ViewChild('articles', {static: false}) private articlesInput: { getSelectedIds: () => any[]; };
  @ViewChild('events', {static: false}) private eventsInput: { getSelectedIds: () => any[]; };

  private config = GlobalUtil.Configuration;

  constructor(
    public activatedRoute: ActivatedRoute,
    public messageService: MessageService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      state: state,
      modelService: messageService
    });

    this.user_uuid = this.routeParams['uuid'];
    this.initialFormGroup();
    this.dataLoaded = true;

    // Setup the columns settings for the multi-selection table (Documents)
    this.documentCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      {
        columnDef: 'thumbnail',
        selectedOnclick: false,
        showThumbnail: true,
        header: '',
        cell: (element: any) => `${element.cdn_uuid}`
      },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` },
      {
        columnDef: 'created_at',
        header: 'Created On',
        selectedOnclick: false,
        dateFormat: this.config.SHORT_DATE_FORMAT,
        cell: (element: any) => `${element.created_at}`
      }
    ];

    // Setup the columns settings for the multi-selection table (articles)
    this.articleCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'title', selectedOnclick: true, header: 'Title', cell: (element: any) => `${element.title}` },
      {
        columnDef: 'created_at',
        header: 'Created On',
        selectedOnclick: false,
        dateFormat: this.config.SHORT_DATE_FORMAT,
        cell: (element: any) => `${element.created_at}`
      }
    ];

    // Setup the columns settings for the multi-selection table (Events)
    this.eventCols = [
      { columnDef: 'selected', header: 'Selected', selectedOnclick: true, cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', header: 'Name', selectedOnclick: true, cell: (element: any) => `${element.name}` },
      {
        columnDef: 'start_time', header: 'Start Time', selectedOnclick: false,
        cell: (element: any) => `${element.start_time}`
      },
      {
        columnDef: 'end_time', header: 'End Time', selectedOnclick: false,
        cell: (element: any) => `${element.end_time}`
      },
    ];

  }

  // setup the initial form for Message entity
  private initialFormGroup(): void {
    this.messageFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      title: [
        null, [
          Validators.minLength(3),
          Validators.maxLength(255),
          Validators.required,
        ]
      ],
      send_as_email: [null],
      send_as_sms: [null],
      send_as_push: [null],
      body: [
        null, [
          Validators.minLength(3),
          this.richTextRequiredValidator,
        ]
      ],
      body_plain_text: [
        null, [
          Validators.minLength(3),
          Validators.maxLength(255),
          this.plainTextRequiredValidator,
        ]
      ],
      recipients: [],
      documents: [],
      articles: [],
      events: [],
    });
  }

  get controls(): any {
    return this.messageFormGroup.controls;
  }

  public save(): void {
    this.formSubmitted = true;
    this.replaceBooleanFieldsWithByte();
    this.messageFormGroup.patchValue({
      'documents': this.getSelectedDocuments(),
      'articles': this.getSelectedArticles(),
      'events': this.getSelectedEvents(),
      'recipients': [this.user_uuid],
    });
    this.messageService.create(this.messageFormGroup.value).subscribe(result => this.SavedMessage(result));
    this.replaceByteFieldsWithBoolean();
    setTimeout (() => {
      this.formSubmitted = false;
    }, 3000);
  }

  // Make plain text field required if SMS or Push are selected
  public plainTextRequiredValidator(formControl: AbstractControl) {
    if ( ! formControl.parent) {
      return null;
    }
    if (formControl.parent.get('send_as_sms').value || formControl.parent.get('send_as_push').value) {
      return Validators.required(formControl);
    }
    return null;
  }

  // Make rich text field required if 'Email' is selected, should be consolodated with plainTextRequiredValidator()
  public richTextRequiredValidator(formControl: AbstractControl) {
    if ( ! formControl.parent) {
      return null;
    }
    // if (formControl.parent.get('email').value) {
    //   return Validators.required(formControl);
    // }
    return null;
  }

  private SavedMessage(data) {
    this.state.message({ ['MessageIndexUpdate']: true });
    this.closeSlideout();
  }

  // Replace all the boolean fields with byte
  private replaceBooleanFieldsWithByte(): void {
    this.messageFormGroup.patchValue({
      'send_as_email': this.controls.send_as_email.value ? '1' : '0',
      'send_as_push': this.controls.send_as_push.value ? '1' : '0',
      'send_as_sms': this.controls.send_as_sms.value ? '1' : '0',
    });
  }

  // Replace all the boolean fields with byte
  private replaceByteFieldsWithBoolean(): void {
    this.messageFormGroup.patchValue({
      'send_as_email': this.controls.send_as_email.value === '1' ? true : false,
      'send_as_push': this.controls.send_as_push.value === '1' ? true : false,
      'send_as_sms': this.controls.send_as_sms.value === '1' ? true : false,
    });
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  private getSelectedDocuments(): any[] {
    let value: any[] = null;
    if (!! this.documentsInput) {
      value = this.documentsInput.getSelectedIds();
    }
    return value === null ? [] : value;
  }

  private getSelectedArticles(): any[] {
    let value: any[] = null;
    if (!! this.articlesInput) {
      value = this.articlesInput.getSelectedIds();
    }
    return value === null ? [] : value;
  }

  private getSelectedEvents(): any[] {
    let value: any[] = null;
    if (!! this.eventsInput) {
      value = this.eventsInput.getSelectedIds();
    }
    return value === null ? [] : value;
  }

}
