import { paramCase } from 'change-case';
import { fixToolbarPositinoOnComponentHeightChange } from './../../utils';

import { COMP_TEXT_INLINE_ICON } from './../../../const/components';

import { FIELD_ICON_SELECTOR } from './../../../const/traits';

import { inlineIconOnRender } from './shared';

const compParamName = paramCase(COMP_TEXT_INLINE_ICON);

export const InlineIcon = (editor, options:any = {}) => {
  return {
    isComponent: el => el.tagName === compParamName.toUpperCase(),
    // Model
    model: {
      defaults: {
        tagName: compParamName,
        name: 'Icon',
        badgeable: false,
        resizable: false,
        editable: false,
        draggable: false,
        droppable: false,
        inline: true,
        attributes: {
          icon: 'add',
          contenteditable: 'false'
        },
        traits:[FIELD_ICON_SELECTOR]
      }
    },
    // View
    view: {
      tagName: compParamName,
      // Init
      init() {
        // editor.select(this.model);
        this.listenTo(this.model, 'change:attributes', this.handleAttributeChanges);
      },
      // Attribute changes handler
      handleAttributeChanges() {
        this.render();
      },
      // On render
      onRender() {
        inlineIconOnRender.call(this, editor);
      }
    }
  }
}