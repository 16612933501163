import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Cache } from '@app/cache';
import { Environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService extends BaseService {

   constructor(
    public http: HttpClient,
    public cache: Cache
  ) {
    super(http, cache);
    this.setVars({
      endPoint: 'timezone',
      modelTitle: 'Timezone'
    });
  }

  public getDefault(): Observable<any> {
    return this.http.get<any>(`${Environment.apiURL}timezones/default`);
  }

  public getTimezonesList(): Observable<any> {
    return this.http.get<any>(`${Environment.apiURL}timezones/active`);
  }
}
