import { Component, Input } from '@angular/core';

@Component({
  selector: 'schedule-day',
  templateUrl: './schedule-day.component.html',
  styleUrls: ['./schedule-day.component.scss']
})
export class ScheduleDayComponent {
  @Input() date = '';
  @Input() fulldate = '';
  @Input() hoursView = false;

  getDay(date) {
    date = new Date(date);
    return date.getDate();
  }
}

