export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];
export namespace GlobalUtil {
  export class Validation {
    static readonly GENERAL_FIELD_MAX_LENGTH = 255;
    static readonly ISO_FIELD_MAX_LENGTH = 3;
    static readonly EMAIL_PATTERN = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    static readonly MOBILE_PATTERN = '^[1-9][0-9]*$';
    static readonly MOBILE_MIN_LENGTH = 10;
    static readonly MOBILE_MAX_LENGTH = 16;
  }

  export class Configuration {
    static readonly SHORT_DATE_FORMAT = 'MMM dd, yyyy';

    // This format is currenly only used in 'event-duplicate.component'
    static readonly LONG_DATE_FORMAT = 'MMM dd yyyy hh:mm a';

    // Used for formatting dates before sending to the API
    static readonly SHORT_DATE_FORMAT_DISPLAY = 'YYYY-MM-DD';

    // Used for formatting dates and times before sending to the API
    static readonly LONG_DATE_FORMAT_FOR_SAVE_ENTRY = 'YYYY-MM-DD HH:mm:ss';

    static readonly DATE_PICKER_SHORT_DATE_FORMAT_DISPLAY = 'DD MMM YYYY';
    static readonly LONG_DATE_FORMAT_DISPLAY = `${Configuration.DATE_PICKER_SHORT_DATE_FORMAT_DISPLAY} h:mm`;
    static readonly LONG_DATE_FORMAT_DISPLAY_12HOURS = `${Configuration.DATE_PICKER_SHORT_DATE_FORMAT_DISPLAY} h:mm a`;
    static readonly ADJUSTMENT_HOUR_DIFFERENCE = 1;
  }

  export class LocalStorageKey {
    static readonly FOLDER_VIEW_LIST_MODE = 'folder_view_mode';
    static readonly ACTIVE_FOLDER_ENDPOINT = 'folders';
  }

  export const uuidv4 = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}

