import { COMP_LAYOUT_PAGE_SECTION } from './../../const/components';

import { CMD_REMOVE_FROM_PAGE_SECTIONS } from './../../const/commands';

export const RemoveComponent = function(editor, options:any = {}) {
    return {
        name: options.name,
        noLabel: true,
        templateInput: '',
        getInputEl() {
            const model = editor.getSelected();
            var button = document.createElement('button');

            button.innerHTML = `Remove ${model.get('name')}`;
            button.classList.add('gjs-button', 'button', 'button-danger');
            button.onclick = () => {
                if (confirm(`Are you sure you want to remove this ${model.get('name')}?`)) {
                    if (model.is(COMP_LAYOUT_PAGE_SECTION)) {
                        editor.Commands.run(CMD_REMOVE_FROM_PAGE_SECTIONS);
                    } else {
                        model.remove();
                    }
                }
            }

            return button;
        }
    }
}