import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-start-finish',
  templateUrl: './start-finish.component.html',
  styleUrls: ['./start-finish.component.scss']
})
export class StartFinishComponent {
  @Input() event: any;
  constructor() { }
}
