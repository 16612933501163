export const CONTACTS_FILTER_FIELDS = [
  {
    type: 'dropdown_group',
    name: 'user_uuid',
    label: 'User',
    value: '',
    apiUrl: 'groups/members/list',
    showSearch: true
  },
  // {
  //   type: 'text',
  //   name: 'name',
  //   label: 'Name',
  //   value: ''
  // },
  {
    type: 'text',
    name: 'firstname',
    label: 'First Name',
    value: ''
  },
  {
    type: 'text',
    name: 'lastname',
    label: 'Last Name',
    value: ''
  },
  {
    type: 'text',
    name: 'email',
    label: 'Email',
    value: ''
  },
  {
    type: 'dropdown',
    name: 'contact_type_uuid',
    label: 'Contact Type',
    value: '',
    apiUrl: 'contact-types/list'
  }
];
