export const MESSAGES_RESPONSE_FILTER_FIELDS = [
  {
    type: 'dropdown_group',
    name: 'created_user_uuid',
    label: 'All Users',
    value: '',
    apiUrl: 'groups/members/list',
    showSearch: true
  },
  {
    type: 'date',
    name: 'created_at',
    label: 'Sent date',
    value: '',
  },
  {
    type: 'dropdown',
    name: 'date_match',
    label: 'Exact Date',
    value: '',
    options: [
      {
        text: 'Exact Date',
        value: 'exact'
      },
      {
        text: 'Before',
        value: 'before'
      },
      {
        text: 'On and Before',
        value: 'onandbefore'
      },
      {
        text: 'After',
        value: 'after'
      },
      {
        text: 'On and After',
        value: 'onandafter'
      }
    ]
  }
];
