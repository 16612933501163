// taken from https://scotch.io/@vigneshsithirai/angular-6-7-http-client-interceptor-with-error-handling
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SnackBarService } from '@services/snack-bar.service';
import { HttpCacheService } from '@services/http-cache.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '@services/auth.service';
import { State } from '@app/state';

const TTL = 3000;

/**
 * HTTP interceptor
 *
 */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  private modelTitle = '';
  private userSessionUrl = false;
  private refreshingTokenUrl = false;
  private hideSnackBarNotifications = false;

  constructor(
    private state: State,
    private authService: AuthService,
    private snackBar: SnackBarService,
    private router: Router,
    private cache: HttpCacheService,
  ) {
    this.state.subscription().subscribe(stateData => {
      if(!! stateData.hideSnackBarNotifications) {
        this.hideSnackBarNotifications = stateData.hideSnackBarNotifications;
      };
    });
  }

  /**
   * Intercept all http requests
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @return {Observable}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if(request.url.includes('api/user/get-tokens')) {
      return next.handle(request);
    }

    this.userSessionUrl = (
      request.url.endsWith('user-session/get-session') ||
      request.url.endsWith('user-session/refresh-token') ||
      request.url.includes('user-session/check-route-permissions')
    );

    this.refreshingTokenUrl = !! request.url.endsWith('user-session/refresh-token');

    // Get model title if provied by request
    // Not needed really...
    if (!!request.params.get('modelTitle')) {
      this.modelTitle = request.params.get('modelTitle');
    }

    const appendedRequest = this.refreshingTokenUrl
      ? this.appendRefreshRequestHeaders(request)
      : this.appendRequestHeaders(request);

    if (this.userSessionUrl) {
      return this.handleUserSessionRequest(appendedRequest, next);
    } else {
      return this.handleRequest(appendedRequest, next);
    }
  }

  deploymentNotification(event: HttpResponse<any>) {
    if (!! event.body.cc_deployment_hash) {
      localStorage.setItem('deployment_hash', event.body.cc_deployment_hash);
    } else {
      const deploymentHash = event.headers.get('CC-Deployment-Hash');
      if (deploymentHash === null) {
        localStorage.setItem('deployment_hash', deploymentHash);
      } else {
        if(deploymentHash !== localStorage.getItem('deployment_hash')) {
          this.state.message({
            clientCCNotifications: 'UPDATE_CLIENT_CC_REQUIRED',
            clientCCDeploymentHash: deploymentHash
          });
        }
      }
    }
  }

  handleResponse(appendedRequest) {

    return map((event: HttpEvent<any>) => {
      if(appendedRequest.url.includes('files.sportsology') && appendedRequest.method === 'GET') {
        return event
      }

      if (event instanceof HttpResponse) {

        // Is response successful
        if (this.successFullResponse(event)) {

          const data: any = {};

          // Cache get requests
          if (appendedRequest.method === 'GET' && event.status === 200) {
            this.cache.set(appendedRequest.url, event, TTL);
            // Moved into get reqs as header is not sent on PATCH, PUT, DELETE and not sure why,
            // CORRs being hardwork from api
            this.deploymentNotification(event);
          }

          // PUT / PATCH generic responses if non went with response
          if (appendedRequest.method === 'PUT' || appendedRequest.method === 'PATCH') {
            data.message = (!!event.body && !!event.body.status)
              ? event.body.status
              : 'Updated successfully';

            if(! this.hideSnackBarNotifications) {
              this.snackBar.success(data);
            }
          }

          // POST generic responses if non went with response
          if (appendedRequest.method === 'POST') {
            data.message = (!!event.body && !!event.body.status)
              ? event.body.status
              : 'Created succesfully';

            if(! this.hideSnackBarNotifications) {
              this.snackBar.success(data);
            }
          }
        }
      }
      return event;
    })
  }

  handleUserSessionRequest(appendedRequest, next) {
    return next.handle(appendedRequest).pipe(
      this.handleResponse(appendedRequest)
    );
  }

  handleRequest(appendedRequest, next) {
    return next.handle(appendedRequest).pipe(
      this.handleResponse(appendedRequest),
      catchError(async (error: HttpErrorResponse) => {

        let data: any = {};
        let errors: Array<any> = [];
        let reasonTxt = '';

        if (error.status !== 403) {
          if (error.ok === false && !!this.modelTitle && error.status === 422) {

            reasonTxt = this.modelTitle + ' cannot be saved';
            if (error.error instanceof Object) {
              Object.keys(error.error).forEach(field_name => errors.push(error.error[field_name]));
            } else if (Array.isArray(error.error)) {
              errors = error.error;
            }

          } else {

            reasonTxt = error && error.statusText ? error.statusText : '';
            errors.push(error.error.error);

          }

          data = {
            reason: reasonTxt,
            status: error.status,
            errors: errors
          };

          // show error message
          this.snackBar.error(data);
        }

        // show message before hard logout
        if (error.status === 401) {
          setTimeout(() => this.router.navigateByUrl('logout'), 5000);
        } else if (error.status === 503) {
          setTimeout(() => this.router.navigateByUrl('logout'), 5000);
        } else {
          return throwError(error);
        }
      })
    );
  }

  /**
   * Was response successful?
   *
   * @param {HttpResponse<any>} event
   */
  successFullResponse(event: HttpResponse<any>) {
    return event.ok === true &&
      (event.status === 200 || event.status === 201 || event.status === 204);
  }

  /**
   * Append headers to all requests
   *
   * @param {HttpRequest<any>} request
   */
  appendRequestHeaders(request: HttpRequest<any>) {
    const appendedHeaders: any = { 'Content-Type': 'application/json' };
    appendedHeaders['JWT'] = localStorage.getItem('sessionToken');
    if(this.state.get('inApp') === true) {
      appendedHeaders['Is-App-Request'] = 'true';
    }
    return request.clone({ headers: new HttpHeaders(appendedHeaders), withCredentials: true });
  }

  /**
   * Append headers to refresh request
   *
   * @param {HttpRequest<any>} request
   */
  appendRefreshRequestHeaders(request: HttpRequest<any>) {
    const appendedHeaders: any = { 'Content-Type': 'application/json' };
    appendedHeaders['JWT'] = localStorage.getItem('refreshToken');
    if(this.state.get('inApp') === true) {
      appendedHeaders['Is-App-Request'] = 'true';
    }
    const refreshTokenRequest = request.clone({ headers: new HttpHeaders(appendedHeaders), withCredentials: true });
    refreshTokenRequest.params.delete('refreshingToken');
    return refreshTokenRequest;
  }
}
