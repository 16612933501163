import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { State } from '@app/state';
import * as moment from 'moment-timezone';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';

/**
* A moment timezone pipe to support parsing based on time zone abbreviations
* covers all cases of offset variation due to daylight saving.
*
* Same API as DatePipe with additional timezone abbreviation support
* Official date pipe dropped support for abbreviations names from Angular V5
*/
@Pipe({
  name: 'momentDate'
})
export class MomentDatePipe extends DatePipe implements PipeTransform {

  private _defaultTimezoneName: string;
  private _locale: string;

  constructor(
    @Inject(OWL_DATE_TIME_LOCALE) locale: string,
    @Inject(State) state: State
  ) {
    super(null);
    // Needs something passed so don't change

    this._defaultTimezoneName = state.get('defaultTimezone').name;
    this._locale = locale;
  }

  transform(
    value: any,
    format: any = 'mediumDate',
    timezone: any = this._defaultTimezoneName,
    locale: any = this._locale
  ): string {
    const valueUTC = moment.utc(value).valueOf();
    const timezoneOffset = moment(value).tz(timezone).format('Z');

    return super.transform(valueUTC, format, timezoneOffset, locale);
  }
}
