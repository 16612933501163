export const APPS_FILTER_FIELDS = [
  {
    type: 'text',
    name: 'name',
    label: 'Name',
    value: ''
  },
  {
    type: 'dropdown',
    name: 'published',
    label: 'Published',
    value: '',
    options: [
      {
        text: 'Yes',
        value: '1'
      },
      {
        text: 'No',
        value: '0'
      }
    ]
  }
];
