import { traitButton } from './shared';
import { CMD_OPEN_PAGE_ITEM_SELECTOR } from './../../const/commands';

export const PageFormItemSelector = function(editor, options) {
    return {
        name: options.name,
        noLabel: true,
        templateInput: '',
        getInputEl() {
            return traitButton({
                editor: editor,
                buttonText: options.buttonText,
                command: CMD_OPEN_PAGE_ITEM_SELECTOR,
                commandOptions: { itemType: options.itemType }
            });
        }
    }
}