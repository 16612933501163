import { Component, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiModel } from '@app/models/entities/api-model';
import { FormUtil } from '@app/modules/common/shared/util/form-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { DocumentService } from '@services/document.service';
import { SidebarService } from '@services/sidebar.service';
import { DOCUMENTS_FILTER_FIELDS } from './filter-fields';
@Component({
  selector: 'document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss'],
  providers: [SidebarService]
})

@Injectable()
export class DocumentViewComponent extends BaseComponent {
  public pageTitle = 'View Document';
  public document: object = {};
  public dataLoaded = false;
  public FormUtil = FormUtil;
  public fields: any = DOCUMENTS_FILTER_FIELDS;
  public parent_uuid = '';
  public baseUrl: string = '';
  constructor(
    public activatedRoute: ActivatedRoute,
    public documentService: DocumentService,
    public sidebarService: SidebarService,
    public state: State,
    public router: Router
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: documentService
    });
    this.setPageVars({
      sidebar: 'documents'
    });
    this.modelServiceGetOne(this.routeParams['id'], 'document').then(data => {
      this.dataLoaded = true;
    });
    const settings = this.state.get('ccSettings');
    this.baseUrl = settings.uploadcare.url;
    this.parent_uuid = this.routeParams['parent_uuid'];
  }

  public getFileUrl(document): string {
    let sourceUrl = '';
    const fileType = this.getDocumentType(document.file_type);
    if (document === undefined) { return; }
    if (fileType === FormUtil.Document.DOCUMENT_TYPE_IMAGE) {
      sourceUrl = `${this.baseUrl}${document.cdn_uuid}/-/preview/-/resize/150x/`;
    } else {
      sourceUrl = `${this.baseUrl}${document.cdn_uuid}/`;
    }
    return sourceUrl;
  }

  public getDocumentType(type: string): string {
    if (type === undefined) { return null; }
    if (type.indexOf(FormUtil.Document.DOCUMENT_TYPE_IMAGE) !== -1) {
      return FormUtil.Document.DOCUMENT_TYPE_IMAGE;
    } else if (type.indexOf(FormUtil.Document.DOCUMENT_TYPE_OFFICE) !== -1) {
      return FormUtil.Document.DOCUMENT_TYPE_OFFICE;
    } else if (type.indexOf(FormUtil.Document.DOCUMENT_TYPE_PDF) !== -1) {
      return FormUtil.Document.DOCUMENT_TYPE_PDF;
    } else if (type.indexOf(FormUtil.Document.DOCUMENT_TYPE_VIDEO) !== -1) {
      return FormUtil.Document.DOCUMENT_TYPE_VIDEO;
    }
  }

  public getViewer(type: string): string {
    if (type.indexOf(FormUtil.Document.DOCUMENT_TYPE_OFFICE) !== -1) {
      return 'office';
    } else if (type.indexOf(FormUtil.Document.DOCUMENT_TYPE_PDF) !== -1) {
      return 'google';
    }
  }

  public goBackParentListing(): string {
    return `/folder/view/${this.parent_uuid}`;
  }

  public onChangedBreadcrumb(item: ApiModel.Breadcrumb) {
    const url = `/folder/view/${item.folder_uuid}`;
    this.router.navigateByUrl(url,
      { skipLocationChange: true }).then(() => this.state.message({ slideoutShow: true }));
  }

}
