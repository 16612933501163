import { Component, Injectable, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FolderService } from '@app/services/folder.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { CoreFormAdvancedSelectComponent } from '@app/modules/common/core/components/form-advanced-select/core-form-advanced-select.component';
@Component({
  selector: 'folder-form',
  templateUrl: './folder-form.component.html',
  providers: [SidebarService]
})
export class FolderFormComponent extends BaseComponent {
  // Local variables
  folderFormGroup: FormGroup;
  usersCols: any;
  dataLoaded = false;
  formSubmitted = false;
  userIds = [];
  @ViewChild(CoreFormAdvancedSelectComponent, {static: false}) private advancedSelectUsersInput;
  constructor(
    public activatedRoute: ActivatedRoute,
    public folderService: FolderService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: folderService
    });

    this.setPageVars({
      sidebar: 'folders',
    });

    this.initialFormGroup();

    const id = this.routeParams['id'];

    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result) => {
        this.populateInitialData(result);
        this.folderFormGroup.patchValue(result);
        this.folderFormGroup.patchValue({
          send_as_email: null,
          send_as_sms: null,
          send_as_push: null,
        });
        this.dataLoaded = true;
      });
    }

    // Setup the columns settings for the multi-selection table (Users)
    this.usersCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` },
    ];
  }

  get controls(): any {
    return this.folderFormGroup.controls;
  }

  selectedIds(row): any {
    row.users.forEach(el => {
      this.userIds.push(el);
    });
    return this.userIds;
  }

  public cancel(): void {
    this.folderFormGroup.reset();
  }

  public save(): void {
    this.formSubmitted = true;
    const id = this.controls.id.value;
    const uuid = this.controls.uuid.value;
    this.replaceBooleanFieldsWithByte();

    if (this.controls.public.value === '0') {
      this.folderFormGroup.patchValue({'users': this.getSelectedUsers()});
    } else {
      this.folderFormGroup.patchValue({'users': [], 'user_circles': []});
    }

    this.folderFormGroup.patchValue({'parent_folder_uuid': this.getParentFolders()});

    if (this.folderFormGroup.get('parent_folder_uuid').value === null) {
      this.folderFormGroup.removeControl('parent_folder_uuid');
    }

    if (id || uuid) {
      // Update the existing folder
      this.folderService.patchById(uuid ? uuid : id, this.folderFormGroup.value)
        .subscribe(result => this.SavedMessage(result));
    } else {
      // Create the new folder
      this.folderService.create(this.folderFormGroup.value).subscribe(result => this.SavedMessage(result));
    }
    // Rollback the changes so all the checkboxes will bound correctly
    this.replaceByteFieldsWithBoolean();

    setTimeout (() => {
      this.formSubmitted = false;
    }, 2000);
  }

  // Use this method to validate the result from server after our request.
  private SavedMessage(data) {
    this.state.message({ ['FolderIndexUpdate']: true });
    this.closeSlideout();
  }

  // Replace all the boolean fields with byte
  private replaceBooleanFieldsWithByte(): void {
    this.folderFormGroup.patchValue({
      'send_as_email': this.controls.send_as_email.value ? '1' : '0',
      'send_as_push': this.controls.send_as_push.value ? '1' : '0',
      'send_as_sms': this.controls.send_as_sms.value ? '1' : '0',
      'public': this.controls.public.value ? '1' : '0'
    });
  }

  // Replace all the boolean fields with byte
  private replaceByteFieldsWithBoolean(): void {
    this.folderFormGroup.patchValue({
      'send_as_email': this.controls.send_as_email.value === '1' ? true : false,
      'send_as_push': this.controls.send_as_push.value === '1' ? true : false,
      'send_as_sms': this.controls.send_as_sms.value === '1' ? true : false,
      'public': this.controls.public.value === '1' ? true : false
    });
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  private getSelectedUsers(): any[] {
    const value: any[] = this.advancedSelectUsersInput.getSelectedIds();
    return value === null ? null : value;
  }

  private getParentFolders() {
    return this.activatedRoute.snapshot.paramMap.get('parent_uuid');
  }

  private populateInitialData(row): void {
    if (row !== undefined) {
      this.selectedIds(row);
    }
  }

  private initialFormGroup(): void {
    // setup the initial form for a folder entity
    this.folderFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      name: [null, [Validators.required]],
      send_as_email: [null],
      send_as_sms: [null],
      send_as_push: [null],
      public: [null],
      message: [null],
      body: [null],
      users: [],
      user_circles: [[]],
      parent_folder_uuid: [null]
    });
  }
}
