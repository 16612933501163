import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { ContentLogService } from '@services/content-log.service';
import { SidebarService } from '@services/sidebar.service';
import { CONTENT_LOGS_FILTER_FIELDS } from './filter-fields';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
@Component({
  selector: 'app-content-logs-index',
  templateUrl: './content-logs-index.component.html',
  styleUrls: [],
  providers: [SidebarService]
})
export class ContentLogsIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['select', 'avatar', 'created_by_user', 'created_at', 'subject_type', 'subject_title', 'action', 'actions'];
  public dataLoaded = false;
  public fields: any = CONTENT_LOGS_FILTER_FIELDS;
  public node: Breadcrumb;
  constructor(
    public contentLogService: ContentLogService,
    public router: Router,
    public sidebarService: SidebarService,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: contentLogService,
      router,
      sidebarService,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      sidebar: '',
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.node = {
      label: 'Logs',
      link: 'content-logs',
      title: 'Logs'
    };
    this.buildSettingsBreadcrumb(this.node);
  }
}
