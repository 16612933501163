import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CountryService } from '@app/services/country.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
@Component({
  selector: 'country-form',
  templateUrl: './country-form.component.html',
  styleUrls: ['./country-form.component.scss'],
  providers: [SidebarService]
})

export class CountryFormComponent extends BaseComponent {
  // Local variables
  countryFormGroup: FormGroup;
  dataLoaded = false;
  globalUtil = GlobalUtil;

  // Input parameters

  // Onput events

  // Contractor
  constructor(
    public activatedRoute: ActivatedRoute,
    public countryService: CountryService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: countryService
    });

    this.setPageVars({
      sidebar: ''
    });

    this.initialFormGroupSetup();
    const id = this.routeParams['id'];
    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result: any) => {
        this.dataLoaded = true;
        this.countryFormGroup.patchValue(result);
      });
    }
  }

  // All the public methods
  get controls(): any {
    return this.countryFormGroup.controls;
  }

  public save(): void {
    const id = this.controls.id.value;
    const uuid = this.controls.uuid.value;
    if (id || uuid) {
      // Update the existing row
      this.countryService.patchById(uuid ? uuid : id, this.countryFormGroup.value)
        .subscribe(result => {
          this.SavedMessage(result);
        });
    } else {
      // Create the new row
      this.countryService.create(this.countryFormGroup.value).subscribe(result => this.SavedMessage(result));
    }
  }

  public cancel(): void {
    this.countryFormGroup.reset();
  }

  // All the private methods
  private initialFormGroupSetup(): void {
    // setup the initial form for row entity
    this.countryFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      name: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      iso: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.ISO_FIELD_MAX_LENGTH)]],
      iso2: [null, [Validators.maxLength(GlobalUtil.Validation.ISO_FIELD_MAX_LENGTH)]]
    });
  }

  private SavedMessage(data) {
    this.state.message({ ['CountryIndexUpdate']: true });
    this.closeSlideout();
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }
}
