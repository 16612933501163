import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { UserNoteService } from '@services/user-note.service';
@Component({
  selector: 'user-notes-index',
  templateUrl: './user-notes-index.component.html',
  styleUrls: [],
  providers: [SidebarService],
})
export class UserNotesIndexComponent extends BaseComponent implements OnInit {
  @Input() uuid: string;
  public dataSource: any;
  public data: any;
  public dataLoaded = false;
  public displayedColumns = [
    'created_at',
    'body',
    // 'actions'
  ];

  constructor(
    public sidebarService: SidebarService,
    public router: Router,
    public state: State,
    public userNoteService: UserNoteService,
  ) {
    super();
    this.setObjects({
      modelService: userNoteService,
      sidebarService: sidebarService,
      router: router,
      state: state
    });
    this.setPageVars({
      listenForIndexUpdate: true
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.modelServiceGetAllWithId(this.uuid).then(() => (this.dataLoaded = true));
  }
}
