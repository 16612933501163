import {
  Defaults,
  Traits
} from './shared';

import { ICON_BUTTON_CALENDAR } from './../../../const/icons';

import { COMP_BUTTON_CALENDAR } from './../../../const/components';

import { FIELD_CALENDAR_SELECTOR } from './../../../const/traits';

import { PageFormLinkedItem } from './../page-form-linked-item';

export const Calendar = (editor, options:any = {}) => {

  (FIELD_CALENDAR_SELECTOR as any).at = 9;

  return PageFormLinkedItem(editor, {
    componentName: COMP_BUTTON_CALENDAR,
    displayIcon: ICON_BUTTON_CALENDAR,
    model: {
      defaults: { ...Defaults,
        name: 'Calendar',
        traits: [ ...Traits, FIELD_CALENDAR_SELECTOR]
      }
    }
  });
}