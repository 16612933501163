import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiModel } from '@app/models/entities/api-model';

@Component({
  selector: 'folder-thumbnail-view',
  templateUrl: './folder-thumbnail-view.component.html',
  styleUrls: ['./folder-thumbnail-view.component.scss']
})

export class FolderThumbnailViewComponent implements OnInit {
  @Input() folderItems;
  @Output() editFolder = new EventEmitter<ApiModel.Folder>();
  @Output() deleteFolder = new EventEmitter<ApiModel.Folder>();

  constructor() {
  }

  ngOnInit() {
  }

  public editItem(item: ApiModel.Folder) {
    if (this.editFolder.observers.length > 0) {
      this.editFolder.emit(item);
    }
  }

  public deleteItem(item: ApiModel.Folder) {
    if (this.deleteFolder.observers.length > 0) {
      this.deleteFolder.emit(item);
    }
  }
}
