import { paramCase } from 'change-case';
import { sentenceCase } from 'sentence-case';
import { titleCase } from 'title-case';

// Utils
import {
    iconElement,
    loadingSpinner
} from './editor/utils';

// Commands
import {
    CMD_GET_ITEM_SNAPSHOT,
    CMD_OPEN_FILE_SELECTOR,
    CMD_OPEN_ICON_SELECTOR,
    CMD_OPEN_IMAGE_SELECTOR,
    CMD_OPEN_INLINE_PAGE_ITEM_SELECTOR,
    CMD_OPEN_ITEM_PERMS_SELECTOR,
    CMD_OPEN_PAGE_ITEM_SELECTOR,
    CMD_OPEN_TEXT_EDITOR,
    CMD_PANEL_OPEN_COMPONENT_ITEMS,
    CMD_REMOVE_FROM_PAGE_FORM_ITEMS,
    CMD_REMOVE_FROM_PAGE_ITEM_PERMS,
    CMD_REMOVE_FROM_PAGE_SECTIONS,
    CMD_UPDATE_ITEMS_SNAPSHOTS,
    CMD_UPDATE_PAGE_FORM_ITEM_PERMS,
    CMD_UPDATE_PAGE_FORM_ITEMS,
    CMD_UPDATE_PAGE_SECTIONS
} from './const/commands';

// Editor commands
export const editorCommands = function() {
    const Commands = this.editor.Commands;

    // Open modals
    Commands.add(CMD_OPEN_ICON_SELECTOR, {
        run: (editor, sender, options:any = {}) => this.openIconSelector(options)
    });
    Commands.add(CMD_OPEN_FILE_SELECTOR, {
        run: (editor, sender, options:any = {}) => this.openFileSelector(options)
    });
    Commands.add(CMD_OPEN_IMAGE_SELECTOR, {
        run: (editor, sender, options:any = {}) => this.openImageSelector(options)
    });
    Commands.add(CMD_OPEN_ITEM_PERMS_SELECTOR, {
        run: (editor, sender, options:any = {}) => this.openItemPermsSelector(options)
    });
    Commands.add(CMD_OPEN_PAGE_ITEM_SELECTOR, {
        run: (editor, sender, options:any = {}) => this.openItemSelector(options)
    });
    Commands.add(CMD_OPEN_INLINE_PAGE_ITEM_SELECTOR, {
        run: (editor, sender, options:any = {}) => this.inlineItemDialog()
    });
    Commands.add(CMD_OPEN_TEXT_EDITOR, {
        run: (editor, sender, options:any = {}) => this.openTextEditor()
    });

    // Update formgroup
    Commands.add(CMD_REMOVE_FROM_PAGE_FORM_ITEMS, {
        run: (editor, sender, options:any = {}) => this.removeFromFormGroup(options)
    });
    Commands.add(CMD_UPDATE_PAGE_FORM_ITEMS, {
        run: (editor, sender, options:any = {}) => this.updateFormGroup(options)
    });

    // Update perms
    Commands.add(CMD_REMOVE_FROM_PAGE_ITEM_PERMS, {
        run: (editor, sender, options:any = {}) => this.removeFromPerms(options)
    });
    Commands.add(CMD_UPDATE_PAGE_FORM_ITEM_PERMS, {
        run: (editor, sender, options:any = {}) => this.updatePerms(options)
    });

    // Snapshots
    Commands.add(CMD_GET_ITEM_SNAPSHOT, {
        run: (editor, sender, options:any = {}) => this.getItemSnapshot(options)
    });
    Commands.add(CMD_UPDATE_ITEMS_SNAPSHOTS, {
        run: (editor, sender, options:any = {}) => this.updateItemsSnapshot(options)
    });

    // Page sections
    Commands.add(CMD_UPDATE_PAGE_SECTIONS, {
        run: (editor, sender, options:any = {}) => this.updatePageSections(options)
    });
    Commands.add(CMD_REMOVE_FROM_PAGE_SECTIONS, {
        run: (editor, sender, options:any = {}) => this.removeFromPageSections(options),
        stop: editor => console.log('testing')
    });

    // Panels
    Commands.add(CMD_PANEL_OPEN_COMPONENT_ITEMS, {
        run: async (editor, sender, options:any = {}) => {
            const Panels = editor.Panels;
            const componentItemsPanel = document.getElementById('panel-component-items');
            const componentItemsPanelInner = document.getElementById('panel-component-items-inner');
            const model = editor.getSelected();
            const attrs = model.getAttributes();

            componentItemsPanelInner.innerHTML = loadingSpinner();

            await this.pageService.getItemsSnapshot(attrs.itemUUID, model.attributes.itemType)
                .subscribe(itemsSnapshot => {
                    const itemPerm = this.itemPerms[attrs.uuid];
                    const hasPerms = itemPerm !== undefined;
                    let icon;

                    componentItemsPanelInner.innerHTML = '';
                    itemsSnapshot.forEach(itemSnapshot => {
                        let itemSnapshotEl = document.createElement('div');

                        icon = '';
                        if (
                            hasPerms &&
                            !! itemPerm.items &&
                            itemPerm.items
                                .findIndex(item => item.linkedItemUUID === itemSnapshot.uuid) !== -1
                        ) {
                            icon = iconElement('lock');
                        }

                        const itemType = !! itemSnapshot.type
                            ? '<br>' + titleCase(sentenceCase(itemSnapshot.type))
                            : '';
                        itemSnapshotEl.innerHTML = `
<span class="widget-item-snapshot-title">
    <strong>
        ${itemSnapshot.title}
    </strong>
    ${itemType}
</span>
${icon}`;
                        itemSnapshotEl.classList.add('widget-item-snapshot');
                        itemSnapshotEl.onclick = e => editor.Commands
                            .run(CMD_OPEN_ITEM_PERMS_SELECTOR, {
                                itemUUID: itemSnapshot.uuid, isItemOfParentItem: true,
                                itemType: itemSnapshot.type
                            });

                        componentItemsPanelInner.append(itemSnapshotEl);
                    });
                });

            componentItemsPanel.style.display = 'block';
        },
        // stop: () => {
        //     const componentItemsPanel = document.getElementById('panel-component-items');
        //     const componentItemsPanelInner = document.getElementById('panel-component-items-inner');

        //     componentItemsPanelInner.innerHTML = '';
        //     componentItemsPanel.style.display = 'none';
        // }
    });
}