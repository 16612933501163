import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ApiModel } from '@app/models/entities/api-model';
import {  Router } from '@angular/router';
import { FolderService } from '@app/services/folder.service';
@Component({
  selector: 'document-breadcrumb',
  templateUrl: './document-breadcrumb.component.html',
  styleUrls: ['./document-breadcrumb.component.scss']
})
export class DocumentBreadcrumbComponent implements OnInit, OnChanges {
  @Input() uuid;
  @Input() renderFirstDefaultNode = true;
  @Input() filterHeading = '';
  @Output() onChanged: EventEmitter<ApiModel.Breadcrumb> = new EventEmitter();
  public dataloaded = false;
  public breadcrumbs: ApiModel.Breadcrumb[] = [];
  constructor(
    public folderService: FolderService,
    public router: Router,
    ) { }

  ngOnInit() {
  }

  // Replaces `RouterLink` .. copied from base.component.ts
  public openLinkByUrl(url: string) {
    this.router.navigateByUrl(url);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.renderBreadcrumbElements(this.uuid);
  }

  public onChangedItem(item: ApiModel.Breadcrumb): void {
    if (this.onChanged.observers.length > 0) {
      this.onChanged.emit(item);
    }
  }

  private renderBreadcrumbElements(uuid): void {
    // Call the api to get the breadcrumb
    const url = `${uuid}/breadcrumb`;
    this.folderService.getOne(url).subscribe((res: ApiModel.Breadcrumb[]) => {
      this.breadcrumbs = res;
      this.dataloaded = true;
    });
  }

}
