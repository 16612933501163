import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ApiDataService } from '@services/api-data.service';
@Component({
  selector: 'sidebar-form-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: []
})
export class SidebarFormCheckboxComponent implements OnInit {
  @Input() apiDataSource = '';
  @Input() apiDataSourceCacheKey = '';
  @Input() dataSourceValue = 'uuid';
  @Input() dataSourceText = 'name';
  @Input() field: any = {};
  @Input() form: FormGroup;
  @Input() checkboxOptions = [];
  dataLoaded = false;
  public options: Array<any> = [];
  @Output() controlInitialized = new EventEmitter<boolean>();
  constructor(private apiDataService: ApiDataService) { }

  ngOnInit() {
    if (this.apiDataSource !== '' && typeof this.apiDataSource !== 'undefined') {
      this.apiDataService
        .getOptions(this.apiDataSource, this.apiDataSourceCacheKey)
        .subscribe((result: any) => this.convertObjectToValues(typeof result.data !== 'undefined' ? result.data : result));
    } else if (this.checkboxOptions && this.checkboxOptions.length > 0) {
      this.options = this.checkboxOptions;
      this.dataLoaded = true;
    }
  }
  convertObjectToValues(objectToParse) {
    objectToParse.forEach(object => {
      this.options.push({
        value: object[this.dataSourceValue],
        text: object[this.dataSourceText]
      });
    });
    this.options = this.options.sort((left, right): any => {
      if (left.text < right.text) {
        return -1;
      }
      if (left.text > right.text) {
        return 1;
      }
      return 0;
    });
    this.dataLoaded = true;
    this.addCheckboxes();
    this.controlInitialized.next(true);
  }

  private addCheckboxes() {
    const arr = new FormArray([]);
    this.options.forEach((o, i) => {
      const control = new FormGroup({
        id: new FormControl(o.value),
        selected: new FormControl(false)
      });
      arr.push(control);
    });
    this.form.addControl(this.field.name, arr);
  }
}
