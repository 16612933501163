import { Inject, Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FileInfo } from '@app/models/entities/fileInfo';
import { FIREBASE_REFERENCES } from '../../../../firebase.module';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { State } from '@app/state';
import { map } from 'rxjs/operators';

import { displayAsOptions, itemFloatOptions } from './const';

import { Environment } from '@environments/environment';

import {
  // Items
  COMP_BUTTON_APP_COMPONENT,
  COMP_BUTTON_APP_COMPONENT_OPTIONS_FOR_CORE_SELECT,
  COMP_BUTTON_APP_PAGE,
  COMP_BUTTON_ARTICLE,
  COMP_BUTTON_ARTICLE_CATEGORY,
  COMP_BUTTON_BUNDLE,
  COMP_BUTTON_CHAT,
  COMP_BUTTON_CALENDAR,
  COMP_BUTTON_CONTACT_GROUP,
  COMP_BUTTON_DIRECTORY_CATEGORY,
  COMP_BUTTON_DIRECTORY_ITEM,
  COMP_BUTTON_DOCUMENT,
  COMP_BUTTON_EVENT,
  COMP_BUTTON_FOLDER,
  COMP_BUTTON_PERIODISATION_CALENDAR,
  COMP_BUTTON_PROFILE_GROUP,
  COMP_BUTTON_USER_CIRCLE,
  COMP_SELECT_OPTIONS_FOR_CORE_SELECT,
} from './../const/components';

@Component({
  selector: 'item-selector-dialog',
  templateUrl: 'item-selector-dialog.component.html',
  styleUrls: ['item-selector-dialog.component.scss']
})
export class ItemSelectorDialogComponent implements OnInit, OnDestroy {
  @ViewChild('uploadComponent', {static: false}) uploadComponent: any;

  private appComponentOptions: any = [];
  private appPageCols: Array<any> = [
    { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
    { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` },
  ];
  private displayAsOptions:Array<any> = displayAsOptions;
  private inlineItemComponentOptions:Array<any>;
  private itemFloatOptions:Array<any> = itemFloatOptions;
  private productId:string;
  private chatDocRef;
  private chatDocRefSub$;

  public chatsList:Array<any> = [];
  public chatsListLoaded:boolean = false;
  public itemSelectFormGroup:FormGroup;
  public showInlineOptions:boolean = false;
  public fbPrefix:string = '';

  constructor(
    private dialogRef: MatDialogRef<ItemSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private formBuilder: FormBuilder,
    @Inject(FIREBASE_REFERENCES.TWO_FIRESTORE) private readonly chat: AngularFirestore,
    @Inject(State) private state,
  ) {
    const { displayAs, itemFloat, imageUUID, itemUUID, itemType, itemText, showInlineOptions } = data;
    var formGroup:any = { itemUUID: [itemUUID], itemType: [itemType]};

    if(Environment.stage) {
      this.fbPrefix = 'stage_';
    }

    this.appComponentOptions = COMP_BUTTON_APP_COMPONENT_OPTIONS_FOR_CORE_SELECT();

    if (showInlineOptions === true) {
      this.inlineItemComponentOptions = COMP_SELECT_OPTIONS_FOR_CORE_SELECT();
      this.showInlineOptions = true;

      formGroup.displayAs = [displayAs];
      formGroup.itemFloat = [itemFloat];
      formGroup.imageUUID = [imageUUID];
      formGroup.itemText = [itemText];
    }

    this.productId = state.get('productId');
    this.itemSelectFormGroup = this.formBuilder.group(formGroup);
  }

  ngOnInit(): void {
    if (this.itemType === COMP_BUTTON_CHAT) {
      this.chatDocRef = this.chat
        .collection(this.fbPrefix + 'chatgroup')
        .doc(this.productId)
        .collection('groups', ref => ref
          .orderBy('timeStamp', 'desc')
          // .where('IsPersonal', '==', false)
          // .where('isArchived', '==', false)
          );

      this.chatDocRefSub$ = this.chatDocRef.get()
        .subscribe(snapshot => this.mapSnapshotDocIds(snapshot));
      this.chatDocRef.snapshotChanges(snapshot => this.mapSnapshotDocIds(snapshot));
    }
  }

  private mapSnapshotDocIds(snapshot): void {
    this.chatsList = [];
    snapshot.docs.forEach(doc => {
      const data = doc.data();

      if(data.IsPersonal === false && data.isArchived === false) {
        this.chatsList.push({ value: doc.id, text: data.groupName });
      }
    });
    this.chatsListLoaded = true;
  }

  ngOnDestroy(): void {
    if (this.itemType === COMP_BUTTON_CHAT) {
      this.chatDocRefSub$.unsubscribe();
    }
  }

  setItemUUID(itemUUID) {
    this.itemSelectFormGroup.patchValue({ itemUUID: itemUUID });
  }

  setItemType(itemType) {
    this.itemSelectFormGroup.patchValue({ itemType: itemType });
  }

  setDisplayAs(displayAs) {
    this.itemSelectFormGroup.patchValue({ displayAs: displayAs });
  }

  setItemFloat(itemFloat) {
    this.itemSelectFormGroup.patchValue({ itemFloat: itemFloat });
  }

  saveAndCloseDialog() {
    const values: any = { itemUUID: this.controls.itemUUID.value };

    if (this.showInlineOptions) {
      values.imageUUID = this.controls.imageUUID.value;
      values.itemType = this.controls.itemType.value;
      values.itemText = this.controls.itemText.value;
      values.itemFloat = this.controls.itemFloat.value;
      values.displayAs = this.controls.displayAs.value;
    }

    this.dialogRef.close(values);
  }

  setImageDetails($fileInfo: FileInfo) {
    if ($fileInfo !== null && !! $fileInfo.uuid) {
      this.itemSelectFormGroup.patchValue({
        imageUUID: $fileInfo.uuid,
        imageDetails: {
          'file_family': $fileInfo.fileFamily,
          'file_name': $fileInfo.filename,
          'file_size': $fileInfo.filesize + '',
          'width': $fileInfo.width,
          'height': $fileInfo.height,
          'file_type': $fileInfo.mimeType,
          'file_extension': $fileInfo.filename.split('?')[0].split('.').pop(),
          'cdn_uuid': $fileInfo.uuid
        }
      });
    }
  }

  get saveDisabled() {
    return this.controls.itemUUID.value === null || this.controls.itemType.value === null;
  }

  get controls() { return this.itemSelectFormGroup.controls; }

  get itemType() { return this.controls.itemType.value; }

  get title() {
    if (this.showInlineOptions && !this.itemType) {
      return 'Inline Item';
    }
    if (this.itemType === COMP_BUTTON_APP_COMPONENT) {
      return 'Select an App Component';
    }
    if (this.itemType === COMP_BUTTON_APP_PAGE) {
      return 'Select an App Page';
    }
    if (this.itemType === COMP_BUTTON_ARTICLE) {
      return 'Select an Article';
    }
    if (this.itemType === COMP_BUTTON_ARTICLE_CATEGORY) {
      return 'Select an Article Category';
    }
    if (this.itemType === COMP_BUTTON_BUNDLE) {
      return 'Select a Bundle';
    }
    if (this.itemType === COMP_BUTTON_CHAT) {
      return 'Select a Chat';
    }
    if (this.itemType === COMP_BUTTON_CALENDAR) {
      return 'Select a Contact Calendar';
    }
    if (this.itemType === COMP_BUTTON_CONTACT_GROUP) {
      return 'Select a Contact Group';
    }
    if (this.itemType === COMP_BUTTON_DIRECTORY_CATEGORY) {
      return 'Select a Directory Category';
    }
    if (this.itemType === COMP_BUTTON_DIRECTORY_ITEM) {
      return 'Select a Directory Item';
    }
    if (this.itemType === COMP_BUTTON_DOCUMENT) {
      return 'Select a Document';
    }
    if (this.itemType === COMP_BUTTON_EVENT) {
      return 'Select an Event';
    }
    if (this.itemType === COMP_BUTTON_FOLDER) {
      return 'Select a Folder';
    }
    if (this.itemType === COMP_BUTTON_PERIODISATION_CALENDAR) {
      return 'Select a Periodisation Calendar';
    }
    if (this.itemType === COMP_BUTTON_PROFILE_GROUP) {
      return 'Select a Profile Group';
    }
    if (this.itemType === COMP_BUTTON_USER_CIRCLE) {
      return 'Select a User Circle';
    }
  }

  get itemSelectLabel() {
    if (this.showInlineOptions && !this.itemType) {
      return 'Inline Item';
    }
    if (this.itemType === COMP_BUTTON_APP_COMPONENT) {
      return 'App Component';
    }
    if (this.itemType === COMP_BUTTON_APP_PAGE) {
      return 'App Page';
    }
    if (this.itemType === COMP_BUTTON_ARTICLE) {
      return 'Article';
    }
    if (this.itemType === COMP_BUTTON_ARTICLE_CATEGORY) {
      return 'Article Category';
    }
    if (this.itemType === COMP_BUTTON_BUNDLE) {
      return 'Bundle';
    }
    if (this.itemType === COMP_BUTTON_CALENDAR) {
      return 'Contact Calendar';
    }
    if (this.itemType === COMP_BUTTON_CONTACT_GROUP) {
      return 'Contact Group';
    }
    if (this.itemType === COMP_BUTTON_DIRECTORY_CATEGORY) {
      return 'Directory Category';
    }
    if (this.itemType === COMP_BUTTON_DIRECTORY_ITEM) {
      return 'Directory Item';
    }
    if (this.itemType === COMP_BUTTON_DOCUMENT) {
      return 'Document';
    }
    if (this.itemType === COMP_BUTTON_EVENT) {
      return 'Event';
    }
    if (this.itemType === COMP_BUTTON_FOLDER) {
      return 'Folder';
    }
    if (this.itemType === COMP_BUTTON_PERIODISATION_CALENDAR) {
      return 'Periodisation Calendar';
    }
    if (this.itemType === COMP_BUTTON_PROFILE_GROUP) {
      return 'Profile Group';
    }
    if (this.itemType === COMP_BUTTON_USER_CIRCLE) {
      return 'User Circle';
    }
  }

  get listUrl() {
    if (this.itemType === COMP_BUTTON_APP_PAGE) {
      return 'apps-v2/pages-list';
    }
    if (this.itemType === COMP_BUTTON_ARTICLE) {
      return 'articles/list';
    }
    if (this.itemType === COMP_BUTTON_ARTICLE_CATEGORY) {
      return 'article-categories/list';
    }
    if (this.itemType === COMP_BUTTON_BUNDLE) {
      return 'bundles/list';
    }
    if (this.itemType === COMP_BUTTON_CALENDAR) {
      return 'calendars/list';
    }
    if (this.itemType === COMP_BUTTON_CONTACT_GROUP) {
      return 'contact-groups/list';
    }
    if (this.itemType === COMP_BUTTON_DIRECTORY_CATEGORY) {
      return 'directory-categories/list';
    }
    if (this.itemType === COMP_BUTTON_DIRECTORY_ITEM) {
      return 'directory-items/list';
    }
    if (this.itemType === COMP_BUTTON_DOCUMENT) {
      return 'documents/list';
    }
    if (this.itemType === COMP_BUTTON_EVENT) {
      return 'events/list';
    }
    if (this.itemType === COMP_BUTTON_FOLDER) {
      return 'folders/list';
    }
    if (this.itemType === COMP_BUTTON_PERIODISATION_CALENDAR) {
      return 'calendars/periodization/list';
    }
    if (this.itemType === COMP_BUTTON_PROFILE_GROUP) {
      return 'contact-groups/list';
    }
    if (this.itemType === COMP_BUTTON_USER_CIRCLE) {
      return 'user-circles/users/list';
    }
  }

  get dataSourceText() {
    if (this.itemType === COMP_BUTTON_ARTICLE) {
      return 'title';
    }
    if (this.itemType === COMP_BUTTON_BUNDLE) {
      return 'title';
    }
  }
}
