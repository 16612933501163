import { Component, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { ActivityService } from '@services/activity.service';
import { SidebarService } from '@services/sidebar.service';
@Component({
  selector: 'app-activity-view',
  templateUrl: './activity-view.component.html',
  styleUrls: ['./activity-view.component.scss'],
  providers: [SidebarService]
})
@Injectable()
export class ActivityViewComponent extends BaseComponent {
  public activity: {
    subject_type: string,
    created_at: string,
    description: string,
    properties: any,
  };
  constructor(
    public activatedRoute: ActivatedRoute,
    public activityService: ActivityService,
    public sidebarService: SidebarService,
    public state: State
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: activityService
    });
    this.setPageVars({
      sidebar: ''
    });
    this.modelServiceGetOne(this.routeParams['id'], 'activity');
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }
}
