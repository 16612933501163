import { traitButton } from './shared';
import { CMD_OPEN_ITEM_PERMS_SELECTOR } from './../../const/commands';

export const ItemPermsSelector = function(editor, options:any = {}) {
    return {
        name: options.name,
        noLabel: true,
        templateInput: '',
        getInputEl() {
            return traitButton({
                editor: editor,
                buttonText: 'Permissions',
                command: CMD_OPEN_ITEM_PERMS_SELECTOR
            });
        }
    }
}