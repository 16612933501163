import { Component, Input } from '@angular/core';
import { State } from '@app/state';
@Component({
  selector: 'day-calendar',
  templateUrl: './day-calendar.component.html',
  styleUrls: ['./day-calendar.component.scss']
})
export class DayCalendarComponent {

  @Input() stateKey = '';

  public title = '';
  public events: Array<object> = [];
  public _date: Date;
  public hours: Array<any>;
  public dateISOString = '';

  constructor(private state: State) {
    this.state.subscription().subscribe(s => {
      if (s[this.stateKey]) {
        this.events = s[this.stateKey].events;
        this.title = s[this.stateKey].title;
        this._date = s[this.stateKey].date;
        this.dateISOString = s[this.stateKey].date.toISOString().substr(0, 10);
      }
    });
    this.hours = [1, 2, 3, 4, 5, 6, 7];
  }
}
