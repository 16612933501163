import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FileInfo } from '@app/models/entities/fileInfo';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { BundleItemService } from '@app/services/bundle-item.service';
@Component({
  selector: 'app-bundle-item-form',
  templateUrl: './bundle-item-form.component.html',
  styleUrls: ['./bundle-item-form.component.scss']
})

export class BundleItemFormComponent extends BaseComponent {
  bundleItemFormGroup: FormGroup;
  dataLoaded = false;
  formSubmitted = false;
  private config = GlobalUtil.Configuration;
  constructor(
    public activatedRoute: ActivatedRoute,
    public bundleItemService: BundleItemService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      state: state,
      modelService: bundleItemService
    });
    this.initialFormGroup();
    const id = this.routeParams['itemid'];
    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result: any) => {
        this.dataLoaded = true;
        this.bundleItemFormGroup.patchValue(result);
        this.bundleItemFormGroup.patchValue({
          'bundle_uuid': result.bundle.uuid,
          'bundle_item_type': result.bundle_item_type
        });
      });
    }
  }

  // Listens for FileInfo from the upload component
  public getUploadedFileInfo($fileInfo: FileInfo) {
    if ($fileInfo) {
      this.bundleItemFormGroup.patchValue({
        'featured_image_cdn_uuid': $fileInfo.uuid,
        'featured_image_cdn_url': $fileInfo.cdnUrl,
        'featured_image_width': $fileInfo.width,
        'featured_image_height': $fileInfo.height,
      });
    } else {
      this.bundleItemFormGroup.patchValue({
        'featured_image_cdn_uuid': null,
        'featured_image_cdn_url': null,
        'featured_image_width': null,
        'featured_image_height': null,
      });
    }
  }

  get controls(): any {
    return this.bundleItemFormGroup.controls;
  }

  public save(): void {
    const id = this.controls.id.value;
    const uuid = this.controls.uuid.value;
    this.formSubmitted = true;
    this.bundleItemFormGroup.patchValue({
      published: !!this.controls.published.value,
      open_in_app_browser: !!this.controls.open_in_app_browser.value,
    });
    if (id || uuid) {
      this.bundleItemService.patchById(uuid ? uuid : id, this.bundleItemFormGroup.value).subscribe(result => this.savedMessage(result));
    } else {
      this.bundleItemService.create(this.bundleItemFormGroup.value).subscribe(result => this.savedMessage(result));
    }
    setTimeout (() => {
      this.formSubmitted = false;
    }, 1000);
  }

  private savedMessage(data): void {
    this.state.message({ ['BundleItemIndexUpdate']: true });
    this.closeSlideout();
    this.state.message({
      ['BundleViewUpdate']: true,
      ['id']: this.controls.bundle_uuid.value
    });
  }

  public closeSlideout(): void {
    this.state.message({ slideoutHide: true });
  }

  private initialFormGroup(): void {
    this.bundleItemFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      title: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      bundle_uuid: [null, [Validators.required]],
      teaser: [null],
      description: [null],
      url: [null],
      bundle_item_type: [null],
      featured_image_cdn_uuid: [null],
      featured_image_cdn_url: [null],
      featured_image_width: [null],
      featured_image_height: [null],
      published: [true],
      open_in_app_browser: [true]
    });
  }

}
