const apiBase = 'stage-api.sportsology.pro';
export const Environment = {
  apiBase: apiBase,
  apiBaseURL: 'https://' + apiBase + '/',
  apiURL: 'https://' + apiBase + '/api/v2/',
  filesURL: 'https://' + apiBase.replace('api.', 'files.') + '/v1/',
  ccUrl: 'stage-app.sportsology.pro',
  production: true,
  stage: true,
  spIdURL: 'https://stage-id.sportsology.pro',
  spIdApiURL: 'https://stage-id.sportsology.pro/api/',
  // uploadCarePublicKey: 'f8bc76e827dbedf412d2',
  // uploadCarePrivateKey: '558f40cfa1c81f261570',
  firebaseConfig: {
    'MAIN': {
      apiKey: 'AIzaSyD4TGga-rKiKWcPX-3pf1Ck7mpkxv18f-o',
      authDomain: 'sportsology-frontend.firebaseapp.com',
      databaseURL: 'https://sportsology-frontend.firebaseio.com',
      projectId: 'sportsology-frontend',
      storageBucket: 'sportsology-frontend.appspot.com',
      messagingSenderId: '93808099499',
      appId: '1:93808099499:web:8d30003545dce54d',
    },
    'CHAT': {
      apiKey: 'AIzaSyCom_YDCbgyJjUrxwFVWn7yrrhLg0eHf4w',
      authDomain: 'ss-app-android-1516119854525.firebaseapp.com',
      databaseURL: 'https://ss-app-android-1516119854525.firebaseio.com',
      projectId: 'ss-app-android-1516119854525',
      storageBucket: 'ss-app-android-1516119854525.appspot.com',
      messagingSenderId: '759767221848',
      appId: '1:759767221848:web:1895515a89e6672cd8ac2d',
      measurementId: 'G-7T2CLT0J0X',
    },
    'CHAT-STAGE': {
      apiKey: "AIzaSyBq-etP-q-gfOJmBC1LNBmqJ08C6dfq48o",
      authDomain: "sportsology-frontend-stage.firebaseapp.com",
      databaseURL: 'https://sportsology-frontend-stage.firebaseio.com',
      projectId: "sportsology-frontend-stage",
      storageBucket: "sportsology-frontend-stage.appspot.com",
      messagingSenderId: "959245014422",
      appId: "1:959245014422:web:2c77c8f1430f2e53c039b7"
    },
  },
  googleApis: {
    googleMapsApiKey: 'AIzaSyB3KLn0UN4VSTF8kNYmPyhhX4ZFOiAbfHo',
    googleGeocodeApiKey: 'AIzaSyDFqmZJ5FSpJ1U09T--1f7Ft0awJMsBu38'
  }
};

