import { Inject, Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { GroupService } from '@services/group.service';

@Component({
  selector: 'item-perms-selector-dialog',
  templateUrl: 'item-perms-selector-dialog.component.html',
  styleUrls: ['item-perms-selector-dialog.component.scss']
})
export class ItemPermsSelectorDialogComponent
{
    private perms:any = [];
    private selectedGroupIndex:number = 0;
    private userGroups:any = [];

    public dataReady:boolean = false;
    public permsEnabled:boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<ItemPermsSelectorDialogComponent>,
        private groupService: GroupService
    ) {
        this.perms = data.perms;
        this.permsEnabled = data.permsEnabled;

        // Bug fix for old perms object not being an array
        if (typeof this.perms === 'object' && ! Array.isArray(this.perms)) {
            this.perms = [];
        }
    }

    get selectedGroup() {
        return this.userGroups[this.selectedGroupIndex];
    }

    get hasPerms() {
        var hasPerms = false;
        this.userGroups.map(userGroup => {
            if (userGroup.hasGroupPerm || userGroup.hasUserPerms) {
                hasPerms = true;
            }
        });
        return hasPerms;
    }

    ngOnInit() {
        this.groupService.userGroups().subscribe(userGroups => {
            this.userGroups = userGroups;
            this.mapUserGroupsToItemsPerms();
            this.dataReady = true;
            this.selectGroup();
        });

    }

    mapUserGroupsToItemsPerms() {
        this.userGroups.map(userGroup => {

            // Correct .list to .users
            userGroup.users = userGroup.list === undefined
                ? []
                : userGroup.list;
            delete userGroup.list;

            if (this.perms === undefined) {
                userGroup = this.nullUserGroup(userGroup);
            } else {
                const permGroup = this.perms.find(permGroup => permGroup.uuid === userGroup.uuid);

                if (permGroup === undefined) {
                    userGroup = this.nullUserGroup(userGroup);
                } else {
                    const { enableForGroup, disableForGroup } = permGroup;

                    userGroup.enableForGroup = enableForGroup;
                    userGroup.disableForGroup = disableForGroup;
                    userGroup.users.map(userGroupUser => {
                        const permGroupUser = permGroup.enabledUsers
                            .find(enabledUserUUID => enabledUserUUID === userGroupUser.uuid);

                        userGroupUser.enabled = permGroupUser !== undefined;
                        return userGroupUser;
                    });
                }
            }

            return userGroup;
        });
        this.processUserGroups();
    }

    nullUserGroup(userGroup) {
        userGroup.enableForGroup = false;
        userGroup.disableForGroup = false;
        userGroup.users.map(user => {
            user.enabled = false;
            return user;
        });
        return userGroup;
    }

    selectGroup(index = 0) {
        this.selectedGroupIndex = index;
    }

    clearPerms() {
        this.userGroups.map(userGroup => {
            userGroup.usersEnabledNone = true;
            userGroup.usersEnabledAll = false;
            userGroup.usersEnabledSome = false;
            userGroup.hasGroupPerm = false;
            userGroup.hasUserPerms = false;
            userGroup.enableForGroup = false;
            userGroup.disableForGroup = false;
            userGroup.users.map(user => {
                user.enabled = false;
                return user;
            });
            return userGroup;
        });
        this.processSelectedGroupAfterUserSelection();
    }

    setPermsForSave() {
        var perms = [];
        if (this.permsEnabled) {
            this.userGroups.forEach(userGroup => {
                if (userGroup.hasGroupPerm || userGroup.hasUserPerms) {
                    var userGroupPerms:any = {};

                    userGroupPerms.enabledUsers = [];
                    userGroupPerms.uuid = userGroup.uuid;
                    userGroupPerms.enableForGroup = userGroup.enableForGroup;
                    userGroupPerms.disableForGroup = userGroup.disableForGroup;
                    userGroup.users.forEach(user => {
                        if (user.enabled) {
                            userGroupPerms.enabledUsers.push(user.uuid);
                        }
                    });

                    perms.push(userGroupPerms);
                }
            });
        } else {
            this.clearPerms();
        }
        this.perms = perms;
    }

    saveAndCloseDialog() {
        this.setPermsForSave();
        this.dialogRef.close({ perms: this.perms, permsEnabled: this.permsEnabled });
    }

    enableForGroupChange(event) {
        this.selectedGroup.enableForGroup = event.checked;
        if (event.checked) {
            this.selectedGroup.disableForGroup = false;
        }
        this.selectedGroupPerm();
    }

    enableForAllGroups() {
        this.userGroups.map(group => {
            group.enableForGroup = true;
            group.disableForGroup = false;
        });
        this.processUserGroups();
    }

    disableForGroupChange(event) {
        this.selectedGroup.disableForGroup = event.checked;
        if (event.checked) {
            this.selectedGroup.enableForGroup = false;
        }
        this.selectedGroupPerm();
    }

    selectedGroupPerm() {
        this.selectedGroup.hasGroupPerm = this.hasGroupPerm(this.selectedGroup);
    }

    hasGroupPerm(userGroup) {
        return userGroup.enableForGroup || userGroup.disableForGroup;
    }

    hasUserPerms(userGroup) {
        const enabledUsers = userGroup.users.filter(user => user.enabled === true);
        return ! userGroup.hasGroupPerm && enabledUsers.length > 0;
    }

    selectAllUsersChange(event) {
        this.selectedGroup.users.map(user => user.enabled = event.checked);
        this.processSelectedGroupAfterUserSelection();
    }

    selectUserChange(event, index) {
        this.selectedGroup.users[index].enabled = event.checked;
        this.processSelectedGroupAfterUserSelection();
    }

    processSelectedGroupAfterUserSelection() {
        this.userGroups[this.selectedGroupIndex] = this.processUserGroup(this.selectedGroup);
    }

    processUserGroups() {
        this.userGroups.map(userGroup => {
            this.processUserGroup(userGroup);
        });
    }

    processUserGroup(userGroup) {
        const usersCount = userGroup.count;
        const selectedUsersCount = userGroup.users.filter(user => user.enabled).length;
        const usersEnabledAll =
            (selectedUsersCount > 0 && usersCount > 0) &&
            (selectedUsersCount === usersCount);

        userGroup.usersEnabledAll = usersEnabledAll;
        userGroup.usersEnabledSome = selectedUsersCount > 0 && ! usersEnabledAll;
        userGroup.usersEnabledNone = selectedUsersCount === 0;
        userGroup.usersEnabledCount = selectedUsersCount;
        userGroup.hasGroupPerm = this.hasGroupPerm(userGroup);
        userGroup.hasUserPerms = this.hasUserPerms(userGroup);

        return userGroup;
    }

    enableForUserChange(event, index) {
        this.selectedGroup.users[index].enabled = event.checked;
    }

    enablePerms() {
       this.permsEnabled = true;
    }

    disablePerms() {
       this.permsEnabled = false;
    }
}
