  import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
import { Router } from '@angular/router';
@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: [
    './breadcrumb.component.scss',
    './breadcrumb-700.component.scss'
  ]
})
export class BreadcrumbComponent implements OnInit {
  @Input() nodes: Breadcrumb[] = [];
  @Output() onClicked = new EventEmitter<Breadcrumb>();
  constructor(public router: Router) { }

  ngOnInit() {
  }

  public onChangedItem(item: Breadcrumb): void {
    if (this.onClicked.observers.length > 0) {
      this.onClicked.emit(item);
    }
    this.router.navigateByUrl(item.link);
  }
}
