import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { CustomFieldService } from '@app/services/custom-field.service';

@Component({
  selector: 'custom-field-group-sort-fields',
  templateUrl: './custom-field-group-sort-fields.component.html',
  styleUrls: [],
})

export class CustomFieldGroupSortFieldsComponent extends BaseComponent implements OnInit {

  public dataLoaded = false;
  public datasource: [];
  public items: [];
  constructor(
    public activatedRoute: ActivatedRoute,
    public customFieldService: CustomFieldService,
    public state: State,
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      state: state,
      modelService: customFieldService,
    });
  }

  ngOnInit(): void {
    this.modelServiceGetOne(this.routeParams['groupId'], 'customFields')
      .then(() => {
        this.datasource = this.dataSourceRows.custom_fields;
        this.dataLoaded = true;
      });
  }

  drop($event: CdkDragDrop<string[]>) {
    moveItemInArray(this.datasource, $event.previousIndex, $event.currentIndex);
    const items = [];
    this.datasource.forEach(function (item) {
      items.push(item['uuid']);
    });
    this.customFieldService.sortRecords(items).subscribe();
  }

  public closeSlideout() {
    this.state.message({
      ['CustomFieldGroupViewUpdate']: true,
      ['id']: this.routeParams['groupId'],
      slideoutHide: true
    });
  }
}
