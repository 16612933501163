import { Component, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { UserCircleService } from '@app/services/user-circle.service';
import { SidebarService } from '@services/sidebar.service';

@Component({
  selector: 'app-user-circle-view',
  templateUrl: './user-circle-view.component.html',
  styleUrls: [],
  providers: [SidebarService]
})
@Injectable()
export class UserCircleViewComponent extends BaseComponent {

  public userCircle: {
    name: string,
    uuid: string,
    created_by_user: any,
    created_user: any,
    created_at: string,
    userCount: number,
    users: any
  };
  public dataLoaded = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public userCircleService: UserCircleService,
    public sidebarService: SidebarService,
    public state: State,
    public router: Router
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService,
      state,
      router,
      modelService: userCircleService
    });
    this.setPageVars({
      sidebar: ''
    });
    this.modelServiceGetOne(this.routeParams['id'], 'userCircle').then(() => {
      this.dataLoaded = true;
    });
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }
}
