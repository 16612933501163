import { Validators } from '@angular/forms';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';

import { BasePage } from '../base-page'

export class AppPage implements BasePage
{
    constructor(private Page) {}

    // Initial form group
    public initialFormGroup(): void {
        this.Page.pageFormGroup = this.Page.formBuilder.group({
            app_page_style_slug: ['default', [Validators.required]],
            app_page_type_slug: ['continuous', [Validators.required]],
            app_v2_uuid: [this.Page.routeParams['app_id']],
            body: [null],
            perms: [null],
            buttons: this.Page.formBuilder.array([]),
            deeplinkable: [false],
            featured_image_cdn_uuid: [null],
            internal_title: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
            name: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
            published: [false],
            style: [null],
            teaser: [null],
            uuid: [null],

            // Related items
            article_categories: [[]],
            articles: [[]],
            bundles: [[]],
            calendars: [[]],
            contact_groups: [[]],
            directory_items: [[]],
            directory_categories: [[]],
            documents: [[]],
            events: [[]],
            folders: [[]],
            images: [[]],
            periodisation_calendars: [[]],
            profile_groups: [[]],
            user_circles: [[]]
        });
    }

    // Init AppPage
    public onInit(): Promise<any> {
        return new Promise((resolve, reject) => {
            const id = this.Page.routeParams['id'];
            if (id === undefined) {
                this.Page.appUUID = this.Page.routeParams['app_id'];
                this.Page.modelService.getFromApi('app-v2/' + this.Page.appUUID).subscribe((result: any) => {
                    this.Page.app = result;
                    resolve(null);
                });
            } else {
                this.Page.modelService.getFromApi('page/edit/' + id).subscribe((result: any) => {
                    this.Page.appUUID = result.app_v2.uuid;
                    this.Page.app = result.app_v2;
                    this.Page.pageFormGroup.patchValue(result);
                    this.Page.pageFormGroup.patchValue({
                        articles: result.articles,
                        article_categories: result.article_categories,
                        bundles: result.bundles,
                        directory_categories: result.directory_categories,
                        directory_items: result.directory_items,
                        documents: result.documents,
                        events: result.events,
                        folders: result.folders,
                        user_circles: result.user_circles
                    });
                    this.Page.pageFormGroup.patchValue({app_v2_uuid: result.app_v2.uuid});
                    this.Page.showCreatePageButton = true;
                    // App pages
                    result.app_pages_with_attributes.forEach(appPageItem =>
                        this.Page.snapshots.appPages[appPageItem.uuid] = appPageItem
                    );
                    // Articles
                    result.articles_with_attributes.forEach(articleItem =>
                        this.Page.snapshots.articles[articleItem.uuid] = articleItem
                    );
                    // Articles categories
                    result.article_categories_with_attributes.forEach(articleCategoryItem =>
                        this.Page.snapshots.articleCategories[articleCategoryItem.uuid] = articleCategoryItem
                    );
                    // Bundles
                    result.bundles_with_attributes.forEach(bundleItem =>
                        this.Page.snapshots.bundles[bundleItem.uuid] = bundleItem
                    );
                    // Calendars
                    result.calendars_with_attributes.forEach(calendarItem =>
                        this.Page.snapshots.calendars[calendarItem.uuid] = calendarItem
                    );
                    // Contact groups
                    result.contact_groups_with_attributes.forEach(contactGroupItem =>
                        this.Page.snapshots.contactGroups[contactGroupItem.uuid] = contactGroupItem
                    );
                    // Directory categories
                    result.directory_categories_with_attributes.forEach(directoryCategory =>
                        this.Page.snapshots.directoryCategories[directoryCategory.uuid] = directoryCategory
                    );
                    // Directory items
                    result.directory_items_with_attributes.forEach(directoryItemItem =>
                        this.Page.snapshots.directoryItems[directoryItemItem.uuid] = directoryItemItem
                    );
                    // Documents
                    result.documents_with_attributes.forEach(documentItem =>
                        this.Page.snapshots.documents[documentItem.uuid] = documentItem
                    );
                    // Events
                    result.events_with_attributes.forEach(eventItem =>
                        this.Page.snapshots.events[eventItem.uuid] = eventItem
                    );
                    // Folders
                    result.folders_with_attributes.forEach(folderItem =>
                        this.Page.snapshots.folders[folderItem.uuid] = folderItem
                    );
                    // Periodisation calendars
                    result.periodisation_calendars_with_attributes.forEach(periodisationCalendarItem =>
                        this.Page.snapshots.calendars[periodisationCalendarItem.uuid] = periodisationCalendarItem
                    );
                    // Profile groups
                    result.profile_groups_with_attributes.forEach(profileGroupItem =>
                        this.Page.snapshots.profileGroups[profileGroupItem.uuid] = profileGroupItem
                    );
                    // User circles
                    result.user_circles_with_attributes.forEach(userCircleItem =>
                        this.Page.snapshots.userCircles[userCircleItem.uuid] = userCircleItem
                    );
                    resolve(result);
                });
            }
        });
    }

    // Handle checkboxes before save
    private replaceBooleanFieldsWithByte(): void {
        this.Page.pageFormGroup.patchValue({
            published: this.Page.controls.published.value ? '1' : '0',
            deeplinkable: this.Page.controls.deeplinkable.value ? '1' : '0'
        });
    }

    // Handle checkboxes after save
    private replaceByteFieldsWithBoolean(): void {
        this.Page.pageFormGroup.patchValue({
            published: this.Page.controls.published.value === '1' ? true : false,
            deeplinkable: this.Page.controls.deeplinkable.value === '1' ? true : false
        });
    }

    // Before save
    public beforeSave(close = false): void {
        // this.replaceBooleanFieldsWithByte();
    }

    // After save
    public afterSave(close = false): void {
        // this.replaceByteFieldsWithBoolean();
        this.refreshAppStructure();
    }

    // Init editor commands
    public initEditorCommands(): void {
    }

    // Init editor traits
    public initEditorTraits(): void {
    }

    // Refresh app structure
    private refreshAppStructure() {
        this.Page.modelService.getFromApi('app-v2/' + this.Page.appUUID)
            .subscribe((result: any) => this.Page.app = result);
    }
}