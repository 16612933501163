import { uuidv4 } from './../../utils';
import { paramCase } from 'change-case';
import { fixInlineAttrs, modelSetClass } from './../../utils';

import { cacheInjectImageDataUrl, iconElement } from './../../utils';

import {
    COMP_BUTTON_ARTICLE,
    COMP_LAYOUT_ROW,
    COMP_LOREM_IPSUM,
    COMP_SELECT_OPTIONS,
    COMP_TEXT_BLOCK
} from './../../../const/components';

import {
    // FIELD_ROUNDED,
    // FIELD_SEND_CURRENT_USER_TOKEN,
    FIELD_BUTTON_SIZE,
    FIELD_BUTTON_STYLE,
    FIELD_CURRENT_IMAGE_URL,
    FIELD_DISABLED,
    FIELD_DISPLAY_AS,
    FIELD_FILE_SELECTOR,
    FIELD_FLOAT_ITEM,
    FIELD_FULL_WIDTH,
    FIELD_ICON_SELECTOR,
    FIELD_IMAGE_SELECTOR,
    FIELD_INLINE_ITEM_SELECTOR,
    FIELD_JUSTIFY_ITEM,
    FIELD_OPEN_IN_APP_BROWSER,
    FIELD_SEMI_TRANSPARENT,
    FIELD_TITLE,
    FIELD_URL,
    FIELD_WRAP_TEXT_TO_NEXT_LINE
} from './../../../const/traits';

export const inlineIconOnRender = function(editor) {
    var modelClass = 'inline-icon';
    const attrs = fixInlineAttrs(this.model.getAttributes());

    this.el.innerHTML = iconElement(attrs.icon);
    modelSetClass.call(this, modelClass);
}

export const inlineButtonOnRender = function(editor) {
    var modelClass = 'inline-item';
    const attrs = fixInlineAttrs(this.model.getAttributes());
    const icon = !! attrs.icon ? iconElement(attrs.icon) : '';

    if (attrs.itemUUID === '***linked-item-deleted***') {
      modelClass += ' linked-item-deleted';
    }
    this.el.innerHTML = `${icon}${attrs.name}`;
    modelSetClass.call(this, modelClass);
}

export const inlineImageOnRender = function(editor) {
    var modelClass = 'inline-item-image';
    const attrs = fixInlineAttrs(this.model.getAttributes());
    const image:any = document.createElement('img');

    if (attrs.itemUUID === '***linked-item-deleted***') {
      modelClass += ' linked-item-deleted';
    }
    if (attrs.floatItem !== 'none') {
        modelClass += ` float-item float-item-${attrs.floatItem}`;
    }
    if (!! attrs.imageUUID && editor.isSecureStorage === true) {
        cacheInjectImageDataUrl(`${editor.baseUrl}${attrs.imageUUID}/`, image, this.el, editor.indexDb);
    } else if (!! attrs.imageUUID && editor.isSecureStorage === false) {
        image.src = `${editor.baseUrl}${attrs.imageUUID}/`;
        this.el.appendChild(image);
    } else {
        modelClass += ' image-not-found';
        this.el.innerHTML = '<image-not-found><i class="material-icons">image_not_supported</i></image-not-found>';
    }
    modelSetClass.call(this, modelClass);
}

export const buttonOnRender = function(editor) {
    var modelClass = 'item';
    const attrs = this.model.getAttributes();

    if (attrs.itemUUID === '***linked-item-deleted***') {
      modelClass += ' linked-item-deleted';
    }
    if (attrs.Type === 'text') {
        modelClass += ' text-';
        this.el.innerHTML = attrs.name;
    } else {
        const wrapTextToNextLine = attrs.wrapTextToNextLine === true ? ' wrap-button-title' : '';
        const blockStyle = attrs.fullWidth === true ? ' button-block' : '';
        // const roundedStyle = attrs.rounded === true ? '' : ' button-not-rounded';
        const roundedStyle = '';
        const icon = !! attrs.icon ? `<i class="material-icons">${attrs.icon}</i>` : '';
        const iconClass = !! attrs.icon ? ' has-icon' : '';

        if (attrs.justifyItem !== 'none') {
            modelClass += ` item-flex-justify item-flex-justify-${attrs.justifyItem}`;
        }
        if (attrs.displayAs === 'text') {
            this.el.innerHTML = `<strong>${icon}${attrs.name}</strong>`;
        }

        if (attrs.semiTransparent) {
            modelClass += ' item-transparency-5';
        }

        if (attrs.displayAs === 'button') {
            this.el.innerHTML = `
<span class="button ${blockStyle}${roundedStyle} button-${attrs.buttonStyle} button-${attrs.buttonSize} button-${attrs.displayAs}${iconClass}">
    ${icon}<span class="button-title${wrapTextToNextLine}">${attrs.name}</span>
</span>`;
        }
    }

    modelSetClass.call(this, modelClass);
}

export const imageOnRender = function(editor) {
    var modelClass = 'item';
    const attrs = this.model.getAttributes();
    const blockStyle = attrs.fullWidth === true ? ' button-block' : '';
    const image:any = document.createElement('img');
    // const roundedStyle = attrs.rounded === true ? '' : ' button-image-not-rounded';
    const roundedStyle = '';

    modelClass += ` button-image ${blockStyle}${roundedStyle}`;
    if (attrs.itemUUID === '***linked-item-deleted***') {
      modelClass += ' linked-item-deleted';
    }
    if (attrs.justifyItem !== 'none') {
        modelClass += ` item-flex-justify item-flex-justify-${attrs.justifyItem}`;
    }

    if (attrs.semiTransparent) {
        modelClass += ' item-transparency-5';
    }

    if (!! attrs.imageUUID && editor.isSecureStorage === true) {
        cacheInjectImageDataUrl(`${editor.baseUrl}${attrs.imageUUID}/`, image, this.el, editor.indexDb);
    } else if (!! attrs.imageUUID && editor.isSecureStorage === false) {
        image.src = `${editor.baseUrl}${attrs.imageUUID}/`;
        this.el.appendChild(image);
    } else if (!! attrs.currentImageUrl) {
        this.el.innerHTML = `<img src="${attrs.currentImageUrl}" />`;
    } else {
        this.el.innerHTML = '<image-not-found><i class="material-icons">image_not_supported</i></image-not-found>';
    }

    modelSetClass.call(this, modelClass);
}

export const FileDefaults = {
    droppable: false,
    badgable: false,
    draggable: paramCase(COMP_LAYOUT_ROW),
    // components: {
    //     type: 'textnode',
    //     content: COMP_LOREM_IPSUM,
    // },
    attributes: {
        buttonSize: FIELD_BUTTON_SIZE.default,
        buttonStyle: FIELD_BUTTON_STYLE.default,
        currentFileUrl: null,
        currentImageUrl: null,
        disabled: FIELD_DISABLED.default,
        displayAs: FIELD_DISPLAY_AS.default,
        fileDetails: null,
        fileUUID: null,
        fullWidth: FIELD_FULL_WIDTH.default,
        icon: null,
        imageDetails: null,
        imageUUID: null,
        itemUUID: null,
        justifyItem: FIELD_JUSTIFY_ITEM.default,
        name: COMP_LOREM_IPSUM,
        // placeholder: FIELD_PLACEHOLDER.default,
        // rounded: FIELD_ROUNDED.default,
        // uuid: uuidv4(),
        wrapTextToNextLine: FIELD_WRAP_TEXT_TO_NEXT_LINE.default
    }
}

export const Defaults = {
    droppable: false,
    badgable: false,
    draggable: paramCase(COMP_LAYOUT_ROW),
    // components: {
    //     type: 'textnode',
    //     content: COMP_LOREM_IPSUM,
    // },
    attributes: {
        // rounded: FIELD_ROUNDED.default,
        buttonSize: FIELD_BUTTON_SIZE.default,
        buttonStyle: FIELD_BUTTON_STYLE.default,
        currentImageUrl: null,
        disabled: FIELD_DISABLED.default,
        displayAs: FIELD_DISPLAY_AS.default,
        fullWidth: FIELD_FULL_WIDTH.default,
        icon: null,
        imageDetails: null,
        imageUUID: null,
        itemUUID: null,
        justifyItem: FIELD_JUSTIFY_ITEM.default,
        name: COMP_LOREM_IPSUM,
        // placeholder: FIELD_PLACEHOLDER.default,
        semiTransparent: FIELD_SEMI_TRANSPARENT.default,
        // uuid: uuidv4(),
        wrapTextToNextLine: FIELD_WRAP_TEXT_TO_NEXT_LINE.default
    }
}

export const Traits = [
    FIELD_TITLE,
    FIELD_DISPLAY_AS,
    FIELD_BUTTON_STYLE,
    FIELD_BUTTON_SIZE,
    FIELD_FULL_WIDTH,
    FIELD_JUSTIFY_ITEM,
    // FIELD_ROUNDED,
    FIELD_DISABLED,
    FIELD_SEMI_TRANSPARENT,
    FIELD_WRAP_TEXT_TO_NEXT_LINE,
    // FIELD_PLACEHOLDER,
    // FIELD_CURRENT_IMAGE_URL,
    FIELD_IMAGE_SELECTOR,
    FIELD_ICON_SELECTOR
];

export const UrlTraits = [
    FIELD_TITLE,


    FIELD_URL,

    FIELD_DISPLAY_AS,
    FIELD_BUTTON_STYLE,
    FIELD_BUTTON_SIZE,
    FIELD_FULL_WIDTH,
    FIELD_JUSTIFY_ITEM,
    FIELD_DISABLED,
    FIELD_SEMI_TRANSPARENT,
    FIELD_WRAP_TEXT_TO_NEXT_LINE,


    FIELD_OPEN_IN_APP_BROWSER,


    FIELD_IMAGE_SELECTOR,
    FIELD_ICON_SELECTOR
];

export const InlineUrlTraits = [
    FIELD_TITLE,
    FIELD_URL,
    FIELD_DISPLAY_AS,
    FIELD_OPEN_IN_APP_BROWSER,
    FIELD_FLOAT_ITEM,
    FIELD_DISABLED,
    // FIELD_PLACEHOLDER,
    FIELD_ICON_SELECTOR,
    FIELD_IMAGE_SELECTOR
];

export const InlineUrlDefaults = {
    droppable: false,
    badgable: false,
    highlightable: false,
    hoverable: false,
    textable: false,
    draggable: false,
    inline: true,
    attributes: {
        contenteditable: 'false',
        current_image_url: null,
        disabled: FIELD_DISABLED.default,
        display_as: 'link',
        float_item: FIELD_FLOAT_ITEM.default,
        image_details: '',
        image_uuid: '',
        name: COMP_LOREM_IPSUM,
        open_in_app_browser: FIELD_OPEN_IN_APP_BROWSER.default,
        // placeholder: FIELD_PLACEHOLDER.default,
        url: '',
        url_snapshot: null,
        uuid: uuidv4()
    }
}

export const InlineTraits = [
    FIELD_TITLE,
    FIELD_DISPLAY_AS,
    FIELD_FLOAT_ITEM,
    {
        type: 'select',
        name: 'inline_item_type',
        label: 'Item type',
        options: COMP_SELECT_OPTIONS
    },
    FIELD_DISABLED,
    // FIELD_PLACEHOLDER,
    FIELD_INLINE_ITEM_SELECTOR,
    FIELD_ICON_SELECTOR,
    FIELD_IMAGE_SELECTOR
];

export const InlineDefaults = {
    droppable: false,
    badgable: false,
    highlightable: false,
    hoverable: false,
    textable: false,
    draggable: false,
    inline: true,
    // draggable: `div.${paramCase(COMP_TEXT_BLOCK)}`,
    attributes: {
        contenteditable: 'false',
        disabled: FIELD_DISABLED.default,
        display_as: 'link',
        float_item: FIELD_FLOAT_ITEM.default,
        image_details: '',
        image_uuid: '',
        inline_item_type: COMP_BUTTON_ARTICLE,
        item_uuid: '',
        name: COMP_LOREM_IPSUM,
        // placeholder: FIELD_PLACEHOLDER.default,
        // uuid: uuidv4(),
    }
}

export const UrlDefaults = {
    droppable: false,
    badgable: false,
    draggable: paramCase(COMP_LAYOUT_ROW),
    // components: {
    //     type: 'textnode',
    //     content: COMP_LOREM_IPSUM,
    // },
    attributes: {
        // placeholder: FIELD_PLACEHOLDER.default,
        // rounded: FIELD_ROUNDED.default,
        buttonSize: FIELD_BUTTON_SIZE.default,
        buttonStyle: FIELD_BUTTON_STYLE.default,
        currentImageUrl: null,
        disabled: FIELD_DISABLED.default,
        displayAs: FIELD_DISPLAY_AS.default,
        fullWidth: FIELD_FULL_WIDTH.default,
        imageDetails: null,
        imageUUID: null,
        itemUUID: null,
        justifyItem: FIELD_JUSTIFY_ITEM.default,
        name: COMP_LOREM_IPSUM,
        openInAppBrowser: FIELD_OPEN_IN_APP_BROWSER.default,
        // sendCurrentUserToken: FIELD_SEND_CURRENT_USER_TOKEN.default,
        semiTransparent: FIELD_SEMI_TRANSPARENT.default,
        urlSnapshot: null,
        // uuid: uuidv4(),
        wrapTextToNextLine: FIELD_WRAP_TEXT_TO_NEXT_LINE.default
    }
}