import { Component, Input } from '@angular/core';

@Component({
  selector: 'user-edit-tabs',
  templateUrl: './user-edit-tabs.component.html',
  styleUrls: ['./user-edit-tabs.component.scss'],
})
export class UserEditTabsComponent {

  @Input() uuid: string;
  @Input() user: object;
}
