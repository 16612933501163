import { FieldType } from '@app/models/view-models/field-type';

export const FIELD_TYPES: FieldType[] = [
  { value: 'group', label: 'Group', isDefault: false },
  { value: 'string', label: 'Text', isDefault: false },
  { value: 'text', label: 'Long text', isDefault: false },
  { value: 'integer', label: 'Integer', isDefault: false },
  { value: 'numeric', label: 'Numeric', isDefault: false },
  { value: 'url', label: 'URL', isDefault: false },
  { value: 'email', label: 'Email address', isDefault: false },
  { value: 'date', label: 'Date', isDefault: false },
  { value: 'datetime', label: 'Datetime', isDefault: false },
];

export const MODELS: FieldType[] = [
  { value: 'App\\User', label: 'User', isDefault: true },
  { value: 'App\\Event', label: 'Event', isDefault: false },
];

export enum Enum_Custom_Field_Model {
  APP_USER = 'App\\User',
  APP_EVENT = 'App\\Event'
}
