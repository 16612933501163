import {
  Defaults,
  Traits
} from './shared';

import { ICON_BUTTON_PROFILE_GROUP } from './../../../const/icons';

import { COMP_BUTTON_PROFILE_GROUP } from './../../../const/components';

import { FIELD_PROFILE_GROUP_SELECTOR } from './../../../const/traits';

import { PageFormLinkedItem } from './../page-form-linked-item';

export const ProfileGroup = (editor, options:any = {}) => {

  (FIELD_PROFILE_GROUP_SELECTOR as any).at = 9;

  return PageFormLinkedItem(editor, {
    componentName: COMP_BUTTON_PROFILE_GROUP,
    displayIcon: ICON_BUTTON_PROFILE_GROUP,
    model: {
      defaults: { ...Defaults,
        name: 'Profile Group',
        traits: [ ...Traits, FIELD_PROFILE_GROUP_SELECTOR]
      }
    }
  });
}