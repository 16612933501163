import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { PermissionService } from '@services/permission.service';
import { SidebarService } from '@services/sidebar.service';
import { FilterFields } from './filter-fields';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
@Component({
  selector: 'app-permissions-index',
  templateUrl: './permissions-index.component.html',
  styleUrls: ['./permissions-index.component.scss'],
  providers: [SidebarService]
})
export class PermissionsIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['name', 'role_count', 'user_count', 'actions'];
  public dataLoaded = false;
  public filterFields: any = FilterFields;
  public node: Breadcrumb;

  constructor(
    public permissionService: PermissionService,
    public router: Router,
    public sidebarService: SidebarService,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: permissionService,
      router,
      sidebarService,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      sidebar: '',
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.node = {
      label: 'Permissions',
      link: 'permissions',
      title: 'Permissions'
    };
    this.buildSettingsBreadcrumb(this.node);
  }
}
