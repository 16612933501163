import { eventCard } from './cards';

import {
  Defaults,
  Traits
} from './shared';

import { ICON_BUTTON_EVENT } from './../../../const/icons';

import { COMP_BUTTON_EVENT } from './../../../const/components';

import { FIELD_EVENT_SELECTOR } from './../../../const/traits';

import { PageFormLinkedItem } from './../page-form-linked-item';

export const Event = (editor, options:any = {}) => {

  (FIELD_EVENT_SELECTOR as any).at = 9;

  return PageFormLinkedItem(editor, {
    componentName: COMP_BUTTON_EVENT,
    displayIcon: ICON_BUTTON_EVENT,
    model: {
      defaults: { ...Defaults,
        name: 'Event',
        traits: [ ...Traits, FIELD_EVENT_SELECTOR]
      }
    },
    cardOnRender() {
      return eventCard.call(this);
    }
  });
}