import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseComponent } from '@app/components/base.component';
import { State } from '@app/state';
@Component({
  selector: 'core-form-badge',
  templateUrl: './core-form-badge.component.html',
  styleUrls: ['./core-form-badge.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreFormBadgeComponent),
      multi: true
    }
  ]
})

export class CoreFormBadgeComponent extends BaseComponent implements ControlValueAccessor, OnInit {
  @Input() formService: any;
  @Input() data: any = [];
  @Input() id = '';
  @Input() url = null;
  @Input() Yeslabel = 'Yes';
  @Input() Nolabel = 'No';
  // tslint:disable-next-line: no-input-rename
  @Input('Value') val: string;

  @Output() valueChanged = new EventEmitter<string>();
  public showDataSavingLoader = false;

  constructor(
    public state: State
  ) {
    super();
    this.setObjects({
      state: state,
    });

  }

  ngOnInit() {
  }

  // Both onChange and onTouched are functions
  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  // We implement this method to keep a reference to the onChange
  // callback function passed by the forms API
  registerOnChange(fn) {
    this.onChange = fn;
  }

  // We implement this method to keep a reference to the onTouched
  // callback function passed by the forms API
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // This is a basic setter that the forms API is going to use
  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  updateStatus(): void {
    if (this.formService !== undefined) {
      this.showDataSavingLoader = true;
      this.formService.patchById(this.id, this.data, this.url).subscribe(result => this.afterRowUpdated());
    }
  }

  afterRowUpdated(): void {
    if (this.valueChanged.observers.length > 0) {
      this.valueChanged.emit('');
    }
  }
}
