const makeFieldFromCustomTrait = (traitName:string) => {
    return { type: traitName, name: traitName };
}

// Custom traits
const _TRAIT_APP_COMPONENT_SELECTOR = 'appComponentSelector';
export const TRAIT_APP_COMPONENT_SELECTOR = _TRAIT_APP_COMPONENT_SELECTOR;
export const FIELD_APP_COMPONENT_SELECTOR = makeFieldFromCustomTrait(_TRAIT_APP_COMPONENT_SELECTOR);

const _TRAIT_APP_PAGE_SELECTOR = 'appPageSelector';
export const TRAIT_APP_PAGE_SELECTOR = _TRAIT_APP_PAGE_SELECTOR;
export const FIELD_APP_PAGE_SELECTOR = makeFieldFromCustomTrait(_TRAIT_APP_PAGE_SELECTOR);

const _TRAIT_ARTICLE_CATEGORY_SELECTOR = 'articleCategorySelector';
export const TRAIT_ARTICLE_CATEGORY_SELECTOR = _TRAIT_ARTICLE_CATEGORY_SELECTOR;
export const FIELD_ARTICLE_CATEGORY_SELECTOR = makeFieldFromCustomTrait(_TRAIT_ARTICLE_CATEGORY_SELECTOR);

const _TRAIT_ARTICLE_SELECTOR = 'articleSelector';
export const TRAIT_ARTICLE_SELECTOR = _TRAIT_ARTICLE_SELECTOR;
export const FIELD_ARTICLE_SELECTOR = makeFieldFromCustomTrait(_TRAIT_ARTICLE_SELECTOR);

const _TRAIT_BUNDLE_DISPLAY_AS = 'bundleDisplayAs';
export const TRAIT_BUNDLE_DISPLAY_AS = _TRAIT_BUNDLE_DISPLAY_AS;
export const FIELD_BUNDLE_DISPLAY_AS = makeFieldFromCustomTrait(_TRAIT_BUNDLE_DISPLAY_AS);

const _TRAIT_BUNDLE_SELECTOR = 'bundleSelector';
export const TRAIT_BUNDLE_SELECTOR = _TRAIT_BUNDLE_SELECTOR;
export const FIELD_BUNDLE_SELECTOR = makeFieldFromCustomTrait(_TRAIT_BUNDLE_SELECTOR);

const _TRAIT_CALENDAR_SELECTOR = 'calendarSelector';
export const TRAIT_CALENDAR_SELECTOR = _TRAIT_CALENDAR_SELECTOR;
export const FIELD_CALENDAR_SELECTOR = makeFieldFromCustomTrait(_TRAIT_CALENDAR_SELECTOR);

const _TRAIT_CHAT_SELECTOR = 'chatSelector';
export const TRAIT_CHAT_SELECTOR = _TRAIT_CHAT_SELECTOR;
export const FIELD_CHAT_SELECTOR = makeFieldFromCustomTrait(_TRAIT_CHAT_SELECTOR);

const _TRAIT_COMPONENT_TITLE = 'componentTitle';
export const TRAIT_COMPONENT_TITLE = _TRAIT_COMPONENT_TITLE;
export const FIELD_COMPONENT_TITLE = makeFieldFromCustomTrait(_TRAIT_COMPONENT_TITLE);

const _TRAIT_CONTACT_GROUP_SELECTOR = 'contactGroupSelector';
export const TRAIT_CONTACT_GROUP_SELECTOR = _TRAIT_CONTACT_GROUP_SELECTOR;
export const FIELD_CONTACT_GROUP_SELECTOR = makeFieldFromCustomTrait(_TRAIT_CONTACT_GROUP_SELECTOR);

const _TRAIT_DIRECTORY_CATEGORY_SELECTOR = 'directoryCategorySelector';
export const TRAIT_DIRECTORY_CATEGORY_SELECTOR = _TRAIT_DIRECTORY_CATEGORY_SELECTOR;
export const FIELD_DIRECTORY_CATEGORY_SELECTOR = makeFieldFromCustomTrait(_TRAIT_DIRECTORY_CATEGORY_SELECTOR);

const _TRAIT_DIRECTORY_ITEM_SELECTOR = 'directoryItemSelector';
export const TRAIT_DIRECTORY_ITEM_SELECTOR = _TRAIT_DIRECTORY_ITEM_SELECTOR;
export const FIELD_DIRECTORY_ITEM_SELECTOR = makeFieldFromCustomTrait(_TRAIT_DIRECTORY_ITEM_SELECTOR);

const _TRAIT_DOCUMENT_SELECTOR = 'documentSelector';
export const TRAIT_DOCUMENT_SELECTOR = _TRAIT_DOCUMENT_SELECTOR;
export const FIELD_DOCUMENT_SELECTOR = makeFieldFromCustomTrait(_TRAIT_DOCUMENT_SELECTOR);

const _TRAIT_EVENT_SELECTOR = 'eventSelector';
export const TRAIT_EVENT_SELECTOR = _TRAIT_EVENT_SELECTOR;
export const FIELD_EVENT_SELECTOR = makeFieldFromCustomTrait(_TRAIT_EVENT_SELECTOR);

const _TRAIT_FILE_SELECTOR = 'fileSelector';
export const TRAIT_FILE_SELECTOR = _TRAIT_FILE_SELECTOR;
export const FIELD_FILE_SELECTOR = makeFieldFromCustomTrait(_TRAIT_FILE_SELECTOR);

const _TRAIT_FOLDER_SELECTOR = 'folderSelector';
export const TRAIT_FOLDER_SELECTOR = _TRAIT_FOLDER_SELECTOR;
export const FIELD_FOLDER_SELECTOR = makeFieldFromCustomTrait(_TRAIT_FOLDER_SELECTOR);

const _TRAIT_ICON_SELECTOR = 'iconSelector';
export const TRAIT_ICON_SELECTOR = _TRAIT_ICON_SELECTOR;
export const FIELD_ICON_SELECTOR = makeFieldFromCustomTrait(_TRAIT_ICON_SELECTOR);

const _TRAIT_IMAGE_SELECTOR = 'imageSelector';
export const TRAIT_IMAGE_SELECTOR = _TRAIT_IMAGE_SELECTOR;
export const FIELD_IMAGE_SELECTOR = makeFieldFromCustomTrait(_TRAIT_IMAGE_SELECTOR);

const _TRAIT_INLINE_ITEM_SELECTOR = 'inlineItemSelector';
export const TRAIT_INLINE_ITEM_SELECTOR = _TRAIT_INLINE_ITEM_SELECTOR;
export const FIELD_INLINE_ITEM_SELECTOR = makeFieldFromCustomTrait(_TRAIT_INLINE_ITEM_SELECTOR);

const _TRAIT_ITEM_PERMS_SELECTOR = 'itemPermsSelector';
export const TRAIT_ITEM_PERMS_SELECTOR = _TRAIT_ITEM_PERMS_SELECTOR;
export const FIELD_ITEM_PERMS_SELECTOR = makeFieldFromCustomTrait(_TRAIT_ITEM_PERMS_SELECTOR);

const _TRAIT_PERIODISATION_CALENDAR_SELECTOR = 'periodisationCalendarSelector';
export const TRAIT_PERIODISATION_CALENDAR_SELECTOR = _TRAIT_PERIODISATION_CALENDAR_SELECTOR;
export const FIELD_PERIODISATION_CALENDAR_SELECTOR = makeFieldFromCustomTrait(_TRAIT_PERIODISATION_CALENDAR_SELECTOR);

const _TRAIT_PROFILE_GROUP_SELECTOR = 'profileGroupSelector';
export const TRAIT_PROFILE_GROUP_SELECTOR = _TRAIT_PROFILE_GROUP_SELECTOR;
export const FIELD_PROFILE_GROUP_SELECTOR = makeFieldFromCustomTrait(_TRAIT_PROFILE_GROUP_SELECTOR);

const _TRAIT_ROW_COLUMN_ITEM_FLOW = 'rowColumnItemFlow';
export const TRAIT_ROW_COLUMN_ITEM_FLOW = _TRAIT_ROW_COLUMN_ITEM_FLOW;
export const FIELD_ROW_COLUMN_ITEM_FLOW = makeFieldFromCustomTrait(_TRAIT_ROW_COLUMN_ITEM_FLOW);

const _TRAIT_ROW_COLUMN_ITEM_SPACING = 'rowColumnItemSpacing';
export const TRAIT_ROW_COLUMN_ITEM_SPACING = _TRAIT_ROW_COLUMN_ITEM_SPACING;
export const FIELD_ROW_COLUMN_ITEM_SPACING = makeFieldFromCustomTrait(_TRAIT_ROW_COLUMN_ITEM_SPACING);

const _TRAIT_TEXT_EDITOR = 'textEditor';
export const TRAIT_TEXT_EDITOR = _TRAIT_TEXT_EDITOR;
export const FIELD_TEXT_EDITOR = makeFieldFromCustomTrait(_TRAIT_TEXT_EDITOR);

const _TRAIT_USER_CIRCLE_SELECTOR = 'userCircleSelector';
export const TRAIT_USER_CIRCLE_SELECTOR = _TRAIT_USER_CIRCLE_SELECTOR;
export const FIELD_USER_CIRCLE_SELECTOR = makeFieldFromCustomTrait(_TRAIT_USER_CIRCLE_SELECTOR);

// Misc
const _TRAIT_MISC_REMOVE_COMPONENT = 'removeComponent';
export const TRAIT_MISC_REMOVE_COMPONENT = _TRAIT_MISC_REMOVE_COMPONENT;
export const FIELD_MISC_REMOVE_COMPONENT = makeFieldFromCustomTrait(_TRAIT_MISC_REMOVE_COMPONENT);

const _TRAIT_MISC_TEXTBOX = 'textboxTrait';
export const TRAIT_MISC_TEXTBOX = _TRAIT_MISC_TEXTBOX;
export const FIELD_MISC_TEXTBOX = makeFieldFromCustomTrait(_TRAIT_MISC_TEXTBOX);


// Fields
export const FIELD_TITLE = {
    label: 'Title',
    name: 'name',
    type: 'text'
}

export const FIELD_URL = {
    label: 'Url',
    name: 'url',
    type: 'text'
}

export const FIELD_WIDTH = {
    label: 'Width',
    name: 'width',
    type: 'text'
}

export const FIELD_HEIGHT = {
    label: 'Height',
    name: 'height',
    type: 'text'
}

export const FIELD_OPEN_IN_APP_BROWSER = {
    label: 'Open in app browser',
    name: 'openInAppBrowser',
    type: 'checkbox',
    default: false
}

export const FIELD_SEND_CURRENT_USER_TOKEN = {
    label: 'Send current user token',
    name: 'sendCurrentUserToken',
    type: 'checkbox',
    default: false
}

export const FIELD_INITIAL_STATE_OPEN = {
    name: 'initialStateOpen',
    type: 'checkbox',
    label: 'Initial state open',
    default: false
}

export const FIELD_WRAP_TEXT_TO_NEXT_LINE = {
    label: 'Wrap text to next line',
    name: 'wrapTextToNextLine',
    type: 'checkbox',
    default: false
}

export const FIELD_DISPLAY_AS = {
    type: 'select',
    name: 'displayAs',
    label: 'Display As',
    default: 'button',
    options: [
        { id: 'button', label: 'Button' },
        { id: 'image', label: 'Image' },
        // { id: 'card', label: 'Card' },
        { id: 'text', label: 'Text' },
    ]
}

export const FIELD_AUTO_SCROLL = {
    name: 'autoScroll',
    label: 'Auto Scroll',
    type: 'checkbox',
    default: false,
}

export const FIELD_SHOW_DOTS = {
    name: 'showDots',
    label: 'Show Dots',
    type: 'checkbox',
    default: true,
}

export const FIELD_SHOW_PREV_NEXT = {
    name: 'showPrevNext',
    label: 'Show Prev / Next',
    type: 'checkbox',
    default: false,
}

export const FIELD_CURRENT_IMAGE_URL = {
    label: 'Current URL',
    type: 'text',
    name: 'currentImageUrl'
}

export const FIELD_FONT = {
    type: 'select',
    name: 'font',
    label: 'Font',
    default: 'default',
    options: [
        { id: 'default', label: 'Default' },
        { id: 'monseratt', label: 'Monseratt' },
        { id: 'comic-sans', label: 'Comic Sans' }
    ]
}

// export const FIELD_SEMI_TRANSPARENT = {
//     type: 'select',
//     name: 'transparency',
//     label: 'Transparency',
//     default: '10',
//     options: [
//         { id: '10', label: '1' },
//         { id: '9', label: '.9' },
//         { id: '8', label: '.8' },
//         { id: '7', label: '.7' },
//         { id: '6', label: '.6' },
//         { id: '5', label: '.5' },
//         { id: '4', label: '.4' },
//         { id: '3', label: '.3' },
//         { id: '2', label: '.2' },
//         { id: '1', label: '.1' },
//         { id: '0', label: '0' }
//     ]
// }

export const FIELD_SEMI_TRANSPARENT = {
    type: 'checkbox',
    name: 'semiTransparent',
    label: 'Semi Transparent?',
    default: false
}

export const FIELD_BUNDLE_HIDE_BUNDLE_TITLE = {
    type: 'checkbox',
    name: 'hideBundleTitle',
    label: 'Hide Title?'
}

export const FIELD_TEXT_ALIGN = {
    type: 'select',
    name: 'textAlign',
    label: 'Align Text',
    default: 'center',
    options: [
        { id: 'left', label: 'Left' },
        { id: 'center', label: 'Center' },
        { id: 'right', label: 'Right' }
    ]
}

export const FIELD_FONT_SIZE = {
    type: 'select',
    name: 'fontsize',
    label: 'Font Size',
    default: 12,
    options: [
        { id: 10, label: 10 },
        { id: 12, label: 12 },
        { id: 14, label: 14 },
        { id: 16, label: 16 },
        { id: 18, label: 18 },
        { id: 20, label: 20 },
        { id: 22, label: 22 },
        { id: 24, label: 24 },
        { id: 26, label: 26 },
        { id: 30, label: 30 },
        { id: 36, label: 36 },
        { id: 42, label: 42 },
        { id: 50, label: 50 },
        { id: 60, label: 60 },
        { id: 72, label: 72 }
    ]
}

export const FIELD_LINE_SPACING = {
    type: 'select',
    name: 'linespacing',
    label: 'Line Spacing',
    default: 'single',
    options: [
        { id: 'single', label: 'Single' },
        { id: 'double', label: 'Double' },
        { id: 'treble', label: 'Treble' }
    ]
}

export const FIELD_BUTTON_SIZE = {
    type: 'select',
    name: 'buttonSize',
    label: 'Size',
    default: 'medium',
    options: [
        { id: 'small', label: 'Small' },
        { id: 'medium', label: 'Medium' },
        { id: 'large', label: 'Large' }
    ]
}

export const FIELD_DISABLED = {
    type: 'checkbox',
    name: 'disabled',
    label: 'Disabled',
    default: false
}

// export const FIELD_PLACEHOLDER = {
//     type: 'checkbox',
//     name: 'placeholder',
//     label: 'Placeholder',
//     default: false
// }

export const FIELD_BUTTON_STYLE = {
    type: 'select',
    name: 'buttonStyle',
    label: 'Style',
    default: 'primary',
    options: [
        { id: 'primary', label: 'Primary' },
        { id: 'secondary', label: 'Secondary' },
        { id: 'outline', label: 'Outline' }
    ]
}

export const FIELD_FULL_WIDTH = {
    type: 'checkbox',
    name: 'fullWidth',
    label: 'Full Width',
    default: false
}

export const FIELD_JUSTIFY_ITEM = {
    type: 'select',
    name: 'justifyItem',
    label: 'Flex justify content',
    default: 'none',
    options: [
        { id: 'none', label: 'None' },
        { id: 'start', label: 'Start' },
        { id: 'end', label: 'End' },
    ]
}

export const FIELD_ROUNDED = {
    type: 'checkbox',
    name: 'rounded',
    label: 'Rounded',
    default: true
}

export const FIELD_FLOAT_ITEM = {
    type: 'select',
    name: 'float_item',
    label: 'Float item',
    default: 'none',
    options: [
        { id: 'none', label: 'None' },
        { id: 'left', label: 'Left' },
        { id: 'right', label: 'Right' }
    ]
}

export const FIELD_USE_FLEX = {
    type: 'checkbox',
    name: 'useFlex',
    label: 'Flex items',
    default: false
}

export const FIELD_H_SPACING = {
    type: 'checkbox',
    name: 'hSpacing',
    label: 'H spacing'
}

export const FIELD_V_SPACING = {
    type: 'checkbox',
    name: 'vSpacing',
    label: 'V spacing'
}

// export const FIELD_H_SPACING = {
//     type: 'select',
//     name: 'hSpacing',
//     label: 'Horizontal spacing',
//     default: 'small',
//     options: [
//         { id: 'none', label: 'None' },
//         { id: 'small', label: 'Small' },
//         { id: 'medium', label: 'Medium' },
//         { id: 'large', label: 'Large' }
//     ]
// }

// export const FIELD_V_SPACING = {
//     type: 'select',
//     name: 'vSpacing',
//     label: 'Vertical spacing',
//     default: 'small',
//     options: [
//         { id: 'none', label: 'None' },
//         { id: 'small', label: 'Small' },
//         { id: 'medium', label: 'Medium' },
//         { id: 'large', label: 'Large' }
//     ]
// }

export const FIELD_JUSTIFY_CONTENT = {
    type: 'select',
    name: 'justifyContent',
    label: 'Justify content',
    default: 'none',
    options: [
        { id: 'none', label: 'None' },
        { id: 'start', label: 'Start' },
        { id: 'end', label: 'End' },
        { id: 'center', label: 'Center' },
        { id: 'space-between', label: 'Space between' },
        { id: 'space-around', label: 'Space around' },
        { id: 'space-evenly', label: 'Space evenly' },
        { id: 'even-width', label: 'Even width' }
    ]
}