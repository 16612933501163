import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { AccessCodeService } from '@services/access-code.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
import { ACODE_FILTER_FIELDS } from './filter-fields';

@Component({
  selector: 'app-access-codes-index',
  templateUrl: './access-codes-index.component.html',
  styleUrls: ['./access-codes-index.component.scss']
})
export class AccessCodesIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['created_at', 'title', 'code', 'group', 'role', 'user_count', 'active', 'actions'];
  public dataLoaded = false;
  public node: Breadcrumb;
  public fields: any = ACODE_FILTER_FIELDS;
  constructor(
    public accessCodeService: AccessCodeService,
    public router: Router,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      state,
      router,
      modelService: accessCodeService,
      snackbarService,
      dialog
    });
    this.setPageVars({

      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.node = {
      label: 'Access Codes',
      link: 'access-codes',
      title: 'Access Codes'
    };
    this.buildSettingsBreadcrumb(this.node);
  }

  public updatedStatus(): void {
    this.state.message({ ['AccessCodeIndexUpdate']: true });
  }
}
