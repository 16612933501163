import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
import { TimezoneService } from '@app/services/timezone.service';
import { TIMEZONE_FILTER_FIELDS } from './filter-fields';

@Component({
  selector: 'app-timezones-index',
  templateUrl: './timezones-index.component.html',
  styleUrls: ['./timezones-index.component.scss']
})
export class TimezonesIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['name', 'shortname', 'visible'];
  public dataLoaded = false;
  public node: Breadcrumb;
  public fields: any = TIMEZONE_FILTER_FIELDS;
  private defaultTimezoneUUID: any = null;

  constructor(
    public router: Router,
    public state: State,
    public timezoneService: TimezoneService,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: timezoneService,
      router,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      listenForIndexUpdate: true
    });
    this.getDefaultTimezone().then(() => this.modelServiceGetAll().then(() => (this.dataLoaded = true)));
  }

  getDefaultTimezone() {
    return new Promise((resolve:any) => {
      this.timezoneService.getDefault().subscribe(timezone => {
        this.defaultTimezoneUUID = timezone.uuid;
        resolve();
      });
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.node = {
      label: 'Timezones',
      link: 'timezones',
      title: 'Timezones'
    };
    this.buildSettingsBreadcrumb(this.node);
  }

  public updatedStatus(): void {
    this.state.message({ ['TimezoneIndexUpdate']: true });
  }

}

