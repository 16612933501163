import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { ContactService } from '@services/contact.service';
import { SidebarService } from '@services/sidebar.service';
import { CONTACTS_FILTER_FIELDS } from './filter-fields';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
import { LinkContactToUserDialogComponent } from './link-contact-to-user-dialog.component';
@Component({
  selector: 'app-contacts-index',
  templateUrl: './contacts-index.component.html',
  styleUrls: ['./contacts-index.component.scss'],
  providers: [SidebarService]
})
export class ContactsIndexComponent extends BaseComponent implements OnInit {
  @Input() hideSidebar = false;
  public dataSource: any;
  public data: any;
  public displayedColumns = ['select', 'name', 'contact_type_name', 'primary_carer', 'email', 'mobile', 'spid_user_id', 'actions'];
  public dataLoaded = false;
  public fields: any = CONTACTS_FILTER_FIELDS;
  public node: Breadcrumb;
  constructor(
    public contactService: ContactService,
    public router: Router,
    public sidebarService: SidebarService,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: contactService,
      router,
      sidebarService,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      sidebar: '',
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.node = {
      label: 'Contacts',
      link: 'contacts',
      title: 'Contacts'
    };
    this.buildSettingsBreadcrumb(this.node);
  }

  public linkContactToUser(contact): void {
    const linkContactToUser = this.dialog.open(LinkContactToUserDialogComponent, {
      panelClass: 'link-contact-to-user',
      data: { contact: contact, associated_user_from_secondary_contact: true },
    });

    linkContactToUser.afterClosed().subscribe(() => this.state.message({'ContactIndexUpdate': true}));
  }
}
