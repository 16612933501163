import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { EventService } from '@app/services/event.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
@Component({
  selector: 'app-event-duplicate',
  templateUrl: './event-duplicate.component.html',
  styleUrls: ['./event-duplicate.component.scss']
})
export class ScheduleEventDuplicateComponent extends BaseComponent implements OnInit
{
  public eventFormGroup: FormGroup;
  public datePickerDate: any;
  public weekStartDate: any;
  public weekEndDate: any;
  public eventCols: any;
  public apiDataSource: any;
  public timespan: string;
  public week: string;

  private config = GlobalUtil.Configuration;

  @ViewChild('events', { static: false }) public advancedSelectEventSelection: { getSelectedIds: () => any[]; };

  constructor(
    public eventService: EventService,
    public router: Router,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      router: router,
      state: state,
      modelService: eventService,
    });

    this.state.subscription().subscribe(d => {
      if (!!d.data) {
        const startDate = String(d.data.selectedWeekDates.start).substring(0, 15);
        const endDate = String(d.data.selectedWeekDates.end).substring(0, 15);
        this.datePickerDate = moment(startDate).add(1, 'weeks').format('DD MMM YYYY');
        this.weekStartDate = moment(startDate).format('YYYY-MM-DD');
        this.weekEndDate = moment(endDate).subtract(1, 'days').format('YYYY-MM-DD');
        this.timespan = d.data.timespan;
      }
    });
  }

  public ngOnInit(): void {
    this.eventCols = [
      { columnDef: 'selected', header: 'Selected', selectedOnclick: true, cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', header: 'Name', selectedOnclick: true, cell: (element: any) => `${element.name}` },
      {
        columnDef: 'start_time', header: 'Start Time', selectedOnclick: false,
        dateFormat: this.config.LONG_DATE_FORMAT,
        cell: (element: any) => `${element.start_time}`
      },
      {
        columnDef: 'end_time', header: 'End Time', selectedOnclick: false,
        dateFormat: this.config.LONG_DATE_FORMAT,
        cell: (element: any) => `${element.end_time}`
      },
    ];
    this.initialFormGroup();
    this.apiDataSource = `schedules/admin/events/all/${this.weekStartDate}/${this.weekEndDate}?fields=uuid,name,start_time,end_time`;
  }

  public save(): void {
    this.eventFormGroup.patchValue({
      from_date: this.transformDateTime(this.controls.from_date.value),
      to_date: this.transformDateTime(this.controls.to_date.value),
      events: this.getSelectedEvents(),
    });
    this.eventService.duplicateMany(this.eventFormGroup.value).
      subscribe(result => this.closeSlideout());
  }

  public closeSlideout(): void {
    this.state.update({
      isDuplicateEventMode: false
    });
    this.state.message({ slideoutHide: true });
  }

  get controls(): any {
    return this.eventFormGroup.controls;
  }

  get canSave(): boolean {
    const canSave =
      !! this.advancedSelectEventSelection &&
      (this.eventFormGroup.valid && this.advancedSelectEventSelection.getSelectedIds().length > 0);
    return canSave;
  }

  /**
   * Converts the date to a more SQL friendly format
   * @param val
   */
  private transformDateTime(val: any): any {
    return moment(val).format(this.config.SHORT_DATE_FORMAT_DISPLAY);
  }

  public getSelectedEvents(): any[] {
    const value: any[] = this.advancedSelectEventSelection.getSelectedIds();
    return value === null ? null : value;
  }

  private initialFormGroup(): void {
    this.eventFormGroup = this.formBuilder.group({
      from_date: this.weekStartDate,
      to_date: [moment(this.datePickerDate).format('DD MMM YYYY'), [Validators.required]],
      events: []
    });
  }

  public setToDate(dateValue) {
    this.eventFormGroup.patchValue({
      to_date: dateValue
    });
  }

}
