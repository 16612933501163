import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { BundleService } from '@app/services/bundle.service';
import { BundleItemService } from '@app/services/bundle-item.service';

@Component({
  selector: 'bundle-item-sort',
  templateUrl: './bundle-item-sort.component.html',
  styleUrls: [],
  providers: [SidebarService]
})

export class BundleItemSortComponent extends BaseComponent implements OnInit {
  public pageTitle = 'Sort Bundle Items';
  public dataLoaded = false;
  public datasource: [];
  public items: [];
  constructor(
    public activatedRoute: ActivatedRoute,
    public bundleService: BundleService,
    public bundleItemService: BundleItemService,
    public sidebarService: SidebarService,
    public state: State,
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: bundleService,
    });
  }

  ngOnInit(): void {
    this.modelServiceGetOne(this.routeParams['id'], 'bundleItems')
      .then(() => {
        this.datasource = this.dataSourceRows.bundle_items;
        this.dataLoaded = true;
      });
  }

  drop($event: CdkDragDrop<string[]>) {
    moveItemInArray(this.datasource, $event.previousIndex, $event.currentIndex);
    const items = [];
    this.datasource.forEach(function (item) {
      items.push(item['uuid']);
    });
    this.bundleItemService.sortRecords(items).subscribe();
  }

  public closeSlideout() {
    this.state.message({
      ['BundleIndexUpdate']: true,
      ['BundleViewUpdate']: true,
      ['id']: this.routeParams['id']
    });
    this.state.message({ slideoutHide: true });
  }
}
