import { Component, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogComponent} from '@app/components/confirmation-dialog/confirmation-dialog.component';
import { ApiModel } from '@app/models/entities/api-model';
import { DocumentService } from '@app/services/document.service';
import { FolderService } from '@app/services/folder.service';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { SidebarService } from '@services/sidebar.service';
import { of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { FOLDER_FILTER_FIELDS } from './filter-fields';
import { LocalStorageService } from '@app/services/local-storage.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { FilterLink } from '@app/models/view-models/filter-link';
import { SidebarFilterLink } from '@app/services/sidebar-filter-link.service';

@Component({
  selector: 'folder-view',
  templateUrl: './folder-view.component.html',
  styleUrls: ['./folder-view.component.scss', './folder-view-700.component.scss'],
  providers: [SidebarService]
})

export class FolderViewComponent implements OnInit {
  public links: FilterLink[] = [];
  public dsFolder: any = [];
  public folderItems: ApiModel.Folder[] = [];
  public dsDocument: any = [];
  public documentItems: ApiModel.Folder[] = [];
  public dataLoaded = false;
  public fields: any = FOLDER_FILTER_FIELDS;
  public result: any = [];
  public queryStringFilter = '';
  public parent_uuid = '';
  public view_mode = false;
  public breadcrumbFilterHeading = '';
  private localStorageKey = GlobalUtil.LocalStorageKey;
  constructor(
    public sidebarService: SidebarService,
    public router: Router,
    public state: State,
    public folderService: FolderService,
    public documentService: DocumentService,
    public snackbarService: SnackBarService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private storage: LocalStorageService,
    private sidebarFilterLink: SidebarFilterLink
  ) {
    route.params.subscribe(val => {
      this.parent_uuid = this.route.snapshot.paramMap.get('parent_uuid');
      this.loadListData();
      this.state.subscription().subscribe(s => {
        if (!!s['FolderIndexUpdate'] || !!s['DocumentIndexUpdate']) {
          this.modelServiceGetOne(this.parent_uuid, 'result').then(() => {
            this.loadListData();
          });
        }
      });
    });
  }

  ngOnInit() {
    const _viewMode: boolean = this.storage.getKey(this.localStorageKey.FOLDER_VIEW_LIST_MODE);
    if (_viewMode !== undefined) {
      this.view_mode = _viewMode;
    }
    this.links = this.sidebarFilterLink.buildDocumentFilterLinks();
  }

  public modelServiceGetOne(id, resultVar, page: number = 1) {
    return new Promise((resolve:any, reject:any) => {
      if (typeof id !== 'undefined') {
        return this.folderService.getOne(id).subscribe(res => {
          this.pageLoaded();
          if (res) {
            // Folders
            this.result = this.getFoldersData(res);
            this.folderItems = this.result;
            this.dsFolder = new MatTableDataSource<any>(this.result);
            // Documents
            this.result = this.getDocumentsData(res);
            this.documentItems = this.result;
            this.dsDocument = new MatTableDataSource<any>(this.result);
            this.dataLoaded = true;
          }
          resolve((this[resultVar] = this.result));
        });
      } else {
        this.pageLoaded();
        resolve();
      }
    });
  }

  public get NoRecordForFolderAndDocument(): boolean {
    return this.folderItems.length === 0 && this.documentItems.length === 0;
  }

  public openFormSlideOut(link) {
    const endPoint = `${link}/${this.parent_uuid}`;
    const url = `/folder/view/${this.parent_uuid}/(slideout:${endPoint})`;
    this.router.navigateByUrl(url,
      { skipLocationChange: true }).then(() => this.state.message({ slideoutShow: true }));
  }

  public openFormSlideOutPage(row: ApiModel.Folder | ApiModel.Document) {
    let endPoint = row.type === 'Document' ? 'documentedit' : 'folderedit';
    endPoint = endPoint + '/' + row.uuid;
    const url = `/folder/view/${this.parent_uuid}/(slideout:${endPoint})`;
    this.router.navigateByUrl(url,
      { skipLocationChange: true }).then(() => this.state.message({ slideoutShow: true }));
  }

  public isGroup(index, item): boolean {
    return item.isGroupStarted;
  }

  public modelServiceDelete(row: ApiModel.Folder | ApiModel.Document) {
    const modalService = row.type === 'Document' ? this.documentService : this.folderService;
    this.openDialog(row.type).then(confirmed => {
      if (confirmed) {
        modalService.delete(row.uuid).subscribe(
          result => {
            this.loadListData();
            this.snackbarService.success({ message: `${row.type} deleted` });
          });
      }
    });
  }

  public checkFormChanges($event) {
    $event.valueChanges
      .pipe(
        debounceTime(300),
        switchMap(data => {
          this.queryStringFilter = this.createFilterParams(data);
          console.log(this.queryStringFilter);
          return of(this.modelServiceGetAll());
        })
      )
      .subscribe(() => { });
  }

  public createFilterParams = obj => {
    let filterQueryStr = '';
    Object.keys(obj).forEach(prop => {
      if (obj[prop] && obj[prop] !== '' && !Array.isArray(obj[prop])) {
        filterQueryStr += `&search_field=${prop}&search_term=${obj[prop]}`;
      } else if (Array.isArray(obj[prop])) {
        let selOptions = obj[prop].filter(option => option.selected);
        if (selOptions.length > 0) {
          selOptions = selOptions.map(option => option.id).toString();
          filterQueryStr += `&search_field=${prop}&search_term=${selOptions}`;
        }
      }
    });
    return filterQueryStr;
  }

  // TODO - we will fix the search later.
  public modelServiceGetAll() {
    this.state.message({ pageLoaded: false });
    return new Promise((resolve:any, reject:any) => {

      this.folderService.setFilterVars(this.queryStringFilter);
      return this.folderService.getOne(this.parent_uuid).subscribe(res => {
        this.result = this.getFoldersData(res);
        this.dsFolder = new MatTableDataSource<any>(this.result);
        resolve();
      });
    });
  }

  public openViewFormSlideOutPage(row) {
    let endPoint = 'documentview';
    endPoint = endPoint + '/' + row.uuid;
    const url = `/folder/view/${this.parent_uuid}/${endPoint}`;
    this.router.navigateByUrl(url);
  }

  public openMoveDocFormSlideOutPage(row:  ApiModel.Document) {
    const endPoint = 'documentmove/' + row.uuid;
    const url = `/folder/view/${this.parent_uuid}/(slideout:${endPoint})`;
    this.router.navigateByUrl(url,
      { state: { name: row.name }}).then(() => this.state.message({ slideoutShow: true }));
  }

  public onChangedBreadcrumb(item: ApiModel.Breadcrumb) {
    this.parent_uuid = item.folder_uuid;
    this.modelServiceGetOne(this.parent_uuid, 'result').then(() => {
      this.loadListData();
    });
  }

  public changeViewMode($event) {
    if ($event !== undefined) {
      this.storage.setKey(this.localStorageKey.FOLDER_VIEW_LIST_MODE, $event.checked);
    }
  }

  private pageLoaded() {
    if (!!this.state) {
      this.state.message({ pageLoaded: true });
    }
  }

  private getFoldersData(data) {
    const res = [];
    data.sub_folders.forEach((el, ind) => {
      el.type = 'Folder';
      res.push(el);
    });
    return res;
  }

  private getDocumentsData(data) {
    const res = [];
    data.documents.forEach((el, ind) => {
      el.type = 'Document';
      res.push(el);
    });
    return res;
  }

  private openDialog(title: string): Promise<boolean> {
    return new Promise((resolve:any, reject:any) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        // data: `Confirm deletion of this ${title}(s)?`
        data: `Click 'Delete' to confirm`
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  private loadListData(): void {
    this.modelServiceGetOne(this.parent_uuid, 'result').then(() => {
      this.dataLoaded = true;
    });
  }

}
