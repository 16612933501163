import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MasterSettingService } from '@app/services/master-setting.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { FormValidators } from '@app/modules/common/shared/validators/form-validators.validator';
import { State } from '@app/state';
import { SidebarService } from '@services/sidebar.service';
import { BaseComponent } from '../../base.component';
import { CoreFormAdvancedSelectComponent } from '@app/modules/common/core/components/form-advanced-select/core-form-advanced-select.component';
@Component({
  selector: 'master-setting-form',
  templateUrl: './master-setting-form.component.html',
  styleUrls: ['./master-setting-form.component.scss'],
  providers: [SidebarService]
})
export class MasterSettingFormComponent extends BaseComponent {
  // Local variables
  authUser;
  dataLoaded = false;
  globalUtil = GlobalUtil;
  masterSettingFormGroup: FormGroup;
  showConfirmDeleteMasterButton = false;

  @ViewChild(CoreFormAdvancedSelectComponent, {static: false}) private advancedSelectInput;

  constructor(
    public activatedRoute: ActivatedRoute,
    public masterSettingService: MasterSettingService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: masterSettingService
    });

    this.setPageVars({
      sidebar: ''
    });

    this.initialFormGroupSetup();
    const uuid = this.routeParams['id'];
    if (uuid === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(uuid).subscribe((result: any) => {
        result.setting_value = result.value;
        this.masterSettingFormGroup.patchValue(result);
        this.dataLoaded = true;
      });
    }
  }

  // All the public methods
  get controls(): any {
    return this.masterSettingFormGroup.controls;
  }

  public save(): void {
    const uuid = this.controls.uuid.value;

    if (this.controls.type.value === 'bool') {
      this.replaceBooleanFieldsWithByte();
    }

    if (uuid) {
      // Update the existing row
      this.masterSettingService.patchById(uuid, this.masterSettingFormGroup.value)
        .subscribe(result => this.savedMessage(result));
    } else {
      // Create the new row
      this.masterSettingService.create(this.masterSettingFormGroup.value).subscribe(result => this.savedMessage(result));
    }

    if (this.controls.type.value === 'bool') {
      // Rollback the changes so all the checkboxes will bound correctly
      this.replaceByteFieldsWithBoolean();
    }
  }

  public deleteMasterSetting(): void {
    this.masterSettingService.delete(this.controls.uuid.value).subscribe(result => this.savedMessage(result));
  }

  public cancel(): void {
    this.masterSettingFormGroup.reset();
    this.closeSlideout();
  }

  // All the private methods
  private initialFormGroupSetup(): void {
    // setup the initial form for row entity
    this.masterSettingFormGroup = this.formBuilder.group({
      uuid: [null],
      name: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      slug: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      type: [null, [Validators.required]],
      group: [null, [Validators.required]],
      editable_by_client: [null],
      setting_value: [null, [Validators.required]]
    });
  }

  private savedMessage(data) {
    this.state.message({ ['MasterSettingIndexUpdate']: true });
    this.closeSlideout();
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  // All the private methods (Private method should be after all the public methods)
  private replaceBooleanFieldsWithByte(): void {
    // Replace all the boolean fields with byte
    this.masterSettingFormGroup.patchValue({
      'setting_value': this.controls.setting_value.value ? '1' : '0',
      'editable_by_client': this.controls.editable_by_client.value ? '1' : '0'
    });
  }

  private replaceByteFieldsWithBoolean(): void {
    // Replace all the boolean fields with byte
    this.masterSettingFormGroup.patchValue({
      'setting_value': this.controls.setting_value.value === '1' ? true : false,
      'editable_by_client': this.controls.editable_by_client.value === '1' ? true : false
    });
  }
}
