import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CalendarService } from '@app/services/calendar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';

@Component({
  selector: 'calendars-sort',
  templateUrl: './calendars-sort.component.html',
  styleUrls: [],
  providers: [SidebarService],
})

@Injectable()
export class CalendarSortComponent extends BaseComponent implements OnInit {

  public pageTitle = 'Sort Calendar';
  public dataLoaded = false;
  public datasource: [];
  constructor(
    public activatedRoute: ActivatedRoute,
    public calendarService: CalendarService,
    public sidebarService: SidebarService,
    public state: State,
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: calendarService,
    });
  }

  ngOnInit(): void {
    this.modelServiceGetAll()
      .then(() => {
        this.datasource = this.dataSourceRows.sort(grp => grp.display_order);
        this.dataLoaded = true;
      });
  }

  drop($event: CdkDragDrop<string[]>) {
    moveItemInArray(this.datasource, $event.previousIndex, $event.currentIndex);
    const items = [];
    this.datasource.forEach(function (item) {
      items.push(item['uuid']);
    });
    this.calendarService.sortRecords(items).subscribe();
  }

  public closeSlideout() {
    this.state.message({ ['CalendarIndexUpdate']: true });
    this.state.message({ slideoutHide: true });
  }
}
