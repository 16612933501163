import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { ArticleCategoryService } from '@services/article-category.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
@Component({
  selector: 'app-article-categories-index',
  templateUrl: './article-categories-index.component.html',
  styleUrls: ['./article-categories-index.component.scss']
})
export class ArticleCategoriesIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['featured_image', 'created_at', 'name', 'article_count', 'status', 'actions'];
  public dataLoaded = false;
  public node: Breadcrumb;
  constructor(
    public articleCategoryService: ArticleCategoryService,
    public router: Router,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: articleCategoryService,
      router,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }

  public updatedStatus(): void {
    this.state.message({ ['ArticleCategoryIndexUpdate']: true });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.nodes = [
      {
        label: 'Articles',
        link: 'articles',
      },
      {
        label: 'Article Categories',
        link: 'article-categories',
      }
    ];
  }
}
