import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { EventCategoryService } from '@services/event-category.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
@Component({
  selector: 'app-event-categories-index',
  templateUrl: './event-categories-index.component.html',
  styleUrls: ['./event-categories-index.component.scss']
})
export class EventCategoriesIndexComponent extends BaseComponent implements OnInit {

  public dataSource: any;
  public data: any;
  public displayedColumns = ['name', 'event_count', 'actions'];
  public dataLoaded = false;
  public node: Breadcrumb;
  constructor(
    public eventCategoryService: EventCategoryService,
    public router: Router,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: eventCategoryService,
      router,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.node = {
      label: 'Event Categories',
      link: 'event-categories',
      title: 'Event Categories'
    };
    this.buildSettingsBreadcrumb(this.node);
  }
}
