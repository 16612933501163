import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from './base.service';
import { Cache } from '@app/cache';

@Injectable({
  providedIn: 'root'
})
export class AccessCodeUsersService extends BaseService {

  private AccessCodeUsersCache = [];

  constructor(
    public http: HttpClient,
    public cache: Cache
  ) {
    super(http, cache);
    this.setVars({
      endPoint: 'access-code/{id}/users',
      customPagination: true, // Prevent base.component from initialising pagination
      modelTitle: 'AccessCodeUser'
    });
  }
}
