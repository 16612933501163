import { Component, Injectable, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { UserMessageService } from '@services/user-message.service';
@Component({
  selector: 'user-messages-index',
  templateUrl: '../../../messages/index/messages-index.component.html',
  styleUrls: ['../../../messages/index/messages-index.component.scss'],
  providers: [SidebarService]
})
@Injectable()
export class UserMessagesIndexComponent extends BaseComponent implements OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  public fields: any;
  public links: [];
  public onFilterClicked: any;
  hideSidebar = true;
  hideSearch = true;

  @Input() uuid: string;


  public dataSource: any;
  public data: any;
  public displayedColumns = ['created_by_user.name', 'title', 'recipient_count', 'delivery', 'actions'];
  public dataLoaded = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public userMessageService: UserMessageService,
    public sidebarService: SidebarService,
    public state: State
  ) {
    super();
    this.setObjects({
      modelService: userMessageService,
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state
    });
    this.setPageVars({
      listenForIndexUpdate: true
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.modelService.getAllWithId(this.uuid).subscribe(data => {
      this.dataSource = new MatTableDataSource(data['data']);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataLoaded = true;
    });
  }

  createMessage() {
    document.getElementById('sidebar').classList.add('open');
    this.openSlideOutWithLink('messages', 'createForm');
  }

}
