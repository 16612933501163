import { iconElement } from './../utils';

export const BUNDLE_ARTICLE_WIDGETS_CATEGORY = 'Bundles / Articles';
export const BUTTONS_CATEGORY = 'Buttons';
export const LAYOUT_CATEGORY = 'Layout';
export const MEDIA_CATEGORY = 'Media';
export const TEXT_CATEGORY = 'Text';
export const WIDGETS_CATEGORY = 'Widgets';

export const blockIconTitle = (title, icon) => `${iconElement(icon, true)}<span>${title}</span>`;

import {
  // Layout
  ICON_LAYOUT_ACCORDION,
  ICON_LAYOUT_DIVIDER,
  ICON_LAYOUT_PAGE_SECTION,
  ICON_LAYOUT_PLACEHOLDER,
  ICON_LAYOUT_ROW,
  ICON_LAYOUT_SECTION,
  // Text
  ICON_TEXT_HEADING,
  ICON_TEXT_TEXT_BLOCK,
  // Media
  ICON_MEDIA_IMAGE,
  ICON_MEDIA_IMAGE_SLIDER,
  ICON_MEDIA_VIDEO,
  ICON_MEDIA_VIDEO_PLAYLIST,
  // Buttons
  ICON_BUTTON_APP_COMPONENT,
  ICON_BUTTON_APP_PAGE,
  ICON_BUTTON_ARTICLE,
  ICON_BUTTON_ARTICLE_CATEGORY,
  ICON_BUTTON_BUNDLE,
  ICON_BUTTON_CALENDAR,
  ICON_BUTTON_CHAT,
  ICON_BUTTON_DIRECTORY_ITEM,
  ICON_BUTTON_DIRECTORY_CATEGORY,
  ICON_BUTTON_CONTACT_GROUP,
  ICON_BUTTON_DOCUMENT,
  ICON_BUTTON_EVENT,
  ICON_BUTTON_FILE,
  ICON_BUTTON_FOLDER,
  ICON_BUTTON_PERIODISATION_CALENDAR,
  ICON_BUTTON_PLACEHOLDER,
  ICON_BUTTON_PRODUCT_URL,
  ICON_BUTTON_PROFILE_GROUP,
  ICON_BUTTON_URL,
  ICON_BUTTON_USER_CIRCLE,
  // Bundles / Articles
  // Widget
  ICON_WIDGET_EMBED,
  ICON_WIDGET_MAP
} from './../../const/icons';

import {
  // Layout
  COMP_LAYOUT_ACCORDION,
  COMP_LAYOUT_DIVIDER,
  COMP_LAYOUT_PAGE_SECTION,
  COMP_LAYOUT_PLACEHOLDER,
  COMP_LAYOUT_ROW,
  COMP_LAYOUT_SECTION,
  // Text
  COMP_TEXT,
  COMP_TEXT_BLOCK,
  COMP_TEXT_BLOCK_OLD,
  COMP_TEXT_HEADING,
  COMP_TEXT_INLINE_ITEM,
  // Media
  COMP_MEDIA_IMAGE,
  COMP_MEDIA_IMAGE_SLIDER,
  COMP_MEDIA_VIDEO,
  COMP_MEDIA_VIDEO_PLAYLIST,
  // Bundle / Articles
  COMP_WIDGET_ARTICLE_CATEGORY,
  COMP_WIDGET_BUNDLE,
  // Buttons
  COMP_BUTTON_APP_COMPONENT,
  COMP_BUTTON_APP_PAGE,
  COMP_BUTTON_ARTICLE,
  COMP_BUTTON_ARTICLE_CATEGORY,
  COMP_BUTTON_BUNDLE,
  COMP_BUTTON_CALENDAR,
  COMP_BUTTON_CHAT,
  COMP_BUTTON_DIRECTORY_CATEGORY,
  COMP_BUTTON_DIRECTORY_ITEM,
  COMP_BUTTON_CONTACT_GROUP,
  COMP_BUTTON_DOCUMENT,
  COMP_BUTTON_EVENT,
  COMP_BUTTON_FILE,
  COMP_BUTTON_FOLDER,
  COMP_BUTTON_PERIODISATION_CALENDAR,
  COMP_BUTTON_PLACEHOLDER,
  COMP_BUTTON_PRODUCT_URL,
  COMP_BUTTON_PROFILE_GROUP,
  COMP_BUTTON_URL,
  COMP_BUTTON_USER_CIRCLE,
  // Widgets
  COMP_WIDGET_DIRECTORY_CATEGORY,
  COMP_WIDGET_EMBED,
  COMP_WIDGET_MAP
} from './../../const/components';

// Layout
export const BLOCK_LAYOUT_ACCORDION = BlockManager => {
  BlockManager.add(COMP_LAYOUT_ACCORDION, {
    label: blockIconTitle('Accordion', ICON_LAYOUT_ACCORDION),
    category: LAYOUT_CATEGORY,
    content: { type: COMP_LAYOUT_ACCORDION }
  });
}
export const BLOCK_LAYOUT_ROW = BlockManager => {
  BlockManager.add(COMP_LAYOUT_ROW, {
    label: blockIconTitle('Row', ICON_LAYOUT_ROW),
    category: LAYOUT_CATEGORY,
    content: { type: COMP_LAYOUT_ROW }
  });
}
export const BLOCK_LAYOUT_PAGE_SECTION = BlockManager => {
  BlockManager.add(COMP_LAYOUT_PAGE_SECTION, {
    label: blockIconTitle('Page Section', ICON_LAYOUT_PAGE_SECTION),
    category: LAYOUT_CATEGORY,
    content: { type: COMP_LAYOUT_PAGE_SECTION }
  });
}
export const BLOCK_LAYOUT_PLACEHOLDER = BlockManager => {
  BlockManager.add(COMP_LAYOUT_PLACEHOLDER, {
    label: blockIconTitle('Placeholder', ICON_LAYOUT_PLACEHOLDER),
    category: LAYOUT_CATEGORY,
    content: { type: COMP_LAYOUT_PLACEHOLDER }
  });
}
export const BLOCK_LAYOUT_SECTION = BlockManager => {
  BlockManager.add(COMP_LAYOUT_SECTION, {
    label: blockIconTitle('Section', ICON_LAYOUT_SECTION),
    category: LAYOUT_CATEGORY,
    content: { type: COMP_LAYOUT_SECTION }
  });
}
export const BLOCK_LAYOUT_DIVIDER = BlockManager => {
  BlockManager.add(COMP_LAYOUT_DIVIDER, {
    label: blockIconTitle('Divider', ICON_LAYOUT_DIVIDER),
    category: LAYOUT_CATEGORY,
    content: { type: COMP_LAYOUT_DIVIDER }
  });
}

// Text
export const BLOCK_TEXT_HEADING = BlockManager => {
  BlockManager.add(COMP_TEXT_HEADING, {
    label: blockIconTitle('Heading', ICON_TEXT_HEADING),
    category: TEXT_CATEGORY,
    content: { type: COMP_TEXT_HEADING }
  });
}
export const BLOCK_TEXT_BLOCK_OLD = BlockManager => {
  BlockManager.add(COMP_TEXT_BLOCK_OLD, {
    label: blockIconTitle('Text', ICON_TEXT_TEXT_BLOCK),
    category: TEXT_CATEGORY,
    badgable: false,
    highlightable: false,
    hoverable: false,
    content: COMP_TEXT
  });
}
export const BLOCK_TEXT_BLOCK = BlockManager => {
  BlockManager.add(COMP_TEXT_BLOCK, {
    label: blockIconTitle('Text Block', ICON_TEXT_TEXT_BLOCK),
    category: TEXT_CATEGORY,
    // badgable: false,
    // highlightable: false,
    // hoverable: false,
    content: { type: COMP_TEXT_BLOCK }
  });
}

// Media
export const BLOCK_MEDIA_IMAGE = BlockManager => {
  BlockManager.add(COMP_MEDIA_IMAGE, {
    label: blockIconTitle('Image', ICON_MEDIA_IMAGE),
    category: MEDIA_CATEGORY,
    content: { type: COMP_MEDIA_IMAGE },
  });
}
export const BLOCK_MEDIA_IMAGE_SLIDER = BlockManager => {
  BlockManager.add(COMP_MEDIA_IMAGE_SLIDER, {
    label: blockIconTitle('Image Slider', ICON_MEDIA_IMAGE_SLIDER),
    category: MEDIA_CATEGORY,
    content: { type: COMP_MEDIA_IMAGE_SLIDER }
  });
}
export const BLOCK_MEDIA_VIDEO = BlockManager => {
  BlockManager.add(COMP_MEDIA_VIDEO, {
    label: blockIconTitle('Video', ICON_MEDIA_VIDEO),
    category: MEDIA_CATEGORY,
    content: { type: COMP_MEDIA_VIDEO }
  });
}
export const BLOCK_MEDIA_VIDEO_PLAYLIST = BlockManager => {
  BlockManager.add(COMP_MEDIA_VIDEO_PLAYLIST, {
    label: blockIconTitle('Video Playlist', ICON_MEDIA_VIDEO_PLAYLIST),
    category: MEDIA_CATEGORY,
    content: { type: COMP_MEDIA_VIDEO_PLAYLIST }
  });
}

// Bundle / Articles
export const BLOCK_WIDGET_ARTICLE_CATEGORY = BlockManager => {
  BlockManager.add(COMP_WIDGET_ARTICLE_CATEGORY, {
    label: blockIconTitle('Article Category', ICON_BUTTON_ARTICLE_CATEGORY),
    category: BUNDLE_ARTICLE_WIDGETS_CATEGORY,
    content: { type: COMP_WIDGET_ARTICLE_CATEGORY }
  });
}
export const BLOCK_WIDGET_BUNDLE = BlockManager => {
  BlockManager.add(COMP_WIDGET_BUNDLE, {
    label: blockIconTitle('Bundle', ICON_BUTTON_BUNDLE),
    category: BUNDLE_ARTICLE_WIDGETS_CATEGORY,
    content: { type: COMP_WIDGET_BUNDLE }
  });
}
export const BLOCK_WIDGET_DIRECTORY_CATEGORY = BlockManager => {
  BlockManager.add(COMP_WIDGET_DIRECTORY_CATEGORY, {
    label: blockIconTitle('Directory Category', ICON_BUTTON_DIRECTORY_CATEGORY),
    category: BUNDLE_ARTICLE_WIDGETS_CATEGORY,
    content: { type: COMP_WIDGET_DIRECTORY_CATEGORY }
  });
}

// Buttons
export const BLOCK_BUTTON_APP_COMPONENT = BlockManager => {
  BlockManager.add(COMP_BUTTON_APP_COMPONENT, {
    label: blockIconTitle('App Component', ICON_BUTTON_APP_COMPONENT),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_APP_COMPONENT }
  });
}
export const BLOCK_BUTTON_APP_PAGE = BlockManager => {
  BlockManager.add(COMP_BUTTON_APP_PAGE, {
    label: blockIconTitle('App Page', ICON_BUTTON_APP_PAGE),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_APP_PAGE }
  });
}
export const BLOCK_BUTTON_ARTICLE = BlockManager => {
  BlockManager.add(COMP_BUTTON_ARTICLE, {
    label: blockIconTitle('Article', ICON_BUTTON_ARTICLE),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_ARTICLE }
  });
}
export const BLOCK_BUTTON_ARTICLE_CATEGORY = BlockManager => {
  BlockManager.add(COMP_BUTTON_ARTICLE_CATEGORY, {
    label: blockIconTitle('Article Category', ICON_BUTTON_ARTICLE_CATEGORY),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_ARTICLE_CATEGORY }
  });
}
export const BLOCK_BUTTON_BUNDLE = BlockManager => {
  BlockManager.add(COMP_BUTTON_BUNDLE, {
    label: blockIconTitle('Bundle', ICON_BUTTON_BUNDLE),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_BUNDLE }
  });
}
export const BLOCK_BUTTON_CALENDAR = BlockManager => {
  BlockManager.add(COMP_BUTTON_CALENDAR, {
    label: blockIconTitle('Calendar', ICON_BUTTON_CALENDAR),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_CALENDAR }
  });
}
export const BLOCK_BUTTON_CHAT = BlockManager => {
  BlockManager.add(COMP_BUTTON_CHAT, {
    label: blockIconTitle('Chat', ICON_BUTTON_CHAT),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_CHAT }
  });
}
export const BLOCK_BUTTON_CONTACT_GROUP = BlockManager => {
  BlockManager.add(COMP_BUTTON_CONTACT_GROUP, {
    label: blockIconTitle('Contact Group', ICON_BUTTON_CONTACT_GROUP),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_CONTACT_GROUP }
  });
}
export const BLOCK_BUTTON_DIRECTORY_CATEGORY = BlockManager => {
  BlockManager.add(COMP_BUTTON_DIRECTORY_CATEGORY, {
    label: blockIconTitle('Directory Category', ICON_BUTTON_DIRECTORY_CATEGORY),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_DIRECTORY_CATEGORY }
  });
}
export const BLOCK_BUTTON_DIRECTORY_ITEM = BlockManager => {
  BlockManager.add(COMP_BUTTON_DIRECTORY_ITEM, {
    label: blockIconTitle('Directory Item', ICON_BUTTON_DIRECTORY_ITEM),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_DIRECTORY_ITEM }
  });
}
export const BLOCK_BUTTON_DOCUMENT = BlockManager => {
  BlockManager.add(COMP_BUTTON_DOCUMENT, {
    label: blockIconTitle('Document', ICON_BUTTON_DOCUMENT),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_DOCUMENT }
  });
}
export const BLOCK_BUTTON_EVENT = BlockManager => {
  BlockManager.add(COMP_BUTTON_EVENT, {
    label: blockIconTitle('Event', ICON_BUTTON_EVENT),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_EVENT }
  });
}
export const BLOCK_BUTTON_FOLDER = BlockManager => {
  BlockManager.add(COMP_BUTTON_FOLDER, {
    label: blockIconTitle('Folder', ICON_BUTTON_FOLDER),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_FOLDER }
  });
}
export const BLOCK_BUTTON_FILE = BlockManager => {
  BlockManager.add(COMP_BUTTON_FILE, {
    label: blockIconTitle('File Upload', ICON_BUTTON_FILE),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_FILE }
  });
}
export const BLOCK_BUTTON_PERIODISATION_CALENDAR = BlockManager => {
  BlockManager.add(COMP_BUTTON_PERIODISATION_CALENDAR, {
    label: blockIconTitle('Periodisation Calendar', ICON_BUTTON_PERIODISATION_CALENDAR),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_PERIODISATION_CALENDAR }
  });
}
export const BLOCK_BUTTON_PLACEHOLDER = BlockManager => {
  BlockManager.add(COMP_BUTTON_PLACEHOLDER, {
    label: blockIconTitle('Placeholder', ICON_BUTTON_PLACEHOLDER),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_PLACEHOLDER }
  });
}
export const BLOCK_BUTTON_PRODUCT_URL = BlockManager => {
  BlockManager.add(COMP_BUTTON_PRODUCT_URL, {
    label: blockIconTitle('Product Url', ICON_BUTTON_PRODUCT_URL),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_PRODUCT_URL }
  });
}
export const BLOCK_BUTTON_PROFILE_GROUP = BlockManager => {
  BlockManager.add(COMP_BUTTON_PROFILE_GROUP, {
    label: blockIconTitle('Profile Group', ICON_BUTTON_PROFILE_GROUP),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_PROFILE_GROUP }
  });
}
export const BLOCK_BUTTON_URL = BlockManager => {
  BlockManager.add(COMP_BUTTON_URL, {
    label: blockIconTitle('Url', ICON_BUTTON_URL),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_URL }
  });
}
export const BLOCK_BUTTON_USER_CIRCLE = BlockManager => {
  BlockManager.add(COMP_BUTTON_USER_CIRCLE, {
    label: blockIconTitle('User Circle', ICON_BUTTON_USER_CIRCLE),
    category: BUTTONS_CATEGORY,
    content: { type: COMP_BUTTON_USER_CIRCLE }
  });
}

// Widgets
export const BLOCK_WIDGET_EMBED = BlockManager => {
  BlockManager.add(COMP_WIDGET_EMBED, {
    label: blockIconTitle('Embed', ICON_WIDGET_EMBED),
    category: WIDGETS_CATEGORY,
    content: { type: COMP_WIDGET_EMBED }
  });
}
export const BLOCK_WIDGET_MAP = BlockManager => {
  BlockManager.add(COMP_WIDGET_MAP, {
    label: blockIconTitle('Map', ICON_WIDGET_MAP),
    category: WIDGETS_CATEGORY,
    content: { type: COMP_WIDGET_MAP }
  });
}