export const FOLDER_FILTER_FIELDS = [
  {
    type: 'text',
    name: 'name',
    label: 'Keywords',
    value: ''
  },
  {
    type: 'date',
    name: 'created_at',
    label: 'Created At',
    value: ''
  },
  {
    type: 'dropdown',
    name: 'date_match',
    label: 'Exact Date',
    value: '',
    options: [
      {
        text: 'Exact Date',
        value: 'exact'
      },
      {
        text: 'Before',
        value: 'before'
      },
      {
        text: 'On and Before',
        value: 'onandbefore'
      },
      {
        text: 'After',
        value: 'after'
      },
      {
        text: 'On and After',
        value: 'onandafter'
      }
    ]
  }
];
