// All the material related modules should be decleared here
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatChipsModule,
  MatMenuModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatCardModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatTooltipModule,
  MatDividerModule,
  MatExpansionModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatGridListModule,
  MatFormFieldModule,
  MatTreeModule,
  MatRadioModule
} from '@angular/material';

const MATERIAL_MODULES = [
  CommonModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatChipsModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MatCardModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatDividerModule,
  MatExpansionModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatGridListModule,
  MatFormFieldModule,
  MatTreeModule,
  MatRadioModule
];
@NgModule({
  imports: [
    ...MATERIAL_MODULES
  ],
  exports: [
   ...MATERIAL_MODULES
  ]
})
export class MaterialModule {
}
