import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { ArticleService } from '@services/article.service';
import { SidebarService } from '@services/sidebar.service';
import { ARTICLE_FILTER_FIELDS } from './filter-fields';
import { FilterLink } from '@app/models/view-models/filter-link';
import { SidebarFilterLink } from '@app/services/sidebar-filter-link.service';
@Component({
  selector: 'app-articles-index',
  templateUrl: './articles-index.component.html',
  styleUrls: ['./articles-index.component.scss', './articles-index-700.component.scss'],
  providers: [SidebarService]
})
export class ArticlesIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['select', 'title', 'article_categories', 'created_by_user.name', 'created_at', 'status', 'actions'];
  public dataLoaded = false;
  public fields: any = ARTICLE_FILTER_FIELDS;
  public links: FilterLink[] = [];
  constructor(
    public articleService: ArticleService,
    public router: Router,
    public sidebarService: SidebarService,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog,
    private sidebarFilterLink: SidebarFilterLink
  ) {
    super();
    this.setObjects({
      modelService: articleService,
      router,
      sidebarService,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      sidebar: 'articles',
      listenForIndexUpdate: true,
    });
    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.links = this.sidebarFilterLink.buildArticlesFilterLinks();
  }

  public updatedStatus(): void {
    this.state.message({ ['ArticleIndexUpdate']: true });
  }

  public onFilterClicked(link: FilterLink): void {
    if (link.route) {
      this.router.navigate([link.route]);
    }
  }
}
