import { Component, OnInit, Input, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { State } from '@app/state';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTable, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { ApiModel } from '@app/models/entities/api-model';
import * as Moment from 'moment';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { EventService } from '@app/services/event.service';
import { SnackBarService } from '@app/services/snack-bar.service';
import { takeUntil } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';

@Component({
  selector: 'event-bulk-edit',
  templateUrl: './event-bulk-edit.component.html',
  styleUrls: ['./event-bulk-edit.component.scss'],
})

export class EventBulkEditComponent implements OnInit, OnDestroy
{
  @Input() heading = 'Edit Events ';
  @ViewChild(MatPaginator, {static: false}) public paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) public sort: MatSort;
  @ViewChild(MatTable, {static: false}) table : MatTable<any>;

  dataSource: any;
  formValid: any;
  displayedColumns = ['name', 'start_time', 'end_time', 'action'];
  selection = new SelectionModel<ApiModel.Event>(true, []);

  private config = GlobalUtil.Configuration;
  private selectedItems:Array<any> = [];

  private unsubscribe: Subject<void> = new Subject<void>();
  private updateSelectedItems: boolean = false;

  public saveAllSubmitted: boolean = false;
  public intervalApplied: boolean = false;
  public intervalApplyTo: string = 'both';
  public intervalDuration: number = 10;
  public intervalPeriod: string = 'minutes';
  public intervalType: string = 'add';

  private itemsMap: Array<any> = [];

  get allFormsValid(): boolean {
    var valid = true;
    this.dataSource.data.forEach(item => {
      if (item.formInvalid) {
        valid = false;
      }
    });
    return valid;
  }

  constructor(
    private changeDetectorRef : ChangeDetectorRef,
    private snackbarService: SnackBarService,
    private readonly eventService: EventService,
    private readonly state: State
  ) {
    const sub = this.state.subscription().subscribe(state => {
      if (!! state.data) {
        this.selection = state.data;
        this.selectedItems = (state.data.selected as ApiModel.Event[]);
        sub.unsubscribe();
      }
    });
  }

  private setSelectedItemsMatTable(): void {
    this.itemsMap = [];

    let items = JSON.parse(JSON.stringify(this.selectedItems));

    if (this.intervalApplied) {
      items.map(item => {
        if(! item.duplicatedStopInterval) {
          if (this.intervalApplyTo === 'start' || this.intervalApplyTo === 'both') {
            item.start_time_local = Moment(item.start_time_local)
              [this.intervalType](this.intervalDuration, this.intervalPeriod)
              .format(this.config.LONG_DATE_FORMAT_FOR_SAVE_ENTRY);
            item.start_time = Moment(item.start_time)
              [this.intervalType](this.intervalDuration, this.intervalPeriod)
              .format(this.config.LONG_DATE_FORMAT_FOR_SAVE_ENTRY);
          }
          if (this.intervalApplyTo === 'end' || this.intervalApplyTo === 'both') {
            item.end_time_local = Moment(item.end_time_local)
              [this.intervalType](this.intervalDuration, this.intervalPeriod)
              .format(this.config.LONG_DATE_FORMAT_FOR_SAVE_ENTRY);
            item.end_time = Moment(item.end_time)
              [this.intervalType](this.intervalDuration, this.intervalPeriod)
              .format(this.config.LONG_DATE_FORMAT_FOR_SAVE_ENTRY);
          }
        }
        return item;
      });
    }

    this.selectedItems.map(selectedItem => {
      delete selectedItem['duplicatedStopInterval'];
      return selectedItem;
    });

    this.dataSource = new MatTableDataSource(items);
    items.forEach(item => {
      var itemToAdd:any = {
        name: item.name,
        start_time: item.start_time_local,
        end_time: item.end_time_local,
        start_time_timezone_uuid: item.start_time_timezone.uuid,
        end_time_timezone_uuid: item.end_time_timezone.uuid,
        all_day_event: item.all_day_event
      };

      if(!! item.uuid) {
        itemToAdd.uuid = item.uuid;
      }

      if(!! item.originalUuid) {
        itemToAdd.originalUuid = item.originalUuid;
      }

      this.itemsMap.push(itemToAdd);
    });

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.setSelectedItemsMatTable();
  }

  public applyInterval(): void {
    this.intervalApplied = true;
    this.setSelectedItemsMatTable();
  }

  public clearInterval(): void {
    this.intervalApplied = false;
    this.setSelectedItemsMatTable();
  }

  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  public closeSlideout(): void {
    this.selection.clear();
    this.dataSource.data = [];
    this.state.message({['EventIndexUpdate']: true, slideoutHide: true });
  }

  public setStartDate(row: any, $event): void {
    row.start_time = this.transformDateTime($event);
    row.start_time_local = $event;
    let itemIndex = this.itemsMap.findIndex(item => item.uuid === row.uuid);
    this.itemsMap[itemIndex].start_time = row.start_time;
    this.changeDetectorRef.detectChanges();
  }

  public setEndDate(row: any, $event): void {
    row.end_time = this.transformDateTime($event);
    row.end_time_local = $event;
    let itemIndex = this.itemsMap.findIndex(item => item.uuid === row.uuid);
    this.itemsMap[itemIndex].end_time = row.end_time;
    this.changeDetectorRef.detectChanges();
  }

  public setName(row: ApiModel.Event, $event): void {
    row.name = $event.target.value;
    let itemIndex = this.itemsMap.findIndex(item => item.uuid === row.uuid);
    this.itemsMap[itemIndex].name = row.name;
  }

  private transformDateTime(val: any): any {
    return Moment(val).format(this.config.LONG_DATE_FORMAT_FOR_SAVE_ENTRY);
  }

  public duplicate(element, index) {
    const newElement = JSON.parse(JSON.stringify(element));
    const newIndex = index + 1;
    newElement.originalUuid = newElement.uuid;
    newElement.duplicatedStopInterval = true;
    delete newElement.uuid;
    newElement.name = `Copy - ${element.name}`;
    this.selectedItems.splice(newIndex, 0, newElement);
    // this.selectedItems[index] = element;
    setTimeout(() => {
      this.setSelectedItemsMatTable();
      setTimeout(() => {
        this.itemsMap[newIndex].originalUuid = newElement.originalUuid;
        this.itemsMap[newIndex].name = newElement.name;
        delete this.itemsMap[newIndex].uuid;
        this.changeDetectorRef.detectChanges();
      });
    });
  }

  public remove(index) {
    this.selectedItems.splice(index, 1);
    this.itemsMap.splice(index, 1);
    this.setSelectedItemsMatTable();
  }

  // Delete multiple items selected from table checkboxes
  public saveAll() {
    if (this.selectedItems.length === 0) {
      this.snackbarService.warning({ message: `No events to save` });
      return;
    }

    const promises = [];
    const snackbarRef = this.snackbarService.openProgress('Saving...');

    this.saveAllSubmitted = true;
    this.state.message({ hideSnackBarNotifications: true });

    this.itemsMap.forEach(row => {
      const promise = new Promise((resolve, reject) => {
        if (!! row.uuid) {
          this.eventService.patchById(row.uuid, row).subscribe(
            (result:any) => resolve(result),
            (error: any) => {
              this.saveAllSubmitted = false;
              resolve(error);
            }
          );
        } else {
          this.eventService.duplicateFromBulkEdit((row as any)).subscribe(
            (result:any) => resolve(result),
            (error: any) => {
              this.saveAllSubmitted = false;
              resolve(error);
            }
          );
        }
      });
      promises.push(promise);
    });

    Promise.all(promises)
      .catch(error => {
        console.log(error)
        this.saveAllSubmitted = false;
      })
      .then(result => {
        this.saveAllSubmitted = false;
        snackbarRef.dismiss();
        this.snackbarService.success({ message: `Saved successfully` });
        this.state.message({ hideSnackBarNotifications: false });
        this.closeSlideout();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}