import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit
{
  @Input() imageSrc:string = '';
  @Input() imageAlt:string = '';
  @Input() placeholderIcon = 'account_circle';

  public imageUrl:string;
  public secureUrl:boolean = true;

  constructor() { }

  ngOnInit() {
    if(this.imageSrc.includes('/img/default-avatar.jpg')) {
      this.secureUrl = false;
      this.imageUrl = this.imageSrc;
    } else {
      if(this.imageSrc.substr(-2) === '//') {
        this.imageSrc = this.imageSrc.substr(0, this.imageSrc.length - 2);
      }
      if(this.imageSrc.substr(-1) === '/') {
        this.imageSrc = this.imageSrc.substr(0, this.imageSrc.length - 1);
      }
      this.imageUrl = `${this.imageSrc}/-/preview/-/resize/60x60/`;
    }
  }
}