import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { MessageDocumentsService } from '@services/message-documents.service';

@Component({
  selector: 'app-message-sort-documents',
  templateUrl: './message-sort-documents.component.html',
})

@Injectable()
export class MessageSortDocumentsComponent extends BaseComponent implements OnInit {

  public dataLoaded = false;
  public datasource: [];
  public messageId: string;
  constructor(
    public activatedRoute: ActivatedRoute,
    public messageDocumentsService: MessageDocumentsService,
    public state: State,
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      state: state,
      modelService: messageDocumentsService,
    });
  }

  ngOnInit(): void {
    this.messageId = this.routeParams['id'];
    this.modelService.getAllWithId(this.messageId).subscribe(data => {
      this.datasource = data.sort(d => d.display_order);
      this.dataLoaded = true;
    });
  }

  drop($event: CdkDragDrop<string[]>) {
    moveItemInArray(this.datasource, $event.previousIndex, $event.currentIndex);
    const items = [];
    const endpoint = 'admin/message/' + this.messageId + '/sort-documents';
    this.datasource.forEach(function (item) {
      items.push(item['uuid']);
    });
    this.messageDocumentsService.sortRecords(items, null, endpoint).subscribe();
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }
}
