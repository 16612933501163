import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State } from '@app/state';
import { BaseService } from './base.service';
import { Cache } from '@app/cache';
import { Environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  private userCache = [];
  private baseUrl = '';
  public expectReturnResultAfterSave:boolean = true;

  constructor(
    public http: HttpClient,
    public cache: Cache,
    public state: State,
  ) {
    super(http, cache);
    this.setVars({
      endPoint: 'user',
      modelTitle: 'User'
    });

    const settings = this.state.get('ccSettings');
    this.baseUrl = settings.uploadcare.url;
  }

  mapUser(data) {
    const returnData: any = {};

    returnData.uuid = data.uuid;
    returnData.firstname = data.firstname;
    returnData.lastname = data.lastname;
    returnData.groups = data.groups;
    returnData.details = [];
    returnData.details.push({ 'key': 'Mobile', 'value': data.mobile, 'icon': 'phone', 'model': 'mobile' });
    returnData.details.push({ 'key': 'Telephone', 'value': data.profile.telephone, 'icon': 'phone', 'model': 'profile.telephone' });
    returnData.details.push({ 'key': 'Email', 'value': data.email, 'icon': 'envelope', 'model': 'email' });
    returnData.details.push({ 'key': 'Address', 'value': data.profile.address, 'icon': 'house', 'model': 'profile.address' });
    returnData.details.push({
      'key': 'Status',
      'value': data.profile.marital_status,
      'icon': 'two-people',
      'model': 'profile.marital_status'
    });

    let dateOfBirthString = '';
    if (data.profile.date_of_birth) {
      let year, month, day;
      [year, month, day] = data.profile.date_of_birth.substr(0, 10).split('-');
      // Month indexes start at zero in the Javascript Date class
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
      const dateOfBirth = new Date(year, month - 1, day);
      const datePipe = new DatePipe('en-US');
      dateOfBirthString = datePipe.transform(dateOfBirth, 'MMM d yyyy');
    } else {
      dateOfBirthString = '';
    }

    returnData.avatar = this.baseUrl + data.profile.avatar + `/-/preview/-/resize/350x/`;
    console.log(returnData);
    if (data.profile.avatar) {
      const avatarUUID = data.profile.avatar;
      returnData.avatarUUID = avatarUUID;
    } else {
      returnData.avatar = '/assets/img/avatarupload/upload.png';
    }

    return returnData;
  }

  sendCalendarLink(uuid: string) {
    const productId = this.state.get('productId');
    const url = `${Environment.apiURL}user/${uuid}/send-calendar-email/${productId}`;
    return this.http.get<object>(url);
  }

  inviteByEmail(uuid: string) {
    const url = `${Environment.apiURL}spid/user/${uuid}/send-invite`;
    return this.http.post<object>(url, {
      user_uuid: uuid,
      spid_invite_by_email: true,
    });
  }

  inviteBySms(uuid: string) {
    const url = `${Environment.apiURL}spid/user/${uuid}/send-invite`;
    return this.http.post<object>(url, {
      user_uuid: uuid,
      spid_invite_by_sms: true,
    });
  }

  // chat
  alluserdata(uuid: string) {
    const url = `${Environment.apiURL}user/${uuid}`;
    return this.http.get<object>(url);
  }

}
