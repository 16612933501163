import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Cache } from '@app/cache';

@Injectable({
  providedIn: 'root'
})
export class ClientSettingService extends BaseService {

  private clientSettingCache = [];

  constructor(
    public http: HttpClient,
    public cache: Cache
  ) {
    super(http, cache);
    this.setVars({
      endPoint: 'client-setting',
      modelTitle: 'Client Setting'
    });
  }

  public getClientSettings()
  {
    return this.getFromApi(`client-settings\\required-by-cc`);
  }
}
