import { paramCase } from 'change-case';

import { modelSetClass, updatePageFormItemPerms } from './../../utils';

import {
    COMP_LAYOUT_ACCORDION,
    COMP_LAYOUT_SECTION,
    COMP_MEDIA_VIDEO
} from './../../../const/components';

import { ICON_MEDIA_VIDEO } from '../../../const/icons';

const compParamName = paramCase(COMP_MEDIA_VIDEO);

export const Video = (editor, options:any = {}) => {
    return {
        isComponent: el => el.tagName === compParamName.toUpperCase(),
        model: {
            defaults: {
                displayIcon: ICON_MEDIA_VIDEO,
                tagName: compParamName,
                name: 'Video',
                draggable: `${paramCase(COMP_LAYOUT_SECTION)},${paramCase(COMP_LAYOUT_ACCORDION)}`,
                droppable: false,
                resizable: false,
                ytVideoId: 'wBtpGdUSOFQ',
                viVideoId: 226053498,
                attributes: { provider: 'yt', url: null },
                traits:[{
                    type: 'select',
                    label: 'Provider',
                    name: 'provider',
                    options: [{ value: 'yt', name: 'Youtube' }, { value: 'vi', name: 'Vimeo' }]
                }, {
                    name: 'videoId',
                    label: 'Video ID'
                }, {
                    name: 'url',
                    label: 'Video URL'
                },
                // {
                //     name: 'fullWidth',
                //     type: 'checkbox',
                //     label: 'Full Width'
                // },
                {
                    name: 'autoplay',
                    type: 'checkbox',
                    label: 'Autoplay'
                }, {
                    name: 'controls',
                    type: 'checkbox',
                    label: 'Controls'
                }, {
                    name: 'loop',
                    type: 'checkbox',
                    label: 'Loop'
                }]
            }
        },
        view: {
            // has to be div as it misbehaves with custom tag names
            tagName: compParamName,
            // Init
            init() {
                updatePageFormItemPerms.call(this, editor);
                this.listenTo(this.model, 'change:attributes:videoId change:attributes:provider change:attributes:controls', this.handleAttributeChanges);
            },
            // Handle attr changes
            handleAttributeChanges() {
                updatePageFormItemPerms.call(this, editor);
                this.render();
            },
            // On render
            onRender() {
                // Timeout needed to help get canvas width correctly
                setTimeout(() => {
                    const attrs = this.model.getAttributes();
                    const canvasWrapperEl = editor.DomComponents.getWrapper().getEl();
                    const canvasWindow = editor.Canvas.getWindow();
                    const computedStyle = canvasWindow.getComputedStyle(canvasWrapperEl);
                    const canvasWrapperWidthText = computedStyle.width;
                    const videoAspectHeight = (canvasWrapperWidthText.replace('px', '') / 1.77);
                    const cont = document.createElement('div');
                    const autoplay = attrs.autoplay === true ? '1' : '0';
                    const loop = attrs.loop === true ? '1' : '0';
                    var videoIframe:any = document.createElement('iframe');
                    var id;
                    var uri;
                    var controls;

                    if (attrs.provider === 'yt') {
                        id = !! attrs.videoId ? attrs.videoId : this.model.get('ytVideoId');
                        uri = 'https://www.youtube.com/embed/' + id;
                        if (attrs.controls !== true) {
                            controls = 'controls=0&showinfo=0';
                        }
                    }

                    if (attrs.provider === 'vi') {
                        id = !! attrs.videoId ? attrs.videoId : this.model.get('viVideoId');
                        uri = 'https://player.vimeo.com/video/' + id;
                        if (attrs.controls !== true) {
                            controls = 'title=0&portrait=0&badge=0&transparent=0';
                        }
                    }

                    if(!! attrs.url) {
                        videoIframe.src = attrs.url;
                    } else {
                        videoIframe.src = `${uri}?${controls}`;
                    }

                    // videoIframe.src = `${uri}?autoplay=${autoplay}&loop=${loop}${controls}`;
                    videoIframe.height = videoAspectHeight;
                    videoIframe.frameBorder = 0;
                    videoIframe.setAttribute('allowfullscreen', true);

                    cont.classList.add('widget-inner');
                    cont.appendChild(videoIframe);
                    this.el.innerHTML = '';
                    this.el.appendChild(cont);

                    this.model.setStyle({ height: `${videoAspectHeight}px` })
                    modelSetClass.call(this, 'video widget-item');
                }, 0);
            }
        }
    }
}