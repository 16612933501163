import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { UserService } from '@services/user.service';
import { FILTER_FIELDS } from './filter-fields';
@Component({
  selector: 'app-users-index',
  templateUrl: './users-index.component.html',
  styleUrls: ['./users-index.component.scss', './users-index-700.component.scss'],
  providers: [SidebarService]
})
export class UsersIndexComponent extends BaseComponent implements OnInit {

  public dataSource: any;
  public data: any;
  public displayedColumns = [
  // 'select',
  'avatar', 'name', 'group', 'role', 'status', 'apps', 'actions'];
  public dataLoaded = false;
  public fields: any = FILTER_FIELDS;

  constructor(
    public sidebarService: SidebarService,
    public router: Router,
    public state: State,
    public userService: UserService,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: userService,
      router,
      sidebarService,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      sidebar: 'user',
      listenForIndexUpdate: true
    });

    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }
  ngOnInit() {
    super.ngOnInit();
    // this.openSlideOutWithLink('users', 'edit/d2ee4fb8-ad9e-4db0-9021-902b743ec8fa');
  }

  sendCalendarLink(uuid: string) {
    return this.modelService.sendCalendarLink(uuid).subscribe((result: any) => {
      this.snackbarService.success({
        message: 'Calendar subscription information has been sent'
      });
    },
      (error: any) => {
        console.log(`Error sending calendar details: ${JSON.stringify(error)}`);
      }
    );
  }

  inviteByEmail(uuid: string) {
    return this.modelService.inviteByEmail(uuid).subscribe((result: any) => {
      this.modelServiceGetAll(this.pageNumberAfterAction()).then(() => (this.dataLoaded = true));
      this.snackbarService.success({
        message: 'Invitation sent by email'
      });
    },
      (error: any) => {
        console.log('Error sending invitation: ' + JSON.stringify(error));
      }
    );
  }

  inviteBySms(uuid: string) {
    return this.modelService.inviteBySms(uuid).subscribe((result: any) => {
      this.modelServiceGetAll(this.pageNumberAfterAction()).then(() => (this.dataLoaded = true));
      this.snackbarService.success({
        message: 'Invitation sent by SMS'
      });
    },
      (error: any) => {
        console.log('Error sending invitation: ' + JSON.stringify(error));
      }
    );
  }

  editAssociatedContact(associatedUser):void {
    console.log(associatedUser)
  }
}
