// All the third parties helper related modules should be decleared here

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
const ANGULAR_MODULES = [
  BrowserAnimationsModule,
  BrowserModule,
  FormsModule,
  ReactiveFormsModule,
  CommonModule,
  RouterModule
];

@NgModule({
  imports: [
    ...ANGULAR_MODULES
  ],
  exports: [
   ...ANGULAR_MODULES
  ]
})

export class AngularModule { }
