import { Component, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { FolderService } from '@app/services/folder.service';
import { SidebarService } from '@services/sidebar.service';

@Component({
  selector: 'app-folder-view-users',
  templateUrl: './folder-view-users.component.html',
  styleUrls: [],
  providers: [SidebarService]
})
@Injectable()
export class FolderViewUsersComponent extends BaseComponent {

  public folder: {
    name: string,
    uuid: string,
    created_by_user: any,
    created_user: any,
    created_at: string,
    userCount: number,
    user_count: number,
    users_with_attributes: any
  };

  public dataLoaded = false;

  // https://codeburst.io/using-the-plural-pipe-in-angular-2-2bcc67a14030
  // Make this into a global array for other models
  public userPluralMapping = {
    '=0': '0 users',
    '=1': '1 user',
    'other': '# users'
  };

  constructor(
    public activatedRoute: ActivatedRoute,
    public folderService: FolderService,
    public sidebarService: SidebarService,
    public state: State,
    public router: Router
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService,
      state,
      router,
      modelService: folderService
    });
    this.setPageVars({
      sidebar: ''
    });
    this.modelServiceGetOne(this.routeParams['id'], 'folder').then(() => {
      this.dataLoaded = true;
    });
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }
}
