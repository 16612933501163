import { paramCase } from 'change-case';

import {
  cacheInjectImageDataUrl,
  fixToolbarPositinoOnComponentHeightChange,
  modelSetClass,
  updatePageFormItemPerms
} from './../../utils';

import {
  COMP_LAYOUT_ROW,
  COMP_MEDIA_IMAGE,
  COMP_MEDIA_IMAGE_SLIDER,
  COMP_TEXT_BLOCK
} from './../../../const/components';

import {
  FIELD_IMAGE_SELECTOR,
  FIELD_CURRENT_IMAGE_URL
} from './../../../const/traits';

import { CMD_OPEN_IMAGE_SELECTOR } from './../../../const/commands';

import { ICON_MEDIA_IMAGE } from './../../../const/icons';

const compParamName = paramCase(COMP_MEDIA_IMAGE);

export const Image = (editor, options:any = {}) => {
  return {
    isComponent: el => el.tagName === compParamName.toUpperCase(),
    // Model
    model: {
      defaults: {
        displayIcon: ICON_MEDIA_IMAGE,
        tagName: compParamName,
        name: 'Image',
        resizable: false,
        editable: false,
        draggable: `
${paramCase(COMP_MEDIA_IMAGE_SLIDER)},
${paramCase(COMP_LAYOUT_ROW)}`,
        droppable: false,
        attributes: {
          imageUUID: null,
          imageDetails: null
        },
        traits:[FIELD_CURRENT_IMAGE_URL, FIELD_IMAGE_SELECTOR]
      }
    },
    // View
    view: {
      tagName: compParamName,
      // Events
      events: { dblclick: 'doubleClickEvent' },
      // Dblclick
      doubleClickEvent(e) {
        editor.Commands.run(CMD_OPEN_IMAGE_SELECTOR);
      },
      // Init
      init() {
        // editor.select(this.model);
        updatePageFormItemPerms.call(this, editor);
        this.listenTo(this.model, 'change:attributes', this.handleAttributeChanges);
      },
      // Attribute changes handler
      handleAttributeChanges() {
        updatePageFormItemPerms.call(this, editor);
        this.render();
        fixToolbarPositinoOnComponentHeightChange(editor);
      },
      // On render
      onRender() {
        const attrs = this.model.getAttributes();
        const image:any = document.createElement('img');
        let modelClass = `item ${compParamName}`;
        let url = '';

        if(!! attrs.imageUUID) {
          url = `${editor.baseUrl}${attrs.imageUUID}/`;
        }

        if (!! attrs.imageUUID && editor.isSecureStorage === true) {
          cacheInjectImageDataUrl(url, image, this.el, editor.indexDb);
        } else if (!! attrs.imageUUID && editor.isSecureStorage === false) {
          image.src = url;
          this.el.appendChild(image);
        } else if (!! attrs.currentImageUrl) {
          this.el.innerHTML = `<img src="${attrs.currentImageUrl}" />`;
        } else {
          modelClass += ' image-not-found';
          this.el.innerHTML = '<image-not-found><i class="material-icons">image_not_supported</i></image-not-found>';
        }

        modelSetClass.call(this, modelClass);
      }
    }
  }
}