import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { ContactGroupService } from '@app/services/contact-group.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';

@Component({
  selector: 'app-contact-group-form',
  templateUrl: './contact-group-form.component.html',
  styleUrls: [],
})
export class ContactGroupFormComponent extends BaseComponent implements OnInit {
  contactFormGroup: FormGroup;
  dataLoaded = false;
  formSubmitted = false;
  usersCols: any;
  groupsCols: any;
  userSelectedIds = [];
  groupSelectedIds = [];
  globalUtil = GlobalUtil;

  @ViewChild('users', { static: false }) private advancedSelectUserInput: { getSelectedIds: () => any[] };
  @ViewChild('groups', { static: false }) private advancedSelectGroupInput: { getSelectedIds: () => any[] };

  constructor(
    public activatedRoute: ActivatedRoute,
    public contactGroupService: ContactGroupService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      state: state,
      modelService: ContactGroupService,
    });

    this.setPageVars({
      sidebar: '',
    });

    // Setup the columns settings for the multi-selection table (Users)
    this.usersCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` },
    ];

    // Setup the columns settings for the multi-selection table (Groups)
    this.groupsCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` },
    ];
  }

  ngOnInit() {
    this.initialFormGroupSetup();
    const id = this.routeParams['id'];
    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.contactGroupService.getOne(id).subscribe((result: any) => {
        this.contactFormGroup.patchValue(result);
        this.populateInitialData(result);
        this.dataLoaded = true;
      });
    }
  }

  // All the public methods
  get controls(): any {
    return this.contactFormGroup.controls;
  }

  public save(): void {
    const uuid = this.controls.uuid.value;
    this.formSubmitted = true;
    this.contactFormGroup.patchValue({
      users: this.getSelectedUsers(),
      groups: this.getSelectedGroups(),
    });

    if (uuid) {
      this.contactGroupService.patchById(uuid, this.contactFormGroup.value).subscribe((result) => {
        this.SavedMessage(result);
      });
    } else {
      this.contactGroupService.create(this.contactFormGroup.value).subscribe((result) => {
        this.SavedMessage(result);
      });
    }

    setTimeout(() => {
      this.formSubmitted = false;
    }, 3000);
  }

  public getCustomFieldsForm(val): void {
    this.contactFormGroup.patchValue({
      custom_fields: val,
    });
  }

  // All the private methods
  private initialFormGroupSetup(): void {
    this.contactFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      name: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      type: [null, [Validators.required]],
      users: [null],
      groups: [null],
      custom_fields: null,
    });
  }

  private populateInitialData(row): void {
    if (row !== undefined) {
      this.selectedIds(row);
    }
  }

  private selectedIds(row): any {
    row.groups.forEach((el: { uuid: any }) => {
      this.groupSelectedIds.push(el.uuid);
    });

    row.users.forEach((el: { uuid: any }) => {
      this.userSelectedIds.push(el.uuid);
    });
  }

  private SavedMessage(data) {
    this.state.message({ ['ContactGroupIndexUpdate']: true });
    this.closeSlideout();
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  private getSelectedUsers(): any[] {
    const value: any[] = this.advancedSelectUserInput.getSelectedIds();
    return value === null ? null : value;
  }

  private getSelectedGroups(): any[] {
    const value: any[] = this.advancedSelectGroupInput.getSelectedIds();
    return value === null ? null : value;
  }
}
