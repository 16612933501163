import { Component, Input, OnChanges } from '@angular/core';
import { State } from '@app/state';
@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss', './sidebar-700.component.scss']
})
export class SidebarComponent implements OnChanges {
  @Input() sections: Array<object> = [];
  public displayOnSmallViewport = false;
  constructor(private state: State) { }
  ngOnChanges() {
    this.state.subscription().subscribe(payload => {
      if (payload['openSettingsSideBar'] === true) {
        this.displayOnSmallViewport = true;
      }
    });
    if (this.state.get()['openSettingsSideBar'] === true) {
      this.displayOnSmallViewport = true;
    }
  }

  closeSidebar() {
    this.displayOnSmallViewport = false;
    this.state.update({ openSettingsSideBar: false });
  }
}
