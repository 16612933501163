import { paramCase } from 'change-case';

import {
  fixToolbarPositinoOnComponentHeightChange,
  modelSetClass,
  updatePageFormItemPerms
} from './../../utils';

import {
  COMP_LOREM_IPSUM,
  COMP_LAYOUT_SECTION,
  COMP_WIDGET_EMBED
} from './../../../const/components';

import { ICON_WIDGET_EMBED } from './../../../const/icons';

import { TRAIT_MISC_TEXTBOX, FIELD_WIDTH, FIELD_HEIGHT } from './../../../const/traits';

const compParamName = paramCase(COMP_WIDGET_EMBED);

export const EmbedWidget = (editor, options:any = null) => {
  return {
    isComponent: el => el.tagName === compParamName.toUpperCase(),
    // Model
    model: {
      defaults: {
        tagName: compParamName,
        displayIcon: ICON_WIDGET_EMBED,
        name: 'Embed',
        droppable: false,
        draggable: paramCase(COMP_LAYOUT_SECTION),
        attributes: { embed_code: COMP_LOREM_IPSUM, width: 1000, height: 650 },
        traits: [{
          label: 'Embed code',
          name: 'embed_code',
          type: TRAIT_MISC_TEXTBOX
        },
          FIELD_WIDTH,
          FIELD_HEIGHT
        ]
      }
    },
    // View
    view: {
      tagName: compParamName,
      // Init
      init() {
        // editor.select(this.model);
        updatePageFormItemPerms.call(this, editor);
        this.listenTo(this.model, 'change:attributes', this.handleAttributeChanges);
      },
      // Attribute changes handler
      handleAttributeChanges() {
        updatePageFormItemPerms.call(this, editor);
        this.render();
        fixToolbarPositinoOnComponentHeightChange(editor);
      },
      // On render
      onRender() {
        const attrs = this.model.getAttributes();

        this.el.innerHTML = `<div class="widget-inner">${attrs.embed_code}</div>`;
        modelSetClass.call(this, 'widget-item item-embed');
      }
    }
  }
}