import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectFilterBy'
})

export class SelectFilterByPipe implements PipeTransform {
  transform(arr: any[], searchText: string, fieldName?: string): any[] {
    var fieldName2;

    if (! arr) {
      return [];
    }
    if (! searchText || searchText.length < 4) {
      return arr;
    }

    searchText = searchText.toLowerCase();

    if (fieldName.includes('|')) {
      const fields = fieldName.split('|');
      fieldName = fields[0];
      fieldName2 = fields[1];
    }

    return arr.filter((it: any) => {
      var item = null;
      var item2 = null;
      if (typeof it === 'string') {
        item = it.toLowerCase().includes(searchText);
      } else if (typeof it === 'number') {
        item = it.toString().toLowerCase().includes(searchText);
      } else {
        item = it[fieldName].toLowerCase().includes(searchText);
      }
      if (!! fieldName2) {
        if (typeof it === 'string') {
        item2 = it.toLowerCase().includes(searchText);
        } else if (typeof it === 'number') {
          item2 = it.toString().toLowerCase().includes(searchText);
        } else {
          item2 = it[fieldName2].toLowerCase().includes(searchText);
        }
      }
      return (item || item2);
    });
  }
}
