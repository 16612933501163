import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { UserContentLogService } from '@services/user-content-log.service';
@Component({
  selector: 'user-content-log-index',
  templateUrl: './user-content-log-index.component.html',
  styleUrls: [],
  providers: [SidebarService],
})
export class UserContentLogIndexComponent extends BaseComponent implements OnInit {
  @Input() uuid: string;
  public dataSource: any;
  public data: any;
  public dataLoaded = false;
  public displayedColumns = [
    'select',
    'created_at',
    'subject_type',
    'subject_title',
    'action',
    // 'actions'
  ];

  constructor(
    public sidebarService: SidebarService,
    public router: Router,
    public state: State,
    public userContentLogService: UserContentLogService,
  ) {
    super();
    this.setObjects({
      modelService: userContentLogService,
      sidebarService: sidebarService,
      router: router,
      state: state
    });
    this.setPageVars({
      listenForIndexUpdate: true
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.modelServiceGetAllWithId(this.uuid).then(() => (this.dataLoaded = true));
  }
}
