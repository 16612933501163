import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, pipe } from 'rxjs';

import { BaseService } from './base.service';
import { Cache } from '@app/cache';
import { State } from '@app/state';
import { Environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CalendarService extends BaseService {

  constructor(
    public http: HttpClient,
    public cache: Cache,
    public state: State
  ) {
    super(http, cache);
    this.setVars({
      endPoint: 'calendar',
      modelTitle: 'Calendar'
    });
  }

  static eventCache = {};
  static eventRequestCache = {};
  private calendarCache = {};

  getCategories(): Observable<any> {
      // const teamID = '2a5ded1-f4a7-4103-acef-23460dac5172';
      // const categoriesApiUrl =  `${this.getApiEndpoint()}/event-categories/`;
      // Hard code the URL in for now - the base service isn't generating the correct URL
      // There's another ticket to resolve EventCategory issues
      const categoriesApiUrl = Environment.apiURL + 'event-categories/list?fields=uuid,name,color';
      return this.http.get<object[]>(categoriesApiUrl);
  }

  getCalendarEvents(calendarUuid: string, fromDate: Date, toDate: Date): Observable<Object[]> {
    const fromSlug = fromDate.toISOString().substr(0, 10);
    const toSlug = toDate.toISOString().substr(0, 10);
    return this.http.get<object[]>(`${this.getApiEndpoint()}/${calendarUuid}/events/${fromSlug}/${toSlug}`);
  }

  getAllCalendarsEvents(fromDate: Date, toDate: Date): Observable<Object[]> {
    const fromSlug = fromDate.toISOString().substr(0, 10);
    const toSlug = toDate.toISOString().substr(0, 10);
    return this.http.get<object[]>(`${this.getApiEndpoint()}s/events/${fromSlug}/${toSlug}`);
  }

  getEvent(eventUuid: string): Observable<Object> {
    if (CalendarService.eventCache[eventUuid]) {
        // remember to destroy this cache
        // return an RX observable that instantly resolves 'of' the cached event object
        return of(CalendarService.eventCache[eventUuid]);
    }
    const categoriesApiUrl = `${this.getApiEndpoint()}/event/${eventUuid}`;
    const event = this.http.get<object>(categoriesApiUrl);
    CalendarService.eventRequestCache[eventUuid] = event;
    event.subscribe(e => {
        CalendarService.eventCache[eventUuid] = e;
    });
    return event;
  }
}
