import { paramCase } from 'change-case';

import { ICON_TEXT_TEXT_BLOCK } from './icons';

import {
    FIELD_FONT,
    FIELD_FONT_SIZE,
    FIELD_LINE_SPACING
} from './traits';

// Misc
export const COMP_LOREM_IPSUM = 'Lorem ipsum';
export const COMP_LOREM_IPSUM_LONG = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc finibus blandit turpis id ullamcorper. Nullam facilisis est id ipsum gravida, eget sodales ex mollis. Nam vitae cursus ipsum. Morbi in fringilla sem. Phasellus dignissim, est in laoreet iaculis, augue risus tristique libero, id dapibus urna mauris in dui. Donec a diam in augue facilisis suscipit. Proin ac justo a erat elementum tempor. In hac habitasse platea dictumst. Nam sagittis nisi eu felis faucibus, at ornare eros tincidunt. Phasellus rhoncus mauris risus, sed tristique lacus elementum nec. Maecenas ut cursus dolor, sit amet dictum ante. Cras lacinia nulla maximus ligula commodo vehicula. Etiam lacinia sem nunc, eu fermentum lorem molestie quis. Curabitur interdum eros at justo porttitor consectetur.';
export const COMP_WRAPPER_SELECTOR = 'div[id="wrapper"]';

// Widget
const _COMP_WIDGET_ARTICLE_CATEGORY = 'articleCategoryWidget';
export const COMP_WIDGET_ARTICLE_CATEGORY = _COMP_WIDGET_ARTICLE_CATEGORY;
const _COMP_WIDGET_BUNDLE = 'bundleWidget';
export const COMP_WIDGET_BUNDLE = _COMP_WIDGET_BUNDLE;
const _COMP_WIDGET_DIRECTORY_CATEGORY = 'directoryCategoryWidget';
export const COMP_WIDGET_DIRECTORY_CATEGORY = _COMP_WIDGET_DIRECTORY_CATEGORY;
const _COMP_WIDGET_DIRECTORY_ITEM = 'directoryWidget';
export const COMP_WIDGET_DIRECTORY_ITEM = _COMP_WIDGET_DIRECTORY_ITEM;
const _COMP_WIDGET_EMBED = 'embedWidget';
export const COMP_WIDGET_EMBED = _COMP_WIDGET_EMBED;
const _COMP_WIDGET_MAP = 'mapWidget';
export const COMP_WIDGET_MAP = _COMP_WIDGET_MAP;

// Layout
const _COMP_LAYOUT_ACCORDION = 'accordion';
export const COMP_LAYOUT_ACCORDION = _COMP_LAYOUT_ACCORDION;
const _COMP_LAYOUT_DIVIDER = 'divider';
export const COMP_LAYOUT_DIVIDER = _COMP_LAYOUT_DIVIDER;
const _COMP_LAYOUT_PAGE_SECTION = 'pageSection';
export const COMP_LAYOUT_PAGE_SECTION = _COMP_LAYOUT_PAGE_SECTION;
const _COMP_LAYOUT_PLACEHOLDER = 'placeholder';
export const COMP_LAYOUT_PLACEHOLDER = _COMP_LAYOUT_PLACEHOLDER;
const _COMP_LAYOUT_ROW = 'row';
export const COMP_LAYOUT_ROW = _COMP_LAYOUT_ROW;
const _COMP_LAYOUT_SECTION = 'section';
export const COMP_LAYOUT_SECTION = _COMP_LAYOUT_SECTION;

// Items
const _COMP_BUTTON_APP_COMPONENT = 'appComponent';
export const COMP_BUTTON_APP_COMPONENT = _COMP_BUTTON_APP_COMPONENT;
const APP_COMPONENTS_LIST = [
    { id: 'home', name: 'Home' },
    { id: 'chats', name: 'Chats' },
    { id: 'schedule', name: 'Schedule' },
    { id: 'profiles', name: 'Profiles' },
    { id: 'contacts', name: 'Contacts' },
    { id: 'documents', name: 'Documents' },
    { id: 'notifications', name: 'Notifications' },
    { id: 'periodisationCalendar', name: 'Periodisation Calendar' }
]
export const COMP_BUTTON_APP_COMPONENT_OPTIONS = APP_COMPONENTS_LIST;
export const COMP_BUTTON_APP_COMPONENT_OPTIONS_FOR_CORE_SELECT = () => {
    var options = [];
    for(let option of APP_COMPONENTS_LIST) {
        options.push({ value: option.id, text: option.name });
    }
    return options;
};
const _COMP_BUTTON_APP_PAGE = 'appPage';
export const COMP_BUTTON_APP_PAGE = _COMP_BUTTON_APP_PAGE;
const _COMP_BUTTON_ARTICLE = 'article';
export const COMP_BUTTON_ARTICLE = _COMP_BUTTON_ARTICLE;
const _COMP_BUTTON_ARTICLE_CATEGORY = 'articleCategory';
export const COMP_BUTTON_ARTICLE_CATEGORY = _COMP_BUTTON_ARTICLE_CATEGORY;
const _COMP_BUTTON_BUNDLE = 'bundle';
export const COMP_BUTTON_BUNDLE = _COMP_BUTTON_BUNDLE;
const _COMP_BUTTON_CALENDAR = 'calendar';
export const COMP_BUTTON_CALENDAR = _COMP_BUTTON_CALENDAR;
const _COMP_BUTTON_CHAT = 'chat';
export const COMP_BUTTON_CHAT = _COMP_BUTTON_CHAT;
const _COMP_BUTTON_CONTACT_GROUP = 'contactGroup';
export const COMP_BUTTON_CONTACT_GROUP = _COMP_BUTTON_CONTACT_GROUP;
const _COMP_BUTTON_DIRECTORY_CATEGORY = 'directoryCategory';
export const COMP_BUTTON_DIRECTORY_CATEGORY = _COMP_BUTTON_DIRECTORY_CATEGORY;
const _COMP_BUTTON_DIRECTORY_ITEM = 'directoryItem';
export const COMP_BUTTON_DIRECTORY_ITEM = _COMP_BUTTON_DIRECTORY_ITEM;
const _COMP_BUTTON_DOCUMENT = 'document';
export const COMP_BUTTON_DOCUMENT = _COMP_BUTTON_DOCUMENT;
const _COMP_BUTTON_EVENT = 'event';
export const COMP_BUTTON_EVENT = _COMP_BUTTON_EVENT;
const _COMP_BUTTON_FILE = 'file';
export const COMP_BUTTON_FILE = _COMP_BUTTON_FILE;
const _COMP_BUTTON_FOLDER = 'folder';
export const COMP_BUTTON_FOLDER = _COMP_BUTTON_FOLDER;
const _COMP_BUTTON_PERIODISATION_CALENDAR = 'periodisationCalendar';
export const COMP_BUTTON_PERIODISATION_CALENDAR = _COMP_BUTTON_PERIODISATION_CALENDAR;
const _COMP_BUTTON_PLACEHOLDER = 'placeholder';
export const COMP_BUTTON_PLACEHOLDER = _COMP_BUTTON_PLACEHOLDER;
const _COMP_BUTTON_PROFILE_GROUP = 'profileGroup';
export const COMP_BUTTON_PROFILE_GROUP = _COMP_BUTTON_PROFILE_GROUP;
const _COMP_BUTTON_URL = 'url';
export const COMP_BUTTON_URL = _COMP_BUTTON_URL;
const _COMP_BUTTON_PRODUCT_URL = 'productUrl';
export const COMP_BUTTON_PRODUCT_URL = _COMP_BUTTON_PRODUCT_URL;
const _COMP_BUTTON_USER_CIRCLE = 'userCircle';
export const COMP_BUTTON_USER_CIRCLE = _COMP_BUTTON_USER_CIRCLE;
export const COMP_SELECT_OPTIONS = [
    { id: _COMP_BUTTON_APP_COMPONENT, label: 'App Component' },
    { id: _COMP_BUTTON_APP_PAGE, label: 'App Page' },
    { id: _COMP_BUTTON_ARTICLE, label: 'Article' },
    { id: _COMP_BUTTON_BUNDLE, label: 'Bundle' },
    { id: _COMP_BUTTON_DIRECTORY_ITEM, label: 'Directory Item' },
    { id: _COMP_BUTTON_DOCUMENT, label: 'Document' },
    { id: _COMP_BUTTON_EVENT, label: 'Event' },
    { id: _COMP_BUTTON_FOLDER, label: 'Folder' },
    { id: _COMP_BUTTON_USER_CIRCLE, label: 'User Circle' },
    { id: _COMP_BUTTON_BUNDLE, label: 'Bundle' },
];
export const COMP_SELECT_OPTIONS_FOR_CORE_SELECT = () => {
    var options = [];
    for(let option of COMP_SELECT_OPTIONS) {
        options.push({ value: option.id, text: option.label });
    }
    return options;
};

// Media
const _COMP_MEDIA_IMAGE = 'imageComp';
export const COMP_MEDIA_IMAGE = _COMP_MEDIA_IMAGE;
const _COMP_MEDIA_IMAGE_SLIDER = 'imageSliderWidget';
export const COMP_MEDIA_IMAGE_SLIDER = _COMP_MEDIA_IMAGE_SLIDER;
const _COMP_MEDIA_INLINE_IMAGE = 'inlineImageComp';
export const COMP_MEDIA_INLINE_IMAGE = _COMP_MEDIA_INLINE_IMAGE;
const _COMP_MEDIA_VIDEO = 'videoWidget';
export const COMP_MEDIA_VIDEO = _COMP_MEDIA_VIDEO;
const _COMP_MEDIA_VIDEO_PLAYLIST = 'videoPlaylist';
export const COMP_MEDIA_VIDEO_PLAYLIST = _COMP_MEDIA_VIDEO_PLAYLIST;

// Text
const _COMP_TEXT_HEADING = 'heading';
export const COMP_TEXT_HEADING = _COMP_TEXT_HEADING;

// Inline
export const COMP_TEXT = {
    type: 'text',
    name: 'Text',
    displayIcon: ICON_TEXT_TEXT_BLOCK,
    content: COMP_LOREM_IPSUM_LONG,
    class: 'text-block item',
    attributes: {
        class: 'text-block item',
        // font: FIELD_FONT.default,
        // fontsize: FIELD_FONT_SIZE.default,
        // linespacing: FIELD_LINE_SPACING.default
    },
    droppable: false,
    badgable: false,
    highlightable: false,
    hoverable: false,
    draggable: paramCase(COMP_LAYOUT_ROW),
    traits: []
};
const _COMP_TEXT_BLOCK_OLD = 'text';
export const COMP_TEXT_BLOCK_OLD = _COMP_TEXT_BLOCK_OLD;
const _COMP_TEXT_BLOCK = 'textBlock';
export const COMP_TEXT_BLOCK = _COMP_TEXT_BLOCK;
export const COMP_TEXT_INLINE_ICON = 'inlineIcon';
export const COMP_TEXT_INLINE_ITEM = 'inlineItem';
export const COMP_TEXT_INLINE_URL = 'inlineUrl';

export const COMP_LAYOUT_ROW_DROPPABLE = `text-block-test,${paramCase(_COMP_BUTTON_APP_COMPONENT)},${paramCase(_COMP_BUTTON_DIRECTORY_CATEGORY)},${paramCase(_COMP_BUTTON_APP_PAGE)},${paramCase(_COMP_BUTTON_ARTICLE)},${paramCase(_COMP_BUTTON_ARTICLE_CATEGORY)},${paramCase(_COMP_BUTTON_BUNDLE)},${paramCase(_COMP_BUTTON_CALENDAR)},${paramCase(_COMP_BUTTON_DIRECTORY_ITEM)},${paramCase(_COMP_BUTTON_CONTACT_GROUP)},${paramCase(_COMP_BUTTON_DOCUMENT)},${paramCase(_COMP_BUTTON_EVENT)},${paramCase(_COMP_BUTTON_FILE)},${paramCase(_COMP_BUTTON_FOLDER)},${paramCase(_COMP_BUTTON_PERIODISATION_CALENDAR)},${paramCase(_COMP_BUTTON_PLACEHOLDER)},${paramCase(_COMP_BUTTON_PROFILE_GROUP)},${paramCase(_COMP_BUTTON_URL)},${paramCase(_COMP_BUTTON_PRODUCT_URL)},${paramCase(_COMP_BUTTON_USER_CIRCLE)},${paramCase(_COMP_LAYOUT_DIVIDER)},${paramCase(_COMP_MEDIA_IMAGE)},${paramCase(_COMP_TEXT_HEADING)},${paramCase(_COMP_TEXT_BLOCK)},${paramCase(_COMP_BUTTON_CHAT)},div.text-block`;
export const COMP_LAYOUT_SECTION_DROPPABLE = `${paramCase(_COMP_LAYOUT_ACCORDION)},${paramCase(_COMP_WIDGET_DIRECTORY_CATEGORY)},${paramCase(_COMP_LAYOUT_ROW)},${paramCase(_COMP_MEDIA_IMAGE_SLIDER)},${paramCase(_COMP_MEDIA_VIDEO)},${paramCase(_COMP_MEDIA_VIDEO_PLAYLIST)},${paramCase(_COMP_WIDGET_ARTICLE_CATEGORY)},${paramCase(_COMP_WIDGET_BUNDLE)},${paramCase(_COMP_WIDGET_DIRECTORY_ITEM)},${paramCase(_COMP_WIDGET_EMBED)},${paramCase(_COMP_WIDGET_MAP)}`;