import { iconElement, uuidv4 } from './../utils';

import { FIELD_ITEM_PERMS_SELECTOR } from './../../const/traits';

import { CMD_UPDATE_PAGE_FORM_ITEM_PERMS } from './../../const/commands';

export const BaseComponent = (component, editor) => {

    component.model.defaults.hoverable = false;
    component.model.defaults.badgable = false;

    // Create attributes object if it does not exist
    if (! component.model.defaults.hasOwnProperty('attributes')) {
        component.model.defaults.attributes = {};
    }

    // Create attributes perms object if it does not exist
    if (! component.model.defaults.attributes.hasOwnProperty('perms')) {
        component.model.defaults.attributes.perms = [];
    }

    // Create attributes perms object if it does not exist
    if (! component.model.defaults.attributes.hasOwnProperty('permsEnabled')) {
        component.model.defaults.attributes.permsEnabled = false;
    }

    // Create attributes uuid object if it does not exist
    if (! component.model.defaults.attributes.hasOwnProperty('uuid')) {
        component.model.defaults.attributes.uuid = uuidv4();
    }

    // Create empty traits to stop default id and title traits
    if (! component.model.defaults.hasOwnProperty('traits')) {
        component.model.defaults.traits = [];
    }

    // Create empty traits to stop default id and title traits
    if (! component.model.defaults.hasOwnProperty('displayIcon')) {
        component.model.defaults.icon = iconElement(component.model.defaults.displayIcon);
    }

    // Make sure hasComponentItems is set
    component.model.defaults.hasComponentItems = component.model.defaults.hasOwnProperty('hasComponentItems');

    // Create perms items of not exist
    if (
        component.model.defaults.hasComponentItems &&
        ! component.model.defaults.attributes.hasOwnProperty('itemsPerms')
    ) {
        component.model.defaults.attributes.itemsPerms = new Map;
    }

    // Map traits array if trait needs a different position in traits
    const traits = [];
    component.model.defaults.traits.forEach((trait, index) => {
        if (! trait.at) {
            traits.push(trait);
        } else {
            traits.splice(trait.at, 0, trait);
        }
    });
    component.model.defaults.traits = traits;

    // Perms selector
    component.model.defaults.traits.push(FIELD_ITEM_PERMS_SELECTOR);

    return component;
}