import { AfterContentInit, Inject, Component, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IconService } from '@app/services/icon-service';

@Component({
  selector: 'icon-selector-dialog',
  templateUrl: 'icon-selector-dialog.component.html',
  styleUrls: ['icon-selector-dialog.component.scss']
})
export class IconSelectorDialogComponent {
    public selectedIcon: string;
    public iconElements;
    public icons;

    constructor(
        private elRef:ElementRef,
        private iconService: IconService,
        private dialogRef: MatDialogRef<IconSelectorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.selectedIcon = data.icon;
        this.icons = iconService.getIconsJson();
    }

    ngAfterContentInit() {
        this.iconElements = document.getElementsByClassName('icon-dialog-icon');
    }

    setIcon(event, icon) {
        for (var iconElement of this.iconElements) {
            iconElement.classList.remove('selected');
        }
        event.target.classList.add('selected');
        this.selectedIcon = icon;
    }

    saveAndCloseDialog() {
        this.dialogRef.close({ icon: this.selectedIcon })
    }

    clearIcon() {
        this.selectedIcon = null;
        this.saveAndCloseDialog();
    }
}
