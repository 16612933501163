import { Component, Injectable, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserCircleService } from '@app/services/user-circle.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
@Component({
  selector: 'user-circle-form',
  templateUrl: './user-circle-form.component.html',
  styleUrls: ['./user-circle-form.component.scss'],
  providers: [SidebarService]
})
@Injectable()
export class UserCircleFormComponent extends BaseComponent {

  userCircleForm: FormGroup;
  dataLoaded = false;
  formSubmitted = false;
  usersCols: any;
  userSelectedIds = [];
  globalUtil = GlobalUtil;

  @ViewChild('users', {static: false}) private advancedSelectUserInput;
  constructor(
    public activatedRoute: ActivatedRoute,
    public userCircleService: UserCircleService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService,
      state,
      modelService: UserCircleService
    });

    this.setPageVars({
      sidebar: ''
    });

    this.initialFormGroupSetup();
    const id = this.routeParams['id'];
    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.userCircleService.getOne(id).subscribe((result: any) => {
        this.userCircleForm.patchValue(result);
        this.populateInitialData(result);
        this.dataLoaded = true;
      });
    }

    // Setup the columns settings for the multi-selection table (Users)
    this.usersCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` }
    ];
  }

  // All the public methods
  get controls(): any {
    return this.userCircleForm.controls;
  }

  public save(): void {
    this.formSubmitted = true;
    const id = this.controls.id.value;
    const uuid = this.controls.uuid.value;

    this.userCircleForm.patchValue({
      users: this.getSelectedUsers()
    });

    if (id || uuid) {
      // Update the existing row
      this.userCircleService.patchById(uuid ? uuid : id, this.userCircleForm.value).subscribe(result => {
        this.SavedMessage(result);
      });
    } else {
      // Create the new row
      this.userCircleService.create(this.userCircleForm.value).subscribe(result => this.SavedMessage(result));
    }

    setTimeout (() => {
      this.formSubmitted = false;
    }, 1000);

  }

  // All the private methods
  private initialFormGroupSetup(): void {
    // setup the initial form for row entity
    this.userCircleForm = this.formBuilder.group({
      uuid: [null],
      id: [null],
      name: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      // groups: [null],
      users: [null]
    });
  }

  private populateInitialData(row): void {
    if (row !== undefined) {
      this.selectedIds(row);
    }
  }

  private selectedIds(row): any {
    row.users.forEach(el => {
      this.userSelectedIds.push(el.uuid);
    });
  }

  private SavedMessage(data) {
    this.state.message({ ['UserCircleIndexUpdate']: true });
    this.closeSlideout();
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  private getSelectedUsers(): any[] {
    const value: any[] = this.advancedSelectUserInput.getSelectedIds();
    return value === null ? null : value;
  }
}
