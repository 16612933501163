import { NgModule } from '@angular/core';
import { NumberOnlyDirective } from './directives/number-only/number-only.directive';
import { SingleLineOnlyDirective } from './directives/single-line-only/single-line-only.directive';
import { NumberAsArrayPipe } from './pipes/number-as-array.pipe';
import { DateSuffixPipe } from './pipes/date-suffix.pipe';
import { MomentDatePipe } from './pipes/moment-date.pipe';
import { SecureUrlPipe } from './pipes/secure-url.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SecureUrlVideoPipe } from './pipes/secure-url-video.pipe';
import { SelectFilterByPipe } from './pipes/select-filter.pipe';
import { CanDragDropOntoThisElementDirective } from './directives/can-drag-drop-onto-this-element/can-drag-drop-onto-this-element.directive';
import { AngularModule } from '../third-parties/angular/angular.module';
import { MaterialModule } from '../third-parties/material/material.module';

const DIRECTIVES = [
  CanDragDropOntoThisElementDirective,
  NumberOnlyDirective,
  SingleLineOnlyDirective
];

const PIPES = [
  DateSuffixPipe,
  SelectFilterByPipe,
  NumberAsArrayPipe,
  MomentDatePipe,
  SecureUrlPipe,
  SecureUrlVideoPipe,
  SafeUrlPipe
];

@NgModule({
  declarations: [
    ...DIRECTIVES,
    ...PIPES
  ],
  imports: [
     AngularModule,
     MaterialModule
  ],
  exports: [
    ...DIRECTIVES,
    ...PIPES
  ],
  providers: []
})

export class SharedModule {
  constructor() {
    // console.log('Shared module loaded');
  }
}
