import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { UserSpIdService } from '@services/user-spid.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectionOption } from '@app/models/view-models/option';
@Component({
  selector: 'user-spid-form',
  templateUrl: './user-spid-form.component.html',
  styleUrls: ['./user-spid-form.component.scss'],
})
export class UserSpIdFormComponent extends BaseComponent implements OnInit {
  @Input() userSpId: any;
  @Input() hideSidebar = false;

  user: any;
  dataLoaded = false;
  formSubmitted = false;
  userSportsologyFormGroup: FormGroup;
  private appOptions = [];

  constructor(
    public activatedRoute: ActivatedRoute,
    public userSpIdService: UserSpIdService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      state: state,
      modelService: userSpIdService
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.user = this.userSpId;
    this.dataLoaded = true;
    this.initialFormGroup();
    this.getSelectedValues(this.userSpId.apps_v2);
    this.userSportsologyFormGroup.patchValue(this.userSpId);
    this.userSportsologyFormGroup.patchValue({
      'apps_v2': this.userSpId.apps_v2
    });
  }

  get controls(): any {
    return this.userSportsologyFormGroup.controls;
  }

  public save(): void {
    const uuid = this.controls.uuid.value;
    this.formSubmitted = true;
    this.userSpIdService.patchById(uuid, this.userSportsologyFormGroup.value).subscribe();
    setTimeout (() => {
      this.formSubmitted = false;
    }, 2000);
  }

  public getSelectedOption(option: SelectionOption): void {
    this.appOptions = this.controls.apps_v2.value;
    const val = option.value;
    if (option.selected === true) {
      this.appOptions.push(val);
    } else {
      this.removedOption(val);
    }
    this.userSportsologyFormGroup.patchValue({ 'apps_v2': this.appOptions });
  }

  private removedOption(val: string): void {
    const index: number = this.appOptions.indexOf(val);
    if (index !== -1) {
      this.appOptions.splice(index, 1);
    }
  }

  private getSelectedValues(result) {
    result.forEach(el => {
      this.appOptions.push(el.uuid);
    });
  }

  private initialFormGroup(): void {
    this.userSportsologyFormGroup = this.formBuilder.group({
      uuid: [null],
      apps_v2: [null]
    });
  }

}
