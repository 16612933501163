import { MatDialogConfig } from '@angular/material/dialog';

// Dialogs
import { ItemPermsSelectorDialogComponent } from './dialogs/item-perms-selector-dialog.component';

// Init perms
export const initPerms = function() {
  this.itemPerms = new Map;
}

// Update perms
export const updatePerms = function(options) {
  const { model, isLinkedItem } = options;
  const attrs = model.getAttributes();

  // Bug fix for incorrect perms object that should be an array
  if (typeof attrs.perms === 'object' && ! Array.isArray(attrs.perms)) {
    attrs.perms = [];
    model.setAttributes(attrs);
  }

  // Set form perms entry
  this.itemPerms[attrs.uuid] = {};
  this.itemPerms[attrs.uuid].permsEnabled = attrs.permsEnabled;
  this.itemPerms[attrs.uuid].userGroups = attrs.perms;

  // Set form perm linked item info
  if (isLinkedItem && attrs.itemUUID !== null) {
    this.itemPerms[attrs.uuid].linkedModel = model.itemType;
    this.itemPerms[attrs.uuid].linkedItemUUID = attrs.itemUUID;
  }

  // Set related items perms
  if (model.get('hasComponentItems')) {
    this.itemPerms[attrs.uuid].items = attrs.itemsPerms;
    // this.itemPerms[attrs.uuid].items.map(item => {
    //   if (item.permsEnabled === undefined) {
    //     item.permsEnabled = false;
    //   }
    //   return item;
    // });
  }

  // Remove any empty perms from form perms
  const permsRemoved = removeEmptyPerms.call(this, options);
}

// Remove perms from form perms if perm and related items is empty
const removeEmptyPerms = function(options) {
  const { model, isLinkedItem } = options;
  const attrs = model.getAttributes();
  var permsRemoved = false;

  // Remove .items if related items perms is empty
  if (model.get('hasComponentItems')) {
    if (this.itemPerms[attrs.uuid].items.length === 0) {
      delete this.itemPerms[attrs.uuid].items;
    } else {
      var itemsWithPermsCount = 0;
      this.itemPerms[attrs.uuid].items.map((itemPerm, itemPermIndex) => {
        if (itemPerm.permsEnabled/*itemPerm.userGroups.length > 0*/) {
          itemsWithPermsCount++;
        } else {
          this.itemPerms[attrs.uuid].items.splice(itemPermIndex, 1);
        }
      });
      if (itemsWithPermsCount === 0) {
        delete this.itemPerms[attrs.uuid].items;
      }
    }
  }

  if (
    ! this.itemPerms[attrs.uuid].permsEnabled &&
    this.itemPerms[attrs.uuid].items === undefined
    // (
    //   this.itemPerms[attrs.uuid].userGroups.length === 0 ||
    //   this.itemPerms[attrs.uuid].userGroups === undefined
    // )
  ) {
    delete this.itemPerms[attrs.uuid];
    permsRemoved = true;
  }

  return permsRemoved;
}

// Remove from perms
export const removeFromPerms = function(options) {
  const { uuid } = options;

  delete this.itemPerms[uuid];
}

// Open item perms selector
export const openItemPermsSelector = function(options): void {
  const { isItemOfParentItem, itemUUID, itemType } = options;
  const model = this.editor.getSelected();
  const attrs = model.getAttributes();
  const dialogConfig = new MatDialogConfig();
  var dialogData:any = { isItemOfParentItem: isItemOfParentItem, permsEnabled: attrs.permsEnabled };

  if (isItemOfParentItem) {
    // Get current item perms data
    const currentItemPerm = attrs.itemsPerms
      .find(itemPerm => itemPerm.linkedItemUUID === itemUUID);

    dialogData.perms = currentItemPerm !== undefined
      ? currentItemPerm.userGroups
      : [];
    dialogData.permsEnabled = currentItemPerm !== undefined
      ? currentItemPerm.permsEnabled
      : false;
  } else {
    dialogData.perms = attrs.perms;
  }

  dialogConfig.data = dialogData;

  this.zone.run(() => {
    const dialogRef = this.dialog.open(ItemPermsSelectorDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async result => {
      if (result !== undefined && !! result.perms) {
        if (isItemOfParentItem !== undefined) {
          const updatedItemPerm:any = {};

          updatedItemPerm.permsEnabled = result.permsEnabled;
          updatedItemPerm.userGroups = result.perms;
          updatedItemPerm.linkedModel = itemType;
          updatedItemPerm.linkedItemUUID = itemUUID;

          const currentItemPermIndex = attrs.itemsPerms
            .findIndex(item => item.linkedItemUUID === itemUUID);

          if (currentItemPermIndex !== -1) {
            // Update current perm
            attrs.itemsPerms[currentItemPermIndex] = updatedItemPerm;
          } else {
            // Add new perm to .itemPerms
            attrs.itemsPerms.push(updatedItemPerm);
          }
        } else {
          attrs.permsEnabled = result.permsEnabled;
          attrs.perms = result.perms;
        }
        model.setAttributes(attrs);
      }
    });
  });
}
