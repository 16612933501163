import { paramCase } from 'change-case';

import { cacheInjectImageDataUrl, modelSetClass, updatePageFormItemPerms } from './../../utils';

import { ICON_TEXT_TEXT_BLOCK } from './../../../const/icons';

import { FIELD_TEXT_EDITOR } from './../../../const/traits';

import { CMD_OPEN_TEXT_EDITOR} from './../../../const/commands';

import {
  COMP_LAYOUT_ROW,
  COMP_LOREM_IPSUM_LONG,
  COMP_TEXT_BLOCK
} from './../../../const/components';

const compParamName = paramCase(COMP_TEXT_BLOCK);

export const TextBlock = editor => {
  return {
    isComponent: el => el.tagName === compParamName.toUpperCase(),
    // Model
    model: {
      defaults: {
        displayIcon: ICON_TEXT_TEXT_BLOCK,
        tagName: compParamName,
        name: 'Text Block',
        droppable: false,
        draggable: paramCase(COMP_LAYOUT_ROW),
        attributes: { body: COMP_LOREM_IPSUM_LONG },
        traits: [FIELD_TEXT_EDITOR]
      }
    },
    // View
    view: {
      tagName: compParamName,
      events: {
        dblclick: 'doubleClickEvent'
      },
      // Init
      init() {
        updatePageFormItemPerms.call(this, editor);
        this.listenTo(this.model, 'change:attributes', this.handleAttributeChanges);
      },
      // Double click event
      doubleClickEvent(e) {
        editor.Commands.run(CMD_OPEN_TEXT_EDITOR);
      },
      // Attribute changes handler
      handleAttributeChanges() {
        updatePageFormItemPerms.call(this, editor);
        this.render();
      },
      // On render
      onRender() {
        const attrs = this.model.getAttributes();
        this.el.innerHTML = attrs.body;
        const images = this.el.getElementsByTagName('img');
        var url;
        for (var image of images) {
          url = `${editor.baseUrl}${image.getAttribute('imageuuid')}/`;
          if (editor.isSecureStorage) {
            cacheInjectImageDataUrl(url, image, null, editor.indexDb);
          } else {
            image.src = url;
          }
        }
        modelSetClass.call(this, 'item text-block');
      }
    }
  }
}