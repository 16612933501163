import { Component, OnInit, Input } from '@angular/core';

import { State } from '@app/state';

@Component({
  selector: 'day-calendar-event',
  templateUrl: './day-calendar-event.component.html',
  styleUrls: ['./day-calendar-event.component.scss']
})
export class DayCalendarEventComponent implements OnInit {

  @Input() datetime = '';
  @Input() hour = '';

  constructor(private state: State) { }

  ngOnInit() {
    const dateToCompare = this.datetime + ' ' + this.hour;
    // this.state.subscription().subscribe( data =>{
    //     if(data['dayCaledarEvents']){

    //     }
    // })
  }
}
