import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DirectoryItemService } from '@services/directory-item.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { SidebarService } from '@services/sidebar.service';
import { BaseComponent } from '../../base.component';
import { FileInfo } from '@app/models/entities/fileInfo';
@Component({
  selector: 'directory-item-form',
  templateUrl: './directory-item-form.component.html',
  styleUrls: ['./directory-item-form.component.scss'],
  providers: [SidebarService]
})

export class DirectoryItemFormComponent extends BaseComponent {
  directoryItemFormGroup: FormGroup;
  dataLoaded = false;
  globalUtil = GlobalUtil;
  formSubmitted = false;
  constructor(
    public activatedRoute: ActivatedRoute,
    public directoryItemService: DirectoryItemService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService:  directoryItemService
    });

    this.setPageVars({
      sidebar: ''
    });

    this.initialFormGroupSetup();

    const id = this.routeParams['id'];

    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result: any) => {
        this.dataLoaded = true;
        this.directoryItemFormGroup.patchValue(result);
        if(!! result.country) {
          this.controls.country_uuid.setValue(result.country.uuid);
        }
      });
    }
  }

  get controls(): any {
    return this. directoryItemFormGroup.controls;
  }

  public save(): void {
    const uuid = this.controls.uuid.value;
    this.formSubmitted = true;
    this.directoryItemFormGroup.patchValue({
      published: !!this.controls.published.value,
    });
    if (uuid) {
      this.directoryItemService.patchById(uuid, this.directoryItemFormGroup.value)
        .subscribe(result => {
          this.SavedMessage(result);
        });
    } else {
      this.directoryItemService.create(this.directoryItemFormGroup.value)
        .subscribe(result => {
          this.SavedMessage(result);
        });
    }
    setTimeout (() => {
      this.formSubmitted = false;
    }, 3000);
  }

  // All the private methods
  private initialFormGroupSetup(): void {
    this. directoryItemFormGroup = this.formBuilder.group({
      uuid: [null],
      name: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      directory_categories: [null, [Validators.required]],
      teaser: [null, [Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      body: [null],
      featured_image_cdn_uuid: [null],
      featured_image_cdn_url: [null],
      featured_image_width: [null],
      featured_image_height: [null],
      email: [null, [Validators.email, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      url: [null, [Validators.maxLength(1000)]],
      map_url: [null, [Validators.maxLength(1000)]],
      google_business_url: [null, [Validators.maxLength(1000)]],
      telephone: [null, [Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      address: [null, [Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      city: [null, [Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      state: [null, [Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      postcode: [null, [Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      country_uuid: [null],
      published: [true],
    });
  }

  // Listens for FileInfo from the upload component
  public getUploadedFileInfo($fileInfo: FileInfo) {
    if ($fileInfo) {
      this.directoryItemFormGroup.patchValue({
        'featured_image_cdn_uuid': $fileInfo.uuid,
        'featured_image_cdn_url': $fileInfo.cdnUrl,
        'featured_image_width': $fileInfo.width,
        'featured_image_height': $fileInfo.height,
      });
    } else {
      this.directoryItemFormGroup.patchValue({
        'featured_image_cdn_uuid': null,
        'featured_image_cdn_url': null,
        'featured_image_width': null,
        'featured_image_height': null,
      });
    }
  }

  private SavedMessage(data) {
    this.state.message({ ['DirectoryItemIndexUpdate']: true });
    this.closeSlideout();
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

}
