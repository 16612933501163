import { Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
  selector: 'core-form-checkbox',
  templateUrl: './core-form-checkbox.component.html',
  styleUrls: ['./core-form-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreFormCheckboxComponent),
      multi: true
    }
  ]
})
export class CoreFormCheckboxComponent implements ControlValueAccessor, OnInit {
  @HostBinding('attr.id') externalId = '';
  @Input() label = 'text';
  @Input() hint = '';
  // tslint:disable-next-line: no-input-rename
  @Input('value') val: string;
  ngOnInit() {
    // if (Number.isInteger(this.checked)) {
    //   this.checked = this.checked === 1 ? true : false;
    // }
  }
  // Both onChange and onTouched are functions
  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }
  // We implement this method to keep a reference to the onChange
  // callback function passed by the forms API
  registerOnChange(fn) {
    this.onChange = fn;
  }
  // We implement this method to keep a reference to the onTouched
  // callback function passed by the forms API
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  // This is a basic setter that the forms API is going to use
  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }
}
