import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { UserNotificationService } from '@services/user-notification.service';
@Component({
  selector: 'user-notifications-index',
  templateUrl: './user-notifications-index.component.html',
  styleUrls: [],
  providers: [SidebarService],
})
export class UserNotificationsIndexComponent extends BaseComponent implements OnInit {
  @Input() uuid: string;
  public dataSource: any;
  public data: any;
  public dataLoaded = false;
  public displayedColumns = [
    'created_at',
    'subject',
    'type',
    'sent_at',
    'read_at',
    // 'actions'
  ];

  constructor(
    public sidebarService: SidebarService,
    public router: Router,
    public state: State,
    public userNotificationService: UserNotificationService,
  ) {
    super();
    this.setObjects({
      modelService: userNotificationService,
      sidebarService: sidebarService,
      router: router,
      state: state
    });
    this.setPageVars({
      listenForIndexUpdate: true
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.modelServiceGetAllWithId(this.uuid).then(() => (this.dataLoaded = true));
  }
}
