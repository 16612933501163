import { Validators } from '@angular/forms';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';

import { BasePage } from '../base-page'

export   class Article implements BasePage
{
    constructor(private Page) {}

    // Initial form group
    public initialFormGroup(): void {
        this.Page.pageFormGroup = this.Page.formBuilder.group({
            body: [null],
            featured: [null],
            featured_image_cdn_uuid: [null],
            title: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
            article_categories: [[], [Validators.required]],
            published: [null],
            show_as_directory_item_in_list: [null],
            style: [null],
            tags: [[]],
            teaser: [null],
            uuid: [null],

            map_url: [null],
            cms_page_id: [null],
            phone: [null],
            postcode: [null],
            url: [null],
            email: [null,[Validators.pattern(GlobalUtil.Validation.EMAIL_PATTERN)]],

            articles: [[]],
            directory_items: [[]],
            documents: [[]],
            events: [[]],
            folders: [[]]
        })
    }

    // Before save
    public beforeSave(close = false): void {
    }

    // After save
    public afterSave(): void {
    }

    // Init Article
    public onInit(): Promise<any> {
        return new Promise((resolve, reject) => {
            const id = this.Page.routeParams['id'];
            if (id === undefined) {
                resolve(null);
            } else {
                this.Page.modelService.getFromApi('article/edit/' + id).subscribe((result: any) => {
                    this.Page.pageFormGroup.patchValue(result);
                    this.Page.pageFormGroup.patchValue({
                      article_categories: result.article_categories,
                      articles: result.articles,
                      directory_items: result.directory_items,
                      documents: result.documents,
                      events: result.events,
                      folders: result.folders,
                      tags: result.tags
                    });

                    // Articles
                    result.articles_with_attributes.forEach(articleItem =>
                        this.Page.snapshots.articles[articleItem.uuid] = articleItem
                    );
                    // Directory items
                    result.directory_items_with_attributes.forEach(directoryItemItem =>
                        this.Page.snapshots.directoryItems[directoryItemItem.uuid] = directoryItemItem
                    );
                    // Documents
                    result.documents_with_attributes.forEach(documentItem =>
                        this.Page.snapshots.documents[documentItem.uuid] = documentItem
                    );
                    // Events
                    result.events_with_attributes.forEach(eventItem =>
                        this.Page.snapshots.events[eventItem.uuid] = eventItem
                    );
                    // Folders
                    result.folders_with_attributes.forEach(folderItem =>
                        this.Page.snapshots.folders[folderItem.uuid] = folderItem
                    );
                    resolve(result);
                });
            }
        });
    }

    // Init editor commands
    public initEditorCommands(): void {
    }

    // Init editor traits
    public initEditorTraits(): void {
    }
}