import { paramCase } from 'change-case';

import {
  fixToolbarPositinoOnComponentHeightChange,
  iconElement,
  modelSetClass,
  updatePageFormItemPerms,
  fixPerms
} from './../../utils';

import {
  // Layout
  COMP_LAYOUT_ACCORDION,
  COMP_LAYOUT_ROW,
  COMP_LAYOUT_ROW_DROPPABLE,
  COMP_LAYOUT_SECTION,
} from './../../../const/components';

import {
  // FIELD_USE_FLEX,
  FIELD_H_SPACING,
  FIELD_JUSTIFY_CONTENT,
  FIELD_V_SPACING,
  TRAIT_ROW_COLUMN_ITEM_FLOW,
  TRAIT_ROW_COLUMN_ITEM_SPACING
} from '../../../const/traits';

import { ICON_LAYOUT_ROW } from './../../../const/icons';

const compParamName = paramCase(COMP_LAYOUT_ROW);

export const Row = editor => {
  return {
    isComponent: el => el.tagName === compParamName.toUpperCase(),
    // Model
    model: {
      init() {
        // Correction for change of spacing traits to bool from string
        const attrs = this.getAttributes();
        var spacingAttrFix = false;

        if (typeof attrs.hSpacing === 'string') {
          attrs.hSpacing = attrs.hSpacing === 'none'
            ? false
            : true;
          spacingAttrFix = true;
        }
        if (typeof attrs.vSpacing === 'string') {
          attrs.vSpacing = attrs.vSpacing === 'none'
            ? false
            : true;
          spacingAttrFix = true;
        }

        // Save attrs if the fix has been applied
        if (spacingAttrFix) {
          this.setAttributes(attrs);
        }
      },
      defaults: {
        displayIcon: ICON_LAYOUT_ROW,
        hoverable: false,
        badgable: false,
        tagName: compParamName,
        name: 'Row',
        draggable: `${paramCase(COMP_LAYOUT_SECTION)},${paramCase(COMP_LAYOUT_ACCORDION)}`,
        droppable: COMP_LAYOUT_ROW_DROPPABLE,
        attributes: {
          title: null,
          collapse: null,
          useFlex: false,
          hSpacing: null,
          vSpacing: null,
          justifyContent: 'even-width'
        },
        traits: [
          // FIELD_USE_FLEX,
          { type: TRAIT_ROW_COLUMN_ITEM_FLOW },
          // { type: TRAIT_ROW_COLUMN_ITEM_SPACING }
          FIELD_H_SPACING,
          FIELD_V_SPACING,
          // FIELD_JUSTIFY_CONTENT
        ]
      }
    },
    // View
    view: {
      tagName: compParamName,
      // Init
      init() {
        updatePageFormItemPerms.call(this, editor);
        this.listenTo(this.model, 'change:attributes', this.handleAttributeChanges);
      },
      // Attribute changes handler
      handleAttributeChanges() {
        updatePageFormItemPerms.call(this, editor);
        this.render();
        fixToolbarPositinoOnComponentHeightChange(editor);
      },
      // On render
      onRender() {
        const attrs = this.model.getAttributes();
        let rowClass = compParamName;

        if (attrs.useFlex) {
          rowClass += ' row-flex';
          if (attrs.justifyContent !== 'none') {
            rowClass += ` row-flex-justify row-flex-justify-${attrs.justifyContent}`;
          }
        }

        // Changed to work with bool spacing attr
        if (attrs.hSpacing === true || attrs.vSpacing === true) {
          rowClass += ' row-item-spacing';
          if (attrs.hSpacing === true) {
            rowClass += ' row-item-h-spacing-large';
          }
          if (attrs.vSpacing) {
            rowClass += ' row-item-v-spacing-large';
          }
        }

        // if (attrs.hSpacing !== 'none' || attrs.vSpacing !== 'none') {
        //   rowClass += ' row-item-spacing';
        //   if (attrs.hSpacing !== 'none') {
        //     rowClass += ' row-item-h-spacing-' + attrs.hSpacing;
        //   }
        //   if (attrs.vSpacing !== 'none') {
        //     rowClass += ' row-item-v-spacing-' + attrs.vSpacing;
        //   }
        // }
        modelSetClass.call(this, rowClass);
      }
    }
  }
}