import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ApiDataService } from '@services/api-data.service';
@Component({
  selector: 'core-form-select',
  templateUrl: './core-form-select.component.html',
  styleUrls: ['./core-form-select.component.scss'],
  providers: [
    ApiDataService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreFormSelectComponent),
      multi: true
    }
  ]
})

export class CoreFormSelectComponent implements OnInit, ControlValueAccessor {
  @Input()
  set id(value: string) {
    this._ID = value;
  }
  @Input() apiDataSource = '';
  @Input() apiDataSourceCacheKey = '';
  @Input() autoFocus = false;
  @Input() dataSourceValue = 'uuid';
  @Input() dataSourceText = 'name';
  @Input() dataSourceTextSecondLine:any = false;
  @Input() disabled = false;
  @Input() name = '';
  @Input() placeHolder = '';
  @Input() selectOptions = [];
  @Input() selected: Array<any> = [];
  @Input() disableIfDataEmpty = false;
  @Input() updateParentWithSelectedResult = false;
  @Input() groupedResult = false; // Group options within <optgroup>
  @Input() showSearch = false; // Show the searchbox
  @Input() allowMultiple = false; // Use the checkbox style interface
  @Input() showEmptyOption = true; // Show an empty <option> as the first <option> (hidden by default when 'allowMultiple' is true)
  @Input('value') _value: any;

  @Output() notifyParentComponentEvent: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
  @Output() notifyParentComponentApiResultNotEmpty: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() shareOptionsWithParent: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  public dataLoaded = false;
  public options: Array<any> = [];
  private _ID = '';

  constructor(private apiDataService: ApiDataService) { }

  get id() {
    return this._ID;
  }

  get value() {
    return this._value;
  }

  set value(val) {
    // This seems to help the events not be called twice on the same value selection / null value set
    if (this._value !== val) {
      this._value = val;
      this.onChange(val);
      this.onTouched();
    }
  }

  ngOnInit(): void {
    if (this.apiDataSource !== '' && typeof this.apiDataSource !== 'undefined') {
      this.apiDataService
        .getOptions(this.apiDataSource, this.apiDataSourceCacheKey)
        .subscribe((result: any) => {
          const data: any = typeof result.data !== 'undefined' ? result.data : result;
          this.convertObjectToValues(data);
          if (this.disableIfDataEmpty === true) {
            this.disableIfDataEmpty = data.length === 0;
          }
          this.notifyParentComponentApiResultNotEmpty.emit(data.length > 0);
        });
    } else if (this.selectOptions && this.selectOptions.length > 0) {
      this.options = this.selectOptions;
      this.shareOptionsWithParent.emit(this.options);
      this.dataLoaded = true;
    }
  }

  notifyParentComponent() {
    if (this.updateParentWithSelectedResult) {
      this.notifyParentComponentEvent.emit(this.selected);
    }
  }

  private convertObjectToValues(objectToParse): void {
    if (this.groupedResult) {
      objectToParse.forEach((groupedObject: any) => {
        const groupedObjectTmp: any = {};
        groupedObjectTmp.name = groupedObject.name;
        groupedObjectTmp.count = groupedObject.count;
        groupedObjectTmp.list = [];

        groupedObject.list.forEach(object => {
          const option: any = {
            value: object[this.dataSourceValue],
            text: object[this.dataSourceText],
            selected: this.setSelected(object[this.dataSourceValue])
          };
          if (this.dataSourceTextSecondLine && !! object[this.dataSourceTextSecondLine]) {
            option.textSecondLine = object[this.dataSourceTextSecondLine];
          }
          groupedObjectTmp.list.push(option);
        });
        this.options.push(groupedObjectTmp);
      });
    } else {
      if (this.allowMultiple) {
        const multiValues = [];
        if (this.value !== undefined) {
          [...this.value].forEach(val => {
            if (val.uuid !== undefined) {
              multiValues.push(val.uuid);
            } else {
              multiValues.push(val);
            }
          });
          // set selected values back to Value variable.
          this.value = multiValues;
        }
        if (objectToParse.error === undefined) {
          objectToParse.forEach(object => {
            const option: any = {
              value: object[this.dataSourceValue],
              text: object[this.dataSourceText]
            };
            if (this.dataSourceTextSecondLine && !! object[this.dataSourceTextSecondLine]) {
              option.textSecondLine = object[this.dataSourceTextSecondLine];
            }
            this.options.push(option);
          });
        }
      } else {
        if (objectToParse.error === undefined) {
          objectToParse.forEach(object => {
            const option: any = {
              value: object[this.dataSourceValue],
              text: object[this.dataSourceText],
              selected: this.setSelected(object[this.dataSourceValue])
            };
            if (this.dataSourceTextSecondLine && !! object[this.dataSourceTextSecondLine]) {
              option.textSecondLine = object[this.dataSourceTextSecondLine];
            }
            this.options.push(option);
          });
        }
      }
    }
    this.shareOptionsWithParent.emit(this.options);
    this.dataLoaded = true;
  }

  private setSelected(key: any): boolean {
    return key === this.value;
  }

  // Not sure why both onChange and onSelectionChange exist
  // as this can result in the event being emitted twice?!
  // Craig 26-01-21

  onChange: any = (val: any) => {
    if (this.updateParentWithSelectedResult) {
      this.notifyParentComponentEvent.emit(val);
    }
  }

  // This one can probabley be removed?
  onSelectionChange: any = (selection: any) => {
    if (this.updateParentWithSelectedResult) {
      this.notifyParentComponentEvent.emit(selection.value);
    }
  }

  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
}
