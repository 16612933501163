import { Component, Input } from '@angular/core';
import { Environment } from '@environments/environment';
@Component({
  selector: 'app-form-base-element',
  template: '',
})
export class FormBaseElementComponent {

  @Input() type = 'text';
  @Input() label = 'text';
  @Input() name = 'name';
  @Input() value = 'name';
  @Input() defaultValue?: any = null;

  protected ApiUrl = Environment.apiURL;

  constructor() { }

  resetValue() {
    if (!!this.defaultValue) {
      // this.setDefaultValue()
    } else {
      this.value = '';
    }
  }

  data() {
    return this.value;
  }
}
