import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'sidebar-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class SidebarFormBuilderComponent implements OnInit {
  @Input() field: any;
  @Input() form: any;
  @Input() submitted: boolean;
  @Output() controlInitialized = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() { }

  checkboxInitialized() {
    this.controlInitialized.next(true);
  }
}
