import { paramCase } from 'change-case';

import { modelSetClass, updatePageFormItemPerms } from './../../utils';

import {
  COMP_LAYOUT_PAGE_SECTION,
  COMP_LAYOUT_SECTION,
  COMP_LOREM_IPSUM,
  COMP_WRAPPER_SELECTOR
} from './../../../const/components';

import {
  CMD_UPDATE_PAGE_SECTIONS,
  CMD_REMOVE_FROM_PAGE_SECTIONS
} from './../../../const/commands';

import { FIELD_MISC_REMOVE_COMPONENT } from './../../../const/traits';

import { iconElement } from './../../utils';

import { ICON_LAYOUT_PAGE_SECTION } from './../../../const/icons';

const compParamName = paramCase(COMP_LAYOUT_PAGE_SECTION);

export const PageSection = editor => {
  return {
    isComponent: el => el.tagName === compParamName.toUpperCase(),
    // Model
    model: {
      init() {
        editor.Commands.run(CMD_UPDATE_PAGE_SECTIONS, { model: this });
      },
      defaults: {
        toolbar: [],
        displayIcon: ICON_LAYOUT_PAGE_SECTION,
        tagName: compParamName,
        name: 'Page Section',
        draggable: COMP_WRAPPER_SELECTOR,
        droppable: `
${paramCase(COMP_LAYOUT_SECTION)}`,
        attributes: { name: 'Page Section' },
        components: [{
          type: COMP_LAYOUT_SECTION,
          removable: false,
        }],
        traits: [{
          name: 'name',
          label: 'Title',
          default: 'Page Section'
        },
        FIELD_MISC_REMOVE_COMPONENT]
      }
    },
    // View
    view: {
      tagName: compParamName,
      // Init
      init() {
        this.listenTo(this.model, 'change:attributes', this.handleAttributeChanges);
      },
      // Attribute changes handler
      handleAttributeChanges() {
        editor.Commands.run(CMD_UPDATE_PAGE_SECTIONS);
        this.render();
      },
      // On render
      onRender() {
        modelSetClass.call(this, compParamName + ' ' + this.model.getClasses().join(' '))
      }
    }
  }
}