import { paramCase } from 'change-case';

import {
  fixToolbarPositinoOnComponentHeightChange,
  modelSetClass,
  updatePageFormItemPerms
} from './../../utils'

import {
  COMP_BUTTON_APP_PAGE,
  COMP_LAYOUT_PAGE_SECTION,
  COMP_LAYOUT_ROW,
  COMP_LAYOUT_SECTION,
  COMP_LAYOUT_SECTION_DROPPABLE,
  COMP_WRAPPER_SELECTOR,
} from './../../../const/components';

import { ICON_LAYOUT_SECTION } from './../../../const/icons';

const compParamName = paramCase(COMP_LAYOUT_SECTION);

export const Section = (editor, config) => {
  return {
    isComponent: el => el.tagName === compParamName.toUpperCase(),
    // Model
    model: {
      defaults: {
        displayIcon: ICON_LAYOUT_SECTION,
        tagName: compParamName,
        name: 'Section',
        draggable: config.type === COMP_BUTTON_APP_PAGE
          ? paramCase(COMP_LAYOUT_PAGE_SECTION)
          : COMP_WRAPPER_SELECTOR,
        droppable: COMP_LAYOUT_SECTION_DROPPABLE,
        components: [{ type: COMP_LAYOUT_ROW }],
        traits: []
      }
    },
    // View
    view: {
      tagName: compParamName,
      // Init
      init() {
        updatePageFormItemPerms.call(this, editor);
        this.listenTo(this.model, 'change:attributes', this.handleAttributeChanges);
      },
      // Attribute changes handler
      handleAttributeChanges() {
        updatePageFormItemPerms.call(this, editor);
        this.render();
        fixToolbarPositinoOnComponentHeightChange(editor);
      },
      // On render
      onRender() {
        modelSetClass.call(this, compParamName);
      }
    }
  }
}