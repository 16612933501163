import { paramCase } from 'change-case';

import {
  fixToolbarPositinoOnComponentHeightChange,
  fixInlineAttrs,
  modelSetClass,
  updatePageFormItemPerms,
  updatePageFormItems
} from './../utils';

import {
  appPageCard,
  articleCard,
  articleCategoryCard,
  bundleCard,
  calendarCard,
  contactGroupCard,
  DirectoryItemCard,
  documentCard,
  eventCard,
  fileCard,
  folderCard,
  periodisationCalendarCard,
  profileGroupCard,
  urlCard,
  userCircleCard
} from './buttons/cards';

import {
  inlineButtonOnRender,
  inlineImageOnRender,
  buttonOnRender,
  imageOnRender
} from './buttons/shared';

import {
  COMP_BUTTON_APP_COMPONENT,
  COMP_BUTTON_APP_PAGE,
  COMP_BUTTON_ARTICLE,
  COMP_BUTTON_ARTICLE_CATEGORY,
  COMP_BUTTON_BUNDLE,
  COMP_BUTTON_CALENDAR,
  COMP_BUTTON_CHAT,
  COMP_BUTTON_CONTACT_GROUP,
  COMP_BUTTON_DIRECTORY_ITEM,
  COMP_BUTTON_DOCUMENT,
  COMP_BUTTON_EVENT,
  COMP_BUTTON_FILE,
  COMP_BUTTON_FOLDER,
  COMP_BUTTON_PERIODISATION_CALENDAR,
  COMP_BUTTON_PLACEHOLDER,
  COMP_BUTTON_PRODUCT_URL,
  COMP_BUTTON_PROFILE_GROUP,
  COMP_BUTTON_URL,
  COMP_BUTTON_USER_CIRCLE
} from './../../const/components';

import {
  // Update page form items
  CMD_GET_ITEM_SNAPSHOT,
  CMD_OPEN_FILE_SELECTOR,
  CMD_OPEN_PAGE_ITEM_SELECTOR,
  CMD_REMOVE_FROM_PAGE_FORM_ITEMS,
  CMD_REMOVE_FROM_PAGE_ITEM_PERMS,
  CMD_UPDATE_ITEMS_SNAPSHOTS,
  CMD_UPDATE_PAGE_FORM_ITEM_PERMS,
  CMD_UPDATE_PAGE_FORM_ITEMS
} from './../../const/commands';

export const PageFormLinkedItem = (editor, options:any = {}) => {

  const itemType = options.componentName;
  const tagName = paramCase(itemType);

  options.model.defaults.itemType = itemType;
  options.model.defaults.tagName = tagName;
  options.inline = !! options.model.defaults.inline
    ? true
    : false;
  options.model.defaults.displayIcon = options.displayIcon;
  options.model.defaults.attributes.displayIcon = options.displayIcon;

  return {
    isComponent: el => el.tagName === tagName.toUpperCase(),
    // Model
    model: {
      // updated(property, value, prevValue) {},
      defaults: options.model.defaults,
    },
    // View
    view: {
      tagName: tagName,
      displayIcon: options.displayIcon,
      // Events
      events: {
        // click: 'singleClickEvent',
        dblclick: 'doubleClickEvent'
      },
      // Dblclick
      doubleClickEvent(e) {
        if (
          itemType === COMP_BUTTON_PLACEHOLDER ||
          itemType === COMP_BUTTON_URL ||
          itemType === COMP_BUTTON_PRODUCT_URL
        ) {
          return;
        }
        const attrs = fixInlineAttrs(this.model.getAttributes());

        if (itemType === COMP_BUTTON_FILE) {
          editor.Commands.run(CMD_OPEN_FILE_SELECTOR);
        } else {
          editor.Commands.run(CMD_OPEN_PAGE_ITEM_SELECTOR, { itemType: itemType });
        }
      },
      // Init
      init() {
        // editor.select(this.model);
        updatePageFormItemPerms.call(this, editor, true);
        if (
          itemType !== COMP_BUTTON_APP_COMPONENT &&
          itemType !== COMP_BUTTON_FILE &&
          itemType !== COMP_BUTTON_PLACEHOLDER &&
          itemType !== COMP_BUTTON_URL &&
          itemType !== COMP_BUTTON_PRODUCT_URL &&
          itemType !== COMP_BUTTON_CHAT
        ) {
          updatePageFormItems.call(this, editor);
          this.getSnapshot();
        }
        this.listenTo(this.model, 'change:attributes:url', this.handleUrlChange);
        this.listenTo(this.model, 'change:attributes', this.handleAttributeChanges);
      },
      // Get snapshot
      getSnapshot() {
        const attrs = fixInlineAttrs(this.model.getAttributes());

        if (!! attrs.itemUUID) {
          const snapshot = editor.Commands.run(CMD_GET_ITEM_SNAPSHOT, {
            itemUUID: attrs.itemUUID,
            itemType: options.inline
              ? attrs.inlineItemType
              : itemType
          });

          this.model.set('snapshot', snapshot);
          // if (snapshot) {
          //   // update all items that have same itemType and itemUUID
          //   // editor.Commands.run(CMD_UPDATE_ITEMS_SNAPSHOTS, {
          //   //   snapshot: snapshot, uuid: attrs.uuid
          //   // });
          // }
        }
      },
      // Attribute changes handler
      async handleAttributeChanges() {
        const attrs = fixInlineAttrs(this.model.getAttributes());
        const previous = this.model._previousAttributes.attributes;

        updatePageFormItemPerms.call(this, editor, true);

        if (itemType !== COMP_BUTTON_PRODUCT_URL && !! attrs.url && attrs.url !== previous.url) {
          const key = '4c6d408868f4a3fc539364ab0c4c8020';
          await fetch('https://api.linkpreview.net/?&key=' + key + '&q=' + attrs.url)
            .then(data => data.json())
            .then(data => {
              let urlSnapshot;

              if (!! data.error) {
                urlSnapshot = null;
              } else {
                urlSnapshot = {};
                if (!! data.url) {
                  urlSnapshot.url = data.url;
                }
                if (!! data.title) {
                  urlSnapshot.title = data.title;
                }
                if (!! data.image) {
                  urlSnapshot.image = data.image;
                }
                if (!! data.description) {
                  urlSnapshot.description = data.description;
                }
              }

              let updatedAttrs = attrs;
              updatedAttrs.urlSnapshot = urlSnapshot;
              this.model.setAttributes(updatedAttrs);
            });
        }

        if (
          itemType !== COMP_BUTTON_APP_COMPONENT &&
          itemType !== COMP_BUTTON_FILE &&
          itemType !== COMP_BUTTON_PLACEHOLDER &&
          itemType !== COMP_BUTTON_URL &&
          itemType !== COMP_BUTTON_PRODUCT_URL &&
          itemType !== COMP_BUTTON_CHAT
        ) {
          updatePageFormItems.call(this, editor);
        }
        this.render();
        fixToolbarPositinoOnComponentHeightChange(editor);
      },
      // On Render
      onRender() {
        const attrs = fixInlineAttrs(this.model.getAttributes());
        let modelClass = '';

        if (options.inline) {
          switch (attrs.displayAs) {
            case 'card':
              modelClass = 'inline-item-card item-card';
              if (attrs.floatItem !== 'none') {
                modelClass += ` float-item float-item-${attrs.floatItem}`;
              }
              this.el.innerHTML = this.getCardContent(attrs.inlineItemType);
              modelSetClass.call(this, modelClass);
            break;
            case 'image':
              inlineImageOnRender.call(this, editor);
            break;
            default:
              inlineButtonOnRender.call(this, editor);
            break;
          }
        } else {
          if (itemType === COMP_BUTTON_APP_COMPONENT || itemType === COMP_BUTTON_PLACEHOLDER) {
            switch (attrs.displayAs) {
              case 'image':
                imageOnRender.call(this, editor);
              break;
              default:
                buttonOnRender.call(this, editor);
              break;
            }
          } else {
            switch (attrs.displayAs) {
              case 'card':
                this.el.innerHTML = this.getCardContent(itemType);
                modelSetClass.call(this, 'item item-card');
              break;
              case 'image':
                imageOnRender.call(this, editor);
              break;
              default:
                buttonOnRender.call(this, editor);
              break;
            }
          }
        }
      },
      // Get card content
      getCardContent(cardItemType) {
        switch (cardItemType) {
          case COMP_BUTTON_APP_PAGE:
            return appPageCard.call(this);
          break;
          case COMP_BUTTON_ARTICLE:
            return articleCard.call(this);
          break;
          case COMP_BUTTON_ARTICLE_CATEGORY:
            return articleCategoryCard.call(this);
          break;
          case COMP_BUTTON_BUNDLE:
            return bundleCard.call(this);
          break;
          case COMP_BUTTON_CALENDAR:
            return calendarCard.call(this);
          break;
          case COMP_BUTTON_CONTACT_GROUP:
            return contactGroupCard.call(this);
          break;
          case COMP_BUTTON_DIRECTORY_ITEM:
            return contactGroupCard.call(this);
          break;
          case COMP_BUTTON_DOCUMENT:
            return documentCard.call(this);
          break;
          case COMP_BUTTON_EVENT:
            return eventCard.call(this);
          break;
          case COMP_BUTTON_FOLDER:
            return folderCard.call(this);
          break;
          case COMP_BUTTON_FILE:
            return fileCard.call(this);
          break;
          case COMP_BUTTON_PERIODISATION_CALENDAR:
            return periodisationCalendarCard.call(this);
          break;
          case COMP_BUTTON_PROFILE_GROUP:
            return profileGroupCard.call(this);
          break;
          case COMP_BUTTON_USER_CIRCLE:
            return userCircleCard.call(this);
          break;
          case COMP_BUTTON_URL:
          case COMP_BUTTON_PRODUCT_URL:
            return urlCard.call(this);
          break;
        }
      }
    }
  }
}