import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { CustomFieldService } from '@app/services/custom-field.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-custom-field-view-group',
  templateUrl: './custom-field-group-view.component.html',
  styleUrls: ['./custom-field-group-view.component.scss']
})
export class CustomFieldGroupViewComponent extends BaseComponent implements OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  public dataSource: any;
  public data: any;
  public dataLoaded = false;
  public groupId = null;
  public displayedColumns = [
    'select',
    // 'display_order',
    'name',
    'type',
    'active',
    'created_at',
    'actions'
  ];

  constructor(
    public activatedRoute: ActivatedRoute,
    public customFieldService: CustomFieldService,
    public router: Router,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog,
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      modelService: customFieldService,
      router: router,
      state: state,
      snackbarService,
      dialog
    });

    // Check if we need to refresh the page - after CustomField form submitted etc.
    this.state.subscription().subscribe((message: any) => {
      if (!!message['CustomFieldGroupViewUpdate']) {
        this.loadCustomFieldData();
      }
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadCustomFieldData();
  }

  public updatedStatus(): void {
    this.loadCustomFieldData();
  }

  // Overrides the usual openSlideOutWithLink function
  public openFormSlideOut(itemId: any, action = 'edit') {
    const url = `/custom-field-groups/view/${this.routeParams['id']}/(slideout:custom-field-${action}/${itemId})`;
    this.router.navigateByUrl(url,
      { skipLocationChange: true }).then(() => this.state.message({ slideoutShow: true }));
  }

  // Overrides the usual openSlideOutWithLink function
  public openSortFieldsSlideOut() {
    const groupId = this.routeParams['id'];
    const url = `/custom-field-groups/view/${groupId}/(slideout:sort-fields/${groupId})`;
    this.router.navigateByUrl(url,
      { skipLocationChange: true }).then(() => this.state.message({ slideoutShow: true }));
  }

  // Override the generic delete method as we need customization - We need to remove the current row from CustomField table.
  public modelServiceDelete(uuid: string) {
    this.confirmDialog().then(confirmed => {
      if (confirmed) {
        this.customFieldService.delete(uuid).subscribe(
          result => {
            this.loadCustomFieldData();
            this.snackbarService.success({ message: 'Field deleted' });
          }
        );
      }
    });
  }

  // Delete multiple items selected from table checkboxes
  // this overrides the generic function in base.component
  public modelServiceSelectedDelete() {
    if (this.selection.selected.length === 0) {
      this.snackbarService.warning({ message: 'No Items selected' });
      return;
    }
    this.confirmDialog().then(confirmed => {
      if (confirmed) {
        const snackbarRef = this.snackbarService.openProgress('Deleting...');
        forkJoin(this.selection.selected.map(selected => this.customFieldService.delete(selected.uuid))).subscribe(
          result => {
            this.loadCustomFieldData();
            snackbarRef.dismiss();
            this.snackbarService.success({
              message: 'Fields deleted successfully'
            });
          },
          (error: any) => {
            console.log('Error deleting multiple content: ' + JSON.stringify(error));
          }
        );
      }
      this.selection.clear();
    });
  }

  /**
   * Get the single CustomField and also and subfields
   */
  private loadCustomFieldData(): void {
    this.groupId = this.routeParams['id'];
    this.modelService.getOne(this.groupId).subscribe((result: any) => {
      this.buildBreadcrumb(result.name);
      this.dataSource = new MatTableDataSource(result.custom_fields);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataLoaded = true;
    });
  }

  private buildBreadcrumb(crumb: string): void {
    this.nodes = [{
      label: 'Custom Fields',
      link: 'custom-field-groups',
      title: 'Custom Fields'
    }];
    this.nodes.push({
      label: crumb,
      link: '',
      title: ''
    });
  }

}
