import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { NoteService } from '@app/services/note.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
import { NOTES_FILTER_FIELDS } from './filter-fields';
import { SidebarFilterLink } from '@app/services/sidebar-filter-link.service';
import { FilterLink } from '@app/models/view-models/filter-link';
@Component({
  selector: 'app-notes-index',
  templateUrl: './notes-index.component.html',
  styleUrls: ['./notes-index.component.scss'],
  providers: [SidebarService]
})
export class NotesIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['created_at', 'title', 'category', 'created_for_user', 'actions'];
  public dataLoaded = false;
  public node: Breadcrumb;
  public fields: any = NOTES_FILTER_FIELDS;
  public links: FilterLink[] = [];

  constructor(
    public noteService: NoteService,
    public router: Router,
    public sidebarService: SidebarService,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog,
    private sidebarFilterLink: SidebarFilterLink
  ) {
    super();
    this.setObjects({
      modelService: noteService,
      router,
      sidebarService,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      sidebar: 'notes',
      listenForIndexUpdate: true
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.modelServiceGetAll().then(() => (
      this.dataLoaded = true
    ));
    this.node = {
      label: 'Notes',
      link: 'notes',
      title: 'Notes'
    };
    this.buildSettingsBreadcrumb(this.node);
    this.links = this.sidebarFilterLink.buildNotesFilterLinks();
  }

  public updatedStatus(): void {
    this.state.message({ ['NoteIndexUpdate']: true });
  }

  public onFilterClicked(link: FilterLink): void {
    if (link.route) {
      this.router.navigate([link.route]);
    }
  }
}
