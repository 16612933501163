import { Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, OnInit, AfterViewInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileInfo } from '@app/models/entities/fileInfo';
import { State } from '@app/state';
import { FormBaseElementComponent } from '@elements/form/form-base-element.component';
import { DocumentService } from '@services/document.service';
import { UploadService } from '@services/upload.service';

@Component({
  selector: 'core-form-upload',
  templateUrl: './core-form-upload.component.html',
  styleUrls: ['./core-form-upload.component.scss', './core-form-upload-700.component.scss'],
  host: { class: 'form-element' },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreFormUploadComponent),
      multi: true
    }
  ]
})
export class CoreFormUploadComponent extends FormBaseElementComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input() createDocumentInDb = true;
  @Input() multiple = false;
  @Input() openDialogueOnFirstLoad = false;
  @Input() imagesOnly = false;
  @Input() crop = false;
  @Input() name = 'file_upload';
  @Input() noPreview = false;
  @Input() noUpdateOfParentForm = false;
  @Input() previewSrc = '';
  @Input() valueForForm: any;
  @Input() uuid: any = false;
  // tslint:disable-next-line:no-input-rename
  @Input('value') val: string;

  @Output() valueChanged = new EventEmitter<FileInfo>();

  @HostBinding('class.waiting-for-file-choice') private waitingForFileChoice = true;
  @HostBinding('class.uploading') private uploading = false;
  @HostBinding('class.show-preview-and-reset') private showPreviewAndRemoveButton = false;

  @ViewChild('ngxUploadcareWidget', {static: false}) uploadWidget: any;

  public showPreviewIfImageUploaded = false;
  public percentComplete = '0%';
  public backgroundImageStyle = '';
  public document: object = {};
  public publicKey: string = '';
  public secretKey: string = '';
  public secureSignature: string = '';
  public secureExpire: string = '';
  private ucareURL = 'https://ucarecdn.com/';
  // Both onChange and onTouched are functions
  onChange: any = () => { };
  onTouched: any = () => { };

  constructor(
    public documentService: DocumentService,
    public elementRef: ElementRef,
    public uploadService: UploadService,
    public state: State
  ) {
    super();
  }

  ngOnInit() {
    this.publicKey = this.uploadService.publicKey;
    this.secretKey = this.uploadService.secretKey;
    if (! this.noPreview && this.uuid) {
      this.setPreviewImage(this.uuid);
    }
    if (this.multiple) {
      this.valueForForm = [];
    }
    if (this.uuid === null && this.openDialogueOnFirstLoad) {
      setTimeout(() => this.openDialogue());
    }
  }

  ngAfterViewInit() {
    // disabled for now to make it easier to use in app and CC
    // this.setSecureVars();
  }

  setSecureVars() {
    this.uploadService.setSecureVars();
    this.uploadWidget.secureExpire = this.uploadService.secureExpire;
    this.uploadWidget.secureSignature = this.uploadService.secureSignature;
  }

  // Sets a preview image in the UploadCare widget
  setPreviewImage(uuid) {
    this.showPreviewIfImageUploaded = true;
    if(this.uploadService.secure) {
      this.uploadService.getUcareSecureUrl(uuid, '/-/preview/-/resize/350x/')
        .subscribe((result:any) => this.backgroundImageStyle = `url(${result.url})`);
    } else {
      this.backgroundImageStyle = `url(${this.ucareURL}${uuid}/-/preview/-/resize/350x/)`;
    }
  }

  unsetPreviewImage() {
    this.showPreviewIfImageUploaded = false;
    this.backgroundImageStyle = '';
  }

  uploadComplete(payload) {
    if (this.multiple) {
      this.uploadService.getFileGroup(payload.uuid).then((fileGroup: any) => {
        fileGroup.files.forEach((file: any, index) => {
          const value = this.prepFilePayloadForInputVar(file);
          this.valueForForm.push(value);
          if (this.createDocumentInDb) {
            this.documentService.createDocument(value).then(res => {
              if (index === fileGroup.files.length - 1) {
                this.resetWidget();
              }
            });
          }
        });
      });
    } else {
      this.percentComplete = '0%';
      this.uploading = false;
      this.showPreviewAndRemoveButton = true;
      if (payload.isImage) {
        this.setPreviewImage(payload.uuid);
      }
      this.valueForForm = this.prepFilePayloadForInputVar(payload);
      if (this.valueChanged.observers.length > 0) {
        this.valueChanged.emit(this.valueForForm);
      }
    }
  }

  private prepFilePayloadForInputVar(file: any) {
    const value: any = {
      uuid: file.uuid,
      filesize: file.size,
      cdnUrl: file.cdnUrl,
    };
    value.filename = this.multiple ? file.original_filename : file.name;
    value.mimeType = this.multiple ? file.mime_type : file.mimeType;
    value.fileFamily = this.fileFamily(value.mimeType);
    if (file.isImage) {
      value.width = file.originalImageInfo.width;
      value.height = file.originalImageInfo.height;
      if (file.crop) {
        value.width = file.crop.width;
        value.height = file.crop.height;
      }
    }
    return value;
  }

  // Creates file family by truncating mimeType supplied by UploadCare (image/jpeg)
  fileFamily(mimeType: string) {
    return mimeType.split('/')[0];
  }

  fileChange($event: any) {
    if ($event === null) {
      this.resetWidget();
    }
  }

  uploadProgress(payload: { state: string; progress: number; }) {
    // if (! this.uploading) {
    //   this.setSecureVars();
    // }
    setTimeout(() => {
      this.uploading = true;
      this.waitingForFileChoice = false;
      if (payload.state === 'ready') {
        this.percentComplete = '100%';
        if (this.openDialogueOnFirstLoad) {
          this.openDialogue();
        }
        return;
      }
      if (payload.state === 'error') {
        this.resetWidget();
        return;
      }
      this.percentComplete = Math.max(0, Math.ceil(payload.progress * 100)) + '%';
    });
  }

  openDialogue() {
    this.openDialogueOnFirstLoad = false;
    this.uploadWidget.openDialog();
  }

  resetWidget() {
    setTimeout(() => {
      this.uploading = false;
      this.showPreviewAndRemoveButton = false;
      this.waitingForFileChoice = true;
      this.valueForForm = this.multiple ? [] : null;
      if (this.valueChanged.observers.length > 0) {
        this.valueChanged.emit(this.valueForForm);
      }
      this.unsetPreviewImage();
      this.uploadWidget.reset(true);
    });
  }

  data() {
    return this.valueForForm;
  }

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
  }

  // We implement this method to keep a reference to the onChange
  // callback function passed by the forms API
  registerOnChange(fn) {
    this.onChange = fn;
  }

  // We implement this method to keep a reference to the onTouched
  // callback function passed by the forms API
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // This is a basic setter that the forms API is going to use
  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }
}
