import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { AccessCodeUsersService } from '@services/access-code-users.service';
@Component({
  selector: 'app-access-code-view',
  templateUrl: './access-code-view.component.html',
  styleUrls: ['./access-code-view.component.scss']
})
export class AccessCodeViewComponent extends BaseComponent implements OnInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  public dataSource: any;
  public data: any;
  public dataLoaded = false;
  public accessCodeID = null;
  public displayedColumns = [
    'created_at',
    'firstname',
    'email',
  ];

  constructor(
    public activatedRoute: ActivatedRoute,
    public accessCodeUsersService: AccessCodeUsersService,
    public router: Router,
    public state: State,
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      modelService: accessCodeUsersService,
      router: router,
      state: state
    });
    this.setPageVars({
      listenForIndexUpdate: true
    });
  }

  public updatedStatus(): void {
    this.state.message({
      ['AccessCodeUserViewUpdate']: true,
      ['id']: this.routeParams['id']
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.modelService.getAllWithId(this.routeParams['id']).subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataLoaded = true;
      this.accessCodeID = this.routeParams['id'];
    });
  }
}
