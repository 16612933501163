import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { State } from '@app/state';
import { AuthService } from '@services/auth.service';

/**
 * Route Sportsology Super User Guard
 */
@Injectable()
export class SportsologySuperUserGuard implements CanActivate {

  private authUser:any = null;
  private guardReady:boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private state: State
  ) {
    this.state.subscription().subscribe(state => {
      if (!! state.user) {
        this.guardReady = true;
        this.authUser = state.user;
      }
    });
  }

  /**
   * Can activate route
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} routeState
   * @return {Observable<boolean>}
   */
  canActivate(route: ActivatedRouteSnapshot, routeState: RouterStateSnapshot): boolean {
    if (! this.userIsSportsologySuperUser()) {
      this.authService.destroyApp('You do not have permissions to view that route');
      this.authService.logout();
      return false;
    }
    return true;
  }

  /**
   * User is Sportsology super user
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} routeState
   * @return {Observable<boolean>}
   */
  userIsSportsologySuperUser(): boolean {
    if (this.guardReady && (
      this.authUser === null ||
      this.authUser.sportsology_super_admin === undefined ||
      this.authUser.sportsology_super_admin !== true
    )) {
      return false;
    }
    return true;
  }
}
