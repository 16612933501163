import { paramCase } from 'change-case';

import {
  fixToolbarPositinoOnComponentHeightChange,
  modelSetClass,
  updatePageFormItemPerms
} from './../../utils';

import {
  CMD_DEFAULT_ITEM_TOOLBAR,
  CMD_ITEM_TOOLBAR_SCROLL_LEFT,
  CMD_ITEM_TOOLBAR_SCROLL_RIGHT
} from './../../../const/commands';

import {
  COMP_LAYOUT_ACCORDION,
  COMP_LAYOUT_SECTION,
  COMP_MEDIA_IMAGE,
  COMP_MEDIA_IMAGE_SLIDER
} from './../../../const/components';

import {
  FIELD_SHOW_DOTS,
  FIELD_AUTO_SCROLL,
  FIELD_SHOW_PREV_NEXT
} from './../../../const/traits';

import { ICON_MEDIA_IMAGE_SLIDER } from './../../../const/icons';

const compParamName = paramCase(COMP_MEDIA_IMAGE_SLIDER);

export const ImageSlider = editor => {
  return {
    isComponent: el => el.tagName === compParamName.toUpperCase(),
    // Model
    model: {
      defaults: {
        displayIcon: ICON_MEDIA_IMAGE_SLIDER,
        toolbar: [
          ...CMD_DEFAULT_ITEM_TOOLBAR,
          CMD_ITEM_TOOLBAR_SCROLL_LEFT,
          CMD_ITEM_TOOLBAR_SCROLL_RIGHT
        ],
        tagName: compParamName,
        name: 'Image Slider',
        draggable: `${paramCase(COMP_LAYOUT_SECTION)},${paramCase(COMP_LAYOUT_ACCORDION)}`,
        droppable: `${paramCase(COMP_MEDIA_IMAGE)}`,
        attributes: {
          autoScroll: FIELD_AUTO_SCROLL.default,
          showDots: FIELD_SHOW_DOTS.default,
          showPrevNext: FIELD_SHOW_PREV_NEXT.default
        },
        traits:[
          FIELD_SHOW_PREV_NEXT,
          FIELD_SHOW_DOTS,
          FIELD_AUTO_SCROLL
        ]
      }
    },
    // View
    view: {
      tagName: compParamName,
      events: {
        drop: 'handleImageDrop'
      },
      // Init
      init() {
        updatePageFormItemPerms.call(this, editor);
        this.listenTo(this.model, 'change:attributes', this.handleAttributeChanges);
      },
      handleImageDrop($event) {
        // Needed to trigger rerender *after* item is dropped
        setTimeout(() => this.render());
      },
      // Attribute changes handler
      handleAttributeChanges() {
        updatePageFormItemPerms.call(this, editor);
        this.render();
        fixToolbarPositinoOnComponentHeightChange(editor);
      },
      // On render
      onRender() {
        const attrs = this.model.getAttributes();
        const cont = document.createElement('div');
        let modelClass = `widget-item ${compParamName}`;

        cont.classList.add('widget-inner');

        if (this.model.components().length === 0) {
          this.el.innerHTML = '<item-message>Drag images onto the slider</item-message>';
        } else {
          if (attrs.showDots || attrs.showPrevNext) {
            const imageSliderNav = document.createElement('image-slider-nav');
            let imageSliderNavItems = '';

            if (attrs.showDots) {
              const dots = document.createElement('dots');

              imageSliderNavItems += '<i class="material-icons">more_horiz</i>';
              modelClass += ' has-dots';
            }
            if (attrs.showPrevNext) {
              const prevNext = document.createElement('prev-next');

              imageSliderNavItems += '<i class="material-icons prev">arrow_back_ios</i><i class="material-icons next">arrow_forward_ios</i>';
              modelClass += ' has-prev-next';
            }

            imageSliderNav.innerHTML = imageSliderNavItems;
            this.el.append(imageSliderNav)
          }
        }

        this.el.append(cont);
        modelSetClass.call(this, modelClass);
      }
    }
  }
}