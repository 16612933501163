import { Directive, ElementRef } from '@angular/core';
import { WindowRefService } from '@services/window-ref.service';
@Directive({
  selector: '[can-drag-drop-onto-this-element]'
})
export class CanDragDropOntoThisElementDirective {
  constructor(
    public element: ElementRef,
    public windowRef: WindowRefService
  ) {
    this.element.nativeElement.addEventListener('dragover', (evt) => {
      this.windowRef.nativeWindow.canDragDropOverElement = true;
    });
    this.element.nativeElement.addEventListener('dragleave', (evt) => {
      this.windowRef.nativeWindow.canDragDropOverElement = false;
    });
  }
}
