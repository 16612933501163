import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from './base.service';
import { Cache } from '@app/cache';

@Injectable({
  providedIn: 'root'
})
export class BundleViewService extends BaseService {

  private BundleViewCache = [];

  constructor(
    public http: HttpClient,
    public cache: Cache
  ) {
    super(http, cache);
    this.setVars({
      endPoint: 'bundle/{id}/bundle-items',
      customPagination: true, // Prevent base.component from initialising pagination
      modelTitle: 'Bundle'
    });
  }
}
