import { Component, Injectable, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EventService } from '@app/services/event.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import * as Moment from 'moment';
@Component({
  selector: 'app-user-event-form',
  templateUrl: './user-event-form.component.html',
  styleUrls: ['./user-event-form-700.component.scss'],
})

@Injectable()
export class UserEventFormComponent extends BaseComponent  {
  // Local variables
  eventFormGroup: FormGroup;
  user_uuid: string;
  attendees: any;
  customFields: any;
  calendarCols: any;
  attendeeCols: any;
  documentCols: any;
  articleCols: any;
  selectedCalendarIds = [];
  selectedAttendeeIds = [];
  selectedDocumentIds = [];
  selectedArticleIds = [];
  onToManyColumnsDef = [];
  dataLoaded = false;
  formSubmitted = false;
  showEventReminderButtons = false;
  public showCustomFieldsTab = true;
  private config = GlobalUtil.Configuration;
  // ViewChild elements
  @ViewChild('calendars', {static: false}) private advancedSelectCalendarsInput: { getSelectedIds: () => any[]; };
  @ViewChild('attendees', {static: false}) private advancedSelectAttendeesInput: { getSelectedIds: () => any[]; };
  @ViewChild('documents', {static: false}) private advancedSelectDocumentsInput: { getSelectedIds: () => any[]; };
  @ViewChild('articles', {static: false}) private advancedSelectArticlesInput: { getSelectedIds: () => any[]; };
  private hour12Timer = true;
  private showTime = true;
  private defaultTimezone: any;
  onLoaded: any;

  constructor(
    public activatedRoute: ActivatedRoute,
    public eventService: EventService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      state: state,
      modelService: eventService
    });

    this.user_uuid = this.routeParams['uuid'];

    this.defaultTimezone = this.state.get('defaultTimezone');

    this.initialFormGroup();

    const attendees = {
      attendees: [
        this.user_uuid,
      ]
    };

    this.populateInitialData(attendees);

    this.dataLoaded = true;

    this.modelServiceGetAll(1, null, 'custom-fields').then(() => {
      this.customFields = this.dataSourceRows;
      this.customFields = this.customFields.filter(r => r.models.indexOf('App\\Event') > -1);
      this.customFields.map(group => group.selected = false );
      this.initialiseCustomFields(this.customFields);
    });

    // Setup the columns settings for the multi-selection table (Calendar)
    this.calendarCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` }
    ];

    // Setup the columns settings for the multi-selection table (Attendee)
    this.attendeeCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` }
    ];

    // Setup the columns settings for the multi-selection table (Document)
    this.documentCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      {
        columnDef: 'thumbnail',
        selectedOnclick: false,
        showThumbnail: true,
        header: '',
        cell: (element: any) => `${element.cdn_uuid}`
      },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` },
      {
        columnDef: 'created_at',
        header: 'Created On',
        selectedOnclick: false,
        dateFormat: this.config.SHORT_DATE_FORMAT,
        cell: (element: any) => `${element.created_at}`
      }
    ];

    // Setup the columns settings for the multi-selection table (Articles)
    this.articleCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'title', selectedOnclick: true, header: 'Title', cell: (element: any) => `${element.title}` },
      {
        columnDef: 'created_at',
        header: 'Created On',
        selectedOnclick: false,
        dateFormat: this.config.SHORT_DATE_FORMAT,
        cell: (element: any) => `${element.created_at}`
      }
    ];
  }

  private addEventReminder() {
    this.controls.event_reminders.push(
      this.formBuilder.group({
        type: [null, [Validators.required]],
        offset_time: [15, [Validators.required]],
        offset_unit: [null, [Validators.required]]
      })
    );
  }

  private removeEventReminder(i) {
    this.controls.event_reminders.removeAt(i);
  }

  get controls(): any {
    return this.eventFormGroup.controls;
  }

  public setScheduleDate(dateValue: string) {
    this.eventFormGroup.patchValue({
      scheduled_notification_date: Moment(dateValue)
    });
  }

  public setScheduleTimezone(timezoneId: string) {
    this.eventFormGroup.patchValue({
      scheduled_notification_timezone_uuid: timezoneId
    });
  }

  public save(): void {
    this.formSubmitted = true;
    this.replaceBooleanFieldsWithByte();
    this.eventFormGroup.patchValue({
      calendars: this.getSelectedCalendars(),
      attendees: this.getSelectedAttendees(),
      documents: this.getSelectedDocuments(),
      articles: this.getSelectedArticles(),
      start_time: this.transformDateTime(this.controls.start_time.value),
      end_time: this.transformDateTime(this.controls.end_time.value)
    });

    this.eventService.create(this.eventFormGroup.value).subscribe(result => this.SavedMessage(result));

    this.replaceByteFieldsWithBoolean();
    setTimeout (() => {
      this.formSubmitted = false;
    }, 3000);
  }

  public setStartDate(dateValue: string) {
    this.eventFormGroup.patchValue({
      start_time: dateValue
    });
  }

  public setStartDateTimezone(timezoneId: string) {
    this.eventFormGroup.patchValue({
      start_time_timezone_uuid: timezoneId
    });
  }

  public setEndDate(dateValue) {
    this.eventFormGroup.patchValue({
      end_time: dateValue
    });
  }

  public setEndDateTimezone(timezoneId: string) {
    this.eventFormGroup.patchValue({
      end_time_timezone_uuid: timezoneId
    });
  }

  public allDayEventSelected() {
  }

  /**
   * CustomField form elements call this function to determine if the field is required
   * @param field
   */
  public getRequiredStatus(field): boolean {
    return field.required === 1 ? true : false;
  }

  // All the private methods (Private method should be after all the public methods)
  private populateInitialData(row): void {
    if (row !== undefined) {
      this.selectedIds(row);
    }
  }

  /**
   * Use this method to validate the result from server after our request.
   * @param data
   */
  private SavedMessage(data) {
    this.state.message({ ['EventIndexUpdate']: true });
    this.closeSlideout();
  }

  /**
   * setup the initial form for event entity
   */
  private initialFormGroup(): void {
    this.eventFormGroup = this.formBuilder.group({
      uuid: null,
      id: null,
      event_category_uuid: [null, [Validators.required]],
      name: [null, [Validators.required]],
      featured_event: [null],
      description: [null],
      show_description_on_screen: [null],
      all_day_event: [null],
      start_time: [this.nowData(), [Validators.required]],
      start_time_timezone_uuid: [this.defaultTimezone.uuid],
      hide_start_time: [null],
      end_time: [this.nowData(), [Validators.required]],
      end_time_timezone_uuid: [this.defaultTimezone.uuid],
      hide_end_time: [null],
      location_uuid: [null],
      postcode: [null],
      calendars: [null],
      attendees: [null],
      documents: [null],
      articles: [null],
      send_as_email: [null],
      send_as_sms: [null],
      send_as_push: [null],
      scheduled_notification_date: [null],
      scheduled_notification_timezone_uuid: [null],
      event_reminders: this.formBuilder.array([]),
    });
    // If we need time then timezone fields should be mandatory.
    if (this.showTime) {
      this.setTimeZoneRequired();
    }
  }

  /**
   * Append the CustomFields to the FormGroup
   */
  private initialiseCustomFields(data): void {
    const fields = {};
    data.forEach(group => {
      group.custom_fields.forEach(field => {
        fields[field.uuid] = new FormControl(field.custom_field_value,
          {
            validators: field.required ? Validators.compose([Validators.required]) : null
          });
      });
    });
    this.eventFormGroup.addControl('custom_fields', new FormGroup(fields));
  }

  private replaceBooleanFieldsWithByte(): void {
    // Replace all the boolean fields with byte
    this.eventFormGroup.patchValue({
      featured_event: this.controls.featured_event.value ? '1' : '0',
      all_day_event: this.controls.all_day_event.value ? '1' : '0',
      show_description_on_screen: this.controls.show_description_on_screen.value ? '1' : '0',
      hide_start_time: this.controls.hide_start_time.value ? '1' : '0',
      hide_end_time: this.controls.hide_end_time.value ? '1' : '0',
      send_as_sms: this.controls.send_as_sms.value ? '1' : '0',
      send_as_email: this.controls.send_as_email.value ? '1' : '0',
      send_as_push: this.controls.send_as_push.value ? '1' : '0',
    });
  }

  private replaceByteFieldsWithBoolean(): void {
    // Replace all the boolean fields with byte
    this.eventFormGroup.patchValue({
      featured_event: this.controls.featured_event.value === '1' ? true : false,
      all_day_event: this.controls.all_day_event.value === '1' ? true : false,
      show_description_on_screen: this.controls.show_description_on_screen.value === '1' ? true : false,
      hide_start_time: this.controls.hide_start_time.value === '1' ? true : false,
      hide_end_time: this.controls.hide_end_time.value === '1' ? true : false,
      send_as_sms: this.controls.send_as_sms.value === '1' ? true : false,
      send_as_email: this.controls.send_as_email.value === '1' ? true : false,
      send_as_push: this.controls.send_as_push.value === '1' ? true : false,
    });
  }

  public closeSlideout() {
    document.getElementById('sidebar').classList.remove('open');
    this.state.update({
      isInNewEventMode: false
    });
    this.state.message({ slideoutHide: true });
  }

  private getSelectedCalendars(): any[] {
    const value: any[] = this.advancedSelectCalendarsInput.getSelectedIds();
    return value === null ? null : value;
  }

  private getSelectedAttendees(): any[] {
    const value: any[] = this.advancedSelectAttendeesInput.getSelectedIds();
    return value === null ? null : value;
  }

  private getSelectedDocuments(): any[] {
    const value: any[] = this.advancedSelectDocumentsInput.getSelectedIds();
    return value === null ? null : value;
  }

  private getSelectedArticles(): any[] {
    const value: any[] = this.advancedSelectArticlesInput.getSelectedIds();
    return value === null ? null : value;
  }

  private selectedIds(row) {
    row.attendees.forEach(el => {
      this.selectedAttendeeIds.push(el);
    });
  }

  private transformDateTime(val: any): any {
    return Moment(val).format(this.config.LONG_DATE_FORMAT_FOR_SAVE_ENTRY);
  }

  private nowData() {
    if (this.hour12Timer) {
      return Moment().format(this.showTime ? this.config.LONG_DATE_FORMAT_DISPLAY_12HOURS :
        this.config.DATE_PICKER_SHORT_DATE_FORMAT_DISPLAY);
    } else {
      return Moment().format(this.showTime ? this.config.LONG_DATE_FORMAT_DISPLAY :
        this.config.DATE_PICKER_SHORT_DATE_FORMAT_DISPLAY);
    }
  }

  private setTimeZoneRequired(): void {
    this.eventFormGroup.get('start_time_timezone_uuid').setValidators([Validators.required]);
    this.eventFormGroup.get('start_time_timezone_uuid').updateValueAndValidity();
    this.eventFormGroup.get('end_time_timezone_uuid').setValidators([Validators.required]);
    this.eventFormGroup.get('end_time_timezone_uuid').updateValueAndValidity();
  }

}
