import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { ContactTypeService } from '@services/contact-type.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
@Component({
  selector: 'app-contact-types-index',
  templateUrl: './contact-types-index.component.html',
  styleUrls: ['./contact-types-index.component.scss']
})
export class ContactTypesIndexComponent extends BaseComponent implements OnInit {
  public node: Breadcrumb;
  public dataSource: any;
  public data: any;
  public displayedColumns = [
    'name',
    'actions'
  ];
  constructor(
    public contactTypeService: ContactTypeService,
    public router: Router,
    public state: State,
  ) {
    super();
    this.setObjects({
      modelService: contactTypeService,
      router: router,
      state: state
    });
    this.setPageVars({
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.nodes = [];
    this.nodes.push(
      {
        label: 'Settings',
        link: 'settings',
      },
      {
        label: 'Contacts',
        link: 'contacts',
      },
      {
        label: 'Contact Types',
        link: 'contact-types',
      }
    );
  }
}
