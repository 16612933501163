import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { ContactGroupUserService } from '@services/contact-group-user.service';

@Component({
  selector: 'app-contact-group-sort-users',
  templateUrl: './contact-group-sort-users.component.html',
})
@Injectable()
export class ContactGroupSortUsersComponent extends BaseComponent implements OnInit {
  public formSubmitted = false;
  public dataLoaded = false;
  public datasource: [];
  constructor(public activatedRoute: ActivatedRoute, public contactGroupUserService: ContactGroupUserService, public state: State) {
    super();
    this.setObjects({
      route: activatedRoute,
      state: state,
      modelService: contactGroupUserService,
    });
  }

  ngOnInit(): void {
    this.modelService.getAllWithId(this.routeParams['id']).subscribe((data) => {
      this.datasource = data.sort((d) => d.display_order);
      this.dataLoaded = true;
    });
  }

  drop($event: CdkDragDrop<string[]>) {
    moveItemInArray(this.datasource, $event.previousIndex, $event.currentIndex);
  }

  save() {
    this.formSubmitted = true;
    const newOrder = this.datasource.map((item: any, index) => {
      var order: any = {};
      order.id = item.contact_group_user_id;
      order.display_order = index + 1;
      return order;
    });
    this.contactGroupUserService.saveOrder({ id: this.routeParams['id'], order: newOrder }).subscribe(() => {
      this.formSubmitted = false;
      this.closeSlideout();
    });
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }
}
