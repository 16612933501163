import { ValidatorFn, AbstractControl } from '@angular/forms';
import { NumberOnlyValidator } from './number-only.validator';

export class FormValidators {
  static numberOnly(): ValidatorFn {
    const number_validator = new NumberOnlyValidator();
    return (control: AbstractControl) =>
      number_validator.validate(control);
  }
}
