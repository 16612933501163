import { Component, Input } from '@angular/core';
import { State } from '@app/state';
@Component({
  selector: 'sidebar-button',
  templateUrl: './sidebar-button.component.html',
  styleUrls: ['./sidebar-button.component.scss']
})
export class SidebarButtonComponent {

  @Input() icon = 'icon';
  private show = false;

  constructor(private state: State) { }

  public showHide() {
    this.show = !this.show;
    const state: any = {};
    if (!!this.show) {
      state.slideoutShow = true;
    } else {
      state.slideoutHide = true;
    }
    this.state.message(state);
  }
}
