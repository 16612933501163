import { MatDialogConfig } from '@angular/material/dialog';
import pluralize from 'pluralize';
import { snakeCase, camelCase, paramCase } from 'change-case';
import { fixInlineAttrs } from './editor/utils';

// Dialogs
import { FileSelectorDialogComponent } from './dialogs/file-selector-dialog.component';
import { IconSelectorDialogComponent } from './dialogs/icon-selector-dialog.component';
import { ImageSelectorDialogComponent } from './dialogs/image-selector-dialog.component';
import { ItemSelectorDialogComponent } from './dialogs/item-selector-dialog.component';
import { InlineUrlDialogComponent } from './dialogs/inline-url-dialog.component';
import { TextEditorDialogComponent } from './dialogs/text-editor-dialog.component';

// Component type consts
import {
  COMP_BUTTON_APP_COMPONENT,
  COMP_BUTTON_APP_PAGE,
  COMP_BUTTON_CHAT,
  COMP_LAYOUT_PAGE_SECTION,
  COMP_LAYOUT_ROW,
  COMP_LAYOUT_SECTION,
  COMP_LOREM_IPSUM_LONG
} from './const/components';

// Form group
import { linkedItemFormGroup } from './const/form-groups';

// Init snapshots
export const initSnapshots = function() {
  this.snapshots.appPages = new Map;
  this.snapshots.articleCategories = new Map;
  this.snapshots.articles = new Map;
  this.snapshots.bundles = new Map;
  this.snapshots.calendars = new Map;
  this.snapshots.contactGroups = new Map;
  this.snapshots.directoryCategories = new Map;
  this.snapshots.directoryItems = new Map;
  this.snapshots.documents = new Map;
  this.snapshots.events = new Map;
  this.snapshots.folders = new Map;
  this.snapshots.periodisationCalendars = new Map;
  this.snapshots.profileGroups = new Map;
  this.snapshots.userCircles = new Map;
}

// Update formgroup items
export const updateFormGroup = function(options): void {
  const { model } = options;
  const itemToUpdate = fixInlineAttrs(model.getAttributes());
  const itemType = model.get('inline')
    ? itemToUpdate.inlineItemType
    : model.get('itemType');

  if (itemType === COMP_BUTTON_APP_PAGE) {
    const updatedItem = linkedItemFormGroup.call(this, itemToUpdate, itemType);

    if (this.controls.buttons.value.filter(item => item.uuid === itemToUpdate.uuid).length > 0) {
      const items = [];

      this.controls.buttons.value.forEach(item => {
        if (item.uuid === itemToUpdate.uuid) {
          items.push(updatedItem);
        } else {
          items.push(item);
        }
      });
      this.controls.buttons = this.formBuilder.array(items);
    } else {
      this.controls.buttons.push(updatedItem);
    }
  } else {
    const field = snakeCase(pluralize.plural(itemType));

    if(this.controls[field].value.find(item => item === itemToUpdate.itemUUID) === undefined) {
      this.controls[field].value.push(itemToUpdate.itemUUID);
    }
  }
}

// Remove from formgroup items
export const removeFromFormGroup = function(options) {
  const { formGroup, uuid } = options;
  const field = snakeCase(pluralize.plural(formGroup));
  let index:any = false;

  if (field === 'app_pages') {
    index = this.controls.buttons.value.findIndex(item => item.uuid === uuid);
    if (index !== -1) {
      this.controls.buttons.removeAt(index);
    }
  } else {
    if (! this.controls[field]) {
      return;
    }
    index = this.controls[field].value.findIndex(item => item === uuid);
    if (index !== -1) {
      delete this.controls.value[index];
    }
  }
}

// Get item snapshot
export const getItemSnapshot = function(options) {
  const { itemType, itemUUID } = options;
  const snapshot = this.snapshots[pluralize.plural(itemType)][itemUUID];

  return snapshot ? snapshot : false;
}

// Update item snapshot
export const updateItemsSnapshot = function(options) {
  const { itemType, itemUUID } = options;
  const snapshot = this.snapshots[pluralize.plural(camelCase(itemType))][itemUUID];

  return snapshot ? snapshot : false;
}

// Open item selector
export const openItemSelector = function(options): void {
  const { itemType } = options;
  const model = this.editor.getSelected();
  const attrs = model.getAttributes();
  const dialogConfig = new MatDialogConfig();

  dialogConfig.data = {
    itemUUID: attrs.itemUUID,
    itemType: itemType
  };
  if (this.pageType === 'appPage') {
    dialogConfig.data.appUUID = this.appUUID;
  }

  this.zone.run(() => {
    const dialogRef = this.dialog.open(ItemSelectorDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async result => {
      if (result !== undefined && !! result.itemUUID) {
        attrs.itemUUID = result.itemUUID;
        if (itemType === COMP_BUTTON_APP_COMPONENT || itemType === COMP_BUTTON_CHAT) {
          model.setAttributes(attrs);
        } else {
          await this.pageService.getSnapshot(attrs.itemUUID, itemType)
            .subscribe(snapshot => {
              this.snapshots[pluralize.plural(itemType)][attrs.itemUUID] = snapshot;
              model.set('snapshot', snapshot);
              model.setAttributes(attrs);
            });
        }
      }
    });
  });
}

// Open text editor modal
export const openTextEditor = function(options): void {
  // const { itemType } = options;
  const model = this.editor.getSelected();
  const attrs = model.getAttributes();
  const dialogConfig = new MatDialogConfig();

  dialogConfig.data = { body: attrs.body, baseUrl: this.editor.baseUrl };
  dialogConfig.data.parent = this;

  this.zone.run(() => {
    const dialogRef = this.dialog.open(TextEditorDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async result => {
      if (result !== undefined && result.body !== undefined) {
        attrs.body = result.body;
        model.setAttributes(attrs);
      }
    });
  });
}

// Open icon selector
export const openIconSelector = function(options): void {
  const model = this.editor.getSelected();
  const attrs = model.getAttributes();
  const dialogConfig = new MatDialogConfig();

  dialogConfig.data = { icon: attrs.icon };

  this.zone.run(() => {
    const dialogRef = this.dialog.open(IconSelectorDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async result => {
      attrs.icon = result.icon;
      model.setAttributes(attrs);
    });
  });
}

// Open image selector
export const openImageSelector = function(options): void {
  const model = this.editor.getSelected();
  const inline = model.get('inline') ? true : false;
  const attrs = model.getAttributes();
  const dialogConfig = new MatDialogConfig();

  dialogConfig.data = { imageUUID: attrs.imageUUID, imageDetails: attrs.imageDetails };

  this.zone.run(() => {
    const dialogRef = this.dialog.open(ImageSelectorDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async result => {
      if (result !== undefined && !! result.imageUUID) {
        attrs.imageUUID = result.imageUUID;
        attrs.imageDetails = result.imageDetails;
        model.setAttributes(attrs);
      }
    });
  });
}

// Open inline image selector
export const inlineImageDialog = function(options): Promise<any> {
  return new Promise(resolve => {
    const { imageFloat, imageUUID } = options;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      showInlineOptions: true,
      imageFloat: imageFloat,
      imageUUID: imageUUID,
      baseUrl: this.editor.baseUrl
    };

    this.zone.run(() => {
      const dialogRef = this.dialog.open(ImageSelectorDialogComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(async result => resolve(result));
    });
  });
}

// Open inline url dialog
export const inlineUrlDialog = function(options): Promise<any> {
  return new Promise(resolve => {
    const {
      displayAs,
      imageUUID,
      itemFloat,
      itemText,
      openInAppBrowser,
      url
    } = options;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      displayAs: displayAs,
      imageUUID: imageUUID,
      itemFloat: itemFloat,
      itemText: itemText,
      openInAppBrowser: openInAppBrowser,
      url: url
    };

    this.zone.run(() => {
      const dialogRef = this.dialog.open(InlineUrlDialogComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(async result => resolve(result));
    });
  });
}

// Open inline item dialog
export const inlineItemDialog = function(options): Promise<any> {
  return new Promise(resolve => {
    const { itemFloat, imageUUID, itemUUID, itemType, itemText, displayAs } = options;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      displayAs: displayAs,
      imageUUID: imageUUID,
      itemFloat: itemFloat,
      itemText: itemText,
      itemType: itemType,
      itemUUID: itemUUID,
      showInlineOptions: true
    };

    this.zone.run(() => {
      const dialogRef = this.dialog.open(ItemSelectorDialogComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(async result => resolve(result));
    });
  });
}

// Open image selector
export const openFileSelector = function(options): void {
  const model = this.editor.getSelected();
  const inline = model.get('inline') ? true : false;
  const attrs = model.getAttributes();
  const dialogConfig = new MatDialogConfig();

  if (inline) {
    dialogConfig.data = { fileUUID: attrs.file_uuid, fileDetails: attrs.file_details };
  } else {
    dialogConfig.data = { fileUUID: attrs.fileUUID, fileDetails: attrs.fileDetails };
  }

  this.zone.run(() => {
    const dialogRef = this.dialog.open(FileSelectorDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async result => {
      if (result !== undefined && !! result.fileUUID) {
        if (inline) {
          attrs.file_uuid = result.fileUUID;
          attrs.file_details = result.fileDetails;
        } else {
          attrs.fileUUID = result.fileUUID;
          attrs.fileDetails = result.fileDetails;
        }
        model.setAttributes(attrs);
      }
    });
  });
}

// After save actions
export const afterSaveActions = function(options): void {
  const { close } = options;

  if (close) {
    this.closePageEditor();
  } else {
    this.pageModel.afterSave();
  }
}

// Save
export const save = function(options): void {
  const { close } = options;
  const uuid = this.controls.uuid.value;

  this.formSubmitted = true;
  const data: any = {
    body: JSON.stringify(this.editor.getComponents()),
    style: JSON.stringify(this.editor.getStyle()),
    perms: JSON.stringify(this.itemPerms)
  };
  this.pageFormGroup.patchValue(data);

  this.pageModel.beforeSave();

  if (uuid) {
    let url = '';
    if (this.pageType === 'article') {
      url = 'article/edit';
    }
    if (this.pageType === 'appPage') {
      url = 'page/edit';
    }
    this.modelService.patchById(uuid, this.pageFormGroup.value, url)
      .subscribe(() => {
        this.afterSaveActions(options);
      });
  } else {
    this.modelService.create(this.pageFormGroup.value)
      .subscribe(uuid => {
        this.controls.uuid.value = uuid;
        this.afterSaveActions(options);
      });
  }
  this.formSubmitted = false;
}

export const updatePageSections = function(options): void {
  const { model } = options;
  const attrs = model.getAttributes();
  const pageSectionId = model.getId();
  const updatedPageSection = { id: pageSectionId, name: attrs.name, selected: false };
  const index = this.pageSections.findIndex(pageSection => pageSection.id === pageSectionId);

  if (index === -1) {
    this.pageSections.push(updatedPageSection);
  } else {
    this.pageSections[index] = updatedPageSection;
  }
}

export const removeFromPageSections = function(options): void {
  const model = this.editor.getSelected();
  const pageSectionId = model.getId();

  if(this.pageSections.length > 1) {
    const index = this.pageSections.findIndex(pageSection => pageSection.id === pageSectionId);
    _selectFirstPageSection.call(this);
    model.remove();
  }
}

// Select page section
const _showPageSection = function(pageSectionId): void {
  const domPageSections = this.editor.DomComponents.getWrapper()
    .find(paramCase(COMP_LAYOUT_PAGE_SECTION));

  this.pageSections.map(pageSection => {
    pageSection.selected = pageSection.id === pageSectionId;
    return pageSection;
  });

  for(const domPageSection of domPageSections) {
    if (domPageSection.getId() === pageSectionId) {
      domPageSection.view.model.addClass('show');
      this.editor.select(domPageSection);
    } else {
      domPageSection.view.model.removeClass('show');
    }
  }
}

export const showPageSection = _showPageSection;

// Select first page section
const _selectFirstPageSection = function(): void {
  // If needed to stop error
  if (this.pageSections.length > 0) {
    const firstPageSectionId = this.pageSections[0].id
    const pageSections = this.editor.DomComponents.getWrapper().find('page-section');

    pageSections.forEach(pageSection => {
      if (pageSection.getId() === firstPageSectionId) {
        pageSection.view.model.addClass('show');
        this.editor.select(pageSection);
      } else {
        pageSection.view.model.removeClass('show');
      }
    });

    _markFirstPageSectionAsSelected.call(this);
  }
}

export const selectFirstPageSection = _selectFirstPageSection;

// Mark first pageSection.selected = true
const _markFirstPageSectionAsSelected = function() {
  this.pageSections.map((pageSection, index) => {
    pageSection.selected = index === 0;
  });
}

export const markFirstPageSectionAsSelected = _markFirstPageSectionAsSelected;

// Current page section
const _currentPageSection = function(): void {
  if (this.pageSections.length > 0) {
    const currentPageSectionId = this.pageSections
      .filter(pageSection => pageSection.selected)[0].id;
    const pageSections = this.editor.DomComponents
      .getWrapper().find(paramCase(COMP_LAYOUT_PAGE_SECTION));

    return pageSections.filter(pageSection => pageSection.getId() === currentPageSectionId)[0];
  }
}

export const currentPageSection = _currentPageSection;

// Add row
export const addRow = function(blockToAdd = null): void {
  const currentSelectedComponent = this.editor.getSelected();
  const currentPageSection = _currentPageSection.call(this);
  const rowType = paramCase(COMP_LAYOUT_ROW);
  let sectionToAppend = null;

  if (
    !! currentSelectedComponent
    &&
    currentSelectedComponent.get('type') === COMP_LAYOUT_SECTION
  ) {
    // Add to current selected section
    sectionToAppend = currentSelectedComponent;
  } else {
    // Add to last section in page section
    const sections = currentPageSection.findType(COMP_LAYOUT_SECTION);
    sectionToAppend = sections[sections.length - 1];
  }

  const newRow = sectionToAppend
    .append(`<${rowType}></${rowType}>`)[0];

  if (blockToAdd) {
    const addedBlock = _addBlockToParentComponent(newRow, blockToAdd);

    this.editor.select(addedBlock);
  } else {
    this.editor.select(newRow);
  }
  this.editor.Canvas.scrollTo(this.editor.getSelected(), { force: true });
}

// Append block to parent block
const _addBlockToParentComponent = (parentComponent, blockToAdd) => {
  const type = paramCase(blockToAdd.attributes.content.type);
  const newBlock = blockToAdd.attributes.content.type === 'text'
    ? `<div data-gjs-type="text" draggable="true" class="text-block item">${COMP_LOREM_IPSUM_LONG}</div>`
    : `<${type}></${type}>`;

  return parentComponent.append(newBlock);
}

// Add section
export const addSection = function(blockToAdd = null, createRow = false): void {
  const currentPageSection = _currentPageSection.call(this);
  const sectionType = paramCase(COMP_LAYOUT_SECTION);
  const rowType = paramCase(COMP_LAYOUT_ROW);
  const newSection = currentPageSection.append(`<${sectionType}></${sectionType}>`)[0];

  if (blockToAdd) {
    const newRow = newSection.find(rowType)[0];
    let addedBlock;

    if (createRow) {
      addedBlock = _addBlockToParentComponent(newRow, blockToAdd);
    } else {
      newRow.remove();
      addedBlock = _addBlockToParentComponent(newSection, blockToAdd);
    }

    this.editor.select(addedBlock);
  } else {
    this.editor.select(newSection);
  }
  this.editor.Canvas.scrollTo(this.editor.getSelected(), { force: true });
}

// Add page section
export const addPageSection = function(): void {
  const pageSection = this.editor.DomComponents.getComponents()
    .add({ type: COMP_LAYOUT_PAGE_SECTION });

  _showPageSection.call(this, pageSection.getId());
}

// App page type change
export const appPageTypeChange = function(): void {
  if (this.controls.app_page_type_slug.value === 'continuous') {
    _selectFirstPageSection.call(this);
  }
}
