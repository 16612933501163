import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormGroup, FormBuilder } from '@angular/forms';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { FormBaseElementComponent } from '@elements/form/form-base-element.component';
import { ApiDataService } from '@services/api-data.service';
import { Subscription } from 'rxjs';
import * as Moment from 'moment';
import * as MomentTimezone from 'moment-timezone';

@Component({
  selector: 'core-form-date-picker',
  templateUrl: './core-form-date-picker.component.html',
  styleUrls: ['./core-form-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreFormDatePickerComponent),
      multi: true
    }
  ]
})

export class CoreFormDatePickerComponent extends FormBaseElementComponent implements OnInit, AfterViewInit {

  constructor(
    public apiDataService: ApiDataService,
    private state: State,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private elRef: ElementRef
  ) {
    super();
  }

  @Input() allowEmpty = true;
  @Input() compareIsAfterStart: boolean = false;
  @Input() compareIsBeforeEnd: boolean = false;
  @Input() compareStartBeforeEndId: string = null;
  @Input() daysFilter = [];
  @Input() hideLabel = false;
  @Input() hour12Timer = true;
  @Input() id = 'start-time';
  @Input() label = '';
  @Input() name = '';
  @Input() pairedEndTime: any = null;
  @Input() pairedEndTimeId: string = null;
  @Input() selectedTimezone ?= '';
  @Input() selectedTimezoneName ?= '';
  @Input() showTime = false;
  @Input() showTimezone = false;
  @Input() startAt: any;
  @Input() updateFromPairedStartTime: boolean = false;
  @Input() pairedAllDayEvent: boolean = false;
  @Input() value: any;

  @Output() dateChanged = new EventEmitter<string>();
  @Output() endBeforeStart = new EventEmitter<boolean>();
  @Output() timezoneChanged = new EventEmitter<string>();

  @ViewChild('timezone', {static: false}) public timezone: any;
  @ViewChild('dt1', {static: false}) public dateTime: any;
  @ViewChild('datefield', {static: false}) public datefield: { nativeElement: { value: string }};

  private compareStartIsBeforeEndSubscription: Subscription;
  private config = GlobalUtil.Configuration;
  private endTimeEl: any;
  private initialStartEndTimeDuration: any = null;
  private pairedEndTimeData: any = null;
  private pairedStartTimeData: any = null;
  private timezones: Array<any> = [];
  private updateEndTimeSubscription: Subscription;
  private updateEndTimezoneSubscription: Subscription;

  public formGroup: FormGroup;
  public showEndIsBeforeStartErrorMessage: boolean = false;
  public onChange: any = () => { };
  public onTouched: any = () => { };

  ngOnInit(): void {
    if (this.value) {
      this.value = this.data();
      // startAt needs to be formatted like so:
      // Fri Mar 15 2019 20:30:00 GMT+0000 (Greenwich Mean Time)
      this.startAt = new Date(this.data());
    }

    if (!this.value && !this.allowEmpty) {
      this.value = this.nowData();
    }

    if (this.selectedTimezone === '') {
      this.selectedTimezone = this.state.get('defaultTimezone').uuid;
      this.selectedTimezoneName = this.state.get('defaultTimezone').name;
    }

    this.formGroup = this.fb.group({ timezoneVal: [this.selectedTimezone] });

    this.formGroup.controls.timezoneVal.valueChanges.subscribe(timezoneValue => {
      this.timezoneChange(timezoneValue);
      // Update related end time timezone
      if (this.pairedEndTime !== null && this.pairedEndTimeId !== null) {
        this.state.message({ updateFromPairedStartTimezone: {
          pairedEndTimeId: this.pairedEndTimeId,
          timezone: timezoneValue
        }});
      }
    });

    // State updates based on being paired with another date / tz input
    this.pairedInputStateSubscriptions();
  }

  ngAfterViewInit(): void {
    this.datefield.nativeElement.value = this.value;
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.updateFromPairedStartTime) {
      this.updateEndTimeSubscription.unsubscribe();
      if (this.showTimezone) {
        this.updateEndTimezoneSubscription.unsubscribe();
      }
    }
  }

  /**
   * Formats data
   */
  data() {
    if (this.hour12Timer) {
      return Moment(this.value).format(this.showTime ? this.config.LONG_DATE_FORMAT_DISPLAY_12HOURS :
        this.config.DATE_PICKER_SHORT_DATE_FORMAT_DISPLAY);
    } else {
      return Moment(this.value).format(this.showTime ? this.config.LONG_DATE_FORMAT_DISPLAY :
        this.config.DATE_PICKER_SHORT_DATE_FORMAT_DISPLAY);
    }
  }

  nowData() {
    if (this.hour12Timer) {
      return Moment().format(this.showTime ? this.config.LONG_DATE_FORMAT_DISPLAY_12HOURS :
        this.config.DATE_PICKER_SHORT_DATE_FORMAT_DISPLAY);
    } else {
      return Moment().format(this.showTime ? this.config.LONG_DATE_FORMAT_DISPLAY :
        this.config.DATE_PICKER_SHORT_DATE_FORMAT_DISPLAY);
    }
  }

  timezoneInput() {
    return this.timezone;
  }

  dateChange($event: { value: Moment.MomentInput; }) {
    if ($event.value === null) {
      this.datefield.nativeElement.value = '';
      this.dateChanged.next(null);
      return;
    }
    if (this.showTime) {
      if (this.hour12Timer) {
        this.value = Moment($event.value).format(this.config.LONG_DATE_FORMAT_DISPLAY_12HOURS);
      } else {
        this.value = Moment($event.value).format(this.config.LONG_DATE_FORMAT_DISPLAY);
      }
    } else {
      this.value = Moment($event.value).format(this.config.DATE_PICKER_SHORT_DATE_FORMAT_DISPLAY);
    }

    if (this.dateChanged.observers.length > 0) {
      let datetimeFormat = this.config.LONG_DATE_FORMAT_DISPLAY;
      if (this.hour12Timer) {
        datetimeFormat = this.config.LONG_DATE_FORMAT_DISPLAY_12HOURS;
      }
      const newDate = Moment(this.value)
        // 2020-08-29 - Paul - This looks like a hack to compensate for BST / GMT, so I've removed it
        // If any date/time pickers behave weirdly it's probably down to this
        // .add(this.config.ADJUSTMENT_HOUR_DIFFERENCE, 'hours')
        .format(this.showTime ? datetimeFormat : this.config.DATE_PICKER_SHORT_DATE_FORMAT_DISPLAY);

      this.startAt = new Date(this.data());
      this.dateChanged.next(newDate);

      this.updateEndTimeWithInitialInterval();
    }

    // set the new value to the input
    this.datefield.nativeElement.value = this.value;
    this.cdr.detectChanges();

    if (this.compareIsBeforeEnd || this.compareIsAfterStart) {
      this.comparePairedStartAndEndTimes();
    }
  }

  setOptionsFromTimezone(timezones: Array<any>): void {
    this.timezones = timezones;
    if (this.selectedTimezoneName === '') {
      this.selectedTimezoneName = timezones.find(timezone => timezone.value === this.selectedTimezone).text;
    }
  }

  pairedInputStateSubscriptions(): void {

    // Get duration of event being edited in minutes
    if (this.pairedEndTime !== null && this.pairedEndTimeId !== null) {
      const initialEndAt:any = new Date(this.pairedEndTime);
      this.initialStartEndTimeDuration = (Math.abs(initialEndAt - this.startAt) / 1000) / 60;
    }

    // updateFromPairedStartTime should be set to true on the end date component in html templates
    if (this.updateFromPairedStartTime) {
      this.updateEndTimeSubscription = this.state.subscription().subscribe((stateData: { updateFromPairedStartTime: any; }) => {
        if (!! stateData.updateFromPairedStartTime) {
          const updateEndTime: any = stateData.updateFromPairedStartTime;
          if (
            this.id === updateEndTime.pairedEndTimeId &&
            updateEndTime.initialStartEndTimeDuration > 0
          ) {
            let datetimeFormat = this.config.LONG_DATE_FORMAT_DISPLAY;
            if (this.hour12Timer) {
              datetimeFormat = this.config.LONG_DATE_FORMAT_DISPLAY_12HOURS;
            }
            this.cdr.detectChanges();

            var newEndTime:any
            // 2020-08-29 - Paul - This looks like a hack to compensate for BST / GMT, so I've removed it
            // If any date/time pickers behave weirdly it's probably down to this
            // .add(this.config.ADJUSTMENT_HOUR_DIFFERENCE, 'hours')
            if(stateData.updateFromPairedStartTime.pairedAllDayEvent) {
              newEndTime = Moment(updateEndTime.startDate)
                .set({ hour: 23, minute: 59, second: 59 })
                .format(this.showTime ? datetimeFormat : this.config.DATE_PICKER_SHORT_DATE_FORMAT_DISPLAY);
            } else {
              newEndTime = Moment(updateEndTime.startDate)
                .add(updateEndTime.initialStartEndTimeDuration, 'minutes')
                .format(this.showTime ? datetimeFormat : this.config.DATE_PICKER_SHORT_DATE_FORMAT_DISPLAY);
            }

            console.log(newEndTime);

            this.dateChanged.next(newEndTime);
            setTimeout(() => {
              this.datefield.nativeElement.value = newEndTime;
              this.value = this.data();
              this.startAt = new Date(this.data());
              this.cdr.detectChanges();
            }, 0)
          }
        }
      });

      if (this.showTimezone) {
        this.updateEndTimezoneSubscription = this.state.subscription().subscribe((stateData: { updateFromPairedStartTimezone: any }) => {
          if (!! stateData.updateFromPairedStartTimezone) {
            setTimeout(() => {
              this.formGroup.setValue({ timezoneVal: stateData.updateFromPairedStartTimezone.timezone });
              this.cdr.detectChanges();
            }, 0);
          }
        });
      }
    }
  }

  comparePairedStartAndEndTimes(): void {
    setTimeout(() => {

      var startTime: any;
      var endTime: any;
      var compareDates: boolean = false;

      if (this.compareIsBeforeEnd) {
        const pairedEndTime: HTMLInputElement = document.getElementById(this.compareStartBeforeEndId) as HTMLInputElement;

        if (pairedEndTime !== undefined && pairedEndTime !== null) {
          startTime = Moment(this.value);
          endTime = Moment(pairedEndTime.value);

          if (this.showTimezone) {
            startTime.tz(this.selectedTimezoneName);
            endTime.tz(pairedEndTime.getAttribute('timezoneName'));
          }

          compareDates = true;
        }
      }

      if (this.compareIsAfterStart) {
        const pairedStartTime: HTMLInputElement = document.getElementById(this.compareStartBeforeEndId) as HTMLInputElement;

        if (pairedStartTime !== undefined && pairedStartTime !== null) {
          startTime = Moment(pairedStartTime.value);
          endTime = Moment(this.value);

          if (this.showTimezone) {
            startTime.tz(pairedStartTime.getAttribute('timezoneName'));
            endTime.tz(this.selectedTimezoneName);
          }

          compareDates = true;
        }
      }

      if (compareDates) {
        this.showEndIsBeforeStartErrorMessage = endTime.isBefore(startTime);
      } else {
        this.showEndIsBeforeStartErrorMessage = false;
      }

      this.endBeforeStart.emit(this.showEndIsBeforeStartErrorMessage);

    }, 0);
  }

  updateEndTimeWithInitialInterval(): void {
    if (this.pairedEndTime !== null && this.pairedEndTimeId !== null) {
      // Setup initial values for later state updates based on paired inputs
      if(this.initialStartEndTimeDuration !== null && this.initialStartEndTimeDuration > 0) {
        this.state.message({ updateFromPairedStartTime: {
          pairedEndTimeId: this.pairedEndTimeId,
          initialStartEndTimeDuration: this.initialStartEndTimeDuration,
          startDate: this.value,
          pairedAllDayEvent: this.pairedAllDayEvent
        }});
      }
    }
  }

  timezoneChange(timezoneId: string): void {
    if (this.timezoneChanged.observers.length > 0) {
      this.timezoneChanged.next(timezoneId);
      if (this.timezones.length > 0) {
        this.selectedTimezoneName = this.timezones.find(timezone => timezone.value === timezoneId).text;
      }
      if (this.compareIsBeforeEnd || this.compareIsAfterStart) {
        this.comparePairedStartAndEndTimes();
      }
    }
  }

  /**
   * Callback function against each day in the Calendar. Used to disable certain days
   *
   * @see https://danielykpan.github.io/date-time-picker/
   * This property accepts a function of <D> => boolean (where <D> is the date type used by the picker).
   * A result of true indicates that the date-time is valid and a result of false indicates that it is not.
   * Again this will also disable the dates on the calendar that are invalid and prevent time being set on the timer.
   * @param d - day of month
   */
  public dateFilter = (d: Date | null): boolean => {
    if (! this.daysFilter.length) {
      return true;
    } else {
      const day = (d || new Date()).getDay();
      const val = this.daysFilter.indexOf(day);
      return val >= 0;
    }
  }
}
