import { paramCase } from 'change-case';

// Utils
import { iconElement } from './editor/utils';

// Commands
import {
    CMD_PANEL_OPEN_BLOCKS,
    CMD_PANEL_OPEN_LAYERS,
    CMD_PANEL_OPEN_TRAITS,
    CMD_PANEL_OPEN_COMPONENT_ITEMS
} from './const/commands';

// Component types
import {
    COMP_LAYOUT_PAGE_SECTION,
    COMP_LAYOUT_ROW,
    COMP_MEDIA_VIDEO
} from './const/components';

// Form const
import { TYPE_APP_PAGE } from './const/form';

// Form actions
import { initPerms } from './page-form-perms-actions';

// Select first page section
const _selectFirstPageSection = function(editor) {
    this.pageSections[0].selected = true;
    const firstPageSectionId = this.pageSections[0].id
    const pageSections = editor.DomComponents.getWrapper().find(paramCase(COMP_LAYOUT_PAGE_SECTION));

    for(const pageSection of pageSections) {
        if (pageSection.getId() === firstPageSectionId) {
            pageSection.view.model.addClass('show');
            editor.select(pageSection);
        }
    }
}
export const selectFirstPageSection = _selectFirstPageSection;

// Select first page section
const _selectFirstRow = function(editor) {
    const firstRow = editor.DomComponents.getWrapper().find(paramCase(COMP_LAYOUT_ROW))[0];

    editor.select(firstRow);
}

// Mark first pageSection.selected = true
const _markFirstPageSectionAsSelected = function() {
  this.pageSections.map((pageSection, index) => {
    pageSection.selected = index === 0;
  });
}
export const markFirstPageSectionAsSelected = _markFirstPageSectionAsSelected;

// Hide toolbar
export const hideToolbar = toolbar => {
    const style = toolbar.style;
    const size = '-1000px';

    style.top = size;
    style.left = size;
    style.display = 'none';
};

// Unhide toolbar
export const unHideToolbar = toolbar => {
    const style = toolbar.style;

    style.display = 'block';
    style.top = '-37px';
};

// If page section selected in layers than make sure its fully visible and other page sections hidden
export const selectPageSectionAfterPageSectionSelection = function(editor) {
    const selectedPageSection = editor.getSelected();
    const pageSections = editor.DomComponents.getWrapper().find(paramCase(COMP_LAYOUT_PAGE_SECTION));

    // Clear show on all page sections
    for(const pageSection of pageSections) {
        pageSection.view.model.removeClass('show');
    }

    // Show selected page section
    selectedPageSection.view.model.addClass('show');

    // Set selected button
    this.pageSections.map(pageSection => {
        pageSection.selected = false;
        if (selectedPageSection.getId() === pageSection.id) {
            pageSection.selected = true;
        }
        return pageSection;
    });
}

// If item selected in layers and in not visible page section then make visible
export const pageSectionVisibleAfterEditorSelection = function(editor) {
    const selected = editor.getSelected();
    const pageSections = editor.DomComponents.getWrapper().find(paramCase(COMP_LAYOUT_PAGE_SECTION));
    const parentPageSection = selected.closestType(COMP_LAYOUT_PAGE_SECTION);

    // // Clear show on all page sections
    for(const pageSection of pageSections) {
        pageSection.view.model.removeClass('show');
    }

    // Show selected page section
    parentPageSection.view.model.addClass('show');

    // Set selected button
    this.pageSections.map(pageSection => {
        pageSection.selected = false;
        if (parentPageSection.getId() === pageSection.id) {
            pageSection.selected = true;
        }
        return pageSection;
    });
}

// Stops a blue outline appearing around text component
export const stopBlueOutlineOnTextElementWhenInlineSelected = (component, editor) => {
    var parent = component.parent();
    if (parent === undefined) {
        parent = component.closest('div.text-block');
    }
    parent.view.render();
}

// Clear page then add page section
export const clearPage = function(editor) {
    const pageSectionTagName = paramCase(COMP_LAYOUT_PAGE_SECTION);

    this.pageSections = [];
    editor.setComponents(`<${pageSectionTagName}></${pageSectionTagName}>`);

    if (this.pageType === TYPE_APP_PAGE) {
        _selectFirstPageSection.call(this, editor);
    } else {
        _markFirstPageSectionAsSelected.call(this);
        _selectFirstRow.call(this, editor);
    }
    initPerms.call(this);
}

// Set title and icon in traits panel
export const setComponentTitleInTraitsPanel = component => {
    setTimeout(() => {
        const icon = !! component.attributes.displayIcon
            ? `<i class="material-icons">${component.attributes.displayIcon}</i> `
            : '';
        const traitsLabel = document.getElementsByClassName('gjs-traits-label')[0];
        const componentItemsLabel = document.getElementsByClassName('gjs-component-items-label')[0];

        if (traitsLabel !== undefined) {
            traitsLabel.innerHTML = `<h2 class="component-title">${icon}${component.attributes.name}</h2>`;
        }
        if (componentItemsLabel !== undefined) {
            componentItemsLabel.innerHTML = `<h2 class="component-title">${icon}${component.attributes.name}</h2>`;
        }
    });
}

// Set component items panel
export const setComponentItemsPanel = editor => {
    const Panels = editor.Panels;
    const componentItemsPanel = document.createElement('div');
    const viewPanels = Panels.getPanel('views-container');

    componentItemsPanel.innerHTML = `
<div class="gjs-component-items-label"></div>
<div id="panel-component-items-inner"></div>
`;
    componentItemsPanel.setAttribute('id', 'panel-component-items');
    componentItemsPanel.style.display = 'none';
    viewPanels.set('appendContent', componentItemsPanel).trigger('change:appendContent');
}

// Toggle component items panel button
export const toggleComponentItemsPanelButton = (editor, show = true) => {
    editor.Panels.removeButton('views', CMD_PANEL_OPEN_COMPONENT_ITEMS);
    if (show) {
        editor.Panels.addButton('views', {
            id: CMD_PANEL_OPEN_COMPONENT_ITEMS,
            label: iconElement('view_stream'),
            visible: false,
            command: CMD_PANEL_OPEN_COMPONENT_ITEMS,
            attributes: { title: 'Show component items' },
            active: false
        });
    }
}

// Remove default traits for text component
export const hideDefaultTraitsForTextComponent = () => {
    const el:any = document.getElementsByClassName('gjs-trt-traits')[0];

    el.style.display = 'none';
    setTimeout(() => {
        el.innerHTML = '';
        el.style.display = 'block';
    }, 0);
}

// Return to previous selection / caret position on return to rte from an inline item
export const restorePreviousRTESelection = function(component, editor) {
    if (!! component.view.rteEnabled && component.view.rteEnabled === true) {
        // if (this.lastSelection !== undefined) {
        //     console.log(this.lastSelection)
        //     this.lastSelection.select();
        // }
    }
}

// Set contenteditable on el
export const contentEditableIsSet = (component, editor) => {
    if (!! component.view.rteEnabled && component.view.rteEnabled === true) {
        const el = component.getEl();
        el.setAttribute('contenteditable', true);
    }
}

// Unset contenteditable on el
export const contentEditableUnset = (component, editor) => {
    const el = component.getEl();
    el.removeAttribute('contenteditable', true);
}

// Stops issue with selected class not always being applied to inline items
export const makeSureSelectedClassIsApplied = (component, editor) => {
    component.view.model.addClass('gjs-selected');
}

// Set active panel
export const setActivePanelOnComponentSelected = function(editor, component) {
    const Panels = editor.Panels;
    const componentItemsPanel = document.getElementById('panel-component-items');
    const viewPanelBlocksButton = Panels.getButton('views', CMD_PANEL_OPEN_BLOCKS);
    const viewPanelComponentItemsButton = Panels.getButton('views', CMD_PANEL_OPEN_COMPONENT_ITEMS);
    const viewPanelLayersButton = Panels.getButton('views', CMD_PANEL_OPEN_LAYERS);
    const viewPanelTraitsButton = Panels.getButton('views', CMD_PANEL_OPEN_TRAITS);

    // Don't switch when the layer manager is on or there is no selected component
    if (! viewPanelLayersButton.get('active') && editor.getSelected()) {
      viewPanelTraitsButton.set('active', true);
    }

    // Don't switch when the component items layer is show
    if (viewPanelComponentItemsButton !== null) {
        if (
            ! viewPanelComponentItemsButton.get('active') &&
            component.get('hasComponentItems')
        ) {
            componentItemsPanel.style.display = 'block';
            viewPanelComponentItemsButton.set('active', true);
        } else {
            viewPanelComponentItemsButton.set('active', false);
        }
    } else {
        componentItemsPanel.style.display = 'none';
    }
}

// Reload canvas
export const rerenderCanvas = function(editor) {
    editor.DomComponents.render();
}

// Hide panels when on panel is selected
export const togglePanels = function(editor, selectedPanel) {
    const Panels = editor.Panels;
    const componentItemsPanel = document.getElementById('panel-component-items');
    const viewPanelBlocksButton = Panels.getButton('views', CMD_PANEL_OPEN_BLOCKS);
    const viewPanelComponentItemsButton = Panels.getButton('views', CMD_PANEL_OPEN_COMPONENT_ITEMS);
    const viewPanelLayersButton = Panels.getButton('views', CMD_PANEL_OPEN_LAYERS);
    const viewPanelTraitsButton = Panels.getButton('views', CMD_PANEL_OPEN_TRAITS);

    if (selectedPanel !== CMD_PANEL_OPEN_TRAITS) {
        viewPanelTraitsButton.set('active', false);
    }
    if (selectedPanel !== CMD_PANEL_OPEN_LAYERS) {
        viewPanelLayersButton.set('active', false);
    }
    if (
        viewPanelComponentItemsButton !== null &&
        selectedPanel !== CMD_PANEL_OPEN_COMPONENT_ITEMS
    ) {
        componentItemsPanel.style.display = 'none';
        viewPanelComponentItemsButton.set('active', false);
    }
    if (selectedPanel !== CMD_PANEL_OPEN_BLOCKS) {
        viewPanelBlocksButton.set('active', false);
    }
}