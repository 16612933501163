import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTable, MatTableDataSource, Sort } from '@angular/material';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { ViewFileDialogComponent } from '@components/view-file-dialog/view-file-dialog.component';
import { forkJoin, of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';

export class BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatTable, {static: false}) matTable: MatTable<any>;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  public filteredResults: Array<any> = [];
  public listColumnTitles: Array<any> = [];
  public routeParams: Array<any> = [];
  public routeData: any;
  public results: Array<any> = [];
  public sidebarSections: Array<object> = [];
  public basePageVars: any = {};
  public modelService: any;
  public modelTitle: string;
  public sidebarService: any;
  public dataSourceRows: any;
  public dataSource = new MatTableDataSource<any>();
  public state: any;
  public router: any;
  public snackbarService: any;
  public dialog: MatDialog;
  public queryStringFilter = '';
  public pageTitle: string;
  public moduleTitle: string;
  public nodes: Breadcrumb[] = [];
  public unsubscribeOnComponentDestroy$: Subject<void> = new Subject();
  public unsubscribeOnComponentDestroyTakeUntil$ = takeUntil(this.unsubscribeOnComponentDestroy$);
  public dataLoaded = false;
  public isPaginatorAlreadySubscribe = false;
  public subscriptions:Array<any> = [];
  public indexUpdateQueryVars:any;

  selection = new SelectionModel<any>(true, []);
  constructor(objects?) { }

  public ngOnInit() {
    // Set paginator and datasource for mat tables
    if (!!this.paginator) {
      this.paginator.hidePageSize = true;
      this.dataSource = new MatTableDataSource();
    }

    // Set endpoint override
    if (!!this.basePageVars) {
      if (!!this.basePageVars.endPoint && !!this.modelService) {
        this.modelService.setEndPoint(this.basePageVars.endPoint);
      }

      // Set model name override
      if (!!this.basePageVars.modelTitle) {
        this.modelTitle = this.basePageVars.modelTitle;
      }

      // Listen for index update
      if (!!this.basePageVars.listenForIndexUpdate) {
        const queryVars:any = !! this.basePageVars.indexUpdateQueryVars
          ? this.basePageVars.indexUpdateQueryVars
          : null;
        this.indexUpdateQueryVars = queryVars;

        this.state
          .subscription()
          .pipe(this.unsubscribeOnComponentDestroyTakeUntil$)
          .subscribe(state => {
            if (!! state[this.modelService.modelTitle.replace(' ', '') + 'IndexUpdate']) {
              this.modelServiceGetAll(null, null, null, this.indexUpdateQueryVars);
            }
            // This hack reloads the ContactGroupView page when a value is updated
            // To be removed when <core-form-badge> is refactored
            if (!! state[this.modelService.modelTitle.replace(' ', '') + 'ViewUpdate']) {
              this.modelServiceGetAllWithId(state.id);
            }
          });
      }
    }
  }

  public ngAfterViewInit() {
    if (!!this.paginator && ! this.modelService.customPagination) {
      this.paginator.page.subscribe(event => {
        this.isPaginatorAlreadySubscribe = true;
        this.modelServiceGetAll(event.pageIndex + 1);
      });
    }
  }

  public setPageVars(vars) {
    this.basePageVars = vars;
  }

  /**
   * Sets objects for the component
   *
   * @param objects
   */
  public setObjects(objects) {
    if (!!objects) {
      if (!!objects.route) {
        objects.route.params.subscribe(params => (this.routeParams = params));
        objects.route.data.subscribe(data => (this.routeData = data));
      }
      if (!!objects.router) {
        this.router = objects.router;
      }
      if (!!objects.state) {
        this.state = objects.state;
      }
      if (!!objects.sidebarService) {
        this.sidebarService = objects.sidebarService;
      }
      if (!!objects.modelService) {
        this.modelService = objects.modelService;
      }
      if (!!objects.snackbarService) {
        this.snackbarService = objects.snackbarService;
      }
      if (!!objects.dialog) {
        this.dialog = objects.dialog;
      }
    }
  }

  /**
   * Returns a single instance of a model as specified by ID
   *
   * @param id - The primary key value of the model
   * @param resultVar - The name of the returned record set
   * @param page
   */
  public modelServiceGetOne(id: any, resultVar: string, page: number = 1) {
    return new Promise((resolve, reject) => {
      if (typeof id !== 'undefined') {
        return this.modelService.getOne(id).subscribe(result => {
          if (result.data !== undefined) {
            this.dataSourceRows = result.data;
          } else {
            this.dataSourceRows = result;
          }
          this.pageLoaded();
          this.setPaginatorWithResult(page, result);
          resolve((this[resultVar] = result));
        });
      } else {
        this.pageLoaded();
        resolve();
      }
    });
  }

  // Use this function to retrieve data for an index page
  // 2020-09-16 - Added `endpoint` parameter to allow endpoint to be manually specified in the format `documents/my-documents`
  public modelServiceGetAll(page: number = 1, sort = null, endpoint = null, extraQueryVars:any = {}) {
    this.dataLoaded = false;
    this.state.message({ pageLoaded: false });
    return new Promise((resolve, reject) => {
      this.modelService.setPage(page);
      if (sort) {
        this.modelService.setSortOrder(sort);
      }
      this.modelService.setFilterVars(this.queryStringFilter);

      // calls base.service.ts
      return this.modelService.getAll(endpoint, extraQueryVars).subscribe(result => {
        this.dataSourceRows = result.data;
        this.pageLoaded();
        this.setPaginatorWithResult(page, result);
        this.dataLoaded = true;
        this.state.message({ ['dataUpdated']: true });
        const elPage = document.getElementsByClassName('page');
        if (elPage !== undefined && elPage.length > 0) {
          elPage[0].scrollTop = 0;
        }
        resolve();
      });
    });
  }

  // This is used to retrieve a list of records belonging to a parent ID
  // For example a list of users belonging to a Contact Group (/contact-groups/view/{uuid})
  public modelServiceGetAllWithId(id, page: number = 1) {
    return new Promise((resolve, reject) => {
      return this.modelService.getAllWithId(id).subscribe(result => {
        this.dataSourceRows = result.data === undefined ? result : result.data;
        this.pageLoaded();
        this.setPaginatorWithResult(page, result);
        resolve();
      });
    });
  }

  public setPaginatorWithResult(page: number, result: any) {
    if (result.data) {
      this.dataSource = result.data;
      this.dataSource.data = result.data;
    } else {
      this.dataSource = result;
      this.dataSource.data =  result;
    }

    setTimeout(() => {
      if (this.paginator !== undefined && result.pagination !== undefined) {
        this.paginator.pageSize = result.pagination.per_page;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator.pageIndex = page - 1;
        this.dataSource.paginator.length = result.pagination.total;
        this.matTable.dataSource = this.dataSource;

        if (!this.isPaginatorAlreadySubscribe ) {
          this.isPaginatorAlreadySubscribe = true;
          this.paginator.page.subscribe(event => {
            this.modelServiceGetAll(event.pageIndex + 1);
          });
        }
      }
    });
  }

  // 2020-08-30 - Paul - Obsolete as far as I can tell
  public modelServiceGet(apiCall, varName) {
    return new Promise((resolve, reject) => {
      return this.modelService.getFromApi(apiCall).subscribe(payload => {
        resolve((this[varName] = payload));
      });
    });
  }

  // Needs expanding to include 'model' as a param
  // Otherwise function falls back the model in the current route
  modelServiceDelete(uuid: string, groupdId?: string) {
    this.confirmDialog().then(confirmed => {
      if (confirmed) {
        this.modelService.delete(uuid, groupdId).subscribe(
          result => {
            this.modelServiceGetAll(this.pageNumberAfterAction());
            this.selection.clear();
            // this.snackbarService.success({ message: `${this.modelService.modelTitle}(s) deleted` });
            this.snackbarService.success({ message: `Deleted successfully` });
          },
          (error: any) => {
            console.log('Error deleting content: ' + JSON.stringify(error));
          }
        );
      }
    });
  }

  // Needs expanding to include 'model' as a param
  // Otherwise function falls back the model in the current route
  modelServiceDuplicate(uuid: string) {
    this.modelService.duplicate(uuid).subscribe((result: any) => {
      this.modelServiceGetAll(this.pageNumberAfterAction(), null, null, this.indexUpdateQueryVars);
      this.selection.clear();
      this.snackbarService.success({ message: `${this.modelService.modelTitle} duplicated` });
    },
      (error: any) => {
        console.log('Error duplicating content: ' + JSON.stringify(error));
      }
    );
  }

  // Returns the current pagination page number
  // Allows correct page to be refreshed after an action (delete, duplicate etc...
  pageNumberAfterAction() {
    return this.paginator.length % this.paginator.pageSize === 1 ? this.paginator.pageIndex : this.paginator.pageIndex + 1;
  }

  pageLoaded() {
    if (!!this.state) {
      this.state.message({ pageLoaded: true });
    }
  }

  public filterResults(term) {
    if (term === '') {
      this.filteredResults = this.results;
      return;
    }
    const filtered = [];
    const searchTerm = term.toLowerCase();

    this.results.forEach((item, index) => {
      const found = false;

      Object.values(item).forEach(value => {
        if (value instanceof String) {
          return;
        }

        const valueAsString = value + '';
        if (valueAsString.toLowerCase().indexOf(searchTerm) > -1) {
          filtered.push(item);
          return;
        }
      });
    });

    this.filteredResults = [];
    setTimeout(() => {
      this.filteredResults = filtered.filter(x => !!x);
    }, 1);
  }

  public modelServiceMapResult(mapKey, data) {
    return this.modelService['map' + mapKey.charAt(0).toUpperCase() + mapKey.slice(1)](data);
  }

  public slideoutShow() {
    this.state.message({ slideoutShow: true });
  }

  public openSlideOutWithLink(model: string, id?: string, data?: any) {
    let url = '';
    if (id) {
      url = `${model}/(slideout:${id})`;
    } else {
      url = `/(slideout:${model})`;
    }
    this.router.navigateByUrl(url, { skipLocationChange: true }).then(() => this.state.message({ slideoutShow: true, data: data }));
  }

  // Replaces `RouterLink`
  public openLinkByUrl(url: string) {
    this.router.navigateByUrl(url);
  }

  public openFullWidthOverlayWithLink(model: string, id?: string) {
    let url = '';
    if (id) {
      url = `${model}/(fullWidthOverlay:${id})`;
    } else {
      url = `/(fullWidthOverlay:${model})`;
    }
    this.router.navigateByUrl(url, { skipLocationChange: true }).then(() => this.state.message({ fullWidthOverlayShow: true }));
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource && this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  // Delete multiple items selected from table checkboxes
  modelServiceSelectedDelete() {
    if (this.selection.selected.length === 0) {
      this.snackbarService.warning({ message: `No ${this.modelService.modelTitle} selected` });
      return;
    }
    this.confirmDialog().then(confirmed => {
      if (confirmed) {
        const snackbarRef = this.snackbarService.openProgress('Deleting...');
        forkJoin(this.selection.selected.map(selected => this.modelService.delete(selected.uuid))).subscribe(
          result => {
            const pageNumberAfterDelete =
              this.paginator.length % this.paginator.pageSize === this.selection.selected.length
                ? this.paginator.pageIndex
                : this.paginator.pageIndex + 1;
            this.modelServiceGetAll(pageNumberAfterDelete);
            this.selection.clear();
            snackbarRef.dismiss();
            // this.snackbarService.success({ message: `${this.modelService.modelTitle}(s) deleted` });
            this.snackbarService.success({ message: `Deleted successfully` });
          },
          (error: any) => {
            console.log('Error deleting multiple content: ' + JSON.stringify(error));
            this.selection.clear();
          }
        );
      } else {
        this.selection.clear();
      }
    });
  }

  onSortData(sort: Sort) {
    if (sort.direction.trim() === '') {
      if (Object.keys(this.dataSource.data[0]).indexOf('display_order') !== -1 && this.dataSource.data[0]['display_order'] !== null) {
        sort = { active: 'display_order', direction: 'asc' };
      }
    }
    this.modelServiceGetAll(this.paginator.pageIndex + 1, sort);
  }

  confirmDialog(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: 'Click \'Delete\' to confirm'
        // data: `Confirm deletion of this ${this.modelService.modelTitle}(s)?`
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  viewFileDialog(id: string): void {
    this.modelService.getFromApi(`document/${id}`).subscribe(result => {
      const dialogRef = this.dialog.open(ViewFileDialogComponent, { data: { document: result }});
    });
  }

  checkFormChanges($event) {
    $event.valueChanges
      .pipe(
        debounceTime(300),
        switchMap(data => {
          this.queryStringFilter = this.createFilterParams(data);
          return of(this.modelServiceGetAll(1, null));
        })
      )
      .subscribe(() => { });
  }

  onFilterLinkClicked(link: any): void {
    this.queryStringFilter = this.createFilterParams(link);
    this.modelServiceGetAll(1, null);
  }

  // Build the sidebar search Filters
  createFilterParams = obj => {
    let filterQueryStr = '';
    let searchTerm = '';
    let searchRelated = '';
    let searchOperator = '';
    const wildcardSearchFields: any = ['title', 'name', 'subject', 'email', 'firstname', 'lastname', 'address', 'internal_title'];
    const relatedModelFields: any = ['article_category_uuid', 'article_tag_uuid', 'calendar_uuid', 'directory_category_uuid'];
    Object.keys(obj).forEach(prop => {
      if (obj[prop] && obj[prop] !== '' && !Array.isArray(obj[prop])) {
        searchTerm = obj[prop];
        // add wildcard modifier to text based search fields and overwrite the searchTerm
        if (wildcardSearchFields.includes(prop)) {
          searchTerm = obj[prop] + '%';
          searchOperator = '&search_operator=like';
        }
        if (relatedModelFields.includes(prop)) {
          switch (prop) {
            case 'article_category_uuid':
              searchRelated = '&search_related=category';
              break;
            case 'article_tag_uuid':
              searchRelated = '&search_related=tag';
              break;
            case 'calendar_uuid':
              searchRelated = '&search_related=calendar';
              break;
            case 'directory_category_uuid':
              searchRelated = '&search_related=directory_category';
              break;
            default:
              break;
          }
        }
        filterQueryStr += `&search_field=${prop}&search_term=${searchTerm}` + searchOperator + searchRelated;
      } else if (Array.isArray(obj[prop])) {
        let selOptions = obj[prop].filter(option => option.selected);
        if (selOptions.length > 0) {
          selOptions = selOptions.map(option => option.id).toString();
          filterQueryStr += `&search_field=${prop}&search_term=${selOptions}`;
        }
      }
    });
    return encodeURI(filterQueryStr);
  }

  ngOnDestroy() {
    this.unsubscribeOnComponentDestroy$.next();
    this.unsubscribeOnComponentDestroy$.complete();

    if (!! this.subscriptions) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }

  get dataCount(): number {
    return this.dataSource.data.length;
  }

  public buildSettingsBreadcrumb(item: Breadcrumb): void {
    this.nodes = [];
    this.nodes = [{
      label: 'Settings',
      link: 'settings',
      title: 'Settings'
    }];
    this.nodes.push({
      label: item.label,
      link: item.link,
      title: item.title
    });
  }

  /**
   * Closes the slideout component and emits a state message { slideoutHide: true }
   *
   * Remove this method from individual components and use this method
   */
  // public closeSlideout() {
  //   this.state.message(
  //     {
  //       slideoutHide: true
  //     }
  //   );
  // }

  // Is the logged-in user the owner of this content
  // Used for disabling buttons etc.
  public isCreatedUser(created_user_uuid) {
    const user = this.state.get('user');
    if (created_user_uuid == user.uuid) {
      return true;
    }
    return false;
  }

}
