import { Component, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FileInfo } from '@app/models/entities/fileInfo';

@Component({
  selector: 'file-selector-dialog',
  templateUrl: 'file-selector-dialog.component.html',
  styleUrls: ['file-selector-dialog.component.scss']
})
export class FileSelectorDialogComponent {
    @ViewChild('uploadComponent', {static: false}) uploadComponent: any;

    private fileDetails: any = null;

    public  fileSelectFormGroup: FormGroup;
    public  fileUUID: any = null;

    constructor(
        private dialogRef: MatDialogRef<FileSelectorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private formBuilder: FormBuilder
    ) {
        this.fileDetails = data.fileDetails;
        this.fileSelectFormGroup = this.formBuilder.group({
            fileUUID: [data.fileUUID]
        });
    }

    setFileDetails($fileInfo: FileInfo) {
        if ($fileInfo !== null && !! $fileInfo.uuid) {
            this.fileUUID = $fileInfo.uuid;
            this.fileDetails = $fileInfo;
        }
    }

    saveAndCloseDialog() {
        this.dialogRef.close({ fileUUID: this.fileUUID, fileDetails: this.fileDetails });
    }
}
