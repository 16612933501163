import { Component, Injectable, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiModel } from '@app/models/entities/api-model';
import { ModelView } from '@app/models/entities/model-view';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { UserService } from '@services/user.service';
import * as Moment from 'moment';
@Component({
  selector: 'user-form-employment-information',
  templateUrl: './user-form-employment-information.component.html',
  styleUrls: [],
  providers: [SidebarService]
})

@Injectable()
export class UserFormEmploymentInformationComponent extends BaseComponent implements OnInit {

  @Input() hideSidebar = false;
  @Input() userEmploymentInformation: ApiModel.User;

  userFormGroup: FormGroup;
  userProfileFormGroup: FormGroup;
  dataLoaded = false;
  loanDirection: ModelView.SelectOption[] = [
    { value: 'from', title: 'Outgoing' },
    { value: 'to', title: 'Incoming' }
  ];

  private config = GlobalUtil.Configuration;

  public startBeforeEnd: boolean = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: userService
    });

    this.setPageVars({
      sidebar: 'User Profile',
    });
    this.initialFormGroup();
  }

  ngOnInit() {
    if (this.userEmploymentInformation) {
      this.userFormGroup.patchValue(this.userEmploymentInformation);
      this.dataLoaded = true;
    }
  }

  public save(): void {
    const uuid = this.userFormGroup.controls.uuid.value;
    this.replaceBooleanFieldsWithByte();
    if (uuid) {
      this.userProfileFormGroup.patchValue({
        contract_start_date: this.changedDateFormat(this.controls.contract_start_date.value,
          this.config.SHORT_DATE_FORMAT_DISPLAY),
        contract_end_date: this.changedDateFormat(this.controls.contract_end_date.value,
          this.config.SHORT_DATE_FORMAT_DISPLAY),
        loan_start_date: this.changedDateFormat(this.controls.loan_start_date.value,
          this.config.SHORT_DATE_FORMAT_DISPLAY),
        loan_end_date: this.changedDateFormat(this.controls.loan_end_date.value,
          this.config.SHORT_DATE_FORMAT_DISPLAY)
      });
      this.userService.patchById(uuid, this.userFormGroup.value)
        .subscribe(result => {
        });
    }
    this.replaceByteFieldsWithBoolean();
  }

  public cancel(): void {
    this.userFormGroup.reset();
  }

  public setStartDate(dateValue: string) {
    this.userProfileFormGroup.patchValue({
      contract_start_date: dateValue
    });
  }

  public setEndDate(dateValue: string) {
    this.userProfileFormGroup.patchValue({
      contract_end_date: dateValue
    });
  }

  public setLoanStartDate(dateValue: string) {
    this.userProfileFormGroup.patchValue({
      loan_start_date: dateValue
    });
  }

  public setLoanEndDate(dateValue: string) {
    this.userProfileFormGroup.patchValue({
      loan_end_date: dateValue
    });
  }

  public get controls(): any {
    return this.userProfileFormGroup.controls;
  }

  private replaceBooleanFieldsWithByte(): void {
    this.userFormGroup.patchValue({
      'is_player': this.controls.is_player.value ? '1' : '0'
    });
  }

  private replaceByteFieldsWithBoolean(): void {
    this.userFormGroup.patchValue({
      'is_player': this.controls.is_player.value === '1' ? true : false
    });
  }

  private initialFormGroup(): void {
    this.userProfileFormGroup = this.formBuilder.group({
      id: [null],
      job_title: [null],
      contract_type_uuid: [null],
      contract_start_date: [null],
      contract_end_date: [null],
      is_player: [null],
      user_position_uuid: [null],
      squad_number: [null],
      club_uuid: [null],
      previous_club_uuid: [null],
      loan_direction: [null],
      loan_club_uuid: [null],
      loan_start_date: [null],
      loan_end_date: [null],
      biography: [null],
      role_responsibility: [null],
      okr: [null],
    });

    this.userFormGroup = this.formBuilder.group({
      uuid: [null],
      profile: this.userProfileFormGroup
    });
  }

  private changedDateFormat(date, format) {
    if (date !== undefined && date !== null) {
      return Moment(date).format(format);
    } else {
      return null;
    }
  }
}
