import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AccessCodeService } from '@app/services/access-code.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { Environment } from '@environments/environment';

@Component({
  selector: 'app-access-code-form',
  templateUrl: './access-code-form.component.html',
  styleUrls: null,
  providers: [SidebarService]
})
export class AccessCodeFormComponent extends BaseComponent {

  accessCodeFormGroup: FormGroup;
  accessCodeProductBase = '';
  spIdURL = '';
  dataLoaded = false;
  formSubmitted = false;
  globalUtil = GlobalUtil;
  ids = [];

  public subscriptions:Array<any> = [];

  constructor(
    public activatedRoute: ActivatedRoute,
    public accessCodeService: AccessCodeService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    const settings = this.state.get('ccSettings');
    this.accessCodeProductBase = settings.spid.accesscodeproductbase;
    this.spIdURL = Environment.spIdURL;
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: accessCodeService
    });

    this.setPageVars({
      sidebar: ''
    });

    this.initialFormGroupSetup();

    const id = this.routeParams['id'];

    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.subscriptions.push(
        this.modelService.getOne(id).subscribe((result: any) => {
          this.dataLoaded = true;
          this.accessCodeFormGroup.patchValue(result);
          this.controls.group_uuid.setValue(result.group.uuid);
          this.controls.role_id.setValue(result.role.id);
        })
      )
    }
  }

  // All the public methods
  get controls(): any {
    return this.accessCodeFormGroup.controls;
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  public save(): void {
    const id = this.controls.id.value;
    const uuid = this.controls.uuid.value;
    this.formSubmitted = true;
    this.replaceBooleanFieldsWithByte();

    if (id || uuid) {
      this.accessCodeService.patchById(uuid ? uuid : id, this.accessCodeFormGroup.value)
        .subscribe(result => {
          this.SavedMessage(result);
        });
    } else {
      this.accessCodeService.create(this.accessCodeFormGroup.value).subscribe(result => this.SavedMessage(result));
    }

    // Rollback the changes so all the checkboxes will bound correctly
    this.replaceByteFieldsWithBoolean();

    setTimeout (() => {
      this.formSubmitted = false;
    }, 1000);

  }

  // All the private methods
  private initialFormGroupSetup(): void {
    this.accessCodeFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      title: [null, [Validators.required, Validators.maxLength(this.globalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      code: [null, [Validators.required, Validators.maxLength(this.globalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      message: [null, [Validators.maxLength(this.globalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      group_uuid: [null, [Validators.required]],
      role_id: [null, [Validators.required]],
      active: [null],
    });
  }

  private replaceBooleanFieldsWithByte(): void {
    this.accessCodeFormGroup.patchValue({
      active: this.controls.active.value ? '1' : '0',
    });
  }

  private replaceByteFieldsWithBoolean(): void {
    this.accessCodeFormGroup.patchValue({
      active: this.controls.active.value === '1' ? true : false,
    });
  }

  private SavedMessage(data: Object) {
    this.state.message({ ['AccessCodeIndexUpdate']: true });
    this.closeSlideout();
  }

}
