import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FilterLink } from '@app/models/view-models/filter-link';
import { ApiModel } from '@app/models/entities/api-model';
@Component({
  selector: 'sidebar-filter-link',
  templateUrl: './sidebar-filter-link.component.html',
  styleUrls: ['./sidebar-filter-link.component.scss']
})
export class SidebarFilterLinkComponent implements OnInit {
  @Input() links: FilterLink[] = [];
  @Output() onClicked: EventEmitter<any> = new EventEmitter();

  constructor(public router: Router) { }

  ngOnInit() {
  }

  public onItemClicked(link: FilterLink) {
    if (this.onClicked.observers.length > 0) {
      this.onClicked.emit(link);
    }
  }
}
