import { Component, Injectable, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { UserProfile } from '@app/models/view-models/user-profile';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';

@Component({
  selector: 'user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss']
})

@Injectable()
export class UserProfileFormComponent extends BaseComponent implements OnInit {

  public avatar = '';
  public avatarUUID = '';
  public editing = false;
  public user: any;
  public userDetails: Array<Object> = [];
  public userResult: UserProfile;
  public uuid = '';

  userFormGroup: FormGroup;
  userProfileFormGroup: FormGroup;
  formSubmitted = false;
  dataLoaded = false;

  private authUser: any;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public activatedRoute: ActivatedRoute,
    public state: State,
    public userService: UserService
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      state: state,
      modelService: userService
    });
    this.initialFormGroupSetup();
    this.modelServiceGetOne(this.routeParams['uuid'], 'userResult')
      .then(() => {
        this.user = this.modelServiceMapResult('user', this.userResult);
        this.userFormGroup.patchValue(this.userResult);
        this.userFormGroup.patchValue({
          'group_uuid': this.userResult.group.uuid,
          'role_id': this.userResult.roles[0].id,
          'mobile': this.userResult.mobile
        });
        this.userProfileFormGroup.patchValue({
          'telephone': this.userResult.profile.telephone,
          'job_title': this.userResult.profile.job_title
        });
        this.dataLoaded = true;
      });
  }

  ngOnInit(): void {
    this.editing = !this.editing;
    this.authUser = this.state.get('user');
    this.state.subscription().subscribe(state => {
      if (!!state['updateUserResult']) {
        this.user = this.modelServiceMapResult('user', state['updateUserResult']);
      }
    });
  }

  get controls(): any {
    return this.userFormGroup.controls;
  }

  public save(): void {
    const uuid = this.controls.uuid.value;
    this.formSubmitted = true;
    if (uuid) {
      this.userService.patchById(uuid, this.userFormGroup.value)
        .subscribe(result => {
          if (this.authUser.uuid === uuid) {
            this.authService.updateUserSession();
          }
          // this.SavedMessage(result);
        });
    }
    setTimeout (() => {
      this.formSubmitted = false;
    }, 1000);

  }

  public upload(src: string): void {
    this.uuid = this.controls.uuid.value;
    this.userService.patchById(this.uuid, {
        'uuid': this.uuid,
        'profile': { 'avatar': src }
      }).subscribe(result => {
        if (this.authUser.uuid === this.uuid) {
          this.authService.updateUserSession();
        }
      });
  }

  private initialFormGroupSetup(): void {
    this.userProfileFormGroup = this.formBuilder.group({
      telephone: null,
      job_title: null,
    });
    this.userFormGroup = this.formBuilder.group({
      uuid: [null],
      firstname: [null, [Validators.required]],
      lastname: [null, [Validators.required]],
      mobile: [null],
      email: [null, [
        Validators.required,
        Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH),
        Validators.pattern(GlobalUtil.Validation.EMAIL_PATTERN)
        ]
      ],
      group_uuid: [null, [Validators.required]],
      role_id: [null, [Validators.required]],
      profile: this.userProfileFormGroup
    });
  }
}
