import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@environments/environment';
import { State } from '@app/state';
import * as CryptoJS from 'crypto-js';


@Injectable({
    providedIn: 'root'
})
export class UploadService {

    private httpOptions: any;
    private fileGroup: any;
    private ucareURL = 'https://ucarecdn.com/';
    private uploadCareApi = 'https://api.uploadcare.com/';

    public baseUrl: any;
    public publicKey: any;
    public secureExpire: any;
    public secretKey: any;
    public secureSignature: any;
    public secure: boolean = false;

    constructor(public http: HttpClient, public state: State) {
        const settings = this.state.get('ccSettings');
        this.publicKey = settings.uploadcare.public_key;
        this.secretKey = settings.uploadcare.secret_key;
        this.secure = settings.uploadcare.secure;
        this.baseUrl = this.secure ? Environment.filesURL : this.ucareURL;
        this.setSecureVars();
        this.httpOptions = {
            headers: new HttpHeaders({ 'Authorization': `Uploadcare.Simple ${this.publicKey}:${this.secretKey}` })
        };
    }

    public async getFileGroup(groupId) {
        return await this.http.get<object[]>(`${this.uploadCareApi}groups/${groupId}/`, this.httpOptions).toPromise();
    }

    public getSignature(): string {
        return this.generateSignature(this.secureExpire);
    }

    public generateSignature(message): string {
        var hash = CryptoJS.HmacSHA256(message, this.secretKey);
        return hash.toString(CryptoJS.enc.Hex);
    }

    public setSecureVars(): void {
        this.secureExpire = (Math.round(Date.now() / 1000) + 600).toString();
        this.secureSignature = this.getSignature();
    }

    public getUcareSecureUrl(id, urlToAppend = '') {
        const baseUrl:string = this.secure ? Environment.filesURL : this.ucareURL;
        var url = `${baseUrl}${id}/url`;
        if(!! urlToAppend)
        {
            url = url + urlToAppend;
        }
        if(url.substr(-1) === '/') {
            url = url.substr(0, url.length - 1);
        }
        return this.http.get<object>(url, this.httpOptions);
    }
}
