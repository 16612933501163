import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FileInfo } from '@app/models/entities/fileInfo';
import { BundleItemService } from '@app/services/bundle-item.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
@Component({
  selector: 'app-bundle-item-create',
  templateUrl: './bundle-item-create.component.html',
})

export class BundleItemCreateComponent extends BaseComponent {
  bundleItemFormGroup: FormGroup;
  dataLoaded = false;
  formSubmitted = false;
  bundleItemType = '';
  private config = GlobalUtil.Configuration;
  constructor(
    public activatedRoute: ActivatedRoute,
    public bundleItemService: BundleItemService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      state: state,
      modelService: bundleItemService
    });

    this.initialFormGroup();
    this.onChanges();

    const bundleID = this.routeParams['bundleID'];

    if (bundleID === undefined) {
      this.dataLoaded = true;
    } else {
      this.dataLoaded = true;
      this.bundleItemFormGroup.patchValue({
        'bundle_uuid': bundleID
      });
    }
  }

  // Listens for FileInfo from the upload component
  public getUploadedFileInfo($fileInfo: FileInfo) {
    if ($fileInfo) {
      this.bundleItemFormGroup.patchValue({
        'featured_image_cdn_uuid': $fileInfo.uuid
      });
    } else {
      this.bundleItemFormGroup.patchValue({
        'featured_image_cdn_uuid': null
      });
    }
  }

  get controls(): any {
    return this.bundleItemFormGroup.controls;
  }

  public save(): void {
    this.formSubmitted = true;
    this.bundleItemFormGroup.patchValue({
      published: !!this.controls.published.value,
      open_in_app_browser: !!this.controls.open_in_app_browser.value,
    });
    this.bundleItemService.create(this.bundleItemFormGroup.value).subscribe(result => this.savedMessage(result));
    setTimeout (() => {
      this.formSubmitted = false;
    }, 1000);
  }

  // Update some values when bundle_item_type is updated - prevents multiple values being sent to the API
  private onChanges(): void {
    this.bundleItemFormGroup.get('bundle_item_type').valueChanges.subscribe(val => {
      this.bundleItemFormGroup.patchValue({
        'article_uuid': null,
        'article_category_uuid': null,
        'page_uuid': null,
        'sub_bundle_uuid': null,
        'directory_item_uuid': null,
        'directory_category_uuid': null,
      });
    });
  }

  private savedMessage(data): void {
    this.closeSlideout();
    this.state.message({
      ['BundleViewUpdate']: true,
      ['id']: this.controls.bundle_uuid.value
    });
  }

  public closeSlideout(): void {
    this.state.message({ slideoutHide: true });
  }

  private initialFormGroup(): void {
    this.bundleItemFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      title: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      bundle_uuid: [null, [Validators.required]],
      teaser: [null],
      description: [null],
      featured_image_cdn_uuid: [null],
      published: [true],
      open_in_app_browser: [true],
      bundle_item_type: [null, [Validators.required]],
      article_uuid: [null],
      article_category_uuid: [null],
      sub_bundle_uuid: [null],
      page_uuid: [null],
      directory_item_uuid: [null],
      directory_category_uuid: [null],
      url: [null],
    });
  }

}
