import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { UserPositionService } from '@services/user-position.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
@Component({
  selector: 'app-user-positions',
  templateUrl: './user-positions-index.component.html',
  styleUrls: ['./user-positions-index.component.scss']
})
export class UserPositionsIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['name', 'user_count', 'actions'];
  public dataLoaded = false;
  public node: Breadcrumb;
  constructor(
    public router: Router,
    public state: State,
    public userPositionService: UserPositionService,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: userPositionService,
      router,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.node = {
      label: 'User Positions',
      link: 'user-positions',
      title: 'User Positions'
    };
    this.buildSettingsBreadcrumb(this.node);
  }
}
