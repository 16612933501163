import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { EventService } from '@services/event.service';
import { ScheduleService } from '@services/schedule.service';
import { TimezoneService } from '@services/timezone.service';
import * as Moment from 'moment-timezone';

declare global {
  interface Date {
    firstDayOfWeek();
    lastDayOfWeek();
    firstDayOfMonth();
    lastDayOfMonth();
    firstDayOfYear();
    lastDayOfYear();
  }
}

Date.prototype.firstDayOfWeek = function () {
  if (this.getDay() === 0) {
    return new Date(this.setDate(this.getDate() - 6));
  } else {
    return new Date(this.setDate(this.getDate() - this.getDay() + 1));
  }
};

Date.prototype.lastDayOfWeek = function () {
  return new Date(this.setDate(this.getDate() - this.getDay() + 7));
};

Date.prototype.firstDayOfMonth = function () {
  return new Date(this.setDate(1));
};

Date.prototype.lastDayOfMonth = function () {
  return new Date(this.getFullYear(), this.getMonth() + 1, 0);
};

Date.prototype.firstDayOfYear = function () {
  return new Date(this.getFullYear(), 0, 1);
};

Date.prototype.lastDayOfYear = function () {
  return new Date(this.getFullYear(), 11, 31);
};

@Component({
  selector: 'app-user-schedule',
  templateUrl: './user-schedule.component.html',
  styleUrls: [
    '../../schedule/schedule.component.scss',
    '../../schedule/schedule.1024.component.scss',
    '../../schedule/schedule-700.component.scss'
  ],
})
export class UserScheduleComponent extends BaseComponent implements OnInit {
  @Input() uuid: string;

  private day: any = {};
  private defaultTimezone: any;
  private month: any = {};
  private selectedDate: Date = null;
  private timezoneToViewName: any = null;
  private timezoneToViewUUID: any = null;
  private today: Date = null;
  private week: any = {};
  private year: any = {};

  public calendarPlugins = [timeGridPlugin, dayGridPlugin, interactionPlugin];
  public currentScheduleView = 'Week';
  public events: Array<Object> = [];
  public filterScheduleCalendarValues: Array<Object> = [];
  public filterScheduleCategoryValues: Array<Object> = [];
  public filterScheduleTimezoneValue: Array<Object> = [];
  public isInNewEventMode = false;
  public isTodayActive = false;
  public leadingMonthNumber: number;
  public loading = true;
  public scheduleViews: Array<string> = ['Day', 'Week', 'Month'];
  public selectedDateView: string = null;
  public showFilter = false;
  public timezones: any = [];

  @ViewChild('fullCalendar', {static: false}) fullCalendar: FullCalendarComponent;
  @ViewChild('schedule', {static: false}) scheduleCont: any;

  constructor(
    public pageTitleService: Title,
    public scheduleService: ScheduleService,
    public eventService: EventService,
    public state: State,
    public router: Router,
    public timezoneService: TimezoneService,
  ) {
    super();
    this.setObjects({
      router: router,
      state: state,
    });

    this.defaultTimezone = this.state.get('defaultTimezone');
  }

  async initSchedule() {
    this.getTimezones().then(async () => {
      this.setTimezone(this.defaultTimezone.uuid, false);
      await this.getEvents();
    });
    this.setToday();
    this.setScheduleVariables();
    this.setCurrentDateView();
  }

  setToday() {
    this.today = new Date();
    this.selectedDate = new Date(this.today);
  }

  getTimezones() {
    return new Promise((resolve:any) => {
      this.timezoneService.getTimezonesList().subscribe(timezones => {
        this.timezones = timezones;
        resolve();
      });
    });
  }

  async refreshSchedule() {
    this.loading = true;
    this.setScheduleVariables();
    this.setCurrentDateView();
    this.setIsToday();
    await this.getEvents();
    this.filterEvents();
  }

  setIsToday() {
    this.isTodayActive = this.currentScheduleView === 'Day' && this.dateString(this.selectedDate) === this.dateString(this.today);
  }

  dateString(date) {
    return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
  }

  filterEvents() {
    const calendarApi = this.fullCalendar.getApi();
    if (!!calendarApi) {
      calendarApi.rerenderEvents();
    }
  }

  setCurrentScheduleViewToday() {
    this.isTodayActive = true;
    this.setToday();
    this.gotoDate();
    this.setCurrentScheduleView('Day');
  }

  clearEvents() {
    this.events = [];
  }

  ngOnInit() {
    this.initSchedule();
  }

  // Placeholder click function for Event links
  createEvent(header, event) {
    return;
  }

  /**
   * What happens when the Event is clicked on the calendar view
   *
   * @param info
   */
  eventClick(info) {

    // Slideout doesn't currently work with this view, so just return here for now
    return;

    // This link will open the slideout over the calendar, but it's borked
    // const url = `/users/view/${this.uuid}/(slideout:event-form/${info.event.id})`;
    // this.router.navigateByUrl(url, { skipLocationChange: true }).then(() => this.state.message({ slideoutShow: true }));

    // Original calendar link slideout
    // this.openSlideOutWithLink('schedule', 'edit-event/' + info.event.id);
  }

  /**
   * What happens when the date is clicked on the calendar view
   *
   * @param info
   */
  dateClick(info) {
    if (this.currentScheduleView !== 'Day') {
      this.selectedDate = new Date(info.dateStr);
      this.gotoDate();
      this.setCurrentScheduleView('Day');
    }
  }

  setTimezone(timezoneUUID: string, refreshSchedule: boolean = true) {
    const calendarApi = this.fullCalendar.getApi();
    this.timezoneToViewUUID = timezoneUUID;
    this.timezoneToViewName = this.timezones.find(timezone => timezone.uuid === timezoneUUID).name;
    if (refreshSchedule) {
      this.refreshSchedule();
    }
  }

  gotoDate() {
    const calendarApi = this.fullCalendar.getApi();
    calendarApi.gotoDate(this.selectedDate);
  }

  /**
   * Render the event on the Calendar
   * @param info
   */
  eventRender(info) {
    if (
      this.filterScheduleCategoryValues.indexOf(info.event.extendedProps.event_category_uuid) === -1 ||
      this.filterScheduleCalendarValues.filter(x => info.event.extendedProps.calendars_uuids.includes(x)).length === 0
    ) {
      // Don't hide any Events
      // info.el.classList.add('hidden-event');
    }

    if (info.event.start < this.day.firstDate && info.event.end < this.day.lastDate) {
      info.el.classList.add('event-starts-before-view-date');
    }

    if (info.event.start > this.day.firstDate && info.event.end > this.day.lastDate) {
      info.el.classList.add('event-ends-after-view-date');
    }

    info.el.style.color = info.event.backgroundColor;

    const divInner = document.createElement('div');
    divInner.classList.add('fc-inner');
    divInner.style.background = info.event.backgroundColor;
    info.el.prepend(divInner);

    const divInnerWhite = document.createElement('div');
    divInnerWhite.classList.add('fc-inner-white');

    if (info.isStart && (this.currentScheduleView === 'Month' || this.currentScheduleView === 'Week')) {
      divInnerWhite.style.borderLeftColor = info.event.backgroundColor;
      divInnerWhite.style.borderLeftWidth = '3px';
      divInnerWhite.style.borderLeftStyle = 'solid';
    }

    if (this.currentScheduleView === 'Day') {
      divInnerWhite.style.borderTopColor = info.isStart ? info.event.backgroundColor : '#fff';
      divInnerWhite.style.borderTopStyle = info.isStart ? 'solid' : 'dashed';
      divInnerWhite.style.borderTopWidth = '3px';
    }
    info.el.prepend(divInnerWhite);
  }

  setCurrentScheduleView(scheduleView: string) {
    this.currentScheduleView = scheduleView;
    this.clearEvents();
    let view = '';
    if (scheduleView === 'Month') {
      view = 'dayGridMonth';
    }
    if (scheduleView === 'Week') {
      view = 'dayGridWeek';
    }
    if (scheduleView === 'Day') {
      view = 'timeGrid';
    }
    const calendarApi = this.fullCalendar.getApi();
    calendarApi.changeView(view);
    this.refreshSchedule();
  }

  setActiveScheduleViewClass(scheduleView: string) {
    return this.currentScheduleView === scheduleView ? 'active' : '';
  }

  setScheduleVariables(): void {
    const date: Date = new Date(this.selectedDate);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    // Set day dates
    this.day.firstDate = new Date(date);
    this.day.lastDate = new Date(date);
    this.day.lastDate.setHours(23);
    this.day.lastDate.setMinutes(59);
    this.day.lastDate.setSeconds(59);
    // Set month dates
    const monthDateClone = new Date(date);
    this.month.firstDate = monthDateClone.firstDayOfMonth();
    this.month.lastDate = monthDateClone.lastDayOfMonth();
    this.month.leadingMonthNumber = monthDateClone.getMonth() - 1;
    // Set week dates
    const weekDateClone = new Date(date);
    this.week.firstDate = weekDateClone.firstDayOfWeek();
    this.week.lastDate = weekDateClone.lastDayOfWeek();
    // Set year dates
    const yearDateClone = new Date(date);
    this.year.firstDate = yearDateClone.firstDayOfYear();
    this.year.lastDate = yearDateClone.lastDayOfYear();
  }

  /**
   * Returns events from the API
   */
  getEvents() {
    return new Promise((resolve:any) => {
      this.clearEvents();
      this.loading = true;
      let startDate: Date;
      let endDate: Date;

      if (this.currentScheduleView === 'Month') {
        // Extend date range to account for leading and trailing days on month view
        startDate = new Date(this.month.firstDate);
        startDate.setDate(0 - this.getLeadingDays().length);
        endDate = new Date(this.month.lastDate);
        endDate.setDate(0 + this.getTrailingDays().length);
        endDate.setMonth(endDate.getMonth() + 1);
      } else {
        startDate = new Date(this[this.currentScheduleView.toLowerCase()].firstDate);
        endDate = new Date(this[this.currentScheduleView.toLowerCase()].lastDate);
      }

      if (this.uuid) {
        this.scheduleService
          .getUserScheduleEvents(this.uuid, startDate, endDate, null)
          .subscribe(eventsResult => this.afterResultReceived(eventsResult));
      }
      resolve();
    });
  }

  /**
   * Called after receiving fresh Events from the API
   *
   * @param events
   */
  afterResultReceived(events: Object[]) {
    this.setFullCalendarEvents(events);
  }

  setFullCalendarEvents(events) {
    this.loading = false;
    this.overlayFullCalendarEvents(events);
  }

  getFullDate(type, number) {
    let year = this.selectedDate.getFullYear();
    let month = 0;

    if (type === 'leading') {
      month = this.selectedDate.getMonth();
      if (month === 12) {
        year--;
      }
    }

    if (type === 'current') {
      month = this.selectedDate.getMonth() + 1;
    }

    if (type === 'trailing') {
      month = this.leadingMonthNumber + 2;
      if (month === 1) {
        year++;
      }
    }

    const displayMonth = ('00' + month).slice(-2);
    const displayNumber = ('00' + number).slice(-2);

    return `${year}-${displayMonth}-${displayNumber}`;
  }

  setCurrentDateView() {
    switch (this.currentScheduleView) {
      case 'Day':
        this.selectedDateView =
          '<small>' +
          formatDate(this.selectedDate, 'EEEE', 'en-GB') +
          '<br>' +
          formatDate(this.selectedDate, 'd MMM yyyy', 'en-GB') +
          '</small>';
        break;
      case 'Week':
        this.selectedDateView =
          '<small>' +
          formatDate(this.week.firstDate, 'd MMM yyyy', 'en-GB') +
          '<br>' +
          formatDate(this.week.lastDate, 'd MMM yyyy', 'en-GB') +
          '</small>';
        break;
      case 'Month':
        this.selectedDateView = formatDate(this.selectedDate, 'MMMM yyyy', 'en-GB');
        break;
      case 'Year':
        this.selectedDateView = formatDate(this.selectedDate, 'yyyy', 'en-GB');
        break;
    }
    this.scheduleCont.nativeElement.scrollTop = 0;
  }

  getLeadingDays() {
    const leadingDays = [];
    const numberOfLeadingDays = this.month.firstDate.getDay() - 1;
    const lastDayOfLastMonth = new Date(this.selectedDate);
    lastDayOfLastMonth.setDate(0);
    const lastDateOfLastMonthAsNumber: number = lastDayOfLastMonth.getDate();
    for (let offset = 0; offset < numberOfLeadingDays; offset++) {
      leadingDays.push(lastDateOfLastMonthAsNumber - offset);
    }
    return leadingDays.reverse();
  }

  getTrailingDays() {
    const numberOfRows = 6;
    const numberOfDaysToFill = 7 * numberOfRows;
    const numberOftrailingDays = numberOfDaysToFill - (this.getDaysForMonthScheduleView().length + this.getLeadingDays().length);
    const trailingDays = [];
    for (let offset = 0; offset < numberOftrailingDays; offset++) {
      trailingDays.push(offset + 1);
    }
    return trailingDays;
  }

  getDaysForMonthScheduleView() {
    const lastDayOfTheMonth = this.month.lastDate.getDate();
    const days = [];
    for (let offset = 0; offset < lastDayOfTheMonth; offset++) {
      days.push(offset + 1);
    }
    return days;
  }

  decrementOne() {
    const calendarApi = this.fullCalendar.getApi();
    calendarApi.prev();
    switch (this.currentScheduleView) {
      case 'Day':
        this.selectedDate.setDate(this.selectedDate.getDate() - 1);
        break;
      case 'Week':
        this.selectedDate.setDate(this.selectedDate.firstDayOfWeek().getDate() - 7);
        break;
      case 'Month':
        this.selectedDate.setMonth(this.selectedDate.getMonth() - 1);
        break;
      case 'Year':
        this.selectedDate.setFullYear(this.selectedDate.getFullYear() - 1);
        break;
    }
    this.gotoDate();
    this.refreshSchedule();
  }

  incrementOne() {
    const calendarApi = this.fullCalendar.getApi();
    calendarApi.next();
    switch (this.currentScheduleView) {
      case 'Day':
        this.selectedDate.setDate(this.selectedDate.getDate() + 1);
        break;
      case 'Week':
        this.selectedDate.setDate(this.selectedDate.firstDayOfWeek().getDate() + 7);
        break;
      case 'Month':
        this.selectedDate.setMonth(this.selectedDate.getMonth() + 1);
        break;
      case 'Year':
        this.selectedDate.setFullYear(this.selectedDate.getFullYear() + 1);
        break;
    }
    this.gotoDate();
    this.refreshSchedule();
  }

  overlayFullCalendarEvents(addedEvents) {
    if (this.events.length === 0) {
      this.events = addedEvents;
    } else {
      addedEvents.forEach((addedEvent: any) => {
        const index: number = this.events.findIndex((event: any) => event.uuid === addedEvent.uuid);
        if (index === -1) {
          this.events.push(addedEvent);
        } else {
          this.events[index] = addedEvent;
        }
      });
    }
    this.mapFullCalendarEvents();
  }

  mapFullCalendarEvents() {
    const events: any = this.events;
    this.clearEvents();
    events.forEach((event: any) => this.addMappedEvent(event));
  }

  addMappedEvent(event) {
    this.events.push(this.mapEventFromApi(event));
  }

  mapEventFromApi(event) {
    const mappedEvent: any = {};
    mappedEvent.id = event.uuid;
    mappedEvent.title = event.name;
    // const startDate: any = this.dateParts(event.start_time);
    const start = Moment(event.start_time).tz(this.timezoneToViewName);
    mappedEvent.start = new Date((new Date(start.toString())).getTime() + start.utcOffset() * 60000);
    // const endDate: any = this.dateParts(event.end_time);
    const end = Moment(event.end_time).tz(this.timezoneToViewName);
    mappedEvent.end = new Date((new Date(end.toString())).getTime() + end.utcOffset() * 60000);
    mappedEvent.color = event.event_category && !!event.event_category.color ? event.event_category.color : '#ccc';
    mappedEvent.event_category_uuid = event.event_category && !!event.event_category.uuid ? event.event_category.uuid : false;
    mappedEvent.allDay = event.all_day_event || this.timeListViewEventSpansWholeDay(mappedEvent);
    mappedEvent.calendars_uuids = event.calendars;
    return mappedEvent;
  }

  dateParts(dateString) {
    const parts = dateString.split(' ');
    const day = parts[0].split('-');
    const time = parts[1].split(':');
    const date = {
      year: day[0],
      month: day[1],
      day: day[2],
      hour: time[0],
      minute: time[1]
    };
    return date;
  }

  timeListViewEventSpansWholeDay(event) {
    if (this.currentScheduleView === 'Day') {
      return event.start <= this.day.firstDate && event.end >= this.day.lastDate;
    }
    return false;
  }

}
