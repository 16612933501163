import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from './modules/common/core/components/auth/auth.component';
import { AuthGuard } from './modules/common/core/components/auth/auth.guard';
import { SportsologySuperUserGuard } from './modules/common/core/components/auth/sportsology-super-user.guard';

/* Components */
import { AccessCodesIndexComponent } from '@components/access-codes/index/access-codes-index.component';
import { AccessCodeFormComponent } from '@components/access-codes/form/access-code-form.component';
import { AccessCodeViewComponent } from '@components/access-codes/view/access-code-view.component';

import { ActivitiesIndexComponent } from '@components/activities/index/activities-index.component';
import { ActivityViewComponent } from '@components/activities/view/activity-view.component';

import { AppsIndexComponent } from '@components/apps/index/apps-index.component';
import { AppFormComponent } from '@components/apps/form/app-form.component';
import { ArticleCategoryFormComponent } from '@components/article-categories/form/article-category-form.component';
import { ArticleCategoriesIndexComponent } from '@components/article-categories/index/article-categories-index.component';
import { ArticlesIndexComponent } from '@components/pages/articles-index/articles-index.component';

import { CalendarFormComponent } from '@components/calendars/form/calendar-form.component';
import { CalendarsIndexComponent } from '@components/calendars/index/calendars-index.component';
import { CalendarSortComponent } from '@components/calendars/sort/calendars-sort.component';
import { CalendarViewSubscribersComponent } from '@components/calendars/view-subscribers/calendar-view-subscribers.component';

// Chats
import { ChatsComponent } from '@components/chats/chats.component';

// ContactGroup
import { ContactGroupFormComponent } from '@components/contact-groups/form/contact-group-form.component';
import { ContactGroupsIndexComponent } from '@components/contact-groups/index/contact-groups-index.component';
import { ContactGroupSortUsersComponent } from '@components/contact-groups/sort-users/contact-group-sort-users.component';
import { ContactGroupSortComponent } from '@components/contact-groups/sort/contact-group-sort.component';
import { ContactGroupViewComponent } from '@components/contact-groups/view/contact-group-view.component';
import { ContactGroupViewUsersComponent } from '@components/contact-groups/view-users/contact-group-view-users.component';
// Contact Types
import { ContactTypeFormComponent } from '@components/contact-types/form/contact-type-form.component';
import { ContactTypesIndexComponent } from '@components/contact-types/index/contact-types-index.component';
import { ContactTypeSortComponent } from '@components/contact-types/sort/contact-type-sort-sort.component';
// Contacts
import { ContactsIndexComponent } from '@components/contacts/index/contacts-index.component';
import { SecondaryContactFormComponent } from '@components/contacts/form/secondary-contact-form.component';
// ContentLogs
import { ContentLogsIndexComponent } from '@components/content-logs/index/content-logs-index.component';
// DirectoryCategories
import { DirectoryCategoriesIndexComponent } from './components/directory-categories/index/directory-categories-index.component';
import { DirectoryCategoryFormComponent } from './components/directory-categories/form/directory-category-form.component';
// DirectoryItems
import { DirectoryItemFormComponent } from '@components/directory-items/form/directory-item-form.component';
import { DirectoryItemsIndexComponent } from '@components/directory-items/index/directory-items-index.component';

// ContractType
import { ContractTypeFormComponent } from '@components/contract-types/form/contract-type-form.component';
import { ContractTypesIndexComponent } from '@components/contract-types/index/contract-types-index.component';
import { CountryFormComponent } from '@components/countries/form/country-form.component';
import { CountriesIndexComponent } from '@components/countries/index/countries-index.component';

import { DocumentsIndexComponent } from '@components/documents/index/documents-index.component';
import { DocumentViewComponent } from '@components/documents/view/document-view.component';
import { DocumentViewUsersComponent } from '@components/documents/view-users/document-view-users.component';
import { DocumentFormComponent } from './components/documents/form/document-form.component';

import { EventCategoryFormComponent } from '@components/event-categories/form/event-category-form.component';
import { EventCategoriesIndexComponent } from '@components/event-categories/index/event-categories-index.component';

import { EventsIndexComponent } from '@components/events/index/events-index.component';
import { EventViewComponent } from '@components/events/view/event-view.component';
import { EventFormComponent } from './components/events/form/event-form.component';

// Exports
import { ExportsIndexComponent } from '@components/exports/index/exports-index.component';
import { ExportFormComponent } from '@components/exports/form/export-form.component';

import { GroupFormComponent } from '@components/groups/form/group-form.component';
import { GroupsIndexComponent } from '@components/groups/index/groups-index.component';
import { GroupSortComponent } from '@components/groups/sort/group-sort.component';
import { GroupViewUsersComponent } from './components/groups/view-users/group-view-users.component';

import { LocationFormComponent } from '@components/locations/form/location-form.component';
import { LocationsIndexComponent } from '@components/locations/index/locations-index.component';

import { MasterSettingsIndexComponent } from './components/settings/master-index/master-settings-index.component';
import { MasterSettingFormComponent } from './components/settings/master-form/master-setting-form.component';
import { ClientSettingsIndexComponent } from './components/settings/client-index/client-settings-index.component';
import { ClientSettingFormComponent } from './components/settings/client-form/client-setting-form.component';

import { MessageFormComponent } from '@components/messages/form/message-form.component';
import { MessagesIndexComponent } from '@components/messages/index/messages-index.component';
import { MessageViewComponent } from '@components/messages/view/message-view.component';
import { MessageViewUsersComponent } from '@components/messages/view-users/message-view-users.component';
import { MessageSortArticlesComponent } from './components/messages/sort-articles/message-sort-articles.component';
import { MessageSortDocumentsComponent } from './components/messages/sort-documents/message-sort-documents.component';
import { NotificationsIndexComponent  } from './components/notifications/index/notifications-index.component';
import { NotificationViewComponent  } from './components/notifications/view/notification-view.component';

import { MaintenanceIndexComponent } from './components/maintenance/index/maintenance-index.component';

// import { NotesIndexComponent } from './components/notes/index/notes-index.component';
// import { NoteFormComponent } from './components/notes/form/note-form.component';
// import { NoteCategoriesIndexComponent } from './components/note-categories/index/note-categories-index.component';
// import { NoteCategoryFormComponent } from './components/note-categories/form/note-category-form.component';

import { NotFoundComponent } from '@components/not-found/not-found.component';
import { PageFormComponent } from '@components/pages/form/page-form.component';
import { PagesIndexComponent } from '@components/pages/pages-index/pages-index.component';
import { PermissionFormComponent } from '@components/permissions/form/permission-form.component';
import { PermissionsIndexComponent } from '@components/permissions/index/permissions-index.component';
// Roles
import { RoleFormComponent } from '@components/roles/form/role-form.component';
import { RolesIndexComponent } from '@components/roles/index/roles-index.component';
import { RoleSortComponent } from '@components/roles/sort/role-sort.component';

// Schedule (calendar)
import { ScheduleComponent } from '@components/schedule/schedule.component';
import { ScheduleEventDuplicateComponent } from './components/events/duplicate/event-duplicate.component';
import { EventBulkEditComponent } from './components/events/bulk-edit/event-bulk-edit.component';

import { TagFormComponent } from '@components/tags/form/tag-form.component';
import { TagsIndexComponent } from '@components/tags/index/tags-index.component';
import { UserCircleFormComponent } from '@components/user-circles/form/user-circle-form.component';
import { UserCircleViewComponent } from '@components/user-circles/view/user-circle-view.component';
import { UserCirclesIndexComponent } from '@components/user-circles/index/user-circles-index.component';

import { UserPositionFormComponent } from '@components/user-positions/form/user-position-form.component';
import { UserPositionsIndexComponent } from '@components/user-positions/index/user-positions-index.component';
import { UserPositionSortComponent } from '@components/user-positions/sort/user-position-sort.component';

// Folders
import { FolderFormComponent } from './components/folders/form/folder-form.component';
import { FoldersIndexComponent } from './components/folders/index/folders-index.component';
import { FolderViewComponent } from './components/folders/view/folder-view.component';
import { FolderViewUsersComponent } from './components/folders/view-users/folder-view-users.component';

import { UserAssociatedContactsComponent } from './components/users/associated-contacts/user-associated-contacts.component';
import { UserProfileFormComponent } from './components/users/edit/user-profile-form.component';
import { UserFormComponent } from './components/users/form/user-form.component';
import { UserMessageFormComponent } from './components/users/messages/form/user-message-form.component';
import { UserMessagesIndexComponent } from '@components/users/messages/index/user-messages-index.component';
import { UserEventFormComponent } from './components/users/events/form/user-event-form.component';

import { UsersIndexComponent } from './components/users/index/users-index.component';
import { SettingsIndexComponent } from './components/settings/index/settings-index.component';
import { TimezonesIndexComponent } from './components/timezones/index/timezones-index.component';
import { MessageResponseIndexComponent} from './components/message-responses/index/message-response-index.component';

import { BundlesIndexComponent } from './components/bundles/index/bundles-index.component';
import { BundleItemsIndexComponent } from './components/bundle-items/index/bundle-items-index.component';
import { BundleItemSortComponent } from './components/bundle-items/sort/bundle-item-sort.component';
import { BundleBuilderComponent } from './components/bundles/builder/bundle-builder.component';
import { BundleFormComponent } from './components/bundles/form/bundle-form.component';
import { BundleViewComponent } from './components/bundles/view/bundle-view.component';
import { BundleItemFormComponent } from './components/bundle-items/form/bundle-item-form.component';
import { BundleItemCreateComponent } from './components/bundle-items/create/bundle-item-create.component';

import { CustomFieldGroupIndexComponent } from './components/custom-field-groups/index/custom-field-group-index.component';
import { CustomFieldGroupFormComponent } from './components/custom-field-groups/form/custom-field-group-form.component';
import { CustomFieldGroupViewComponent } from './components/custom-field-groups/view/custom-field-group-view.component';
import { CustomFieldGroupSortComponent } from './components/custom-field-groups/sort/custom-field-group-sort.component';
import { CustomFieldGroupSortFieldsComponent } from './components/custom-field-groups/sort-fields/custom-field-group-sort-fields.component';

import { CustomFieldFormComponent } from './components/custom-fields/form/custom-field-form.component';

import { FolderMoveComponent } from './components/folders/move/folder-move.component';
import { SingleDocumentMoveComponent } from './components/folders/view/single-document-move/single-document-move.component';

import { VantagePointsFormComponent } from './components/vantage-points/vantage-points-form.component';

const routes: Routes = [
  // Vantage Points
  { path: 'vp-form/:formId', component: VantagePointsFormComponent, canActivate: [AuthGuard] },

  // Auth
  { path: 'login-from-id/:loginToken', component: AuthComponent, data: { action: 'loginFromId' } },
  { path: 'login/:sessionToken/rt/:refreshToken', component: AuthComponent, data: { action: 'login' } },
  { path: 'login/:sessionToken/:redirect', component: AuthComponent, data: { action: 'login' } },
  { path: 'in-app-login/:sessionToken/:redirect', component: AuthComponent, data: { action: 'inAppLogin' } },
  { path: 'logout', component: AuthComponent, data: { action: 'logout' }, canActivate: [AuthGuard] },
  { path: 'password-change', component: AuthComponent, data: { action: 'passwordChange' }, canActivate: [AuthGuard] },
  { path: 'update-spid', component: AuthComponent, data: { action: 'updateSpId' }, canActivate: [AuthGuard] },

  // Settings
  { path: 'settings', component: SettingsIndexComponent, data: { pageTitle: 'Settings' }, canActivate: [AuthGuard] },
  // User
  {
    path: 'users',
    component: UsersIndexComponent,
    data: { pageTitle: 'Users' },
    canActivate: [AuthGuard],
    children: [
      { path: 'create', component: UserFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:uuid', component: UserFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'message-form/:uuid', component: UserMessageFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'event-form/:uuid', component: UserEventFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'associated-contacts/:uuid', component: UserAssociatedContactsComponent, canActivate: [AuthGuard], outlet: 'slideout' },
    ]
  },
  {
    path: 'users/view/:uuid',
    component: UserProfileFormComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'event-form/:event_uuid', component: EventFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
    ]
  },
  // Access Codes
  {
    path: 'access-codes',
    component: AccessCodesIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Access Codes' },
    children: [
      { path: 'create', component: AccessCodeFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: AccessCodeFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
    ]
  },
  {
    path: 'access-codes/view/:id',
    component: AccessCodeViewComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Access Codes' },
    children: []
  },
  // Activities
  {
    path: 'activities',
    component: ActivitiesIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Activities' },
    children: [{ path: 'view/:id', component: ActivityViewComponent, canActivate: [AuthGuard], outlet: 'slideout' }]
  },
  // Articles
  {
    path: 'articles',
    component: ArticlesIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Articles', pageType: 'article' },
    children: [
      { path: 'edit/:id', component: PageFormComponent, canActivate: [AuthGuard], outlet: 'fullWidthOverlay' },
      { path: 'create', component: PageFormComponent, canActivate: [AuthGuard], outlet: 'fullWidthOverlay' }
    ]
  },
  // Apps
  {
    path: 'apps',
    component: AppsIndexComponent,
    canActivate: [AuthGuard, SportsologySuperUserGuard],
    data: { pageTitle: 'Apps' },
    children: [
      { path: 'create', component: AppFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: AppFormComponent, canActivate: [AuthGuard], outlet: 'slideout' }
    ]
  },
  // Bundle index page
  {
    path: 'bundles',
    component: BundlesIndexComponent,
    canActivate: [AuthGuard, SportsologySuperUserGuard],
    data: { pageTitle: 'Bundles' },
    children: [
      { path: 'create', component: BundleBuilderComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'fullWidthOverlay' },
      // { path: 'create', component: BundleFormComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: BundleFormComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' },
      { path: 'builder/:id', component: BundleBuilderComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'fullWidthOverlay'},
      // { path: 'sort/:id', component: BundleItemSortComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' }
    ]
  },
  // Bundle View page
  {
    path: 'bundles/view/:id',
    component: BundleViewComponent,
    canActivate: [AuthGuard, SportsologySuperUserGuard],
    data: { pageTitle: 'Bundle Items' },
    children: [
      { path: 'bundle-item-create/:bundleID', component: BundleItemCreateComponent , canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' },
      { path: 'bundle-item-edit/:itemid', component: BundleItemFormComponent , canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' },
      { path: 'bundle-item-sort/:id', component: BundleItemSortComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' }
    ]
  },
  // Bundle items
  {
    path: 'bundle-items',
    component: BundleItemsIndexComponent,
    canActivate: [AuthGuard, SportsologySuperUserGuard],
    data: { pageTitle: 'Bundle Items' },
    children: [
      { path: 'create', component: BundleItemFormComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' },
      { path: 'edit/:itemid', component: BundleItemFormComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' },
    ]
  },
  // Article Categories
  {
    path: 'article-categories',
    component: ArticleCategoriesIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Article Categories' },
    children: [
      { path: 'create', component: ArticleCategoryFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: ArticleCategoryFormComponent, canActivate: [AuthGuard], outlet: 'slideout' }
    ]
  },
  // Calendar
  {
    path: 'schedule',
    component: ScheduleComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Schedule' },
    children: [
      {
        path: 'edit-event/:id',
        component: EventFormComponent,
        canActivate: [AuthGuard],
        outlet: 'slideout',
        data: { scheduleUpdate: true }
      },
      { path: 'create-event', component: EventFormComponent, canActivate: [AuthGuard], outlet: 'slideout', data: { scheduleUpdate: true } },
      { path: 'create-event/:newEventAtDate', component: EventFormComponent, canActivate: [AuthGuard], outlet: 'slideout', data: { scheduleUpdate: true } },
      { path: 'duplicate-event', component: ScheduleEventDuplicateComponent, canActivate:
                [AuthGuard], outlet: 'slideout', data: { scheduleUpdate: true } },
      { path: ':year', component: ScheduleComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: ':year/:month', component: ScheduleComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: ':year/:month', component: ScheduleComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: ':year/:month/:day', component: ScheduleComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: ':year/:month/:day/:eventUUID', component: ScheduleComponent, canActivate: [AuthGuard], outlet: 'slideout' },

    ]
  },
  // Calendars
  {
    path: 'calendars',
    component: CalendarsIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Calendars' },
    children: [
      { path: 'create', component: CalendarFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: CalendarFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'view-subscribers/:id', component: CalendarViewSubscribersComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'sort', component: CalendarSortComponent, canActivate: [AuthGuard], outlet: 'slideout' }
    ]
  },
  // Chats
  {
    path: 'chats',
    component: ChatsComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Chats' }
  },
  // Contacts (Secondary Contacts List)
  {
    path: 'contacts',
    component: ContactsIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Contacts' },
    children: [
      { path: 'create', component: SecondaryContactFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: SecondaryContactFormComponent, canActivate: [AuthGuard], outlet: 'slideout' }
    ]
  },
  // Cotent Logs
  {
    path: 'content-logs',
    component: ContentLogsIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Content Logs' },
  },
  // Directory Categories
  {
    path: 'directory-categories',
    component: DirectoryCategoriesIndexComponent,
    canActivate: [AuthGuard, SportsologySuperUserGuard],
    data: { pageTitle: 'Directory Categories' },
    children: [
      { path: 'create', component: DirectoryCategoryFormComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: DirectoryCategoryFormComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' }
    ]
  },
  // Directory Items
  {
    path: 'directory-items',
    component: DirectoryItemsIndexComponent,
    canActivate: [AuthGuard, SportsologySuperUserGuard],
    data: { pageTitle: 'Directory' },
    children: [
      { path: 'create', component: DirectoryItemFormComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: DirectoryItemFormComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' }
    ]
  },
  // Contact Types (Secondary Contacts)
  {
    path: 'contact-types',
    component: ContactTypesIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Contact Types' },
    children: [
      { path: 'create', component: ContactTypeFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: ContactTypeFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'sort', component: ContactTypeSortComponent, canActivate: [AuthGuard], outlet: 'slideout' }
    ]
  },
  // Contact Groups
  {
    path: 'contact-groups',
    component: ContactGroupsIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Contact Groups' },
    children: [
      { path: 'create', component: ContactGroupFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: ContactGroupFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'sort', component: ContactGroupSortComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'sort-users/:id', component: ContactGroupSortUsersComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'view-users/:id', component: ContactGroupViewUsersComponent, canActivate: [AuthGuard], outlet: 'slideout' }
    ]
  },
  {
    path: 'contact-groups/view/:id',
    component: ContactGroupViewComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Contact Groups' },
    children: []
  },

  // User Circles
  {
    path: 'user-circles',
    component: UserCirclesIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'User Circles' },
    children: [
      { path: 'create', component: UserCircleFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: UserCircleFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'view/:id', component: UserCircleViewComponent, canActivate: [AuthGuard], outlet: 'slideout' }
    ]
  },
  // Contract Types
  {
    path: 'contract-types',
    component: ContractTypesIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Contract Types' },
    children: [
      { path: 'create', component: ContractTypeFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: ContractTypeFormComponent, canActivate: [AuthGuard], outlet: 'slideout' }
    ]
  },
  // Countries / Country
  {
    path: 'countries',
    component: CountriesIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Countries' },
    children: [
      { path: 'create', component: CountryFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: CountryFormComponent, canActivate: [AuthGuard], outlet: 'slideout' }
    ]
  },
  // Documents
  {
    path: 'documents',
    component: DocumentsIndexComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Documents',
    },
    children: [
      { path: 'create', component: DocumentFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: DocumentFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'view/:id', component: DocumentViewComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'view-users/:id', component: DocumentViewUsersComponent, canActivate: [AuthGuard], outlet: 'slideout' }
    ]
  },
  // Folder
  {
    path: 'folders',
    component: FoldersIndexComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Documents',
      // This allow us to reload the document page from the nav to clear filters,
      // but has the side-effect of breaking the slideout forms on the page.
      // shouldReuseRoute: false
    },
    children: [
      { path: 'create', component: FolderFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: FolderFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'view-folder-users/:id', component: FolderViewUsersComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'move-folder/:id', component: FolderMoveComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'create-document', component: DocumentFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit-document/:id', component: DocumentFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'view-document/:id', component: DocumentViewComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'view-document-users/:id', component: DocumentViewUsersComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'move-document/:id', component: SingleDocumentMoveComponent, canActivate: [AuthGuard], outlet: 'slideout' }
    ]
  },
  {
    path: 'folder/view/:parent_uuid', component: FolderViewComponent, canActivate: [AuthGuard],
    data: { pageTitle: 'Documents' },
    children: [
      { path: 'create-document/:parent_uuid', component: DocumentFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'create-folder/:parent_uuid', component: FolderFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'move-folder/:id', component: FolderMoveComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      {
        path: 'folderview/:id', component: FolderViewComponent, canActivate: [AuthGuard],
        outlet: 'slideout', data: { pageTitle: 'Folders' }
      },
      { path: 'folderedit/:id', component: FolderFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'documentedit/:id', component: DocumentFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'documentmove/:id', component: SingleDocumentMoveComponent, canActivate: [AuthGuard], outlet: 'slideout' }
    ]
  },
  {
    path: 'folder/view/:parent_uuid/documentview/:id',
    component: DocumentViewComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Documents' },
    children: []
  },
  // Event Categories
  {
    path: 'event-categories',
    component: EventCategoriesIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Event Categories' },
    children: [
      { path: 'create', component: EventCategoryFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: EventCategoryFormComponent, canActivate: [AuthGuard], outlet: 'slideout' }
    ]
  },
  // Events
  {
    path: 'events/calendar/:calendar_uuid',
    component: EventsIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Events' },
    children: [
      { path: 'view/:id', component: EventViewComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'create', component: EventFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: EventFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'bulk-edit', component: EventBulkEditComponent, canActivate: [AuthGuard], outlet: 'slideout' },
    ]
  },
  {
    path: 'events',
    component: EventsIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Events' },
    children: [
      { path: 'view/:id', component: EventViewComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'create', component: EventFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: EventFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'bulk-edit', component: EventBulkEditComponent, canActivate: [AuthGuard], outlet: 'slideout' },
    ]
  },
  {
    path: 'periodization-events',
    component: EventsIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Periodization Events', periodization: true }
  },
  // Exports
  {
    path: 'exports',
    component: ExportsIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Exports' },
    children: [
      { path: 'create', component: ExportFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: ExportFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
    ]
  },
  // Groups
  {
    path: 'groups',
    component: GroupsIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Groups' },
    children: [
      { path: 'create', component: GroupFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: GroupFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'sort', component: GroupSortComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'view-users/:id', component: GroupViewUsersComponent, canActivate: [AuthGuard], outlet: 'slideout' }
    ]
  },
  // Locations
  {
    path: 'locations',
    component: LocationsIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Locations' },
    children: [
      { path: 'create', component: LocationFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: LocationFormComponent, canActivate: [AuthGuard], outlet: 'slideout' }
    ]
  },
  {
    path: 'timezones',
    component: TimezonesIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Timezones' }
  },

  // Master Settings
  {
    path: 'master-settings',
    component: MasterSettingsIndexComponent,
    canActivate: [AuthGuard, SportsologySuperUserGuard],
    data: { pageTitle: 'Master Settings' },
    children: [
      { path: 'create', component: MasterSettingFormComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: MasterSettingFormComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' }
    ]
  },

  // Client Settings
  {
    path: 'client-settings',
    component: ClientSettingsIndexComponent,
    canActivate: [AuthGuard, SportsologySuperUserGuard],
    data: { pageTitle: 'Client Settings' },
    children: [
      { path: 'edit/:id', component: ClientSettingFormComponent, canActivate: [AuthGuard], outlet: 'slideout' }
    ]
  },

  // Create Message
  {
    path: 'create-message',
    component: MessageFormComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Create Message' }
  },

  // Messages
  {
    path: 'messages',
    component: MessagesIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Messages' },
    children: [
      { path: 'createForm', component: MessageFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'editForm/:id', component: MessageFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'view/:id', component: MessageViewComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'view-users/:id', component: MessageViewUsersComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'sort-articles/:id', component: MessageSortArticlesComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'sort-documents/:id', component: MessageSortDocumentsComponent, canActivate: [AuthGuard], outlet: 'slideout' },
     ]
  },
  // Sent Messages
  {
    path: 'sent-messages',
    component: MessagesIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Sent Messages' }
  },
  // Messages Response
  {
    path: 'message-responses',
    component: MessageResponseIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Message Responses' },
    children: [
      { path: 'message-form/:uuid', component: UserMessageFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
    ]
  },
  // Notifications
  {
    path: 'notifications',
    component: NotificationsIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Delivery Reports' },
    children: [
      { path: 'view/:id', component: NotificationViewComponent, canActivate: [AuthGuard], outlet: 'slideout' },
     ]
  },
  // Notes
  // {
  //   path: 'notes',
  //   component: NotesIndexComponent,
  //   canActivate: [AuthGuard],
  //   data: { pageTitle: 'Notes' },
  //   children: [
  //     { path: 'create', component: NoteFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
  //     { path: 'edit/:id', component: NoteFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
  //    ]
  // },
  // // NoteCategories
  // {
  //   path: 'note-categories',
  //   component: NoteCategoriesIndexComponent,
  //   canActivate: [AuthGuard],
  //   data: { pageTitle: 'Note Categories' },
  //   children: [
  //     { path: 'create', component: NoteCategoryFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
  //     { path: 'edit/:id', component: NoteCategoryFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
  //    ]
  // },
  {
    path: 'user-positions',
    component: UserPositionsIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'User Positions' },
    children: [
      { path: 'create', component: UserPositionFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: UserPositionFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'sort', component: UserPositionSortComponent, canActivate: [AuthGuard], outlet: 'slideout' }
    ]
  },
  // Pages
  {
    path: 'app-pages',
    component: PagesIndexComponent,
    canActivate: [AuthGuard, SportsologySuperUserGuard],
    data: { pageTitle: 'App Pages', pageType: 'appPage' },
    children: [
      { path: 'edit/:id', component: PageFormComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'fullWidthOverlay' },
      { path: 'create/:app_id', component: PageFormComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'fullWidthOverlay' }
    ]
  },
  // Permissions
  {
    path: 'permissions',
    component: PermissionsIndexComponent,
    canActivate: [AuthGuard, SportsologySuperUserGuard],
    data: { pageTitle: 'Permissions' },
    children: [
      { path: 'create', component: PermissionFormComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: PermissionFormComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' }
    ]
  },
  // Roles
  {
    path: 'roles',
    component: RolesIndexComponent,
    canActivate: [AuthGuard, SportsologySuperUserGuard],
    data: { pageTitle: 'Roles' },
    children: [
      { path: 'create', component: RoleFormComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: RoleFormComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' },
      { path: 'sort', component: RoleSortComponent, canActivate: [AuthGuard, SportsologySuperUserGuard], outlet: 'slideout' }
    ]
  },
  // Tags
  {
    path: 'tags',
    component: TagsIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Tags' },
    children: [
      { path: 'create', component: TagFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: TagFormComponent, canActivate: [AuthGuard], outlet: 'slideout' }
    ]
  },

  // Master Settings
  {
    path: 'view-laravel-log',
    component: MaintenanceIndexComponent,
    canActivate: [AuthGuard, SportsologySuperUserGuard],
    data: { pageTitle: 'Laravel Log' }
  },

  //  Custom Field Groups
   {
    path: 'custom-field-groups',
    component: CustomFieldGroupIndexComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Custom Fields' },
    children: [
      { path: 'create', component: CustomFieldGroupFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'edit/:id', component: CustomFieldGroupFormComponent, canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'sort', component: CustomFieldGroupSortComponent, canActivate: [AuthGuard], outlet: 'slideout' },
    ]
  },
  // CustomFieldGroup
  {
    path: 'custom-field-groups/view/:id',
    component: CustomFieldGroupViewComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Custom Fields' },
    children: [
      { path: 'custom-field-create/:groupId', component: CustomFieldFormComponent , canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'custom-field-edit/:fieldId', component: CustomFieldFormComponent , canActivate: [AuthGuard], outlet: 'slideout' },
      { path: 'sort-fields/:groupId', component: CustomFieldGroupSortFieldsComponent , canActivate: [AuthGuard], outlet: 'slideout' },

    ]
  },

  // User Messages
  // { path: 'create-user-message', component: UserMessageEditComponent, canActivate: [AuthGuard] },
  { path: 'user-messages', component: UserMessagesIndexComponent, canActivate: [AuthGuard] },
  // { path: 'user-message/:id', component: UserMessageEditComponent, canActivate: [AuthGuard] },
  // 404
  { path: '404', component: NotFoundComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: '/schedule', pathMatch: 'full' },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
