import {
  UrlDefaults,
  Traits
} from './shared';

import { ICON_BUTTON_URL } from './../../../const/icons';

import { COMP_BUTTON_URL } from './../../../const/components';

import { FIELD_OPEN_IN_APP_BROWSER, FIELD_URL } from './../../../const/traits';

import { PageFormLinkedItem } from './../page-form-linked-item';

export const Url = (editor, options:any = {}) => {

  (FIELD_URL as any).at = 1;
  (FIELD_OPEN_IN_APP_BROWSER as any).at = 9;

  return PageFormLinkedItem(editor, {
    componentName: COMP_BUTTON_URL,
    displayIcon: ICON_BUTTON_URL,
    model: {
      defaults: { ...UrlDefaults,
        name: 'Url',
        traits: [ ...Traits, FIELD_OPEN_IN_APP_BROWSER, FIELD_URL]
      }
    }
  });
}