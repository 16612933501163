import { Component, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { EventService } from '@services/event.service';
import { SidebarService } from '@services/sidebar.service';


@Component({
  selector: 'event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.scss'],
  providers: [SidebarService]
})
@Injectable()
export class EventViewComponent extends BaseComponent {

  public event: object = {};
  public dataLoaded = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public eventService: EventService,
    public sidebarService: SidebarService,
    public state: State,
    public router: Router
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService,
      state,
      router,
      modelService: eventService
    });
    this.setPageVars({
      sidebar: 'events'
    });
    this.modelServiceGetOne(this.routeParams['id'], 'event').then(() => {
      this.dataLoaded = true;
    });
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }
}
