import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { MatSort, MatPaginator, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ApiModel } from '@app/models/entities/api-model';
import { Router } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SnackBarService } from '@app/services/snack-bar.service';
import { DocumentService } from '@app/services/document.service';
import { UploadService } from '@app/services/upload.service';

@Component({
  selector: 'document-list-view',
  templateUrl: './document-list-view.component.html',
  styleUrls: ['./document-list-view.component.scss']
})
export class DocumentListViewComponent extends BaseComponent implements OnInit, OnChanges
{
  @Input() dsDocument: any;
  @Input() showActionMenu = true;
  @Output() editDocument = new EventEmitter<ApiModel.Document>();
  @Output() deleteDocument = new EventEmitter<ApiModel.Document>();
  @Output() viewDocument = new EventEmitter<ApiModel.Document>();
  @Output() deleteSelected = new EventEmitter();
  @Output() getSelectedDocuments = new EventEmitter<any>();
  @Output() moveDocument = new EventEmitter<ApiModel.Document>();
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('matDocumentPaginator', {static: false}) private matDocumentPaginator: MatPaginator;
  public displayedCols = ['file_type', 'name', 'file_size', 'created_at', 'created_by', 'user_count'];
  public selectionDocument = new SelectionModel<any>(true, []);
  public baseUrl;

  constructor(
    router: Router,
    public dialog: MatDialog,
    public snackbarService: SnackBarService,
    state: State,
    public documentService: DocumentService,
    public uploadService: UploadService,
  ) {
    super();
    this.setObjects({
      modelService: documentService,
      router,
      state,
      snackbarService,
      dialog
    });
  }

  ngOnInit() {
    if (this.showActionMenu) {
      this.displayedCols.push('actions');
    }
    this.dsDocument.paginator = this.matDocumentPaginator;
    this.dsDocument.sort = this.sort;
    this.baseUrl = this.uploadService.baseUrl;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dsDocument = changes.dsDocument.currentValue;
    this.dsDocument.paginator = this.matDocumentPaginator;
    this.dsDocument.sort = this.sort;
  }

  // Is the logged-in user the owner of this folder / document
  // Used for disabling the Delete button
  // Duplicated from base.component, so needs consolidating at some point
  public isCreatedUser(created_user_uuid) {
    const user = this.state.get('user');
    if (created_user_uuid == user.uuid) {
      return true;
    }
    return false;
  }

  /** The label for the checkbox on the passed row */
  public checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectionDocument.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  /** Whether the number of selected elements matches the total number of rows. **/
  public isAllSelected() {
    const numSelected = this.selectionDocument.selected.length;
    const numRows = this.dsDocument && this.dsDocument.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. **/
  public masterToggle() {
    this.isAllSelected() ? this.selectionDocument.clear() : this.dsDocument.data.forEach(row => this.selectionDocument.select(row));
    // this.getSelectedDocuments.emit(this.selectionDocument);
  }

  public viewItem(item: ApiModel.Document) {
    if (this.viewDocument.observers.length > 0) {
      this.viewDocument.emit(item);
    }
  }

  public editItem(item: ApiModel.Document) {
    if (this.editDocument.observers.length > 0) {
      this.editDocument.emit(item);
    }
  }

  public deleteItem(item: ApiModel.Document) {
    if (this.deleteDocument.observers.length > 0) {
      this.deleteDocument.emit(item);
    }
  }

  public deleteSelectedItems() {
    if (this.deleteSelected.observers.length > 0) {
      this.deleteSelected.emit();
    }
  }

  public moveItem(item: ApiModel.Document) {
    if (this.moveDocument.observers.length > 0) {
      this.moveDocument.emit(item);
    }
  }
}
