import { Component, Input, OnInit, ViewChild, EventEmitter, Output, DoCheck } from '@angular/core';
import { State } from '@app/state';

enum PAYLOAD_STATE {
  UPLOADED = 'uploaded',
  READY = 'ready',
  ERROR = 'error'
}

@Component({
  selector: 'avatar-upload',
  templateUrl: './avatar-upload.component.html',
  styleUrls: ['./avatar-upload.component.scss']
})
export class AvatarUploadComponent implements OnInit, DoCheck {
  @Input() src = '';
  @Input() uuid = '';
  @Output() onSaved = new EventEmitter<string>();
  @ViewChild('uploadcare', {static: false}) child;
  public uploading = false;
  public percentComplete = '20%';
  public backgroundImageStyle = '';
  public publicKey = '';
  public baseUrl = '';

  constructor(private state: State) {
    const settings = this.state.get('ccSettings');
    this.baseUrl = settings.uploadcare.url;
  }

  ngOnInit(): void {
    const settings = this.state.get('ccSettings');
    this.publicKey = settings.uploadcare.public_key;
    this.setPreviewImage();
  }

  ngDoCheck(): void {
    this.setPreviewImage();
  }

  setPreviewImage() {
    this.backgroundImageStyle = `url(` + this.src + `)`;
  }

  uploadComplete(payload) {
    this.percentComplete = '0%';
    this.uploading = false;
    const imageUUID = payload.uuid;

    try {
      if (imageUUID) {
        this.src = `${this.baseUrl}${imageUUID}/-/preview/-/resize/350x/`;
      } else {
        this.src = 'assets/img/avatar/upload.png';
      }
      this.setPreviewImage();
      this.state.message({'avatarUUID': imageUUID });
      this.onSaved.emit(this.src);
    } catch (error) {
      console.log(error);
    }
  }

  uploadProgress(payload) {
    if (payload.state === PAYLOAD_STATE.UPLOADED) {
      setTimeout(() => {
        this.uploadComplete(payload.incompleteFileInfo);
      }, 2000);
      return;
    }
    if (payload.state === PAYLOAD_STATE.READY) {
      return false;
    }
    if (payload.state === PAYLOAD_STATE.ERROR) {
      this.uploading = false;
      return;
    }
    this.uploading = true;
    this.percentComplete = Math.max(20, Math.ceil(payload['progress'] * 100)) + '%';
  }

  openDialogue() {
    this.child.openDialog();
  }
}
