import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { BUNDLES_FILTER_FIELDS } from './filter-fields';
import { BundleService } from '@app/services/bundle.service';
import { SidebarFilterLink } from '@app/services/sidebar-filter-link.service';
import { FilterLink } from '@app/models/view-models/filter-link';
@Component({
  selector: 'app-bundles-index',
  templateUrl: './bundles-index.component.html',
  styleUrls: ['./bundles-index.component.scss'],
  providers: [SidebarService]
})
export class BundlesIndexComponent extends BaseComponent implements OnInit, AfterViewInit  {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['select', 'featured_image', 'title', 'bundle_items', 'actions'];
  public showSearch = false;
  public dataLoaded = false;
  public fields: any = BUNDLES_FILTER_FIELDS;
  public links: FilterLink[] = [];

  constructor(
    public bundleService: BundleService,
    public router: Router,
    public sidebarService: SidebarService,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog,
    private sidebarFilterLink: SidebarFilterLink
  ) {
    super();
    this.setObjects({
      modelService: bundleService,
      router,
      sidebarService,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      sidebar: 'bundles',
      listenForIndexUpdate: true,
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.modelServiceGetAll().then(() => this.dataLoaded = true);
    this.links = this.sidebarFilterLink.buildBundlesFilterLinks();
  }

  public updatedStatus(): void {
    this.state.message({ ['BundleIndexUpdate']: true });
  }

  public onFilterClicked(link: FilterLink): void {
    if (link.route) {
      this.router.navigate([link.route]);
    }
  }

}
