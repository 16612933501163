import grapesjs from 'grapesjs';
import { paramCase } from 'change-case';

// Editor components, blocks...
import { EditorPlugin } from './editor';

// Utils
import { uuidv4 } from './editor/utils';

// Form consts
import { TYPE_APP_PAGE } from './const/form';

// Command consts
import {
    CMD_PANEL_OPEN_BLOCKS,
    CMD_PANEL_OPEN_COMPONENT_ITEMS,
    CMD_PANEL_OPEN_LAYERS,
    CMD_PANEL_OPEN_TRAITS,
    CMD_REMOVE_FROM_PAGE_FORM_ITEMS,
    CMD_REMOVE_FROM_PAGE_ITEM_PERMS
} from './const/commands';

// Editor base actions
import {
    clearPage,
    contentEditableIsSet,
    contentEditableUnset,
    hideDefaultTraitsForTextComponent,
    hideToolbar,
    makeSureSelectedClassIsApplied,
    markFirstPageSectionAsSelected,
    pageSectionVisibleAfterEditorSelection,
    rerenderCanvas,
    restorePreviousRTESelection,
    selectFirstPageSection,
    selectPageSectionAfterPageSectionSelection,
    setActivePanelOnComponentSelected,
    setComponentItemsPanel,
    setComponentTitleInTraitsPanel,
    stopBlueOutlineOnTextElementWhenInlineSelected,
    toggleComponentItemsPanelButton,
    togglePanels,
    unHideToolbar
} from './editor-base-actions';

// Component type consts
import {
    COMP_LAYOUT_ACCORDION,
    COMP_LAYOUT_PAGE_SECTION,
    COMP_LAYOUT_ROW,
    COMP_MEDIA_VIDEO,
    COMP_MEDIA_IMAGE_SLIDER,
    COMP_TEXT_BLOCK
} from './const/components';

// Icon consts
import { ICON_PERMS } from './const/icons';

// Init editor
export const initializeEditor = function(baseUrl) {

    return new Promise(resolve => {
        var editor;

        // Initialise the editor options
        const options = initOptions.call(this)

        // Load all block, components...
        EditorPlugin(options);

        // Init grapes editor
        editor = initEditor.call(this);

        // Base url for sp cdn
        editor.baseUrl = this.baseUrl;

        // Is storage secure or open
        editor.isSecureStorage = this.isSecureStorage;

        if(editor.isSecureStorage === true) {
            editor.indexDb = this.indexDb;
        }

        // On load init events
        editorOnLoad.call(this, editor);

        // Render
        editor.render();

        setTimeout(() => resolve(editor), 0);
    });
}

// Init editor options
const initOptions = function() {
    let options:any = { type: this.pageType };

    if (this.pageType === TYPE_APP_PAGE) {
        options.pageType = this.controls.app_page_type_slug.value;
    }
    return options;
}

// Init editor
const initEditor = function() {
    return grapesjs.init({
        container: '#grapes-editor',
        autorender: false,
        forceClass: false,
        avoidInlineStyle: false,
        components: '',
        style: '',
        plugins: [this.pageType],
        storageManager: { type: null },
        assetManager: {
            assets: [],
            uploadFile: false,
            embedAsBase64: false
        },
        domComponents: { wrapper: { badgable: false } },
        canvas: {
            styles: [
                './assets/grapesjs/' + this.pageType + '.css',
                // 'https://unpkg.com/material-components-web@latest/dist/material-components-web.min.css',
                // 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css',
                // 'https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css',
                'https://fonts.googleapis.com/icon?family=Material+Icons',
                'https://fonts.googleapis.com/css?family=Overpass:400,700'
            ],
            scripts: [
                'https://cdn.tiny.cloud/1/ld0utlsqklwyb4uvs4s0j11buiuvgx6211p5gqfetoe6cggn/tinymce/5/tinymce.min.js',
                // 'https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js'
            ]
        }
    });
}

// Editor on load
const editorOnLoad = function(editor) {
    editor.on('load', () => {
        // Set timeouts fix syncing with page-form bugs

        // Set wrapper
        const wrapper = editor.DomComponents.getWrapper();

        wrapper.set({ badgable: false, selectable: false, hoverable: false });
        editor.Panels.getButton('views', CMD_PANEL_OPEN_BLOCKS).set('active', true);

        // Load listeners
        setTimeout(() => {
            editorOnLoadListenEvents.call(this, editor);
            editorOnLoadSetPageSection.call(this, editor);
        }, 0);

        // translate | absolute
        // editor.setDragMode('absolute');

        // Editor events
        setTimeout(() => {
            // editorOnAll.call(this, editor);
            editorOnBlockDragStart.call(this, editor);
            editorOnBlockDragStop.call(this, editor);
            editorOnCanvasClear.call(this, editor);
            editorOnComponentDeselect.call(this, editor);
            editorOnComponentMount.call(this, editor);
            editorOnComponentClone.call(this, editor);
            editorOnComponentRemove.call(this, editor);
            editorOnComponentSelected.call(this, editor);
            editorOnPanelSelect.call(this, editor);
        }, 0);

        // Load custom panels
        setComponentItemsPanel(editor);

        this.editorLoaded = true;
    });
}

// On all editor events
const editorOnAll = function(editor) {
    editor.on('all', (event) => {
        // console.log(event)
    });
}

// On editor component mount
const editorOnComponentMount = function(editor) {
}

// On editor component clone
const editorOnComponentClone = function(editor) {
    editor.on('component:clone', (component, options:any) => {
        const newComponentBlockTagName = component.attributes.tagName;
        const parent = editor.getSelected();
        var attrs = component.getAttributes();
        if (!! attrs.uuid) {
            attrs.uuid = uuidv4();
        }
        component.setAttributes(attrs);
        setTimeout(() => editor.select(component));
    });
}

// On editor panel select
const editorOnPanelSelect = function(editor) {
    editor.on(`run:${CMD_PANEL_OPEN_COMPONENT_ITEMS}`, (component, options:any) =>
        togglePanels.call(this, editor, CMD_PANEL_OPEN_COMPONENT_ITEMS));
    editor.on(`run:${CMD_PANEL_OPEN_BLOCKS}`, (component, options:any) =>
        togglePanels.call(this, editor, CMD_PANEL_OPEN_BLOCKS));
    editor.on(`run:${CMD_PANEL_OPEN_LAYERS}`, (component, options:any) =>
        togglePanels.call(this, editor, CMD_PANEL_OPEN_LAYERS));
    editor.on(`run:${CMD_PANEL_OPEN_TRAITS}`, (component, options:any) =>
        togglePanels.call(this, editor, CMD_PANEL_OPEN_TRAITS));
}


// On editor component selected
const editorOnComponentSelected = function(editor) {
    editor.on('component:selected', (component, options:any) => {
        const Panels = editor.Panels;
        const componentAttrs = component.attributes;
        const attrs = component.getAttributes();
        var type;

        // Set the component title and icon in the traits panel
        setComponentTitleInTraitsPanel(component);

        if(!! componentAttrs.type) {
            type = componentAttrs.type;

            if (!! componentAttrs.inline) {
                makeSureSelectedClassIsApplied(component, editor);
                // stopBlueOutlineOnTextElementWhenInlineSelected(component, editor);
            } else {
                // Set page section if selected from layers and item is not in current
                // visible page section
                // if (this.pageType !== TYPE_APP_PAGE) {
                    if(type === COMP_LAYOUT_PAGE_SECTION) {
                        selectPageSectionAfterPageSectionSelection.call(this, editor);
                    } else {
                        pageSectionVisibleAfterEditorSelection.call(this, editor);
                    }
                // }
            }
        }

        // Bug fix to show toolbar on text components
        // Bug fix to hide default traits on text
        // Bug fix to stop rte toolbar being where is should not
        // if(type === COMP_TEXT_BLOCK) {
        //     // unHideToolbar(editor.RichTextEditor.getToolbarEl());
        //     // hideDefaultTraitsForTextComponent();
        //     // contentEditableIsSet(component, editor);
        //     // restorePreviousRTESelection.call(this, component, editor);
        // }

        // Show / hide items button in view panel
        toggleComponentItemsPanelButton(editor, (
            component.get('hasComponentItems') &&
            (attrs.itemUUID !== null && attrs.itemUUID !== undefined)
        ));

        // Cet active panel
        setActivePanelOnComponentSelected.call(this, editor, component);
    });
}

// On editor block drag stop
const editorOnBlockDragStop = function(editor) {
    editor.on('block:drag:stop', (component, block) => {
        // Unset the dragged block
        this.draggedBlock = null;

        // Auto select the dropped block
        if (!! component) {
            const componentAttrs = component.attributes;
            const type = componentAttrs.type;

            // Re-render accordion on drop of row
            if(
                type === COMP_LAYOUT_ROW ||
                type === COMP_MEDIA_VIDEO ||
                type === COMP_MEDIA_IMAGE_SLIDER
            ) {
                const parent = component.parent();

                // Stop component sitting before the accordion header
                if(parent.attributes.type === COMP_LAYOUT_ACCORDION) {
                    parent.view.render();
                }
            }

            editor.select(component);
        }
    });
}

// On editor canvas clear
const editorOnCanvasClear = function(editor) {
    editor.on('run:core:canvas-clear', () => clearPage.call(this, editor));
}

// On block drag start
const editorOnBlockDragStart = function(editor) {
    editor.on('block:drag:start', (block, options) => {
        // Set the block that is being dragged. Used by dropzones
        this.draggedBlock = block;
    });
}

// On editor comp deselect
const editorOnComponentDeselect = function(editor) {
    editor.on('component:deselected', (component, options:any) => {
        const attrs = component.attributes;

        // Bug fix to hide toolbar on text components
        // Needed to stop rte toolbar being where is should not
        // if(!! attrs.type && attrs.type === COMP_TEXT_BLOCK) {
        //     hideToolbar(editor.RichTextEditor.getToolbarEl());
        //     contentEditableUnset(component, editor);
        // }
    });
}

// On editor on comp remove
const editorOnComponentRemove = function(editor) {
    editor.on('component:remove', (component, options:any) => {
        const attrs = component.getAttributes();

        if (!! attrs.uuid) {
            editor.Commands.run(CMD_REMOVE_FROM_PAGE_FORM_ITEMS, {
              formGroup: component.attributes.type, uuid: attrs.uuid
            });

            editor.Commands.run(CMD_REMOVE_FROM_PAGE_ITEM_PERMS, { uuid: attrs.uuid });
        }
        if (component.get('hasComponentItems')) {
            // Hide items button in view panel
            toggleComponentItemsPanelButton(editor, false);
        }
    });
}

// On editor set page section on editor load
const editorOnLoadSetPageSection = function(editor) {
    // If page empty then add a page section
    if (this.pageSections.length === 0) {
        const pageSectionTagName = paramCase(COMP_LAYOUT_PAGE_SECTION);
        editor.setComponents(`<${pageSectionTagName}></${pageSectionTagName}>`);
    }
    if (this.pageType === TYPE_APP_PAGE) {
        selectFirstPageSection.call(this, editor);
    } else {
        markFirstPageSectionAsSelected.call(this);
    }
}

// On editor listen to editor window events
const editorOnLoadListenEvents = function(editor) {
    const Canvas = editor.Canvas;
    const editorDocument = Canvas.getDocument();
    const editorWindow = Canvas.getWindow();
    // Vars used to fire resize events after the window has finished resizing
    var rtime;
    var timeout = false;
    var delta = 200;
    const resizeend = () => {
        const date:any = new Date();
        if (date - rtime < delta) {
            setTimeout(resizeend, delta);
        } else {
            timeout = false;
            // Rerender canvas, helps with things like videos haveing correct aspect ratio
            rerenderCanvas.call(this, editor);
        }
    }

    editorDocument.addEventListener('selectionchange', () => {
        this.lastSelection = editorDocument.getSelection();
    });

    // Listen to window resize events
    editorWindow.addEventListener('resize', () => {
        rtime = new Date();
        if (timeout === false) {
            timeout = true;
            setTimeout(resizeend, delta);
        }
    });
}