import { Component, OnInit } from '@angular/core';
import { State } from '@app/state';
import { Environment } from '@environments/environment';
import { UploadService } from '@app/services/upload.service';

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit
{
  public user: any = false;
  public secureUrl:any = null;
  public imageUrl:string = null;

  constructor(private state: State) {}

  ngOnInit() {
    this.state.subscription().subscribe((state) => {
      if (!! state.user) {
        this.user = state.user;
        if(this.user.avatar.includes('/img/default-avatar.jpg')) {
          this.imageUrl = this.user.avatar;
        } else {
          const avatar = this.user.avatar.endsWith('/') ? this.user.avatar.slice(0, -1) : this.user.avatar;
          this.imageUrl = `${avatar}/-/preview/-/resize/60x60/`;
        }
      }
      if (!! state.ccSettings) {
        this.secureUrl = state.ccSettings.uploadcare.secure;
      }
    });
  }

  public getUserFirstName(): string {
    return this.user.firstname;
  }

  public getUserLastName(): string {
    return this.user.lastname;
  }

  public getUserUUID(): string {
    return this.user.uuid;
  }
}
