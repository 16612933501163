import { Component, Injectable, Input, OnInit, ViewChild, DoCheck } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { DocumentService } from '@services/document.service';
import { SidebarService } from '@services/sidebar.service';
@Component({
  selector: 'document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: [
    './document-upload.component.scss',
    '../../../elements/avatar-upload/avatar-upload.component.scss'
  ],
  providers: [SidebarService],
})
@Injectable()
export class DocumentUploadComponent extends BaseComponent implements OnInit, DoCheck {

  @Input() src = '';
  @Input() uuid = '';
  @ViewChild('uploadcare', {static: false}) child;

  public uploading = false;
  public percentComplete = '20%';
  public backgroundImageStyle = '';

  public document: object = {};
  public baseUrl: string = '';

  constructor(
    public activatedRoute: ActivatedRoute,
    public documentService: DocumentService,
    public sideBarService: SidebarService,
    public state: State,
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sideBarService: sideBarService,
      state: state,
      modelService: documentService
    });
    this.setPageVars({
      sideBar: 'documents',
    });
    const settings = this.state.get('ccSettings');
    this.baseUrl = settings.uploadcare.url;
    this.modelServiceGetOne(this.routeParams['id'], 'document');
  }

  ngOnInit(): void {
    this.setPreviewImage();
  }

  ngDoCheck(): void {
    this.setPreviewImage();
  }

  setPreviewImage() {
    this.backgroundImageStyle = `url(` + this.src + `)`;
  }

  uploadComplete(payload) {
    this.percentComplete = '0%';
    this.uploading = false;
    const fileUUID = payload.uuid;
    this.src = `${this.baseUrl}{fileUUID}/-/preview/-/resize/350x/`;
    this.setPreviewImage();
    this.state.message({ 'avatarUUID': fileUUID });
    this.documentService.create([{
      'filename': payload.uuid,
      'filetype': payload.sourceInfo.file.type,
      'file_family': payload.sourceInfo.file.type,
      'filesize': payload.size,
      'name': payload.name
    }]).subscribe(result => {
      console.log('upload care was called');
    });

    try {

    } catch (error) {
      // this will always happen
    }
  }

  uploadProgress(payload) {

    if (payload.state === 'uploaded') {
      setTimeout(() => {
        this.uploadComplete(payload.incompleteFileInfo);
      }, 2000);
      return;
    }

    if (payload.state === 'ready') {
      return false;
    }

    if (payload.state === 'error') {
      this.uploading = false;
      console.log('something went wrong!');
      return;
    }

    this.uploading = true;
    this.percentComplete = Math.max(20, Math.ceil(payload['progress'] * 100)) + '%';
  }

  openDialogue() {
    this.child.openDialog();
  }

}
