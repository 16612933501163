export const EVENTS_FILTER_FIELDS = [
  {
    type: 'text',
    name: 'name',
    label: 'Title',
    value: ''
  },
  {
    type: 'dropdown',
    name: 'event_category_uuid',
    label: 'All Categories',
    value: '',
    apiUrl: 'event-categories/list'
  },
  {
    type: 'dropdown',
    name: 'calendar_uuid',
    label: 'All Calendars',
    value: '',
    apiUrl: 'calendars/list'
  },
  {
    type: 'dropdown',
    name: 'location_uuid',
    label: 'All Locations',
    value: '',
    apiUrl: 'locations/list'
  },
  {
    type: 'date',
    name: 'start_time',
    label: 'Start Date',
    value: ''
  },
  {
    type: 'dropdown',
    name: 'date_match',
    label: 'Exact Date',
    value: '',
    options: [
      {
        text: 'Exact Date',
        value: 'exact'
      },
      {
        text: 'Before',
        value: 'before'
      },
      {
        text: 'On and Before',
        value: 'onandbefore'
      },
      {
        text: 'After',
        value: 'after'
      },
      {
        text: 'On and After',
        value: 'onandafter'
      }
    ]
  }
  //
  // {
  //   type: 'date',
  //   name: 'end_time',
  //   label: 'End Date',
  //   value: ''
  // }
];
