import { Component, forwardRef, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatPaginator, MatSort, MatSortable, MatTableDataSource } from '@angular/material';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { ApiDataService } from '@services/api-data.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Group } from '../../models/group';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'core-form-advanced-select',
  templateUrl: './core-form-advanced-select.component.html',
  styleUrls: ['./core-form-advanced-select.component.scss'],
  providers: [ApiDataService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreFormAdvancedSelectComponent),
      multi: true
    }
  ]
})

export class CoreFormAdvancedSelectComponent implements OnInit
{
  @Input() allowMultiple = true;
  @Input() apiDataSource = '';
  @Input() cols: any;
  @Input() dateColumns: string[] = [];
  @Input() groupByColumns: string[] = [];
  @Input() heading = '';
  @Input() name = '';
  @Input() oneToManyColumns: string[] = [];
  @Input() primaryKey = 'uuid';
  @Input() resultsPerPage = 20; // default page
  @Input() resultsSizeOptions: number[] = [10, 20, 50, 100];
  @Input() selected: any;
  @Input() selectedResult: Array<any> = [];
  @Input() showGroupRowsOnLoad = true;
  @Input() showSearch = true;
  @Input() showSearchStartEndDates = false;
  @Input() showSortHeaders = true;
  @Input() sortBy: string = null;
  @Output() shareApiDataEvent = new EventEmitter<Array<any>>();

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  private currentSearchEndDate: any = null;
  private currentSearchQuery: string = null;
  private currentSearchStartDate: any = null;
  private searchEndDateSubject$ = new Subject<string>();
  private searchQuerySubject$ = new Subject<string>();
  private searchStartDateSubject$ = new Subject<string>();

  public config = GlobalUtil.Configuration;
  public dataLoaded = false;
  public dataSource = new MatTableDataSource<any | Group>([]);
  public originalDataSource: any;
  public displayedColumns: any;
  public filterValue: string;
  public groups = [];
  public groupToExpanded: any;

  constructor(private apiDataService: ApiDataService) {
  }

  ngOnInit(): void {

    this.searchQuerySubject$
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      ).subscribe((queryValue: string) => {
        this.currentSearchQuery = queryValue;
        this.filterDataSource();
      });

    this.searchStartDateSubject$
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      ).subscribe((startDateValue: string) => {
        this.currentSearchStartDate = startDateValue;
        this.filterDataSource();
      });

    this.searchEndDateSubject$
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      ).subscribe((endDateValue: string) => {
        this.currentSearchEndDate = endDateValue;
        this.filterDataSource();
      });

    this.displayedColumns = this.cols.map(c => c.columnDef);

    if (this.apiDataSource !== '' && typeof this.apiDataSource !== 'undefined') {
      this.getApiContent();
    }
  }

  public refreshApiData() {
    this.getApiContent();
  }

  private filterDataSource(): void {
    if (this.showSearchStartEndDates) {
      this.dataSource.filter = 'filter-name-start-date-end-date';
    } else {
      this.dataSource.filter = this.currentSearchQuery;
    }
  }

  private getApiContent(): void {
    this.apiDataService.getOptions(this.apiDataSource, '').subscribe(
      (result: any) => {
        this.shareApiDataEvent.emit(result);
        let data: any;
        if (this.apiDataSource.indexOf('/') > 0) {
          data = result;
        } else {
          data = result.data;
        }
        let output = '';
        if (this.oneToManyColumns.length > 0) {
          data.forEach(el => {
            // loop through all the one to many columns
            this.oneToManyColumns.forEach(col => {
              // loop through all the value and combine their name property
              if (!! el[col]) {
                el[col].forEach(cat => {
                  output = `${output}${cat.name}, `;
                });
              }
              // Remove the last "," & space from the output string.
              output = output.length > 0 ? output.slice(0, -2) : output;
              el[col] = output;
              output = '';
            });
          });
        }

        // loop through the existing data and mark the selected to tick.
        if (this.groupByColumns.length === 0) {
          data.forEach(el => {
            el['selected'] = false;
            if (this.selected.includes(el[this.primaryKey])) {
              el['selected'] = true;
            }
          });
          data = data.sort((a, b) => b.selected - a.selected);
          this.dataSource = new MatTableDataSource(data);
        } else {
          this.dataSource = new MatTableDataSource(this.addGroups(data, this.groupByColumns));
          this.dataSource.filter = 'group-expanded';
        }

        // need this method for sorting on date column.
        this.dataSource.sortingDataAccessor = (item, property) => {
          if (this.dateColumns.includes(property)) {
            return new Date(item[property]).getTime();
          } else {
            return item[property];
          }
        };

        this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
        this.dataSource.paginator = this.paginator;

        if (this.sortBy) {
          const sortBy:any = this.sortBy.split('|');
          this.sort.sort(({ id: sortBy[0], start: sortBy[1] }) as MatSortable);
        }

        this.dataSource.sort = this.sort;
        this.dataLoaded = true;
      }
    );
  }

  public groupHeaderClick(row) {
    row.expanded = !row.expanded;
    this.groupToExpanded = row;
    this.dataSource.filter = 'group-expanded';
  }

  public applySearchNameFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    if (filterValue === '') {
      filterValue = null;
    }
    this.searchQuerySubject$.next(filterValue);
  }

  public applySearchStartDateFilter(dateInput: any): void {
    const date:any = new Date(dateInput.value).getTime();
    this.searchStartDateSubject$.next(date);
  }

  public applySearchEndDateFilter(dateInput: any): void {
    const date:any = new Date(dateInput.value);
    date.setHours('23');
    date.setMinutes('59');
    date.setSeconds('59');
    this.searchEndDateSubject$.next(date.getTime());
  }

  public getSelectedId() {
    for (let i = 0; i < this.dataSource.data.length; i++) {
      const el = this.dataSource.data[i];
      if (el['selected'] === true) {
        if (this.allowMultiple === false) {
          return el['uuid'];
        }
      }
    }
  }

  public getSelectedIds() {

    // This solves issue of data not being loaded before parent form gets the selected values
    if(! this.dataLoaded) {
      return this.selected;
    }

    this.selectedResult = [];
    this.dataSource.data.forEach(el => {
      if (el instanceof Group) { return false; }
      if (el['selected'] === true) {
        this.selectedResult.push(el[this.primaryKey]);
      }
    });
    return this.selectedResult;
  }

  public selectedRow(row): void {
    let totalSelected = 0;
    row['selected'] = !row['selected'];
    this.dataSource.data.forEach(el => {
      if (!(el instanceof Group) && el['group-name'] === row['group-name'] && el['selected']) {
        totalSelected++;
      }
    });
    this.dataSource.data.forEach(el => {
      if (el instanceof Group && el['name'] === row['group-name']) {
        el['count-selected'] = totalSelected;
      }
    });
  }

  public onSelectionChanged(row) {
    // reset all the existing rows
    this.dataSource.data.forEach(el => {
      if (el instanceof Group) { return false; }
      if (el['selected'] === true) {
        el['selected'] = false;
      }
    });
    row['selected'] = true;
  }

  public selectAllOptionsInGroup(e, grpupName) {
    this.dataSource.data.forEach(el => {
      if (el instanceof Group && el['name'] === grpupName) {
        el['select-all'] = e.checked;
        el['count-selected'] = e.checked ? el['count-total'] : 0;
      }
      if (!(el instanceof Group) && el['group-name'] === grpupName) {
        el['selected'] = e.checked;
      }
    });
  }

  public isGroup(index, item): boolean {
    return item.level;
  }

  public getDateCol(columnDef): boolean {
    return this.dateColumns.includes(columnDef) ? true : false;
  }

  public getOneToManyCol(columnDef): boolean {
    return this.oneToManyColumns.includes(columnDef) ? true : false;
  }

  public data(): any {
    this.selectedResult = [];
    this.dataSource.data.forEach(el => {
      if (el instanceof Group) { return false; }
      if (el['selected'] === true) {
        this.selectedResult.push(el[this.primaryKey]);
      }
    });
    return this.selectedResult;
  }

  private customFilterPredicate(data: any | Group, filter: string): boolean {
    if (filter === 'group-expanded') {
      if (this.showGroupRowsOnLoad || this.dataLoaded) {
        if (data instanceof Group) {
          return data.visible;
        } else {
          return this.getDataRowVisible(data);
        }
      } else {
        if (data instanceof Group) {
          data.expanded = false;
        }
        return (data instanceof Group) ? true : false;
      }
    } else if(filter === 'filter-name-start-date-end-date') {
      return this.getFilterNameStartDateEndDateDataRows(data);
    } else {
      return this.getFilterDataRows(data, filter);
    }
  }

  private getDataRowVisible = (data: any) => {
    if (this.groupToExpanded !== undefined) {
      return (data['group-name'] === this.groupToExpanded.name && this.groupToExpanded.expanded);
    } else {
      return false;
    }
  }

  private addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    const res = this.getSublevel(data, 0, groupByColumns, rootGroup);
    // Select the selected items
    res.forEach(el => {
      el['selected'] = false;
      if (this.selected.includes(el[this.primaryKey])) {
        el['selected'] = true;
        const selectGroup = this.groups.filter((g) => {
          return g.name === el['group-name'];
        });
        if (selectGroup.length > 0) {
          selectGroup[0]['count-selected']++;
        }
      }
    });
    return res;
  }

  private getFilterDataRows = (data: any, filter: string) => {
    let match = false;
    this.displayedColumns.forEach(column => {
      let val = data[column] + '';
      val = val.toLowerCase();
      if (val.includes(filter)) {
        match = true;
        return match;
      }
    });
    return match;
  }

  private getFilterNameStartDateEndDateDataRows = (data: any) => {
    let match: boolean = false;
    this.displayedColumns.forEach(column => {
      if (this.currentSearchQuery === null) {
        match = true;
      } else {
        let val = data[column] + '';
        val = val.toLowerCase();
        if (val.includes(this.currentSearchQuery)) {
          match = true;
        }
      }
      if (column === 'start_time' || column === 'end_time') {
        let date = new Date(data[column]).getTime();
        if (this.currentSearchStartDate !== null && this.currentSearchEndDate === null) {
          match = date >= this.currentSearchStartDate;
        }
        if (this.currentSearchStartDate === null && this.currentSearchEndDate !== null) {
          match = date <= this.currentSearchEndDate;
        }
        if (this.currentSearchStartDate !== null && this.currentSearchEndDate !== null) {
          match = date >= this.currentSearchStartDate && date <= this.currentSearchEndDate
        }
      }
      return match;
    });
    return match;
  }

  private getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    // Recursive function, stop when there are no more levels.
    if (level >= groupByColumns.length) {
      return data;
    }
    this.groups = this.uniqueBy(
      data.map(
        row => {
          const result = new Group();
          result.level = level + 1;
          result.parent = parent;
          for (let i = 0; i <= level; i++) {
            result[groupByColumns[i]] = row[groupByColumns[i]];
          }
          return result;
        }
      ),
      JSON.stringify);

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    const res = [];
    let counter = 0;
    this.groups.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
      this.groups[counter]['select-all'] = false;
      this.groups[counter]['count-selected'] = 0;
      this.groups[counter]['count-total'] = 0;
      res.push(this.groups[counter]);
      rowsInGroup[level].list.forEach(d => {
        d['group-name'] = this.groups[counter]['name'];
        res.push(d);
      });
      this.groups[counter]['count-total'] = rowsInGroup[level].list.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, this.groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
      counter++;
    });
    return res;
  }

  private uniqueBy(a, key) {
    const seen = {};
    return a.filter(function (item) {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

}
