import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ApiModel } from '@app/models/entities/api-model';
import { State } from '@app/state';
@Component({
  selector: 'folder-list-view',
  templateUrl: './folder-list-view.component.html',
  styleUrls: ['./folder-list-view.component.scss']
})
export class FolderListViewComponent implements OnInit, OnChanges {
  @Input() dsFolder: any;
  @Output() editFolder = new EventEmitter<ApiModel.Folder>();
  @Output() deleteFolder = new EventEmitter<ApiModel.Folder>();
  @Output() deleteSelected = new EventEmitter();
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('matFolderPaginator', {static: false}) private matFolderPaginator: MatPaginator;

  private authUser: any;

  public displayedCols = ['type', 'name', 'created_at', 'created_by', 'user_count',
    'document_count', 'actions'];
  public selectionFolder = new SelectionModel<any>(true, []);

  constructor(private readonly state: State) {}

  ngOnInit() {
    this.authUser = this.state.get('user');
    this.dsFolder.paginator = this.matFolderPaginator;
    this.dsFolder.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dsFolder = changes.dsFolder.currentValue;
    this.dsFolder.paginator = this.matFolderPaginator;
    this.dsFolder.sort = this.sort;
  }

  // Is the logged-in user the owner of this folder / document
  // Used for disabling the Delete button
  // Duplicated from base.component, so needs consolidating at some point
  public isCreatedUser(created_user_uuid) {
    if (created_user_uuid == this.authUser.uuid) {
      return true;
    }
    return false;
  }

  /** The label for the checkbox on the passed row */
  public checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectionFolder.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected() {
    const numSelected = this.selectionFolder.selected.length;
    const numRows = this.dsFolder && this.dsFolder.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle() {
    this.isAllSelected() ? this.selectionFolder.clear() : this.dsFolder.forEach(row => this.selectionFolder.select(row));
  }

  public editItem(item: ApiModel.Folder) {
    if (this.editFolder.observers.length > 0) {
      this.editFolder.emit(item);
    }
  }

  public deleteItem(item: ApiModel.Folder) {
    if (this.deleteFolder.observers.length > 0) {
      this.deleteFolder.emit(item);
    }
  }

  public deleteSelectedItems() {
    if (this.deleteSelected.observers.length > 0) {
      this.deleteSelected.emit();
    }
  }
}
