const BundleDisplayAsSliderOptions = [
    { id: 'sliderCircleThumbNoTitle', title: 'Small circle thumbs, no title' },
    { id: 'sliderSquareThumbNoTitle', title: 'Small square thumbs, no title' },
    { id: 'sliderSmallCard', title: 'Small cards' },
    { id: 'sliderMediumCard', title: 'Medium cards' },
    { id: 'sliderLargeCard', title: 'Full width cards' },
    { id: 'sliderLargeTeaserTile', title: 'Full width teaser tiles' },
];

const BundleDisplayAsListOptions = [
    { id: 'list', title: 'No thumbnail' },
    { id: 'listThumb', title: 'With thumbnail' },
    { id: 'listLargeCard', title: 'Large cards' }
];

export const FIELD_BUNDLE_DISPLAY_AS_DEFAULT = 'sliderMediumCard';
export const FIELD_BUNDLE_DISPLAY_AS_SLIDER_OPTIONS = BundleDisplayAsSliderOptions;
export const FIELD_BUNDLE_DISPLAY_AS_LIST_OPTIONS = BundleDisplayAsListOptions;

export const BundleDisplayAs = function(editor, options:any = {}) {
    return {
        noLabel: true,
        name: options.name,
        templateInput: '',
        createInput({ trait }) {
            const el = document.createElement('div');
            let sliderOptionsHtml = [];
            let listOptionsHtml = [];
            let html = `
<div class="gjs-trt-trait__wrp">
    <div class="gjs-trt-trait gjs-trt-trait--radio-block">
        <h3>Display as a slider</h3>`;
            for (const sliderOption of BundleDisplayAsSliderOptions) {
                sliderOptionsHtml.push(`
<input type="radio" id="${sliderOption.id}" name="displayAs" value="${sliderOption.id}">
<label for="${sliderOption.id}">${sliderOption.title}</label>
`);
            }
            html += sliderOptionsHtml.join('<br>');
            html += `
    </div>
</div>
<div class="gjs-trt-trait__wrp">
    <div class="gjs-trt-trait gjs-trt-trait--radio-block">
        <h3>Display as a list</h3>`;
            for (const listOption of BundleDisplayAsListOptions) {
                listOptionsHtml.push(`
<input type="radio" id="${listOption.id}" name="displayAs" value="${listOption.id}">
<label for="${listOption.id}">${listOption.title}</label>
`);
            }
            html += listOptionsHtml.join('<br>');
            html += `
    </div>
</div>`;
            el.innerHTML = html;
            return el;
        },
        onEvent({ elInput, component, event }) {
            const radioItems = elInput.getElementsByTagName('input');

            for(const radioItem of radioItems) {
                if(radioItem.checked) {
                    component.addAttributes({ displayAs: radioItem.value });
                }
            }
        },
        onUpdate({ elInput, component }) {
            const attrs = component.getAttributes();
            const radioItems = elInput.getElementsByTagName('input');

            for(const radioItem of radioItems) {
                if(radioItem.value === attrs.displayAs) {
                    radioItem.checked = true;
                }
            }
        }
    }
}