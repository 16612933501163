import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, pipe } from 'rxjs';

import { BaseService } from './base.service';
import { Cache } from '@app/cache';
import { State } from '@app/state';
import { Environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService extends BaseService {

  constructor(
    public http: HttpClient,
    public cache: Cache,
    public state: State
  ) {
    super(http, cache);
    this.setVars({
      endPoint: 'event',
      modelTitle: 'Event'
    });
  }

  static eventCache = {};
  static eventRequestCache = {};
  private scheduleCache = {};

  /**
   * Gets the Category list for the sidebar
   */
  getCategories(): Observable<any> {
    const categoriesApiUrl = Environment.apiURL + 'event-categories/list?fields=uuid,name,color';
    return this.http.get<object[]>(categoriesApiUrl);
  }

  /**
   * Gets the Calendar list for the sidebar
   */
  getCalendars(): Observable<any> {
    const calendarsApiUrl = Environment.apiURL + 'calendars/list?fields=uuid,name,color,display_order,periodization';
    return this.http.get<object[]>(calendarsApiUrl);
  }

  getScheduleEvents(
    scheduleUuid: string,
    startDate: Date,
    endDate: Date,
    timezoneUUID: string = null,
    calendarsUUID: Array<any> = null,
    categoriesUUID: Array<any> = null
  ): Observable<Object[]> {
    const startSlug = this.dateSlug(startDate);
    const endSlug = this.dateSlug(startDate);
    let url = Environment.apiURL + `schedules/${scheduleUuid}/events/${startSlug}/${endSlug}`;
    let queries:Array<string> = [];

    if (timezoneUUID || calendarsUUID || categoriesUUID) {
      url += '?';
    }
    if (!! timezoneUUID) {
      queries.push(`timezone_uuid=${timezoneUUID}`);
    }
    if (!! calendarsUUID) {
      queries.push(`calendar_uuid=${calendarsUUID.join(',')}`);
    }
    if (!! categoriesUUID) {
      queries.push(`category_uuid=${categoriesUUID.join(',')}`);
    }
    url += queries.join('&');
    return this.http.get<object[]>(url);
  }

  dateSlug(date: Date) {
    var day:any = date.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  getAllSchedulesEvents(
    startDate: Date,
    endDate: Date,
    timezoneUUID: string = null,
    calendarsUUID: Array<any> = null,
    categoriesUUID: Array<any> = null
  ): Observable<Object[]> {
    const startSlug = this.dateSlug(startDate);
    const endSlug = this.dateSlug(endDate);
    let url = Environment.apiURL + `schedules/admin/events/all/${startSlug}/${endSlug}`;
    let queries:Array<string> = [];

    if (timezoneUUID || calendarsUUID || categoriesUUID) {
      url += '?';
    }
    if (!! timezoneUUID) {
      queries.push(`timezone_uuid=${timezoneUUID}`);
    }
    if (!! calendarsUUID) {
      queries.push(`calendar_uuid=${calendarsUUID.join(',')}`);
    }
    if (!! categoriesUUID) {
      queries.push(`category_uuid=${categoriesUUID.join(',')}`);
    }
    url += queries.join('&');
    return this.http.get<object[]>(url);
  }

  /**
   * Gets events from the API filtered by User UUID
   * @param userUUID
   * @param startDate
   * @param endDate
   * @param timezoneUUID
   */
  getUserScheduleEvents(
    userUUID: string,
    startDate: Date,
    endDate: Date,
    timezoneUUID: string = null,
    calendarsUUID: Array<any> = null,
    categoriesUUID: Array<any> = null
  ): Observable<Object[]> {
    const startSlug = this.dateSlug(startDate);
    const endSlug = this.dateSlug(endDate);
    let url:string = Environment.apiURL + `schedules/admin/user/${userUUID}/${startSlug}/${endSlug}`;
    let queries:Array<string> = [];

    if (timezoneUUID || calendarsUUID || categoriesUUID) {
      url += '?';
    }
    if (!! timezoneUUID) {
      queries.push(`timezone_uuid=${timezoneUUID}`);
    }
    if (!! calendarsUUID) {
      queries.push(`calendar_uuid=${calendarsUUID.join(',')}`);
    }
    if (!! categoriesUUID) {
      queries.push(`category_uuid=${categoriesUUID.join(',')}`);
    }
    url += queries.join('&');
    return this.http.get<object[]>(url);
  }

  /**
   * Gets events from the API filtered by Location UUID
   * @param locationUUID
   * @param startDate
   * @param endDate
   * @param timezoneUUID
   */
  getLocationScheduleEvents(
    locationUUID: string,
    startDate: Date,
    endDate: Date,
    timezoneUUID: string = null,
    calendarsUUID: Array<any> = null,
    categoriesUUID: Array<any> = null
  ): Observable<Object[]> {
    const startSlug = this.dateSlug(startDate);
    const endSlug = this.dateSlug(endDate);
    let url = Environment.apiURL + `schedules/admin/location/${locationUUID}/${startSlug}/${endSlug}`;
    let queries:Array<string> = [];

    if (timezoneUUID || calendarsUUID || categoriesUUID) {
      url += '?';
    }
    if (!! timezoneUUID) {
      queries.push(`timezone_uuid=${timezoneUUID}`);
    }
    if (!! calendarsUUID) {
      queries.push(`calendar_uuid=${calendarsUUID.join(',')}`);
    }
    if (!! categoriesUUID) {
      queries.push(`category_uuid=${categoriesUUID.join(',')}`);
    }
    url += queries.join('&');
    return this.http.get<object[]>(url);
  }

  getEvent(eventUuid: string): Observable<Object> {
    if (ScheduleService.eventCache[eventUuid]) {
        // remember to destroy this cache
        // return an RX observable that instantly resolves 'of' the cached event object
        return of(ScheduleService.eventCache[eventUuid]);
    }
    const categoriesApiUrl = Environment.apiURL + `event/${eventUuid}`;
    const event = this.http.get<object>(categoriesApiUrl);
    ScheduleService.eventRequestCache[eventUuid] = event;
    event.subscribe(e => {
        ScheduleService.eventCache[eventUuid] = e;
    });
    return event;
  }
}
