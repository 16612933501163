import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PermissionService } from '@app/services/permission.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
@Component({
  selector: 'permission-form',
  templateUrl: './permission-form.component.html',
  styleUrls: ['./permission-form.component.scss'],
  providers: [SidebarService]
})

export class PermissionFormComponent extends BaseComponent {
  // Local variables
  permissionFormGroup: FormGroup;
  dataLoaded = false;
  globalUtil = GlobalUtil;
  roleCols = [];
  userCols = [];
  userIds = [];
  roleIds = [];

  @ViewChild('users', {static: false}) private advancedSelectUserInput;
  @ViewChild('roles', {static: false}) private advancedSelectRoleInput;
  // Input parameters

  // Onput events

  // Contractor
  constructor(
    public activatedRoute: ActivatedRoute,
    public permissionService: PermissionService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: permissionService
    });

    this.setPageVars({
      sidebar: ''
    });

    this.initialFormGroupSetup();
    const id = this.routeParams['id'];
    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result: any) => {
        this.dataLoaded = true;
        this.permissionFormGroup.patchValue(result);
        this.populateInitialData(result);
      });
    }

    // Setup the columns settings for the multi-selection table (Roles)
    this.roleCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` },
    ];

    // Setup the columns settings for the multi-selection table (Users)
    this.userCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` },
    ];
  }

  // All the public methods
  get controls(): any {
    return this.permissionFormGroup.controls;
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  public save(): void {
    const id = this.controls.id.value;
    this.permissionFormGroup.patchValue({
      'roles': this.getSelectedRoles(),
      'users': this.getSelectedUsers()
    });

    if (id) {
      // Update the existing row
      this.permissionService.patchById(id, this.permissionFormGroup.value)
        .subscribe(result => {
          this.SavedMessage(result);
        });
    } else {
      // Create the new row
      this.permissionService.create(this.permissionFormGroup.value).subscribe(result => this.SavedMessage(result));
    }

  }

  // All the private methods
  private initialFormGroupSetup(): void {
    // setup the initial form for row entity
    this.permissionFormGroup = this.formBuilder.group({
      id: [null],
      name: [null, [Validators.required, Validators.maxLength(this.globalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      guard_name: 'web',
      roles: [],
      users: []
    });
  }

  private SavedMessage(data) {
    this.state.message({ ['PermissionIndexUpdate']: true });
    this.closeSlideout();
  }

  private populateInitialData(row): void {
    if (row !== undefined) {
      this.selectedIds(row);
    }
  }

  private selectedIds(row): any {
    row.rolesList.forEach(el => {
      this.roleIds.push(el);
    });
    row.users.forEach(el => {
      this.userIds.push(el);
    });
  }

  private getSelectedRoles(): any[] {
    const value: any[] = this.advancedSelectRoleInput.getSelectedIds();
    return value === null ? null : value;
  }

  private getSelectedUsers(): any[] {
    const value: any[] = this.advancedSelectUserInput.getSelectedIds();
    return value === null ? null : value;
  }

}
