import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/state';

import { BaseComponent } from '@components/base.component';

@Component({
  selector: 'vantage-points-form',
  templateUrl: './vantage-points-form.component.html',
  styleUrls: ['./vantage-points-form.component.scss']
})

@Injectable()
export class VantagePointsFormComponent extends BaseComponent implements OnInit {

  private _url:string = 'http://sportsology-vantage-points.web.app/form/embed/';
  private _formId:string = '';
  private _productId:string = '';
  private _userUUID:string = '';
  private _user:any = null;
  private _userData:any = {};
  public dataReady:boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    public activatedRoute: ActivatedRoute,
    public state: State,
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      state: state,
    });
  }

  ngOnInit(): void {
    this._user = this.state.get('user');
    this._userData.firstname = this._user.firstname;
    this._userData.lastname = this._user.lastname;
    this._userData.email = this._user.email;
    this._userUUID = this._user.uuid;
    this._formId = this.routeParams['formId'];
    this._productId = this.state.get('productId');
    (window as any).location = this.url();
  }

  url() {
    return `${this._url}${this._productId}/${this._formId}/${this._userUUID}/${btoa(JSON.stringify(this._userData))}`;
    // const url = this.sanitizer.bypassSecurityTrustResourceUrl(`${this._url}${this._formId}/${this._userUUID}`);
  }
}