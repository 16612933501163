import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
  selector: 'core-form-rich-text',
  templateUrl: './core-form-rich-text.component.html',
  styleUrls: ['./core-form-rich-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreFormRichTextComponent),
      multi: true
    }
  ]
})
export class CoreFormRichTextComponent implements ControlValueAccessor {

  // Uses TinyMCE legacy. For docs, see: https://github.com/tinymce/tinymce-angular/tree/legacy
  // This API key is restricted to certain domains, so if it's not working ... this could be why
  @Input() apiKey = 'ld0utlsqklwyb4uvs4s0j11buiuvgx6211p5gqfetoe6cggn';
  @Input() label = '';
  @Input() hideLabel = false;
  @Input() height = 300;
  @Input() initialValue?: any = null;
  // tslint:disable-next-line: no-input-rename
  @Input('value') val: string;
  @Input('init') _init?: any = null;

  // Both onChange and onTouched are functions
  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this.val;
  }

  get init() {
    var init;

    if (this._init) {
      this._init.height = this.height;
      init = this._init;
    } else {
      init = {
        content_style: 'body { font-size: 14px; font-family: Overpass; }',
        height: this.height,
        menubar: false,
        plugins: 'lists',
        statusbar: false,
        toolbar:
          'undo redo | formatselect | removeformat | bold italic | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist | indent outdent | \
          '
      }
    }
    init.icons = 'material';
    init.branding = false;

    return init;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  // We implement this method to keep a reference to the onChange callback function passed by the forms API
  registerOnChange(fn) {
    this.onChange = fn;
  }

  // We implement this method to keep a reference to the onTouched callback function passed by the forms API
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // This is a basic setter that the forms API is going to use
  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }
}
