import { fixToolbarPositinoOnComponentHeightChange } from './../../utils';
import { paramCase } from 'change-case';
import { inlineImageOnRender } from './../buttons/shared';

import {
  COMP_MEDIA_INLINE_IMAGE
} from './../../../const/components';

import { FIELD_IMAGE_SELECTOR } from './../../../const/traits';

const compParamName = paramCase(COMP_MEDIA_INLINE_IMAGE);

export const InlineImage = (editor, options:any = {}) => {
  return {
    isComponent: el => el.tagName === compParamName.toUpperCase(),
    // Model
    model: {
      defaults: {
        tagName: compParamName,
        name: 'Image',
        badgeable: false,
        resizable: false,
        editable: false,
        draggable: false,
        droppable: false,
        inline: true,
        attributes: {
          image_uuid: null,
          image_details: null,
          float_item: 'none',
          contenteditable: 'false'
        },
        traits:[{
          type: 'select',
          name: 'float_item',
          label: 'Float item',
          options: [
            { id: 'none', label: 'None' },
            { id: 'left', label: 'Left' },
            { id: 'right', label: 'Right' }
          ]
        },
        FIELD_IMAGE_SELECTOR]
      }
    },
    // View
    view: {
      tagName: compParamName,
      // Init
      init() {
        // editor.select(this.model);
        this.listenTo(this.model, 'change:attributes', this.handleAttributeChanges);
      },
      // Attribute changes handler
      handleAttributeChanges() {
        this.render();
        // fixToolbarPositinoOnComponentHeightChange(editor);
      },
      // On render
      onRender() {
        inlineImageOnRender.call(this, editor);
      }
    }
  }
}