import { CMD_OPEN_ITEM_PERMS_SELECTOR } from './../../const/commands';

export const TextboxTrait = function(editor, options:any = {}) {
    return {
        name: options.name,
        createInput({ trait }) {
            const el = document.createElement('textarea');

            el.setAttribute('name', trait.attributes.name);
            return el;
        },
        onEvent({ elInput, component, event }) {
            component.addAttributes({ [elInput.name]: elInput.value });
        },
        onUpdate({ elInput, component }) {
            const attrs = component.getAttributes();
            elInput.value = attrs[elInput.name];
        }
    }
}