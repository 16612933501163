import { Component, Injectable, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiModel } from '@app/models/entities/api-model';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { UserService } from '@services/user.service';
import * as Moment from 'moment';
@Component({
  selector: 'user-form-legal-document',
  templateUrl: './user-form-legal-document.component.html',
  styleUrls: ['./user-form-legal-document.component.scss'],
  providers: [SidebarService]
})

@Injectable()
export class UserFormLegalDocumentComponent extends BaseComponent implements OnInit {

  @Input() hideSidebar = false;
  @Input() userLegalDocuments: ApiModel.User;

  userFormGroup: FormGroup;
  userProfileFormGroup: FormGroup;
  dataLoaded = false;
  formSubmitted = false;

  private config = GlobalUtil.Configuration;
  constructor(
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: userService
    });

    this.setPageVars({
      sidebar: 'User Profile',
    });
    // Initial the From Group of user profile.
    this.initialFormGroup();
  }

  ngOnInit(): void {
    if (this.userLegalDocuments) {
      this.userFormGroup.patchValue(this.userLegalDocuments);
      this.dataLoaded = true;
    }
  }

  public save(): void {
    const uuid = this.userFormGroup.controls.uuid.value;
    this.formSubmitted = true;
    if (uuid) {
      // change the date format
      this.userProfileFormGroup.patchValue({
        has_ehic: this.controls.has_ehic.value === true ? '1' : '0',
        passport_issue_date: this.changedDateFormat(this.controls.passport_issue_date.value, this.config.SHORT_DATE_FORMAT_DISPLAY),
        passport_expiry_date: this.changedDateFormat(this.controls.passport_expiry_date.value, this.config.SHORT_DATE_FORMAT_DISPLAY),
      });
      // Update the user profile
      this.userService.patchById(uuid, this.userFormGroup.value)
        .subscribe(result => {
      });
    }
    setTimeout (() => {
      this.formSubmitted = false;
    }, 3000);
  }

  public get controls(): any {
    return this.userProfileFormGroup.controls;
  }

  public setIssueDate(dateValue: string) {
    console.log('setIssueDate: ' + dateValue);
    this.userProfileFormGroup.patchValue({
      passport_issue_date: dateValue
    });
  }

  public setExpiryDate(dateValue: string) {
    console.log('setExpiryDate: ' + dateValue);
    this.userProfileFormGroup.patchValue({
      passport_expiry_date: dateValue
    });
  }

  private initialFormGroup(): void {
    this.userProfileFormGroup = this.formBuilder.group({
      id: [null],
      passport_number: [null],
      passport_surname: [null],
      passport_given_names: [null],
      passport_issue_date: [null],
      passport_expiry_date: [null],
      passport_country_uuid: [null],
      passport_place_of_birth: [null],
      has_ehic: [null],
      address : [null],
      town_city : [null],
      postcode_zip : [null],
      county_state : [null],
      address_country_id : [null]
    });

    this.userFormGroup = this.formBuilder.group({
      uuid: [null],
      profile: this.userProfileFormGroup
    });
  }

  private changedDateFormat(date, format) {
    console.log('date: ' + date);
    if (date === null) {
      return null;
    } else {
      return Moment(date).format(format);
    }
  }
}
