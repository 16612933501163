import { openImport } from './openImport';

import {
  // Interface
  CMD_PANEL_CLEAR,
  CMD_PANEL_DEVICE_DESKTOP,
  CMD_PANEL_DEVICE_TABLET,
  CMD_PANEL_DEVICE_MOBILE,
  CMD_PANEL_IMPORT,
  // Canvas
  CMD_CANVAS_ITEM_SCROLL_LEFT,
  CMD_CANVAS_ITEM_SCROLL_RIGHT
} from './../../const/commands';

import {
  MSG_CLEAR_CANVAS_CONFIRM
} from './../../const/messages';

// Initialise Commands
export const Commands = (editor, config) => {

  const Commands = editor.Commands;

  // Interface
  Commands.add(CMD_PANEL_IMPORT, openImport(editor, config));
  Commands.add(CMD_PANEL_DEVICE_DESKTOP, editor => editor.setDevice('Desktop'));
  Commands.add(CMD_PANEL_DEVICE_TABLET, editor => editor.setDevice('Tablet'));
  Commands.add(CMD_PANEL_DEVICE_MOBILE, editor => editor.setDevice('Mobile portrait'));
  Commands.add(CMD_PANEL_CLEAR, editor =>
    confirm(MSG_CLEAR_CANVAS_CONFIRM) && editor.runCommand('core:canvas-clear')
  );

  Commands.add(CMD_CANVAS_ITEM_SCROLL_LEFT, editor => {
    const model = editor.getSelected();
    const el = model.getEl();
    el.scrollLeft = el.scrollLeft + 100;
    el.scrollRight = el.scrollRight - 100;
  });
  Commands.add(CMD_CANVAS_ITEM_SCROLL_RIGHT, editor => {
    const model = editor.getSelected();
    const el = model.getEl();
    el.scrollLeft = el.scrollLeft - 100;
    el.scrollRight = el.scrollRight + 100;
  });
}