import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Cache } from '@app/cache';

@Injectable({
  providedIn: 'root'
})
export class UserCircleService extends BaseService {
  private userCircleCache = [];

  constructor(public http: HttpClient, public cache: Cache) {
    super(http, cache);
    this.setVars({
      endPoint: 'user-circle',
      modelTitle: 'UserCircle'
    });
  }

  getUserCiclesList() {
    return this.getFromApi('user-circles/list');
  }

  getUserCiclesWithMembersList() {
    return this.getFromApi('user-circles/users/list?include_associated_users=true');
  }
}
