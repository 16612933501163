import { syncInlineContent } from './../utils';
import { paramCase } from 'change-case';

import { iconElement } from './../utils';

// import { CMD_OPEN_INLINE_PAGE_ITEM_SELECTOR } from './../../const/commands';

import {
    COMP_TEXT_BLOCK,
    // COMP_TEXT_INLINE_ITEM,
    // COMP_LOREM_IPSUM
} from './../../const/components';

// Bug fix for not selecting inserted inline items correctly
const selectInsertedItem = editor => {
    setTimeout(() => {
        const textComponent = editor.getSelected();
        const inserted = textComponent.find('[justinserted="true"]')[0];

        editor.select(inserted);

        const el = inserted.getEl();
        el.removeAttribute('justinserted');
    }, 0);
}

export const RTE = (editor, opts:any = {}) => {

    const RichTextEditor = editor.RichTextEditor;

    RichTextEditor.remove('link');
    RichTextEditor.remove('bold');
    RichTextEditor.remove('italic');
    RichTextEditor.remove('underline');
    RichTextEditor.remove('strikethrough');

    RichTextEditor.add('bold', {
        icon: iconElement('format_bold'),
        attributes: { title: 'Bold' },
        result: rte => rte.exec('bold'),
    });

    RichTextEditor.add('italic', {
        icon: iconElement('format_italic'),
        attributes: { title: 'Italic' },
        result: rte => rte.exec('italic'),
    });

    RichTextEditor.add('underline', {
        icon: iconElement('format_underline'),
        attributes: { title: 'Underline' },
        result: rte => rte.exec('underline'),
    });

    RichTextEditor.add('unorderedList', {
        icon: iconElement('format_list_bulleted'),
        attributes: { title: 'Unordered List' },
        result: rte => rte.exec('insertUnorderedList')
    });

    RichTextEditor.add('orderedList', {
        icon: iconElement('format_list_numbered'),
        attributes: { title: 'Ordered List' },
        result: rte => rte.exec('insertOrderedList')
    });

    RichTextEditor.add('textType', {
        icon: `
${iconElement('title')}&nbsp;
<select id="rte-text-type" class="gjs-field">
    <option value="p">Paragraph</option>
    <option value="h1">H1</option>
    <option value="h2">H2</option>
    <option value="h3">H3</option>
    <option value="h4">H4</option>
    <option value="blockquote">Blockquote</option>
</select>`,
        attributes: { title: 'Element Type' },
        event: 'change',
        result: (rte, action) => {
            const select:any = document.getElementById('rte-text-type');
            const textType = select.options[select.selectedIndex].value;;

            rte.exec('formatBlock', textType);
        }
    });

    RichTextEditor.add('fontSize', {
        icon: `
${iconElement('format_size')}&nbsp;
<select id="rte-font-size" class="gjs-field">
    <option value="1">10</option>
    <option value="2">12</option>
    <option value="3">18</option>
    <option value="4">22</option>
    <option value="5">26</option>
    <option value="6">32</option>
    <option value="7">72</option>
</select>`,
        attributes: { title: 'Font Size' },
        event: 'change',
        result: (rte, action) => {
            const select:any = document.getElementById('rte-font-size');
            const fontSize = select.options[select.selectedIndex].value;

            rte.exec('fontSize', fontSize)
        },
        update: (rte, action) => {
            const select:any = document.getElementById('rte-font-size');
            const fontSize = rte.doc.queryCommandValue('FontSize');

            select.value = !! fontSize ? fontSize : 1;
        }
    });

    RichTextEditor.add('justifyContent', {
        icon: `
${iconElement('format_align_justify')}&nbsp;
<select id="rte-justify-content" class="gjs-field">
    <option value="Left">Left</option>
    <option value="Center">Center</option>
    <option value="Right">Right</option>
    <option value="Full">Full</option>
</select>`,
        attributes: { title: 'Justify' },
        event: 'change',
        result: (rte, action) => {
            const select:any = document.getElementById('rte-justify-content');
            const justify = select.options[select.selectedIndex].value;
            const command = `justify${justify}`;

            rte.exec(command);
        },
        update: (rte, action) => {
            const select:any = document.getElementById('rte-justify-content');

            if (rte.doc.queryCommandState('JustifyLeft')) {
                select.value = 'Left';
            };
            if (rte.doc.queryCommandState('JustifyRight')) {
                select.value = 'Right';
            };
            if (rte.doc.queryCommandState('JustifyCenter')) {
                select.value = 'Center';
            };
            if (rte.doc.queryCommandState('JustifyFull')) {
                select.value = 'Full';
            };
        }
    });

    RichTextEditor.add('insert-item-link', {
        icon: iconElement('double_arrow'),
        attributes: { title: 'Inline Item' },
        result: rte => {
            const selection = rte.selection();
            var insert = '<inline-item justinserted="true"></inline-item>';

            if (
                selection.type === 'Range' &&
                !! selection.focusNode &&
                selection.focusNode.nodeName === '#text'
            ) {
                insert = `<inline-item justinserted="true" name="${selection}"></inline-item>`;
            }

            rte.insertHTML(insert);
            syncInlineContent(editor);
            selectInsertedItem(editor);
        }
    });

    RichTextEditor.add('insert-image', {
        icon: iconElement('image'),
        attributes: { title: 'Inline Image' },
        result: rte => {
            rte.insertHTML('<inline-image-comp justinserted="true"></inline-image-comp>');
            syncInlineContent(editor);
            selectInsertedItem(editor);
        }
    });

    RichTextEditor.add('insert-icon', {
        icon: iconElement('info'),
        attributes: { title: 'Icon' },
        result: rte => {
            rte.insertHTML('<inline-icon justinserted="true"></inline-icon>');
            syncInlineContent(editor);
            selectInsertedItem(editor);
        }
    });

    RichTextEditor.add('insert-url', {
        icon: iconElement('link'),
        attributes: { title: 'Url' },
        result: rte => {
            const selection = rte.selection();
            var insert = '<inline-url justinserted="true"></inline-url>';

            if (
                selection.type === 'Range' &&
                !! selection.focusNode &&
                selection.focusNode.nodeName === '#text'
            ) {
                insert = `<inline-url justinserted="true" name="${selection}"></inline-url>`;
            }

            rte.insertHTML(insert);
            syncInlineContent(editor);
            selectInsertedItem(editor);
        }
    });

    RichTextEditor.add('change-to-new-text-block', {
        icon: iconElement('change_history'),
        attributes: { title: 'Change to new text block' },
        result: rte => {
            const currentTextBlock = editor.getSelected();
            const newTextBlockTagName = paramCase(COMP_TEXT_BLOCK);
            const currentHTML = rte.el.innerHTML;

            currentTextBlock.view.disableEditing();
            const newTextBlock = currentTextBlock.replaceWith(`<${newTextBlockTagName} body="${currentHTML}"></${newTextBlockTagName}>`);
            editor.select(newTextBlock);

        }
    });
}