import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CalendarService } from '@app/services/calendar.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { CoreFormAdvancedSelectComponent } from '@app/modules/common/core/components/form-advanced-select/core-form-advanced-select.component';
import { SportsologySuperUserGuard } from '../../../modules/common/core/components/auth/sportsology-super-user.guard';
@Component({
  selector: 'app-calendar-form',
  templateUrl: './calendar-form.component.html',
  styleUrls: ['./calendar-form.component.scss'],
  providers: [SidebarService]
})
export class CalendarFormComponent extends BaseComponent {
  // Local variables
  calendarFormGroup: FormGroup;
  dataLoaded = false;
  formSubmitted = false;
  isSportsologySuperUser = false;
  globalUtil = GlobalUtil;
  subscribersCols: any;
  ids = [];
  @ViewChild(CoreFormAdvancedSelectComponent, {static: false}) private advancedSelectInput;
  constructor(
    public activatedRoute: ActivatedRoute,
    public calendarService: CalendarService,
    public sidebarService: SidebarService,
    private sportsologySuperUserGuard: SportsologySuperUserGuard,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: calendarService
    });

    this.setPageVars({
      sidebar: ''
    });

    this.initialFormGroupSetup();
    const id = this.routeParams['id'];
    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result: any) => {
        this.populateInitialData(result);
        this.dataLoaded = true;
        this.calendarFormGroup.patchValue(result);
      });
    }

    // Setup the columns settings for the multi-selection table (Subscribers)
    this.subscribersCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` },
    ];

    this.isSportsologySuperUser = this.sportsologySuperUserGuard.userIsSportsologySuperUser();
  }

  get controls(): any {
    return this.calendarFormGroup.controls;
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  public save(): void {
    const id = this.controls.id.value;
    const uuid = this.controls.uuid.value;
    this.formSubmitted = true;
    this.calendarFormGroup.patchValue({
      'subscribers': this.getSelectedSubscribers(),
      'periodization': this.controls.periodization.value ? 1 : 0,
    });

    if (id || uuid) {
      this.calendarService.patchById(uuid ? uuid : id, this.calendarFormGroup.value).subscribe(
        result => this.SavedMessage(result)
      );
    } else {
      this.calendarService.create(this.calendarFormGroup.value).subscribe(
        result => this.SavedMessage(result)
      );
    }

    setTimeout (() => {
      this.formSubmitted = false;
    }, 1000);

  }

  private initialFormGroupSetup(): void {
    this.calendarFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      name: [null, [Validators.required, Validators.maxLength(this.globalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      color: [null, [Validators.required]],
      periodization: [null],
      subscribers: []
    });
  }

  private SavedMessage(data) {
    this.state.message({ ['CalendarIndexUpdate']: true });
    this.closeSlideout();
  }

  private getSelectedSubscribers(): any[] {
    const value: any[] = this.advancedSelectInput.getSelectedIds();
    return value === null ? null : value;
  }

  private populateInitialData(row): void {
    if (row !== undefined) {
      this.selectedIds(row);
    }
  }

  private selectedIds(row): any {
    row.subscribers.forEach(el => {
      this.ids.push(el);
    });
    return this.ids;
  }
}
