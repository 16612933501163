import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  @Input() cancelText = 'Cancel';
  @Input() cancelIcon = 'cancel';
  // classes aren't currently used as they class with main .button styles
  // @Input() cancelButtonClass = 'button-primary';

  @Input() confirmText = 'Delete';
  @Input() confirmIcon = 'delete';
  // @Input() confirmButtonClass = 'button-danger';
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public message: string) {}

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
