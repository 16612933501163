import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { DirectoryCategoryService } from '@services/directory-category.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
@Component({
  selector: 'app-directory-categories-index',
  templateUrl: './directory-categories-index.component.html',
  styleUrls: ['./directory-categories-index.component.scss']
})
export class DirectoryCategoriesIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['featured_image', 'name', 'created_at', 'directory_item_count', 'status', 'actions'];
  public dataLoaded = false;
  public node: Breadcrumb;
  constructor(
    public directoryCategoryService: DirectoryCategoryService,
    public router: Router,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: directoryCategoryService,
      router,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }

  public updatedStatus(): void {
    this.state.message({ ['DirectoryCategoryIndexUpdate']: true });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.nodes = [];
    this.nodes.push(
      {
        label: 'Directory',
        link: 'directory-items',
      },
      {
        label: 'Categories',
        link: 'directory-categories',
      }
    );
  }
}
