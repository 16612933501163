import { Directive, ElementRef } from '@angular/core';
@Directive({
  selector: '[single-line-only]'
})
export class SingleLineOnlyDirective {
  constructor(public element: ElementRef) {
    this.element.nativeElement.addEventListener('keypress', function (evt) {
      if (evt.which === 13) {
        evt.preventDefault();
      }
    });
  }
}
