import {
  FileDefaults,
  Traits
} from './shared';

import { ICON_BUTTON_FILE } from './../../../const/icons';

import { COMP_BUTTON_FILE } from './../../../const/components';

import { FIELD_FILE_SELECTOR } from './../../../const/traits';

import { PageFormLinkedItem } from './../page-form-linked-item';

export const File = (editor, options:any = {}) => {

  (FIELD_FILE_SELECTOR as any).at = 9;

  return PageFormLinkedItem(editor, {
    componentName: COMP_BUTTON_FILE,
    displayIcon: ICON_BUTTON_FILE,
    model: {
      defaults: { ...FileDefaults,
        name: 'File',
        traits: [ ...Traits, FIELD_FILE_SELECTOR]
      }
    }
  });
}