import { truncateString, iconElement } from './../../utils';

import {
    ICON_MISC_EMAIL,
    ICON_MISC_MAP,
    ICON_MISC_PHONE,
    ICON_MISC_URL
} from './../../../const/icons';

import { COMP_LOREM_IPSUM } from './../../../const/components';

const emptyCardElement = (icon, content) => {
    const iconEl = iconElement(icon);

    return `
<span class="card-head">
    ${iconEl}
    <span class="card-title">${COMP_LOREM_IPSUM}</span>
</span>
<div class="card-content">${content}</div>`
}

export const emptyCard = (icon, content) => emptyCardElement(icon, content);

const baseCardElement = function(icon, title, content) {
    const iconEl = iconElement(icon);

    return `
<span class="card-head">
    ${iconEl}
    <span class="card-title">${title}</span>
</span>
<div class="card-content">${content}</div>`;
}

export const baseCard = (icon, title, content) => baseCardElement(icon, title, content);

export const appPageCard = function() {
    const snapshot = this.model.get('snapshot');
    let html = '';

    if (! snapshot) {
        return emptyCardElement(this.model.get('displayIcon'), 'Select an app page');
    }

    const { name, teaser } = snapshot;
    const content = `<p>${truncateString(teaser, 100)}</p>`;

    return baseCardElement(this.model.get('displayIcon'), name, content);
}

export const articleCard = function() {
    const snapshot = this.model.get('snapshot');
    let html = '';

    if (! snapshot) {
        return emptyCardElement(this.model.get('displayIcon'), 'Select an article');
    }

    const { name, teaser } = snapshot;
    const content = `<p>${truncateString(teaser, 100)}</p>`;

    return baseCardElement(this.model.get('displayIcon'), name, content);
}

export const bundleCard = function() {
    const snapshot = this.model.get('snapshot');
    let html = '';

    if (! snapshot) {
        return emptyCardElement(this.model.get('displayIcon'), 'Select a bundle');
    }

    const { title } = snapshot;
    const content = `
<ul>
    <li>Item 1</li>
    <li>Item 2</li>
    <li>Item 3</li>
</ul>`;

    return baseCardElement(this.model.get('displayIcon'), title, content);
}

export const calendarCard = function() {
    const snapshot = this.model.get('snapshot');
    let html = '';

    if (! snapshot) {
        return emptyCardElement(this.model.get('displayIcon'), 'Select a calendar');
    }

    const { name } = snapshot;
    const content = `
<ul>
    <li>Event 1</li>
    <li>Event 2</li>
    <li>Event 3</li>
</ul>`;

    return baseCardElement(this.model.get('displayIcon'), name, content);
}

export const contactGroupCard = function() {
    const snapshot = this.model.get('snapshot');
    let html = '';

    if (! snapshot) {
        return emptyCardElement(this.model.get('displayIcon'), 'Select a contact group');
    }

    const { name } = snapshot;
    const content = `
<ul>
    <li>Person 1</li>
    <li>Person 2</li>
    <li>Person 3</li>
</ul>`;

    return baseCardElement(this.model.get('displayIcon'), name, content);
}

export const DirectoryItemCard = function() {
    const snapshot = this.model.get('snapshot');
    let html = '';

    if (! snapshot) {
        return emptyCardElement(this.model.get('displayIcon'), 'Select a directory item');
    }

    const { name, url, telephone, email, map_url } = snapshot;
    var content = [];

    if (!! url) {
        content.push(`<span class="flex-align">${iconElement(ICON_MISC_URL)} <a href="${url}">${url}</a></span>`);
    }
    if (!! telephone) {
        content.push(`<span class="flex-align">${iconElement(ICON_MISC_PHONE)} <a href="tel:${telephone}">${telephone}</a></span>`);
    }
    if (!! email) {
        content.push(`<span class="flex-align">${iconElement(ICON_MISC_EMAIL)} <a href="tel:${email}">${email}</a></span>`);
    }
    if (!! map_url) {
        content.push(`<span class="flex-align">${iconElement(ICON_MISC_MAP)} <a href="tel:${map_url}">${map_url}</a></span>`);
    }

    return baseCardElement(this.model.get('displayIcon'), name, `<p>${content.join('<br>')}</p>`);
}

export const documentCard = function() {
    const snapshot = this.model.get('snapshot');
    let html = '';

    if (! snapshot) {
        return emptyCardElement(this.model.get('displayIcon'), 'Select a document');
    }

    const { name, file_type, file_size, file_name, description } = snapshot;
    var content = [];

    if (!! file_name) {
        content.push(`<strong>File name:</strong> ${file_name}`);
    }
    if (!! description) {
        content.push(`<i>${truncateString(description, 100)}</i>`);
    }
    if (!! file_type) {
        content.push(`<small><strong>File type:</strong> ${file_type}</small>`);
    }
    if (!! file_size) {
        content.push(`<small><strong>File size:</strong> ${file_size}</small>`);
    }

    return baseCardElement(this.model.get('displayIcon'), name, `<p>${content.join('<br>')}</p>`);
}

export const eventCard = function() {
    const snapshot = this.model.get('snapshot');
    let html = '';

    if (! snapshot) {
        return emptyCardElement(this.model.get('displayIcon'), 'Select an event');
    }

    const { name, start_time, end_time, description, all_day_event } = snapshot;
    var content = [];

    if (all_day_event) {
        content.push('<strong>All day event</strong>');
        if (!! start_time) {
            content.push(`<strong>Start:</strong> ${start_time.date}`);
        }
    } else {
        if (!! start_time) {
            content.push(`<strong>Start:</strong> ${start_time.date}`);
        }
        if (!! end_time) {
            content.push(`<strong>End:</strong> ${end_time.date}`);
        }
    }
    if (!! description) {
        content.push(`<i>${truncateString(description, 100)}</i>`);
    }

    return baseCardElement(this.model.get('displayIcon'), name, `<p>${content.join('<br>')}</p>`);
}

export const fileCard = function() {
    const content = `<p>File info here</p>`;

    return baseCardElement(this.model.get('displayIcon'), name, content);
}

export const folderCard = function() {
    const snapshot = this.model.get('snapshot');
    let html = '';

    if (! snapshot) {
        return emptyCardElement(this.model.get('displayIcon'), 'Select a folder');
    }

    const { name } = snapshot;
    const content = `
<ul>
    <li>Item 1</li>
    <li>Item 2</li>
    <li>Item 3</li>
</ul>`;

    return baseCardElement(this.model.get('displayIcon'), name, content);
}

export const periodisationCalendarCard = function() {
    const snapshot = this.model.get('snapshot');
    let html = '';

    if (! snapshot) {
        return emptyCardElement(this.model.get('displayIcon'), 'Select a periodisation calendar');
    }

    const { name } = snapshot;
    const content = `
<ul>
    <li>Jan</li>
    <li>Feb</li>
    <li>March</li>
</ul>`;

    return baseCardElement(this.model.get('displayIcon'), name, content);
}

export const profileGroupCard = function() {
    const snapshot = this.model.get('snapshot');
    let html = '';

    if (! snapshot) {
        return emptyCardElement(this.model.get('displayIcon'), 'Select a profile group');
    }

    const { name } = snapshot;
    const content = `
<ul>
    <li>Person 1</li>
    <li>Person 2</li>
    <li>Person 3</li>
</ul>`;

    return baseCardElement(this.model.get('displayIcon'), name, content);
}

export const userCircleCard = function() {
    const snapshot = this.model.get('snapshot');
    let html = '';

    if (! snapshot) {
        return emptyCardElement(this.model.get('displayIcon'), 'Select a user circle');
    }

    const { name } = snapshot;
    const content = `
<ul>
    <li>Person 1</li>
    <li>Person 2</li>
    <li>Person 3</li>
</ul>`;

    return baseCardElement(this.model.get('displayIcon'), name, content);
}

export const articleCategoryCard = function() {
    const snapshot = this.model.get('snapshot');
    let html = '';

    if (! snapshot) {
        return emptyCardElement(this.model.get('displayIcon'), 'Select an article category');
    }

    const { name } = snapshot;
    const content = `
<ul>
    <li>Article 1</li>
    <li>Article 2</li>
    <li>Article 3</li>
</ul>`;

    return baseCardElement(this.model.get('displayIcon'), name, content);
}

export const urlCard = function() {
    const attrs = this.model.getAttributes();
    const { urlSnapshot, url } = attrs;

    if (! url) {
        return emptyCardElement(this.model.get('displayIcon'), 'Select a url');
    }

    if (! urlSnapshot) {
        return baseCardElement(this.model.get('displayIcon'), attrs.name, '<p>Preview unavailable</p>');
    } else {
        const { title, url, description, image } = urlSnapshot;
        var content = [];

        if (!! title) {
            content.push(`<strong>Title:</strong> ${title}`);
        }
        if (!! description) {
            content.push(`<i>${truncateString(description, 100)}</i>`);
        }
        if (!! url) {
            content.push(`<small><strong>URL:</strong> ${url}</small>`);
        }

        let html = `<p>${content.join('<br>')}</p>`;

        if (!! image) {
            html += `<img src="${image}" />`;
        }

        return baseCardElement(this.model.get('displayIcon'), attrs.name, html);
    }
}