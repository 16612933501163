import { Component, Injectable, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { UserPermissionService } from '@services/user-permission.service';
@Component({
  selector: 'app-user-permission-edit',
  templateUrl: './user-permission-edit.component.html',
  styleUrls: [],
})
@Injectable()
export class UserPermissionEditComponent extends BaseComponent implements OnInit {
  @Input() uuid: string;
  formGroup: FormGroup;
  permissionCols: any;
  selectedPermissionIds = [];
  dataLoaded = false;

  @ViewChild('permissions', {static: false}) private advancedSelectPermissionsInput: { getSelectedIds: () => any[]; };

  constructor(
    public activatedRoute: ActivatedRoute,
    public state: State,
    public userPermissionService: UserPermissionService,
    private formBuilder: FormBuilder
  ) {

    super();

    this.setObjects({
      modelService: userPermissionService,
      route: activatedRoute,
      state: state,
    });

    // Setup the columns settings for the multi-selection table (Calendar)
    this.permissionCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` }
    ];
  }

  ngOnInit() {
    super.ngOnInit();
    this.initialFormGroup();
    this.modelService.getAllWithId(this.uuid).subscribe((result: any) => {
      this.populateInitialData(result);
      this.formGroup.patchValue({
        group_uuid: result.group_uuid,
        role_id: result.role_id,
        calendars: this.selectedIds
      });
      this.dataLoaded = true;
    });
  }

  public save(): void {
    this.formGroup.patchValue({
      permissions: this.getSelectedPermissions(),
    });
    this.modelService.patchById(this.uuid, this.formGroup.value, 'user').subscribe((result: any) => this.SavedMessage(result));
  }

  get controls(): any {
    return this.formGroup.controls;
  }

  // All the private methods (Private method should be after all the public methods)
  private populateInitialData(row: any[]): void {
    if (row !== undefined) {
      this.selectedIds(row);
    }
  }

  // Setup the initial form
  private initialFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      calendars: [null],
    });
  }

  private getSelectedPermissions(): any[] {
    const value: any[] = this.advancedSelectPermissionsInput.getSelectedIds();
    return value === null ? null : value;
  }

  private selectedIds(row: any[]) {
    row.forEach(el => {
      this.selectedPermissionIds.push(el);
    });
  }

  // Use this method to validate the result from server after our request.
  private SavedMessage(data: any) {
    this.state.message({ ['UserPermissionFormUpdate']: true });
  }
}
