import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { DirectoryItemService } from '@services/directory-item.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
import { FILTER_FIELDS } from './filter-fields';
import { FilterLink } from '@app/models/view-models/filter-link';
import { SidebarFilterLink } from '@app/services/sidebar-filter-link.service';
@Component({
  selector: 'app-directory-items-index',
  templateUrl: './directory-items-index.component.html',
  styleUrls: []
})
export class DirectoryItemsIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public node: Breadcrumb;
  public data: any;
  public fields: any = FILTER_FIELDS;
  public links: FilterLink[] = [];
  public displayedColumns = ['select', 'featured_image', 'name', 'directory_categories', 'created_by_user', 'created_at', 'published', 'actions'];
  public dataLoaded = false;
  constructor(
    public directoryItemService: DirectoryItemService,
    public router: Router,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog,
    private sidebarFilterLink: SidebarFilterLink
  ) {
    super();
    this.setObjects({
      modelService: directoryItemService,
      router,
      state,
      snackbarService,
      dialog
    });

    this.setPageVars({
      sidebar: 'directoryItems',
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }

  public updatedStatus(): void {
    this.state.message({ ['DirectoryItemIndexUpdate']: true });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.links = this.sidebarFilterLink.buildDirectoryItemsFilterLinks();
    this.node = {
      label: 'Directory Items',
      link: ' directoryItems',
      title: 'Directory Items'
    };
    this.buildSettingsBreadcrumb(this.node);
  }

  public onFilterClicked(link: FilterLink): void {
    if (link.route) {
      this.router.navigate([link.route]);
    }
  }
}
