import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
@Component({
  selector: 'core-form-timezone',
  templateUrl: './core-form-timezone.component.html',
  styleUrls: ['./core-form-timezone.component.scss']
})
export class CoreFormTimezoneComponent implements OnInit {
  @Input() selected: any = '';
  @Input() name = '';
  @Input() defaultValue?: any = null;

  @Output() timezoneChange = new EventEmitter<string>();

  onNotify(timezoneId: string) {
    this.selected = timezoneId;

    if (this.timezoneChange.observers.length > 0) {
      this.timezoneChange.next(timezoneId);
    }
  }

  ngOnInit(): void {
    this.name += '_timezone_uuid';
  }

  resetValue() {
    if (!!this.defaultValue) {
      // this.setDefaultValue()
    } else {
      this.selected = '';
    }
  }

  data() {
    // stops error
    return this.selected;
  }
}
