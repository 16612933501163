import {
  Defaults,
  Traits
} from './shared';


import { ICON_BUTTON_APP_COMPONENT } from './../../../const/icons';

import { COMP_BUTTON_APP_COMPONENT } from './../../../const/components';

import { FIELD_APP_COMPONENT_SELECTOR } from './../../../const/traits';

import { PageFormLinkedItem } from './../page-form-linked-item';

export const AppComponent = (editor, options:any = {}) => {

  (FIELD_APP_COMPONENT_SELECTOR as any).at = 9;

  return PageFormLinkedItem(editor, {
    componentName: COMP_BUTTON_APP_COMPONENT,
    displayIcon: ICON_BUTTON_APP_COMPONENT,
    model: {
      defaults: { ...Defaults,
        name: 'App Component',
        traits: [ ...Traits, FIELD_APP_COMPONENT_SELECTOR]
      }
    }
  });
}