import { paramCase } from 'change-case';

import {
  fixToolbarPositinoOnComponentHeightChange,
  modelSetClass,
  updatePageFormItemPerms
} from './../../utils';

import { FIELD_INITIAL_STATE_OPEN } from './../../../const/traits';

import {
  COMP_LAYOUT_ACCORDION,
  COMP_LAYOUT_ROW,
  COMP_LAYOUT_SECTION,
  COMP_LOREM_IPSUM,
  COMP_MEDIA_IMAGE_SLIDER,
  COMP_MEDIA_VIDEO
} from './../../../const/components';

import { ICON_LAYOUT_ACCORDION } from './../../../const/icons';

const compParamName = paramCase(COMP_LAYOUT_ACCORDION);

export const Accordion = editor => {
  return {
    isComponent: el => el.tagName === compParamName.toUpperCase(),
    // Model
    model: {
      defaults: {
        displayIcon: ICON_LAYOUT_ACCORDION,
        tagName: compParamName,
        name: 'Accordion',
        draggable: `${paramCase(COMP_LAYOUT_SECTION)}`,
        droppable: `
${paramCase(COMP_LAYOUT_ROW)},
${paramCase(COMP_MEDIA_IMAGE_SLIDER)},
${paramCase(COMP_MEDIA_VIDEO)}`,
        attributes: {
          title: null,
          closed: false,
          initialStateOpen: FIELD_INITIAL_STATE_OPEN.default
        },
        components: [{ type: COMP_LAYOUT_ROW }],
        traits: [{
          name: 'title',
          label: 'Title',
          default: 'Accordion Title'
        },
        FIELD_INITIAL_STATE_OPEN]
      }
    },
    // View
    view: {
      tagName: compParamName,
      // Init
      init() {
        // editor.select(this.model);
        updatePageFormItemPerms.call(this, editor);
        this.listenTo(this.model, 'change:attributes', this.handleAttributeChanges);
      },
      // Attribute changes handler
      handleAttributeChanges() {
        this.render();
        updatePageFormItemPerms.call(this, editor);
        fixToolbarPositinoOnComponentHeightChange(editor);
      },
      // On render
      onRender() {
        const attrs = this.model.getAttributes();
        const header = document.createElement('accordion-header');

        header.innerHTML = `
<accordion-title>${ attrs.title }</accordion-title>
<i class="closed material-icons">chevron_right</i>
<i class="open material-icons">expand_more</i>`;
        header.ondblclick = e => {
          attrs.closed = ! attrs.closed;
          this.model.addAttributes(attrs);
        }
        this.el.insertBefore(header, this.el.firstChild);
        modelSetClass.call(this, compParamName + (attrs.closed ? ' closed' : ' open'))
      }
    }
  }
}