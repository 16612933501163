import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Cache } from '@app/cache';
import {
  IBundle,
  IBundleContentType,
  IBundleItem
} from '@app/models/entities/bundle';

@Injectable({
  providedIn: 'root'
})

export class BundleService extends BaseService {
  private bundleCache = [];
  constructor(
    public http: HttpClient,
    public cache: Cache
  ) {
    super(http, cache);
    this.setVars({
      endPoint: 'bundle',
      modelTitle: 'Bundle'
    });
  }

  /**
   * Map api json to Bundle interface
   */
  public mapBundleApiResponse(_): IBundle {
    return {
      uuid: _.uuid,
      description: _.description,
      featured_image_cdn_url: _.featured_image_cdn_url,
      featured_image_cdn_uuid: _.featured_image_cdn_uuid,
      featured_image_height: _.featured_image_height,
      featured_image_width: _.featured_image_width,
      published: _.published,
      teaser: _.teaser,
      title: _.title,
      internal_title: _.internal_title,
      items: _.bundle_items
        .map(o => this._mapBundleItemApiResponse(o))
    };
  }

  /**
   * Map api json to BundleItem interface
   */
  private _mapBundleItemApiResponse(_): any  {
    let bundleItem:any = {
      uuid: _.uuid,
      article: _.article,
      article_category: _.article_category,
      bundle_item_type: _.bundle_item_type,
      created_at: _.created_at,
      description: _.description,
      directory_category: _.directory_category,
      directory_item: _.directory_item,
      display_order: _.display_order,
      featured_image: _.featured_image,
      featured_image_cdn_url: _.featured_image_cdn_url,
      featured_image_cdn_uuid: _.featured_image_cdn_uuid,
      featured_image_height: _.featured_image_height,
      featured_image_width: _.featured_image_width,
      force_open_url: _.force_open_url,
      open_in_app_browser: _.open_in_app_browser,
      page: _.page,
      published: _.published,
      sub_bundle: _.sub_bundle,
      teaser: _.teaser,
      title: _.title,
      updated_at: _.updated_at,
      url: _.url
    };
    if (_.article) {
      bundleItem.article_uuid = _.article.uuid;
    }
    if (_.article_category) {
      bundleItem.article_category_uuid = _.article_category.uuid;
    }
    if (_.directory_category) {
      bundleItem.directory_category_uuid = _.directory_category.uuid;
    }
    if (_.directory_item) {
      bundleItem.directory_item_uuid = _.directory_item.uuid;
    }
    if (_.sub_bundle) {
      bundleItem.sub_bundle_uuid = _.sub_bundle.uuid;
    }
    if (_.page) {
      bundleItem.page_uuid = _.page.uuid;
    }

    return bundleItem;
  }

  public getContentTypeItems(contentType: IBundleContentType) {
    return super.getFromApi(contentType.itemsUrl);
  }

  public getContentTypeItem(uuid: string, contentType: string) {
    return super.getFromApi(`bundles/get-related-item/${uuid}/${contentType}`);
  }
}
