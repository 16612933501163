import { Component, EventEmitter, HostBinding, OnInit, Output, ViewChild } from '@angular/core';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { State } from '@app/state';
@Component({
  selector: 'slide-out',
  templateUrl: './slide-out.component.html',
  styleUrls: ['./slide-out.component.scss', './slide-out.1024.component.scss', './slide-out.700.component.scss']
})
export class SlideOutComponent implements OnInit {
  @HostBinding('class.show') show = false;
  @HostBinding('class.hide') hide = false;
  @Output() refreshIndexInParentComponent: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(RouterOutlet, {static: false}) outlet: RouterOutlet;

  constructor(private state: State, private router: Router) { }

  ngOnInit() {
    // Fixes a bug with router outlet
    this.router.events.subscribe(e => {
      if (e instanceof ActivationStart && e.snapshot.outlet === 'slideout') {
        this.outlet.deactivate();
      }
    });

    this.state.subscription().subscribe(data => {
      const body = document.getElementsByTagName('body')[0];
      const slideout = document.getElementById('slideout');
      if (!!data['slideoutShow'] && this.show === false) {
        this.show = true;
        this.hide = false;
        slideout.classList.add('open');
        body.classList.add('slideout-open');
      }
      if (!!data['slideoutHide'] && this.show === true) {
        this.hide = true;
        this.show = false;
        slideout.classList.remove('open');
        body.classList.remove('slideout-open');
      }
    });
  }

  refreshIndexInParent() {
    // tslint:disable-next-line: no-unused-expression
    this.refreshIndexInParentComponent.observers.length > 0 ? this.refreshIndexInParentComponent.emit() : null;
  }
}
