import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiModel } from '@app/models/entities/api-model';

@Component({
  selector: 'document-thumbnail-view',
  templateUrl: './document-thumbnail-view.component.html',
  styleUrls: ['./document-thumbnail-view.component.scss']
})

export class DocumentThumbnailViewComponent implements OnInit {
  @Input() documentItems;
  @Output() viewDocument = new EventEmitter<ApiModel.Document>();
  @Output() editDocument = new EventEmitter<ApiModel.Document>();
  @Output() deleteDocument = new EventEmitter<ApiModel.Document>();

  constructor() {
  }

  ngOnInit() {
  }

  public viewItem(item: ApiModel.Document) {
    if (this.viewDocument.observers.length > 0) {
      this.viewDocument.emit(item);
    }
  }

  public editItem(item: ApiModel.Document) {
    if (this.editDocument.observers.length > 0) {
       this.editDocument.emit(item);
    }
  }

  public deleteItem(item: ApiModel.Document) {
    if (this.deleteDocument.observers.length > 0) {
      this.deleteDocument.emit(item);
    }
  }
}
