export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

export const uuidv4 = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export namespace FormUtil {
  export class Validation {
    static readonly GENERAL_FIELD_MAX_LENGTH = 255;
    static readonly ISO_FIELD_MAX_LENGTH = 3;
  }

  export class Document {
    static readonly DOCUMENT_TYPE_IMAGE = 'image';
    static readonly DOCUMENT_TYPE_OFFICE = 'application/vnd.openxmlformats-officedocument';
    static readonly DOCUMENT_TYPE_PDF = 'application/pdf';
    static readonly DOCUMENT_TYPE_VIDEO = 'video';
    static readonly DOCUMENT_TYPE_AUDIO = 'audio';
  }
}