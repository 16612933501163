import { Component, Input, OnInit } from '@angular/core';
// import { Timeline } from 'vis';
import { State } from '@app/state';

@Component({
  selector: 'schedule-row',
  templateUrl: './schedule-row.component.html',
  styleUrls: ['./schedule-row.component.scss']
})
export class ScheduleRowComponent implements OnInit {

  @Input() dates: Array<string> = [];
  @Input() selectedDate: Date;

  // @ViewChild('timeline', {static: false}) timelineElement: ElementRef;

  public allDayEvents: Array<any> = [];
  public events: Array<any> = [];
  public timeline: any;
  public stateEvents: Array<any> = [];
  public eventsForTimeline: any;
  public showAllEvents = true;

  private sidebarFilterlistValues: Array<any> = [];

  constructor(private state: State) { }

  ngOnInit() {
    this.state.subscription().subscribe(state => {
      if (!!state.sidebarFilterlistValues) {
        this.sidebarFilterlistValues = state.sidebarFilterlistValues;
        if (state.events) {
          this.stateEvents = state.events;
          this.filterEvents();
        }
      }
      if (!!state.filterEvents) {
        this.filterEvents();
      }
    });
  }

  filterEvents() {
    const allDayEvents = [];
    const events = [];
    const eventsForTimeline: Array<any> = [];
    this.stateEvents.forEach((event: any, index) => {
      if (this.sidebarFilterlistValues.length > 0 && event.calendars.length > 0) {
        if (this.sidebarFilterlistValues.indexOf(event.event_category.uuid) !== -1) {
          const eventStartString = event.start_time.substr(0, 10);
          const eventEndString = event.end_time.substr(0, 10);
          const isBetweenDates = this.isBetweenDates(eventStartString, eventEndString);
          const startedBeforeDate = this.startedBeforeDate(eventStartString);
          const endsAfterDate = this.endsAfterDate(eventEndString);
          if (isBetweenDates) {
            event.startedBeforeDate = startedBeforeDate;
            event.endsAfterDate = endsAfterDate;
            if (!event.all_day_event && (startedBeforeDate || endsAfterDate)) {
              event.all_day_event = true;
            }
            if (event.all_day_event) {
              const clonedEvent: any = Object.assign({}, event);
              allDayEvents.push(clonedEvent);
            } else {
              events.push(event);
            }
          }
        }
      }
    });
    if (allDayEvents.length > 0) {
      allDayEvents.sort((a, b) => a.starttime - b.starttime);
      this.allDayEvents = allDayEvents;
    }
    if (events.length > 0) {
      events.sort((a, b) => a.starttime - b.starttime);
      this.events = events;
    }
  }

  isBetweenDates(eventStartDate, eventEndDate) {
    const startDateToCheck: Date = new Date(this.dates[0]);
    const endDateToCheck: Date = new Date(this.dates[6]);
    const startDate: Date = new Date(eventStartDate);
    const endDate: Date = new Date(eventEndDate);
    const startDateBetweenDates = ((startDateToCheck.getTime() <= endDate.getTime() && startDateToCheck.getTime() >= startDate.getTime()));
    const endDateBetweenDates = ((endDateToCheck.getTime() <= endDate.getTime() && endDateToCheck.getTime() >= startDate.getTime()));
    return (startDateBetweenDates || endDateBetweenDates);
  }

  startedBeforeDate(eventStartDate) {
    const dateToCheck: Date = new Date(this.dates[0]);
    const startDate: Date = new Date(eventStartDate);
    return (startDate.getTime() < dateToCheck.getTime());
  }

  endsAfterDate(eventEndDate) {
    const dateToCheck: Date = new Date(this.dates[6]);
    const endDate: Date = new Date(eventEndDate);
    return (endDate.getTime() > dateToCheck.getTime());
  }
}

