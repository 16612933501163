import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Cache } from '@app/cache';

@Injectable({
  providedIn: 'root'
})
export class ContractTypeService extends BaseService {

  private contractTypeCache = [];

  constructor(
    public http: HttpClient,
    public cache: Cache
  ) {
    super(http, cache);
    this.setVars({
      endPoint: 'contract-type',
      modelTitle: 'ContractType'
    });
  }
}
