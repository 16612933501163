import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { UserDocumentService } from '@services/user-document.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'user-documents-index',
  templateUrl: '../../../documents/index/documents-index.component.html',
  styleUrls: ['../../../documents/index/documents-index.component.scss'],
  providers: [SidebarService]
})
export class UserDocumentsIndexComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() hideSidebar = true;
  @Input() showSearch = false;
  @Input() uuid: string;

  public dataSource: any;
  public data: any;
  public displayedColumns = ['name', 'file_type', 'file_size', 'created_at', 'users', 'created_user', 'actions'];
  public dataLoaded = false;
  public stateDataSubscription: Subscription;

  constructor(public sidebarService: SidebarService, public state: State, public userDocumentService: UserDocumentService) {
    super();
    this.setObjects({
      modelService: userDocumentService,
      sidebarService: sidebarService,
      state: state
    });
    this.setPageVars({
      listenForIndexUpdate: true
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.modelServiceGetAllWithId(this.uuid);

    this.stateDataSubscription = this.state.subscription().subscribe(stateData => {
      if (stateData.pageLoaded) {
        this.dataLoaded = true;
      }
    });
  }
  ngOnDestroy(): void {
    this.stateDataSubscription.unsubscribe();
  }
}
