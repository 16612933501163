import { Subject } from 'rxjs';

export class State {
  private static observer;
  private static observable = null;
  private state = {};

  subscription() {
    const context = this;
    if (State.observable !== null) {
      return State.observable;
    }

    State.observable = new Subject();
    return State.observable;
  }

  remove(keyToRemove: string) {
    const existingState = JSON.parse(JSON.stringify(this.state ? this.state : {}));
    const newState = {};
    Object.keys(existingState).forEach((key) => {
      if (key !== keyToRemove) {
        newState[key] = existingState[key];
      }
    });
    // this.state = newState;
    State.observable.next(newState);
  }

  get(keyToKey: string = null) {
    const existingState = JSON.parse(JSON.stringify(this.state ? this.state : {}));
    if (keyToKey == null) {
      return existingState;
    }
    if (existingState[keyToKey]) {
      return existingState[keyToKey];
    }
    return null;
  }

  update(incomingState) {
    const existingState = JSON.parse(JSON.stringify(this.state ? this.state : {}));
    this.state = { ...existingState, ...incomingState };
    State.observable.next(this.state);
  }

  message(message: Object) {
    State.observable.next(message);
  }

  mergeDeep(target, ...sources) {
    if (!sources.length) { return target; }
    const source = sources.shift();
    function isObject(item) {
      return (item && typeof item === 'object' && !Array.isArray(item));
    }
    if (isObject(target) && isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          if (!target[key]) { Object.assign(target, { [key]: {} }); }
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
    return this.mergeDeep(target, ...sources);
  }
}
