export const FILTER_FIELDS = [
  {
    type: 'text',
    name: 'firstname',
    label: 'First name',
    value: ''
  },
  {
    type: 'text',
    name: 'lastname',
    label: 'Last name',
    value: ''
  },
  {
    type: 'text',
    name: 'email',
    label: 'Email',
    value: ''
  },
  {
    type: 'dropdown',
    name: 'group_uuid',
    label: 'All groups',
    value: '',
    apiUrl: 'groups/list'
  },
  {
    type: 'dropdown',
    name: 'spid_status',
    label: 'Sportsology status',
    showEmptyOption: false,
    value: '',
    options: [
      {
        text: 'All',
        value: ''
      },
      {
        text: 'Active',
        value: 'active'
      },
      {
        text: 'Pending',
        value: 'pending'
      },
      {
        text: 'Inactive',
        value: 'inactive'
      },
    ]
  },
  {
    type: 'dropdown',
    name: 'administrator',
    label: 'Admin access?',
    showEmptyOption: false,
    value: '',
    options: [
      {
        text: 'All users',
        value: ''
      },
      {
        text: 'Yes',
        value: '1'
      },
    ]
  }
];
