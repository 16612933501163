export const PAGE_FILTER_FIELDS = [
  {
    type: 'text',
    name: 'title',
    label: 'Title',
    value: ''
  },
  {
    type: 'dropdown',
    name: 'published',
    label: 'Published',
    value: '',
    options: [
      {
        text: 'Yes',
        value: '1'
      },
      {
        text: 'No',
        value: '0'
      }
    ]
  }
  // {
  //   type: 'checkbox',
  //   allowMultiple: true,
  //   name: 'page_category_id',
  //   label: 'Categories',
  //   value: '',
  //   apiUrl: 'page-categories',
  // }
];
