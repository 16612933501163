import { Component, EventEmitter, HostBinding, OnInit, Output, ViewChild } from '@angular/core';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { State } from '@app/state';
@Component({
  selector: 'full-width-overlay',
  templateUrl: './full-width-overlay.component.html',
  styleUrls: ['./full-width-overlay.component.scss', './full-width-overlay.1024.component.scss', './full-width-overlay.700.component.scss']
})
export class FullWidthOverlayComponent implements OnInit {
  @HostBinding('class.show') show = false;
  @HostBinding('class.hide') hide = false;
  @Output() refreshIndexInParentComponent: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(RouterOutlet, { static: true }) outlet: RouterOutlet;

  constructor(private state: State, private router: Router) { }

  ngOnInit() {
    // Fixes a bug with router outlet
    this.router.events.subscribe(e => {
      if (e instanceof ActivationStart && e.snapshot.outlet === 'fullWidthOverlay') {
        this.outlet.deactivate();
      }
    });

    this.state.subscription().subscribe(data => {
      if (!!data['fullWidthOverlayShow'] && this.show === false) {
        this.show = true;
        this.hide = false;
        document.getElementById('fullWidthOverlay').classList.add('open');
      }
      if (!!data['fullWidthOverlayHide'] && this.show === true) {
        this.hide = true;
        this.show = false;
        if (!! this.outlet.isActivated && this.outlet.isActivated) {
          this.outlet.detach();
        }
        document.getElementById('fullWidthOverlay').classList.remove('open');
      }
    });
  }

  refreshIndexInParent() {
    this.refreshIndexInParentComponent.observers.length>0? this.refreshIndexInParentComponent.emit() : null;
  }
}
