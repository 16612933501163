import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { MESSAGES_RESPONSE_FILTER_FIELDS } from './filter-fields';
import { MessageResponseService } from '@app/services/message-response.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';

@Component({
  selector: 'app-message-responses-index',
  templateUrl: './message-response-index.component.html',
  styleUrls: ['./message-response-index.scss'],
  providers: [SidebarService]
})
export class MessageResponseIndexComponent extends BaseComponent implements OnInit {
  public data: any;
  public displayedColumns = ['select', 'created_at', 'avatar', 'created_by_user.name', 'message', 'read', 'actions'];
  public dataLoaded = false;
  public fields: any = MESSAGES_RESPONSE_FILTER_FIELDS;
  public node: Breadcrumb;
  constructor(
    public messageResponseService: MessageResponseService,
    public router: Router,
    public sidebarService: SidebarService,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: messageResponseService,
      router,
      sidebarService,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      sidebar: 'messages response',
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => {
       this.dataLoaded = true;
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.nodes = [];
    this.nodes.push({
      label: 'Messages',
      link: 'messages',
      title: 'Messages'
    },
    {
      label: 'Message Responses',
      link: 'message-responses',
      title: 'Message Responses'
    });
   }

  public updatedStatus(): void {
    this.state.message({ ['MessageResponseIndexUpdate']: true });
  }

}
