import { Component, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { State } from '@app/state';
import { SnackBarService } from '@services/snack-bar.service';
import { WindowRefService } from '@services/window-ref.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public title = 'Sportsology';
  constructor(
    private windowRef: WindowRefService,
    private state: State,
    private snackBar: SnackBarService,
  ) {
    this.stopAutoDragAndDropOpeningFiles();
    this.listenForSnackBarMessages();
  }

  stopAutoDragAndDropOpeningFiles() {
    // stops chrome opening a file if dropped into the window
    this.windowRef.nativeWindow.canDragDropOverElement = false;
    this.windowRef.nativeWindow.addEventListener('dragover', (e) => {
      e = e || event;
      e.preventDefault();
    }, false);
    this.windowRef.nativeWindow.addEventListener('drop', (e) => {
      e = e || event;
      e.preventDefault();
    }, false);
  }

  listenForSnackBarMessages() {
    this.state.subscription().subscribe(state => {
      if (!!state.snackBarError) {
        this.snackBar.error(state.snackBarError);
      }
      if (!!state.snackBarSuccess) {
        this.snackBar.success(state.snackBarSuccess);
      }
      if (!!state.snackBarInfo) {
        this.snackBar.info(state.snackBarInfo);
      }
      if (!!state.snackBarWarning) {
        this.snackBar.warning(state.snackBarWarning);
      }
    });
  }
}
