import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from './base.service';
import { Cache } from '@app/cache';
import { Environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExportService extends BaseService {

  private exportCache = [];

  constructor(
    public http: HttpClient,
    public cache: Cache,
  ) {
    super(http, cache);
    this.setVars({
      endPoint: 'export',
      modelTitle: 'Export'
    });
  }

  downloadExport(uuid: string) {
    const url = Environment.apiURL + 'export/download/' + uuid;
    return this.http.get(url, {responseType: 'blob'});
  }

}
