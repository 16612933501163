import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiDataService } from '@services/api-data.service';
import { SelectionOption } from '@app/models/view-models/option';
@Component({
  selector: 'core-form-checkbox-list',
  templateUrl: './core-form-checkbox-list.component.html',
  styleUrls: []
})
export class CoreFormCheckboxListComponent implements OnInit {
  @Input() apiDataSource = '';
  @Input() apiDataSourceCacheKey = '';
  @Input() dataSourceValue = 'uuid';
  @Input() dataSourceText = 'name';
  @Input() selectedOptions = [];
  @Output() selectedOption = new EventEmitter<SelectionOption>();
  public dataLoaded = false;
  public options: Array<SelectionOption> = [];

  constructor(private apiDataService: ApiDataService) { }

  ngOnInit() {
    if (this.apiDataSource !== '' && typeof this.apiDataSource !== 'undefined') {
      this.apiDataService
        .getOptions(this.apiDataSource, this.apiDataSourceCacheKey)
        .subscribe((result: any) => this.convertObjectToValues(typeof result.data !== 'undefined' ? result.data : result));
    }
  }
  convertObjectToValues(objectToParse) {
    objectToParse.forEach(object => {
      this.options.push({
        value: object[this.dataSourceValue],
        text: object[this.dataSourceText],
        selected: this.selectedOptions.indexOf(object[this.dataSourceValue]) >= 0 ? true : false
      });
    });
    this.options = this.options.sort((left, right): any => {
      if (left.text < right.text) {
        return -1;
      }
      if (left.text > right.text) {
        return 1;
      }
      return 0;
    });
    this.dataLoaded = true;
  }

  public change($event): void  {
    if (this.selectedOption.observers.length > 0) {
      this.selectedOption.emit($event);
    }
  }

}
