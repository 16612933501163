import { traitButton } from './shared';
import { CMD_OPEN_INLINE_PAGE_ITEM_SELECTOR } from './../../const/commands';

export const PageFormInlineItemSelector = function(editor, options:any = {}) {
    return {
        name: options.name,
        noLabel: true,
        templateInput: '',
        getInputEl() {
            const attrs = editor.getSelected().getAttributes();
            return traitButton({
                editor: editor,
                buttonText: 'Select Item',
                command: CMD_OPEN_INLINE_PAGE_ITEM_SELECTOR,
                commandOptions: { itemType: attrs.itemType }
            });
        }
    }
}