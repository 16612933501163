import { Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Message } from '@app/models/entities/message';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { MessageService } from '@services/message.service';
import { SidebarService } from '@services/sidebar.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import * as Moment from 'moment';
@Component({
  selector: 'app-message-form',
  templateUrl: './message-form.component.html',
  styleUrls: ['./message-form.component.scss'],
  providers: [SidebarService]
})

export class MessageFormComponent extends BaseComponent implements OnInit {
  // Local variables
  messageFormGroup: FormGroup;
  recipientCols: any;
  userCircleCols: any;
  documentCols: any;
  articleCols: any;
  eventCols: any;
  dataLoaded = false;
  isCreateMessagePage = false;
  formSubmitted = false;
  selectedDocumentIds = [];
  selectedArticleIds = [];
  selectedEventIds = [];
  selectedRecipientIds = [];
  oneToManyColumnsDef = [];
  ids = [];

  @ViewChild('documents', {static: false}) private documentsInput: { getSelectedIds: () => any[]; };
  @ViewChild('articles', {static: false}) private articlesInput: { getSelectedIds: () => any[]; };
  @ViewChild('events', {static: false}) private eventsInput: { getSelectedIds: () => any[]; };
  @ViewChild('recipients', {static: false}) private recipientsInput: { getSelectedIds: () => any[]; };
  @ViewChild('user_circles', {static: false}) private userCirclesInput: { getSelectedIds: () => any[]; };

  private config = GlobalUtil.Configuration;
  private defaultTimezone: any;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public messageService: MessageService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: messageService
    });

    this.isCreateMessagePage = activatedRoute.snapshot.url[0].path === 'create-message';

    this.setPageVars({
      sidebar: 'messages',
    });

    this.defaultTimezone = this.state.get('defaultTimezone');

    this.initialFormGroup();

    const id = this.routeParams['id'];

    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result: Message) => {
        this.populateInitialData(result);
        this.messageFormGroup.patchValue(result);
        this.dataLoaded = true;
      });
    }

    // Setup the columns settings for the multi-selection table (Documents)
    this.documentCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      {
        columnDef: 'thumbnail',
        selectedOnclick: false,
        showThumbnail: true,
        header: '',
        cell: (element: any) => `${element.cdn_uuid}`
      },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` },
      {
        columnDef: 'created_at',
        header: 'Created On',
        selectedOnclick: false,
        dateFormat: this.config.SHORT_DATE_FORMAT,
        cell: (element: any) => `${element.created_at}`
      }
    ];

    // Setup the columns settings for the multi-selection table (articles)
    this.articleCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'title', selectedOnclick: true, header: 'Title', cell: (element: any) => `${element.title}` },
      {
        columnDef: 'created_at',
        header: 'Created On',
        selectedOnclick: false,
        dateFormat: this.config.SHORT_DATE_FORMAT,
        cell: (element: any) => `${element.created_at}`
      }
    ];

    // Setup the columns settings for the multi-selection table (Recipients)
    this.recipientCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` },
    ];

    // UserCircle columns
    this.userCircleCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` },
    ];

    // Setup the columns settings for the multi-selection table (Events)
    this.eventCols = [
      { columnDef: 'selected', header: 'Selected', selectedOnclick: true, cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', header: 'Name', selectedOnclick: true, cell: (element: any) => `${element.name}` },
      {
        columnDef: 'start_time', header: 'Start Time', selectedOnclick: false,
        cell: (element: any) => `${element.start_time}`
      },
      {
        columnDef: 'end_time', header: 'End Time', selectedOnclick: false,
        cell: (element: any) => `${element.end_time}`
      },
    ];

  }

  handleAccordionInputs(panel) {
  }

  private populateInitialData(row): void {
    if (row !== undefined) {
      this.selectedIds(row);
    }
  }

  // setup the initial form for Message entity
  private initialFormGroup(): void {
    this.messageFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      title: [
        null, [
          Validators.minLength(3),
          Validators.maxLength(255),
          Validators.required,
        ]
      ],
      send_as_email: [null],
      send_as_sms: [null],
      send_as_push: [null],
      push_action_to_app_page: [{ value: false, disabled: true }],
      from_email_address: [null, [
        Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH),
        Validators.pattern(GlobalUtil.Validation.EMAIL_PATTERN)
        ]
      ],
      public: [null],
      body: [null, [
        Validators.minLength(3),
        this.richTextRequiredValidator,
      ]],
      body_plain_text: [null, [
        Validators.minLength(3),
        Validators.maxLength(1000),
        this.plainTextRequiredValidator
      ]],
      scheduled_notification_date: [null],
      scheduled_notification_timezone_uuid: [this.defaultTimezone.uuid],
      recipients: [[]],
      page_uuid: [null],
      user_circles: [],
      documents: [],
      articles: [],
      events: [],
    });

    this.controls.page_uuid.valueChanges.subscribe(value => {
      if (value === null) {
        this.controls.push_action_to_app_page.setValue(false);
        this.controls.push_action_to_app_page.disable();
      } else {
        this.controls.push_action_to_app_page.enable();
      }
    });
  }

  get controls(): any {
    return this.messageFormGroup.controls;
  }

  get saveButtonText(): any {
    if (!! this.controls) {
      if (this.getSelectedRecipients().length === 0) {
        return 'Save Draft Message';
      } else {
        if (this.controls.scheduled_notification_date.value !== null) {
          return 'Schedule Message';
        }
      }
    }
    return 'Send Message';
  }

  // Populate the form controls when the page loads
  selectedIds(row): any {

    row.documents.forEach(el => {
      this.selectedDocumentIds.push(el);
    });

    row.articles.forEach(el => {
      this.selectedArticleIds.push(el);
    });

    row.events.forEach(el => {
      this.selectedEventIds.push(el);
    });

    row.recipients.forEach(el => {
      this.selectedRecipientIds.push(el);
    });

    return this.ids;
  }

  public save(): void {
    this.formSubmitted = true;
    const id = this.controls.id.value;
    const uuid = this.controls.uuid.value;
    this.replaceBooleanFieldsWithByte();

    this.messageFormGroup.patchValue({
      'documents': this.getSelectedDocuments(),
      'recipients': this.getSelectedRecipients(),
      'articles': this.getSelectedArticles(),
      'events': this.getSelectedEvents(),
      'scheduled_notification_date':
        this.controls.scheduled_notification_date.value ? this.transformDateTime(this.controls.scheduled_notification_date.value) : null,
    });

    if (id || uuid) {
      // Update the existing message
      this.messageService.patchById(uuid ? uuid : id, this.messageFormGroup.value).subscribe(result => this.SavedMessage(result));
    } else {
      // Create the new message
      this.messageService.create(this.messageFormGroup.value).subscribe(result => this.SavedMessage(result));
    }
    // Rollback the changes so all the checkboxes will bound correctly
    this.replaceByteFieldsWithBoolean();

    setTimeout (() => {
      this.formSubmitted = false;
    }, 3000);

  }

  // Make plain text field required if SMS or Push are selected
  public plainTextRequiredValidator(formControl: AbstractControl) {
    if ( ! formControl.parent) {
      return null;
    }
    if (formControl.parent.get('send_as_sms').value || formControl.parent.get('send_as_push').value) {
      return Validators.required(formControl);
    }
    return null;
  }

  // Make rich text field required if 'Email' is selected, should be consolodated with plainTextRequiredValidator()
  public richTextRequiredValidator(formControl: AbstractControl) {
    if ( ! formControl.parent) {
      return null;
    }
    // if (formControl.parent.get('email').value) {
    //   return Validators.required(formControl);
    // }
    return null;
  }

  // Patches the datepicker value
  public setScheduleDate(dateValue: string) {
    this.messageFormGroup.patchValue({
      scheduled_notification_date: dateValue
      // Moment adds extra timezone identifiers into dataValue
      // which prevents it from being saved
      // scheduled_notification_date: Moment(dateValue)
    });
  }

  public setScheduleTimezone(timezoneId: string) {
    this.messageFormGroup.patchValue({
      scheduled_notification_timezone_uuid: timezoneId
    });
  }

  // All the private methods (Private method should be after all the public methods)

  private SavedMessage(data) {
    if (this.isCreateMessagePage) {
      this.router.navigate(['messages']);
    } else {
      // Use this method to validate the result from server after our request.
      this.state.message({ ['MessageIndexUpdate']: true });
      this.closeSlideout();
    }
  }

  // Converts the date to API friendly format before saving
  private transformDateTime(val: any): any {
    return Moment(val).format(this.config.LONG_DATE_FORMAT_FOR_SAVE_ENTRY);
  }

  private replaceBooleanFieldsWithByte(): void {
    // Replace all the boolean fields with byte
    this.messageFormGroup.patchValue({
      'send_as_email': this.controls.send_as_email.value ? '1' : '0',
      'send_as_push': this.controls.send_as_push.value ? '1' : '0',
      'send_as_sms': this.controls.send_as_sms.value ? '1' : '0',
      'public': this.controls.public.value ? '1' : '0'
    });
  }

  // Replace all the boolean fields with byte
  private replaceByteFieldsWithBoolean(): void {
    this.messageFormGroup.patchValue({
      'send_as_email': this.controls.send_as_email.value === '1' ? true : false,
      'send_as_push': this.controls.send_as_push.value === '1' ? true : false,
      'send_as_sms': this.controls.send_as_sms.value === '1' ? true : false,
      'public': this.controls.public.value === '1' ? true : false,
    });
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  private getSelectedDocuments(): any[] {
    let value: any[] = null;
    if (!! this.documentsInput) {
      value = this.documentsInput.getSelectedIds();
    }
    return value === null ? [] : value;
  }

  private getSelectedRecipients(): any[] {
    // There's an issue with the logic when saving recipients / user circles
    // However for now, I'm going to leave this as is.
    let value: any[] = null;
    if (!! this.recipientsInput) {
      value = this.recipientsInput.getSelectedIds();
    }
    if (!! this.userCirclesInput) {
      if (this.userCirclesInput.getSelectedIds().length) {
        value = this.userCirclesInput.getSelectedIds();
      }
    }
    return value === null ? [] : value;
  }

  private getSelectedArticles(): any[] {
    let value: any[] = null;
    if (!! this.articlesInput) {
      value = this.articlesInput.getSelectedIds();
    }
    return value === null ? [] : value;
  }

  private getSelectedEvents(): any[] {
    let value: any[] = null;
    if (!! this.eventsInput) {
      value = this.eventsInput.getSelectedIds();
    }
    return value === null ? [] : value;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.nodes.push(
      {
        label: 'Messages',
        link: 'messages',
        title: 'Messages'
      },
      {
      label: 'New message',
      link: 'New message',
      title: 'New message'
    });
  }

  public onClicked(node: Breadcrumb): void {
    document.getElementById('sidebar').classList.remove('open');
    this.closeSlideout();
  }
}
