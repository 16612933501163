import { Component, Input, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { State } from '@app/state';
@Component({
  selector: 'app-reload-bar',
  templateUrl: './reload-bar.component.html',
  styleUrls: ['./reload-bar.component.scss']
})
export class ReloadBarComponent implements OnInit
{
  public showReloadBar = false;
  private ccDeploymentHash;

  constructor(private state: State) {}

  ngOnInit() {
    this.state.subscription().subscribe((state) => {
      if (!! state.clientCCNotifications && state.clientCCNotifications === 'UPDATE_CLIENT_CC_REQUIRED') {
        // this.showReloadBar = true;
        // this.ccDeploymentHash = state.clientCCDeploymentHash;
      }
    });
  }

  private hash() {
    return 'time-to-reload'.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
  }

  public reloadCC() {
    localStorage.setItem('deployment_hash', this.ccDeploymentHash);
    setTimeout(() => window.location.href = window.location.href.replace(/#.*$/, '') + `?reload=${this.hash()}`);
  }
}
