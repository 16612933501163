import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Cache } from '@app/cache';
import { Environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService extends BaseService {

  constructor(public http: HttpClient, public cache: Cache) {
    super(http, cache);
    this.setVars({
      endPoint: 'document',
      modelTitle: 'Document'
    });
  }

  // TODO -  Delete this method as made the file upload control as independent control - It is emiting an event with file info as parameter
  public async createDocument(document) {
    const data: any = {};
    data.name = document.filename;
    data.cdn_uuid = document.uuid;
    data.file_name = document.filename;
    data.file_size = document.filesize.toString();
    data.file_family = document.fileFamily;
    data.file_type = document.mimeType;
    data.file_extension = document.filename.split('.').pop();
    return await this.http.post<object>(Environment.apiURL + 'documents', data).toPromise();
  }
}
