import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IndexDBService } from '@app/services/index-db.service';
import { UploadService } from '@app/services/upload.service';
import { Environment } from '@environments/environment';

@Pipe({
  name: 'secureUrl'
})
export class SecureUrlPipe implements PipeTransform
{
  private _secure:boolean = false;

  constructor(
    private http: HttpClient,
    private _indexDBService: IndexDBService,
    // private _uploadService: UploadService,
  ) {}

  transform(url: string)
  {
    // if(true) {
    if(url.substr(-1) === '/') {
      url = url.substr(0, url.length - 1);
    }
    url = url.replace('http://ucarecdn.com/', Environment.filesURL);
    url = url.replace('https://ucarecdn.com/', Environment.filesURL);
    // }
    return new Observable<string>((observer) =>
    {
      if(url.includes('/img/default-avatar.jpg')) {
        observer.next(url);
        return { unsubscribe() {}};
      }

      var transaction = this._indexDBService.db.transaction(['images'], 'readonly');
      var store = transaction.objectStore('images');
      const findFileReq = store.openCursor(url);

      observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
      findFileReq.onsuccess = (e) => {
        const cursor = e.target.result;
        if(cursor) {
          store.get(url).onsuccess = (e) => {
            const data = e.target.result;
            this._injectUrlData(observer, data.bl);
          }
        } else {
          const {next, error} = observer;
          this.http.get(url, {responseType: 'blob'}).subscribe(
            response => {
              transaction = this._indexDBService.db.transaction(['images'], 'readwrite');
              store = transaction.objectStore('images');
              const tID = Date.now();
              const obj = { bl:response, created:tID };
              var request = store.add(obj, url);
              this._injectUrlData(observer, response);
            });
        }
      }

      return { unsubscribe() {}};
    });
  }

  private _injectUrlData(observer, blobData) {
    const reader:any = new FileReader();

    reader.readAsDataURL(blobData);
    reader.onloadend = function() {
      observer.next(reader.result);
    };
  }
}