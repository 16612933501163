import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Environment } from '@environments/environment';
import { Cache } from '@app/cache';

@Injectable({
  providedIn: 'root'
})
export class ApiDataService extends BaseService {

  constructor(
    public http: HttpClient,
    public cache: Cache
  ) {
    super(http, cache);
  }

  public getOptions(url: string, cacheKey?: string) {
    return this.http.get<object[]>(`${Environment.apiURL}${url}`);

    // if (cacheKey !== '') {
    //   cacheKey = 'httpAll' + cacheKey;
    //   const cacheItem = Cache.get(cacheKey);
    //   if (cacheItem !== null) {
    //     const observable = of(cacheItem);
    //     return observable;
    //   }
    //   const observable = this.http.get<object[]>(`${Environment.apiURL}${url}`);
    //   observable.subscribe(allItems => Cache.set(cacheKey, allItems, this.persistentCacheDuration));
    //   return observable;
    // } else {
    // }
  }
}
