import { Observable, Subject } from 'rxjs';
// import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { map, takeUntil } from 'rxjs/operators';
// import { transform } from 'typescript';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AngularFirestore
  // AngularFirestoreCollection
} from '@angular/fire/firestore';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef,
  // MatAutocomplete, MatAutocompleteModule, MatPaginator, MatProgressBarLocation, MatSort, MatTableDataSource
} from '@angular/material';
import { Router } from '@angular/router';
// import { FormValidators } from '@app/modules/common/shared/validators/form-validators.validator';
import * as firebase from 'firebase';
import * as CryptoJS from 'crypto-js';

// import { Breadcrumb } from '@app/models/view-models/breadcrumb';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { GroupService } from '@app/services/group.service';
import { UserCircleService } from '@app/services/user-circle.service';
import { SnackBarService } from '@app/services/snack-bar.service';
import { UploadService } from '@services/upload.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { ChatService } from '@services/chat.service';
import { UserService } from '@services/user.service';
import { FIREBASE_REFERENCES } from '../../firebase.module';
import { AddChatUsersComponent } from './add-chat-users/add-chat-users.component';
import { AddChatUserCirclesComponent } from './add-chat-user-circles/add-chat-user-circles.component';
import { ConfirmationDialogComponent } from '@app/components/confirmation-dialog/confirmation-dialog.component';
import { EditAdminsComponent } from './edit-admins/edit-admins.component';
// import { EditGroupDetailsComponent } from './edit-group-details/edit-group-details.component';
import { SportsologySuperUserGuard } from './../../modules/common/core/components/auth/sportsology-super-user.guard';
import { ChatData } from './models/ChatData';
import { DialogData } from './models/DialogData';
// import { FilesUploaded } from './models/FilesUploaded';
import { MemberData } from './models/MemberData';
import { MessagesViewed } from './models/MessagesViewed';
import { Environment } from '@environments/environment';
import { ViewFileDialogComponent } from '@components/view-file-dialog/view-file-dialog.component';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss'],
})
export class ChatsComponent extends BaseComponent implements OnInit, OnDestroy {
  private _authUser: any;
  private encKey:any = CryptoJS.enc.Utf8.parse('3s6v9y/BE(H+MbQeThWmZq4t7w!z%C&1');
  private encIv:any = CryptoJS.enc.Base64.parse('MTIzNDU2Nzg5MDEyMzQ1Ng==');
  private currentChatMembers:any = [];
  private hideSnackBarNotifications: boolean = false;
  public uuid: string;
  public productId: string;
  public currentChat: any = false;
  public globalUtil = GlobalUtil;
  public messageFormGroup: FormGroup;
  public chatFormGroup: FormGroup;
  public data: any;
  public dataLoaded = false;
  public chatGroups$: Observable<DialogData[]>;
  public chatMessages$: Observable<ChatData[]>;
  public chatMembers$: Observable<MemberData[]>;
  public chatUserCircles = [];
  public chatUserGroups = [];
  public selectedChat = null;
  public updateNewMessage: string;
  public chatisSelected = false;
  public myToken = localStorage.getItem('sessionToken');
  public userGroups: any = [];
  public userCircles: any = [];
  public chatCreatedBy: string;
  public chatCreatedByUserdata: any = [];
  public panelOpenState = false;
  public publicKey: string = '';
  public secretKey: string = '';
  public imageToPreview: string = null;
  public secureFiles: boolean = false;
  public isSpAdmin: boolean = false;
  public filesBaseUrl: string = 'https://ucarecdn.com/';

  public numberOfMembers = 0;
  public numberOfAssociatedContacts = 0;
  public numberOfUserGroups = 0;
  public numberOfUserCircles = 0;
  public numberOfTeamChats = 0;
  public numberOfPersonalChats = 0;
  public numberOfArchivedChats = 0;
  public numberOfMessages: any = [];
  public groupbygroupedid: any = [];
  public messagesviewed?: Array<MessagesViewed> = [];
  public chatfilesupload: any = [];
  public groupUsers: any = [];
  indexExpanded = -1;
  public viewedMessages = 0;
  isShown = false;
  public hugefilteredResult: any = [];
  // Loaded chat data
  public groupchatName: string;
  public groupchatDescription: string;
  public groupchatLogo: string;
  public groupchatId: string;
  public fbPrefix: string = '';
  @ViewChild('uploader', { static: false }) uploader: any;
  control = new FormControl();
  constructor(
    private chatService: ChatService,
    private formBuilder: FormBuilder,
    public firestore: AngularFirestore,
    public dialog: MatDialog,
    public router: Router,
    public groupService: GroupService,
    public userCircleService: UserCircleService,
    public snackbarService: SnackBarService,
    public userService: UserService,
    public state: State,
    public uploadService: UploadService,
    private sportsologySuperUserGuard: SportsologySuperUserGuard,
    private cdr: ChangeDetectorRef,
    // @Inject(FIREBASE_REFERENCES.ONE_FIRESTORE) private readonly main: AngularFirestore,
    @Inject(FIREBASE_REFERENCES.TWO_FIRESTORE) private chat: AngularFirestore
  ) {
    super();
    this.setObjects({
      modelService: chatService,
      userService,
      router,
      state,
      snackbarService,
      dialog,
    });
    if(Environment.stage) {
      this.fbPrefix = 'stage_';
    }
    this._authUser = this.state.get('user');
    this.uuid = this._authUser.uuid;
    this.productId = this.state.get('productId');
    this.groupListenList();
    this.chatListenList();
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.isSpAdmin = this.sportsologySuperUserGuard.userIsSportsologySuperUser();

    this.publicKey = this.uploadService.publicKey;
    this.secretKey = this.uploadService.secretKey;
    this.secureFiles = this.uploadService.secure;
    if(this.secureFiles === true) {
      this.filesBaseUrl = Environment.filesURL;
    }
    this.chatGroups$.subscribe((result) => {
      const groupByKey = (list, key, { omitKey = false }) =>
        list.reduce(
          (hash, { [key]: value, ...rest }) => ({
            ...hash,
            [value]: (hash[value] || []).concat(omitKey ? { ...rest } : { [key]: value, ...rest }),
          }),
          {}
        );
      const groups = groupByKey(result, 'id', { omitKey: true });
    });
    this.chatMessages$.subscribe((result) => {
      const groupByKey = (list, key, { omitKey = false }) =>
        list.reduce(
          (hash, { [key]: value, ...rest }) => ({
            ...hash,
            [value]: (hash[value] || []).concat(omitKey ? { ...rest } : { [key]: value, ...rest }),
          }),
          {}
        );
      const numbers = groupByKey(result, 'groupchatId', { omitKey: true });
      this.numberOfMessages = numbers;
      this.groupbygroupedid = numbers;
      const arraymessagesviewed = [];
      for (const groupkeys of Object.keys(numbers)) {
        const docRef = this.chat
          .collection(this.fbPrefix + 'chatgroup')
          .doc(this.productId)
          .collection('groups')
          .doc(groupkeys)
          .collection('invited')
          .doc(this.uuid)
          .ref.get()
          .then(async doc => {
            const dataset = doc.data() as MemberData;

            if(dataset !== undefined && !! dataset.viewedMessages) {
              this.viewedMessages = dataset.viewedMessages;
              arraymessagesviewed.push({ groupID: groupkeys, viewedMessages: this.viewedMessages });
            }
          });
      }

      this.messagesviewed = arraymessagesviewed;
    });
    this.getGroups();
    this.getUserCircles();
    this.dataLoaded = true;
  }

  public fileupload() {
    this.uploader.openDialog();
  }

  toggleshowgroups() {
    this.isShown = !this.isShown;
  }

  searchContacts(event: any)
  {
    const value = event.target.value;

    const modified = this.userGroups
      .map(function (ComparisonResult) {
        ComparisonResult.filteredUsers = ComparisonResult.list.filter((x) => x.name.toLowerCase().includes(value.toLowerCase()));
        return ComparisonResult;
      })
      .filter(({ list }) => list.length);
    this.hugefilteredResult = modified;
  }

  uploadProgress(payload) {
    this.dataLoaded = false;
  }

  public async showGroupUsers(i) {
    this.indexExpanded = i === this.indexExpanded ? -1 : i;
    this.groupUsers = this.userGroups[i].list;
  }

  public async fileuploadcomplete(payload) {
    this.dataLoaded = true;
    if (payload.isImage) {
      this.imageToPreview = `${this.filesBaseUrl}${payload.uuid}/-/preview/-/resize/50x/`;
    } else if (payload.mimeType === 'application/pdf') {
      document
        .getElementById('preview')
        .insertAdjacentHTML('beforeend', `<img src="https://www.compliant-epc.co.uk/wp-content/uploads/2021/03/PDF.png" height="45px" />`);
    } else if (payload.mimeType === 'video/quicktime') {
      document
        .getElementById('preview')
        .insertAdjacentHTML(
          'beforeend',
          `<img src="https://secure.webtoolhub.com/static/resources/icons/set32/c2fa6cd9.png" height="45px" />`
        );
    } else if (payload.mimeType === 'video/mp4') {
      document
        .getElementById('preview')
        .insertAdjacentHTML(
          'beforeend',
          `<img src="https://w7.pngwing.com/pngs/42/516/png-transparent-mpeg-4-part-14-logo-data-compression-avi-atilde-o-miscellaneous-text-mp3.png" height="45px" />`
        );
    } else {
      document
        .getElementById('preview')
        .insertAdjacentHTML('beforeend', `<img src="https://www.iconsdb.com/icons/preview/black/blank-file-xxl.png" height="45px" />`);
    }

    await this.chatfilesupload.push({ fileuuid: payload.uuid, mimeType: payload.mimeType });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public getGroups() {
    this.groupService.userGroups().subscribe(userGroups => this.userGroups = userGroups);
  }

  public getUserCircles() {
    this.userCircleService.getUserCiclesWithMembersList()
      .subscribe(userCircles => this.userCircles = userCircles);
  }

  // Open a new dialogue window to create a chat
  createNewChatWindow(): void
  {
    const newChat = this.dialog.open(CreateNewchatDialogueComponent, {
      data: { uuid: this.uuid, productid: this.productId },
    });

    newChat.afterClosed().subscribe(result => this.createnewChat(result));
  }

  // Edit chat details dialog
  editGroupDetails(): void
  {
    const docRef = this.chat
      .collection(this.fbPrefix + 'chatgroup')
      .doc(this.productId)
      .collection('groups')
      .doc(this.groupchatId);

    docRef.get().subscribe(snapshot => {
      const editChat = this.dialog.open(CreateNewchatDialogueComponent, {
        data: snapshot.data(),
      });

      editChat.afterClosed().subscribe(result => {
        docRef.update({
          groupDescription: result.groupDescription,
          groupName: result.groupName,
          groupIcon: result.groupIcon
        });
      });
    });
  }

  addChatUsers(): void {
    const addUsers = this.dialog.open(AddChatUsersComponent, {
      data: { userGroups: this.userGroups, productID: this.productId, groupChatID: this.groupchatId },
    });

    addUsers.afterClosed().subscribe((result) => {
    });
  }

  addChatUserCircles(): void {
    const addUserCircles = this.dialog.open(AddChatUserCirclesComponent, {
      data: { userCircles: this.userCircles, productID: this.productId, groupChatID: this.groupchatId },
    });

    addUserCircles.afterClosed().subscribe((result) => {
      // console.log(result);
    });
  }

  deleteUserCircle(userCircle)
  {
    this.chat
      .collection(this.fbPrefix + 'chatgroup')
      .doc(this.productId)
      .collection('groups')
      .doc(this.groupchatId)
      .update({ circles_id: firebase.firestore.FieldValue.arrayRemove(userCircle.uuid) });
    userCircle.list.forEach(user => this.deleteUser(user.uuid, this.groupchatId));
  }

  editAdmins(): void {
    // console.log(this.groupchatId);
    const editAdmins = this.dialog.open(EditAdminsComponent, {
      panelClass: 'add-user',
      width: '650px',
      height: '70vh',
      data: { userGroups: this.userGroups, productID: this.productId, groupChatID: this.groupchatId },
    });

    editAdmins.afterClosed().subscribe((result) => {
      // console.log('The dialog was closed');
    });
  }

  deleteChat(group) {

    const deleteChat = this.dialog.open(ConfirmationDialogComponent, { data: 'Delete Chat' });

    deleteChat.afterClosed().subscribe(result => {
      if (result) {
        const docRef = this.chat
          .collection(this.fbPrefix + 'chatgroup')
          .doc(this.productId)
          .collection('groups')
          .doc(group.id);

        docRef.get()
          .subscribe(chatSnapshot => {
            this.chat
              .collection(this.fbPrefix + 'chatsremoved')
              .doc(this.productId)
              .collection('groups')
              .doc(group.id)
              .set(chatSnapshot.data());
            docRef.delete();

            this.chatisSelected = false;
            this.numberOfMembers = 0;
            this.numberOfAssociatedContacts = 0;
            this.selectedChat = null;
            this.groupchatId = null;
            this.dataLoaded = true;
          });
      }
    });
  }

  deleteUser(user, group_uuid) {
    const invitedDocRef = this.chat.collection(this.fbPrefix + 'chatgroup')
      .doc(this.productId)
      .collection('groups')
      .doc(group_uuid)
      .collection('invited')
      .doc(user);

    const invitedUser = invitedDocRef.get().subscribe(snapshot => {
      const data = snapshot.data();
      if(!! data.associated_recipients && data.associated_recipients.length > 0) {
        data.associated_recipients
          .forEach(associatedUser => this.deleteUser(associatedUser.recipient_uuid, group_uuid));

      }
      invitedDocRef.delete();
    });

    this.chat
      .collection(this.fbPrefix + 'chatgroup')
      .doc(this.productId)
      .collection('groups')
      .doc(group_uuid)
      .update({
        members: firebase.firestore.FieldValue.arrayRemove(user),
      });
  }

  // Create Private chat
  createPrivateChat(datareceived) {
    this.isShown = false;
    this.dataLoaded = false;
    this.chatisSelected = false;
    this.numberOfMembers = 0;
    this.numberOfAssociatedContacts = 0;
    this.selectedChat = null;
    this.groupchatId = null;
    this.chatUserCircles = [];
    this.chatUserGroups = [];

    const _userData = this.state.get('user');
    const myidset = _userData.uuid;
    const newid = _userData.uuid + '+' + datareceived;

    const groupName = 'Private';
    const groupDescription = 'Private Chat';
    const groupIcon =
      'https://ucarecdn.com/c172f101-9f49-4fa7-9eee-2f51aaee7345/-/preview/1162x693/-/setfill/ffffff/-/format/jpeg/-/progressive/yes/';
    const addusertogroup = this.userService.alluserdata(datareceived).toPromise();
    addusertogroup.then((userData: any) => {
      this.chat
        .collection(this.fbPrefix + 'chatgroup')
        .doc(this.productId)
        .collection('groups')
        .doc(newid)
        .set({
          groupName: groupName,
          groupDescription: groupDescription,
          groupIcon: groupIcon,
          groupType: 1,
          timeStamp: Math.floor(Date.now()),
          createdBy: this.uuid,
          messageCount: 0,
          Createdbyfirstname: _userData.firstname,
          Createdbylastname: _userData.lastname,
          avatar: _userData.avatar,
          role: _userData.role,
          ProductID: this.productId,
          IsPersonal: true,
          withuser: userData,
          isArchived: false,
          members: [],
          viewedMembers: [],
        });
    });
    addusertogroup.then(
      (userData: any) => {
        this.chat
          .collection(this.fbPrefix + 'chatgroup')
          .doc(this.productId)
          .collection('groups')
          .doc(newid)
          .update({
            members: firebase.firestore.FieldValue.arrayUnion(this.uuid),
            [`viewedMembers.${_userData.uuid}`]: { viewed: 0 },
          });
        this.chat
          .collection(this.fbPrefix + 'chatgroup')
          .doc(this.productId)
          .collection('groups')
          .doc(newid)
          .update({
            members: firebase.firestore.FieldValue.arrayUnion(datareceived),
            [`viewedMembers.${datareceived}`]: { viewed: 0 },
          });
        this.chat.collection(this.fbPrefix + 'chatgroup').doc(this.productId).collection('groups').doc(newid).collection('invited').doc(datareceived).set({
          firstname: userData.firstname,
          lastname: userData.lastname,
          email: userData.email,
          uuid: userData.uuid,
          avatar: userData.avatar,
          viewedMessages: this.viewedMessages,
        });
        this.chat.collection(this.fbPrefix + 'chatgroup').doc(this.productId).collection('groups').doc(newid).collection('invited').doc(this.uuid).set({
          firstname: _userData.firstname,
          lastname: _userData.lastname,
          uuid: _userData.uuid,
          email: _userData.email,
          avatar: _userData.avatar,
          viewedMessages: this.viewedMessages,
        });
      },
      (error) => {
        console.log('Promise rejected with ' + JSON.stringify(error));
      }
    );
    const promise = this.userService.alluserdata(this.uuid).toPromise();
    promise.then(
      (chatCreatedByUserdata: any) => {
        this.chatCreatedByUserdata = chatCreatedByUserdata;
        this.groupchatName = groupName;
        this.groupchatDescription = groupDescription;
        this.groupchatLogo = groupIcon;
        this.groupchatId = newid;
        this.chatCreatedBy = this.uuid;
        this.selectedChat = newid;
        this.chatMembers$ = this.chat
          .collection<MemberData>(this.fbPrefix + 'chatgroup')
          .doc(this.productId)
          .collection('groups')
          .doc(this.groupchatId)
          .collection('invited')
          .snapshotChanges()
          .pipe(
            map((actions) =>
              actions.map((a) => {
                this.numberOfMembers = actions.length;
                const data = a.payload.doc.data() as MemberData;
                const id = a.payload.doc.id;
                return { id, ...data };
              })
            )
          );
        this.chatMembers$.subscribe();
        if (this.numberOfMessages[newid] !== undefined) {
          this.numberOfMessages[newid] = this.numberOfMessages[newid].length;
        }

        this.chatisSelected = true;
        this.dataLoaded = true;
      },
      (error) => {
        console.log('Promise rejected with ' + JSON.stringify(error));
      }
    );
  }

  public createnewChat(data: any) {
    const newid = GlobalUtil.uuidv4();
    if (!data.groupIcon) {
      data.groupIcon = Environment.spIdURL + '/images/group-icon.png';
    }

    if (!data.groupType) {
      data.groupType = '0';
    }

    const _userData = this.state.get('user');
    const newChatData = {
      groupName: data.groupName,
      groupDescription: data.groupDescription,
      groupIcon: data.groupIcon,
      groupType: Number(data.groupType),
      timeStamp: Math.floor(Date.now()),
      createdBy: this.uuid,
      ProductID: this.productId,
      IsPersonal: false,
      isArchived: false,
      firstname: '',
      messageCount: 0,
      members: [],
      viewedMembers: { [this.uuid]: { viewed: 0 }},
      Createdbyfirstname: _userData.firstname,
      Createdbylastname: _userData.firstname,
      lastname: '',
      withuser: { firstname: '', lastname: '' },
    };

    const newChat = this.chat
      .collection(this.fbPrefix + 'chatgroup')
      .doc(data.productid)
      .collection('groups')
      .doc(newid);

    newChat.set(newChatData).then(fullFilledChatData => {
      newChat.get().subscribe(snapshot => {
        var data = snapshot.data();
        data.id = newid;
        this.setChatWindow(data, true)
      });
    });

    const addusertogroup = this.userService.alluserdata(this.uuid).toPromise();
    addusertogroup.then(
      (userData: any) => {
        this.chat.collection(this.fbPrefix + 'chatgroup').doc(data.productid).collection('groups').doc(newid).collection('invited').doc(this.uuid).set({
          firstname: userData.firstname,
          lastname: userData.lastname,
          email: userData.email,
          uuid: userData.uuid,
          avatar: userData.avatar,
          group: userData.group.name,
          viewedMessages: this.viewedMessages,
        });

        this.chat
          .collection(this.fbPrefix + 'chatgroup')
          .doc(data.productid)
          .collection('groups')
          .doc(newid)
          .update({
            members: firebase.firestore.FieldValue.arrayUnion(this.uuid),
          });

        const docRef:any = this.chat
          .collection(this.fbPrefix + 'chatgroup')
          .doc(data.productid)
          .collection('groups')
          .doc(newid);

        this.subscribeToChanges(docRef);
      },
      (error) => {
        console.log('Promise rejected with ' + JSON.stringify(error));
      }
    );
  }

  //get data for private chat, we can use this later to tidy rest up
  public getuserDetails(id: any) {
    const promise = this.userService.alluserdata(id).toPromise();
    promise.then(
      (data: any) => {
        return data.firstname;
      },
      (error) => {
        console.log('Promise rejected with ' + JSON.stringify(error));
      }
    );
  }

  //TODO first get the group list and if not empty we can pass the groupIDs to an array and then return the members list
  // this shoudl allow us to view the members viewd message number
  public groupListenList() {
    this.chatGroups$ = this.chat
      .collection<DialogData>(this.fbPrefix + 'chatgroup')
      .doc(this.productId)
      // .collection('groups', (ref) => {
      //   ref.where('createdBy', '==', this.uuid);
      //   ref.where('members', 'array-contains', this.uuid);
      //   ref.orderBy('timeStamp', 'desc');
      //   return ref;
      // })
      .collection('groups', (ref) => ref.orderBy('timeStamp', 'desc'))
      .snapshotChanges()
      .pipe(
        map((actions) => {
          var chatGroups =  actions.map((a) => {
            const data = a.payload.doc.data() as DialogData;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
          // Make sure logged in user can only see chats they are part of
          chatGroups = chatGroups
            .filter(chatGroup =>
              chatGroup.createdBy === this.uuid
              ||
              (chatGroup as any).members.includes(this.uuid)
            );
          this.numberOfTeamChats = chatGroups
            .filter((chatGroup:any) => ! chatGroup.IsPersonal && ! chatGroup.isArchived)
            .length;
          this.numberOfPersonalChats = chatGroups
            .filter((chatGroup:any) => chatGroup.IsPersonal && ! chatGroup.isArchived)
            .length;
          this.numberOfArchivedChats = chatGroups
            .filter((chatGroup:any) => chatGroup.isArchived)
            .length;
          return chatGroups;
        })
      );
  }

  public chatListenList() {
    this.chatMessages$ = this.chat
      .collection<ChatData>(this.fbPrefix + 'chats')
      .doc(this.productId)
      .collection('messages', ref => {

        return ref.orderBy('timeStamp', 'asc');

        // return ref
        //   .where('groupchatId', '==', this.groupchatId)
        //   // .limit(10)
        //   .orderBy('timeStamp', 'asc')
        //   .startAt(0)
        //   .endAt(10)
        //   ;
      })
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as ChatData;
            const id = a.payload.doc.id;
            const decrypted = CryptoJS.AES.decrypt(data.message, this.encKey, { iv: this.encIv });
            try {
              data.message = decrypted.toString(CryptoJS.enc.Utf8);
            }
            catch {

            }
            return { id, ...data };
          })
        )
      );
  }

  public membersListenList() {
    this.chatMembers$ = this.chat
      .collection<MemberData>(this.fbPrefix + 'chatgroup')
      .doc(this.productId)
      .collection('groups')
      .doc(this.groupchatId)
      .collection('invited')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as MemberData;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  public async setChatWindow(groupdata, openAddUsers = false) {
    for (const goupkeys of Object.keys(this.groupbygroupedid)) {
      // console.log(goupkeys); // prints values: 10, 20, 30, 40
    }

    if (this.numberOfMessages[groupdata.id] !== undefined) {
      const messagenumber = this.numberOfMessages[groupdata.id].length;
      // Update the message count on member
      if (messagenumber !== undefined) {
        this.chat
          .collection<MemberData>(this.fbPrefix + 'chatgroup')
          .doc(this.productId)
          .collection('groups')
          .doc(groupdata.id)
          .collection('invited')
          .doc(this.uuid)
          .update({
            viewedMessages: messagenumber,
          });
      }
    }

    this.dataLoaded = false;
    this.chatisSelected = false;
    this.numberOfMembers = 0;
    this.numberOfAssociatedContacts = 0;
    this.selectedChat = null;
    this.groupchatId = null;
    this.chatUserCircles = [];
    this.chatUserGroups = [];

    this.userService.alluserdata(groupdata.createdBy).subscribe((chatCreatedByUserdata) => {
      this.chatCreatedByUserdata = chatCreatedByUserdata;
      this.groupchatName = groupdata.groupName;
      this.groupchatDescription = groupdata.groupDescription;
      this.groupchatLogo = groupdata.groupIcon;
      this.groupchatId = groupdata.id;
      this.chatCreatedBy = groupdata.createdBy;
      this.selectedChat = groupdata.id;

      // this.chatListenList();

      const docRef = this.chat
        .collection<MemberData>(this.fbPrefix + 'chatgroup')
        .doc(this.productId)
        .collection('groups')
        .doc(this.groupchatId);

      this.chatMembers$ = docRef
        .collection('invited')
        .snapshotChanges()
        .pipe(map((actions) => {
          const members = actions.map((a) => {
            const data = a.payload.doc.data() as MemberData;
            const id = a.payload.doc.id;
            return { id, ...data };
          });

          this.numberOfMembers = members.filter((member:any) => ! member.isAssociatedUser).length;
          this.numberOfAssociatedContacts = members
            .filter((member:any) => (!! member.isAssociatedUser && member.isAssociatedUser ===true))
            .length;
          return members;
        }
        ))
        .pipe(map((members) =>
          members.sort((a, b) => {
            if (a.firstname < b.firstname) {
              return -1;
            }
            if (a.firstname > b.firstname) {
              return 1;
            }
            return 0;
          })
        ));

      this.subscribeToChanges(docRef);

      this.chatMembers$.subscribe(members => this.currentChatMembers = members);

      if (this.numberOfMessages[groupdata.id] !== undefined) {
        this.numberOfMessages[groupdata.id] = this.numberOfMessages[groupdata.id].length;
      }

      this.chatisSelected = true;
      this.dataLoaded = true;
      this.cdr.detectChanges();
      if(openAddUsers) {
        this.addChatUsers();
      }
    });
  }

  public subscribeToChanges(docRef) {
    docRef
      .valueChanges().subscribe((doc:any) => {

        if(!! doc.circles_id) {
          this.numberOfUserCircles = doc.circles_id.length;
          this.chatUserCircles = this.userCircles
            .filter(userCircle => doc.circles_id.includes(userCircle.uuid));
        }

        if(!! doc.groups_id) {
          this.numberOfUserGroups = doc.groups_id.length;
          this.chatUserGroups = this.userGroups
            .filter(userGroup => doc.groups_id.includes(userGroup.uuid));
        }

        this.groupchatName = doc.groupName;
        this.groupchatDescription = doc.groupDescription;
        this.groupchatLogo = doc.groupIcon;
      });
  }

  public deleteUserGroup(userGroupUuid, groupChatId) {

  }

  public updateMessageValue(event) {
    this.updateNewMessage = event;
  }

  public URLReplacer(str) {
    const match = str.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi);
    let final = str;
    if (match !== null) {
      match.map((url) => {
        final = final.replace(url, '<a href="' + url + '" target="_BLANK">' + url + '</a>');
      });
      return final;
    }

    return str;
  }

  public async sendMessage(messageData) {
    document.getElementById('preview').innerHTML = '';
    let uploadedfiles = null;
    let messageBasic:string = '';
    if (this.chatfilesupload != null && this.chatfilesupload.length > 0) {
      uploadedfiles = await this.chatfilesupload;
      messageBasic = 'File uploaded';
    } else {
      messageBasic = messageData;
    }

    let message = await this.URLReplacer(messageData);

    const encrypted = CryptoJS.AES.encrypt(message, this.encKey, { iv: this.encIv });
    const encryptedMessage = encrypted.toString();

    this.updateNewMessage = '';
    const _userData = this.state.get('user');
    this.chat
      .collection(this.fbPrefix + 'chats')
      .doc(this.productId)
      .collection('messages')
      .add({
        userID: this.uuid,
        timeStamp: Math.floor(Date.now()),
        groupchatId: this.groupchatId,
        firstName: _userData.firstname,
        lastName: _userData.lastname,
        avatar: _userData.avatar,
        role: _userData.role,
        files: uploadedfiles,
        message: encryptedMessage,
      });

    const uuids = this.currentChatMembers.map(member => member.id);

    const index = uuids.indexOf(this.uuid);
    if (index > -1) {
      uuids.splice(index, 1);
    }

    this.state.message({ hideSnackBarNotifications: true });

    this.chatService.sendPush({
      send_as_push: true,
      chat_uuid: this.groupchatId,
      recipient_uuids: uuids,
      chat_title: this.groupchatName,
      title: '',
      body: messageBasic,
      send_user_name: _userData.firstname + ' ' + _userData.lastname,
      sending_user_uuid: this.uuid,
    })
    .toPromise()
    .then(res => this.state.message({ hideSnackBarNotifications: false }));

    this.imageToPreview = null;
    this.chatfilesupload.length = 0;
    this.cdr.detectChanges();
  }

  public archiveChat() {
    // userGroups: this.userGroups, productID: this.productId, groupChatID: this.groupchatId
    this.dataLoaded = false;
    this.chatisSelected = false;

    this.chat.collection(this.fbPrefix + 'chatgroup').doc(this.productId).collection('groups').doc(this.groupchatId).update({
      isArchived: true,
    });
    this.numberOfMembers = 0;
    this.numberOfAssociatedContacts = 0;
    this.selectedChat = null;
    this.groupchatId = null;
    this.dataLoaded = true;
  }

  public viewChatsFileDialog(uuid, type) {
    var dialogRef:any;
    var document:any;
    var fileType:any;

    if(type.includes('video')) {
      fileType = 'video';
    }
    if(type.includes('audio')) {
      fileType = 'audio';
    }
    if(type.includes('pdf')) {
      fileType = 'application/pdf';
    }
    if(type.includes('image')) {
      fileType = 'image';
    }
    if(! fileType) {
      fileType = 'office';
    }
    if(this.uploadService.secure) {
      this.uploadService.getUcareSecureUrl(uuid).subscribe((result:any) => {
        document = {
          file_type: fileType,
          open_from_chats: true,
          url: result.url
        }
        dialogRef = this.dialog.open(ViewFileDialogComponent, { data: { document: document }});
      });
    } else {
      document = {
        file_type: fileType,
        open_from_chats: true,
        url: this.filesBaseUrl + uuid + '/'
      }
      dialogRef = this.dialog.open(ViewFileDialogComponent, { data: { document: document }});
    }
  }
}

@Component({
  selector: 'create-new-chat-dialog',
  templateUrl: './create-new-chat-dialog.html',
})
export class CreateNewchatDialogueComponent
{
  groupIcon: string;
  publicKey: string;
  filesBaseUrl: string;

  constructor(
    public dialogRef: MatDialogRef<CreateNewchatDialogueComponent>,
    public uploadService: UploadService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.data = data;
    this.publicKey = this.uploadService.publicKey;
    this.filesBaseUrl = this.uploadService.baseUrl;
  }

  saveDisabled() {
    const res =
      (!! this.data.groupDescription && this.data.groupDescription.length > 0)
      &&
      (!! this.data.groupName && this.data.groupName.length > 0);
    return res;
  }

  uploadComplete(payload: any) {
    const fileUUID = payload.uuid;
    this.groupIcon = fileUUID;
    this.data.groupIcon = `${this.filesBaseUrl}${fileUUID}/-/preview/-/resize/350x/`;
  }
}
