import { Component, Input } from '@angular/core';
@Component({
  selector: 'view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class AppViewComponent {
  private _id = '';
  @Input() set id(id: string) { this._id = id; }
  get id(): string { return this._id; }

  private _title = '';
  @Input() set title(title: string) { this._title = title; }
  get title(): string { return this._title; }
}
