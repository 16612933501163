import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { PageService } from '@services/page.service';
import { SidebarService } from '@services/sidebar.service';
import { PAGE_FILTER_FIELDS } from './filter-fields';
@Component({
  selector: 'app-pages-index',
  templateUrl: './pages-index.component.html',
  styleUrls: ['./pages-index.component.scss', './pages-index-700.component.scss'],
  providers: [SidebarService]
})
export class PagesIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['select', 'name', 'created_by_user.name', 'status', 'created_at', 'actions'];
  public showSearch = false;
  public searchPlaceholder = 'Search Pages...';
  public dataLoaded = false;
  public apps: [];
  public fields: any = PAGE_FILTER_FIELDS;
  constructor(
    public pageService: PageService,
    public router: Router,
    public sidebarService: SidebarService,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: pageService,
      router,
      sidebarService,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      sidebar: 'pages',
      listenForIndexUpdate: true,
    });
    this.modelService.getFromApi('apps-v2/list').subscribe(appsList => this.apps = appsList);
    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }

  // ngOnInit(): void {
  //   super.ngOnInit();
  //   this.openFullWidthOverlayWithLink('app-pages', 'edit/57fcd27f-1a34-4c35-9e0c-5ee605878f22');
  //   this.dataLoaded = true;
  //   // this.openFullWidthOverlayWithLink('article-pages', 'create');
  // }

  public updatedStatus(): void {
    this.state.message({ ['PageIndexUpdate']: true });
  }
}
