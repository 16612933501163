import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { UserCircleService } from '@services/user-circle.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
@Component({
  selector: 'app-user-circles-index',
  templateUrl: './user-circles-index.component.html',
  styleUrls: ['./user-circles-index.component.scss']
})
export class UserCirclesIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['name', 'user_count', 'created_by_user', 'created_at', 'actions'];
  public dataLoaded = false;
  public node: Breadcrumb;
  constructor(
    public userCircleService: UserCircleService,
    public router: Router,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: userCircleService,
      router,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }

  navigateToView(url) {
    this.router.navigateByUrl(url);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.node = {
      label: 'User Circles',
      link: 'user-circles',
      title: 'User Circles'
    };
    this.buildSettingsBreadcrumb(this.node);
  }
}
