import { FormArray } from '@angular/forms';
import { State } from '@app/state';

export interface IBundle {
  uuid?: string,
  items: Array<IBundleItem>,
  description: string | null,
  featured_image_cdn_url: string | null,
  featured_image_cdn_uuid: string | null,
  featured_image_height: number | null,
  featured_image_width: number | null,
  internal_title: string,
  published: boolean,
  teaser: string,
  title: string
}

export interface IBundleItem {
  article?: object,
  article_category?: object,
  article_category_uuid?: string,
  article_uuid?: string,
  bundle_item_type: string,
  description?: string,
  directory_category?: object,
  directory_category_uuid?: string,
  directory_item?: object,
  directory_item_uuid?: string,
  display_order: number,
  featured_image?: string,
  featured_image_cdn_url?: string,
  featured_image_cdn_uuid?: string,
  featured_image_height?: number,
  featured_image_width?: number,
  force_open_url?: boolean,
  internal_title?: string,
  name?: string,
  open_in_app_browser?: boolean,
  page?: object,
  page_uuid?: string,
  published?: boolean,
  sub_bundle?: boolean,
  sub_bundle_uuid?: string
  teaser?: string,
  title?: string,
  url?: string,
  uuid?: string
}

export class BundleItem
{
  public uuid: string;
  public displayOrder: number;

  private _bundleItem: IBundleItem;
  private _featured_image_cdn_url: string;
  private _featured_image_cdn_uuid: string;
  private _name: string;
  private _title: string;
  private _type: string;
  private _baseUrl: string = '';

  constructor(private bundleItem: IBundleItem, private _state: State) {
    const settings = this._state.get('ccSettings');
    this._baseUrl = settings.uploadcare.url;

    this.uuid = bundleItem.uuid;
    this.displayOrder = bundleItem.display_order;

    this._bundleItem = bundleItem;
    this._featured_image_cdn_url = bundleItem.featured_image_cdn_url;
    this._featured_image_cdn_uuid = bundleItem.featured_image_cdn_uuid;
    this._name = bundleItem.name;
    this._title = bundleItem.title;

    // fix for odd naming of related item
    if (bundleItem.bundle_item_type === 'directory_item_category') {
      this._type = 'directory_category';
    } else {
      this._type = bundleItem.bundle_item_type;
    }
  }

  get title(): string {
    if (this._title) {
      return this._title;
    }
    if (!! this.linkedItem) {
      return this.linkedItemTitle;
    }
  }

  get type(): string {
    var title: string =  this._type.replace(/_/g, ' ')
      .replace(/(^|\s)\S/g, _type => _type.toUpperCase());
    if (title === 'Sub Bundle') {
      title = 'Bundle';
    }
    return title;
  }

  get linkedItemTitle(): any {
    if (!! this.linkedItem) {
      return !! this.linkedItem.name
        ? this.linkedItem.name
        : this.linkedItem.title;
    }
    return null;
  }

  get linkedItem(): any {
    var type = this._type;
    if (type === 'bundle') {
      type = 'sub_bundle';
    }
    return this._bundleItem[type];
  }

  set linkedItem(item) {
    this._bundleItem[this._type] = item;
  }

  get featuredImage(): string {
    var url = null;
    if(!! this._featured_image_cdn_url) {
      url = this._featured_image_cdn_url;
    }
    if (!! this._featured_image_cdn_uuid) {
      url = `${this._baseUrl}${this._featured_image_cdn_uuid}`;
    }
    if (!! this.linkedItem) {
      if (!! this.linkedItem.featured_image_cdn_url) {
        url = this.linkedItem.featured_image_cdn_url;
      }
      if (!! this.linkedItem.featured_image_url) {
        url = this.linkedItem.featured_image_url;
      }
      if (!! this.linkedItem.featured_image_cdn_uuid) {
        url = `${this._baseUrl}${this.linkedItem.featured_image_cdn_uuid}`;
      }
    }
    return url;
  }

  set featuredImage(url) {
    this._featured_image_cdn_url = url;
  }
}

export interface IContentTypeItem {
  uuid: string,
  app_name?: string | null,
  featured_image_cdn_uuid?: string | null,
  featured_image_url?: string | null,
  internal_title?: string | null,
  name?: string | null,
  title?: string | null
}

export class ContentTypeItem
{
  public uuid: string;

  public app_name: string;
  public featured_image_cdn_uuid: string;
  public featured_image_url: string;
  public internal_title: string;

  private _contentTypeItem: IContentTypeItem;
  private _name: string;
  private _title: string;

  get title(): string {
    if (this._title) {
      return this._title;
    }
    if (this._name) {
      return this._name;
    }
  }

  get featuredImage(): string {
    return this.featured_image_url;
  }

  constructor(private contentTypeItem: IContentTypeItem) {
    this.uuid = contentTypeItem.uuid;
    this.featured_image_url = contentTypeItem.featured_image_url;
    this.featured_image_cdn_uuid = contentTypeItem.featured_image_cdn_uuid;
    this.internal_title = contentTypeItem.internal_title;
    this.app_name = contentTypeItem.app_name;

    this._contentTypeItem = contentTypeItem;
    this._name = contentTypeItem.name;
    this._title = contentTypeItem.title;
  }
}

export interface IBundleContentType {
  icon: string,
  items?: Array<IContentTypeItem>,
  itemsUrl?: string,
  itemsGrouped?: boolean,
  loadingItems?: boolean,
  selected?: boolean,
  slug: string,
  title: string
}

export const bundleContentTypes: Array<IBundleContentType> = [{
  icon: 'article',
  items: [],
  itemsUrl: 'articles/list',
  itemsGrouped: false,
  loadingItems: false,
  selected: false,
  slug: 'article',
  title: 'Article'
}, {
  icon: 'view_list',
  items: [],
  itemsUrl: 'article-categories/list',
  itemsGrouped: false,
  loadingItems: false,
  selected: false,
  slug: 'article_category',
  title: 'Article Category'
}, {
  icon: 'dynamic_feed',
  items: [],
  itemsUrl: 'apps-v2/pages-list',
  itemsGrouped: true,
  loadingItems: false,
  selected: false,
  slug: 'page',
  title: 'App Page'
}, {
  icon: 'contact_page',
  items: [],
  itemsUrl: 'directory-items/list',
  itemsGrouped: false,
  loadingItems: false,
  selected: false,
  slug: 'directory_item',
  title: 'Directory Item'
}, {
  icon: 'import_contacts',
  items: [],
  itemsUrl: 'directory-categories/list',
  itemsGrouped: false,
  loadingItems: false,
  selected: false,
  slug: 'directory_category',
  title: 'Directory Category'
}, {
  icon: 'library_books',
  items: [],
  itemsUrl: 'bundles/list',
  itemsGrouped: false,
  loadingItems: false,
  selected: false,
  slug: 'sub_bundle',
  title: 'Bundle'
}, {
  icon: 'link',
  slug: 'url',
  title: 'Url'
}];