import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { CustomFieldService } from '@services/custom-field.service';
import { MODELS, FIELD_TYPES } from '../custom-field-type';

@Component({
  selector: 'app-custom-field-form',
  templateUrl: './custom-field-form.component.html',
  styleUrls: ['./custom-field-form.component.scss'],
})

export class CustomFieldFormComponent extends BaseComponent {

  customFieldFormGroup: FormGroup;
  dataLoaded = false;
  formSubmitted = false;
  models = MODELS;
  fieldTypes = FIELD_TYPES;

  constructor(
    public activatedRoute: ActivatedRoute,
    public customFieldService: CustomFieldService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      state: state,
      modelService: customFieldService,
    });

    const id = this.routeParams['fieldId'];
    const groupId = this.routeParams['groupId'];

    this.initialFormGroup(groupId);

    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result: any) => {
        this.dataLoaded = true;
        this.customFieldFormGroup.patchValue(result);
      });
    }
  }

  get controls(): any {
    return this.customFieldFormGroup.controls;
  }

  public getParentFieldPlaceHolder(): string {
    if (this.controls.type.value !== 'group') {
      return 'Parent field *';
    }
    return 'Parent field';
  }

  public save(): void {
    this.formSubmitted = true;
    const uuid = this.controls.uuid.value;
    this.customFieldFormGroup.patchValue({
      required: !!this.controls.required.value,
      active: !!this.controls.active.value,
    });
    if (uuid) {
      this.customFieldService.patchById(uuid, this.customFieldFormGroup.value)
        .subscribe((result) => this.SavedMessage(result));
    } else {
      this.customFieldService.create(this.customFieldFormGroup.value)
      .subscribe((result) => this.SavedMessage(result));
    }
    setTimeout(() => {
      this.formSubmitted = false;
    }, 1000);
  }

  /**
   * This form is called from CustomFieldGroupView component
   * So we need to tell state to update that page
   *
   * @param data
   */
  private SavedMessage(data) {
    this.state.message({
      ['CustomFieldGroupViewUpdate']: true,
      ['id']: this.routeParams['groupId'] ? this.routeParams['groupId'] : this.controls.parent_uuid.value
    });
    this.closeSlideout();
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  private initialFormGroup(groupId): void {
    this.customFieldFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      name: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      type: [null, [Validators.required]],
      // models: [null, [Validators.required]],
      parent_uuid: [groupId, [Validators.required]],
      instructions: [null, [Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      required: [null],
      active: [null],
    });
  }
}
