import { Component, Injectable, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { UserCalendarService } from '@services/user-calendar.service';
import { SelectionOption } from '@app/models/view-models/option';
@Component({
  selector: 'app-user-calendar-edit',
  templateUrl: './user-calendar-edit.component.html',
  styleUrls: ['./user-calendar-edit.component.scss'],
})
@Injectable()
export class UserCalendarEditComponent extends BaseComponent implements OnInit {
  @Input() uuid: string;
  public formGroup: FormGroup;
  public dataLoaded = false;
  private options = [];

  constructor(
    public activatedRoute: ActivatedRoute,
    public state: State,
    public userCalendarService: UserCalendarService,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      modelService: userCalendarService,
      route: activatedRoute,
      state: state,
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initialFormGroup();
    this.modelService.getAllWithId(this.uuid).subscribe((result: any) => {
      this.formGroup.patchValue({ 'calendars': result });
      this.dataLoaded = true;
    });
  }

  public save(): void {
    this.modelService.patchById(this.uuid, this.formGroup.value, 'user')
      .subscribe((result: any) => this.SavedMessage(result));
  }

  get controls(): any {
    return this.formGroup.controls;
  }

  public getSelectedOption(option: SelectionOption): void {
    this.options = this.controls.calendars.value;
    const val = option.value;
    if (option.selected === true) {
      this.options.push(val);
    } else {
      this.removedOption(val);
    }
    this.formGroup.patchValue({ 'calendars': this.options });
  }

  // All the private methods (Private method should be after all the public methods)
  private removedOption(val: string): void {
    const index: number = this.options.indexOf(val);
    if (index !== -1) {
      this.options.splice(index, 1);
    }
  }
  // Setup the initial form
  private initialFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      calendars: [null]
    });
  }

  // Use this method to validate the result from server after our request.
  private SavedMessage(data: any) {
    this.state.message({ ['UserCalendarFormUpdate']: true });
  }
}
