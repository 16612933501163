import { Component, OnInit, Input } from '@angular/core';
import { UploadService } from '@app/services/upload.service';

@Component({
  selector: 'app-secure-image',
  templateUrl: './secure-image.component.html',
  styleUrls: ['./secure-image.component.scss']
})
export class SecureImageComponent implements OnInit
{
  @Input() imageSrc:string = '';
  @Input() imageAlt:string = '';
  @Input() class:string = '';

  public imageUrl:string;
  public secureUrl:boolean = true;
  public secure:boolean = true;

  constructor(private _uploadService: UploadService) {
    this.secure = _uploadService.secure;
  }

  ngOnInit() {
    if(this.imageSrc.includes('/img/default-avatar.jpg')) {
      this.secure = false;
    }
    this.imageUrl = this.imageSrc;
  }
}