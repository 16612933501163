export const CONTENT_LOGS_FILTER_FIELDS = [
  {
    type: 'dropdown',
    name: 'subject_type',
    label: 'Content type',
    value: '',
    options: [
      {
        text: 'Article',
        value: 'App\\Article'
      },
      {
        text: 'Document',
        value: 'App\\Document'
      },
      {
        text: 'Event',
        value: 'App\\Event'
      },
      {
        text: 'Folder',
        value: 'App\\Folder'
      },
      {
        text: 'Contact Group',
        value: 'App\\ContactGroup'
      },
    ]
  },
  {
    type: 'dropdown',
    name: 'action',
    label: 'Action',
    value: '',
    options: [
      {
        text: 'Show',
        value: 'show'
      },
    ]
  },
  {
    type: 'dropdown_group',
    name: 'created_user_uuid',
    label: 'User',
    value: '',
    apiUrl: 'groups/members/list'
  },
  {
    type: 'date',
    name: 'created_at',
    label: 'Date',
    value: ''
  }
];
