import grapesjs from 'grapesjs';

import {
  PAGE_TYPE_CONTINUOUS,
  TYPE_APP_PAGE,
  TYPE_ARTICLE
} from './../../const/form';

import {
  BLOCK_BUTTON_APP_COMPONENT,
  BLOCK_BUTTON_APP_PAGE,
  BLOCK_BUTTON_ARTICLE,
  BLOCK_BUTTON_ARTICLE_CATEGORY,
  BLOCK_BUTTON_BUNDLE,
  BLOCK_BUTTON_CALENDAR,
  BLOCK_BUTTON_CHAT,
  BLOCK_BUTTON_CONTACT_GROUP,
  BLOCK_BUTTON_DIRECTORY_CATEGORY,
  BLOCK_BUTTON_DIRECTORY_ITEM,
  BLOCK_BUTTON_DOCUMENT,
  BLOCK_BUTTON_EVENT,
  BLOCK_BUTTON_FILE,
  BLOCK_BUTTON_FOLDER,
  BLOCK_BUTTON_PERIODISATION_CALENDAR,
  BLOCK_BUTTON_PLACEHOLDER,
  BLOCK_BUTTON_PRODUCT_URL,
  BLOCK_BUTTON_PROFILE_GROUP,
  BLOCK_BUTTON_URL,
  BLOCK_BUTTON_USER_CIRCLE,
  BLOCK_LAYOUT_ACCORDION,
  BLOCK_LAYOUT_DIVIDER,
  BLOCK_MEDIA_IMAGE_SLIDER,
  BLOCK_LAYOUT_PAGE_SECTION,
  BLOCK_LAYOUT_ROW,
  BLOCK_LAYOUT_SECTION,
  BLOCK_MEDIA_IMAGE,
  BLOCK_MEDIA_VIDEO,
  BLOCK_MEDIA_VIDEO_PLAYLIST,
  BLOCK_TEXT_BLOCK,
  // BLOCK_TEXT_BLOCK_OLD,
  // BLOCK_TEXT_BLOCK_TEST,
  BLOCK_TEXT_HEADING,
  BLOCK_WIDGET_ARTICLE_CATEGORY,
  BLOCK_WIDGET_BUNDLE,
  BLOCK_WIDGET_DIRECTORY_CATEGORY,
  BLOCK_WIDGET_EMBED,
  BLOCK_WIDGET_MAP
} from './blocks';

export const Blocks = grapesjs.plugins.add('blocks', (editor, options:any = {}) => {
  const { type } = options;
  const BlockManager = editor.BlockManager;

  if (type === TYPE_ARTICLE) {
    articleBlocks(BlockManager);
  }
  if (type === TYPE_APP_PAGE) {
    const { pageType } = options;

    appPageBlocks(BlockManager, pageType);
  }
});

const articleBlocks = (BlockManager) => {
  // Layout
  BLOCK_LAYOUT_ROW(BlockManager);
  BLOCK_LAYOUT_SECTION(BlockManager);
  // BLOCK_LAYOUT_DIVIDER(BlockManager);
  // Text
  // BLOCK_TEXT_HEADING(BlockManager);
  BLOCK_TEXT_BLOCK(BlockManager);
  // Media
  BLOCK_MEDIA_IMAGE(BlockManager);
  BLOCK_MEDIA_IMAGE_SLIDER(BlockManager);
  BLOCK_MEDIA_VIDEO(BlockManager);
  // Buttons
  BLOCK_BUTTON_ARTICLE(BlockManager);
  BLOCK_BUTTON_DOCUMENT(BlockManager);
  // BLOCK_BUTTON_EVENT(BlockManager);
  BLOCK_BUTTON_FOLDER(BlockManager);
  BLOCK_BUTTON_URL(BlockManager);
  // Widget
  BLOCK_WIDGET_EMBED(BlockManager);
  // BLOCK_WIDGET_MAP(BlockManager);
}

const appPageBlocks = (BlockManager, pageType) => {
  // Layout
  BLOCK_LAYOUT_SECTION(BlockManager);
  BLOCK_LAYOUT_ROW(BlockManager);
  BLOCK_LAYOUT_ACCORDION(BlockManager);
  // BLOCK_LAYOUT_DIVIDER(BlockManager);
  // Text
  // BLOCK_TEXT_HEADING(BlockManager);
  // BLOCK_TEXT_BLOCK_OLD(BlockManager);
  BLOCK_TEXT_BLOCK(BlockManager);
  // BLOCK_TEXT_BLOCK_TEST(BlockManager);
  // Media
  BLOCK_MEDIA_IMAGE(BlockManager);
  BLOCK_MEDIA_IMAGE_SLIDER(BlockManager);
  BLOCK_MEDIA_VIDEO(BlockManager);
  BLOCK_MEDIA_VIDEO_PLAYLIST(BlockManager);
  // Bundle / Articles
  BLOCK_WIDGET_ARTICLE_CATEGORY(BlockManager);
  BLOCK_WIDGET_BUNDLE(BlockManager);
  BLOCK_WIDGET_DIRECTORY_CATEGORY(BlockManager);
  // Buttons
  BLOCK_BUTTON_APP_COMPONENT(BlockManager);
  BLOCK_BUTTON_APP_PAGE(BlockManager);
  BLOCK_BUTTON_BUNDLE(BlockManager);
  BLOCK_BUTTON_CHAT(BlockManager);
  BLOCK_BUTTON_ARTICLE_CATEGORY(BlockManager);
  BLOCK_BUTTON_ARTICLE(BlockManager);
  BLOCK_BUTTON_URL(BlockManager);
  BLOCK_BUTTON_CALENDAR(BlockManager);
  BLOCK_BUTTON_PERIODISATION_CALENDAR(BlockManager);
  // BLOCK_BUTTON_EVENT(BlockManager);
  BLOCK_BUTTON_FOLDER(BlockManager);
  BLOCK_BUTTON_FILE(BlockManager);
  BLOCK_BUTTON_DIRECTORY_CATEGORY(BlockManager);
  BLOCK_BUTTON_DOCUMENT(BlockManager);
  // BLOCK_BUTTON_USER_CIRCLE(BlockManager);
  // BLOCK_BUTTON_DIRECTORY_ITEM(BlockManager);
  // BLOCK_BUTTON_PROFILE_GROUP(BlockManager);
  BLOCK_BUTTON_CONTACT_GROUP(BlockManager);
  BLOCK_BUTTON_PLACEHOLDER(BlockManager);
  BLOCK_BUTTON_PRODUCT_URL(BlockManager);
  // Widget
  BLOCK_WIDGET_EMBED(BlockManager);
  // BLOCK_WIDGET_MAP(BlockManager);
}