import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FileInfo } from '@app/models/entities/fileInfo';

import { GlobalUtil } from '@app/modules/common/shared/util/global-util';

@Component({
    selector: 'app-page-form-article-form',
    templateUrl: './article-form.component.html',
    styleUrls: ['./article-form.component.scss']
})
export class PageFormArticleFormComponent {
    public formReady: boolean = false;
    private tagCols: any;
    private directoryItemCols: any;
    private selectedTagIds = [];
    private selectedContactCardIds = [];

    private config = GlobalUtil.Configuration;

    @Input() pageFormGroup:any;
    @Input() formSubmitted:any;
    @Input() controls:any;

    @ViewChild('tags', {static: false}) private tagsInput;
    @ViewChild('directoryItems', {static: false}) private directoryItemsInput;

    @Output() pageFormSave = new EventEmitter<any>();

    // Init form and cols for selects
    private ngOnInit(): void {
        this.tagCols = [{
            columnDef: 'selected', selectedOnclick: true, header: 'Selected',
            cell: (element: any) => `${element.checked}`
        }, {
            columnDef: 'name', selectedOnclick: true, header: 'Name',
            cell: (element: any) => `${element.name}`
        }];
        this.directoryItemCols = [{
            columnDef: 'selected', selectedOnclick: true, header: 'Selected',
            cell: (element: any) => `${element.checked}`
        }, {
            columnDef: 'name', selectedOnclick: true, header: 'Name',
            cell: (element: any) => `${element.name}`
        }];
        this.formReady = true;
    }

    // Save the form
    private save(): void {
        this.saveActions();
        this.pageFormSave.emit({ close: false });
        this.replaceByteFieldsWithBoolean();
    }

    // Save and close the form
    private saveAndClose(): void {
        this.saveActions();
        this.pageFormSave.emit({ close: true });
    }

    // Save actions
    private saveActions() {
        this.replaceBooleanFieldsWithByte();
        this.pageFormGroup.patchValue({
          tags: this.getSelectedTags(),
          directory_items: this.getSelectedContactCards(),
        });
    }

    private getSelectedTags(): any[] {
        const value: any[] = this.tagsInput.getSelectedIds();
        return value === null ? null : value;
    }

    private getSelectedContactCards(): any[] {
        const value: any[] = this.directoryItemsInput.getSelectedIds();
        return value === null ? null : value;
    }

    private replaceBooleanFieldsWithByte(): void {
        this.pageFormGroup.patchValue({
            published: this.controls.published.value ? '1' : '0',
            featured: this.controls.featured.value ? '1' : '0',
            show_as_directory_item_in_list: this.controls.show_as_directory_item_in_list.value ? '1' : '0'
        });
    }

    // Replace byte with boolean
    private replaceByteFieldsWithBoolean(): void {
        this.pageFormGroup.patchValue({
            published: this.controls.published.value === '1' ? true : false,
            featured: this.controls.featured.value === '1' ? true : false,
            show_as_directory_item_in_list: this.controls.show_as_directory_item_in_list.value === '1' ? true : false
        });
    }

    // Listens for FileInfo from the upload component
    public getUploadedFileInfo($fileInfo: FileInfo) {
        if ($fileInfo) {
            this.pageFormGroup.patchValue({ featured_image_cdn_uuid: $fileInfo.uuid });
        } else {
            this.pageFormGroup.patchValue({ featured_image_cdn_uuid: null });
        }
    }

    // Get email from formgroup
    get email() {
        return this.pageFormGroup.get('email');
    }
}
