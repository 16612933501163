export const NOTIFICATION_FILTER_FIELDS = [
  {
    type: 'text',
    name: 'subject',
    label: 'Subject',
    value: ''
  },
  {
    type: 'date',
    name: 'created_at',
    label: 'Created At',
    value: ''
  },
];
