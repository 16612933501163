import { RouteReuseStrategy } from '@angular/router/';
import { ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

export class CacheRouteReuseStrategy implements RouteReuseStrategy {

  storedRouteHandles = new Map<string, DetachedRouteHandle>();
  allowRetriveCache = {
    'search-results': true
  };

  shouldReuseRoute(before: ActivatedRouteSnapshot, curr:  ActivatedRouteSnapshot): boolean {
    /* Very important */
    /* Stops components being caches. NG being massivley over complicated! */
    if (curr.routeConfig !== null && !! curr.routeConfig.outlet) {
      return false;
    }
    /* Fixes isuue when using same route in succession */
    if (
      curr.routeConfig !== null &&
      typeof curr.routeConfig.data !== 'undefined' &&
      typeof curr.routeConfig.data.shouldReuseRoute !== 'undefined'
    ) {
      return curr.routeConfig.data.shouldReuseRoute;
    }
    /* end */
    if (this.getPath(before) === 'detail' && this.getPath(curr) === 'search-result') {
      this.allowRetriveCache['search-results'] = true;
    } else {
      this.allowRetriveCache['search-results'] = false;
    }
    return before.routeConfig === curr.routeConfig;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPath(route);
    if (this.allowRetriveCache[path]) {
      return this.storedRouteHandles.has(this.getPath(route));
    }

    return false;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPath(route);
    if (this.allowRetriveCache.hasOwnProperty(path)) {
      return true;
    }
    return false;
  }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
    this.storedRouteHandles.set(this.getPath(route), detachedTree);
  }

  private getPath(route: ActivatedRouteSnapshot): string {
    if (route.routeConfig !== null && route.routeConfig.path !== null) {
      return route.routeConfig.path;
    }
    return '';
  }
}
