import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@components/base.component';

@Component({
  selector: 'app-related-content',
  templateUrl: './related-content.component.html',
  styleUrls: ['./related-content.component.scss']
})
export class RelatedContentComponent extends BaseComponent implements OnInit {
    @Input() item: any;
    @Input() hrTop = false;

    constructor() {
        super();
    }
}
