import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Environment } from '@environments/environment';
import { BaseService } from './base.service';
import { Cache } from '@app/cache';

@Injectable({
  providedIn: 'root'
})
export class ContactGroupService extends BaseService {

  private contactGroupCache = [];


  constructor(
    public http: HttpClient,
    public cache: Cache
  ) {
    super(http, cache);
    this.setVars({
      customPagination: true,
      endPoint: 'contact-group',
      modelTitle: 'ContactGroup'
    });
  }

  /**
   * Remove a User from a Group
   *
   * @param group_uuid
   * @param user_uuid
   */
  removeUserFromGroup(group_uuid: string, user_uuid: string) {
    const url = `${Environment.apiURL}contact-group/${group_uuid}/remove/${user_uuid}`;
    return this.http.delete<object>(url);
  }
}
