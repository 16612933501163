import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IndexDBService
{
  private indexedDB:any;
  private IDBTransaction:any;
  private dbVersion:number = 2;
  private indexDb:any;

  public db:any;

  constructor() {
    this.indexDb = (window as any).indexedDB || (window as any).webkitIndexedDB || (window as any).mozIndexedDB || (window as any).OIndexedDB || (window as any).msIndexedDB;
    this.IDBTransaction = (window as any).IDBTransaction || (window as any).webkitIDBTransaction || (window as any).OIDBTransaction || (window as any).msIDBTransaction;
    const openRequest = this.indexDb.open("localCache", this.dbVersion);

    openRequest.onupgradeneeded = (e) => {
      var thisDB = e.target.result;
      if(! thisDB.objectStoreNames.contains('images')) {
        thisDB.createObjectStore('images');
      }
    }

    openRequest.onsuccess = (e) => {
      this.db = e.target.result;
    }
  }
}
