export const ACODE_FILTER_FIELDS = [
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      value: ''
    },
    {
      type: 'dropdown',
      name: 'active',
      label: 'Active',
      value: '',
      options: [
        {
          text: 'Yes',
          value: '1'
        },
        {
          text: 'No',
          value: '0'
        }
      ]
    }
  ];
