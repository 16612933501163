import { Component, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserPositionService } from '@app/services/user-position.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
@Component({
  selector: 'user-position-form',
  templateUrl: './user-position-form.component.html',
  styleUrls: ['./user-position-form.component.scss'],
  providers: [SidebarService]
})

@Injectable()
export class UserPositionFormComponent extends BaseComponent {
  // Local variables
  userPositionFormGroup: FormGroup;
  dataLoaded = false;
  globalUtil = GlobalUtil;
  isInEditMode = false;
  // Input parameters

  // Onput events

  // Contractor
  constructor(
    public activatedRoute: ActivatedRoute,
    public userPositionService: UserPositionService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: userPositionService
    });

    this.setPageVars({
      sidebar: ''
    });

    this.initialFormGroupSetup();
    const id = this.routeParams['id'];
    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result: any) => {
        this.dataLoaded = true;
        this.userPositionFormGroup.patchValue(result);
        if (this.controls.id.value || this.controls.uuid.value) {
          this.isInEditMode = true;
        }
      });
    }
  }

  // All the public methods
  get controls(): any {
    return this.userPositionFormGroup.controls;
  }

  public save(): void {
    const id = this.controls.id.value;
    const uuid = this.controls.uuid.value;
    if (this.isInEditMode) {
      // Update the existing row
      this.userPositionService.patchById(uuid ? uuid : id, this.userPositionFormGroup.value)
        .subscribe(result => {
          this.SavedMessage(result);
        });
    } else {
      // Create the new row
      this.userPositionService.create(this.userPositionFormGroup.value).subscribe(result => this.SavedMessage(result));
    }
  }

  // All the private methods
  private initialFormGroupSetup(): void {
    // setup the initial form for row entity
    this.userPositionFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      name: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      abbreviation: [null, [Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]]
    });
  }

  private SavedMessage(data) {
    this.state.message({ ['UserPositionIndexUpdate']: true });
    this.closeSlideout();
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }
}
