import { formatDate } from '@angular/common';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { Component, OnInit, OnDestroy, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { EventService } from '@services/event.service';
import { ScheduleService } from '@services/schedule.service';
import { SidebarService } from '@services/sidebar.service';
import { TimezoneService } from '@services/timezone.service';
import { FilterLink } from '@app/models/view-models/filter-link';
import { SidebarFilterLink } from '@app/services/sidebar-filter-link.service';
import * as Moment from 'moment-timezone';

declare global {
  interface Date {
    firstDayOfWeek();
    lastDayOfWeek();
    firstDayOfMonth();
    lastDayOfMonth();
    firstDayOfYear();
    lastDayOfYear();
  }
}

Date.prototype.firstDayOfWeek = function () {
  if (this.getDay() === 0) {
    return new Date(this.setDate(this.getDate() - 6));
  } else {
    return new Date(this.setDate(this.getDate() - this.getDay() + 1));
  }
};

Date.prototype.lastDayOfWeek = function () {
  return new Date(this.setDate(this.getDate() - this.getDay() + 7));
};

Date.prototype.firstDayOfMonth = function () {
  return new Date(this.setDate(1));
};

Date.prototype.lastDayOfMonth = function () {
  return new Date(this.getFullYear(), this.getMonth() + 1, 0);
};

Date.prototype.firstDayOfYear = function () {
  return new Date(this.getFullYear(), 0, 1);
};

Date.prototype.lastDayOfYear = function () {
  return new Date(this.getFullYear(), 11, 31);
};

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss', './schedule.1024.component.scss', './schedule-700.component.scss'],
  providers: [SidebarService]
})
export class ScheduleComponent extends BaseComponent implements OnInit, OnDestroy
{
  private allFilterCategoriesSelected: boolean = true;
  private allFilterCalendarsSelected: boolean = true;
  private day: any = {};
  private locationToViewUUID: any = null;
  private month: any = {};
  private allCalendars:Array<Object> = [];
  private selectedDate: Date = null;
  private timezoneToViewName: any = null;
  private today: Date = null;
  private userCalendarToViewUUID: any = null;
  private week: any = {};
  private year: any = {};
  private defaultTimezone: any;
  private _stopDateClick:boolean = false;
  private _subscriptions:Array<any> = [];

  public timezoneToViewUUID: any = null;

  public scheduleCategories:Array<Object> = [];
  public scheduleCalendars:Array<Object> = [];
  public hasLocations: boolean = false;
  public hasPlayers: boolean = false;

  public calendarPlugins = [timeGridPlugin, dayGridPlugin, interactionPlugin];
  public calendarsHavePeriodizationCalendar: boolean = false;
  public currentScheduleView = 'Week';
  public events: Array<object> = [];
  public get filtersEmpty() {
    var empty:boolean;
    if (this.filterByCalendarOrCategoryOption === 'both') {
      empty =
        this.filterScheduleCalendarValues.length === 0 &&
        this.filterScheduleCategoryValues.length === 0;
    }
    if (this.filterByCalendarOrCategoryOption === 'calendar') {
      empty = this.filterScheduleCalendarValues.length === 0;
    }
    if (this.filterByCalendarOrCategoryOption === 'category') {
      empty = this.filterScheduleCategoryValues.length === 0;
    }
    return empty;
  };
  public filterScheduleCalendarValues: Array<Object> = [];
  public filterScheduleCategoryValues: Array<Object> = [];
  public filterScheduleTimezoneValue: Array<Object> = [];
  public filterByCalendarOrCategoryOption: string = 'both';
  public filterByCalendarOrCategoryOptions: Array<object> = [
    { value: 'calendar', text: 'Filter by Calendar' },
    { value: 'category', text: 'Filter by Category' },
    { value: 'both', text: 'Filter Using Calendar and Category' }
  ];
  public isInNewEventMode = false;
  public isTodayActive = false;
  public leadingMonthNumber: number;
  public loading = true;
  public scheduleViews: Array<string> = ['Day', 'Week', 'Month'];
  public selectedDateView: string = null;
  public showEventsInLocalTime: boolean = false;
  public showFilter = false;
  public showHidePeriodizationEventsOption: string = 'hidden';
  public showHidePeriodizationEventsOptions: Array<object> = [
    { value: 'hidden', text: 'Hide periodization events' },
    { value: 'show', text: 'Show periodization events' },
    { value: 'show-only', text: 'Show only periodization events' }
  ];
  public sidebarLinks: FilterLink[] = [];
  public timezones: any = [];

  @ViewChild('fullCalendar', {static: false}) fullCalendar: FullCalendarComponent;
  @ViewChild('schedule', {static: true}) scheduleCont: any;
  @ViewChild('filtersCalendarsPanel', {static: false}) filtersCalendarsPanel: MatExpansionPanel;
  @ViewChild('filtersCategoriesPanel', {static: false}) filtersCategoriesPanel: MatExpansionPanel;

  constructor(
    public pageTitleService: Title,
    public scheduleService: ScheduleService,
    public eventService: EventService,
    public state: State,
    public router: Router,
    public sideBarService: SidebarService,
    public timezoneService: TimezoneService,
    private sidebarFilterLink: SidebarFilterLink
  ) {
    super();
    this.setFilterOptions();
    this.setObjects({
      sideBarService: sideBarService,
      router: router,
      state: state,
      providers: [SidebarService]
    });
  }

  async initSchedule() {
    this.getTimezones().then(async () => {
      this.setTimezone(this.defaultTimezone.uuid, false);
      await this.getEvents();
    });
    this.setToday();
    this.setScheduleVariables();
    this.setCurrentDateView();
  }

  setToday() {
    this.today = new Date();
    this.selectedDate = new Date(this.today);
  }

  getTimezones() {
    return new Promise((resolve:any) => {
      this.timezoneService.getTimezonesList().subscribe(timezones => {
        this.timezones = timezones;
        resolve();
      });
    });
  }

  async refreshSchedule() {
    this.loading = true;
    this.setScheduleVariables();
    this.setCurrentDateView();
    this.setIsToday();
    this.filterPeriodisationCalendars();
    await this.getEvents();
    this.filterEvents();
  }

  public setShowEventsInLocalTime(): void {
    this.showEventsInLocalTime = ! this.showEventsInLocalTime;
    this.refreshSchedule();
  }

  setIsToday() {
    this.isTodayActive = this.currentScheduleView === 'Day' && this.dateString(this.selectedDate) === this.dateString(this.today);
  }

  dateString(date) {
    return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
  }

  filterEvents() {
    const calendarApi = this.fullCalendar.getApi();
    if (!! calendarApi) {
      calendarApi.rerenderEvents();
    }
  }

  setCurrentScheduleViewToday() {
    this.isTodayActive = true;
    this.setToday();
    this.gotoDate();
    this.setCurrentScheduleView('Day');
  }

  setFilterOptions() {
    this.scheduleService.getCalendars().subscribe(calendars => {
      this.allCalendars = calendars.sort((a, b) => (a.display_order > b.display_order) ? 1 : -1);
      this.calendarsHavePeriodizationCalendar = this.allCalendars
        .filter((calendar:any) => calendar.periodization === true).length > 0;
      this.filterPeriodisationCalendars();
      if (this.scheduleCalendars.length > 0) {
        this.filtersCalendarsPanel.disabled = false;
        setTimeout(() => this.filtersCalendarsPanel.open());
      }
    });

    this.scheduleService.getCategories().subscribe(categories => {
      const filterScheduleCategoryOptions = [];
      categories.forEach(category => {
        this.scheduleCategories.push(category);
        const option: any = {};
        option.value = category.uuid;
        option.name = category.name;
        option.color = category.color;
        option.checked = 'checked';
        filterScheduleCategoryOptions.push(option);
        this.filterScheduleCategoryValues.push(option.value);
      });
      if (this.scheduleCategories.length > 0) {
        this.filtersCategoriesPanel.disabled = false;
      }
      this.state.update({
        sidebarFilterScheduleCategoryOptions: filterScheduleCategoryOptions,
        sidebarFilterScheduleCategoryValues: this.filterScheduleCategoryValues
      });
    });
  }

  filterPeriodisationCalendars() {
    const filterScheduleCalendarOptions = [];
    this.scheduleCalendars = [];
    this.allCalendars.forEach((calendar:any) => {
      var addCalendar:boolean = false;
      if (
        calendar.periodization && (
          this.showHidePeriodizationEventsOption === 'show' ||
          this.showHidePeriodizationEventsOption === 'show-only'
      )) {
        addCalendar = true;
      }
      if (
        ! calendar.periodization && (
          this.showHidePeriodizationEventsOption === 'hidden' ||
          this.showHidePeriodizationEventsOption === 'show'
      )) {
        addCalendar = true;
      }
      if (addCalendar) {
        this.scheduleCalendars.push(calendar);
        const option: any = {};
        option.value = calendar.uuid;
        option.name = calendar.name;
        option.color = calendar.color;
        if (
          this.filterScheduleCalendarValues.length === 0 ||
          this.filterScheduleCalendarValues.indexOf(calendar.uuid) !== -1
        ) {
          option.checked = 'checked';
        }
        filterScheduleCalendarOptions.push(option);
      }
    });

    this.state.update({
      sidebarFilterScheduleCalendarOptions: filterScheduleCalendarOptions,
      sidebarFilterScheduleCalendarValues: this.filterScheduleCalendarValues
    });
  }

  clearEvents() {
    this.events = [];
  }

  ngOnInit() {
    this.defaultTimezone = this.state.get('defaultTimezone');

    // Watch for new events created by user
    this._subscriptions.push(
      this.state.subscription().subscribe(payload => {
        if (!! payload.sidebarFilterScheduleTimezoneValue) {
          this.filterScheduleTimezoneValue = payload.sidebarFilterScheduleTimezoneValue;
        }
        if (!! payload.sidebarFilterScheduleCategoryValues) {
          this.filterScheduleCategoryValues = payload.sidebarFilterScheduleCategoryValues;
          this.allFilterCategoriesSelected = this.filterScheduleCategoryValues.length === this.scheduleCategories.length;
        }
        if (!! payload.sidebarFilterScheduleCalendarValues) {
          this.filterScheduleCalendarValues = payload.sidebarFilterScheduleCalendarValues;
          this.allFilterCalendarsSelected = this.filterScheduleCalendarValues.length === this.scheduleCalendars.length;
        }
        if (payload.isInNewEventMode !== undefined) {
          this.isInNewEventMode = payload.isInNewEventMode;
        }
        if (!! payload.ScheduleUpdate) {
          this.refreshSchedule();
        }
      })
    );

    this.initSchedule();
    this.sidebarLinks = this.sidebarFilterLink.buildScheduleFilterLinks();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  createEvent(header, event) {
    this.state.update({ isInNewEventMode: true });
    this.openSlideOutWithLink('schedule', 'create-event');
  }

  /**
   * What happens when an Event is clicked
   * @param info
   */
  eventClick(info) {
    this.openSlideOutWithLink('schedule', 'edit-event/' + info.event.id);
  }

  /**
   * What happens when the day cell is clicked
   * @param info
   */
  dateClick(info) {
    if (! this._stopDateClick && this.currentScheduleView !== 'Day') {
      this.selectedDate = new Date(info.dateStr);
      this.gotoDate();
      this.setCurrentScheduleView('Day');
    }
    this._stopDateClick = false;
  }

  /**
   * Sets the location UUID for calendar filters
   * @param locationUUID
   */
  setLocation(locationUUID: string) {
    this.locationToViewUUID = locationUUID;
    this.setToday();
  }

  setUserCalendar(calendarUUID: string) {
    this.userCalendarToViewUUID = calendarUUID;
    this.setToday();
  }

  setTimezone(timezoneUUID: string, refreshSchedule: boolean = true) {
    const calendarApi = this.fullCalendar.getApi();
    this.timezoneToViewUUID = timezoneUUID;
    this.timezoneToViewName = this.timezones.find(timezone => timezone.uuid === timezoneUUID).name;
  }

  setShowHidePeriodizationEventsOption(option: string): void {
    this.showHidePeriodizationEventsOption = option;
  }

  setFilterByCalendarOrCategoryOption(option: string): void {
    this.filterByCalendarOrCategoryOption = option;
    if (option === 'category') {
      this.filtersCalendarsPanel.disabled = true;
      this.filtersCategoriesPanel.disabled = false;
      setTimeout(() => this.filtersCategoriesPanel.open());
    }
    if (option === 'calendar') {
      this.filtersCategoriesPanel.disabled = true;
      this.filtersCalendarsPanel.disabled = false;
      setTimeout(() => this.filtersCalendarsPanel.open());
    }
    if (option === 'both') {
      this.filtersCalendarsPanel.disabled = false;
      this.filtersCategoriesPanel.disabled = false;
      setTimeout(() => this.filtersCalendarsPanel.open());
    }
  }

  gotoDate() {
    const calendarApi = this.fullCalendar.getApi();
    calendarApi.gotoDate(this.selectedDate);
  }

  /**
   * How an event should be renderered on the Calendar
   *
   * @param info
   */
  eventRender(info) {
    // if (
    //   this.filterScheduleCategoryValues.indexOf(info.event.extendedProps.event_category_uuid) === -1 &&
    //   this.filterScheduleCalendarValues.filter(x => info.event.extendedProps.calendars_uuids.includes(x)).length === 0
    // ) {
    //   info.el.classList.add('hidden-event');
    // }

    if (info.event.start < this.day.firstDate && info.event.end < this.day.lastDate) {
      info.el.classList.add('event-starts-before-view-date');
    }

    if (info.event.start > this.day.firstDate && info.event.end > this.day.lastDate) {
      info.el.classList.add('event-ends-after-view-date');
    }

    if (this.currentScheduleView === 'Day') {
      if (info.el.childNodes[0].childNodes[0].childNodes[0] !== undefined) {
        const times = info.el.childNodes[0].childNodes[0].childNodes[0].innerHTML.split(' - ');
        info.el.childNodes[0].childNodes[0].childNodes[0]
          .innerHTML = `<span class="fc-start-time">${times[0]}</span> - <span class="fc-end-time">${times[1]}</span>`;
      }
    }

    if (info.event.extendedProps.hideStartTime || info.event.extendedProps.is_periodization_event) {
      info.el.classList.add('fc-hide-start-time');
    }

    if (info.event.extendedProps.hideEndTime || info.event.extendedProps.is_periodization_event) {
      info.el.classList.add('fc-hide-end-time');
    }

    if(info.event.extendedProps.is_periodization_event) {
      info.el.classList.add('fc-is-periodization-event');
    }

    info.el.style.color = info.event.backgroundColor;

    const divInner = document.createElement('div');
    divInner.classList.add('fc-inner');
    divInner.style.background = info.event.backgroundColor;
    info.el.prepend(divInner);

    const divInnerWhite = document.createElement('div');
    divInnerWhite.classList.add('fc-inner-white');

    if (info.isStart && (this.currentScheduleView === 'Month' || this.currentScheduleView === 'Week')) {
      divInnerWhite.style.borderLeftColor = info.event.backgroundColor;
      divInnerWhite.style.borderLeftWidth = '3px';
      divInnerWhite.style.borderLeftStyle = 'solid';
    }

    if (this.currentScheduleView === 'Day') {
      divInnerWhite.style.borderTopColor = info.isStart ? info.event.backgroundColor : '#fff';
      divInnerWhite.style.borderTopStyle = info.isStart ? 'solid' : 'dashed';
      divInnerWhite.style.borderTopWidth = '3px';
    }
    info.el.prepend(divInnerWhite);
  }

  dayRender(info) {
    if (info.view.type === 'dayGridMonth') {
      const newEventButton:any = document.createElement('a');
      newEventButton.innerHTML = '+';
      newEventButton.classList.add('add-new-event-to-day');
      newEventButton.onclick = (event) => {
        this._stopDateClick = true;
        this.addEventAtDay(info);
      };
      info.el.prepend(newEventButton);
    }
  }

  addEventAtDay(info) {
    this.state.update({ isInNewEventMode: true });
    const year = info.date.getFullYear();
    let month = info.date.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = info.date.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    const date = `${year}-${month}-${day} 09:00:00`;
    this.openSlideOutWithLink('schedule', 'create-event/' + date);
  }

  setCurrentScheduleView(scheduleView: string) {
    this.currentScheduleView = scheduleView;
    this.clearEvents();
    let view = '';
    if (scheduleView === 'Month') {
      view = 'dayGridMonth';
    }
    if (scheduleView === 'Week') {
      view = 'dayGridWeek';
    }
    if (scheduleView === 'Day') {
      view = 'timeGrid';
    }
    const calendarApi = this.fullCalendar.getApi();
    calendarApi.changeView(view);
    this.refreshSchedule();
  }

  setActiveScheduleViewClass(scheduleView: string) {
    return this.currentScheduleView === scheduleView ? 'active' : '';
  }

  setScheduleVariables(): void {
    const date: Date = new Date(this.selectedDate);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    // Set day dates
    this.day.firstDate = new Date(date);
    this.day.lastDate = new Date(date);
    this.day.lastDate.setHours(23);
    this.day.lastDate.setMinutes(59);
    this.day.lastDate.setSeconds(59);
    // Set month dates
    const monthDateClone = new Date(date);
    this.month.firstDate = monthDateClone.firstDayOfMonth();
    this.month.lastDate = monthDateClone.lastDayOfMonth();
    this.month.leadingMonthNumber = monthDateClone.getMonth() - 1;
    // Set week dates
    const weekDateClone = new Date(date);
    this.week.firstDate = weekDateClone.firstDayOfWeek();
    this.week.lastDate = weekDateClone.lastDayOfWeek();
    // Set year dates
    const yearDateClone = new Date(date);
    this.year.firstDate = yearDateClone.firstDayOfYear();
    this.year.lastDate = yearDateClone.lastDayOfYear();
  }

  /**
   * Returns events from the API
   */
  getEvents() {
    return new Promise((resolve:any) => {
      this.clearEvents();
      this.loading = true;
      let startDate: Date;
      let endDate: Date;

      if (this.currentScheduleView === 'Month') {
        // Extend date range to account for leading and trailing days on month view
        startDate = new Date(this.month.firstDate);
        startDate.setDate(0 - this.getLeadingDays().length);
        endDate = new Date(this.month.lastDate);
        endDate.setDate(0 + this.getTrailingDays().length);
        endDate.setMonth(endDate.getMonth() + 1);
      } else {
        startDate = new Date(this[this.currentScheduleView.toLowerCase()].firstDate);
        endDate = new Date(this[this.currentScheduleView.toLowerCase()].lastDate);
      }

      const timezoneToViewUUID = this.showEventsInLocalTime ? null : this.timezoneToViewUUID;
      const calendars:any = ! this.allFilterCalendarsSelected ? this.filterScheduleCalendarValues : null;
      const categories:any = ! this.allFilterCategoriesSelected ? this.filterScheduleCategoryValues : null;

      if (this.userCalendarToViewUUID) {
        this.scheduleService
          .getUserScheduleEvents(
            this.userCalendarToViewUUID,
            startDate,
            endDate,
            this.timezoneToViewUUID,
            calendars,
            categories
          )
          .subscribe(eventsResult => this.setApiEventsResult(eventsResult));
      } else if (this.locationToViewUUID) {
        this.scheduleService
          .getLocationScheduleEvents(
            this.locationToViewUUID,
            startDate,
            endDate,
            this.timezoneToViewUUID,
            calendars,
            categories
          )
          .subscribe(eventsResult => this.setApiEventsResult(eventsResult));
      } else {
        this.scheduleService.getAllSchedulesEvents(
          startDate,
          endDate,
          this.timezoneToViewUUID,
          calendars,
          categories
        )
        .subscribe(eventsResult => this.setApiEventsResult(eventsResult));
      }
      resolve();
    });
  }

  setApiEventsResult(addedEvents: Array<object>) {
    if (this.events.length === 0) {
      this.events = addedEvents;
    } else {
      addedEvents.forEach((addedEvent: any) => {
        const index: number = this.events.findIndex((event: any) => event.uuid === addedEvent.uuid);
        if (index === -1) {
          this.events.push(addedEvent);
        } else {
          this.events[index] = addedEvent;
        }
      });
    }
    this.mapEventsFromApiToCalendar();
    this.loading = false;
  }

  getFullDate(type, number) {
    let year = this.selectedDate.getFullYear();
    let month = 0;

    if (type === 'leading') {
      month = this.selectedDate.getMonth();
      if (month === 12) {
        year--;
      }
    }

    if (type === 'current') {
      month = this.selectedDate.getMonth() + 1;
    }

    if (type === 'trailing') {
      month = this.leadingMonthNumber + 2;
      if (month === 1) {
        year++;
      }
    }

    const displayMonth = ('00' + month).slice(-2);
    const displayNumber = ('00' + number).slice(-2);

    return `${year}-${displayMonth}-${displayNumber}`;
  }

  setCurrentDateView() {
    switch (this.currentScheduleView) {
      case 'Day':
        this.selectedDateView =
          '<small>' +
          formatDate(this.selectedDate, 'EEEE', 'en-GB') +
          '<br>' +
          formatDate(this.selectedDate, 'd MMM yyyy', 'en-GB') +
          '</small>';
        break;
      case 'Week':
        this.selectedDateView =
          '<small>' +
          formatDate(this.week.firstDate, 'd MMM yyyy', 'en-GB') +
          '<br>' +
          formatDate(this.week.lastDate, 'd MMM yyyy', 'en-GB') +
          '</small>';
        break;
      case 'Month':
        this.selectedDateView = formatDate(this.selectedDate, 'MMMM yyyy', 'en-GB');
        break;
      case 'Year':
        this.selectedDateView = formatDate(this.selectedDate, 'yyyy', 'en-GB');
        break;
    }
    this.scheduleCont.nativeElement.scrollTop = 0;
  }

  getLeadingDays() {
    const leadingDays = [];
    const numberOfLeadingDays = this.month.firstDate.getDay() - 1;
    const lastDayOfLastMonth = new Date(this.selectedDate);
    lastDayOfLastMonth.setDate(0);
    const lastDateOfLastMonthAsNumber: number = lastDayOfLastMonth.getDate();
    for (let offset = 0; offset < numberOfLeadingDays; offset++) {
      leadingDays.push(lastDateOfLastMonthAsNumber - offset);
    }
    return leadingDays.reverse();
  }

  getTrailingDays() {
    const numberOfRows = 6;
    const numberOfDaysToFill = 7 * numberOfRows;
    const numberOftrailingDays = numberOfDaysToFill - (this.getDaysForMonthScheduleView().length + this.getLeadingDays().length);
    const trailingDays = [];
    for (let offset = 0; offset < numberOftrailingDays; offset++) {
      trailingDays.push(offset + 1);
    }
    return trailingDays;
  }

  getDaysForMonthScheduleView() {
    const lastDayOfTheMonth = this.month.lastDate.getDate();
    const days = [];
    for (let offset = 0; offset < lastDayOfTheMonth; offset++) {
      days.push(offset + 1);
    }
    return days;
  }

  decrementOne() {
    const calendarApi = this.fullCalendar.getApi();
    calendarApi.prev();
    switch (this.currentScheduleView) {
      case 'Day':
        this.selectedDate.setDate(this.selectedDate.getDate() - 1);
        break;
      case 'Week':
        this.selectedDate.setDate(this.selectedDate.firstDayOfWeek().getDate() - 7);
        break;
      case 'Month':
        this.selectedDate.setMonth(this.selectedDate.getMonth() - 1);
        break;
      case 'Year':
        this.selectedDate.setFullYear(this.selectedDate.getFullYear() - 1);
        break;
    }
    this.gotoDate();
    this.refreshSchedule();
  }

  incrementOne() {
    const calendarApi = this.fullCalendar.getApi();
    calendarApi.next();
    switch (this.currentScheduleView) {
      case 'Day':
        this.selectedDate.setDate(this.selectedDate.getDate() + 1);
        break;
      case 'Week':
        this.selectedDate.setDate(this.selectedDate.firstDayOfWeek().getDate() + 7);
        break;
      case 'Month':
        this.selectedDate.setMonth(this.selectedDate.getMonth() + 1);
        break;
      case 'Year':
        this.selectedDate.setFullYear(this.selectedDate.getFullYear() + 1);
        break;
    }
    this.gotoDate();
    this.refreshSchedule();
  }

  mapEventsFromApiToCalendar() {
    const events: any = this.events;
    this.clearEvents();
    events.forEach((apiEvent: any) => {
      const mappedEvent: any = {};
      mappedEvent.id = apiEvent.uuid;
      mappedEvent.title = apiEvent.name;

      let start;
      let end;
      if (this.showEventsInLocalTime) {
        start = Moment(apiEvent.start_time).tz(apiEvent.start_time_timezone.name);
        mappedEvent.start = new Date((new Date(start.toString())).getTime() + start.utcOffset() * 60000);
        end = Moment(apiEvent.end_time).tz(apiEvent.end_time_timezone.name);
        mappedEvent.end = new Date((new Date(end.toString())).getTime() + end.utcOffset() * 60000);
      } else {
        start = Moment(apiEvent.start_time).tz(this.timezoneToViewName);
        mappedEvent.start = new Date((new Date(start.toString())).getTime() + start.utcOffset() * 60000);
        end = Moment(apiEvent.end_time).tz(this.timezoneToViewName);
        mappedEvent.end = new Date((new Date(end.toString())).getTime() + end.utcOffset() * 60000);
      }

      mappedEvent.color = apiEvent.event_category && !!apiEvent.event_category.color ? apiEvent.event_category.color : '#ccc';
      mappedEvent.event_category_uuid = apiEvent.event_category && !!apiEvent.event_category.uuid ? apiEvent.event_category.uuid : false;
      mappedEvent.allDay = apiEvent.all_day_event || this.timeListViewEventSpansWholeDay(mappedEvent);
      mappedEvent.hideStartTime = apiEvent.hide_start_time;
      mappedEvent.hideEndTime = apiEvent.hide_end_time;
      mappedEvent.calendars_uuids = apiEvent.calendars;
      mappedEvent.is_periodization_event = apiEvent.is_periodization_event;

      // Add pdz events if show or show only pdz enabled
      if (mappedEvent.is_periodization_event) {
        if (this.showHidePeriodizationEventsOption === 'show' || this.showHidePeriodizationEventsOption === 'show-only') {
          this.events.push(mappedEvent);
        }
      }

      // Remove events if not pdz but show only pdz
      if (! mappedEvent.is_periodization_event && this.showHidePeriodizationEventsOption !== 'show-only') {
        this.events.push(mappedEvent);
      }
    });
  }

  dateParts(dateString) {
    const parts = dateString.split(' ');
    const day = parts[0].split('-');
    const time = parts[1].split(':');
    const date = {
      year: day[0],
      month: day[1],
      day: day[2],
      hour: time[0],
      minute: time[1]
    };
    return date;
  }

  timeListViewEventSpansWholeDay(event) {
    if (this.currentScheduleView === 'Day') {
      return event.start <= this.day.firstDate && event.end >= this.day.lastDate;
    }
    return false;
  }

  /**
   * Called from Small screen devices to show hide the Sidebar
   *
   */
  toggleFilter(): void {
    this.showFilter = !this.showFilter;
     if (this.showFilter) {
      document.getElementById('sidebar').classList.add('open-cal-filter');
    } else {
      document.getElementById('sidebar').classList.remove('open-cal-filter');
    }
  }

  /**
   * Called from the view - opens the slideout edit form
   *
   */
  duplicateEvents(timespan: string): void {
    const calendarApi = this.fullCalendar.getApi();
    document.getElementById('sidebar').classList.add('open');
    this.openSlideOutWithLink('schedule', 'duplicate-event', {
      selectedWeekDates: calendarApi.state.dateProfile.currentRange,
      timespan: timespan
    });
  }

 /**
  * Handles the click from sidebar links
  * @param link
  */
  public onFilterClicked(link: FilterLink): void {
    if (link.route) {
      this.router.navigate([link.route]);
    }
  }
}
