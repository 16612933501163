import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FileInfo } from '@app/models/entities/fileInfo';
import { ArticleCategoryService } from '@app/services/article-category.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
@Component({
  selector: 'app-article-category-form',
  templateUrl: './article-category-form.component.html',
  styleUrls: ['./article-category-form.component.scss'],
  providers: [SidebarService]
})
export class ArticleCategoryFormComponent extends BaseComponent {
  articleCategoryFormGroup: FormGroup;
  dataLoaded = false;
  globalUtil = GlobalUtil;
  constructor(
    public activatedRoute: ActivatedRoute,
    public articleCategoryService: ArticleCategoryService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: articleCategoryService
    });

    this.setPageVars({
      sidebar: ''
    });

    this.initialFormGroupSetup();
    const id = this.routeParams['id'];
    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result: any) => {
        this.dataLoaded = true;
        this.articleCategoryFormGroup.patchValue(result);
      });
    }
  }

  // All the public methods
  get controls(): any {
    return this.articleCategoryFormGroup.controls;
  }

  public save(): void {
    const id = this.controls.id.value;
    const uuid = this.controls.uuid.value;

    this.replaceBooleanFieldsWithByte();
    if (id || uuid) {
      // Update the existing row
      this.articleCategoryService.patchById(uuid ? uuid : id, this.articleCategoryFormGroup.value)
        .subscribe(result => {
          this.SavedMessage(result);
        });
    } else {
      // Create the new row
      this.articleCategoryService.create(this.articleCategoryFormGroup.value).subscribe(result => this.SavedMessage(result));
    }
    this.replaceByteFieldsWithBoolean();
  }

  private initialFormGroupSetup(): void {
    this.articleCategoryFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      name: [null, [Validators.required, Validators.maxLength(this.globalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      teaser: [null, [Validators.maxLength(this.globalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      description: [null],
      featured_image_cdn_uuid: [null],
      featured_image_cdn_url: [null],
      featured_image_width: [null],
      featured_image_height: [null],
      app_tile_list_style: [null],
      published: [null],
    });
  }

  // Listens for FileInfo from the upload component
  public getUploadedFileInfo($fileInfo: FileInfo) {
    if ($fileInfo) {
      this.articleCategoryFormGroup.patchValue({
        'featured_image_cdn_uuid': $fileInfo.uuid,
        'featured_image_cdn_url': $fileInfo.cdnUrl,
        'featured_image_width': $fileInfo.width,
        'featured_image_height': $fileInfo.height,
      });
    } else {
      this.articleCategoryFormGroup.patchValue({
        'featured_image_cdn_uuid': null,
        'featured_image_cdn_url': null,
        'featured_image_width': null,
        'featured_image_height': null,
      });
    }
  }

  private replaceBooleanFieldsWithByte(): void {
    this.articleCategoryFormGroup.patchValue({
        published: this.controls.published.value ? '1' : '0',
    });
  }

    // Replace byte with boolean
  private replaceByteFieldsWithBoolean(): void {
    this.articleCategoryFormGroup.patchValue({
      published: this.controls.published.value === '1' ? true : false,
    });
  }

  private SavedMessage(data) {
    this.state.message({ ['ArticleCategoryIndexUpdate']: true });
    this.closeSlideout();
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }
}
