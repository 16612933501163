/**
 * 2020-12-09 - Removed a few fields that have a foreign key
 * The API export function needs to map these foreign keys to the Title
 * Eg. Country ID should return Counr
 */
export const EXPORTABLE_FIELDS = [
  {
    'value': 'users.firstname',
    'text': 'First name',
  },
  {
    'value': 'users.lastname',
    'text': 'Last name',
  },
  {
    'value': 'users.email',
    'text': 'Email address',
  },
  {
    'value': 'users.mobile',
    'text': 'Mobile',
  },
  {
    'value': 'users.created_at',
    'text': 'Date created',
  },
  {
    'value': 'users.deleted_at',
    'text': 'Date deleted',
  },
  {
    'value': 'users.spid_status',
    'text': 'SPID status',
  },
  {
    'value': 'user_profiles.job_title',
    'text': 'Job title',
  },
  // {
  // 'value': 'user_profiles.country_id',
  // 'text': 'Nationality',
  // },
  {
    'value': 'user_profiles.squad_number',
    'text': 'Squad number',
  },
  {
    'value': 'user_profiles.telephone',
    'text': 'Telephone',
  },
  {
    'value': 'user_profiles.address',
    'text': 'Address',
  },
  {
    'value': 'user_profiles.town_city',
    'text': 'Town / City',
  },
  {
    'value': 'user_profiles.postcode_zip',
    'text': 'Postcode / Zip',
  },
  {
    'value': 'user_profiles.county_state',
    'text': 'County / State',
  },
  // {
  // 'value': 'user_profiles.address_country_id',
  // 'text': 'Address Country',
  // },
  {
    'value': 'user_profiles.biography',
    'text': 'Biography',
  },
  {
    'value': 'user_profiles.okr',
    'text': 'OKR',
  },
  {
    'value': 'user_profiles.role_responsibility',
    'text': 'Roles and Responsibilities',
  },
  {
    'value': 'user_profiles.passport_expiry',
    'text': 'Passport expiration date',
  },
  {
    'value': 'users.group_id',
    'text': 'Group',
  },
  // {
  // 'value': 'roles.user_role_id',
  // 'text': 'User Role',
  // },
  {
    'value': 'user_profiles.passport_number',
    'text': 'Passport number',
  },
  {
    'value': 'user_profiles.passport_surname',
    'text': 'Passport surname',
  },
  {
    'value': 'user_profiles.passport_given_names',
    'text': 'Passport given names',
  },
  {
    'value': 'user_profiles.place_of_birth',
    'text': 'Place of birth',
  },
  {
    'value': 'user_profiles.passport_issue_date',
    'text': 'Passport issue date',
  },
];
