import { paramCase } from 'change-case';

import { modelSetClass, updatePageFormItemPerms } from './../../utils';

import {
  COMP_LAYOUT_DIVIDER,
  COMP_LAYOUT_ROW,
} from './../../../const/components';

import { ICON_LAYOUT_DIVIDER } from './../../../const/icons';

const compParamName = paramCase(COMP_LAYOUT_DIVIDER);

export const Divider = editor => {
  return {
    isComponent: el => el.tagName === compParamName.toUpperCase(),
    // Model
    model: {
      defaults: {
        displayIcon: ICON_LAYOUT_DIVIDER,
        tagName: compParamName,
        name: 'Divider',
        droppable: false,
        draggable: `${paramCase(COMP_LAYOUT_ROW)},#add-row`,
        traits: []
      }
    },
    // View
    view: {
      tagName: compParamName,
      // Init
      init() {
        updatePageFormItemPerms.call(this, editor);
        this.listenTo(this.model, 'change:attributes', this.handleAttributeChanges);
      },
      // Attribute changes handler
      handleAttributeChanges() {
        updatePageFormItemPerms.call(this, editor);
      },
      // On render
      onRender() {
        modelSetClass.call(this, 'item');
      }
    }
  }
}