// All the settings (menu) related components, services & classes should be decleared here

import { NgModule } from '@angular/core';
import { MaterialModule } from '../common/third-parties/material/material.module';
import { CoreModule } from '../common/core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AngularModule } from '../common/third-parties/angular/angular.module';

const COMPONENTS = [
  // CalendarFormComponent,
  // CalendarsIndexComponent,
  // CalendarSortComponent,
  // SettingsIndexComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
     CoreModule,
     AngularModule,
     MaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
   ...COMPONENTS
  ],
  bootstrap: []
})
export class SettingsModule {
  constructor() {
    // console.log('Settings module loaded');
  }
}
