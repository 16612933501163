export class DialogData {
  groupName: string;
  groupDescription: string;
  groupIcon: string;
  groupType: number;
  timeStamp: Date;
  id: string;
  IsPersonal: boolean;
  ProductID: string;
  withuser: Array<Withuser>;
  createdBy: string;
  Createdbyfirstname: string;
  Createdbylastname: string;
  avatar: string;
  role: string;
  isArchived: boolean;
}

export class Withuser {
  created_by_user: Array<CreatedBy>;
  profile: Array<UserProfile>;
  firstname: string;
  lastname: string;
}

export class  CreatedBy {
    uuid: string;
}

export class UserProfile {
  avatar: string;
}
