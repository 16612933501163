import { Component } from '@angular/core';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SettingSections } from './settings-links';
import { SportsologySuperUserGuard } from './../../../modules/common/core/components/auth/sportsology-super-user.guard';

@Component({
  selector: 'app-settings-index',
  templateUrl: './settings-index.component.html',
  styleUrls: [
    './settings-index.component.scss',
    './settings-index-700.component.scss',
  ]
})
export class SettingsIndexComponent extends BaseComponent {

  private authUser: any;

  public sections = SettingSections.sections;

  constructor(
    private sportsologySuperUserGuard: SportsologySuperUserGuard,
    public state: State
  ) {
    super();
    this.setObjects({
      state,
    });
  }

  canViewLink(link) {
    if (link.sportsologySuperAdmin === undefined) {
    }
    if (
      link.sportsologySuperAdmin !== undefined &&
      link.sportsologySuperAdmin === true
    ) {
      return this.sportsologySuperUserGuard.userIsSportsologySuperUser();
    }
    return true;
  }
}
