import { ActivatedRoute, Router } from '@angular/router';
import { Component, Injectable, OnInit } from '@angular/core';
import { BaseComponent } from '@components/base.component';
import { NotificationService } from '@services/notification.service';
import { SidebarService } from '@services/sidebar.service';
import { State } from '@app/state';

@Component({
  selector: 'app-notification-view',
  templateUrl: './notification-view.component.html',
  styleUrls: [],
  providers: [SidebarService],
})

@Injectable()
export class NotificationViewComponent extends BaseComponent implements OnInit {

  public notification: {
    notification: any,
    created_user: any,
    created_at: string
  };
  public dataLoaded = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public notificationService: NotificationService,
    public sidebarService: SidebarService,
    public router: Router,
    public state: State,
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: notificationService,
      router: router
    });
    this.setPageVars({
      sidebar: 'notifications',
    });
    this.modelServiceGetOne(this.routeParams['id'], 'notification').then(() => {
      this.dataLoaded = true;
    });
  }

  public modelServiceGetOne(id: any, resultVar: string, page: number = 1) {
    return new Promise((resolve:any, reject:any) => {
      if (typeof id !== 'undefined') {
        return this.modelService.getOne(id).subscribe(result => {
          // if (result.data !== undefined) {
          //   this.dataSourceRows = result.data;
          // } else {
          //   this.dataSourceRows = result;
          // }
          this.pageLoaded();
          resolve((this[resultVar] = result));
        });
      } else {
        this.pageLoaded();
        resolve();
      }
    });
  }

  public booleanToText(val): string {
    return val === true ? 'Yes' : 'No';
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
