import {
  CMD_PANEL_CLEAR,
  CMD_PANEL_CORE_REDO,
  CMD_PANEL_CORE_UNDO,
  CMD_PANEL_DEVICE_DESKTOP,
  CMD_PANEL_DEVICE_MOBILE,
  CMD_PANEL_DEVICE_TABLET,
  CMD_PANEL_IMPORT,
  CMD_PANEL_OPEN_BLOCKS,
  CMD_PANEL_OPEN_COMPONENT_ITEMS,
  CMD_PANEL_OPEN_LAYERS,
  CMD_PANEL_OPEN_TRAITS,
  CMD_PANEL_OUTLINE_VISIBILITY
} from './../../const/commands';

import { iconElement } from './../utils';

export const Panels = (editor, config) => {

  const Panels = editor.Panels;
  let editPanel = null
  const editorConfig = editor.getConfig();

  editorConfig.showDevices = 0;

  Panels.getPanels().reset([{
    id: 'commands',
    buttons: [{}],
  },{
    id: 'options',
    buttons: [{
      id: CMD_PANEL_OUTLINE_VISIBILITY,
      command: CMD_PANEL_OUTLINE_VISIBILITY,
      context: CMD_PANEL_OUTLINE_VISIBILITY,
      label: iconElement('crop_free'),
    },
    // {
    //   id: preview,
    //   context: preview,
    //   command: e => e.runCommand(preview),
    //   className: 'fa fa-eye',
    // },
    // {
    //   id: fullScreen,
    //   command: fullScreen,
    //   context: fullScreen,
    //   className: 'fa fa-arrows-alt',
    // },
    // {
    //   id: exportTemplate,
    //   className: 'fa fa-code',
    //   command: e => e.runCommand(exportTemplate),
    // },
    {
      id: 'undo',
      label: iconElement('undo'),
      command: CMD_PANEL_CORE_UNDO,
    },{
      id: 'redo',
      label: iconElement('redo'),
      command: CMD_PANEL_CORE_REDO,
    },
    // {
    //   id: CMD_PANEL_IMPORT,
    //   className: 'fa fa-download',
    //   command: e => e.runCommand(CMD_PANEL_IMPORT),
    // },
    {
      id: CMD_PANEL_CLEAR,
      label: iconElement('delete'),
      command: CMD_PANEL_CLEAR,
    }],
  },
  // Views panel
  {
    id: 'views',
    buttons  : [{
      active: false,
      command: CMD_PANEL_OPEN_TRAITS,
      id: CMD_PANEL_OPEN_TRAITS,
      label: iconElement('settings'),
      togglable: false,
      visible: false
    },
    // {
    //   id: osm,
    //   command: osm,
    //   active: true,
    //   className: 'fa fa-paint-brush',
    // },
    {
      active: false,
      command: CMD_PANEL_OPEN_LAYERS,
      id: CMD_PANEL_OPEN_LAYERS,
      label: iconElement('view_list'),
      togglable: false,
      visible: false
    }, {
      active: false,
      command: CMD_PANEL_OPEN_BLOCKS,
      id: CMD_PANEL_OPEN_BLOCKS,
      label: iconElement('extension'),
      togglable: false,
      visible: false
    }],
  }
  ]);

  // Add devices buttons
  const panelDevices = Panels.addPanel({id: 'devices-c'});
  panelDevices.get('buttons').add([{
    id: CMD_PANEL_DEVICE_DESKTOP,
    command: CMD_PANEL_DEVICE_DESKTOP,
    label: iconElement('desktop_mac'),
    active: 1,
  }, {
    id: CMD_PANEL_DEVICE_TABLET,
    command: CMD_PANEL_DEVICE_TABLET,
    label: iconElement('tablet_mac')
  }, {
    id: CMD_PANEL_DEVICE_MOBILE,
    command: CMD_PANEL_DEVICE_MOBILE,
    label: iconElement('phone_iphone')
  }]);

  // // On component change show the Style Manager
  // config.showStylesOnChange && editor.on('component:selected', () => {
  //   const openSmBtn = Panels.getButton('views', osm);
  //   const openLayersBtn = Panels.getButton('views', openLayers);

  //   // Don't switch when the Layer Manager is on or
  //   // there is no selected component
  //   if ((!openLayersBtn || !openLayersBtn.get('active')) && editor.getSelected()) {
  //     openSmBtn && openSmBtn.set('active', 1);
  //   }
  // });
}