import { userCircleCard } from './cards';

import {
  Defaults,
  Traits,
} from './shared';

import { ICON_BUTTON_USER_CIRCLE } from './../../../const/icons';

import { COMP_BUTTON_USER_CIRCLE } from './../../../const/components';

import { TRAIT_USER_CIRCLE_SELECTOR } from './../../../const/traits';

import { PageFormLinkedItem } from './../page-form-linked-item';

export const UserCircle = (editor, options:any = {}) => {

  return PageFormLinkedItem(editor, {
    componentName: COMP_BUTTON_USER_CIRCLE,
    displayIcon: ICON_BUTTON_USER_CIRCLE,
    model: {
      defaults: { ...Defaults,
        name: 'User Circle',
        traits: [ ...Traits, {
          type: TRAIT_USER_CIRCLE_SELECTOR,
          at: 9
        }]
      }
    },
    cardOnRender() {
      return userCircleCard.call(this);
    }
  });
}