export const SettingSections = {
  sections: [
    {
      title: 'General Settings',
      links: [
        { title: 'Calendars', link: '/calendars' },
        { title: 'Event Categories', link: '/event-categories' },
        { title: 'Contract Types', link: '/contract-types' },
        { title: 'User Positions', link: '/user-positions' },
        { title: 'Countries', link: '/countries' },
        { title: 'Locations', link: '/locations' },
        { title: 'Timezones', link: '/timezones' },
        { title: 'Custom Fields', link: '/custom-field-groups', sportsologySuperAdmin: true },
        { title: 'API Settings', link: '/client-settings' },
        { title: 'Master API Settings', link: '/master-settings', sportsologySuperAdmin: true },
        { title: 'View Laravel Log', link: '/view-laravel-log', sportsologySuperAdmin: true }
      ]
    },
    {
      title: 'Admin Settings',
      links: [
        { title: 'Groups', link: '/groups' },
        { title: 'Roles', link: '/roles', sportsologySuperAdmin: true },
        { title: 'Permissions', link: '/permissions', sportsologySuperAdmin: true },
        // { title: 'Notes', link: '/notes' },
        { title: 'Apps', link: '/apps', sportsologySuperAdmin: true },
        { title: 'Activity Log', link: '/activities' },
        { title: 'Content Log', link: '/content-logs' },
        { title: 'Access Codes', link: '/access-codes', sportsologySuperAdmin: true },
        { title: 'Export Data', link: '/exports' },
      ]
    },
    {
      title: 'Notification Settings',
      links: [
        { title: 'Contact Groups', link: '/contact-groups', sportsologySuperAdmin: true },
        { title: 'User Circles', link: '/user-circles' },
        { title: 'Secondary Contacts', link: '/contacts' },
      ]
    }
  ]
};
