import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileInfo } from '@app/models/entities/fileInfo';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { BundleService } from '@app/services/bundle.service';

@Component({
  selector: 'edit-bundle-item-dialog',
  templateUrl: 'edit-bundle-item-dialog.component.html',
  styleUrls: ['edit-bundle-item-dialog.component.scss']
})
export class EditBundleItemDialogComponent implements OnInit
{
    public _fileDetails: any;
    public _bundleItemData: any;
    public _bundleUuid: string;

    public  bundleItemFormGroup: FormGroup;
    public  dataLoaded: boolean = false;
    public  formSubmitted: boolean = false;
    public  type: string;

    public get controls(): any { return this.bundleItemFormGroup.controls; }

    public get itemUuid(): any {
        var itemUuid:any = false;
        if (this.type === 'article') {
            itemUuid = this.bundleItemFormGroup.controls.article_uuid.value;
        }
        if (this.type === 'article_category') {
            itemUuid = this.bundleItemFormGroup.controls.article_category_uuid.value;
        }
        if (this.type === 'directory_category') {
            itemUuid = this.bundleItemFormGroup.controls.directory_category_uuid.value;
        }
        if (this.type === 'directory_item') {
            itemUuid = this.bundleItemFormGroup.controls.directory_item_uuid.value;
        }
        if (this.type === 'sub_bundle' || this.type === 'bundle') {
            itemUuid = this.bundleItemFormGroup.controls.sub_bundle_uuid.value;
        }
        if (this.type === 'page') {
            itemUuid = this.bundleItemFormGroup.controls.page_uuid.value;
        }
        if (! itemUuid) {
            return false;
        }
        return itemUuid;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private _formBuilder: FormBuilder,
        private bundleService: BundleService,
        private dialogRef: MatDialogRef<EditBundleItemDialogComponent>
    ) {
        this._bundleUuid = data.bundleUuid;
        this._bundleItemData = data.bundleItemData;
        this.type = this._bundleItemData.bundle_item_type;
    }

    ngOnInit() {
        this._initialFormGroup();
        this.dataLoaded = true;
    }

    /**
     * Form group initial data
     */
    public _initialFormGroup(): void {
        var bundleItem:any = {
            uuid: [this._bundleItemData.uuid],
            title: [this._bundleItemData.title, [
                Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)
            ]],
            bundle_uuid: [this._bundleUuid],
            teaser: [this._bundleItemData.teaser],
            // description: [this._bundleItemData.description],
            url: [this._bundleItemData.url],
            bundle_item_type: [this.type],
            featured_image_cdn_uuid: [this._bundleItemData.featured_image_cdn_uuid],
            test: [this._bundleItemData.featured_image_cdn_uuid],
            featured_image_cdn_url: [this._bundleItemData.featured_image_cdn_url],
            featured_image_width: [this._bundleItemData.featured_image_width],
            featured_image_height: [this._bundleItemData.featured_image_height],
            published: [this._bundleItemData.published],
            open_in_app_browser: [this._bundleItemData.open_in_app_browser]
        };
        if (this._bundleItemData.article) {
          bundleItem.article_uuid = [this._bundleItemData.article.uuid];
        }
        if (this._bundleItemData.article_category) {
          bundleItem.article_category_uuid = [this._bundleItemData.article_category.uuid];
        }
        if (this._bundleItemData.directory_category) {
          bundleItem.directory_category_uuid = [this._bundleItemData.directory_category.uuid];
        }
        if (this._bundleItemData.directory_item) {
          bundleItem.directory_item_uuid = [this._bundleItemData.directory_item.uuid];
        }
        if (this._bundleItemData.sub_bundle) {
          bundleItem.sub_bundle_uuid = [this._bundleItemData.sub_bundle.uuid];
        }
        if (this._bundleItemData.page) {
          bundleItem.page_uuid = [this._bundleItemData.page.uuid];
        }
        this.bundleItemFormGroup = this._formBuilder.group(bundleItem);
    }

    /**
     * Get file info after file has been uploaded to the cdn
     *
     * @param {FileInfo} $fileInfo
     */
    public getUploadedFileInfo($fileInfo: FileInfo) {
        if ($fileInfo) {
            this.bundleItemFormGroup.patchValue({
                featured_image_cdn_uuid: $fileInfo.uuid,
                featured_image_cdn_url: $fileInfo.cdnUrl,
                featured_image_width: $fileInfo.width,
                featured_image_height: $fileInfo.height
            });
        }
    }

    /**
     * Send data back to parent
     */
    public saveAndCloseDialog() {
        var data = this.bundleItemFormGroup.value;
        if (! data.title) {
            data.title = null;
        }
        if (! data.teaser) {
            data.teaser = null;
        }
        this.formSubmitted = true;
        if (this.type === 'url') {
            this.dialogRef.close({ update: true, data: data });
        } else {
            this.bundleService.getContentTypeItem(this.itemUuid, this.type)
                .subscribe(relatedItem => {
                    if (this.type === 'bundle') {
                        data['sub_bundle'] = relatedItem;
                    } else {
                        data[this.type] = relatedItem;
                    }
                    this.dialogRef.close({ update: true, data: data });
                });
        }
    }

    /**
     * Close dialog
     */
    public closeDialog() {
        this.dialogRef.close({ update: false });
    }
}
