import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { ClientSettingService } from '@services/client-setting.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
@Component({
  selector: 'app-client-settings-index',
  templateUrl: './client-settings-index.component.html',
  styleUrls: ['./client-settings-index.component.scss']
})
export class ClientSettingsIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public node: Breadcrumb;
  public data: any;
  public displayedColumns = ['name', 'group_title', 'type_title', 'value', 'actions'];
  public dataLoaded = false;
  constructor(
    public clientSettingService: ClientSettingService,
    public router: Router,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: clientSettingService,
      router,
      state,
      snackbarService,
      dialog
    });

    this.setPageVars({
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }

  public updatedStatus(): void {
    this.state.message({ ['ClientSettingIndexUpdate']: true });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.node = {
      label: 'Api Settings',
      link: 'client-settings',
      title: 'Api Settings'
    };
    this.buildSettingsBreadcrumb(this.node);
  }
}
