import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SnackBarService } from '@app/services/snack-bar.service';
import { ContactGroupService } from '@app/services/contact-group.service';
import { ContactGroupUserService } from '@app/services/contact-group-user.service';
@Component({
  selector: 'app-contact-group-view',
  templateUrl: './contact-group-view.component.html',
  styleUrls: ['./contact-group-view.component.scss'],
})
export class ContactGroupViewComponent extends BaseComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public dataSource: any;
  public data: any;
  public dataLoaded = false;
  public groupId = null;
  public displayedColumns = [
    'avatar',
    'firstname',
    'show_email',
    'show_telephone',
    'show_mobile',
    'show_biography',
    'show_role_responsibility',
    'show_okr',
    'show_address',
    'actions',
  ];

  constructor(
    public activatedRoute: ActivatedRoute,
    public contactGroupService: ContactGroupService,
    public contactGroupUserService: ContactGroupUserService,
    public router: Router,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      modelService: contactGroupService,
      router: router,
      state: state,
      snackbarService,
      dialog,
    });
    this.setPageVars({
      listenForIndexUpdate: true,
    });
  }

  public updatedStatus(): void {
    this.state.message({
      ['ContactGroupUserUpdate']: true,
      ['id']: this.routeParams['id'],
    });
    this.loadContactGroup();
  }

  public removeUserFromGroup(group_uuid: string, user_uuid: string, user_name: string) {
    return this.modelService.removeUserFromGroup(group_uuid, user_uuid).subscribe(
      (result: any) => {
        this.loadContactGroup();
        this.snackbarService.success({
          message: `${user_name} was removed from the group`,
        });
      },
      (error: any) => {
        console.log('There was an error removing ' + user_name + ': ' + JSON.stringify(error));
      }
    );
  }

  private loadContactGroup(): void {
    this.groupId = this.routeParams['id'];
    this.modelService.getOne(this.groupId).subscribe((result: any) => {
      this.buildBreadcrumb(result.name);
      this.dataSource = new MatTableDataSource(result.users);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataLoaded = true;
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadContactGroup();
  }

  private buildBreadcrumb(crumb: string): void {
    this.nodes = [];
    this.nodes.push(
      {
        label: 'Settings',
        link: 'settings',
      },
      {
        label: 'Contact Groups',
        link: 'contact-groups',
      },
      {
        label: crumb,
        link: '',
      }
    );
  }
}
