import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { BundleViewService} from '@services/bundle-view.service';
import { BundleItemService } from '@app/services/bundle-item.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-bundle-view',
  templateUrl: './bundle-view.component.html',
  styleUrls: ['./bundle-view.component.scss']
})
export class BundleViewComponent extends BaseComponent implements OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  public dataSource: any;
  public data: any;
  public dataLoaded = false;
  public bundleID = null;
  public displayedColumns = [
    'select',
    'created_at',
    'title',
    'type',
    'published',
    'actions'
  ];

  constructor(
    public activatedRoute: ActivatedRoute,
    public bundleViewService: BundleViewService,
    public router: Router,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog,
    private bundleItemService: BundleItemService
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      modelService: bundleViewService,
      router: router,
      state: state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      listenForIndexUpdate: true
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadBundleData();
    this.buildBreadcrumb();
  }

  public updatedStatus(): void {
    this.state.message({
      ['BundleViewUpdate']: true,
      ['id']: this.routeParams['id']
    });
  }

  // Overrides the usual openSlideOutWithLink function
  public openFormSlideOut(itemId: any, action = 'edit') {
    const url = `/bundles/view/${this.routeParams['id']}/(slideout:bundle-item-${action}/${itemId})`;
    this.router.navigateByUrl(url,
      { skipLocationChange: true }).then(() => this.state.message({ slideoutShow: true }));
  }

  // Overrides the usual openSlideOutWithLink function
  public openSortSlideOut(itemId) {
    const url = `/bundles/view/${this.routeParams['id']}/(slideout:bundle-item-sort/${itemId})`;
    this.router.navigateByUrl(url,
      { skipLocationChange: true }).then(() => this.state.message({ slideoutShow: true }));
  }

  // Override the generic delete method as we need customization - We need to remove the current row from BundleItem table.
  public modelServiceDelete(uuid) {
    const modalService =  this.bundleItemService;
    this.modelService.modelTitle = 'Bundle item';
    this.confirmDialog().then(confirmed => {
      if (confirmed) {
        modalService.delete(uuid).subscribe(
          result => {
            this.loadBundleData();
            this.snackbarService.success({ message: `Bundle item deleted` });
          }
        );
      }
    });
  }

  // Delete multiple items selected from table checkboxes
  // this overrides the generic function in base.component
  modelServiceSelectedDelete() {
    if (this.selection.selected.length === 0) {
      this.snackbarService.warning({ message: `No Items selected` });
      return;
    }
    this.confirmDialog().then(confirmed => {
      if (confirmed) {
        const snackbarRef = this.snackbarService.openProgress('Deleting...');
        forkJoin(this.selection.selected.map(selected => this.bundleItemService.delete(selected.uuid))).subscribe(
          result => {
            this.loadBundleData();
            snackbarRef.dismiss();
            this.snackbarService.success({ message: `Items deleted successfully` });
          },
          (error: any) => {
            console.log('Error deleting multiple content: ' + JSON.stringify(error));
          }
        );
      }
      this.selection.clear();
    });
  }

  private loadBundleData(): void {
    this.modelService.getAllWithId(this.routeParams['id']).subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataLoaded = true;
      this.bundleID = this.routeParams['id'];
    });
  }

  private buildBreadcrumb(): void {
    this.nodes = [];
    this.nodes = [{
      label: 'Bundles',
      link: 'bundles',
      title: 'Bundles'
    }];
    this.nodes.push({
      label: 'Bundle view',
      link: 'Bundle view',
      title: 'Bundle view'
    });
  }

}
