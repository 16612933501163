import { paramCase } from 'change-case';

import {
  fixToolbarPositinoOnComponentHeightChange,
  iconElement,
  modelSetClass,
  updatePageFormItemPerms,
  updatePageFormItems
} from './../../utils';

import {
  COMP_BUTTON_BUNDLE,
  COMP_LAYOUT_SECTION,
  COMP_LOREM_IPSUM,
  COMP_WIDGET_BUNDLE
} from './../../../const/components';

import { ICON_BUTTON_BUNDLE } from './../../../const/icons';

import {
  CMD_GET_ITEM_SNAPSHOT,
  CMD_OPEN_PAGE_ITEM_SELECTOR,
  CMD_UPDATE_PAGE_FORM_ITEM_PERMS,
  CMD_UPDATE_PAGE_FORM_ITEMS
} from './../../../const/commands';

import {
  FIELD_BUNDLE_DISPLAY_AS,
  FIELD_BUNDLE_HIDE_BUNDLE_TITLE,
  FIELD_BUNDLE_SELECTOR,
  FIELD_CURRENT_IMAGE_URL,
  FIELD_TITLE
} from './../../../const/traits';

import {
  FIELD_BUNDLE_DISPLAY_AS_DEFAULT,
  FIELD_BUNDLE_DISPLAY_AS_LIST_OPTIONS,
  FIELD_BUNDLE_DISPLAY_AS_SLIDER_OPTIONS
} from './../../traits/bundle-display-as';

const compParamName = paramCase(COMP_WIDGET_BUNDLE);

export const BundleWidget = (editor, options:any = null) => {
  return {
    isComponent: el => el.tagName === compParamName.toUpperCase(),
    // Model
    model: {
      init() {
        const attrs = this.getAttributes();

        if (!! attrs.itemUUID) {
          const snapshot = editor.Commands.run(CMD_GET_ITEM_SNAPSHOT, {
            itemType: COMP_BUTTON_BUNDLE, itemUUID: attrs.itemUUID
          });

          this.set('snapshot', snapshot);
        }
      },
      defaults: {
        hasComponentItems: true,
        tagName: compParamName,
        itemType: COMP_BUTTON_BUNDLE,
        name: 'Bundle',
        displayIcon: ICON_BUTTON_BUNDLE,
        droppable: false,
        draggable: paramCase(COMP_LAYOUT_SECTION),
        attributes: {
          displayAs: FIELD_BUNDLE_DISPLAY_AS_DEFAULT,
          hideBundleTitle: null,
          itemsPerms: []
        },
        snapshot: {},
        traits: [
          FIELD_CURRENT_IMAGE_URL,
          FIELD_BUNDLE_HIDE_BUNDLE_TITLE,
          FIELD_BUNDLE_DISPLAY_AS,
          FIELD_BUNDLE_SELECTOR
        ]
      }
    },
    // View
    view: {
      tagName: compParamName,
      // Events
      events: { dblclick: 'doubleClickEvent' },
      // Dblclick
      doubleClickEvent(e) {
        editor.Commands.run(CMD_OPEN_PAGE_ITEM_SELECTOR, {
          itemType: COMP_BUTTON_BUNDLE, buttonText: 'Select a Bundle'
        });
      },
      // Init
      init() {
        // editor.select(this.model);
        updatePageFormItems.call(this, editor);
        updatePageFormItemPerms.call(this, editor, true);
        this.listenTo(this.model, 'change:attributes', this.handleAttributeChanges);
      },
      // Attribute changes handler
      handleAttributeChanges() {
        updatePageFormItems.call(this, editor);
        updatePageFormItemPerms.call(this, editor, true);
        this.render();
        fixToolbarPositinoOnComponentHeightChange(editor);
      },
      // On render
      onRender() {
        const attrs = this.model.getAttributes();
        const snapshot = this.model.get('snapshot');
        const icon = iconElement(this.model.get('displayIcon'));
        let html = '';

        if (!! snapshot.title) {
          const isList = FIELD_BUNDLE_DISPLAY_AS_LIST_OPTIONS.find(option => option.id === attrs.displayAs);
          let bundleStyle = '';

          if (!! isList) {
            bundleStyle = 'List ' + FIELD_BUNDLE_DISPLAY_AS_LIST_OPTIONS
              .find(option => option.id === attrs.displayAs).title;
          } else {
            bundleStyle = 'Slider ' + FIELD_BUNDLE_DISPLAY_AS_SLIDER_OPTIONS
              .find(option => option.id === attrs.displayAs).title;
          }
          html = `<item-title>${icon} ${snapshot.title} - ${bundleStyle}</item-title>`;
        } else {
          html = `<item-title>${icon} Bundle not set</item-title>`;
        }

        this.el.innerHTML = `<div class="widget-inner">${html}</div>`;
        modelSetClass.call(this, `widget-item ${compParamName}`);
      }
    }
  }
}