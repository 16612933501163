import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { UserSecondaryContactService } from '@services/user-secondary-contact.service';
@Component({
  selector: 'user-secondary-contacts-index',
  templateUrl: '../../../contacts/index/contacts-index.component.html',
  styleUrls: ['../../../contacts/index/contacts-index.component.scss'],
  providers: [SidebarService]
})
export class UserSecondaryContactsIndexComponent extends BaseComponent implements OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  @Input() hideSidebar = true;
  @Input() showSearch = false;
  @Input() uuid: string;

  public dataSource: any;
  public data: any;
  public displayedColumns = ['name', 'contact_type_name', 'primary_carer', 'email', 'mobile', 'actions'];
  public dataLoaded = false;

  constructor(public sidebarService: SidebarService, public state: State, public userSecondaryContactService: UserSecondaryContactService) {
    super();
    this.setObjects({
      modelService: userSecondaryContactService,
      sidebarService: sidebarService,
      state: state
    });
    this.setPageVars({
      listenForIndexUpdate: true
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.modelService.getAllWithId(this.uuid).subscribe(data => {
      this.dataSource = new MatTableDataSource(data['data']);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataLoaded = true;
    });
  }
}
