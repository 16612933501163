import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'sidebar-filter-form',
  templateUrl: './sidebar-filter-form.component.html',
  styleUrls: []
})
export class SidebarFilterFormComponent implements OnInit {
  @Input() fields: any;
  form: FormGroup;
  submitted = false;
  checkboxCount = new BehaviorSubject<number>(0);
  @Output() formCreated = new EventEmitter<FormGroup>();

  constructor(private fb: FormBuilder) { }

  // For creating form
  createFormControl(formFields) {
    const group = this.fb.group({});
    formFields.forEach(field => {
      if (field.type === 'checkbox') {
        this.checkboxCount.next(this.checkboxCount.getValue() + 1);
      } else {
        const control = this.fb.control(field.value, this.addFormControlValidations(field.validations || []));
        group.addControl(field.name, control);
      }
    });
    return group;
  }

  // Add validations to form control
  addFormControlValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  ngOnInit() {
    this.form = this.createFormControl(this.fields);
    this.checkboxCount.subscribe(val => {
      if (val === 0) {
        if (this.formCreated.observers.length > 0) {
          this.formCreated.emit(this.form);
        }
      }
    });
  }

  controlsInitialized() {
    this.checkboxCount.next(this.checkboxCount.getValue() - 1);
  }
}
