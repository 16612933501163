import { Component, Injectable, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EventService } from '@app/services/event.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { MatDialog } from '@angular/material';
import { SnackBarService } from '@app/services/snack-bar.service';
import { SidebarService } from '@services/sidebar.service';
import * as Moment from 'moment';
import * as MomentTimezone from 'moment-timezone';
@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss', './event-form-700.component.scss'],
  providers: [SidebarService]
})

@Injectable()
export class EventFormComponent extends BaseComponent  {
  // Local variables
  eventFormGroup: FormGroup;
  customFields: any;
  calendarCols: any;
  attendeeCols: any;
  documentCols: any;
  articleCols: any;
  selectedCalendarIds = [];
  selectedAttendeeIds = [];
  selectedDocumentIds = [];
  selectedArticleIds = [];
  onToManyColumnsDef = [];
  dataLoaded = false;
  formSubmitted = false;
  showEventReminderButtons = false;
  onLoaded: any;

  public defaultTimezone:any;
  public formInvalid: boolean = false;
  public formMode = 'create';
  public userTimezoneName:any;
  public showCustomFieldsTab = true;

  // ViewChild elements
  @ViewChild('calendars', {static: false}) private advancedSelectCalendarsInput: { getSelectedIds: () => any[]; };
  @ViewChild('attendees', {static: false}) private advancedSelectAttendeesInput: { getSelectedIds: () => any[]; };
  @ViewChild('documents', {static: false}) private advancedSelectDocumentsInput: { getSelectedIds: () => any[]; };
  @ViewChild('articles', {static: false}) private advancedSelectArticlesInput: { getSelectedIds: () => any[]; };

  private config = GlobalUtil.Configuration;
  private hour12Timer = true;
  private showTime = true;

  constructor(
    public activatedRoute: ActivatedRoute,
    public eventService: EventService,
    public sidebarService: SidebarService,
    public snackbarService: SnackBarService,
    public state: State,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: eventService,
      snackbarService,
      dialog
    });
    this.setPageVars({
      sidebar: 'events'
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.setupColDefs();
    const id = this.routeParams['id'];

    this.defaultTimezone = this.state.get('defaultTimezone');
    this.userTimezoneName = this.defaultTimezone.name;

    this.initialFormGroup();
    if (id === undefined) {
      this.dataLoaded = true;
      this.formMode = 'create';
      this.modelServiceGetAll(1, null, 'custom-fields').then(() => {
        this.customFields = this.dataSourceRows;
        this.customFields = this.customFields.filter(r => r.models.indexOf('App\\Event') > -1);
        this.customFields.map(group => group.selected = false );
        this.initialiseCustomFields(this.customFields);
      });
    } else {
      this.formMode = 'edit';
      this.modelService.getOne(id).subscribe((result: any) => {
        this.customFields = result.custom_fields;
        this.populateInitialData(result);
        this.initialiseCustomFields(result.custom_fields);
        this.eventFormGroup.patchValue(result);
        this.eventFormGroup.patchValue({
          send_as_email: null,
          send_as_sms: null,
          send_as_push: null,
          start_time: result.start_time_local,
          end_time: result.end_time_local,
          location_uuid: result.location ? result.location.uuid : null,
          event_category_uuid: result.event_category ? result.event_category.uuid : null,
          start_time_timezone_uuid: result.start_time_timezone ? result.start_time_timezone.uuid : null,
          end_time_timezone_uuid: result.end_time_timezone ? result.end_time_timezone.uuid : null,
          schedule_notification_timezone_uuid: result.schedule_notification_timezone ? result.schedule_notification_timezone.uuid : null
        });
        this.dataLoaded = true;
      });
    }
  }

  private setupColDefs(): void {
    // Setup the columns settings for the multi-selection table (Calendar)
    this.calendarCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` }
    ];

    // Setup the columns settings for the multi-selection table (Attendee)
    this.attendeeCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` }
    ];

    // Setup the columns settings for the multi-selection table (Document)
    this.documentCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      {
        columnDef: 'thumbnail',
        selectedOnclick: false,
        showThumbnail: true,
        header: '',
        cell: (element: any) => `${element.cdn_uuid}`
      },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` },
      {
        columnDef: 'created_at',
        header: 'Created On',
        selectedOnclick: false,
        dateFormat: this.config.SHORT_DATE_FORMAT,
        cell: (element: any) => `${element.created_at}`
      }
    ];

    // Setup the columns settings for the multi-selection table (Articles)
    this.articleCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'title', selectedOnclick: true, header: 'Title', cell: (element: any) => `${element.title}` },
      // {
      //   columnDef: 'article_categories_with_attributes',
      //   selectedOnclick: false,
      //   header: 'Categories',
      //   cell: (element: any) => `${element.article_categories_with_attributes}`
      // },
      {
        columnDef: 'created_at',
        header: 'Created On',
        selectedOnclick: false,
        dateFormat: this.config.SHORT_DATE_FORMAT,
        cell: (element: any) => `${element.created_at}`
      }
    ];

    // this.onToManyColumnsDef.push('article_categories_with_attributes');
  }

  private addEventReminder() {
    this.controls.event_reminders.push(
      this.formBuilder.group({
        type: [null, [Validators.required]],
        offset_time: [15, [Validators.required]],
        offset_unit: [null, [Validators.required]]
      })
    );
  }

  private removeEventReminder(i) {
    this.controls.event_reminders.removeAt(i);
  }

  get controls(): any {
    return this.eventFormGroup.controls;
  }

  public setScheduleDate(dateValue: string) {
    this.eventFormGroup.patchValue({
      scheduled_notification_date: Moment(dateValue)
    });
  }

  public setScheduleTimezone(timezoneId: string) {
    this.eventFormGroup.patchValue({
      scheduled_notification_timezone_uuid: timezoneId
    });
  }

  public save(): void {
    this.formSubmitted = true;
    const id = this.controls.id.value;
    const uuid = this.controls.uuid.value;
    this.replaceBooleanFieldsWithByte();
    // Patch all the multi-select tables value and transfor the start & end date&time to specific format as per API.
    this.eventFormGroup.patchValue({
      calendars: this.getSelectedCalendars(),
      attendees: this.getSelectedAttendees(),
      documents: this.getSelectedDocuments(),
      articles: this.getSelectedArticles(),
      start_time: this.transformDateTime(this.controls.start_time.value),
      end_time: this.transformDateTime(this.controls.end_time.value)
    });
    if (id || uuid) {
      // Update the existing event
      this.eventService.patchById(uuid ? uuid : id, this.eventFormGroup.value).subscribe(result => this.SavedMessage(result));
    } else {
      // Create the new event
      this.eventService.create(this.eventFormGroup.value).subscribe(result => this.SavedMessage(result));
    }
    // Rollback the changes so all the checkboxes will bound correctly
    this.replaceByteFieldsWithBoolean();
    setTimeout (() => {
      this.formSubmitted = false;
    }, 3000);
  }

  /**
   * Called when start date is changed on the form
   *
   * @param dateValue
   */
  public setStartDate(dateValue: string) {
    this.eventFormGroup.patchValue({
      start_time: dateValue
    });
    if (this.formMode === 'create') {
      var newEndTime:string;
      if(this.controls.all_day_event.value) {
        newEndTime = Moment(dateValue).set({ hour: 23, minute: 59, second: 59 }).format(this.config.LONG_DATE_FORMAT_DISPLAY_12HOURS);
      } else {
        newEndTime = Moment(dateValue).add(30, 'minutes').format(this.config.LONG_DATE_FORMAT_DISPLAY_12HOURS);
      }
      const endTimeElement: HTMLInputElement = document.getElementById('event-form-end-time') as HTMLInputElement;
      endTimeElement.value = newEndTime;
      this.setEndDate(newEndTime);
    }
    // Future development ...
    // We should also update the endtime when the form is in edit mode
    // When startTime changes, calculate the difference between existing date and new date
    // Then update endTime with the same date difference
  }

  public setStartDateTimezone(timezoneId: string) {
    this.eventFormGroup.patchValue({
      start_time_timezone_uuid: timezoneId
    });
  }

  public setEndDate(dateValue) {
    this.eventFormGroup.patchValue({
      end_time: dateValue
    });
  }

  public setEndDateTimezone(timezoneId: string) {
    this.eventFormGroup.patchValue({
      end_time_timezone_uuid: timezoneId
    });
  }

  public allDayEventSelected() {
  }

  /**
   * CustomField form elements call this function to determine if the field is required
   * @param field
   */
  public getRequiredStatus(field): boolean {
    return field.required === 1 ? true : false;
  }

  // All the private methods (Private method should be after all the public methods)
  private populateInitialData(row): void {
    if (row !== undefined) {
      this.selectedIds(row);
    }
  }

  /**
   * Use this method to validate the result from server after our request.
   * Calls state messages:
   * one updates the Event index page in case we opened the form from there
   * the other refreshes the calendar view in case we opened the form from there
   *
   * @param data
   */
  private SavedMessage(data) {
    this.state.message({
      EventIndexUpdate: true,
      ScheduleUpdate: true
    });
    this.closeSlideout();
  }

  /**
   * setup the initial form for event entity
   */
  private initialFormGroup(): void {
    this.eventFormGroup = this.formBuilder.group({
      uuid: null,
      id: null,
      event_category_uuid: [null, [Validators.required]],
      name: [null, [Validators.required]],
      featured_event: [null],
      description: [null],
      show_description_on_screen: [null],
      all_day_event: [null],
      start_time: [this.nowData(), [Validators.required]],
      start_time_timezone_uuid: [this.defaultTimezone.uuid],
      hide_start_time: [null],
      end_time: [this.updateEndTime(this.nowData()), [Validators.required]],
      end_time_timezone_uuid: [this.defaultTimezone.uuid],
      hide_end_time: [null],
      location_uuid: [null],
      postcode: [null],
      calendars: [null],
      attendees: [null],
      documents: [null],
      articles: [null],
      send_as_email: [null],
      send_as_sms: [null],
      send_as_push: [null],
      push_action_to_app_page: [{ value: false, disabled: true }],
      page_uuid: [null],
      scheduled_notification_date: [null],
      scheduled_notification_timezone_uuid: [null],
      event_reminders: this.formBuilder.array([]),
    });
    // If we need time then timezone fields should be mandatory.
    if (this.showTime) {
      this.setTimeZoneRequired();
    }
    this.controls.page_uuid.valueChanges.subscribe(value => {
      if (value === null) {
        this.controls.push_action_to_app_page.setValue(false);
        this.controls.push_action_to_app_page.disable();
      } else {
        this.controls.push_action_to_app_page.enable();
      }
    });
  }

  /**
   * Append the CustomFields to the FormGroup
   */
  private initialiseCustomFields(data): void {
    const fields = {};
    data.forEach(group => {
      group.custom_fields.forEach(field => {
        fields[field.uuid] = new FormControl(field.custom_field_value,
          {
            validators: field.required ? Validators.compose([Validators.required]) : null
          });
      });
    });
    this.eventFormGroup.addControl('custom_fields', new FormGroup(fields));
  }

  private replaceBooleanFieldsWithByte(): void {
    // Replace all the boolean fields with byte
    this.eventFormGroup.patchValue({
      featured_event: this.controls.featured_event.value ? '1' : '0',
      all_day_event: this.controls.all_day_event.value ? '1' : '0',
      show_description_on_screen: this.controls.show_description_on_screen.value ? '1' : '0',
      hide_start_time: this.controls.hide_start_time.value ? '1' : '0',
      hide_end_time: this.controls.hide_end_time.value ? '1' : '0',
      send_as_sms: this.controls.send_as_sms.value ? '1' : '0',
      send_as_email: this.controls.send_as_email.value ? '1' : '0',
      send_as_push: this.controls.send_as_push.value ? '1' : '0',
    });
  }

  private replaceByteFieldsWithBoolean(): void {
    // Replace all the boolean fields with byte
    this.eventFormGroup.patchValue({
      featured_event: this.controls.featured_event.value === '1' ? true : false,
      all_day_event: this.controls.all_day_event.value === '1' ? true : false,
      show_description_on_screen: this.controls.show_description_on_screen.value === '1' ? true : false,
      hide_start_time: this.controls.hide_start_time.value === '1' ? true : false,
      hide_end_time: this.controls.hide_end_time.value === '1' ? true : false,
      send_as_sms: this.controls.send_as_sms.value === '1' ? true : false,
      send_as_email: this.controls.send_as_email.value === '1' ? true : false,
      send_as_push: this.controls.send_as_push.value === '1' ? true : false,
    });
  }

  public closeSlideout() {
    document.getElementById('sidebar').classList.remove('open');
    this.state.update({
      isInNewEventMode: false
    });
    this.state.message({ slideoutHide: true });
  }

  /**
   * Delete the slideout from card title
   * Custom function to override the one in base.component
   *
   */
  public deleteEvent(uuid: string) {
  // Override the generic delete method as we need customization - We need to remove the current row from BundleItem table.
    // this.modelService.modelTitle = 'Bundle item';
    this.confirmDialog().then(confirmed => {
      if (confirmed) {
        this.modelService.delete(uuid).subscribe(
          result => {
            this.SavedMessage(result),
            this.snackbarService.success({ message: 'Event deleted' });
          }
        );
      }
    });
  }

  private getSelectedCalendars(): any[] {
    const value: any[] = this.advancedSelectCalendarsInput.getSelectedIds();
    return value === null ? null : value;
  }

  private getSelectedAttendees(): any[] {
    const value: any[] = this.advancedSelectAttendeesInput.getSelectedIds();
    return value === null ? null : value;
  }

  private getSelectedDocuments(): any[] {
    const value: any[] = this.advancedSelectDocumentsInput.getSelectedIds();
    return value === null ? null : value;
  }

  private getSelectedArticles(): any[] {
    const value: any[] = this.advancedSelectArticlesInput.getSelectedIds();
    return value === null ? null : value;
  }

  private selectedIds(row) {
    row.calendars.forEach(el => {
      this.selectedCalendarIds.push(el);
    });
    row.attendees.forEach(el => {
      this.selectedAttendeeIds.push(el);
    });
    row.documents.forEach(el => {
      this.selectedDocumentIds.push(el);
    });
    row.articles.forEach(el => {
      this.selectedArticleIds.push(el);
    });
    row.articles.forEach(el => {
      this.selectedArticleIds.push(el);
    });
    row.reminders_with_attributes.forEach(el => {
      this.controls.event_reminders.push(
        this.formBuilder.group({
          type: [el.type, [Validators.required]],
          offset_time: [el.offset_time, [Validators.required]],
          offset_unit: [el.offset_unit, [Validators.required]],
          uuid: [el.uuid]
        })
      );
    });
  }

  private transformDateTime(val: any): any {
    return Moment(val).format(this.config.LONG_DATE_FORMAT_FOR_SAVE_ENTRY);
  }

  private nowData(): string {
    var date:any;
    if (this.formMode === 'create' && !! this.routeParams['newEventAtDate']) {
      if (this.hour12Timer) {
        date = MomentTimezone(this.routeParams['newEventAtDate']).tz(this.userTimezoneName);
      } else {
        date = MomentTimezone(this.routeParams['newEventAtDate']).tz(this.userTimezoneName);
      }
    } else {
      if (this.hour12Timer) {
        date = MomentTimezone().tz(this.userTimezoneName);
      } else {
        date = MomentTimezone().tz(this.userTimezoneName);
      }
    }

    if (!! this.routeParams['newEventAtDate']) {
      // make sure that time is 9am local what ever the TZ
      date.set({ hour: 9, minute: 0, second: 0 });
    }

    date = date.format(this.showTime
      ? this.config.LONG_DATE_FORMAT_DISPLAY_12HOURS
      : this.config.DATE_PICKER_SHORT_DATE_FORMAT_DISPLAY
    );

    return date;
  }

  /**
   * Called from initialFormGroup() - sets endTime to be 30 mins the future
   * @param dateValue
   */
  private updateEndTime(dateValue) {
    return Moment(this.nowData()).add(30, 'minutes').format(this.config.LONG_DATE_FORMAT_DISPLAY_12HOURS);
  }

  private setTimeZoneRequired(): void {
    this.eventFormGroup.get('start_time_timezone_uuid').setValidators([Validators.required]);
    this.eventFormGroup.get('start_time_timezone_uuid').updateValueAndValidity();
    this.eventFormGroup.get('end_time_timezone_uuid').setValidators([Validators.required]);
    this.eventFormGroup.get('end_time_timezone_uuid').updateValueAndValidity();
  }

}
