import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { State } from '@app/state';
import { SportsologySuperUserGuard } from '../../../../../../modules/common/core/components/auth/sportsology-super-user.guard';
@Component({
  selector: 'primary-navigation',
  templateUrl: './primary-navigation.component.html',
  styleUrls: ['./primary-navigation.component.scss', './primary-navigation.component-700.scss'],
})
export class PrimaryNavigationComponent implements OnInit, OnDestroy
{
  private activePage = 'schedule';
  private _subscriptions:Array<any> = [];
  private _productsWithChats:Array<string> = [
    // local
    '17e3acd3-2f81-11e9-b4dc-e8b1fc5862f3',
    // sp demo
    'f5df7b57-e3c7-11ea-b79c-f23c91868160',
    // lfc
    'ded562ac-3e7c-42bc-b98e-91d5ab3d7331',
    // Chicago fire
    '5d80c5e3-4089-11eb-8adb-7085c2f98a6d',
    // Wizards
    'eff962b4-1c63-11eb-afe9-7085c2f98a6d',
    // Premier league
    '2e1ca748-06c3-44ee-aec1-77d3723590f1',
    // 49ers
    'b2e9a812-91d3-4773-9461-624891ea70e0',
  ];

  public dataReady = false;
  public isMenuOpen = false;
  public productId: string = null;
  public sections: Array<object> = [];
  public teamLogoMobile = '';
  public teamLogoSmallMobile = '';
  public title = 'Sportsology';

  constructor(
    private router: Router,
    private sportsologySuperUserGuard: SportsologySuperUserGuard,
    private state: State
  ) {}

  ngOnInit(): void {
    this._subscriptions.push(
      this.state.subscription().subscribe(payload => {
        if (payload['setTopNavigationHeader']) {
          this.title = payload['setTopNavigationHeader'];
        }
        if (!! payload.productId && payload.productId !== this.productId) {
          this.productId = payload.productId;
          this.setSections();
        }
      })
    );
    this._subscriptions.push(
      this.router.events.subscribe(routerEvent => {
        if (routerEvent instanceof NavigationEnd) {
          this.activePage = routerEvent.url.replace(/^\/|\/$/g, '');
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  makeid(length): string {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  setSections() {
    this.teamLogoSmallMobile = `/assets/img/team-logos/54px/${this.productId}.png?rand=${this.makeid(10)}`;
    this.teamLogoMobile = `/assets/img/team-logos/256px/${this.productId}.png?rand=${this.makeid(10)}`;

    this.sections = [{
      title: '',
      link: '',
      isTeamLogo: true,
      linkClass: 'no-title nav-link-team-logo hidden-on-small',
      teamLogo: `/assets/img/team-logos/54px/${this.productId}.png?rand=${this.makeid(10)}`,
      stateMessage: { openSettingsSideBar: false },
    }, {
      title: 'Schedule',
      link: 'schedule',
      icon: '/assets/img/material-icons/baseline-today-24px.svg',
      stateMessage: { openSettingsSideBar: false },
    }, {
      title: 'Event listing',
      linkClass: 'hidden-on-large',
      link: 'events',
      icon: '/assets/img/material-icons/baseline-today-24px.svg',
      stateMessage: { openSettingsSideBar: false },
    }, {
      title: 'Messages',
      link: 'messages',
      icon: '/assets/img/material-icons/baseline-chat-24px.svg',
      stateMessage: { openSettingsSideBar: false },
    }];

    if(this._productsWithChats.includes(this.productId)) {
      this.sections.push({
        title: 'Chats',
        link: 'chats',
        icon: '/assets/img/material-icons/ic_forum_48px_white.svg',
        stateMessage: { openSettingsSideBar: false },
      });
    }

    this.sections.push(
    {
      title: 'Documents',
      link: 'folders',
      icon: '/assets/img/material-icons/baseline-folder-24px.svg',
      stateMessage: { openSettingsSideBar: false },
    }, {
      title: 'Articles',
      link: 'articles',
      linkClass: 'hidden-on-small',
      icon: '/assets/img/material-icons/outline-format_align_left-24px.svg',
      stateMessage: { fullWidthOverlayHide: true },
    }, {
      title: 'App Pages',
      link: 'app-pages',
      linkClass: 'hidden-on-small',
      icon: '/assets/img/material-icons/view_quilt-24px.svg',
      stateMessage: { fullWidthOverlayHide: true },
      sportsologySuperAdmin: true
    }, {
      title: 'Bundles',
      link: 'bundles',
      icon: '/assets/img/material-icons/perm_media-white-24px.svg',
      stateMessage: { fullWidthOverlayHide: true },
      sportsologySuperAdmin: true
    }, {
      title: 'Directory',
      link: 'directory-items',
      icon: '/assets/img/material-icons/outline-format_align_left-24px.svg',
      stateMessage: { openSettingsSideBar: false },
      sportsologySuperAdmin: true
    }, {
      title: 'Users',
      link: 'users',
      icon: '/assets/img/material-icons/baseline-supervisor_account-24px.svg',
      stateMessage: { openSettingsSideBar: false },
    }, {
      title: 'Settings',
      link: 'settings',
      icon: '/assets/img/material-icons/outline-settings-24px.svg',
      stateMessage: { openSettingsSideBar: false },
    });

    this.dataReady = true;
  }

  openMenu(): void {
    this.isMenuOpen = true;
  }

  closeMenu(): void {
    this.isMenuOpen = false;
  }

  openLink(link, stateMessage = null): void {
    if (stateMessage !== null) {
      this.state.update(stateMessage);
    }
    this.router.navigateByUrl(link).then(() => this.state.message({ slideoutHide: true }));
    this.closeMenu();
  }

  canViewLink(link) {
    if (link.sportsologySuperAdmin === undefined) { }
    if (link.sportsologySuperAdmin !== undefined && link.sportsologySuperAdmin === true) {
      return this.sportsologySuperUserGuard.userIsSportsologySuperUser();
    }
    return true;
  }
}
