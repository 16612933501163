import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { AppService } from '@services/app.service';
import { SidebarService } from '@services/sidebar.service';
import { APPS_FILTER_FIELDS } from './filter-fields';

@Component({
  selector: 'app-apps-index',
  templateUrl: './apps-index.component.html',
  styleUrls: ['./apps-index.component.scss', './apps-index-700.component.scss'],
  providers: [SidebarService]
})
export class AppsIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['select', 'name', 'published', 'created_at', 'actions'];
  public dataLoaded = false;
  public fields: any = APPS_FILTER_FIELDS;
  constructor(
    public appService: AppService,
    public router: Router,
    public sidebarService: SidebarService,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: appService,
      router,
      sidebarService,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      sidebar: 'apps',
      listenForIndexUpdate: true,
    });
    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public updatedStatus(): void {
    this.state.message({ ['AppV2IndexUpdate']: true });
  }

}
