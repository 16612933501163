import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
@Component({
  selector: 'schedule-event',
  templateUrl: './schedule-event.component.html',
  styleUrls: ['./schedule-event.component.scss']
})
export class ScheduleEventComponent extends BaseComponent implements OnInit {
  @Input() event: any;
  @Input() dates: Array<any>;
  @Input() rowStartDate: string;
  @Input() rowEndDate: string;

  private startRowDate: any;
  private endRowDate: any;
  public width: any;
  public leftOffset: any;

  constructor(
    public router: Router,
    public state: State,
  ) {
    super();
    this.setObjects({
      router: router,
      state: state,
    });
  }

  ngOnInit() {
    this.startRowDate = new Date(this.dates[0]);
    this.startRowDate.setHours(0);
    this.startRowDate.setMinutes(0);
    this.startRowDate.setSeconds(0);
    this.endRowDate = new Date(this.dates[6]);
    this.endRowDate.setHours(23);
    this.endRowDate.setMinutes(59);
    this.endRowDate.setSeconds(59);
    this.event.start_time_date = new Date(this.event.start_time);
    if (!this.startsAfterRowStartDate()) {
      this.event.start_time_date = new Date(this.startRowDate);
    }
    this.event.end_time_date = new Date(this.event.end_time);
    if (this.endsAfterRowEndDate()) {
      this.event.end_time_date = new Date(this.endRowDate);
    }
    this.width = this.widthOfBlock();
    this.leftOffset = this.leftOffsetOfBlock();
  }

  widthOfBlock() {
    return this.eventDuration();
  }

  leftOffsetOfBlock() {
    if (this.startsAfterRowStartDate()) {
      return this.hoursFromStart();
    }
    return 0;
  }

  eventDuration() {
    return Math.abs(this.event.start_time_date.getTime() - this.event.end_time_date.getTime()) / 3600000;
  }

  hoursFromStart() {
    return Math.abs(this.startRowDate.getTime() - this.event.start_time_date.getTime()) / 3600000;
  }

  hoursToEnd() {
    return Math.abs(this.endRowDate.getTime() - this.event.end_time_date.getTime()) / 3600000;
  }

  startsAfterRowStartDate() {
    return Math.abs(this.event.start_time_date.getTime()) > Math.abs(this.startRowDate.getTime());
  }

  endsAfterRowEndDate() {
    return Math.abs(this.event.end_time_date.getTime()) > Math.abs(this.endRowDate.getTime());
  }
}
