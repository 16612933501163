import { Component, Injectable, ViewChild, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { PAGE_FILTER_FIELDS } from './filter-fields';
import { paramCase } from 'change-case';

import { PageService } from '@app/services/page.service';
import { ArticleService } from '@app/services/article.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';

// Editor
import { editorCommands } from './editor-base-commands';
import { initializeEditor } from './editor-base';
import { canDropIntoRow, canDropIntoSection } from './editor/utils';
import { ICON_LAYOUT_ROW, ICON_LAYOUT_SECTION } from './const/icons';
import { COMP_LAYOUT_SECTION } from './const/components';

// Pages
import { Article } from './article/article';
import { AppPage } from './app-page/app-page';

// Form perms actions
import {
  initPerms,
  openItemPermsSelector,
  removeFromPerms,
  updatePerms
} from './page-form-perms-actions';

import { TYPE_APP_PAGE, TYPE_ARTICLE } from './const/form';

// Form actions
import {
  addPageSection,
  addRow,
  addSection,
  afterSaveActions,
  appPageTypeChange,
  getItemSnapshot,
  initSnapshots,
  openFileSelector,
  openIconSelector,
  openImageSelector,
  inlineImageDialog,
  inlineItemDialog,
  inlineUrlDialog,
  openItemSelector,
  openTextEditor,
  removeFromFormGroup,
  removeFromPageSections,
  save,
  showPageSection,
  updateFormGroup,
  updateItemsSnapshot,
  updatePageSections,
} from './page-form-actions';
import { IndexDBService } from '@app/services/index-db.service';

@Component({
  selector: 'app-page-form',
  templateUrl: './page-form.component.html',
  styleUrls: ['./page-form.component.scss']
})
export class PageFormComponent extends BaseComponent implements OnInit
{
  private _editor: any;
  private baseUrl: any;
  private isSecureStorage: boolean = false;
  private indexDb: any;
  private app: any = null;
  private appUUID: string;
  private canDropOnRow: any = null;
  private canDropOnSection: any = null;
  private config = GlobalUtil.Configuration;
  private draggedBlock: any = null;
  private fields = PAGE_FILTER_FIELDS;
  private formSubmitted = false;
  private ICON_LAYOUT_ROW = ICON_LAYOUT_ROW;
  private ICON_LAYOUT_SECTION = ICON_LAYOUT_SECTION;
  private pageFormGroup: FormGroup;
  private pageModel: AppPage | Article;
  private showCreatePageButton = false;
  private showPageSelector = false;
  private snapshots:any = new Map;

  public dataLoaded = false;
  public editorLoaded = false;
  public Page: any;
  public pageSections:any = [];
  public pageType: string;

  // Get editor
  get editor() {
    return this._editor;
  }

  // Get controls
  get controls(): any {
    return this.pageFormGroup.controls;
  }

  constructor(
    private zone: NgZone,
    public activatedRoute: ActivatedRoute,
    public formBuilder: FormBuilder,
    public pageService: PageService,
    public articleService: ArticleService,
    public state: State,
    public dialog: MatDialog,
    private _indexDbService: IndexDBService
    ) {
    super();
    this.pageType = this.activatedRoute.parent.snapshot.data.pageType;
    let modelService = null;
    if (this.pageType === 'article') {
      modelService = articleService;
    }
    if (this.pageType === 'appPage') {
      modelService = pageService;
    }
    this.setObjects({
      route: activatedRoute,
      state: state,
      modelService: modelService,
      listenForIndexUpdate: true
    });

    const settings = this.state.get('ccSettings');
    this.baseUrl = settings.uploadcare.url;
    this.isSecureStorage = settings.uploadcare.secure;
    if(this.isSecureStorage) {
      this.indexDb = _indexDbService.db;
    }
  }

  // Init page
  async initPageModel() {
    if (this.pageType === 'article') {
      this.pageModel = new Article(this);
    }
    if (this.pageType === 'appPage') {
      this.pageModel = new AppPage(this);
    }
    this.pageModel.initialFormGroup();
  }

  // On init
  async ngOnInit() {
    initSnapshots.call(this);
    initPerms.call(this);
    await this.initPageModel();
    this.pageModel.onInit().then((result:any) => {
      this.initializeEditor().then(editor => {
        this._editor = editor;
        editorCommands.call(this);
        if (result !== null) {
          this.editor.setComponents(JSON.parse(result.body));
        }
        this.pageModel.initEditorCommands.call(this);
        this.pageModel.initEditorTraits.call(this);
        this.dataLoaded = true;
      });
    });
  }

  // Close the editor
  private closePageEditor(): void {
    if (this.pageType === TYPE_APP_PAGE) {
      this.state.message({ ['PageIndexUpdate']: true });
    }
    if (this.pageType === TYPE_ARTICLE) {
      this.state.message({ ['ArticleIndexUpdate']: true });
    }
    this.state.message({ fullWidthOverlayHide: true });
  }

  // Init editor
  private initializeEditor(): any {
    return initializeEditor.call(this);
  }

  // After save
  private afterSaveActions(options): void {
    afterSaveActions.call(this, options);
  }

  // Open fiel selector
  private openFileSelector(options): void {
    openFileSelector.call(this, options);
  }

  // Get snapshot
  private getItemSnapshot(options) {
    return getItemSnapshot.call(this, options);
  }

  // Open item selector
  private openIconSelector(options): void {
    openIconSelector.call(this, options);
  }

  // Open image selector
  private openImageSelector(options): void {
    openImageSelector.call(this, options);
  }

  // Open image dialog
  private inlineImageDialog(options = {}): Promise<any> {
    return inlineImageDialog.call(this, options);
  }

  // Open item dialog
  private inlineItemDialog(options): Promise<any> {
    return inlineItemDialog.call(this, options);
  }

  // Open url dialog
  private inlineUrlDialog(options): Promise<any> {
    return inlineUrlDialog.call(this, options);
  }

  // Open item selector
  private openItemSelector(options): void {
    openItemSelector.call(this, options);
  }

  // Open text editor modal
  private openTextEditor(options): void {
    openTextEditor.call(this, options);
  }

  // Open item perms selector
  private openItemPermsSelector(options): void {
    openItemPermsSelector.call(this, options);
  }

  // Remove from form group
  private removeFromFormGroup(options): void {
    removeFromFormGroup.call(this, options);
  }

  // Update form group on item changes
  private updateFormGroup(options): void {
    updateFormGroup.call(this, options);
  }

  // Remove from form group
  private removeFromPerms(options): void {
    removeFromPerms.call(this, options);
  }

  // Update form group on item changes
  private updatePerms(options): void {
    updatePerms.call(this, options);
  }

  // Update snapshots across items
  private updateItemsSnapshot(options): void {
    updateItemsSnapshot.call(this, options);
  }

  // Update page sections
  private updatePageSections(options): void {
    updatePageSections.call(this, options);
  }

  // Remove from page sections
  private removeFromPageSections(options): void {
    removeFromPageSections.call(this, options);
  }

  // Select page section
  private showPageSection(pageSectionId): void {
    showPageSection.call(this, pageSectionId);
  }

  // Add row
  private addRow(): void {
    addRow.call(this);
  }

  // Add section
  private addSection(): void {
    addSection.call(this);
  }

  // Add row with dropped block
  private onDropRow(): void {
    if (canDropIntoRow(this.draggedBlock)) {
      addRow.call(this, this.draggedBlock);
    }
    this.canDropOnRow = null;
  }

  // Drag over dropzone row with dragged block
  private onDragOverRow(): void {
    this.canDropOnRow = canDropIntoRow(this.draggedBlock);
  }

  // Add section with dropped block
  private onDropSection(): void {
    if (this.canDropOnSection) {
      addSection.call(this, this.draggedBlock, canDropIntoRow(this.draggedBlock));
    }
    this.canDropOnSection = null;
  }

  // Drag over dropzone section with dragged block
  private onDragOverSection(): void {
    this.canDropOnSection = this.draggedBlock.attributes.content.type !== COMP_LAYOUT_SECTION;
  }

  // Add page section
  public addPageSection(): void {
    addPageSection.call(this);
  }

  // App page type change
  public appPageTypeChange(): void {
    appPageTypeChange.call(this);
  }

  // Save
  public save(options): void {
    save.call(this, options);
  }

  // Show / hide component items panel
  public showComponentItemsPanel(options): void {
  }
}
