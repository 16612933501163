import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { SnackBarComponent } from '../elements/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  error(data) {
    this.createMessage(data, 'error');
  }

  success(data) {
    this.createMessage(data, 'success');
  }

  smallSuccess(data) {
    this.createMessage(data, ['small', 'success']);
  }

  info(data) {
    this.createMessage(data, 'info');
  }

  warning(data) {
    this.createMessage(data, 'warning');
  }

  openProgress(message: string) {
    return this.snackBar.open(message, '', { panelClass: 'info' });
  }

  createMessage(data, snackBarClass) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: 2500,
      data: data,
      panelClass: snackBarClass
    });
  }
}
