import { Component, Input, OnInit } from '@angular/core';
import { State } from '@app/state';
@Component({
  selector: 'sidebar-filter-list',
  templateUrl: './sidebar-filter-list.component.html',
  styleUrls: ['./sidebar-filter-list.component.scss'],
})
export class SidebarFilterListComponent implements OnInit {

  @Input() title = '';
  @Input() stateKey = '';
  @Input() showAll = true;

  public showAllOptions = false;
  public showAllDisabled = true;
  public hideAllDisabled = false;
  public options: Array<object> = [];

  constructor(private state: State) { }

  ngOnInit() {
    this.state.subscription().subscribe(data => {
      if (data[this.stateKey + 'Options']) {
        this.options = data[this.stateKey + 'Options'];
      }
    });
  }

  changeState(value, element) {
    const checked = (element.target.checked);
    const options = [];
    this.options.forEach(option => {
      if (option['value'] === value) {
        option['checked'] = checked ? 'checked' : null;
      }
      options.push(option);
    });
    this.options = options;
    const values = this.options.filter(x => x['checked'] === 'checked').map(x => x['value']);
    this.showAllDisabled = values.length === this.options.length;
    this.hideAllDisabled = values.length === 0;
    this.state.update({ [this.stateKey + 'Values']: values });
  }

  checkAll() {
    this.options.map((option: any) => option.checked = 'checked');
    this.updateValues();
    this.showAllDisabled = true;
    this.hideAllDisabled = false;
  }

  unCheckAll() {
    this.options.map((option: any) => option.checked = null);
    this.updateValues();
    this.showAllDisabled = false;
    this.hideAllDisabled = true;
  }

  updateValues() {
    const values = this.options.filter(x => x['checked'] === 'checked').map(x => x['value']);
    this.state.update({ [this.stateKey + 'Values']: values });
  }
}
