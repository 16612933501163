import { Component, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileInfo } from '@app/models/entities/fileInfo';

import { displayAsOptions, itemFloatOptions } from './const';

const urlReg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

@Component({
  selector: 'inline-url-dialog',
  templateUrl: 'inline-url-dialog.component.html',
  styleUrls: ['inline-url-dialog.component.scss']
})
export class InlineUrlDialogComponent {
    public displayAsOptions:Array<any> = displayAsOptions;
    public inlineUrlFormGroup: FormGroup;
    public itemFloatOptions:Array<any> = itemFloatOptions;

    get controls() { return this.inlineUrlFormGroup.controls; }

    constructor(
        private dialogRef: MatDialogRef<InlineUrlDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private formBuilder: FormBuilder
    ) {
        const { displayAs, openInAppBrowser, imageUUID, itemFloat, itemText, url } = data;
        var formGroup:any = {
            displayAs: [displayAs],
            openInAppBrowser: [openInAppBrowser, [Validators.required]],
            imageUUID: [imageUUID],
            itemFloat: [itemFloat],
            itemText: [itemText],
            url: [url, [Validators.required, Validators.pattern(urlReg)]]
        };

        this.inlineUrlFormGroup = this.formBuilder.group(formGroup);
    }

    setItemUUID(itemUUID) {
        this.inlineUrlFormGroup.patchValue({ itemUUID: itemUUID });
    }

    setDisplayAs(displayAs) {
        this.inlineUrlFormGroup.patchValue({ displayAs: displayAs });
    }

    setItemFloat(itemFloat) {
        this.inlineUrlFormGroup.patchValue({ itemFloat: itemFloat });
    }

    setImageDetails($fileInfo: FileInfo) {
        if ($fileInfo !== null && !! $fileInfo.uuid) {
            this.inlineUrlFormGroup.patchValue({
                imageUUID: $fileInfo.uuid,
                imageDetails: {
                    'file_family': $fileInfo.fileFamily,
                    'file_name': $fileInfo.filename,
                    'file_size': $fileInfo.filesize + '',
                    'width': $fileInfo.width,
                    'height': $fileInfo.height,
                    'file_type': $fileInfo.mimeType,
                    'file_extension': $fileInfo.filename.split('?')[0].split('.').pop(),
                    'cdn_uuid': $fileInfo.uuid
                }
            });
        }
    }

    saveAndCloseDialog() {
        var values:any = {
            displayAs: this.controls.displayAs.value,
            imageUUID: this.controls.imageUUID.value,
            itemFloat: this.controls.itemFloat.value,
            itemText: this.controls.itemText.value,
            openInAppBrowser: this.controls.openInAppBrowser.value,
            url: this.controls.url.value
        };

        this.dialogRef.close(values);
    }
}
