import {
  // Widget
  COMP_WIDGET_ARTICLE_CATEGORY,
  COMP_WIDGET_BUNDLE,
  COMP_WIDGET_DIRECTORY_CATEGORY,
  COMP_WIDGET_DIRECTORY_ITEM,
  COMP_WIDGET_EMBED,
  COMP_WIDGET_MAP,
  // Layout
  COMP_LAYOUT_ACCORDION,
  COMP_LAYOUT_DIVIDER,
  COMP_LAYOUT_PAGE_SECTION,
  COMP_LAYOUT_PLACEHOLDER,
  COMP_LAYOUT_ROW,
  COMP_LAYOUT_SECTION,
  // Items
  COMP_BUTTON_APP_COMPONENT,
  COMP_BUTTON_APP_PAGE,
  COMP_BUTTON_ARTICLE,
  COMP_BUTTON_ARTICLE_CATEGORY,
  COMP_BUTTON_BUNDLE,
  COMP_BUTTON_CALENDAR,
  COMP_BUTTON_CHAT,
  COMP_BUTTON_CONTACT_GROUP,
  COMP_BUTTON_DIRECTORY_CATEGORY,
  COMP_BUTTON_DIRECTORY_ITEM,
  COMP_BUTTON_DOCUMENT,
  COMP_BUTTON_EVENT,
  COMP_BUTTON_FILE,
  COMP_BUTTON_FOLDER,
  COMP_BUTTON_PERIODISATION_CALENDAR,
  COMP_BUTTON_PLACEHOLDER,
  COMP_BUTTON_PRODUCT_URL,
  COMP_BUTTON_PROFILE_GROUP,
  COMP_BUTTON_URL,
  COMP_BUTTON_USER_CIRCLE,
  // Media
  COMP_MEDIA_IMAGE,
  COMP_MEDIA_IMAGE_SLIDER,
  COMP_MEDIA_INLINE_IMAGE,
  COMP_MEDIA_VIDEO,
  COMP_MEDIA_VIDEO_PLAYLIST,
  // Text
  COMP_TEXT_BLOCK,
  COMP_TEXT_HEADING,
  // Inline
  COMP_TEXT_INLINE_ICON,
  COMP_TEXT_INLINE_ITEM,
  COMP_TEXT_INLINE_URL
} from './../../const/components';

// Base
import { BaseComponent } from './base-component';

// Buttons
import { AppComponent } from './buttons/app-component';
import { AppPage } from './buttons/app-page';
import { Article } from './buttons/article';
import { ArticleCategory } from './buttons/article-category';
import { Bundle } from './buttons/bundle';
import { Calendar } from './buttons/calendar';
import { Chat } from './buttons/chat';
import { ContactGroup } from './buttons/contact-group';
import { DirectoryCategory } from './buttons/directory-category';
import { DirectoryItem } from './buttons/directory-item';
import { Document } from './buttons/document';
import { Event } from './buttons/event';
import { File } from './buttons/file';
import { Folder } from './buttons/folder';
import { InlineIcon } from './buttons/inline-icon';
import { InlineItem } from './buttons/inline-item';
import { InlineUrl } from './buttons/inline-url';
import { PeriodisationCalendar } from './buttons/periodisation-calendar';
import { Placeholder } from './buttons/placeholder';
import { ProductUrl } from './buttons/product-url';
import { ProfileGroup } from './buttons/profile-group';
import { Url } from './buttons/url';
import { UserCircle } from './buttons/user-circle';

// Layout
import { Accordion } from './layout/accordion';
import { Divider } from './layout/divider';
import { Row } from './layout/row';
import { PageSection } from './layout/page-section';
import { Section } from './layout/section';

// Text
import { Heading } from './text/heading';
import { TextBlock } from './text/text-block';

// Widget
import { ArticleCategoryWidget } from './widgets/article-category';
import { BundleWidget } from './widgets/bundle';
// import { DirectoryItemWidget } from './widgets/directory-item';
import { DirectoryCategoryWidget } from './widgets/directory-category';
import { EmbedWidget } from './widgets/embed';
import { MapWidget } from './widgets/map';

// Media
import { Image } from './media/image';
import { ImageSlider } from './media/image-slider';
import { InlineImage } from './media/inline-image';
import { Video } from './media/video';
import { VideoPlaylist } from './media/video-playlist';

export const Components = (editor, config:any = {}) => {
  const DomComponents = editor.DomComponents;

  // Layout
  DomComponents.addType(COMP_LAYOUT_ACCORDION, BaseComponent(Accordion(editor), editor));
  DomComponents.addType(COMP_LAYOUT_DIVIDER, BaseComponent(Divider(editor), editor));
  DomComponents.addType(COMP_LAYOUT_PAGE_SECTION, BaseComponent(PageSection(editor), editor));
  DomComponents.addType(COMP_LAYOUT_PLACEHOLDER, BaseComponent(Placeholder(editor), editor));
  DomComponents.addType(COMP_LAYOUT_ROW, BaseComponent(Row(editor), editor));
  DomComponents.addType(COMP_LAYOUT_SECTION, BaseComponent(Section(editor, config), editor));

  // Text
  DomComponents.addType(COMP_TEXT_BLOCK, BaseComponent(TextBlock(editor), editor));
  DomComponents.addType(COMP_TEXT_HEADING, BaseComponent(Heading(editor), editor));

  // Inline
  DomComponents.addType(COMP_TEXT_INLINE_ICON, BaseComponent(InlineIcon(editor), editor));
  DomComponents.addType(COMP_TEXT_INLINE_ITEM, BaseComponent(InlineItem(editor), editor));
  DomComponents.addType(COMP_TEXT_INLINE_URL, BaseComponent(InlineUrl(editor), editor));

  // Items
  DomComponents.addType(COMP_BUTTON_APP_COMPONENT, BaseComponent(AppComponent(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_APP_PAGE, BaseComponent(AppPage(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_ARTICLE, BaseComponent(Article(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_ARTICLE_CATEGORY, BaseComponent(ArticleCategory(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_BUNDLE, BaseComponent(Bundle(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_CALENDAR, BaseComponent(Calendar(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_CHAT, BaseComponent(Chat(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_CONTACT_GROUP, BaseComponent(ContactGroup(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_DIRECTORY_CATEGORY, BaseComponent(DirectoryCategory(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_DIRECTORY_ITEM, BaseComponent(DirectoryItem(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_DOCUMENT, BaseComponent(Document(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_EVENT, BaseComponent(Event(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_FILE, BaseComponent(File(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_FOLDER, BaseComponent(Folder(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_PERIODISATION_CALENDAR, BaseComponent(PeriodisationCalendar(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_PLACEHOLDER, BaseComponent(Placeholder(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_PRODUCT_URL, BaseComponent(ProductUrl(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_PROFILE_GROUP, BaseComponent(ProfileGroup(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_URL, BaseComponent(Url(editor, config), editor));
  DomComponents.addType(COMP_BUTTON_USER_CIRCLE, BaseComponent(UserCircle(editor, config), editor));

  // Media
  DomComponents.addType(COMP_MEDIA_IMAGE, BaseComponent(Image(editor), editor));
  DomComponents.addType(COMP_MEDIA_IMAGE_SLIDER, BaseComponent(ImageSlider(editor), editor));
  DomComponents.addType(COMP_MEDIA_INLINE_IMAGE, BaseComponent(InlineImage(editor), editor));
  DomComponents.addType(COMP_MEDIA_VIDEO, BaseComponent(Video(editor), editor));
  DomComponents.addType(COMP_MEDIA_VIDEO_PLAYLIST, BaseComponent(VideoPlaylist(editor), editor));

  // Widgets
  DomComponents.addType(COMP_WIDGET_ARTICLE_CATEGORY, BaseComponent(ArticleCategoryWidget(editor, config), editor));
  DomComponents.addType(COMP_WIDGET_BUNDLE, BaseComponent(BundleWidget(editor, config), editor));
  DomComponents.addType(COMP_WIDGET_DIRECTORY_CATEGORY, BaseComponent(DirectoryCategoryWidget(editor, config), editor));
  DomComponents.addType(COMP_WIDGET_EMBED, BaseComponent(EmbedWidget(editor, config), editor));
  DomComponents.addType(COMP_WIDGET_MAP, BaseComponent(MapWidget(editor, config), editor));
}