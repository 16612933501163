export const RowColumnItemSpacing = function(editor, options:any = {}) {
    return {
        noLabel: true,
        templateInput: '',
        name: options.name,
        createInput({ trait }) {
            const el = document.createElement('div');
            let sliderOptionsHtml = [];
            let listOptionsHtml = [];
            let html = `
<h3>Item spacing</h3>
<div class="gjs-trt-trait gjs-trt-trait--radio">
    <div class="gjs-label-wrp">
        <div class="gjs-label" title="Horizontal spacing">Item flow</div>
    </div>
    <div class="gjs-field-wrp gjs-field-wrp--radio">
        <label for="row-as-row">Row</label>
        <input type="radio" id="row-as-row" name="useFlex" value="row">
        <label for="row-as-column">Column</label>
        <input type="radio" id="row-as-column" name="useFlex" value="column">
    </div>
</div>`;
            el.innerHTML = html;
            return el;
        },
        onEvent({ elInput, component, event }) {
            const radioItems = elInput.getElementsByTagName('input');

            for(const radioItem of radioItems) {
                if(radioItem.checked) {
                    component.addAttributes({ displayAs: radioItem.value });
                }
            }
        },
        onUpdate({ elInput, component }) {
            const attrs = component.getAttributes();
            const radioItems = elInput.getElementsByTagName('input');

            for(const radioItem of radioItems) {
                if(radioItem.value === attrs.displayAs) {
                    radioItem.checked = true;
                }
            }
        }
    }
}