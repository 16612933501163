import { Component, Injectable, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FileInfo } from '@app/models/entities/fileInfo';
import { BundleService } from '@app/services/bundle.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
@Component({
  selector: 'app-bundle-form',
  templateUrl: './bundle-form.component.html',
})
export class BundleFormComponent extends BaseComponent {
  dataLoaded = false;
  formSubmitted = false;
  bundleFormGroup: FormGroup;

  articleCols: any;
  directoryItemCols: any;
  pageCols: any;
  subBundleCols: any;

  selectedArticleIds = [];
  selectedPageIds = [];
  selectedDirectoryItemIds = [];
  selectedSubBundleIds = [];

  bundleItemCount = 0;
  private config = GlobalUtil.Configuration;
  @ViewChild('articles', {static: false}) private advancedSelectArticlesInput: { getSelectedIds: () => any[]; };
  @ViewChild('directory_items', {static: false}) private advancedSelectDirectoryItemsInput: { getSelectedIds: () => any[]; };
  @ViewChild('pages', {static: false}) private advancedSelectPagesInput: { getSelectedIds: () => any[]; };
  @ViewChild('sub_bundles', {static: false}) private advancedSelectSubBundlesInput: { getSelectedIds: () => any[]; };
  constructor(
    public activatedRoute: ActivatedRoute,
    public bundleService: BundleService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      state: state,
      modelService: bundleService
    });

    this.modelServiceGetOne(this.routeParams['id'], 'bundle').then(data => {
      this.populateInitialData(data);
      this.dataLoaded = true;
    });


    this.initialFormGroup();
    const id = this.routeParams['id'];

    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result: any) => {
        this.populateInitialData(result);
        this.dataLoaded = true;
        this.bundleItemCount = result.bundle_item_count;
        this.bundleFormGroup.patchValue(result);
        // We don't use this form for editing, so the patchValue below is pointless
        this.bundleFormGroup.patchValue({
          article_categories: result.article_categories,
          directory_categories: result.directory_categories
        });
      });
    }

    // Setup the columns settings for core-form-advanced-select (Articles)
    this.articleCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'title', selectedOnclick: true, header: 'Title', cell: (element: any) => `${element.title}` }
    ];

    // Setup the columns settings for core-form-advanced-select (DirectoryItems)
    this.directoryItemCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` }
    ];

    // Setup the columns settings for core-form-advanced-select (Pages)
    this.pageCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` }
    ];

    // Setup the columns settings for core-form-advanced-select (Pages)
    this.subBundleCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'title', selectedOnclick: true, header: 'Title', cell: (element: any) => `${element.title}` }
    ];
  }

  private populateInitialData(row): void {
    if (row !== undefined) {
      this.selectedIds(row);
    }
  }

  private initialFormGroup(): void {
    this.bundleFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      title: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      teaser: [null],
      description: [null],
      bundle_items: this.formBuilder.group({
        sub_bundles: [null],
        articles: [null],
        article_category_id: [null],
        directory_items: [null],
        directory_category_id: [null],
        pages: [null],
      }),
      featured_image_cdn_uuid: [null],
      featured_image_cdn_url: [null],
      featured_image_width: [null],
      featured_image_height: [null],
      published: [true],
    });
  }

  get controls(): any {
    return this.bundleFormGroup.controls;
  }

  public save(): void {
    const uuid = this.controls.uuid.value;
    this.formSubmitted = true;

    this.bundleFormGroup.patchValue({
      published: !!this.controls.published.value,
      bundle_items: {
        articles: this.getSelectedArticleIds(),
        pages: this.getSelectedPageIds(),
        directory_items: this.getSelectedDirectoryItemIds(),
        sub_bundles: this.getSelectedSubBundleIds(),
      }
    });

    if (uuid) {
      this.bundleService.patchById(uuid, this.bundleFormGroup.value).subscribe(result => this.savedMessage(result));
    } else {
      this.bundleService.create(this.bundleFormGroup.value).subscribe(result => this.savedMessage(result));
    }

    setTimeout (() => {
      this.formSubmitted = false;
    }, 3000);
  }

  private selectedIds(row) {
    if (row.bundle_items.article) {
      row.bundle_items.article.forEach(el => {
        this.selectedArticleIds.push(el.uuid);
      });
    }
  }

  private savedMessage(data) {
    this.state.message({ ['BundleIndexUpdate']: true });
    this.closeSlideout();
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  private getSelectedArticleIds(): any[] {
    const value: any[] = this.advancedSelectArticlesInput.getSelectedIds();
    return value === null ? null : value;
  }

  private getSelectedDirectoryItemIds(): any[] {
    const value: any[] = this.advancedSelectDirectoryItemsInput.getSelectedIds();
    return value === null ? null : value;
  }

  private getSelectedPageIds(): any[] {
    const value: any[] = this.advancedSelectPagesInput.getSelectedIds();
    return value === null ? null : value;
  }

  private getSelectedSubBundleIds(): any[] {
    const value: any[] = this.advancedSelectSubBundlesInput.getSelectedIds();
    return value === null ? null : value;
  }

}
