import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { State } from '@app/state';
import { Environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { Observable, throwError } from 'rxjs';
import { error } from '@angular/compiler/src/util';

/**
 * Route Auth Guard
 */
@Injectable()
export class AuthGuard implements CanActivate {

  private _allowedAuthRouteActions: Array<string> = ['login', 'logout', 'confirmUser', 'loginFromId'];
  private _hasLoggedIn: boolean = false;
  private _localStoreSessionToken: string = null;
  private _productId: string = null;
  private _sessionToken: string = null;

  constructor(
    private authService: AuthService,
    private router: Router,
    private state: State
  ) {}

  /**
   * Allowed routes
   *
   * @param route
   */
  private _allowedAuthRoute(route: ActivatedRouteSnapshot): boolean {
    if ((route.component as any).name === 'AuthComponent') {
      if (!! route.data.action && this._allowedAuthRouteActions.includes(route.data.action)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Allowed chats routes
   *
   * @param route
   */
  private _allowedChatsRoute(route: ActivatedRouteSnapshot): boolean {
    if ((route.component as any).name === 'ChatsComponent') {
      return (
        // local
        this._productId === '17e3acd3-2f81-11e9-b4dc-e8b1fc5862f3' ||
        // sp demo
        this._productId === 'f5df7b57-e3c7-11ea-b79c-f23c91868160' ||
        // lfc
        this._productId === 'ded562ac-3e7c-42bc-b98e-91d5ab3d7331'
      );
    }
    return true;
  }

  /**
   * Test the base url is correct
   *
   * @param  {RouterStateSnapshot} routeState [description]
   * @return {boolean}                        [description]
   */
  private _testBaseUrl(routeState: RouterStateSnapshot): boolean {
    if (
      window.location.hostname.startsWith('sportsology-frontend--stage-') &&
      window.location.hostname.endsWith('.web.app')
    ) {
      return true;
    }
    return Environment.ccUrl === window.location.hostname;
  }

  /**
   * Can activate route
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} routeState
   * @return {Observable<boolean>}
   */
  async canActivate(route: ActivatedRouteSnapshot, routeState: RouterStateSnapshot) {
    // Make sure the domain is the correct one
    if(! this._testBaseUrl(routeState)) {
      this.authService.destroyApp('Invalid Domain');
      // Don't use logout to redirect to spid
      return false;
    }

    this._productId = this.state.get('productId');
    this._sessionToken = this.state.get('sessionToken');
    this._localStoreSessionToken = localStorage.getItem('sessionToken');

    // Check if route needs perms at all
    if (this._allowedAuthRoute(route)) {
      return true;
    }

    // Check if chats route is allowed
    if (! this._allowedChatsRoute(route)) {
      return false;
    }

    // If state product id or session token are missing but token is in local storage the relogin
    if (
      (! this._productId || ! this._sessionToken) &&
      !! this._localStoreSessionToken
    ) {
      this.router.navigate([
        '/login/' + this._localStoreSessionToken + '/' + btoa(routeState.url)
      ], { skipLocationChange: true });
      return true;
    }

    // If state product id or session token are missing to go to spid
    if (! this._sessionToken || ! this._productId) {
      this.authService.destroyApp();
      // Use logout to redirect to spid
      this.authService.logout();
      return false;
    }

    // Check api for route perms and do refresh token
    var isAuth = false;
    await this.authService.checkRoutePermissions(routeState.url)
      .then((res:any) => {
         if (res === true) {
           isAuth = true;
         }
      })
      .catch(async (error) => {
        if (error.status === 403) {
          if(
            !! (error as any).error.refresh_token &&
            (error as any).error.refresh_token === true
          ) {
            await this.authService.refreshUserSession()
              .then(async (refreshTokenResult:any) => {
                this.authService.updateToken(refreshTokenResult.token);
                await this.authService.checkRoutePermissions(routeState.url).then((res:any) => {
                   if (res === true) {
                     isAuth = true;
                   }
                })
              })
          }
          if (
            !! (error as any).error.invalid_ip_address &&
            (error as any).error.invalid_ip_address === true
          ) {
            this.authService.destroyAndLogout();
            throwError(error);
          }
        }
      });

    return isAuth;
  }
}
