import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { State } from '@app/state';
@Component({
  selector: 'information-bar',
  templateUrl: './information-bar.component.html',
  styleUrls: ['./information-bar.component.scss']
})
export class InformationBarComponent implements OnInit {
  @Input() editable = false;
  @Input() noEditButton = false;
  @Input() boxStyleClass = false;
  @HostBinding('class.box-style') addBoxStyleClass = false;

  constructor(private state: State) {
  }

  ngOnInit() {
    this.addBoxStyleClass = this.boxStyleClass;
  }

  // enableEditing() {
  //   this.state.message({informationBarEdit: true})
  // }
}
