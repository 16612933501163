import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'flash-message',
  templateUrl: './flash-message.component.html',
  styleUrls: ['./flash-message.component.scss']
})

export class FlashMessageComponent implements OnInit {
  @Input() displayMessage = 'Sorry, nothing found.';  // default message
  @Input() defaultMessageClass = 'alert'; // default main alert class
  @Input() messageClass = 'alert-info'; // default sub class

  constructor() { }
  // Life cycle hock
  ngOnInit() {
  }

  get cssFullClass(): string {
    return `${this.defaultMessageClass} ${this.messageClass}`;
  }
}
