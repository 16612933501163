// Core and Vendor
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { RouteReuseStrategy, RouterModule } from '@angular/router/';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from '@app/app-routing';
import { AppComponent } from '@app/app.component';
import { Cache } from '@app/cache';
import { CacheRouteReuseStrategy } from '@app/cache-route-reuse.strategy';
import { HttpRequestInterceptor } from '@app/http-request.interceptor';

import { LOCAL_STORAGE } from 'ngx-webstorage-service';
import { LocalStorageService, LOCAL_STORAGE_SERVICE } from './services/local-storage.service';
import { SharedModule } from './modules/common/shared/shared.module';
import { HelperModule } from './modules/common/third-parties/helper/helper.module';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { AngularCdkModule } from './modules/common/third-parties/angular-cdk/angular-cdk.module';
import { AngularModule } from './modules/common/third-parties/angular/angular.module';
import { CoreModule } from './modules/common/core/core.module';
import { SettingsModule } from './modules/settings/settings.module';

// App
import { MaterialModule } from '@app/modules/common/third-parties/material/material.module';
import { State } from '@app/state';
import { JwtModule } from '@auth0/angular-jwt';
import { Environment } from '@environments/environment';
import { WindowRefService } from '@services/window-ref.service';

// Components
import { AuthComponent } from './modules/common/core/components/auth/auth.component';
import { AuthGuard } from './modules/common/core/components/auth/auth.guard';
import { SportsologySuperUserGuard } from './modules/common/core/components/auth/sportsology-super-user.guard';

// Generic elements
import { AvatarUploadComponent } from './elements/avatar-upload/avatar-upload.component';
import { AvatarComponent } from './elements/avatar/avatar.component';
// import { SecureImageComponent } from './elements/secure-image/secure-image.component';
import { DayCalendarComponent } from './elements/day-calendar/day-calendar.component';
import { DayCalendarEventComponent } from './elements/day-calendar/event/day-calendar-event.component';
import { DropDownComponent } from './elements/drop-down/drop-down.component';
import { DropDownItemComponent } from './elements/drop-down-item/drop-down-item.component';

// Form Elements
import { FormComponent } from './elements/form/form.component';
import { FormBaseElementComponent } from './elements/form/form-base-element.component';

// Layout Elements
// import { ListComponent } from '@elements/list/list.component';
import { TabsComponent } from './elements/tabs/tabs.component';
import { TabPanelComponent } from './elements/tabs/panel/tab-panel.component';
import { TabPanelContentComponent } from './elements/tabs/panel/content/tab-panel-content.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { HeaderBarComponent } from './elements/header-bar/header-bar.component';
import { SnackBarComponent } from './elements/snack-bar/snack-bar.component';
import { ReloadBarComponent } from './elements/reload-bar/reload-bar.component';
import { BreadcrumbComponent } from './elements/breadcrumb/breadcrumb.component';
import { FlashMessageComponent } from './elements/flash-message/flash-message.component';

// AccessCodes
import { AccessCodesIndexComponent } from './components/access-codes/index/access-codes-index.component';
import { AccessCodeFormComponent } from './components/access-codes/form/access-code-form.component';
import { AccessCodeViewComponent } from './components/access-codes/view/access-code-view.component';

// Activities
import { ActivitiesIndexComponent } from './components/activities/index/activities-index.component';
import { ActivityViewComponent } from './components/activities/view/activity-view.component';

// Article Categories
import { ArticleCategoryFormComponent } from './components/article-categories/form/article-category-form.component';
import { ArticleCategoriesIndexComponent } from './components/article-categories/index/article-categories-index.component';
// Articles
import { ArticlesIndexComponent } from './components/pages/articles-index/articles-index.component';

// Apps
import { AppsIndexComponent } from './components/apps/index/apps-index.component';
import { AppFormComponent } from './components/apps/form/app-form.component';
import { AppStructureComponent } from './components/apps/structure/app-structure.component';

// Calendars
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ContactGroupFormComponent } from './components/contact-groups/form/contact-group-form.component';

// Chats
import { ChatsComponent } from './components/chats/chats.component';

// DirectoryCategories
import { DirectoryCategoriesIndexComponent } from './components/directory-categories/index/directory-categories-index.component';
import { DirectoryCategoryFormComponent } from './components/directory-categories/form/directory-category-form.component';

// DirectoryItems
import { DirectoryItemsIndexComponent } from './components/directory-items/index/directory-items-index.component';
import { DirectoryItemFormComponent } from './components/directory-items/form/directory-item-form.component';

// ContactGroups
import { ContactGroupsIndexComponent } from './components/contact-groups/index/contact-groups-index.component';
import { ContactGroupSortUsersComponent } from './components/contact-groups/sort-users/contact-group-sort-users.component';
import { ContactGroupSortComponent } from './components/contact-groups/sort/contact-group-sort.component';
import { ContactGroupViewComponent } from './components/contact-groups/view/contact-group-view.component';
import { ContactGroupViewUsersComponent } from './components/contact-groups/view-users/contact-group-view-users.component';

// ContactTypes
import { ContactTypeFormComponent } from './components/contact-types/form/contact-type-form.component';
import { ContactTypesIndexComponent } from './components/contact-types/index/contact-types-index.component';
import { ContactTypeSortComponent } from './components/contact-types/sort/contact-type-sort-sort.component';

// Content Logs
import { ContentLogsIndexComponent } from './components/content-logs/index/content-logs-index.component';

// Contacts
import { SecondaryContactFormComponent } from './components/contacts/form/secondary-contact-form.component';
import { ContactsIndexComponent } from './components/contacts/index/contacts-index.component';
import { LinkContactToUserDialogComponent } from './components/contacts/index/link-contact-to-user-dialog.component';

// ContractTypes
import { ContractTypeFormComponent } from './components/contract-types/form/contract-type-form.component';
import { ContractTypesIndexComponent } from './components/contract-types/index/contract-types-index.component';

// Countries
import { CountriesIndexComponent } from './components/countries/index/countries-index.component';
import { CountryFormComponent } from './components/countries/form/country-form.component';

// Documents
import { DocumentFormComponent } from './components/documents/form/document-form.component';
import { DocumentsIndexComponent } from './components/documents/index/documents-index.component';
import { DocumentUploadComponent } from './components/documents/upload/document-upload.component';
import { DocumentViewComponent } from './components/documents/view/document-view.component';
import { DocumentViewUsersComponent } from './components/documents/view-users/document-view-users.component';
import { DocumentBreadcrumbComponent } from './elements/document-breadcrumb/document-breadcrumb.component';
import { DocumentThumbnailViewComponent } from './components/folders/view/thumbnail-view/document/document-thumbnail-view.component';
import { DocumentListViewComponent } from './components/folders/view/list-view/document/document-list-view.component';
import { SingleDocumentMoveComponent } from './components/folders/view/single-document-move/single-document-move.component';
import { ViewFileDialogComponent } from './components/view-file-dialog/view-file-dialog.component';

// EventCategories
import { EventCategoryFormComponent } from './components/event-categories/form/event-category-form.component';
import { EventCategoriesIndexComponent } from './components/event-categories/index/event-categories-index.component';

// Events
import { EventsIndexComponent } from './components/events/index/events-index.component';
import { EventViewComponent } from './components/events/view/event-view.component';
import { EventFormComponent } from './components/events/form/event-form.component';
import { ScheduleEventDuplicateComponent } from './components/events/duplicate/event-duplicate.component';
import { EventBulkEditComponent } from './components/events/bulk-edit/event-bulk-edit.component';

// Exports
import { ExportsIndexComponent } from './components/exports/index/exports-index.component';
import { ExportFormComponent } from './components/exports/form/export-form.component';

// Folders
import { FoldersIndexComponent } from './components/folders/index/folders-index.component';
import { FolderViewComponent } from './components/folders/view/folder-view.component';
import { FolderViewUsersComponent } from './components/folders/view-users/folder-view-users.component';
import { FolderFormComponent } from './components/folders/form/folder-form.component';
import { FolderMoveComponent } from './components/folders/move/folder-move.component';
import { FolderThumbnailViewComponent } from './components/folders/view/thumbnail-view/folder/folder-thumbnail-view.component';
import { FolderListViewComponent } from './components/folders/view/list-view/folder/folder-list-view.component';

// Groups
import { GroupsIndexComponent } from './components/groups/index/groups-index.component';
import { GroupFormComponent } from './components/groups/form/group-form.component';
import { GroupSortComponent } from './components/groups/sort/group-sort.component';
import { GroupViewUsersComponent } from './components/groups/view-users/group-view-users.component';

// Locations
import { LocationFormComponent } from './components/locations/form/location-form.component';
import { LocationsIndexComponent } from './components/locations/index/locations-index.component';

// Maintenance
import { MaintenanceIndexComponent } from './components/maintenance/index/maintenance-index.component';

// Master Settings
import { MasterSettingFormComponent } from './components/settings/master-form/master-setting-form.component';
import { MasterSettingsIndexComponent } from './components/settings/master-index/master-settings-index.component';
import { ClientSettingFormComponent } from './components/settings/client-form/client-setting-form.component';
import { ClientSettingsIndexComponent } from './components/settings/client-index/client-settings-index.component';

// Messages
import { MessageFormComponent } from './components/messages/form/message-form.component';
import { MessagesIndexComponent } from './components/messages/index/messages-index.component';
import { MessageViewComponent } from './components/messages/view/message-view.component';
import { MessageViewUsersComponent } from './components/messages/view-users/message-view-users.component';
import { MessageSortArticlesComponent } from './components/messages/sort-articles/message-sort-articles.component';
import { MessageSortDocumentsComponent } from './components/messages/sort-documents/message-sort-documents.component';

// Notes
import { NotesIndexComponent } from './components/notes/index/notes-index.component';
import { NoteFormComponent } from './components/notes/form/note-form.component';
import { NoteCategoriesIndexComponent } from './components/note-categories/index/note-categories-index.component';
import { NoteCategoryFormComponent } from './components/note-categories/form/note-category-form.component';

// Notifications
import { NotificationsIndexComponent } from './components/notifications/index/notifications-index.component';
import { NotificationViewComponent } from './components/notifications/view/notification-view.component';

// Not Found (404 page)
import { NotFoundComponent } from './components/not-found/not-found.component';

// Pages
import { PagesIndexComponent } from './components/pages/pages-index/pages-index.component';
import { PageFormComponent } from './components/pages/form/page-form.component';
import { PageFormAppPageFormComponent } from './components/pages/form/app-page/app-page-form.component';
import { PageFormArticleFormComponent } from './components/pages/form/article/article-form.component';
import { FileSelectorDialogComponent } from './components/pages/form/dialogs/file-selector-dialog.component';
import { IconSelectorDialogComponent } from './components/pages/form/dialogs/icon-selector-dialog.component';
import { ImageSelectorDialogComponent } from './components/pages/form/dialogs/image-selector-dialog.component';
import { ItemPermsSelectorDialogComponent } from './components/pages/form/dialogs/item-perms-selector-dialog.component';
import { ItemSelectorDialogComponent } from './components/pages/form/dialogs/item-selector-dialog.component';
import { InlineUrlDialogComponent } from './components/pages/form/dialogs/inline-url-dialog.component';
import { TextEditorDialogComponent } from './components/pages/form/dialogs/text-editor-dialog.component';

// Permissions
import { PermissionFormComponent } from './components/permissions/form/permission-form.component';
import { PermissionsIndexComponent } from './components/permissions/index/permissions-index.component';

// Roles
import { RoleFormComponent } from './components/roles/form/role-form.component';
import { RolesIndexComponent } from './components/roles/index/roles-index.component';
import { RoleSortComponent } from './components/roles/sort/role-sort.component';

// Schedule (full calendar pages)
import { ScheduleComponent } from './components/schedule/schedule.component';
import { ScheduleDayComponent } from './components/schedule/day/schedule-day.component';
import { ScheduleEventComponent } from './components/schedule/event/schedule-event.component';
import { ScheduleRowComponent } from './components/schedule/row/schedule-row.component';

// Tags
import { TagFormComponent } from './components/tags/form/tag-form.component';
import { TagsIndexComponent } from './components/tags/index/tags-index.component';

// UserCircles
import { UserCircleFormComponent } from './components/user-circles/form/user-circle-form.component';
import { UserCircleViewComponent } from './components/user-circles/view/user-circle-view.component';
import { UserCirclesIndexComponent } from './components/user-circles/index/user-circles-index.component';

// UserPositions
import { UserPositionFormComponent } from './components/user-positions/form/user-position-form.component';
import { UserPositionsIndexComponent } from './components/user-positions/index/user-positions-index.component';
import { UserPositionSortComponent } from './components/user-positions/sort/user-position-sort.component';

// Users
import { UsersIndexComponent } from './components/users/index/users-index.component';

// User Profile components (Tabs when viewing a User)
import { UserEditTabsComponent } from './components/users/edit/tabs/user-edit-tabs.component';
import { UserAssociatedContactsComponent } from './components/users/associated-contacts/user-associated-contacts.component';
import { UserFormComponent } from './components/users/form/user-form.component';
import { UserSpIdFormComponent } from './components/users/spid/user-spid-form.component';
import { UserCalendarEditComponent } from './components/users/calendars/edit/user-calendar-edit.component';
import { UserProfileFormComponent } from './components/users/edit/user-profile-form.component';
import { UserFormEmploymentInformationComponent } from './components/users/employment-information/user-form-employment-information.component';
import { UserFormLegalDocumentComponent } from './components/users/legal-documents/user-form-legal-document.component';
import { UserPermissionEditComponent } from './components/users/permissions/edit/user-permission-edit.component';
import { UserFormCustomFieldsComponent } from './components/users/custom-fields/user-form-custom-fields.component';
import { UserFormAdminSettingsComponent } from './components/users/admin-settings/user-admin-settings.component';
import { UserContentLogIndexComponent } from './components/users/content-logs/index/user-content-log-index.component';
import { UserNotificationsIndexComponent } from './components/users/notifications/index/user-notifications-index.component';
import { UserMessageFormComponent } from './components/users/messages/form/user-message-form.component';
import { UserEventFormComponent } from './components/users/events/form/user-event-form.component';
import { UserScheduleComponent } from './components/users/schedule/user-schedule.component';

// Next four components aren't used any more
import { UserMessagesIndexComponent } from './components/users/messages/index/user-messages-index.component';
import { UserNotesIndexComponent } from './components/users/notes/index/user-notes-index.component';
import { UserSecondaryContactsIndexComponent } from './components/users/secondary-contacts/index/user-secondary-contacts-index.component';
import { UserDocumentsIndexComponent } from './components/users/documents/index/user-documents-index.component';

// Calendars
import { CalendarsIndexComponent } from './components/calendars/index/calendars-index.component';
import { CalendarFormComponent } from './components/calendars/form/calendar-form.component';
import { CalendarSortComponent } from './components/calendars/sort/calendars-sort.component';
import { CalendarViewSubscribersComponent } from './components/calendars/view-subscribers/calendar-view-subscribers.component';

import { SettingsIndexComponent } from './components/settings/index/settings-index.component';
import { TimezonesIndexComponent } from './components/timezones/index/timezones-index.component';
import { MessageResponseIndexComponent } from './components/message-responses/index/message-response-index.component';

import { BundlesIndexComponent } from './components/bundles/index/bundles-index.component';
import { BundleFormComponent } from './components/bundles/form/bundle-form.component';
import { BundleBuilderComponent } from './components/bundles/builder/bundle-builder.component';
import { EditBundleItemDialogComponent } from './components/bundles/builder/edit-bundle-item-dialog.component';
import { BundleViewComponent } from './components/bundles/view/bundle-view.component';

import { BundleItemsIndexComponent } from './components/bundle-items/index/bundle-items-index.component';
import { BundleItemSortComponent } from './components/bundle-items/sort/bundle-item-sort.component';
import { BundleItemCreateComponent } from './components/bundle-items/create/bundle-item-create.component';
import { BundleItemFormComponent } from './components/bundle-items/form/bundle-item-form.component';

// Elements
import { StartFinishComponent } from './elements/event/start-finish/start-finish.component';
import { AddressComponent } from './elements/location/address/address.component';
import { HtmlComponent } from './elements/content/html/html.component';
import { LocationCardComponent } from './elements/location/location-card/location-card.component';
import { MapComponent } from './elements/location/map/map.component';
import { UserListComponent } from './elements/user/user-list/user-list.component';
import { RelatedContentComponent } from './elements/related-content/related-content.component';

// Custom Field Groups
import { CustomFieldGroupIndexComponent } from './components/custom-field-groups/index/custom-field-group-index.component';
import { CustomFieldGroupFormComponent } from './components/custom-field-groups/form/custom-field-group-form.component';
import { CustomFieldGroupViewComponent } from './components/custom-field-groups/view/custom-field-group-view.component';
import { CustomFieldGroupSortComponent } from './components/custom-field-groups/sort/custom-field-group-sort.component';
import { CustomFieldGroupSortFieldsComponent } from './components/custom-field-groups/sort-fields/custom-field-group-sort-fields.component';

import { CustomFieldFormComponent } from './components/custom-fields/form/custom-field-form.component';
import { MappingFieldFormComponent } from './components/custom-fields/mapping/mapping-field-form.component';

import { VantagePointsFormComponent } from './components/vantage-points/vantage-points-form.component';
import { AngularFirestore } from '@angular/fire/firestore';

// chats
import { ENV_CONFIG } from './env';
import {FirebaseModule} from './firebase.module';
import {CreateNewchatDialogueComponent} from './components/chats/chats.component';
import { AddChatUsersComponent } from './components/chats/add-chat-users/add-chat-users.component';
import { AddChatUserCirclesComponent } from './components/chats/add-chat-user-circles/add-chat-user-circles.component';
import { EditGroupDetailsComponent } from './components/chats/edit-group-details/edit-group-details.component';
import { EditAdminsComponent } from './components/chats/edit-admins/edit-admins.component';
export function tokenGetter() {
  return localStorage.getItem('sessionToken');
}

const JWTForRoot = JwtModule.forRoot({
  config: {
    authScheme: '',
    headerName: 'Jwt',
    // throwNoTokenError: true,
    tokenGetter: tokenGetter,
    whitelistedDomains: [Environment.apiBase]
  }
});

const setup: any = {
  declarations: [
    AccessCodeFormComponent,
    AccessCodesIndexComponent,
    AccessCodeViewComponent,
    ActivitiesIndexComponent,
    ActivityViewComponent,
    AddressComponent,
    AddChatUsersComponent,
    AddChatUserCirclesComponent,
    AppComponent,
    AppFormComponent,
    AppsIndexComponent,
    AppStructureComponent,
    ArticleCategoriesIndexComponent,
    ArticleCategoryFormComponent,
    ArticlesIndexComponent,
    AuthComponent,
    AvatarComponent,
    AvatarUploadComponent,
    BreadcrumbComponent,
    BundleBuilderComponent,
    BundleFormComponent,
    BundleItemCreateComponent,
    BundleItemFormComponent,
    BundleItemsIndexComponent,
    BundleItemSortComponent,
    BundlesIndexComponent,
    BundleViewComponent,
    CalendarFormComponent,
    CalendarsIndexComponent,
    CalendarSortComponent,
    CalendarViewSubscribersComponent,
    ChatsComponent,
    CreateNewchatDialogueComponent,
    ClientSettingFormComponent,
    ClientSettingsIndexComponent,
    ConfirmationDialogComponent,
    ContactGroupFormComponent,
    ContactGroupsIndexComponent,
    ContactGroupSortComponent,
    ContactGroupSortUsersComponent,
    ContactGroupViewComponent,
    ContactGroupViewUsersComponent,
    ContactsIndexComponent,
    ContactTypeFormComponent,
    ContactTypesIndexComponent,
    ContactTypeSortComponent,
    ContentLogsIndexComponent,
    ContractTypeFormComponent,
    ContractTypeFormComponent,
    ContractTypesIndexComponent,
    CountriesIndexComponent,
    CountryFormComponent,
    CustomFieldFormComponent,
    CustomFieldGroupFormComponent,
    CustomFieldGroupIndexComponent,
    CustomFieldGroupSortComponent,
    CustomFieldGroupSortFieldsComponent,
    CustomFieldGroupViewComponent,
    DayCalendarComponent,
    DayCalendarEventComponent,
    DirectoryCategoriesIndexComponent,
    DirectoryCategoryFormComponent,
    DirectoryItemFormComponent,
    DirectoryItemsIndexComponent,
    DocumentBreadcrumbComponent,
    DocumentFormComponent,
    DocumentListViewComponent,
    DocumentsIndexComponent,
    DocumentThumbnailViewComponent,
    DocumentUploadComponent,
    DocumentViewComponent,
    DocumentViewUsersComponent,
    DropDownComponent,
    DropDownItemComponent,
    EditBundleItemDialogComponent,
    EditAdminsComponent,
    EditGroupDetailsComponent,
    EventBulkEditComponent,
    EventCategoriesIndexComponent,
    EventCategoryFormComponent,
    EventFormComponent,
    EventsIndexComponent,
    EventViewComponent,
    ExportFormComponent,
    ExportsIndexComponent,
    FileSelectorDialogComponent,
    FlashMessageComponent,
    FolderFormComponent,
    FolderListViewComponent,
    FolderMoveComponent,
    FoldersIndexComponent,
    FolderThumbnailViewComponent,
    FolderViewComponent,
    FolderViewUsersComponent,
    FormBaseElementComponent,
    FormComponent,
    GroupFormComponent,
    GroupsIndexComponent,
    GroupSortComponent,
    GroupViewUsersComponent,
    HeaderBarComponent,
    HtmlComponent,
    IconSelectorDialogComponent,
    ImageSelectorDialogComponent,
    InlineUrlDialogComponent,
    ItemPermsSelectorDialogComponent,
    ItemSelectorDialogComponent,
    LinkContactToUserDialogComponent,
    LocationCardComponent,
    LocationFormComponent,
    LocationsIndexComponent,
    MaintenanceIndexComponent,
    MapComponent,
    MappingFieldFormComponent,
    MasterSettingFormComponent,
    MasterSettingsIndexComponent,
    MessageFormComponent,
    MessageResponseIndexComponent,
    MessagesIndexComponent,
    MessageSortArticlesComponent,
    MessageSortDocumentsComponent,
    MessageViewComponent,
    MessageViewUsersComponent,
    NoteCategoriesIndexComponent,
    NoteCategoryFormComponent,
    NoteFormComponent,
    NotesIndexComponent,
    NotFoundComponent,
    NotificationsIndexComponent,
    NotificationViewComponent,
    PageFormAppPageFormComponent,
    PageFormArticleFormComponent,
    PageFormComponent,
    PagesIndexComponent,
    PermissionFormComponent,
    PermissionsIndexComponent,
    RelatedContentComponent,
    ReloadBarComponent,
    RoleFormComponent,
    RolesIndexComponent,
    RoleSortComponent,
    ScheduleComponent,
    ScheduleDayComponent,
    ScheduleEventComponent,
    ScheduleEventDuplicateComponent,
    ScheduleRowComponent,
    SecondaryContactFormComponent,
    // SecureImageComponent,
    SettingsIndexComponent,
    SingleDocumentMoveComponent,
    SnackBarComponent,
    StartFinishComponent,
    TabPanelComponent,
    TabPanelContentComponent,
    TabsComponent,
    TagFormComponent,
    TagsIndexComponent,
    TextEditorDialogComponent,
    TimezonesIndexComponent,
    UserCalendarEditComponent,
    UserContentLogIndexComponent,
    UserCircleFormComponent,
    UserCirclesIndexComponent,
    UserCircleViewComponent,
    UserDocumentsIndexComponent,
    UserEditTabsComponent,
    UserEventFormComponent,
    UserFormAdminSettingsComponent,
    UserAssociatedContactsComponent,
    UserFormComponent,
    UserFormCustomFieldsComponent,
    UserFormEmploymentInformationComponent,
    UserFormLegalDocumentComponent,
    UserListComponent,
    UserMessageFormComponent,
    UserMessagesIndexComponent,
    UserNotesIndexComponent,
    UserNotificationsIndexComponent,
    UserPermissionEditComponent,
    UserPositionFormComponent,
    UserPositionsIndexComponent,
    UserPositionSortComponent,
    UserProfileFormComponent,
    UserSecondaryContactsIndexComponent,
    UserScheduleComponent,
    UsersIndexComponent,
    UserSpIdFormComponent,
    VantagePointsFormComponent,
    ViewFileDialogComponent
  ],
  entryComponents: [
    SnackBarComponent,
    ConfirmationDialogComponent,
    CreateNewchatDialogueComponent,
    AddChatUsersComponent,
    AddChatUserCirclesComponent,
    EditGroupDetailsComponent,
    EditBundleItemDialogComponent,
    EditAdminsComponent,
    IconSelectorDialogComponent,
    FileSelectorDialogComponent,
    ImageSelectorDialogComponent,
    InlineUrlDialogComponent,
    ItemSelectorDialogComponent,
    ItemPermsSelectorDialogComponent,
    LinkContactToUserDialogComponent,
    TextEditorDialogComponent,
    ViewFileDialogComponent
  ],
  imports: [
    HttpClientModule,
    JWTForRoot,
    FullCalendarModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: Environment.production }),
    AngularFireModule.initializeApp(Environment.firebaseConfig.MAIN),
    AngularCdkModule,
    AngularFireModule.initializeApp(Environment.firebaseConfig.CHAT),
    AngularModule,
    MaterialModule,
    SharedModule,
    HelperModule,
    CoreModule,
    FirebaseModule,
    SettingsModule,
    AppRoutingModule,
  ],
  providers: [
    AuthGuard,
    SportsologySuperUserGuard,
    Cache,
    {provide: ENV_CONFIG, useValue: Environment},
    AngularFirestore,
    State,
    WindowRefService,
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    },
    {
      provide: OWL_DATE_TIME_LOCALE,
      useValue: 'en-gb'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    {
      provide: LOCAL_STORAGE_SERVICE,
      useExisting: LOCAL_STORAGE
    },
    LocalStorageService,
  ],
  bootstrap: [AppComponent]
};

@NgModule(setup)
export class AppModule {
  constructor() {
  }
}
