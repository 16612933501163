import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Environment } from '@environments/environment';
import { BaseService } from './base.service';
import { Cache } from '@app/cache';

@Injectable({
  providedIn: 'root'
})
export class ContactGroupUserService extends BaseService {

  private contactGroupUserCache = [];

  constructor(
    public http: HttpClient,
    public cache: Cache
  ) {
    super(http, cache);
    this.setVars({
      endPoint: 'contact-group/{id}/users',
      customPagination: true, // Prevent base.component from initialising pagination
      modelTitle: 'ContactGroupUser'
    });
  }

}
