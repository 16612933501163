import { Component, Input, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Environment } from './../../../environments/environment';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit {
  @Input() filterKey = '';
  @Input() showUserMenu = true;
  moduleTitle = 'Control Center';
  stage = Environment.stage;
  pageTitle = 'Sportsology';
  private routeData;
  constructor(private router: Router) {}
  ngOnInit() {
    this.router.events.subscribe(data => {
      if (data instanceof RoutesRecognized) {
        this.routeData = data.state.root.firstChild.data;
        this.pageTitle = (this.routeData.pageTitle);
      }
    });
  }
}
