// All the third parties helper related modules should be decleared here

import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';

const ANGULAR_CDK_MODULES = [
  DragDropModule,
  CdkTableModule,
];

@NgModule({
  imports: [
    ...ANGULAR_CDK_MODULES
  ],
  exports: [
   ...ANGULAR_CDK_MODULES
  ]
})

export class AngularCdkModule { }
