import { Validator, AbstractControl, ValidationErrors } from '@angular/forms';

export class NumberOnlyValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const val = control.value;
    if (val === undefined) {
      return null;
    }
    if (isNaN(val) || !val.match(/^[+-]?\d+\.?\d*$/)) {
      return { numberonly: true };
    }
  }
}
