import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  Subject,
  Subscription
} from 'rxjs';
import {
  debounceTime
} from 'rxjs/operators';

@Directive({
  selector: '[debounceClick]'
})

export class ButtonDebounceClickDirective implements OnInit, OnDestroy {
  @Input() interval = 2000; // Default interval time is 2 seconds.
  @Output() debounceClick = new EventEmitter();
  private clicks = new Subject();
  private subscription: Subscription;

  constructor() {}

  ngOnInit(): void {
    this.subscription = this.clicks
      .pipe(debounceTime(this.interval))
      .subscribe(e => {
        this.debounceClick.emit(e);
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
