import { paramCase } from 'change-case';

import {
    COMP_LAYOUT_ROW_DROPPABLE,
    COMP_LAYOUT_SECTION_DROPPABLE
} from './../../const/components';

import {
    CMD_UPDATE_PAGE_FORM_ITEM_PERMS,
    CMD_UPDATE_PAGE_FORM_ITEMS
} from './../../const/commands';

// Create uuid
export const uuidv4 = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const modelSetClass = function(modelClass: string): void {
    const attrs = this.model.getAttributes();
    if (attrs.permsEnabled) {
        modelClass += ' has-perms';
    }
    this.model.setClass(modelClass);
}

export const fixPerms = function() {

}

// Loading spinner
export const loadingSpinner = () => `
<div class="loading-spinner">
    <img src="assets/img/material-icons/spinner.svg" />
</div>`;

// Icon
export const iconElement = (icon, outlined = false) => `<i class="material-icons">${icon}</i>`;

// Open panel
export const openPanel = function(editor, panel) {
    if (panel === 'traits') {
        panel = 'open-tm';
    }
    const btn = editor.Panels.getButton('views', panel);
    setTimeout(() => btn.set('active', true), 0);
}

// Open traits panel
export const openTraitsPanel = editor => openPanel(editor, 'traits');

// Parse uri
export const parseUri = uri => {
    var el = document.createElement('a');
    el.href = uri;
    var query = {};
    var qrs = el.search.substring(1).split('&');
    for (var i = 0; i < qrs.length; i++) {
        var pair = qrs[i].split('=');
        var name = decodeURIComponent(pair[0]);
        if (name) query[name] = decodeURIComponent(pair[1]);
    }
    return {
        hostname: el.hostname,
        pathname: el.pathname,
        protocol: el.protocol,
        search: el.search,
        hash: el.hash,
        port: el.port,
        query
    };
}

// Truncate string
export const truncateString = (str, num) => {
    if (str.length <= num) {
        return str
    }
    return str.slice(0, num) + '...'
}

// Can drop into row
export const canDropIntoRow = block => {
    if (block.attributes.content.type === 'text') {
        return true;
    }
    const types = COMP_LAYOUT_ROW_DROPPABLE.split(',');
    return types.indexOf(paramCase(block.attributes.content.type)) !== -1;
}

// Can drop into section
export const canDropIntoSection = block => {
    const types = COMP_LAYOUT_SECTION_DROPPABLE.split(',');
    return types.indexOf(paramCase(block.attributes.content.type)) !== -1;
}

// Handle updates to model perms
export const updatePageFormItemPerms = function(editor, isLinkedItem = false) {
    editor.Commands.run(CMD_UPDATE_PAGE_FORM_ITEM_PERMS, {
        model: this.model, isLinkedItem: isLinkedItem
    });
}

// Update form buttons when button changes in editor
export const updatePageFormItems = function(editor) {
    editor.Commands.run(CMD_UPDATE_PAGE_FORM_ITEMS, { model: this.model });
}

// Get image from secure storage, cache and inject into el
export const cacheInjectImageDataUrl = (url, image, el, indexDb) => {

    // image.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
    if(url.substr(-1) === '/') {
        url = url.substr(0, url.length - 1);
    }

    var reader = new FileReader();
    var transaction = indexDb.transaction(['images'], 'readonly');
    var store = transaction.objectStore('images');
    const findFileReq = store.openCursor(url);

    findFileReq.onsuccess = (e) => {
        const cursor = e.target.result;
        if(cursor) {
            store.get(url).onsuccess = (e) => {
                const data = e.target.result;
                reader.readAsDataURL(data.bl);
                reader.onloadend = () => {
                    image.src = reader.result;
                    if(el !== null) {
                        el.appendChild(image);
                    }
                }
            }
        } else {
            fetch(url, { headers: {
                'Content-Type': 'application/json',
                'jwt': localStorage.getItem('sessionToken')
            }})
            .then(res => Promise.resolve(res))
            .then(res => res.body)
            .then(body => readableStream(body))
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                transaction = indexDb.transaction(['images'], 'readwrite');
                store = transaction.objectStore('images');
                const tID = Date.now();
                const obj = { bl:blob, created:tID };
                var request = store.add(obj, url);
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    image.src = reader.result;
                    if(el !== null) {
                        el.appendChild(image);
                    }
                }
            });
        }
    }
}

// Get secure url from files
export const getSecureUrl = (url) => {
    return new Promise(resolve => {
        if(url.substr(-1) === '/') {
            url = url.substr(0, url.length - 1);
        }
        fetch(url, { headers: {
            'Content-Type': 'application/json',
            'jwt': localStorage.getItem('sessionToken')
        }})
        .then(res => res.json())
        .then(data => resolve(data.url));
    });
}

const readableStream = (body:any) => {
    const streamReader = body.getReader();
    return new ReadableStream({
        start(controller) {
            return pump();
            function pump() {
                return streamReader.read().then(({ done, value }) => {
                    // When no more data needs to be consumed, close the stream
                    if (done) {
                        controller.close();
                        return;
                    }
                    // Enqueue the next data chunk into our target stream
                    controller.enqueue(value);
                    return pump();
                });
            }
        }
    });
}

// Get image from secure storage, cache and return as data url
export const cacheInjectImageUrl = (url, indexDb) => {

    if(url.substr(-1) === '/') {
        url = url.substr(0, url.length - 1);
    }

    return new Promise(resolve => {
        var reader = new FileReader();
        var transaction = indexDb.transaction(['images'], 'readonly');
        var store = transaction.objectStore('images');
        const findFileReq = store.openCursor(url);

        findFileReq.onsuccess = (e) => {
            const cursor = e.target.result;
            if(cursor) {
                store.get(url).onsuccess = (e) => {
                    const data = e.target.result;
                    reader.readAsDataURL(data.bl);
                    reader.onloadend = () => {
                        resolve(reader.result);
                    }
                }
            } else {
                fetch(url, { headers: {
                    'Content-Type': 'application/json',
                    'jwt': localStorage.getItem('sessionToken')
                }})
                .then(res => Promise.resolve(res))
                .then(res => res.body)
                .then(body => readableStream(body))
                .then(stream => new Response(stream))
                .then(response => response.blob())
                .then(blob => {
                    transaction = indexDb.transaction(['images'], 'readwrite');
                    store = transaction.objectStore('images');
                    const tID = Date.now();
                    const obj = { bl:blob, created:tID };
                    var request = store.add(obj, url);
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        resolve(reader.result);
                    }
                });
            }
        }
    });
}

/**
 * Bug fixes
 */


// Fixes a bug where the toolbar position doesn't update when a component class update changes the
// height of the block in the frame
export const fixToolbarPositinoOnComponentHeightChange = function(editor) {
    const model = editor.getSelected();
    editor.selectRemove(model);
    editor.select(model);
    // editor.trigger('change:canvasOffset');
}

export const syncInlineContent = function(editor) {
    setTimeout(() => editor.getSelected().trigger('sync:content'), 0);
}

// Fixes rte converting all attrs to lowercase
// TODO convert all attrs to lower case
export const fixInlineAttrs = attrs => {
    if (!! attrs.item_uuid) {
        attrs.itemUUID = attrs.item_uuid;
    }
    if (!! attrs.image_uuid) {
        attrs.imageUUID = attrs.image_uuid;
    }
    if (!! attrs.image_details) {
        attrs.imageDetails = attrs.image_details;
    }
    if (!! attrs.float_item) {
        attrs.floatItem = attrs.float_item;
    }
    if (!! attrs.display_as) {
        attrs.displayAs = attrs.display_as;
    }
    if (!! attrs.inline_item_type) {
        attrs.inlineItemType = attrs.inline_item_type;
    }
    return attrs;
}