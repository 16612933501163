import { ActivatedRoute, Router } from '@angular/router';
import { Component, Injectable, OnInit } from '@angular/core';
import { BaseComponent } from '@components/base.component';
import { MessageService } from '@services/message.service';
import { SidebarService } from '@services/sidebar.service';
import { State } from '@app/state';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';

@Component({
  selector: 'app-message-view',
  templateUrl: './message-view.component.html',
  styleUrls: ['../../messages/view/message-view.component.scss'],
  providers: [SidebarService],
})

@Injectable()
export class MessageViewComponent extends BaseComponent implements OnInit {

  public message: {
    title: string,
    created_by_user: any,
    created_at: string

  };
  public dataLoaded = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public messageService: MessageService,
    public sidebarService: SidebarService,
    public router: Router,
    public state: State,
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: messageService,
      router: router
    });
    this.setPageVars({
      sidebar: 'messages',
    });
    this.modelServiceGetOne(this.routeParams['id'], 'message').then(() => {
      this.dataLoaded = true;
    });
  }

  public booleanToText(val): string {
    return val === true ? 'Yes' : 'No';
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.nodes.push(
      {
        label: 'Messages',
        link: 'messages',
        title: 'Messages'
      }, {
      label: 'view message',
      link: 'view message',
      title: 'view message'
    });
  }

  public onClicked(node: Breadcrumb): void {
    document.getElementById('sidebar').classList.remove('open');
    this.closeSlideout();
  }
}
