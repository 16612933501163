import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { CalendarService } from '@services/calendar.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
@Component({
  selector: 'app-calendars-index',
  templateUrl: './calendars-index.component.html',
  styleUrls: ['./calendars-index.component.scss']
})
export class CalendarsIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['name', 'subscribers', 'periodization', 'actions'];
  public dataLoaded = false;
  public node: Breadcrumb;
  constructor(
    public calendarService: CalendarService,
    public router: Router,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      state,
      router,
      modelService: calendarService,
      snackbarService,
      dialog
    });
    this.setPageVars({
      sidebar: '',
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.node = {
      label: 'Calendars',
      link: 'calendars',
      title: 'Calendars'
    };
    this.buildSettingsBreadcrumb(this.node);
  }

  public updatedStatus(): void {
    this.state.message({ ['CalendarIndexUpdate']: true });
  }

}
