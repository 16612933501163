import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Environment } from '@environments/environment';
import { Cache } from '@app/cache';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService extends BaseService {

  constructor(
    public http: HttpClient,
    public cache: Cache
  ) {
    super(http, cache);
  }

  public getLog() {
    return this.http.get<object[]>(`${Environment.apiURL}maintenance/view-laravel-log`);
  }

  public migrateTwilioDeviceTokens() {
    return this.http.get<object[]>(`${Environment.apiURL}maintenance/migrate-twilio-device-registrations`);
  }

  public resetAlldayEventEndTimes() {
    return this.http.get<object[]>(`${Environment.apiURL}maintenance/reset-allday-event-end-times`);
  }

  public fixChats() {
    return this.http.get<object[]>(`${Environment.apiURL}maintenance/fix-chats`);
  }
}
