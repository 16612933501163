import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { FormValidators } from '@app/modules/common/shared/validators/form-validators.validator';
import { State } from '@app/state';
import { SidebarService } from '@services/sidebar.service';
import { ClientSettingService } from '@services/client-setting.service';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'client-setting-form',
  templateUrl: './client-setting-form.component.html',
  styleUrls: ['./client-setting-form.component.scss'],
  providers: [SidebarService]
})

export class ClientSettingFormComponent extends BaseComponent {
  // Local variables
  clientSettingFormGroup: FormGroup;
  dataLoaded = false;
  globalUtil = GlobalUtil;
  clientSetting;

  constructor(
    public activatedRoute: ActivatedRoute,
    public clientSettingService: ClientSettingService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: clientSettingService
    });

    this.setPageVars({
      sidebar: ''
    });

    this.initialFormGroupSetup();
    this.modelService.getOne(this.routeParams['id']).subscribe((result: any) => {
      this.clientSetting = result;
      result.setting_value = result.value;
      this.clientSettingFormGroup.patchValue(result);
      this.dataLoaded = true;
    });
  }

  get controls(): any {
    return this.clientSettingFormGroup.controls;
  }

  public save(): void {
    const uuid = this.controls.uuid.value;

    if (this.clientSetting.master_setting.type === 'bool') {
      this.replaceBooleanFieldsWithByte();
    }

    this.clientSettingService.patchById(uuid, this.clientSettingFormGroup.value)
      .subscribe(result => this.savedMessage(result));

    if (this.clientSetting.master_setting.type === 'bool') {
      // Rollback the changes so all the checkboxes will bound correctly
      this.replaceByteFieldsWithBoolean();
    }
  }

  private revertToMaster(): void {
    this.controls.setting_value.value = null;
    this.save();
  }

  public cancel(): void {
    this.clientSettingFormGroup.reset();
    this.closeSlideout();
  }

  private initialFormGroupSetup(): void {
    this.clientSettingFormGroup = this.formBuilder.group({
      uuid: [null],
      setting_value: [null, [Validators.required]]
    });
  }

  private savedMessage(data) {
    this.state.message({ ['ClientSettingIndexUpdate']: true });
    this.closeSlideout();
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  private replaceBooleanFieldsWithByte(): void {
    this.clientSettingFormGroup.patchValue({
      'setting_value': this.controls.setting_value.value ? '1' : '0'
    });
  }

  private replaceByteFieldsWithBoolean(): void {
    this.clientSettingFormGroup.patchValue({
      'setting_value': this.controls.setting_value.value === '1' ? true : false
    });
  }
}
