// Traits button
export const traitButton = options => {
    const { buttonText, command, commandOptions, editor, status, id, onClickAction } = options;
    var statusClass = 'button-primary';
    var button = document.createElement('button');

    if (!! status) {
        statusClass = `button-${statusClass}`;
    }

    button.innerHTML = buttonText;
    button.classList.add('gjs-button', 'button', statusClass);

    if (!! command) {
        button.onclick = () => {
            editor.Commands.run(command, commandOptions);
            // Stops warning that onclick took too long
            return;
        };
    }

    if (!! onClickAction) {
        button.onclick = onClickAction;
    }

    if (!! id) {
        button.setAttribute('id', id);
    }

    return button;
}