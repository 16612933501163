// All the settings (menu) related components, services & classes should be declared here

import { NgModule } from '@angular/core';
import { MaterialModule } from '../third-parties/material/material.module';
import { AngularModule } from '../third-parties/angular/angular.module';
import { AngularCdkModule } from '../third-parties/angular-cdk/angular-cdk.module';

import { SharedModule } from '../shared/shared.module';

import { CoreFormBadgeComponent } from './components/badge/core-form-badge.component';
import { CoreFormCheckboxComponent } from './components/checkbox/core-form-checkbox.component';
import { CoreFormCheckboxListComponent } from './components/checkbox-list/core-form-checkbox-list.component';
import { CoreFormColorPickerComponent } from './components/color-picker/core-form-color-picker.component';
import { CoreFormDatePickerComponent } from './components/date-picker/core-form-date-picker.component';
import { CoreFormAdvancedSelectComponent } from './components/form-advanced-select/core-form-advanced-select.component';
import { CoreFormInputComponent } from './components/form-input/core-form-input.component';
import { CoreFormHintComponent } from './components/hint/core-form-hint.component';
import { CoreFormRichTextComponent } from './components/rich-text/core-form-rich-text.component';
import { CoreFormSelectComponent } from './components/select/core-form-select.component';
import { CoreFormTextareaComponent } from './components/textarea/core-form-textarea.component';
import { CoreFormTimezoneComponent } from './components/timezone/core-form-timezone.component';
import { CoreFormUploadComponent } from './components/upload/core-form-upload.component';
import { FileIconComponent } from './components/file-icon/file-icon.component';
import { PrimaryNavigationComponent } from './components/layout/primary-navigation/primary-navigation.component';
import { FullWidthOverlayComponent } from './components/layout/full-width-overlay/full-width-overlay.component';
import { AppViewComponent } from './components/layout/view/view.component';
import { InformationBarComponent } from './components/layout/information-bar/information-bar.component';
import { LoadingComponent } from './components/layout/loading/loading.component';
import { PageComponent } from './components/layout/page/page.component';
import { PageNavigationComponent } from './components/layout/page-navigation/page-navigation.component';
import { RightbarComponent } from './components/layout/rightbar/rightbar.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { SidebarButtonComponent } from './components/layout/sidebar/button/sidebar-button.component';
import { SidebarFilterListComponent } from './components/layout/sidebar/filter-list/sidebar-filter-list.component';
import { SidebarFilterFormComponent } from './components/layout/sidebar/filter-form/sidebar-filter-form.component';
import { SidebarFormInputComponent } from './components/layout/sidebar/filter-form/input/input.component';
import { SidebarFormCheckboxComponent } from './components/layout/sidebar/filter-form/checkbox/checkbox.component';
import { SidebarFormDatepickerComponent } from './components/layout/sidebar/filter-form/datepicker/datepicker.component';
import { SidebarFormBuilderComponent } from './components/layout/sidebar/filter-form/form-builder/form-builder.component';
import { FormSelectComponent } from '../../../../app/elements/form/select/form-select.component';
import { HelperModule } from '../third-parties/helper/helper.module';
import { SlideOutComponent } from './components/layout/slide-out/slide-out.component';
import { SidebarFilterLinkComponent } from './components/layout/sidebar/filter-link/sidebar-filter-link.component';
import { UserMenuComponent } from './components/layout/user-menu/user-menu.component';
import { ButtonDebounceClickDirective } from './directives/btn-debounce-click.directive';
import { SecureImageComponent } from './../../../elements/secure-image/secure-image.component';

const COMPONENTS = [
  CoreFormBadgeComponent,
  CoreFormCheckboxComponent,
  CoreFormCheckboxListComponent,
  CoreFormColorPickerComponent,
  CoreFormDatePickerComponent,
  CoreFormAdvancedSelectComponent,
  CoreFormInputComponent,
  CoreFormHintComponent,
  CoreFormRichTextComponent,
  CoreFormSelectComponent,
  CoreFormTextareaComponent,
  CoreFormTimezoneComponent,
  CoreFormUploadComponent,
  FileIconComponent,
  FullWidthOverlayComponent,
  PrimaryNavigationComponent,
  AppViewComponent,
  InformationBarComponent,
  LoadingComponent,
  PageComponent,
  PageNavigationComponent,
  RightbarComponent,
  SidebarComponent,
  SidebarButtonComponent,
  SidebarFilterListComponent,
  SidebarFilterFormComponent,
  SidebarFormInputComponent,
  SidebarFormCheckboxComponent,
  SidebarFormDatepickerComponent,
  SidebarFormBuilderComponent,
  FormSelectComponent,
  SlideOutComponent,
  SidebarFilterLinkComponent,
  UserMenuComponent,
  ButtonDebounceClickDirective,
  SecureImageComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    HelperModule,
    AngularModule,
    AngularCdkModule,
    SharedModule,
    MaterialModule
  ],
  exports: [
   ...COMPONENTS
  ]
})
export class CoreModule { }
