export const NOTES_FILTER_FIELDS = [
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      value: ''
    },
    {
      type: 'dropdown',
      name: 'note_category_uuid',
      label: 'All Categories',
      value: '',
      apiUrl: 'note-categories/list'
    },
  ];
