import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { CustomFieldService } from '@app/services/custom-field.service';
import { Enum_Custom_Field_Model } from '../custom-field-type';
import { MappingCustomFieldExtension, CustomFieldExtension } from '@app/models/view-models/custom-field';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-mapping-field-form',
  templateUrl: './mapping-field-form.component.html',
  styleUrls: ['./mapping-field-form.component.scss']
})

export class MappingFieldFormComponent extends BaseComponent implements OnInit {
  @Input() showFieldSelection: false;
  @Input() model: Enum_Custom_Field_Model;
  @Input() uuid: string;
  @Input() data: any;
  @Input() showGroupInstructions: true;
  @Input() isSaved = true;
  @Output() onSaved: EventEmitter<any> = new EventEmitter();
  @Output() onLoaded: EventEmitter<MappingCustomFieldExtension[]> = new EventEmitter();

  public mappedFieldsGroup: MappingCustomFieldExtension[] = [];
  public dataLoaded = false;
  public customFieldFormGroup: FormGroup;
  public formSubmitted = false;
  private selectedRows = [{}];

  constructor(
    public activatedRoute: ActivatedRoute,
    public customFieldService: CustomFieldService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      state: state,
      modelService: customFieldService,
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    // TODO - change with new endpoint when ready.
    this.modelServiceGetAll().then(() => {
      this.mappedFieldsGroup = this.dataSourceRows;
      this.mappedFieldsGroup = this.mappedFieldsGroup.filter(r => r.models.indexOf(this.model) > -1);
      this.mappedFieldsGroup.map(group => group.selected = false );
      this.onLoaded.emit(this.mappedFieldsGroup);
      this.initialFormGroup();
      if (!(this.isSaved) && !(this.showFieldSelection)) {
        this.setupSubscriptions();
      }
      this.dataLoaded = true;
    });
  }

  private setupSubscriptions(): void {
    this.customFieldFormGroup.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((frm) => {
        this.onSaved.emit(frm);
      });
  }

  get controls(): any {
    return this.customFieldFormGroup.controls;
  }

  public save(): void {
    this.formSubmitted = true;
    setTimeout(() => {
      this.formSubmitted = false;
    }, 1000);
    this.onSaved.emit(this.customFieldFormGroup.value);
  }

  public getRequiredStatus(field: MappingCustomFieldExtension): boolean {
    return field.required === 1 ? true : false;
  }

  // User can toggle all checkboxes within a fieldset
  public onFieldsetToggle(group: MappingCustomFieldExtension): void {
    group.selected = !group.selected;
    group.custom_fields.map(f => f.selected = group.selected);
    this.preparedSelectedFields();
    this.onSaved.emit(this.selectedRows);
  }

  public onFieldToggle(field: CustomFieldExtension): void {
    field.selected = !field.selected;
    this.preparedSelectedFields();
    this.onSaved.emit(this.selectedRows);
  }

  private preparedSelectedFields(): void {
    this.selectedRows = [];
    this.mappedFieldsGroup.map(group => {
       group.custom_fields.map(fld => {
          if (fld.selected) {
            this.selectedRows.push(fld.uuid);
          }
        }
      );
    });
  }

  private initialFormGroup(): void {
    this.customFieldFormGroup = this.formBuilder.group({
      name: null,
    });

    this.mappedFieldsGroup.forEach(group => {
      const fields = group.custom_fields.map( (field: MappingCustomFieldExtension ) => {
        return new FormControl(null,
          {
            validators: field.required ? Validators.compose([Validators.required]) : null
          });
      });
      this.customFieldFormGroup.addControl(group.slug, new FormArray(fields));
    });
  }
}
