import { traitButton } from './shared';
import { CMD_OPEN_FILE_SELECTOR } from './../../const/commands';

export const FileSelector = function(editor, options:any = {}) {
    return {
        name: options.name,
        noLabel: true,
        templateInput: '',
        getInputEl() {

            const model = editor.getSelected();
            const attrs = model.getAttributes();
            const cont = document.createElement('div');
            const button = traitButton({
                editor: editor, buttonText: 'Select File', command: CMD_OPEN_FILE_SELECTOR
            });
            cont.appendChild(button);
            return cont;
        }
    }
}