import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { State } from '@app/state';
import { Environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiUri = Environment.apiURL;
  private apiBaseURL = Environment.apiBaseURL;
  private spIdApiURL = Environment.spIdApiURL;

  constructor(
    private http: HttpClient,
    private router: Router,
    private state: State
  ) {}

  public async getUserSession(): Promise<any> {
    return await this.http.get<any>(this.apiUri + 'user-session/get-session').toPromise();
  }

  public async getUserTokens(loginToken): Promise<any> {
    return await this.http.get<any>(this.spIdApiURL + 'user/get-tokens/' + loginToken).toPromise();
  }

  public async refreshUserSession(): Promise<object[]> {
    return await this.http.get<any>(this.apiBaseURL + 'public/v1/user-session/refresh-token').toPromise();
  }

  public deleteUserSession(): Observable<object[]> {
    return this.http.get<object[]>(this.apiUri + 'user-session/destroy-session');
  }

  public async checkRoutePermissions(route: string): Promise<object[]> {
    return await this.http.get<object[]>(this.apiUri + 'user-session/check-route-permissions/' + btoa(route)).toPromise();
  }

  public updateToken(token): void {
    localStorage.setItem('sessionToken', token);
    this.state.update({ 'sessionToken': token });
  }

  /**
   * Update user session
   */
  public async updateUserSession() {
    await this.refreshUserSession()
      .then((refreshTokenResult: any) => {
        this.updateToken(refreshTokenResult.token);
        this.getUserSession()
          .then(userSessionResult => this.setUserSessionState(userSessionResult))
          .catch(error => this.logout('Failed user session after refresh token'));
      })
      .catch(error => this.logout('Failed refresh token'));
  }

  /**
   * Logout
   */
  public logout(msg: string = ''): void {
    this.clearState();
    window.open(Environment.spIdURL, '_self');
  }

  /**
   * Destroy andl ogout
   */
  public destroyAndLogout() {
    this.clearState();
    this.destroyApp('Invalid IP');
    // setTimeout(() => window.open(Environment.spIdURL, '_self'), 5000);
  }

  /**
   * Set session state
   *
   * @param {any} userSessionResult
   */
  public setUserSessionState(userSessionResult: any): void {
    const authUser = userSessionResult.user;

    delete authUser.id;
    authUser.loggedInAt = new Date();

    const state: any = {
      'sessionToken': localStorage.getItem('sessionToken'),
      'refreshToken': localStorage.getItem('refreshToken'),
      'defaultTimezone': userSessionResult.default_timezone,
      'user': authUser
    };

    this.state.update(state);
  }

  /**
   * Clear state and local storage
   */
  public clearState(): void {
    this.state.remove('user');
    this.state.remove('settings');
    this.state.remove('sessionToken');
    this.state.remove('refreshToken');
    this.state.remove('loggedInAt');
    const deployment_hash = localStorage.getItem('deployment_hash');
    localStorage.clear();
    if (!! deployment_hash) {
      localStorage.setItem('deployment_hash', deployment_hash);
    }
  }

  /**
   * Destroy dom
   */
  public destroyApp(msg: string = 'Session Invalid'): void {
    this.clearState();
    const html: any = document.getElementsByTagName('html')[0];
    const body: any = document.getElementsByTagName('body')[0];
    html.classList.add('in-app-invalid-session');
    body.classList.add('in-app-invalid-session');
    body.innerHTML = `<div id="in-app-invalid-session"><h2>${msg}</h2></div>`;
    window.stop();
    // // tslint:disable
    // SportsologyAppMessageInvoker.postMessage('invalid-session');
    // // tslint:enable
  }
}
