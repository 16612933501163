import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { BaseService } from './base.service';
import { Cache } from '@app/cache';
import { Environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventService extends BaseService {

  private eventCache = [];

  constructor(
    public http: HttpClient,
    public cache: Cache
  ) {
    super(http, cache);
    this.setVars({
      endPoint: 'event',
      modelTitle: 'Event'
    });
  }

  duplicateFromBulkEdit(event): Observable<Object[]> {
    var data = {
      name: event.name,
      start_time: event.start_time,
      start_time_timezone_uuid: event.start_time_timezone_uuid,
      end_time: event.end_time,
      end_time_timezone_uuid: event.end_time_timezone_uuid
    }
    return this.http.post<object[]>(`${Environment.apiURL}event/${event.originalUuid}/duplicate-from-bulk-edit`, data);
  }
}
