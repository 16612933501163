import { Component, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FileInfo } from '@app/models/entities/fileInfo';
import { displayAsOptions, itemFloatOptions } from './const';

@Component({
  selector: 'image-selector-dialog',
  templateUrl: 'image-selector-dialog.component.html',
  styleUrls: ['image-selector-dialog.component.scss']
})
export class ImageSelectorDialogComponent {
    @ViewChild('uploadComponent', {static: false}) uploadComponent: any;

    public imageSelectFormGroup: FormGroup;
    private showInlineOptions: boolean = false;
    private imageFloatOptions: Array<any> = itemFloatOptions;

    get controls() {
        return this.imageSelectFormGroup.controls;
    }

    constructor(
        private dialogRef: MatDialogRef<ImageSelectorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private formBuilder: FormBuilder
    ) {
        const {
            showInlineOptions,
            imageUUID,
            imageDetails
        } = data;
        const formGroup:any = { imageUUID: [imageUUID], imageDetails: [imageDetails] };

        this.showInlineOptions  = showInlineOptions;

        // if (showInlineOptions) {
        //     formGroup.imageFloat = [imageFloat];
        // }

        this.imageSelectFormGroup = this.formBuilder.group(formGroup);
    }

    setImageDetails($fileInfo: FileInfo) {
        if ($fileInfo !== null && !! $fileInfo.uuid) {
            this.imageSelectFormGroup.patchValue({
                imageUUID: $fileInfo.uuid,
                imageDetails: {
                    'file_family': $fileInfo.fileFamily,
                    'file_name': $fileInfo.filename,
                    'file_size': $fileInfo.filesize + '',
                    'width': $fileInfo.width,
                    'height': $fileInfo.height,
                    'file_type': $fileInfo.mimeType,
                    'file_extension': $fileInfo.filename.split('?')[0].split('.').pop(),
                    'cdn_uuid': $fileInfo.uuid
                }
            });
        }
    }

    saveAndCloseDialog() {
        const values:any = {
            imageUUID: this.controls.imageUUID.value, imageDetails: this.controls.imageDetails.value
        };

        // if (this.showInlineOptions) {
        //     values.imageFloat = this.controls.imageFloat.value;
        // }

        this.dialogRef.close(values);
    }
}
