import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Cache } from '@app/cache';

@Injectable({
  providedIn: 'root'
})
export class PageService extends BaseService {

  private pageCache = [];

  constructor(
    public http: HttpClient,
    public cache: Cache
  ) {
    super(http, cache);
    this.setVars({
      endPoint: 'page',
      modelTitle: 'Page'
    });
  }

  getSnapshot(itemUUID, itemType) {
    return this.getFromApi(`page\\item-snapshot\\${itemType}\\${itemUUID}`);
  }

  getItemsSnapshot(itemUUID, itemType) {
    return this.getFromApi(`page\\items-snapshot\\${itemType}\\${itemUUID}`);
  }
}
