export const LOCATION_FILTER_FIELDS = [
  {
    type: 'text',
    name: 'name',
    label: 'Name',
    value: ''
  },
  {
    type: 'text',
    name: 'address',
    label: 'Address',
    value: ''
  },
  {
    type: 'dropdown',
    name: 'country_uuid',
    label: 'Country',
    value: '',
    apiUrl: 'countries/list',
    showSearch: true,
  }
];
