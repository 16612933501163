import {
    TRAIT_APP_COMPONENT_SELECTOR,
    TRAIT_APP_PAGE_SELECTOR,
    TRAIT_ARTICLE_CATEGORY_SELECTOR,
    TRAIT_ARTICLE_SELECTOR,
    TRAIT_BUNDLE_DISPLAY_AS,
    TRAIT_BUNDLE_SELECTOR,
    TRAIT_CALENDAR_SELECTOR,
    TRAIT_CHAT_SELECTOR,
    TRAIT_COMPONENT_TITLE,
    TRAIT_CONTACT_GROUP_SELECTOR,
    TRAIT_DIRECTORY_CATEGORY_SELECTOR,
    TRAIT_DIRECTORY_ITEM_SELECTOR,
    TRAIT_DOCUMENT_SELECTOR,
    TRAIT_EVENT_SELECTOR,
    TRAIT_FILE_SELECTOR,
    TRAIT_FOLDER_SELECTOR,
    TRAIT_ICON_SELECTOR,
    TRAIT_IMAGE_SELECTOR,
    TRAIT_INLINE_ITEM_SELECTOR,
    TRAIT_ITEM_PERMS_SELECTOR,
    TRAIT_PERIODISATION_CALENDAR_SELECTOR,
    TRAIT_PROFILE_GROUP_SELECTOR,
    TRAIT_ROW_COLUMN_ITEM_FLOW,
    TRAIT_ROW_COLUMN_ITEM_SPACING,
    TRAIT_TEXT_EDITOR,
    TRAIT_USER_CIRCLE_SELECTOR,
    // Misc
    TRAIT_MISC_TEXTBOX,
    TRAIT_MISC_REMOVE_COMPONENT
} from './../../const/traits';

import {
    COMP_BUTTON_APP_COMPONENT,
    COMP_BUTTON_APP_PAGE,
    COMP_BUTTON_ARTICLE,
    COMP_BUTTON_ARTICLE_CATEGORY,
    COMP_BUTTON_BUNDLE,
    COMP_BUTTON_CALENDAR,
    COMP_BUTTON_CHAT,
    COMP_BUTTON_CONTACT_GROUP,
    COMP_BUTTON_DIRECTORY_CATEGORY,
    COMP_BUTTON_DIRECTORY_ITEM,
    COMP_BUTTON_DOCUMENT,
    COMP_BUTTON_EVENT,
    COMP_BUTTON_FILE,
    COMP_BUTTON_FOLDER,
    COMP_BUTTON_PERIODISATION_CALENDAR,
    COMP_BUTTON_PROFILE_GROUP,
    COMP_BUTTON_USER_CIRCLE
} from './../../const/components';

import { BundleDisplayAs } from './bundle-display-as';
import { FileSelector } from './file-selector';
import { IconSelector } from './icon-selector';
import { ImageSelector } from './image-selector';
import { ItemPermsSelector } from './item-perms-selector';
import { PageFormInlineItemSelector } from './page-form-inline-item-selector';
import { PageFormItemSelector } from './page-form-item-selector';
import { RemoveComponent } from './remove-component';
import { RowColumnItemFlow } from './row-column-item-flow';
import { RowColumnItemSpacing } from './row-column-item-spacing';
import { TextboxTrait } from './textbox';
import { TextEditor } from './text-editor';

export const Traits = (editor, config:any = {}) => {

    const TraitManager = editor.TraitManager;

    // Custom fields
    TraitManager.addType(TRAIT_MISC_TEXTBOX, TextboxTrait(editor, { name: TRAIT_MISC_TEXTBOX }));
    TraitManager.addType(TRAIT_BUNDLE_DISPLAY_AS, BundleDisplayAs(editor, { name: TRAIT_BUNDLE_DISPLAY_AS }));
    TraitManager.addType(TRAIT_ROW_COLUMN_ITEM_FLOW, RowColumnItemFlow(editor, { name: TRAIT_ROW_COLUMN_ITEM_FLOW }));
    TraitManager.addType(TRAIT_ROW_COLUMN_ITEM_SPACING, RowColumnItemSpacing(editor, { name: TRAIT_ROW_COLUMN_ITEM_SPACING }));

    // Selectors
    TraitManager.addType(TRAIT_APP_COMPONENT_SELECTOR, PageFormItemSelector(editor, {
        itemType: COMP_BUTTON_APP_COMPONENT, buttonText: 'Select an App Component',
        name: TRAIT_APP_COMPONENT_SELECTOR
    }));

    TraitManager.addType(TRAIT_APP_PAGE_SELECTOR, PageFormItemSelector(editor, {
        itemType: COMP_BUTTON_APP_PAGE, buttonText: 'Select an App Page',
        name: TRAIT_APP_PAGE_SELECTOR
    }));
    TraitManager.addType(TRAIT_ARTICLE_SELECTOR, PageFormItemSelector(editor, {
        itemType: COMP_BUTTON_ARTICLE, buttonText: 'Select an Article',
        name: TRAIT_ARTICLE_SELECTOR
    }));
    TraitManager.addType(TRAIT_ARTICLE_CATEGORY_SELECTOR, PageFormItemSelector(editor, {
        itemType: COMP_BUTTON_ARTICLE_CATEGORY, buttonText: 'Select an Article Category',
        name: TRAIT_ARTICLE_CATEGORY_SELECTOR
    }));
    TraitManager.addType(TRAIT_BUNDLE_SELECTOR, PageFormItemSelector(editor, {
        itemType: COMP_BUTTON_BUNDLE, buttonText: 'Select a Bundle',
        name: TRAIT_BUNDLE_SELECTOR
    }));
    TraitManager.addType(TRAIT_CALENDAR_SELECTOR, PageFormItemSelector(editor, {
        itemType: COMP_BUTTON_CALENDAR, buttonText: 'Select a Calendar',
        name: TRAIT_CALENDAR_SELECTOR
    }));
    TraitManager.addType(TRAIT_CHAT_SELECTOR, PageFormItemSelector(editor, {
        itemType: COMP_BUTTON_CHAT, buttonText: 'Select a Chat',
        name: TRAIT_CHAT_SELECTOR
    }));
    TraitManager.addType(TRAIT_CONTACT_GROUP_SELECTOR, PageFormItemSelector(editor, {
        itemType: COMP_BUTTON_CONTACT_GROUP, buttonText: 'Select a Contact Group',
        name: TRAIT_CONTACT_GROUP_SELECTOR
    }));
    TraitManager.addType(TRAIT_DIRECTORY_CATEGORY_SELECTOR, PageFormItemSelector(editor, {
        itemType: COMP_BUTTON_DIRECTORY_CATEGORY, buttonText: 'Select a Directory Category'
    }));
    TraitManager.addType(TRAIT_DIRECTORY_ITEM_SELECTOR, PageFormItemSelector(editor, {
        itemType: COMP_BUTTON_DIRECTORY_ITEM, buttonText: 'Select a Directory Item',
        name: TRAIT_DIRECTORY_ITEM_SELECTOR
    }));
    TraitManager.addType(TRAIT_DOCUMENT_SELECTOR, PageFormItemSelector(editor, {
        itemType: COMP_BUTTON_DOCUMENT, buttonText: 'Select a Document',
        name: TRAIT_DOCUMENT_SELECTOR
    }));
    TraitManager.addType(TRAIT_EVENT_SELECTOR, PageFormItemSelector(editor, {
        itemType: COMP_BUTTON_EVENT, buttonText: 'Select an Event',
        name: TRAIT_EVENT_SELECTOR
    }));

    TraitManager.addType(TRAIT_FILE_SELECTOR, FileSelector(editor, { name: TRAIT_FILE_SELECTOR }));
    TraitManager.addType(TRAIT_FOLDER_SELECTOR, PageFormItemSelector(editor, {
        itemType: COMP_BUTTON_FOLDER, buttonText: 'Select a Folder', name: TRAIT_FOLDER_SELECTOR
    }));
    TraitManager.addType(TRAIT_ICON_SELECTOR, IconSelector(editor, { name: TRAIT_ICON_SELECTOR }));
    TraitManager.addType(TRAIT_IMAGE_SELECTOR, ImageSelector(editor, { name: TRAIT_IMAGE_SELECTOR }));
    TraitManager.addType(TRAIT_INLINE_ITEM_SELECTOR, PageFormInlineItemSelector(editor, { name: TRAIT_INLINE_ITEM_SELECTOR }));
    TraitManager.addType(TRAIT_ITEM_PERMS_SELECTOR, ItemPermsSelector(editor, { name: TRAIT_ITEM_PERMS_SELECTOR }));
    TraitManager.addType(TRAIT_TEXT_EDITOR, TextEditor(editor, { name: TRAIT_TEXT_EDITOR }));
    TraitManager.addType(TRAIT_PERIODISATION_CALENDAR_SELECTOR, PageFormItemSelector(editor, {
        itemType: COMP_BUTTON_PERIODISATION_CALENDAR, buttonText: 'Select a Calendar',
        name: TRAIT_PERIODISATION_CALENDAR_SELECTOR
    }));
    TraitManager.addType(TRAIT_PROFILE_GROUP_SELECTOR, PageFormItemSelector(editor, {
        itemType: COMP_BUTTON_PROFILE_GROUP, buttonText: 'Select a Profile Group',
        name: TRAIT_PROFILE_GROUP_SELECTOR
    }));
    TraitManager.addType(TRAIT_MISC_REMOVE_COMPONENT, RemoveComponent(editor, { name: TRAIT_MISC_REMOVE_COMPONENT }));
    TraitManager.addType(TRAIT_USER_CIRCLE_SELECTOR, PageFormItemSelector(editor, {
        itemType: COMP_BUTTON_USER_CIRCLE, buttonText: 'Select a User Circle',
        name: TRAIT_USER_CIRCLE_SELECTOR
    }));
}