import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { TabPanelContentComponent } from '@elements/tabs/panel/content/tab-panel-content.component';
@Component({
  selector: 'tab-panel',
  templateUrl: './tab-panel.component.html',
  styleUrls: ['./tab-panel.component.scss'],
})
export class TabPanelComponent {
  @ViewChild(TabPanelContentComponent, { read: ViewContainerRef, static: true }) tabcontent: ViewContainerRef;
  private contentAdded = false;
  insertIntoTab(content) {
    if (!this.contentAdded) {
      this.contentAdded = true;
      this.tabcontent.createEmbeddedView(content);
    }
  }
}
