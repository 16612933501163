import grapesjs from 'grapesjs';

import { TYPE_APP_PAGE } from './../const/form';

import { Commands } from './commands';
import { Blocks } from './blocks';
import { RTE } from './rich-text-editor';
import { Traits } from './traits';
import { Components } from './components';
import { Panels } from './panels';
import { Styles } from './styles';

export const EditorPlugin = editorOptions => {
  return grapesjs.plugins.add(editorOptions.type, (editor, opts:any = {}) => {

    let config = opts;

    const { type } = editorOptions;
    config.type = type;

    if (type === TYPE_APP_PAGE) {
      const { pageType } = editorOptions;

      config.pageType = pageType;
    }

    let defaults = {
      // Which blocks to add
      // blocks: ['link-block', 'quote', 'text-basic', 'page-link'],

      // Modal import title
      modalImportTitle: 'Import',

      // Modal import button text
      modalImportButton: 'Import',

      // Import description inside import modal
      modalImportLabel: '',

      // Default content to setup on import model open.
      // Could also be a function with a dynamic content return (must be a string)
      // eg. modalImportContent: editor => editor.getHtml(),
      modalImportContent: '',

      // Code viewer (eg. CodeMirror) options
      importViewerOptions: {},

      // Confirm text before cleaning the canvas
      textCleanCanvas: 'Are you sure to clean the canvas?',

      // Text for General sector in Style Manager
      textGeneral: 'General',

      // Text for Layout sector in Style Manager
      textLayout: 'Layout',

      // Text for Typography sector in Style Manager
      textTypography: 'Typography',

      // Text for Decorations sector in Style Manager
      textDecorations: 'Decorations',

      // Text for Extra sector in Style Manager
      textExtra: 'Extra',

      // Use custom set of sectors for the Style Manager
      customStyleManager: [],

      // `grapesjs-blocks-basic` plugin options
      // By setting this option to `false` will avoid loading the plugin
      blocksBasicOpts: {},

      // `grapesjs-navbar` plugin options
      // By setting this option to `false` will avoid loading the plugin
      navbarOpts: {},

      // `grapesjs-component-countdown` plugin options
      // By setting this option to `false` will avoid loading the plugin
      countdownOpts: {},

      // `grapesjs-plugin-forms` plugin options
      // By setting this option to `false` will avoid loading the plugin
      formsOpts: {},

      // `grapesjs-plugin-export` plugin options
      // By setting this option to `false` will avoid loading the plugin
      exportOpts: {},

      // `grapesjs-aviary` plugin options, disabled by default
      // Aviary library should be included manually
      // By setting this option to `false` will avoid loading the plugin
      aviaryOpts: 0,

      // `grapesjs-plugin-filestack` plugin options, disabled by default
      // Filestack library should be included manually
      // By setting this option to `false` will avoid loading the plugin
      filestackOpts: 0
    };

    // Load defaults
    for (let name in defaults) {
      if (!(name in config))
        config[name] = defaults[name];
    }

    // Setup rich text editor
    RTE(editor, config);

    // Load Traits
    Traits(editor, config)

    // Load components
    Components(editor, config);

    // Load blocks
    Blocks(editor, config);

    // Load commands
    Commands(editor, config);

    // Load panels
    Panels(editor, config);

    // Load styles
    Styles(editor, config);
  });
}