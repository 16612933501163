export class Cache {

  static set(key: string, value: any, expiryInMinutes: number = 60) {
    const date = new Date();
    const millisecondExpiry = date.setMinutes( date.getMinutes() + expiryInMinutes);
    localStorage.setItem(key, JSON.stringify({'expiry': millisecondExpiry, 'data': value}));
  }

  static get(key: string) {
    const cacheItemRaw = localStorage.getItem(key);
    if (!cacheItemRaw) {
      return null;
    }
    const cacheItem = JSON.parse(cacheItemRaw);
    const nowInMilliseconds = Cache.now();
    if (parseFloat(cacheItem.expiry) > nowInMilliseconds) {
      return cacheItem.data;
    }
    return null;
  }

  static now() {
    const now = new Date();
    return now.setMinutes(now.getMinutes() + 0);
  }

  static clear(key = null) {
    localStorage.setItem(key, JSON.stringify({'expiry': 0, 'data': null}));
  }

  private static getCache() {
    if ( localStorage.get('cache')) {
      return JSON.parse(localStorage.get('cache'));
    }
    return {};
  }
  private static putCache( cache ) {
    localStorage.put('cache', JSON.stringify(cache));
  }
}
