import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Const } from '@app/const';
import { FormBaseElementComponent } from '@elements/form/form-base-element.component';
import { ApiDataService } from '@services/api-data.service';
@Component({
  selector: 'form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
  providers: [ApiDataService]
})
export class FormSelectComponent extends FormBaseElementComponent implements OnInit {
  @Input() apiDataSource = '';
  @Input() apiDataSourceCacheKey = '';
  @Input() dataSourceValue = 'uuid';
  @Input() dataSourceText = 'name';
  @Input() empty: any = false;
  @Input() groupedResult = false;
  @Input() hasDot = false;
  @Input() hideLabel = false;
  @Input() label = '';
  @Input() multiple = false;
  @Input() name = '';
  @Input() selected: Array<any> = [];
  @Input() selectedResult: Array<any> = [];
  @Input() selectedType = 'list'; // Supply 'object' if the data is key/value pairs
  @Input() updateParentWithSelectedResult = false;
  @Input() values: any;
  @Input() valuesFromConst: any = false;
  @Input() materialDesign: any = false;
  @Input() field: any = {};
  @Input() fieldName: '';
  @Input() form: FormGroup;
  @Output() notifyParentComponentEvent: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  @ViewChild('multipleSelect', {static: false}) private multipleSelect: any;
  @ViewChild('multipleSelectGrouped', {static: false}) private multipleSelectGrouped: any;

  public currentColor: string;
  public parsedValues: Array<object> = [];
  public selectReady = false;

  constructor(private apiDataService: ApiDataService) {
    super();
  }

  ngOnInit() {
    if (this.selected === null && this.multiple) {
      this.selected = [];
    }

    if (this.apiDataSource !== '' && typeof this.apiDataSource !== 'undefined') {
      this.apiDataService
        .getOptions(this.apiDataSource, this.apiDataSourceCacheKey)
        .subscribe((result: any) => this.convertObjectToValues(typeof result.data !== 'undefined' ? result.data : result));
    }

    if (this.valuesFromConst) {
      this.parsedValues = Const[this.valuesFromConst];
      this.selectReady = true;
    }
    this.selectedResult = this.selected;
  }

  notifyParentComponent() {
    if (this.updateParentWithSelectedResult) {
      this.notifyParentComponentEvent.emit(this.selected);
    }
  }

  convertObjectToValues(objectToParse) {
    if (typeof objectToParse === 'undefined') {
      return;
    }

    // Normally 'this.selected' is a list of UUIDs
    // Here we check if it's an object, extract the key, and convert it to a list
    // add [selectedType]="object" to the <form-select> to trigger this condition
    if (this.selectedType === 'object' && this.selected[0] !== 'undefined') {
      this.selected = this.selected.map(a => a[this.dataSourceValue]);
    }

    if (this.groupedResult) {
      objectToParse.forEach((groupedObject: any) => {
        const groupedObjectTmp: any = {};
        groupedObjectTmp.name = groupedObject.name;
        groupedObjectTmp.count = groupedObject.count;
        groupedObjectTmp.list = [];

        groupedObject.list.forEach(object => {
          groupedObjectTmp.list.push({
            value: object[this.dataSourceValue],
            text: object[this.dataSourceText],
            selected: this.setSelected(object[this.dataSourceValue])
          });
        });
        this.parsedValues.push(groupedObjectTmp);
      });
    } else {
      objectToParse.forEach(object => {
        this.parsedValues.push({
          value: object[this.dataSourceValue],
          text: object[this.dataSourceText],
          selected: this.setSelected(object[this.dataSourceValue])
        });
      });
    }
    this.selectReady = true;
    this.selectedResult = this.selected;
  }

  // Returns data to form.component
  data(): any {
    if (this.multiple) {
      const select: any = this.multiple && this.groupedResult ? this.multipleSelectGrouped : this.multipleSelect;
      const selected = [];
      [].forEach.call(select.nativeElement.selectedOptions, option => selected.push(option.value));
      this.selectedResult = selected;
    } else {
      this.selectedResult = this.selected;
    }
    return this.selectedResult;
  }

  // Sets the selected value for an option
  setSelected(key: any) {
    if (this.multiple) {
      return this.selected.indexOf(key) > -1;
    } else {
      return key === this.selected;
    }
  }

  resetValue() {
    if (!!this.defaultValue) {
    } else {
      if (this.multiple) {
        const select: any = this.multipleSelect;
        [].forEach.call(select.nativeElement.options, option => (option.selected = false));
      } else {
        this.selectedResult = this.selected = null;
      }
    }
  }
}
