import { paramCase } from 'change-case';

import {
  InlineDefaults,
  InlineTraits
} from './shared';

import { COMP_TEXT_INLINE_ITEM } from './../../../const/components';

import { ICON_BUTTON_URL } from './../../../const/icons';

import { PageFormLinkedItem } from './../page-form-linked-item';

const compParamName = paramCase(COMP_TEXT_INLINE_ITEM);

export const InlineItem = (editor, options:any = {}) => {
  return PageFormLinkedItem(editor, {
    componentName: COMP_TEXT_INLINE_ITEM,
    displayIcon: ICON_BUTTON_URL,
    model: {
      defaults: { ...InlineDefaults,
        name: 'Item',
        traits: [ ...InlineTraits]
      }
    }
  });
}