import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ContactService } from '@services/contact.service';
import { GroupService } from '@services/group.service';
import { UserService } from '@services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';

@Component({
  selector: 'app-link-contact-to-user-dialog',
  templateUrl: './link-contact-to-user-dialog.component.html',
  styleUrls: ['./link-contact-to-user-dialog.component.scss'],
})
export class LinkContactToUserDialogComponent implements OnInit {
  public contact: any = null;
  public groups: any = null;
  public tempToken: any = null;
  public dataLoaded: boolean = false;
  public associatedUserFromSecondaryContact: boolean = false;
  public formSubmitted: boolean = false;
  public linkedUserFormGroup: FormGroup;

  private globalUtil = GlobalUtil;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<LinkContactToUserDialogComponent>,
    private formBuilder: FormBuilder,
    private userService: UserService,
  ) {
    if(!! this.data) {
      if(!! this.data.contact) {
        this.contact = this.data.contact;
      }
      if(!! this.data.associated_user_from_secondary_contact) {
        this.associatedUserFromSecondaryContact = this.data.associated_user_from_secondary_contact;
      }
      if(!! this.data.temp_associated_user_token) {
        this.tempToken = this.data.temp_associated_user_token;
      }
    }
  }

  get controls(): any {
    return this.linkedUserFormGroup.controls;
  }

  ngOnInit() {
    this.initialFormGroupSetup();
    this.dataLoaded = true;
  }

  private initialFormGroupSetup(): void {

    var uuid = null;
    var firstname = null;
    var lastname = null;
    var contactTypeUuid = null;
    var mobile = '';
    var email = '';

    if(!! this.contact) {
      uuid = this.contact.uuid;
      email = this.contact.email;
      firstname = this.contact.firstname;
      lastname = this.contact.lastname;
      mobile = this.contact.mobile ? '+' + this.contact.mobile.replace('+', '') : null;
      contactTypeUuid = this.contact.contact_type_uuid;
    }

    var fields:any = {
      firstname: [firstname],
      lastname: [lastname],
      mobile: [mobile],
      apps_v2: [null],
      role_id: [4],
      email: [email, [
        Validators.required,
        Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH),
        Validators.pattern(GlobalUtil.Validation.EMAIL_PATTERN)
        ]
      ],
      associated_user_type_uuid: [contactTypeUuid],
      group_uuid: [null],
      link_events: [false],
      link_messages: [false],
      link_folders_documents: [false],
      link_chats: [false],
      spid_invite_by_sms: [{ value: false, disabled: ! mobile }],
      spid_invite_by_email: [{ value: false, disabled: ! email }]
    };

    if(uuid) {
      fields.contact_uuid = [uuid];
    } else {
      fields.associated_user_temp_token = this.tempToken;
    }

    if(this.associatedUserFromSecondaryContact) {
      fields.associated_user_from_secondary_contact = true;
    }

    if(!! this.data.baseUserUuid) {
      fields.associated_user_subject_uuid = this.data.baseUserUuid;
    }

    this.linkedUserFormGroup = this.formBuilder.group(fields);

    this.controls.mobile.valueChanges.subscribe(value => {
      if (value === null) {
        this.controls.spid_invite_by_sms.setValue(false);
        this.controls.spid_invite_by_sms.disable();
      } else {
        this.controls.spid_invite_by_sms.enable();
      }
    });

    this.controls.email.valueChanges.subscribe(value => {
      if (value === null) {
        this.controls.spid_invite_by_email.setValue(false);
        this.controls.spid_invite_by_email.disable();
      } else {
        this.controls.spid_invite_by_email.enable();
      }
    });
  }

  public async save() {
    this.userService.create(this.linkedUserFormGroup.value).subscribe(newUser => this.dialogRef.close(newUser));
  }
}
