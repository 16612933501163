import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-file-icon',
  templateUrl: './file-icon.component.html',
  styleUrls: ['./file-icon.component.scss']
})
export class FileIconComponent implements OnInit {
  @Input() extension = 'unknown';
  @Input() fileFamily = '';
  @Input() cdnUuid = '';
  @Input() cdnUrl = '';

  public sourceUrl;

  constructor() { }

  ngOnInit() {
    if(this.fileFamily === 'image') {
      if(this.cdnUrl.includes('ucarecdn.com') && this.cdnUrl.substr(-1) !== '/') {
        this.cdnUrl += '/';
      }
      this.sourceUrl = this.cdnUrl;
    } else {
      this.sourceUrl = `/assets/img/file-icons/${this.extension}.svg`;
    }
  }
}
