import { Pipe, PipeTransform } from '@angular/core';
import { monthNames } from '../util/global-util';

@Pipe({ name: 'dateSuffix' })
export class DateSuffixPipe implements PipeTransform {
  transform(value: string): string {
    let suffix = 'th';
    const date = new Date(value);
    const day = date.getDate() + '';
    // setup the suffix
    if (day === '1' || day === '21' || day === '31') {
      suffix = 'st';
    } else if (day === '2' || day === '22') {
      suffix = 'nd';
    } else if (day === '3' || day === '23') {
      suffix = 'rd';
    }
    // TODO - this needs to be changed based on the format of global settings.
    // make the date & time value based on our format.
    const res = day + suffix + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear() + ' '
      + value.substr(value.length - 7).trim().replace(' ', '');
    return res;
  }
}
