import { paramCase } from 'change-case';
import {
    modelSetClass,
    parseUri,
    updatePageFormItemPerms
} from './../../utils';

import {
    COMP_LAYOUT_SECTION,
    COMP_WIDGET_MAP
} from './../../../const/components';

import { ICON_WIDGET_MAP } from './../../../const/icons';

const compParamName = paramCase(COMP_WIDGET_MAP);

export const MapWidget = (editor, options:any = {}) => {
    return {
        isComponent: el => el.tagName === compParamName.toUpperCase(),
        model: {
            activeOnRender: true,
            defaults: {
                tagName: compParamName,
                displayIcon: ICON_WIDGET_MAP,
                name: 'Map',
                draggable: paramCase(COMP_LAYOUT_SECTION),
                droppable: false,
                resizable: false,
                src: '',
                void: 0,
                mapUrl: 'https://maps.google.com/maps',
                mapType: 'q',
                address: '',
                zoom: '1',
                attributes: { frameborder: 0 },
                traits: [{
                    label: 'Address',
                    name: 'address',
                    placeholder: 'eg. London, UK',
                    changeProp: true
                }, {
                    type: 'select',
                    label: 'Map type',
                    name: 'mapType',
                    changeProp: true,
                    default: 'w',
                    options: [
                        { value: 'q', name: 'Roadmap' },
                        { value: 'w', name: 'Satellite' }
                    ]
                }, {
                    label: 'Zoom',
                    name: 'zoom',
                    type: 'range',
                    min: 1,
                    max: 20,
                    changeProp: true,
                    default: 10
                }]
            },

            init() {
                if (this.get('src')) {
                    this.parseFromSrc();
                } else {
                    this.updateSrc();
                }
                this.listenTo(this, 'change:address change:zoom change:mapType', this.updateSrc);
                this.listenTo(this, 'change:perms', this.updatePerms);
            },

            updatePerms() {
                updatePageFormItemPerms.call(this, editor);
            },

            updateSrc() {
              this.set('src', this.getMapUrl());
            },

            getMapUrl() {
                var model = this;
                var addr = model.get('address');
                var zoom = model.get('zoom');
                var type = model.get('mapType');
                var size = '';
                addr = addr ? '&q=' + addr : '';
                zoom = zoom ? '&z=' + zoom : '';
                type = type ? '&t=' + type : '';
                var result = model.get('mapUrl') + '?' + addr + zoom + type;
                result += '&output=embed';
                return result;
            },

            parseFromSrc() {
                var uri:any = parseUri(this.get('src'));
                var query:any = uri.query;
                if (query.q) this.set('address', query.q);
                if (query.z) this.set('zoom', query.z);
                if (query.t) this.set('mapType', query.t);
            }
        },
        view: {
            tagName: compParamName,

            init() {
                // editor.select(this.model);
                updatePageFormItemPerms.call(this, editor);
                this.listenTo(this.model, 'change:address change:zoom change:mapType', this.updateSrc)
            },

            updateSrc() {
                this.getIframe().src = this.model.get('src');
            },

            getIframe() {
                if (!this.iframe) {
                    var ifrm:any = document.createElement('iframe');
                    ifrm.src = this.model.get('src');
                    ifrm.frameBorder = 0;
                    ifrm.style.height = '100%';
                    ifrm.style.width = '100%';
                    ifrm.className = this.ppfx + 'no-pointer';
                    this.iframe = ifrm;
                }
                return this.iframe;
            },

            onRender() {
                modelSetClass.call(this, 'map widget-item');
                const cont = document.createElement('div');

                cont.classList.add('widget-inner');
                cont.appendChild(this.getIframe());
                this.el.appendChild(cont);
            }
        }
    }
}