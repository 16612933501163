import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ExportService } from '@app/services/export.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { EXPORTABLE_FIELDS } from './exportable-field-list';
import { CoreFormAdvancedSelectComponent } from '@app/modules/common/core/components/form-advanced-select/core-form-advanced-select.component';
import { Enum_Custom_Field_Model } from '@app/components/custom-fields/custom-field-type';
@Component({
  selector: 'app-export-form',
  templateUrl: './export-form.component.html',
  styleUrls: ['./export-form.component.scss'],
  providers: [SidebarService]
})
export class ExportFormComponent extends BaseComponent {
  exportableFieldOptions: any = EXPORTABLE_FIELDS;
  userModel = Enum_Custom_Field_Model.APP_USER;
  exportFormGroup: FormGroup;
  dataLoaded = false;
  globalUtil = GlobalUtil;
  usersCols: any;
  ids = [];
  @ViewChild(CoreFormAdvancedSelectComponent, {static: false}) private advancedSelectInput: { getSelectedIds: () => any[]; };
  constructor(
    public activatedRoute: ActivatedRoute,
    public exportService: ExportService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder,
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: exportService
    });

    this.setPageVars({
      sidebar: ''
    });

    this.initialFormGroupSetup();
    const id = this.routeParams['id'];
    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result: any) => {
        this.populateInitialData(result);
        this.dataLoaded = true;
        this.exportFormGroup.patchValue(result);
      });
    }
    // Setup the columns settings for the multi-selection table (users)
    this.usersCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` },
    ];

  }

  // All the public methods
  get controls(): any {
    return this.exportFormGroup.controls;
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  public save(): void {
    const id = this.controls.id.value;
    const uuid = this.controls.uuid.value;
    this.exportFormGroup.patchValue({
      'users': this.getSelectedUsers()
    });

    if (id || uuid) {
      // Update the existing row
      this.exportService.patchById(uuid ? uuid : id, this.exportFormGroup.value)
        .subscribe(result => {
          this.SavedMessage(result);
        });
    } else {
      // Create the new row
      this.exportService.create(this.exportFormGroup.value).subscribe(result => this.SavedMessage(result));
    }
  }

  public getCustomFieldsForm(val): void {
    this.exportFormGroup.patchValue({
      custom_fields : val
    });
  }

  // All the private methods
  private initialFormGroupSetup(): void {
    this.exportFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      title: [null, [Validators.required, Validators.maxLength(this.globalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      description: [null],
      format: [null, [Validators.required]],
      fields: [null, [Validators.required]],
      conditions: this.formBuilder.group({
        group_uuid: [null],
        // role_id: [null],
      }),
      users: [],
      custom_fields: null
    });
  }

  private SavedMessage(data) {
    this.state.message({ ['ExportIndexUpdate']: true });
    this.closeSlideout();
  }

  private getSelectedUsers(): any[] {
    const value: any[] = this.advancedSelectInput.getSelectedIds();
    return value === null ? null : value;
  }

  private populateInitialData(row): void {
    if (row !== undefined) {
      this.selectedIds(row);
    }
  }

  private selectedIds(row): any {
    row.users.forEach(el => {
      this.ids.push(el);
    });
    return this.ids;
  }
}
