import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FIREBASE_REFERENCES } from '../../../firebase.module';
import { UserService } from '@services/user.service';
import * as firebase from 'firebase';

import { Environment } from '@environments/environment';

@Component({
  selector: 'app-add-chat-user-circles',
  templateUrl: './add-chat-user-circles.component.html',
  styleUrls: ['./add-chat-user-circles.component.scss'],
})
export class AddChatUserCirclesComponent implements OnInit
{
  public userCircles: any = [];
  public productID: string;
  public groupChatID: string;
  public fbPrefix: string = '';

  constructor(
    public userService: UserService,
    @Inject(FIREBASE_REFERENCES.TWO_FIRESTORE) private readonly chat: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.userCircles = this.data.userCircles;
    this.productID = this.data.productID;
    this.groupChatID = this.data.groupChatID;

    if(Environment.stage) {
      this.fbPrefix = 'stage_';
    }
  }

  ngOnInit() {}

  onNoClick(): void
  {
    this.data.close();
  }

  public addUserCircle(userCircle)
  {
    this.chat
      .collection(this.fbPrefix + 'chatgroup')
      .doc(this.productID)
      .collection('groups')
      .doc(this.groupChatID)
      .update({ circles_id: firebase.firestore.FieldValue.arrayUnion(userCircle.uuid) });
    userCircle.list.forEach(groupMember => this.addUserToGroup(groupMember));
  }

  public addUserToGroup(groupMember, associatedSubject:any = false)
  {
    const uuid = associatedSubject ? groupMember.recipient_uuid : groupMember.uuid;

    this.chat
      .collection(this.fbPrefix + 'chatgroup')
      .doc(this.productID)
      .collection('groups')
      .doc(this.groupChatID)
      .update({
        members: firebase.firestore.FieldValue.arrayUnion(uuid),
        [`viewedMembers.${groupMember.uuid}`]: { viewed: 0 }
      });

    var data:any = {
      firstname: groupMember.firstname,
      lastname: groupMember.lastname,
      email: groupMember.email,
      uuid: uuid,
      avatar: groupMember.avatar,
      group: groupMember.group,
      viewedMessages: 0,
    };

    if(!! groupMember.associated_user_recipients && groupMember.associated_user_recipients.length > 0) {
      const associatedUsers = groupMember.associated_user_recipients
        .filter(associatedUser => associatedUser.link_chats === true);

      if(associatedUsers.length > 0) {
        data.associated_recipients = groupMember.associated_user_recipients;
      }
    }

    if(associatedSubject) {
      delete associatedSubject.associated_user_recipients;
      data.associated_subject = associatedSubject;
      data.isAssociatedUser = true;
    }

    this.chat.collection(this.fbPrefix + 'chatgroup')
      .doc(this.productID)
      .collection('groups')
      .doc(this.groupChatID)
      .collection('invited')
      .doc(uuid)
      .set(data);

    if(!! groupMember.associated_user_recipients && groupMember.associated_user_recipients.length > 0) {
      groupMember.associated_user_recipients.forEach(associatedUser => {
        if(associatedUser.link_chats) {
          this.addUserToGroup(associatedUser, groupMember);
        }
      });
    }
  }
}