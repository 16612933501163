import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { RoleService } from  '@services/role.service';
import { SidebarService } from '@services/sidebar.service';
import { BaseComponent } from '../../base.component';
@Component({
  selector: 'role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss'],
  providers: [SidebarService]
})
export class RoleFormComponent extends BaseComponent {
  roleFormGroup: FormGroup;
  dataLoaded = false;
  formSubmitted = false;
  globalUtil = GlobalUtil;
  usersCols = [];
  usersIds = [];
  permissionsCols = [];
  permissionsIds = [];
  @ViewChild('permissions', {static: false}) private multiSelectedPermission;

  constructor(
    public activatedRoute: ActivatedRoute,
    public roleService: RoleService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: roleService
    });

    this.setPageVars({
      sidebar: ''
    });

    this.initialFormGroupSetup();
    const id = this.routeParams['id'];
    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result: any) => {
        this.dataLoaded = true;
        this.roleFormGroup.patchValue(result);
        this.populateInitialData(result);
      });
    }

    // Setup the columns settings for the multi-selection table (Permissions)
    this.permissionsCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` }
    ];
  }

  // All the public methods
  get controls(): any {
    return this.roleFormGroup.controls;
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  public save(): void {

    this.formSubmitted = true;
    const id = this.controls.id.value;

    this.roleFormGroup.patchValue({
      guard_name: 'web',
      permissions: this.getSelectedPermissions()
    });

    if (id) {
      // Update the existing row
      this.roleService.patchById(id, this.roleFormGroup.value).subscribe(result => {
        this.SavedMessage(result);
      });
    } else {
      // Create the new row
      this.roleService.create(this.roleFormGroup.value).subscribe(result => this.SavedMessage(result));
    }

    setTimeout (() => {
      this.formSubmitted = false;
    }, 1000);
  }

  public selectedPermissionIds(row): any {
    row.permissionList.forEach(el => {
      this.permissionsIds.push(el);
    });
    return this.permissionsIds;
  }

  // All the private methods
  private initialFormGroupSetup(): void {
    this.roleFormGroup = this.formBuilder.group({
      id: [null],
      name: [null, [Validators.required, Validators.maxLength(this.globalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      guard_name: [null, [Validators.maxLength(this.globalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      permissions: [],
      users: []
    });
  }

  private SavedMessage(data) {
    this.state.message({ ['RoleIndexUpdate']: true });
    this.closeSlideout();
  }

  private populateInitialData(row): void {
    if (row !== undefined) {
      this.selectedPermissionIds = this.selectedPermissionIds(row);
    }
  }

  private getSelectedPermissions(): any[] {
    const value: any[] = this.multiSelectedPermission.getSelectedIds();
    return value === null ? null : value;
  }
}
