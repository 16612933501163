import { ChangeDetectorRef, Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatSort, MatSortable, MatTable, MatTableDataSource } from '@angular/material';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { BundleService } from '@app/services/bundle.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { FileInfo } from '@app/models/entities/fileInfo';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BundleItemService } from '@app/services/bundle-item.service';
import { EditBundleItemDialogComponent } from './edit-bundle-item-dialog.component';
import {
  bundleContentTypes,
  BundleItem,
  ContentTypeItem,
  IBundle,
  IBundleContentType,
  IBundleItem,
  IContentTypeItem
} from '@app/models/entities/bundle';
import { map, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Subject, Observable } from "rxjs";

@Component({
  selector: 'app-bundle-builder',
  templateUrl: './bundle-builder.component.html',
  styleUrls: ['./bundle-builder.component.scss']
})
export class BundleBuilderComponent extends BaseComponent implements OnInit, AfterViewInit
{
  @ViewChild(MatTable, {static: false}) table : MatTable<any>;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  private _uuid: string = null;

  public bundle: IBundle;
  public bundleFormGroup: FormGroup;
  public bundleItemsColumns: Array<string> = ['featured_image', 'title', 'bundle_item_type', 'actions'];
  public contentTypes: Array<IBundleContentType> = bundleContentTypes;
  public data: any;
  public dataLoaded: boolean = false;
  public dataSource: MatTableDataSource<BundleItem>;
  public formSubmitted: boolean = false;
  public showAddContent: boolean = true;

  public tableItems: Array<any> = [];
  public tableItemsSubject: Subject<any> = new Subject<any>();
  public filteredContentTypeItems: Array<any> = [];
  public filterContentTypeItemsQuery: string = ''
  public filterContentTypeItemsQueryChanged: Subject<string> = new Subject<string>();

  private get _formMode(): string {
    return this._uuid === null ? 'create' : 'edit';
  }

  public get controls(): any {
    return this.bundleFormGroup.controls;
  }

  public get showBundleEmpty(): boolean {
    return this._uuid === null;
  }

  public get showLoadingBundle(): boolean {
    return this.isEdit && ! this.dataLoaded;
  }

  public get isEdit(): boolean {
    return this._formMode === 'edit';
  }

  public get isCreate(): boolean {
    return this._formMode === 'create';
  }

  public get selectedContentType(): IBundleContentType {
    const selectedContentType = this.contentTypes
      .find(contentType => contentType.selected);

    return !! selectedContentType ? selectedContentType : null;
  };

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _bundleService: BundleService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _formBuilder: FormBuilder,
    private _state: State,

    public bundleItemService: BundleItemService,
    public dialog: MatDialog,
    public router: Router,
  ) {
    super();
    this.setObjects({
      route: _activatedRoute,
      state: _state,
      modelService: _bundleService,
      router,
      dialog
    });

    this._initObservers();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._initialFormGroup();
    if (!! this.routeParams['id']) {
      this._uuid = this.routeParams['id'];
      this._setBundleData();
    } else {
      this.dataLoaded = true;
    }
  }

  private _bundleItems(): Array<any> {
    return this.controls.items.value
      .sort((a, b) => a.display_order < b.display_order);
  };

  private _bundleItemObjects(): Array<BundleItem> {
    return this._bundleItems()
      .map(item => new BundleItem(item, this._state));
  }

  private _bundleItemsHighestDisplayOrder(): number {
    if (this._bundleItems().length === 0) {
      return 0;
    }

    return this._bundleItems()[this._bundleItems().length - 1].display_order;
  }

  private _initObservers(): void {
    this.filterContentTypeItemsQueryChanged
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.filteredContentTypeItems = (this.selectedContentType as any).items
          .filter(item => item.title.toLowerCase()
            .includes(this.filterContentTypeItemsQuery.toLowerCase()));
      });

    this.tableItemsSubject.subscribe(bundleItems => {
      this.tableItems = bundleItems.sort((a, b) => {
        if (a.displayOrder < b.displayOrder) {
          return -1;
        } else if (a.displayOrder > b.displayOrder) {
          return 1;
        } else {
          return 0;
        }
      });
      this._setMatTableDataSource(this.tableItems);
    });
  }

  /**
   * Set bundle data from api
   */
  private _setBundleData(): void {
    if (this.isCreate) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(this._uuid).subscribe(apiResponse => {
        const bundle: IBundle = this.modelService.mapBundleApiResponse(apiResponse);
        const items = bundle.items;
        delete bundle.items;
        this.bundleFormGroup.patchValue(bundle);
        this._setBundleItemsFormGroup(items);
        this._setMatTableDataSource();
        this.dataLoaded = true;
      });
    }
  }

  /**
   * Close bundle builder
   */
  private _closeBundleBuilder(): void {
    this.state.message({ fullWidthOverlayHide: true });
  }

  /**
   * Set MatTable datasource
   */
  private _setMatTableDataSource(bundleItems:any = null): void {
    if (bundleItems) {
      this.dataSource.connect().next(bundleItems);
    } else {
      const bundleItemsForTable: Array<BundleItem> = [];
      this.dataSource = new MatTableDataSource(this._bundleItemObjects());
    }
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Initialise formgroup
   */
  private _initialFormGroup(): void {
    this.bundleFormGroup = this._formBuilder.group({
      uuid: [null],
      description: [null],
      featured_image_cdn_url: [null],
      featured_image_cdn_uuid: [null],
      featured_image_height: [null],
      featured_image_width: [null],
      internal_title: [null, [
        Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)
      ]],
      items: this._formBuilder.array([]),
      published: [true],
      teaser: [null],
      title: [null, [
        Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)
      ]]
    });
  }

  /**
   * Bundle items formgroup array
   *
   * @param  {Array<IBundleItem>} bundleItems
   */
  private _setBundleItemsFormGroup(bundleItems: Array<IBundleItem>): void {
    const items = this._formBuilder.array([]);
    bundleItems.forEach(bundleItem => this._addBundleItemToFormGroup(bundleItem));
    this.tableItemsSubject.next(this._bundleItemObjects());
  }

  /**
   * Add bundle item to FormGroup
   *
   * @param {IBundleItem} bundleItem
   */
  private _addBundleItemToFormGroup(bundleItem: IBundleItem): void {
    (this.bundleFormGroup.controls.items as FormArray).push(this._bundleItemFormGroup(bundleItem));
  }

  /**
   * Bundle item formgroup
   *
   * @param  {IBundleItem} bundleItem [description]
   * @return {FormGroup}             [description]
   */
  private _bundleItemFormGroup(bundleItem: IBundleItem): FormGroup {
    return this._formBuilder.group(bundleItem);
  }

  /**
   * On save actions
   */
  private _savedActions(data:any): void {
    this.formSubmitted = false;
    this._state.message({ ['BundleIndexUpdate']: true });
    this._closeBundleBuilder();
  }

  /**
   * Add content
   *
   * @param {string} contentTypeSlug [description]
   */
  public contentTypeSelector(contentTypeSlug: string): void {
    this.contentTypes.map(contentType => {
      if (contentType.slug === contentTypeSlug) {
        if(contentTypeSlug !== 'url') {
          contentType.items = [];
          contentType.loadingItems = true;
          this.modelService.getContentTypeItems(contentType)
            .subscribe(itemsResult => {
              if (contentType.itemsGrouped) {
                var items: any = [];
                itemsResult.forEach(itemsGroup => itemsGroup.list
                  .sort((a:any, b:any) => {
                    const aDate:any = new Date(a.created_at);
                    const bDate:any = new Date(b.created_at);
                    return bDate - aDate;
                  })
                  .forEach(item => {
                    items.push(new ContentTypeItem(item));
                  }));
                contentType.items = items;
              } else {
                contentType.items = itemsResult
                  .sort((a:any, b:any) => {
                    const aDate:any = new Date(a.created_at);
                    const bDate:any = new Date(b.created_at);
                    return bDate - aDate;
                  })
                  .map(item => new ContentTypeItem(item));
              }
              contentType.loadingItems = false;
            });
        }
        contentType.selected = true;
      } else {
        contentType.selected = false;
      }
      return contentType;
    });
  }

  // Listens for FileInfo from the upload component
  public getUploadedFileInfo($fileInfo: FileInfo) {
    if ($fileInfo) {
      this.bundleFormGroup.patchValue({
        'featured_image_cdn_uuid': $fileInfo.uuid,
        'featured_image_cdn_url': $fileInfo.cdnUrl,
        'featured_image_width': $fileInfo.width,
        'featured_image_height': $fileInfo.height,
      });
    } else {
      this.bundleFormGroup.patchValue({
        'featured_image_cdn_uuid': null,
        'featured_image_cdn_url': null,
        'featured_image_width': null,
        'featured_image_height': null,
      });
    }
  }

  /**
   * Called by bundle item published button
   */
  public updatedStatus(): void {
  }

  /**
   * Set bundle item display order
   *
   * @param CdkDragDrop<string> event
   */
  public orderBundleItems(event: CdkDragDrop<string[]>) {
    const previousIndex = this.dataSource.data.findIndex(row => row === event.item.data);
    moveItemInArray(this.dataSource.data, previousIndex, event.currentIndex);
    moveItemInArray(this.controls.items.value, previousIndex, event.currentIndex);
    this.controls.items.value.map((item:any, index) => item.display_order = index + 1);
    // (this.controls.items as FormArray).controls
    //   .map((item:any, index) => {
    //     item.patchValue({ display_order: index + 1 })
    //     return item;
    //   });
    this.dataSource.data.map((item:any, index) => item.displayOrder = index + 1);
    this.dataSource.data = this.dataSource.data.slice();
    // setTimeout(() => {
    //   this.tableItemsSubject.next(this._bundleItemObjects());
    // }, 0);
    this._changeDetectorRef.detectChanges();
    this._setMatTableDataSource();
    // const items = JSON.parse(JSON.stringify(this.dataSource.data.slice()));
    // this.dataSource.connect().next(this.dataSource.data.slice(items));
    // this._setMatTableDataSource();
    // setTimeout(() =>
    //   // this._setMatTableDataSource()
    // , 1000);
  }

  /**
   * Sort mat table by display order
   *
   */
  private _sortDataSource(): void {
    // this.dataSource.sort.sort(<MatSortable>({ id: id, start: start }));
    // this.dataSource.data.sort((a: BundleItem, b: BundleItem) => {
    //   return a.displayOrder < b.displayOrder;
    //   // if (a.displayOrder < b.displayOrder) {
    //   //   return 1;
    //   // } else if (a.displayOrder < b.displayOrder) {
    //   //   return -1;
    //   // } else {
    //   //   return 0;
    //   // }
    // });
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Add url bundle item type
   *
   */
  public addUrlBundleItem(): void {
    this.contentTypeSelector('url');
    const newUuid = this.addBundleItem();
    this.openEditBundleItemDialog(newUuid);
  }

  /**
   * Add new bundle item
   *
   * @param {any} item [description]
   */
  public addBundleItem(item: IContentTypeItem = null): string {
    const contentTypeSlug = this.selectedContentType.slug;
    const bundleItem: IBundleItem = {
      title: null,
      teaser: null,
      description: null,
      url: null,
      uuid: GlobalUtil.uuidv4(),
      bundle_item_type: contentTypeSlug,
      display_order: this._bundleItemsHighestDisplayOrder() + 1,
      featured_image_cdn_uuid: null,
      featured_image_cdn_url: null,
      featured_image_width: null,
      featured_image_height: null,
      published: true,
      open_in_app_browser: true,
    };

    if (!! item) {
      bundleItem[contentTypeSlug] = {
        featured_image_cdn_uuid: item.featured_image_cdn_uuid,
        featured_image_url: item.featured_image_url,
        internal_title: item.internal_title,
        title: item.title,
        uuid: item.uuid
      };
      bundleItem[contentTypeSlug + '_uuid'] = item.uuid;
    }

    // Add bundle item to FormGroup
    this._addBundleItemToFormGroup(bundleItem);
    this.tableItemsSubject.next(this._bundleItemObjects());
    // this._setMatTableDataSource();
    // Close item selector
    this.closeItemSelector();
    return bundleItem.uuid;
  }

  /**
   * Unset selectedContentType and close item selector
   */
  public closeItemSelector(): void {
    // Unset current content type
    this.filterContentTypeItemsQuery = '';
    this.filteredContentTypeItems = [];
    this.selectedContentType.selected = false;
  }

  /**
   * Open edit bundle item modal
   *
   * @param {string} uuid
   */
  public openEditBundleItemDialog(uuid: string): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      bundleUuid: this._uuid, bundleItemData: this._getBundleItemFormGroupByUuid(uuid)
    };
    const dialogRef = this.dialog.open(EditBundleItemDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (!! result && result.update === true) {
        const index = this._getBundleItemFormGroupIndexByUuid(uuid);
        (this.bundleFormGroup.controls.items as FormArray).controls[index].patchValue(result.data);
        this._setMatTableDataSource();
      }
    });
  }

  /**
   * Get bundle item form data from for array using uuid
   *
   * @param {string} uuid
   */
  private _getBundleItemFormGroupByUuid(uuid: string): any {
    return this.bundleFormGroup.controls.items.value.find(item => item.uuid === uuid);
  }

  /**
   * Get bundle item form data from for array using uuid
   *
   * @param {string} uuid
   */
  private _getBundleItemFormGroupIndexByUuid(uuid: string): any {
    return this.bundleFormGroup.controls.items.value.findIndex(item => item.uuid === uuid);
  }

  /**
   * Remove bundle item
   *
   * @param {string} uuid
   */
  public removeBundleItem(uuid: string): void {
    const index = this._getBundleItemFormGroupIndexByUuid(uuid);
    (this.bundleFormGroup.controls.items as FormArray).removeAt(index);
    this._setMatTableDataSource();
  }

  /**
   * Save bundle
   */
  public save(): void {
    this.formSubmitted = true;

    // Patch / create
    if (this._uuid) {
      this.modelService.patchById(this._uuid, this.bundleFormGroup.value, 'bundle/save-bundle-builder')
        .subscribe(result => this._savedActions(result));
    } else {
      this.modelService.create(this.bundleFormGroup.value, 'bundle/save-bundle-builder')
        .subscribe(result => this._savedActions(result));
    }
  }

  /**
   * Filter content type items
   */
  public filterContentTypeItems(): void {
    this.filterContentTypeItemsQueryChanged.next();
  }
}
