export const PAGE_FILTER_FIELDS = [{
  type: 'text',
  name: 'name',
  label: 'Name',
  searchOperator: 'like',
  value: ''
}, {
  type: 'text',
  name: 'internal_title',
  label: 'Internal Title',
  searchOperator: 'like',
  value: ''
}, {
  type: 'dropdown',
  name: 'published',
  label: 'Published',
  value: '',
  options: [{
    text: 'Yes',
    value: '1'
  }, {
    text: 'No',
    value: '0'
  }]
}, {
  type: 'checkbox',
  name: 'app_v2_id',
  label: 'Apps',
  value: '',
  dataSourceText: '\'name\'',
  apiUrl: 'apps-v2/list',
}];
