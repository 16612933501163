import { Component, Inject, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { tinyMceInit, inlineImageDialog } from './text-editor-tinymce.component';
import { State } from '@app/state';
import { cacheInjectImageDataUrl, getSecureUrl } from './../editor/utils';

@Component({
  selector: 'text-editor-dialog',
  templateUrl: 'text-editor-dialog.component.html',
  styleUrls: ['text-editor-dialog.component.scss']
})
export class TextEditorDialogComponent
{
    private editor;
    private pageForm;
    private body;
    private baseUrl;
    private indexDb;
    private isSecureStorage;
    private textEditorFormGroup: FormGroup;

    public dataLoaded = false;
    public tinyMceInit = null;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<TextEditorDialogComponent>,
        private formBuilder: FormBuilder,
        private state: State,
        private chageDetector: ChangeDetectorRef
    ) {
        this.pageForm = data.parent;
        this.baseUrl = data.baseUrl;
        this.body = data.body;
        const settings = this.state.get('ccSettings');
        this.baseUrl = settings.uploadcare.url;
        this.isSecureStorage = this.pageForm.isSecureStorage;
        if (this.pageForm.isSecureStorage) {
            this.indexDb = this.pageForm.indexDb;
        }
        this.textEditorFormGroup = this.formBuilder.group({ body: [this.body] });
    }

    ngOnInit() {
        const cont = document.createElement('div');
        cont.innerHTML = this.body;
        const images = cont.getElementsByTagName('img');
        this.init();
        if(images.length === 0) {
            this.dataLoaded = true;
        } else {
            var index = 1;
            var promises = [];
            for (var image of images) {
                const url = `${this.pageForm.baseUrl}${image.getAttribute('imageuuid')}/`;
                if (this.pageForm.isSecureStorage) {
                    promises.push(getSecureUrl(url+'url').then((resultUrl:any) => image.src = resultUrl));
                } else {
                    image.src = url;
                    if(index === images.length) {
                        this.body = cont.innerHTML;
                        this.textEditorFormGroup.patchValue({ body: this.body });
                        this.dataLoaded = true;
                    }
                }
                index++;
            }
            if(this.pageForm.isSecureStorage && promises.length > 0) {
                Promise.all(promises).then(() => {
                    this.body = cont.innerHTML;
                    this.textEditorFormGroup.patchValue({ body: this.body });
                    this.dataLoaded = true;
                })
            }
        }
    }

    get controls() {
        return this.textEditorFormGroup.controls;
    }

    init() {
        if (this.pageForm.isSecureStorage) {
            this.tinyMceInit = tinyMceInit.call(this, this.baseUrl, this.isSecureStorage, this.indexDb);
        } else {
            this.tinyMceInit = tinyMceInit.call(this, this.baseUrl, this.isSecureStorage);
        }
        this.chageDetector.detectChanges();
    }

    saveAndCloseDialog() {
        const tinyMceCont = document.getElementById(this.editor.id);

        this.dialogRef.close({ body: tinyMceCont.innerHTML });
    }
}
