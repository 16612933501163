import { Component, Input, OnInit } from '@angular/core';
import { State } from '@app/state';
@Component({
  selector: 'page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  host: { 'class': 'page-host' }
})
export class PageComponent implements OnInit {

  @Input() hidePageLoading = false;
  @Input() noScroll = false;

  public pageLoading = true;

  private _title = '';
  @Input() set title(title: string) { this._title = title; }
  get title(): string { return this._title; }

  private _noFrame = false;
  @Input() set noFrame(noFrame: boolean) { this._noFrame = noFrame; }
  get noFrame(): boolean { return this._noFrame; }

  constructor(public state: State) { }

  ngOnInit() {
    if (!this.hidePageLoading) {
      this.state.subscription().subscribe(state => {
        if (!!state['pageLoaded']) {
          this.pageLoading = false;
        }
      });
    } else {
      this.pageLoading = false;
    }
  }
}
