import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { LinkContactToUserDialogComponent } from './../../contacts/index/link-contact-to-user-dialog.component';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})

export class UserFormComponent extends BaseComponent implements OnInit, AfterContentChecked {

  associatedUsersList:any = [];
  associatedUsersCols:any = [];
  selectedAssociatedUserIds:any = [];
  userFormGroup: FormGroup;
  dataLoaded = false;
  formSubmitted = false;
  tempToken = null;

  private authUser: any;

  @ViewChild('associated_users_user', {static: false}) private associatedUsersInput: { getSelectedIds: () => any[]; };

  constructor(
    private formBuilder: FormBuilder,
    public activatedRoute: ActivatedRoute,
    public authService: AuthService,
    public state: State,
    public router: Router,
    public userService: UserService,
    public dialog: MatDialog,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      state: state,
      modelService: userService
    });

    const id = this.routeParams['uuid'];

    this.tempToken = uuidv4();
    this.initialFormGroupSetup();

    this.associatedUsersCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` },
    ];

    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result: any) => {
        this.populateInitialData(result);
        this.userFormGroup.patchValue(result);
        this.userFormGroup.patchValue({
          'associated_users_user': result.associated_users_user,
          'group_uuid': result.group.uuid,
          'role_id': result.roles[0].id,
          'mobile': result.mobile
        });
        this.dataLoaded = true;
      });
    }
  }

  // All the public methods
  get controls(): any {
    return this.userFormGroup.controls;
  }

  ngOnInit() {
    this.authUser = this.state.get('user');
  }

  ngAfterContentChecked() : void {
    this.changeDetector.detectChanges();
  }

  openAssociatedUserDialog() {
    var data:any = { associated_user_from_secondary_contact: false };
    if(this.tempToken) {
      data.temp_associated_user_token = this.tempToken;
    }
    if(this.routeParams['uuid']) {
      data.baseUserUuid = this.routeParams['uuid'];
    }
    const linkContactToUser = this.dialog.open(LinkContactToUserDialogComponent, {
      panelClass: 'link-contact-to-user',
      data: data,
    });
    linkContactToUser.afterClosed().subscribe(newUser => {
      if(newUser !== undefined && !! newUser.uuid) {
        (this.associatedUsersInput as any).refreshApiData();
        var ids = this.associatedUsersInput.getSelectedIds();
        ids.push(newUser.uuid);
        this.selectedAssociatedUserIds = ids;
      }
    });
  }

  // Populate the form controls when the page loads
  selectedIds(row): any {
    row.associated_users_user.forEach(el => {
      this.selectedAssociatedUserIds.push(el);
    });
  }

  setAsociatedUsersList(users) {
    this.associatedUsersList = users;
  }

  private getSelectedAssociatedUsers(): any[] {
    let value: any[] = null;
    if (!! this.associatedUsersInput) {
      value = this.associatedUsersInput.getSelectedIds();
    }
    return value === null ? [] : value;
  }

  private populateInitialData(row): void {
    if (row !== undefined) {
      this.selectedIds(row);
    }
  }

  public save(gotoAssociatedUsers = false): void {
    const uuid = this.controls.uuid.value;
    this.formSubmitted = true;

    this.userFormGroup.patchValue({
      administrator: !! this.controls.administrator.value,
      receive_email_notification: !! this.controls.receive_email_notification.value,
      receive_sms_notification: !! this.controls.receive_sms_notification.value,
      receive_push_notification: !! this.controls.receive_push_notification.value,
      associated_users_user: this.getSelectedAssociatedUsers(),
    });
    if (uuid) {
      this.userService.patchById(uuid, this.userFormGroup.value)
        .subscribe(result => {
          if (this.authUser.uuid === uuid) {
            this.authService.updateUserSession();
          }
          this.SavedMessage(result);
          if(gotoAssociatedUsers) {
            this.openSlideOutWithLink('users', 'associated-contacts/' + uuid);
          }
        });
    } else {
      this.userService.create(this.userFormGroup.value)
        .subscribe((result:any) => {
          this.SavedMessage(result);
          if(gotoAssociatedUsers) {
            this.openSlideOutWithLink('users', 'associated-contacts/' + result.uuid);
          }
        });
    }
    setTimeout (() => {
      this.formSubmitted = false;
    }, 3000);
  }

  private initialFormGroupSetup(): void {
    var fields:any = {
      uuid: [null],
      group_uuid: [null, [Validators.required]],
      firstname: [
        null, [
          Validators.required,
          Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)
        ]
      ],
      lastname: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      email: [null, [
        Validators.required,
        Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH),
        Validators.pattern(GlobalUtil.Validation.EMAIL_PATTERN)
        ]
      ],
      mobile: [null],
      role_id: [null, [Validators.required]],
      administrator: [null],
      receive_email_notification: [null],
      receive_sms_notification: [null],
      receive_push_notification: [null],
      apps_v2: [null],
      associated_users_user: [[]],
      spid_invite_by_sms: [{ value: false, disabled: true }],
      spid_invite_by_email: [{ value: false, disabled: true }]
    };

    if(this.tempToken) {
      fields.associated_user_recipient_temp_token = [this.tempToken];
    }

    this.userFormGroup = this.formBuilder.group(fields);

    this.controls.email.valueChanges.subscribe(value => {
      if (value === null) {
        this.controls.spid_invite_by_email.setValue(false);
        this.controls.spid_invite_by_email.disable();
      } else {
        this.controls.spid_invite_by_email.enable();
      }
    });

    this.controls.mobile.valueChanges.subscribe(value => {
      if (value === null) {
        this.controls.spid_invite_by_sms.setValue(false);
        this.controls.spid_invite_by_sms.disable();
      } else {
        this.controls.spid_invite_by_sms.enable();
      }
    });
  }

  private SavedMessage(data) {
    this.state.message({ ['UserIndexUpdate']: true });
    this.closeSlideout();
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }
}
