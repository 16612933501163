import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'sidebar-form-textbox',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class SidebarFormInputComponent implements OnInit {
  @Input() field: any = {};
  @Input() form: FormGroup;
  @Input() submitted: boolean;

  constructor() { }

  ngOnInit() { }
}
