import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NoteCategoryService } from '@app/services/note-category.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
@Component({
  selector: 'app-note-category-form',
  templateUrl: './note-category-form.component.html',
  styleUrls: ['./note-category-form.component.scss'],
  providers: [SidebarService]
})
export class NoteCategoryFormComponent extends BaseComponent {
  noteCategoryFormGroup: FormGroup;
  dataLoaded = false;
  globalUtil = GlobalUtil;
  formSubmitted = false;
  constructor(
    public activatedRoute: ActivatedRoute,
    public noteCategoryService: NoteCategoryService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: noteCategoryService
    });

    this.initialFormGroupSetup();

    const id = this.routeParams['id'];

    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result: any) => {
        this.dataLoaded = true;
        this.noteCategoryFormGroup.patchValue(result);
      });
    }
  }

  // All the public methods
  get controls(): any {
    return this.noteCategoryFormGroup.controls;
  }

  public save(): void {
    const uuid = this.controls.uuid.value;
    this.formSubmitted = true;
    if (uuid) {
      this.noteCategoryService.patchById(uuid , this.noteCategoryFormGroup.value)
        .subscribe(result => {
          this.SavedMessage(result);
        });
    } else {
      this.noteCategoryService.create(this.noteCategoryFormGroup.value)
        .subscribe(result => this.SavedMessage(result));
    }
    setTimeout (() => {
      this.formSubmitted = false;
    }, 2000);
  }

  private initialFormGroupSetup(): void {
    this.noteCategoryFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      name: [null, [Validators.required, Validators.maxLength(this.globalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      description: [null, [Validators.maxLength(this.globalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
    });
  }

  private SavedMessage(data: Object) {
    this.state.message({ ['NoteCategoryIndexUpdate']: true });
    this.closeSlideout();
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }
}
