import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SelectionOption } from '@app/models/view-models/option';
import { UserAdminSettingsService } from '@app/services/user-admin-settings.service';

@Component({
  selector: 'app-user-admin-settings',
  templateUrl: './user-admin-settings.component.html',
  styleUrls: [],
})

export class UserFormAdminSettingsComponent extends BaseComponent implements OnInit {
  @Input() uuid: string;
  formGroupAdminSettings: FormGroup;
  public dataLoaded = false;
  private options = [];

  constructor(
    public activatedRoute: ActivatedRoute,
    public state: State,
    public userAdminSettingsService: UserAdminSettingsService,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      modelService: userAdminSettingsService,
      route: activatedRoute,
      state: state,
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.initialFormGroup();
    this.modelService.getAllWithId(this.uuid).subscribe((result: any) => {
      this.getSelectedValues(result);
      this.formGroupAdminSettings.patchValue({
        'admin_groups': this.options
      });
      this.dataLoaded = true;
    });
  }

  public save(): void {
    this.modelService.patchById(this.uuid, this.formGroupAdminSettings.value, 'user').subscribe();
  }

  get controls(): any {
    return this.formGroupAdminSettings.controls;
  }

  public getSelectedOption(option: SelectionOption): void {
    this.options = this.controls.admin_groups.value;
    const val = option.value;
    if (option.selected === true) {
      this.options.push(val);
    } else {
      this.removedOption(val);
    }
    this.formGroupAdminSettings.patchValue({ 'admin_groups': this.options });
  }

  // Setup the initial form
  private initialFormGroup(): void {
    this.formGroupAdminSettings = this.formBuilder.group({
      admin_groups: [null],
      can_message_players: [null]
    });
  }

  private removedOption(val: string): void {
    const index: number = this.options.indexOf(val);
    if (index !== -1) {
      this.options.splice(index, 1);
    }
  }

  private getSelectedValues(result) {
    result.forEach(el => {
      this.options.push(el.uuid);
    });
  }
}
