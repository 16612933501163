import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
const moment = _moment;
const globalUtil = GlobalUtil.Configuration;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
@Component({
  selector: 'sidebar-form-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})

export class SidebarFormDatepickerComponent implements OnInit {
  @Input() field: any = {};
  @Input() form: FormGroup;
  constructor(private _adapter: DateAdapter<any>) { }
  ngOnInit(): void {

  }

  handleDateChange($event): void {
    const d = $event.target.value;
    if (d && d !== '') {
      this.form.get(this.field.name).setValue(d.format(globalUtil.SHORT_DATE_FORMAT_DISPLAY), {
        onlyself: true
      });
    }
  }
}
