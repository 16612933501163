import { BaseService } from './base.service';
import { Cache } from '@app/cache';
// import { Client } from "twilio-chat";
import { Environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State } from '@app/state';
import { uuidv4 } from '@app/modules/common/shared/util/form-util';

import {
  // CHATS_STATE_KEYS,
  // TW_CLIENT_EVENTS,
  // TW_CLIENT_CONNECTION_STATES,
  // TW_CLIENT_CONNECTION_STATES_TEXT,
} from '../components/chats/const';

@Injectable({
  providedIn: 'root'
})
export class ChatService extends BaseService {

  constructor(
    public http: HttpClient,
    public cache: Cache,
    private state: State
  ) {
    super(http, cache);
    this.setVars({
      endPoint: 'chats',
      modelTitle: 'Chats'
    });
  }

  sendPush(data:any) {
    const url = `${Environment.apiURL}chats/send-push`;
    return this.http.post<object>(url, data);
  }
}
