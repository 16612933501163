import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FileInfo } from '@app/models/entities/fileInfo';
import { DirectoryCategoryService } from '@app/services/directory-category.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
@Component({
  selector: 'app-directory-category-form',
  templateUrl: './directory-category-form.component.html',
  styleUrls: ['./directory-category-form.component.scss'],
  providers: [SidebarService]
})
export class DirectoryCategoryFormComponent extends BaseComponent {
  directoryCategoryFormGroup: FormGroup;
  dataLoaded = false;
  globalUtil = GlobalUtil;
  formSubmitted = false;
  constructor(
    public activatedRoute: ActivatedRoute,
    public directoryCategoryService: DirectoryCategoryService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: directoryCategoryService
    });

    this.setPageVars({
      sidebar: ''
    });

    const id = this.routeParams['id'];

    this.initialFormGroupSetup();

    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result: any) => {
        this.dataLoaded = true;
        this.directoryCategoryFormGroup.patchValue(result);
      });
    }
  }

  // All the public methods
  get controls(): any {
    return this.directoryCategoryFormGroup.controls;
  }

  public save(): void {
    const uuid = this.controls.uuid.value;
    this.formSubmitted = true;
    this.directoryCategoryFormGroup.patchValue({
      published: !!this.controls.published.value,
    });
    if (uuid) {
      this.directoryCategoryService.patchById(uuid , this.directoryCategoryFormGroup.value)
        .subscribe(result => {
          this.SavedMessage(result);
        });
    } else {
      this.directoryCategoryService.create(this.directoryCategoryFormGroup.value)
        .subscribe(result => this.SavedMessage(result));
    }
    setTimeout (() => {
      this.formSubmitted = false;
    }, 2000);
  }

  // All the private methods
  private initialFormGroupSetup(): void {
    this.directoryCategoryFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      name: [null, [Validators.required, Validators.maxLength(this.globalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      teaser: [null, [Validators.maxLength(this.globalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      body: [null],
      featured_image_cdn_uuid: [null],
      featured_image_cdn_url: [null],
      featured_image_width: [null],
      featured_image_height: [null],
      published: [null],
    });
  }

  // Listens for FileInfo from the upload component
  public getUploadedFileInfo($fileInfo: FileInfo) {
    if ($fileInfo) {
      this.directoryCategoryFormGroup.patchValue({
        'featured_image_cdn_uuid': $fileInfo.uuid,
        'featured_image_cdn_url': $fileInfo.cdnUrl,
        'featured_image_width': $fileInfo.width,
        'featured_image_height': $fileInfo.height,
      });
    } else {
      this.directoryCategoryFormGroup.patchValue({
        'featured_image_cdn_uuid': null,
        'featured_image_cdn_url': null,
        'featured_image_width': null,
        'featured_image_height': null,
      });
    }
  }

  private SavedMessage(data) {
    this.state.message({ ['DirectoryCategoryIndexUpdate']: true });
    this.closeSlideout();
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }
}
