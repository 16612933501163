import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Cache } from '@app/cache';

@Injectable({
  providedIn: 'root'
})
export class UserCalendarService extends BaseService {

  private userCalendarCache = [];

  constructor(
    public http: HttpClient,
    public cache: Cache
  ) {
    super(http, cache);
    this.setVars({
      endPoint: 'user/{id}/calendars',
      modelTitle: 'Calendar'
    });
  }
}
