import { Directive, ElementRef, HostListener, Input } from '@angular/core';
/**
 * Attribute directive used to stop user entering anything other than a number in an input field
 */
@Directive({
  selector: '[numberOnly]'
})

export class NumberOnlyDirective {
  @Input() decimal = 2;
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete', 'ArrowLeft', 'ArrowRight'];
  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])

  onKeyDown(event: KeyboardEvent): void {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    if (this.decimal > 0) {
      this.regex = new RegExp(`^-?\\d*\\.?\\d{0,${this.decimal}}$`);
    } else {
      this.regex = new RegExp(`^-?\\d*$`);
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
