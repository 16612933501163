import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@environments/environment';
import { BaseService } from './base.service';
import { Cache } from '@app/cache';

@Injectable({
  providedIn: 'root'
})
export class UserSpIdService extends BaseService {

  private sportsologyIdCache = [];

  constructor(
    public http: HttpClient,
    public cache: Cache
  ) {
    super(http, cache);
    this.setVars({
      endPoint: 'user',
      modelTitle: 'Sportsology Id'
    });
  }

  resendInvite(data) {
    return this.http.post<object>(`${Environment.apiURL}spid/user/${data.uuid}/send-invite`, data);
  }

  block(data) {
    return this.http.get<object>(`${Environment.apiURL}spid/user/${data.uuid}/block`, data);
  }

  unblock(data) {
    return this.http.get<object>(`${Environment.apiURL}spid/user/${data.uuid}/unblock`, data);
  }
}
