import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContactGroupService } from '@app/services/contact-group.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';

@Component({
  selector: 'contact-group-sort',
  templateUrl: './contact-group-sort.component.html',
  styleUrls: [],
  providers: [SidebarService],
})
@Injectable()
export class ContactGroupSortComponent extends BaseComponent implements OnInit {
  public dataLoaded = false;
  public datasource: [];
  constructor(
    public activatedRoute: ActivatedRoute,
    public contactGroupService: ContactGroupService,
    public sidebarService: SidebarService,
    public state: State
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: contactGroupService,
    });
  }

  // // NG - life cycle hocks
  ngOnInit(): void {
    // super.ngOnInit();
    this.modelServiceGetAll().then(() => {
      this.datasource = this.dataSourceRows.sort((grp) => grp.display_order);
      this.dataLoaded = true;
    });
  }

  drop($event: CdkDragDrop<string[]>) {
    const replacedElement = this.datasource[$event.currentIndex];
    const replacingElement = this.datasource[$event.previousIndex];

    // Swap the element
    const temp = this.datasource[$event.currentIndex];
    this.datasource[$event.currentIndex] = this.datasource[$event.previousIndex];
    this.datasource[$event.previousIndex] = temp;

    // TODO : These two requests should be handle in one request i-e updateDisplayOrder() at API side.
    // update the new/replacing element
    const uuid = $event.item.element.nativeElement.id;
    if (uuid) {
      const ind = $event.currentIndex + 1;
      this.contactGroupService
        .patchById(uuid, { display_order: ind })
        .subscribe((result) => this.ReplacedDisplayOrder(replacingElement, replacedElement));
    }
  }

  private ReplacedDisplayOrder(replacingElement, replacedElement) {
    // update the replaced element
    const uuid = replacedElement.uuid;
    if (uuid) {
      const ind = replacingElement.display_order;
      this.contactGroupService.patchById(uuid, { display_order: ind }).subscribe();
    }
  }

  public closeSlideout() {
    this.state.message({ ['ContactGroupIndexUpdate']: true });
    this.state.message({ slideoutHide: true });
  }
}
