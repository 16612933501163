import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';

@Component({
  selector: 'app-structure',
  templateUrl: './app-structure.component.html',
  styleUrls: ['./app-structure.component.scss', './app-structure-700.component.scss']
})
export class AppStructureComponent extends BaseComponent {
  @Input() app: any;
  @Input() showCreatePageButton = false;

  constructor(public state: State, public router: Router) {
    super();
    this.setObjects({ router });
  }
}
