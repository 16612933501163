import { Component, Inject, OnInit, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormUtil } from '@app/modules/common/shared/util/form-util';

@Component({
  selector: 'app-view-file-dialog',
  templateUrl: './view-file-dialog.component.html',
  styleUrls: ['./view-file-dialog.component.scss']
})
export class ViewFileDialogComponent implements OnInit
{
  public dataLoaded:boolean = false;
  public document;
  public FormUtil;
  public url: string = '';
  public documentType: string = '';

  constructor(
    public dialogRef: MatDialogRef<ViewFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.document = data.document;
    this.FormUtil = FormUtil;
  }

  ngOnInit() {
    this.getDocumentType();
    let sourceUrl = '';
    if(!! this.document.open_from_chats) {
      if (this.document === undefined) { return; }
      if (this.documentType === FormUtil.Document.DOCUMENT_TYPE_IMAGE) {
        this.url = this.document.url;
      } else if (this.documentType === FormUtil.Document.DOCUMENT_TYPE_VIDEO || this.documentType === FormUtil.Document.DOCUMENT_TYPE_AUDIO){
        this.url = this.document.url;
      } else {
        sourceUrl = this.document.url;
        if(this.documentType === FormUtil.Document.DOCUMENT_TYPE_PDF) {
          this.url = sourceUrl + '#toolbar=0&navpanes=0&scrollbar=0';
        } else {
          this.url = 'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(this.document.url);
        }
      }
    } else {
      if (this.document === undefined) { return; }
      if (this.documentType === FormUtil.Document.DOCUMENT_TYPE_IMAGE) {
        this.url = this.document.cdn.sportsology_url;
      } else if (this.documentType === FormUtil.Document.DOCUMENT_TYPE_VIDEO){
        this.url = this.document.cdn.src;
      } else {
        sourceUrl = this.document.cdn.src;
        if(this.documentType === FormUtil.Document.DOCUMENT_TYPE_PDF) {
          this.url = sourceUrl + '#toolbar=0&navpanes=0&scrollbar=0';
        } else {
          this.url = 'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(sourceUrl);
        }
      }
    }
    this.dataLoaded = true;
  }

  close(): void {
    this.dialogRef.close();
  }

  public getDocumentType(): void {
    if (this.document.file_type === undefined) {
      this.documentType = null;
    } else {
      if (this.document.file_type.indexOf(FormUtil.Document.DOCUMENT_TYPE_IMAGE) !== -1) {
        this.documentType = FormUtil.Document.DOCUMENT_TYPE_IMAGE;
      } else if (this.document.file_type.indexOf(FormUtil.Document.DOCUMENT_TYPE_OFFICE) !== -1) {
        this.documentType = FormUtil.Document.DOCUMENT_TYPE_OFFICE;
      } else if (this.document.file_type.indexOf('office') !== -1) {
        this.documentType = 'office';
      } else if (this.document.file_type.indexOf(FormUtil.Document.DOCUMENT_TYPE_PDF) !== -1) {
        this.documentType = FormUtil.Document.DOCUMENT_TYPE_PDF;
      } else if (this.document.file_type.indexOf(FormUtil.Document.DOCUMENT_TYPE_VIDEO) !== -1) {
        this.documentType = FormUtil.Document.DOCUMENT_TYPE_VIDEO;
      } else if (this.document.file_type.indexOf(FormUtil.Document.DOCUMENT_TYPE_AUDIO) !== -1) {
        this.documentType = FormUtil.Document.DOCUMENT_TYPE_AUDIO;
      }
    }
  }

  public getViewer(): void {
    if (this.document.file_type.indexOf(FormUtil.Document.DOCUMENT_TYPE_OFFICE) !== -1) {
      this.documentType = 'office';
    } else if (this.document.file_type.indexOf(FormUtil.Document.DOCUMENT_TYPE_PDF) !== -1) {
      this.documentType = 'google';
    }
  }
}
