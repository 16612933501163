import { ICON_TEXT_HEADING } from './../../../const/icons';

import { modelSetClass, updatePageFormItemPerms } from './../../utils';

import {
    FIELD_FONT,
    FIELD_FONT_SIZE,
    FIELD_LINE_SPACING,
    FIELD_TEXT_ALIGN,
    FIELD_TITLE
} from './../../../const/traits';

export const Heading = editor => {
  return {
    isComponent: el => el.tagName === 'HEADING',
    // Model
    model: {
      defaults: {
        displayIcon: ICON_TEXT_HEADING,
        tagName: 'heading',
        name: 'Heading',
        droppable: false,
        draggable: 'row',
        components: { type: 'textnode', content: 'Heading' },
        attributes: { name: 'Heading' },
        traits: [
          FIELD_TITLE,
          // FIELD_HEADING_TYPE
        {
          type: 'select',
          name: 'type',
          label: 'Type',
          default: 'main',
          options: [
            { id: 'main', label: 'Main' },
            { id: 'large', label: 'Large' },
            { id: 'medium', label: 'Medium' },
            { id: 'small', label: 'Small' }
          ]
        },
          FIELD_TEXT_ALIGN,
          FIELD_FONT
        ]
      }
    },
    // View
    view: {
      tagName: 'heading',
      // Init
      init() {
        updatePageFormItemPerms.call(this, editor);
        this.listenTo(this.model, 'change:attributes', this.handleAttributeChanges);
      },
      // Attribute changes handler
      handleAttributeChanges() {
        updatePageFormItemPerms.call(this, editor);
        this.render();
      },
      // On render
      onRender() {
        const attrs = this.model.getAttributes();
        modelSetClass.call(this, 'item ' + attrs.type + '-heading');
        this.el.innerHTML = attrs.name;
        this.el.style = 'text-align: ' + attrs.textAlign;
      }
    }
  }
}