import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@environments/environment';
import { BaseService } from './base.service';
import { Cache } from '@app/cache';

@Injectable({
  providedIn: 'root'
})
export class GroupService extends BaseService {

  private groupCache = [];

  constructor(
    public http: HttpClient,
    public cache: Cache
  ) {
    super(http, cache);
    this.setVars({
      endPoint: 'group',
      modelTitle: 'Group'
    });
  }

  userGroups() {
    const url = `${Environment.apiURL}groups/members/list?include_associated_users=true`;
    return this.http.get<object>(url);
  }
}