import { folderCard } from './cards';

import {
  UrlDefaults,
  UrlTraits,
} from './shared';

import { ICON_BUTTON_PRODUCT_URL } from './../../../const/icons';

import { COMP_BUTTON_PRODUCT_URL } from './../../../const/components';

import { PageFormLinkedItem } from './../page-form-linked-item';

export const ProductUrl = (editor, options:any = {}) => {
  const traits = UrlTraits;
  // traits.splice(2, 1);
  return PageFormLinkedItem(editor, {
    componentName: COMP_BUTTON_PRODUCT_URL,
    displayIcon: ICON_BUTTON_PRODUCT_URL,
    model: {
      defaults: { ...UrlDefaults,
        name: 'Product Url',
        traits: [ ...traits]
      }
    },
    cardOnRender() {
      return folderCard.call(this);
    }
  });
}