import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DocumentFolderService } from '@app/services/document-folder.service';
import { FolderService } from '@app/services/folder.service';
import { DocumentService } from '@app/services/document.service';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { FOLDER_FILTER_FIELDS } from './filter-fields';
import { FilterLink } from '@app/models/view-models/filter-link';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
import { SidebarFilterLink } from '@app/services/sidebar-filter-link.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { LocalStorageService } from '@app/services/local-storage.service';
@Component({
  selector: 'app-folders-index',
  templateUrl: './folders-index.component.html',
  styleUrls: ['./folders-index.component.scss', './folders-index-700.component.scss'],
  providers: [SidebarService],
})
export class FoldersIndexComponent extends BaseComponent implements OnInit {
  public links: FilterLink[] = [];
  public dataSource: any;
  public data: any;
  public activeEndpoint = '';
  public displayedColumns = [
    'icon',
    'name',
    'file_size',
    'created_at',
    'created_by_user.name',
    'user_count',
    'document_count',
    'actions',
  ];
  public breadcrumbFilterHeading = '';
  public isShowBreadcrumb = true;
  public node: Breadcrumb;
  public fields: any = FOLDER_FILTER_FIELDS;
  private localStorageKey = GlobalUtil.LocalStorageKey;
  constructor(
    public folderService: FolderService,
    public documentService: DocumentService,
    public documentFolderService: DocumentFolderService,
    public router: Router,
    public sidebarService: SidebarService,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog,
    private sidebarFilterLink: SidebarFilterLink,
    private storage: LocalStorageService,
  ) {
    super();
    this.setObjects({
      modelService: documentFolderService,
      router,
      sidebarService,
      state,
      snackbarService,
      dialog,
    });
    this.setPageVars({
      sidebar: 'folders',
      listenForIndexUpdate: false,
    });
    this.getAll();
  }

  ngOnInit(): void {
    // this.openFormSlideOut('create-document');
    // this.openSlideOutWithLink('documents', 'create/e9644425-f6a6-45f2-b32b-31aeb1a1f67d')
    super.ngOnInit();
    this.buildBreadcrumb();
    this.links = this.sidebarFilterLink.buildDocumentFilterLinks();
    this.activeEndpoint = this.storage.getKey(this.localStorageKey.ACTIVE_FOLDER_ENDPOINT);
    // Override the default `listenForIndexUpdate` in base.component, because it will load the wrong endpoint
    this.state.subscription().subscribe(state => {
      if (!!state['FolderIndexUpdate']) {
        this.getAll(this.activeEndpoint);
      }
    });
  }

  public openFormSlideOut(endPoint: string) {
    const url = `/folders/(slideout:${endPoint})`;
    this.router.navigateByUrl(url, { skipLocationChange: true }).then(() => this.state.message({ slideoutShow: true }));
  }

  // Override the delete method as we need customization
  public modelServiceDelete(uuid: string, model: string) {
    const modelService = model === 'document' ? this.documentService : this.folderService;
    this.confirmDialog().then(confirmed => {
      if (confirmed) {
        modelService.delete(uuid).subscribe(
          result => {
            this.getAll(this.activeEndpoint);
            this.snackbarService.success({ message: 'Deleted successfully' });
          });
      }
    });
  }

  /**
   * When the Filter links in the sidebar are clicked
   *
   * @param link
   */
  public onFilterClicked(link: FilterLink): void {
    this.isShowBreadcrumb = true;
    this.nodes = [
      {
        label: 'Documents',
        link: 'folders',
      },
    ];
    this.nodes.push({
      label: link.heading,
      link: '',
    });
    // Save the clicked filter endpoint, so we can reload the correct page
    // this index needs refreshing after a Folder or Document is uploaded.
    this.storage.setKey(this.localStorageKey.ACTIVE_FOLDER_ENDPOINT, link.endpoint);
    // Load the data
    this.getAll(link.endpoint);
}

  public onBreadcrumbClicked(b: Breadcrumb): void {
    this.isShowBreadcrumb = !this.isShowBreadcrumb;
    this.getAll();
  }

  private getAll(endpoint = null): void {
    this.queryStringFilter = '';
    this.modelServiceGetAll(1, null, endpoint).then(() => {
      this.dataLoaded = true;
    });
  }

  private buildBreadcrumb(): void {
    this.nodes = [
      {
        label: 'Documents',
        link: 'folders',
        title: 'Documents',
      },
    ];
    this.nodes.push({
      label: 'All Documents',
      link: '',
      title: 'All Documents',
    });
  }

  editFolder(row: { uuid: string; }): void {
    document.getElementById('sidebar').classList.add('open');
    this.openSlideOutWithLink('folders', 'edit/' + row.uuid);
  }

  editDocument(row: { uuid: string; }): void {
    document.getElementById('sidebar').classList.add('open');
    this.openSlideOutWithLink('folders', 'edit-document/' + row.uuid);
  }

  moveDocument(row: { uuid: string; }): void {
    document.getElementById('sidebar').classList.add('open');
    this.openSlideOutWithLink('folders', 'move-document/' + row.uuid);
  }

  viewDocument(row: { uuid: string; }): void {
    document.getElementById('sidebar').classList.add('open');
    this.openSlideOutWithLink('folders', 'view-document/' + row.uuid);
  }
}
