import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { NoteCategoryService } from '@services/note-category.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
@Component({
  selector: 'app-note-categories-index',
  templateUrl: './note-categories-index.component.html',
  styleUrls: ['./note-categories-index.component.scss']
})
export class NoteCategoriesIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['name', 'created_at', 'note_count', 'actions'];
  public dataLoaded = false;
  public node: Breadcrumb;
  constructor(
    public noteCategoryService: NoteCategoryService,
    public router: Router,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: noteCategoryService,
      router,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }

  public updatedStatus(): void {
    this.state.message({ ['NoteCategoryIndexUpdate']: true });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.nodes = [];
    this.nodes.push(
    {
      label: 'Settings',
      link: 'settings',
    },
      {
      label: 'Notes',
      link: 'notes',
    },
    {
      label: 'Categories',
      link: 'note-categories',
    }
    );
  }
}
