import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { State } from '@app/state';
import { AuthService } from '@services/auth.service';
import { ClientSettingService } from '@services/client-setting.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { throwError } from 'rxjs';

/**
 * Auth component
 *
 */
@Component({
  selector: 'auth',
  templateUrl: './auth.component.html',
  providers: [AuthService]
})
export class AuthComponent {

  private authState: any;

  /**
   * Constructor
   *
   * @param {ActivatedRoute} private activatedRoute
   * @param {AuthService} private authService
   * @param {State} private state
   * @param {Router} private router
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private clientSettingService: ClientSettingService,
    private router: Router,
    private state: State
  ) {
    this.authState = state;
    this.activatedRoute.data.subscribe(data => {
      this[data.action]();
    });
  }

  /**
   * Login from SPID
   */
  public loginFromId(): void {
    this.activatedRoute.params.subscribe(async (params) => {
      if (params.loginToken !== undefined) {
        await this.authService.getUserTokens(params.loginToken)
          .catch(error => this.logout('Failed login'))
          .then(userTokensResult => {
            const url = '/login/' + userTokensResult.tokens.token +
              '/rt/' + userTokensResult.tokens.refresh_token;
            this.router.navigate([url], { skipLocationChange: true });
          });
      } else {
        this.logout('Failed login');
      }
    });
  }

  /**
   * Login
   */
  public login(): void {
    this.activatedRoute.params.subscribe(async (params) => {
      if (params.sessionToken !== undefined) {
        localStorage.setItem('sessionToken', params.sessionToken);
      }
      if (params.refreshToken !== undefined) {
        localStorage.setItem('refreshToken', params.refreshToken);
      }

      await this.authService.getUserSession()
        .then(userSessionResult => this.processUserSession(params, userSessionResult))
        .catch(error => {
          if (error.status === 403) {
            if(
              !! (error as any).error.refresh_token &&
              (error as any).error.refresh_token === true
            ) {
              this.authService.refreshUserSession()
                .then((refreshTokenResult: any) => {
                  this.authService.updateToken(refreshTokenResult.token);
                  this.authService.getUserSession()
                    .then(userSessionResult => this.processUserSession(params, userSessionResult))
                    .catch(error => this.logout('Failed user session after refresh token'));
                })
                .catch(error => this.logout('Failed refresh token'));
            }
            if (
              !! (error as any).error.invalid_ip_address &&
              (error as any).error.invalid_ip_address === true
            ) {
              this.authService.destroyAndLogout();
              throwError(error);
            }
          } else {
            this.logout('Invalid user session');
          }
        });
    });
  }

  /**
   * In app page login
   */
  public inAppLogin(): void {
    this.authService.clearState();
    this.state.update({ 'inApp': true });
    this.login();
  }

  /**
   * Process user session
   * @param {[type]} params            [description]
   * @param {[type]} userSessionResult [description]
   */
  public processUserSession(params, userSessionResult): void {
    this.clientSettingService.getClientSettings()
      .subscribe(settingsResult => {
        this.setUser(userSessionResult);
        this.setSettings(settingsResult, userSessionResult.product_id);
        this.loginActions(userSessionResult, !! params.redirect ? atob(params.redirect) : 'schedule');
      });
  }

  /**
   * Settings from api
   * @param {[type]} settingsResult [description]
   * @param {[type]} productId      [description]
   */
  private setSettings(settingsResult, productId): void {
    this.state.update({ 'ccSettings': settingsResult, 'productId': productId });
  }

  /**
   * Set user
   * @param {[type]} userSessionResult [description]
   */
  private setUser(userSessionResult): void
  {
    const authUser = userSessionResult.user;
    delete authUser.id;

    // Hack - bounce user to login screen if they're not an Administrator
    if (authUser.administrator === 0) {
      return this.logout('Not an administrator');
    }

    this.removePreviousLocalStorage();
  }

  // Remove previous use of local storage
  private removePreviousLocalStorage(): void {
    localStorage.removeItem('inApp');
    localStorage.removeItem('folders');
    localStorage.removeItem('authUser');
    localStorage.removeItem('productID');
    localStorage.removeItem('defaultTimezone');
  }

  /**
   * Logout
   */
  public logout(errorMsg?: string): void {
    const inApp = this.state.get('inApp');
    if (!! inApp && inApp === true) {
      this.authService.destroyApp();
    } else {
      if (!!this.authState.state.sessionToken) {
        this.authService.deleteUserSession().subscribe(
          res => this.authService.logout(),
          error => this.authService.logout()
        );
      } else {
        this.authService.logout();
      }
    }
  }

  /**
   * Login actions
   *
   * @param {object} userSessionResult
   * @param {string} redirect
   */
  public loginActions(userSessionResult: object, redirect: string): void {
    this.authService.setUserSessionState(userSessionResult);
    this.loginRedirect(redirect);
  }

  /**
   * Clear app first loading
   */
  public clearLoading(): void {
    const appFirstLoad = document.getElementById('app-first-load');

    if (!! appFirstLoad) {
      this.fadeThenRemove(appFirstLoad);
    }
  }

  /**
   * Fade then remove element
   */
  public fadeThenRemove(elem): void {
    var op = 1;  // initial opacity
    var timer = setInterval(function () {
      if (op <= 0.1){
        clearInterval(timer);
        elem.parentNode.removeChild(elem);
      }
      elem.style.opacity = op;
      elem.style.filter = 'alpha(opacity=' + op * 100 + ')';
      op -= op * 0.1;
    }, 40);
  }

  /**
   * Redirect after login
   *
   * @param {[type]} redirect
   */
  public loginRedirect(redirect): void {
    const parts = redirect.split("?");
    var isReload = false;

    redirect = parts[0];
    if (!! parts[1]) {
      const query = parts[1].split('&');
      if (query.length > 0) {
        query.forEach(queryVariable => {
          var queryVariableParts = queryVariable.split('=');
          if (queryVariableParts[0] === 'reload') {
            isReload = true;
          }
        });
      }
    }

    if (isReload) {
      window.location.href = redirect;
    } else {
      this.router.navigate([redirect]).then(() => {
        if (! this.keepLoading(redirect)) {
          this.clearLoading();
        }
      });
    }
  }

  private keepLoading(redirect)
  {
    return redirect.startsWith('/vp-form/');
  }
}
