import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FileInfo } from '@app/models/entities/fileInfo';

import {
    PAGE_STYLE_DARK,
    PAGE_STYLE_DEFAULT,
    PAGE_STYLE_LIGHT,
    PAGE_TYPE_ACCORDION,
    PAGE_TYPE_CONTINUOUS,
    PAGE_TYPE_DROPDOWN,
    PAGE_TYPE_TABBED
} from './../const/form';

@Component({
    selector: 'app-page-form-app-page-form',
    templateUrl: './app-page-form.component.html',
    styleUrls: ['./app-page-form.component.scss']
})
export class PageFormAppPageFormComponent
{
    private app_page_types: any = [
        { value: PAGE_TYPE_CONTINUOUS, text: 'Continuous' },
        { value: PAGE_TYPE_TABBED, text: 'Tabbed' },
        { value: PAGE_TYPE_DROPDOWN, text: 'Dropdown' },
        { value: PAGE_TYPE_ACCORDION, text: 'Accordion' }
    ];

    private app_page_styles: any = [
        { value: PAGE_STYLE_DEFAULT, text: 'Default' },
        { value: PAGE_STYLE_LIGHT, text: 'Light' },
        { value: PAGE_STYLE_DARK, text: 'Dark' }
    ];

    public formReady = false;

    @Input() app: any;
    @Input() controls: any;
    @Input() formSubmitted: any;
    @Input() pageFormGroup: any;

    @Output() addPageSection = new EventEmitter<any>();
    @Output() appPageTypeChange = new EventEmitter<any>();
    @Output() pageFormSave = new EventEmitter<any>();

    // Init
    ngOnInit() {
        this.formReady = true;
    }

    // Save page
    save(close: boolean = false) {
        this.pageFormSave.emit({ close: close });
    }

    // Listens for FileInfo from the upload component
    public getUploadedFileInfo($fileInfo: FileInfo) {
        if ($fileInfo) {
            this.pageFormGroup.patchValue({ featured_image_cdn_uuid: $fileInfo.uuid });
        } else {
            this.pageFormGroup.patchValue({ featured_image_cdn_uuid: null });
        }
    }
}
