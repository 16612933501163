import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-admins',
  templateUrl: './edit-admins.component.html',
  styleUrls: ['./edit-admins.component.scss'],
})
export class EditAdminsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
