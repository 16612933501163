import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { UserService } from '@services/user.service';
@Component({
  selector: 'app-user-associated-contacts',
  templateUrl: './user-associated-contacts.component.html',
  styleUrls: ['./user-associated-contacts.component.scss'],
})
export class UserAssociatedContactsComponent extends BaseComponent implements OnInit {
  associatedContactsFormGroup: FormGroup;
  user;
  dataLoaded = false;
  formSubmitted = false;
  contactTypes = [];

  constructor(
    private formBuilder: FormBuilder,
    public activatedRoute: ActivatedRoute,
    public state: State,
    public userService: UserService
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      state: state,
      modelService: userService,
    });

    const id = this.routeParams['uuid'];
    this.associatedContactsFormGroup = this.formBuilder.group({
      uuid: id,
      associated_users: this.formBuilder.array([]),
    });
    this.modelService.getOne(id).subscribe((result: any) => {
      this.user = result;
      result.associated_users_with_attributes.forEach((associatedUser) => {
        const associatedUsersFormGroup = this.formBuilder.group({
          associated_user_type_uuid: [
            associatedUser.contact_type === null ? null : associatedUser.contact_type.uuid,
            [Validators.required],
          ],
          uuid: associatedUser.uuid,
          link_events: associatedUser.link_events,
          link_messages: associatedUser.link_messages,
          link_folders_documents: associatedUser.link_folders_documents,
          link_chats: associatedUser.link_chats,
        });
        (this.associatedContactsFormGroup.controls.associated_users as FormArray).push(associatedUsersFormGroup);
      });
      this.modelService.getFromApi('contact-types/list?fields=uuid,name').subscribe(data => {
        if(data.length > 0) {
          this.contactTypes = [];
          data.forEach((item: any) => {
            this.contactTypes.push({ value: item.uuid, text: item.name });
          })
        }
        console.log(this.contactTypes);
        this.dataLoaded = true;
      });
    });
  }

  // All the public methods
  get controls(): any {
    return this.associatedContactsFormGroup.controls;
  }

  ngOnInit() {}

  public save(): void {
    const uuid = this.controls.uuid.value;
    this.formSubmitted = true;

    this.userService.patchById(uuid, this.associatedContactsFormGroup.value, `associated-users`).subscribe((result) => {
      this.SavedMessage(result);
    });

    setTimeout(() => {
      this.formSubmitted = false;
    }, 3000);
  }

  private SavedMessage(data) {
    this.state.message({ ['UserIndexUpdate']: true });
    this.closeSlideout();
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }
}
