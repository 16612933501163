import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { ExportService } from '@services/export.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
@Component({
  selector: 'app-exports-index',
  templateUrl: './exports-index.component.html',
  styleUrls: ['./exports-index.component.scss']
})
export class ExportsIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['created_at', 'created_by_user', 'title', 'users', 'format', 'actions'];
  public dataLoaded = false;
  public node: Breadcrumb;
  public blob: Blob;
  constructor(
    public exportService: ExportService,
    public router: Router,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      state,
      router,
      modelService: exportService,
      snackbarService,
      dialog
    });
    this.setPageVars({
      sidebar: '',
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.node = {
      label: 'Exports',
      link: 'exports',
      title: 'Exports'
    };
    this.buildSettingsBreadcrumb(this.node);
  }

  downloadExport(uuid: string) {
    return this.modelService.downloadExport(uuid).subscribe((result: any) => {
      this.blob = new Blob([result], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const downloadURL = window.URL.createObjectURL(result);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = `export-${uuid}.xlsx`;
      link.click();
      this.snackbarService.success({
        message: 'Downloading file'
      });
    },
      (error: any) => {
        console.log('Error downloading file: ' + JSON.stringify(error));
        this.snackbarService.warning({
          message: 'Error downloading file'
        });
      }
    );
  }
}
