import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
import { CustomFieldService } from '@app/services/custom-field.service';
import { CUSTOM_FIELD_FILTER_FIELDS } from './filter-fields';

@Component({
  selector: 'app-custom-field-index',
  templateUrl: './custom-field-group-index.component.html',
  styleUrls: ['./custom-field-group-index.component.scss']
})
export class CustomFieldGroupIndexComponent extends BaseComponent implements OnInit {
  public displayedColumns = ['created_at', 'name', 'models', 'children', 'created_by_user.name', 'active', 'actions'];
  public dataLoaded = false;
  public fields: any = CUSTOM_FIELD_FILTER_FIELDS;
  public node: Breadcrumb;
  constructor(
    public router: Router,
    public state: State,
    public customFieldService: CustomFieldService,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: customFieldService,
      router,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => {
      this.dataLoaded = true;
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.node = {
      label: 'Custom Fields',
      link: 'Custom Fields',
        title: 'Custom Fields'
    };
    this.buildSettingsBreadcrumb(this.node);
  }

  // Called when `active` column is updated
  public updatedStatus(): void {
    this.state.message({ ['CustomFieldIndexUpdate']: true });
  }

}
