import {
  Defaults,
  Traits
} from './shared';

import { ICON_BUTTON_DIRECTORY_CATEGORY } from './../../../const/icons';

import { COMP_BUTTON_DIRECTORY_CATEGORY } from './../../../const/components';

import { FIELD_DIRECTORY_CATEGORY_SELECTOR } from './../../../const/traits';

import { PageFormLinkedItem } from './../page-form-linked-item';

export const DirectoryCategory = (editor, options:any = {}) => {

  (FIELD_DIRECTORY_CATEGORY_SELECTOR as any).at = 9;

  return PageFormLinkedItem(editor, {
    componentName: COMP_BUTTON_DIRECTORY_CATEGORY,
    displayIcon: ICON_BUTTON_DIRECTORY_CATEGORY,
    model: {
      defaults: { ...Defaults,
        name: 'Directory Category',
        traits: [ ...Traits, FIELD_DIRECTORY_CATEGORY_SELECTOR]
      }
    }
  });
}