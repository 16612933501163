import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GroupService } from '@app/services/group.service';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { FormValidators } from '@app/modules/common/shared/validators/form-validators.validator';
import { State } from '@app/state';
import { SidebarService } from '@services/sidebar.service';
import { BaseComponent } from '../../base.component';
import { CoreFormAdvancedSelectComponent } from '@app/modules/common/core/components/form-advanced-select/core-form-advanced-select.component';
@Component({
  selector: 'group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.scss'],
  providers: [SidebarService]
})

export class GroupFormComponent extends BaseComponent {
  // Local variables
  groupFormGroup: FormGroup;
  dataLoaded = false;
  globalUtil = GlobalUtil;
  adminCols = [];
  ids = [];
  @ViewChild(CoreFormAdvancedSelectComponent, {static: false}) private advancedSelectInput;
  // Input parameters

  // Onput events

  // Contractor
  constructor(
    public activatedRoute: ActivatedRoute,
    public groupService: GroupService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: groupService
    });

    this.setPageVars({
      sidebar: ''
    });

    this.initialFormGroupSetup();
    const id = this.routeParams['id'];
    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result: any) => {
        this.dataLoaded = true;
        this.groupFormGroup.patchValue(result);
        this.populateInitialData(result);
      });
    }

    // Setup the columns settings for the multi-selection table (Admins)
    this.adminCols = [
      { columnDef: 'selected', selectedOnclick: true, header: 'Selected', cell: (element: any) => `${element.checked}` },
      { columnDef: 'name', selectedOnclick: true, header: 'Name', cell: (element: any) => `${element.name}` },
    ];
  }

  // All the public methods
  get controls(): any {
    return this.groupFormGroup.controls;
  }

  public save(): void {
    const id = this.controls.id.value;
    const uuid = this.controls.uuid.value;
    this.replaceBooleanFieldsWithByte();

    this.groupFormGroup.patchValue({
      'admin': this.getSelectedAdmins()
    });

    if (id || uuid) {
      // Update the existing row
      this.groupService.patchById(uuid ? uuid : id, this.groupFormGroup.value)
        .subscribe(result => {
          this.SavedMessage(result);
        });
    } else {
      // Create the new row
      this.groupService.create(this.groupFormGroup.value).subscribe(result => this.SavedMessage(result));
    }

    // Rollback the changes so all the checkboxes will bound correctly
    this.replaceByteFieldsWithBoolean();
  }

  selectedIds(row): any {
    row.admin.forEach(el => {
      this.ids.push(el);
    });
    return this.ids;
  }

  // All the private methods
  private initialFormGroupSetup(): void {
    this.groupFormGroup = this.formBuilder.group({
      uuid: [null],
      id: [null],
      name: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      min_users: [null, [FormValidators.numberOnly, Validators.min(0)]],
      max_users: [null, [FormValidators.numberOnly, Validators.min(0)]],
      playing_staff: null,
      is_associated_users_group: null,
      admin: []
    });
  }

  private SavedMessage(data) {
    this.state.message({ ['GroupIndexUpdate']: true });
    this.closeSlideout();
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  private populateInitialData(row): void {
    if (row !== undefined) {
      this.selectedIds(row);
    }
  }

  // All the private methods (Private method should be after all the public methods)
  private replaceBooleanFieldsWithByte(): void {
    // Replace all the boolean fields with byte
    this.groupFormGroup.patchValue({
      'playing_staff': this.controls.playing_staff.value ? '1' : '0',
      'is_associated_users_group': this.controls.is_associated_users_group.value ? '1' : '0',
    });
  }

  private replaceByteFieldsWithBoolean(): void {
    // Replace all the boolean fields with byte
    this.groupFormGroup.patchValue({
      'playing_staff': this.controls.playing_staff.value === '1' ? true : false,
      'is_associated_users_group': this.controls.is_associated_users_group.value === '1' ? true : false
    });
  }

  private getSelectedAdmins(): any[] {
    const value: any[] = this.advancedSelectInput.getSelectedIds();
    return value === null ? null : value;
  }
}
