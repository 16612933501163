import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GlobalUtil } from '@app/modules/common/shared/util/global-util';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { CustomFieldService } from '@app/services/custom-field.service';
import { MODELS, FIELD_TYPES } from '../../custom-fields/custom-field-type';

@Component({
  selector: 'app-custom-field-group-form',
  templateUrl: './custom-field-group-form.component.html',
  styleUrls: ['./custom-field-group-form.component.scss'],
})

export class CustomFieldGroupFormComponent extends BaseComponent {

  customFieldFormGroup: FormGroup;
  dataLoaded = false;
  formSubmitted = false;
  models = MODELS;
  fieldTypes = FIELD_TYPES;

  constructor(
    public activatedRoute: ActivatedRoute,
    public customFieldService: CustomFieldService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      state: state,
      modelService: customFieldService,
    });

    this.initialFormGroup();

    const id = this.routeParams['id'];

    if (id === undefined) {
      this.dataLoaded = true;
    } else {
      this.modelService.getOne(id).subscribe((result: any) => {
        this.dataLoaded = true;
        this.customFieldFormGroup.patchValue(result);
      });
    }
  }

  get controls(): any {
    return this.customFieldFormGroup.controls;
  }

  public save(): void {
    this.formSubmitted = true;
    const uuid = this.controls.uuid.value;
    this.customFieldFormGroup.patchValue({
      active: !!this.controls.active.value,
    });
    if (uuid) {
      this.customFieldService.patchById(uuid, this.customFieldFormGroup.value)
        .subscribe((result) => this.SavedMessage(result));
    } else {
      this.customFieldService.create(this.customFieldFormGroup.value)
        .subscribe((result) => this.SavedMessage(result));
    }
    setTimeout(() => {
      this.formSubmitted = false;
    }, 1000);
  }

  private SavedMessage(data) {
    this.state.message({ ['CustomFieldIndexUpdate']: true });
    this.closeSlideout();
  }

  public closeSlideout() {
    this.state.message({ slideoutHide: true });
  }

  private initialFormGroup(): void {
    this.customFieldFormGroup = this.formBuilder.group({
      uuid: [null],
      name: [null, [Validators.required, Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      type: ['group'],
      models: [null, [Validators.required]],
      instructions: [null, [Validators.maxLength(GlobalUtil.Validation.GENERAL_FIELD_MAX_LENGTH)]],
      active: [null],
    });
  }
}
