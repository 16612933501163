import { paramCase } from 'change-case';

import { iconElement, modelSetClass, updatePageFormItemPerms } from './../../utils';

import {
    COMP_LAYOUT_ACCORDION,
    COMP_LAYOUT_SECTION,
    COMP_MEDIA_VIDEO_PLAYLIST
} from './../../../const/components';

import { ICON_MEDIA_VIDEO_PLAYLIST } from '../../../const/icons';

const compParamName = paramCase(COMP_MEDIA_VIDEO_PLAYLIST) ;

export const VideoPlaylist = (editor, options:any = {}) => {
    return {
        isComponent: el => el.tagName === compParamName.toUpperCase(),
        model: {
            defaults: {
                displayIcon: ICON_MEDIA_VIDEO_PLAYLIST,
                tagName: compParamName,
                name: 'Video',
                draggable: `${paramCase(COMP_LAYOUT_SECTION)},${paramCase(COMP_LAYOUT_ACCORDION)}`,
                droppable: false,
                resizable: false,
                attributes: { provider: 'yt', url: null },
                traits:[
                {
                    type: 'select',
                    label: 'Provider',
                    name: 'provider',
                    options: [{ value: 'yt', name: 'Youtube' }, { value: 'vi', name: 'Vimeo' }]
                },
                {
                    name: 'playlistId',
                    label: 'Playlist ID'
                },
                // // {
                // //     name: 'fullWidth',
                // //     type: 'checkbox',
                // //     label: 'Full Width'
                // // },
                // {
                //     name: 'autoplay',
                //     type: 'checkbox',
                //     label: 'Autoplay'
                // }, {
                //     name: 'controls',
                //     type: 'checkbox',
                //     label: 'Controls'
                // }, {
                //     name: 'loop',
                //     type: 'checkbox',
                //     label: 'Loop'
                // }
                ]
            }
        },
        view: {
            // has to be div as it misbehaves with custom tag names
            tagName: compParamName,
            // Init
            init() {
                this.listenTo(this.model, 'change:attributes:playlistId', this.handleAttributeChanges);
            },
            // Handle attr changes
            handleAttributeChanges() {
                this.render();
            },
            // On render
            onRender() {
                const attrs = this.model.getAttributes();
                const icon = iconElement(this.model.get('displayIcon'));

                this.el.innerHTML = `<div class="widget-inner"><item-title>${icon} Video Playlist</item-title></div>`;
                modelSetClass.call(this, 'video-playlist widget-item');
            }
        }
    }
}