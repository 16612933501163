import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { LocationService } from '@services/location.service';
import { Breadcrumb } from '@app/models/view-models/breadcrumb';
import { LOCATION_FILTER_FIELDS } from './filter-fields';
@Component({
  selector: 'app-locations-index',
  templateUrl: './locations-index.component.html',
  styleUrls: ['./locations-index.component.scss']

})
export class LocationsIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['name', 'address', 'postcode', 'country', 'actions'];
  public dataLoaded = false;
  public node: Breadcrumb;
  public fields: any = LOCATION_FILTER_FIELDS;

  constructor(
    public locationService: LocationService,
    public router: Router,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: locationService,
      router,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      listenForIndexUpdate: true
    });
    this.modelServiceGetAll().then(() => {
      this.dataLoaded = true;
      this.modelTitle = 'location';
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.node = {
      label: 'Locations',
      link: 'locations',
      title: 'Locations'
    };
    this.buildSettingsBreadcrumb(this.node);
  }
}
