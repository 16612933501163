// All the third parties helper related modules should be declared here

import { NgModule } from '@angular/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ColorCircleModule } from 'ngx-color/circle';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { FileSizeModule } from 'ngx-filesize';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { UcWidgetModule } from 'ngx-uploadcare-widget';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';

const HELPER_MODULES = [
  EditorModule,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  ColorCircleModule,
  NgxDocViewerModule,
  FileSizeModule,
  NgxMatSelectSearchModule,
  UcWidgetModule,
  Nl2BrPipeModule,
  NgxMatIntlTelInputModule
];

@NgModule({
  imports: [
    ...HELPER_MODULES
  ],
  exports: [
   ...HELPER_MODULES
  ]
})

export class HelperModule { }
