import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { UserService } from '@services/user.service';

@Component({
  selector: 'user-form-custom-fields',
  templateUrl: './user-form-custom-fields.component.html',
  styleUrls: ['./user-form-custom-fields.component.scss'],
  providers: [SidebarService]
})

export class UserFormCustomFieldsComponent extends BaseComponent implements OnInit {
  @Input() uuid: string;
  public userFormGroup: FormGroup;
  public user: any;
  public customFields: any;
  public userDetails: Array<Object> = [];
  public userResult: any;
  dataLoaded = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    public sidebarService: SidebarService,
    public state: State,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjects({
      route: activatedRoute,
      sidebarService: sidebarService,
      state: state,
      modelService: userService
    });

    this.setPageVars({
      sidebar: 'User Profile',
    });

  }

  ngOnInit(): void {
    super.ngOnInit();
    this.modelServiceGetOne(this.routeParams['uuid'], 'userResult')
      .then(() => {
        this.customFields = this.userResult.custom_fields;
        this.dataLoaded = true;
        this.initialFormGroup();
    });
  }

  public save(): void {
    this.modelService
      .patchById(this.uuid, this.userFormGroup.value, 'user')
      .subscribe((result: any) => this.SavedMessage(result));
  }

  private initialFormGroup(): void {
    // Build the CustomField form controls
    const fields = {};
    this.customFields.forEach(group => {
      group.custom_fields.forEach(field => {
        fields[field.uuid] = new FormControl(field.custom_field_value,
          {
            validators: field.required ? Validators.compose([Validators.required]) : null
          });
      });
    });
    this.userFormGroup = this.formBuilder.group({
      custom_fields: new FormGroup(fields)
    });
  }

  /**
   * CustomField form elements call this function to determine if the field is required
   * @param field
   */
  public getRequiredStatus(field): boolean {
    return field.required === 1 ? true : false;
  }

  private SavedMessage(data: any) {
    this.state.message({ ['UserCustomFieldFormUpdate']: true });
  }

}
