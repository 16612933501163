import { HttpClient } from '@angular/common/http';
import { Observable, of, from } from 'rxjs';
import { pluralize, dasherize, underscore, titleize } from 'inflected';

import { Environment } from '@environments/environment';
import { Cache } from '@app/cache';

export class BaseService {
  private endPoint: string;
  private noPluralForIndex: boolean;
  private modelTitle: string;
  private customPagination: boolean;
  public persistentCacheDuration = 0; // 0 = off, 60 = one minute
  public paginate: any = false;
  public sortDetails: any = false;
  public filterDetails: any;
  public expectReturnResultAfterSave = false;
  constructor(public http: HttpClient, public cache: Cache) {}

  public setVars(vars) {
    if (!!vars.endPoint) {
      this.endPoint = vars.endPoint;
    }

    if (!!vars.noPluralForIndex) {
      this.noPluralForIndex = vars.noPluralForIndex;
    }

    if (!!vars.modelTitle) {
      this.modelTitle = vars.modelTitle;
    }

    if (!!vars.customPagination) {
      this.customPagination = vars.customPagination;
    }
  }

  public setPage(page) {
    if (this.paginate === false) {
      this.paginate = {};
    }
    this.paginate.page = page;
  }

  public setSortOrder({ active: columnName, direction: sortOrder }) {
    if (this.sortDetails === false) {
      this.sortDetails = {};
    }
    this.sortDetails.order_by = columnName;
    this.sortDetails.order = sortOrder;
  }

  public paginateVars() {
    if (this.paginate) {
      return (
        '?' +
        Object.keys(this.paginate)
          .map(key => key + '=' + this.paginate[key])
          .join('&')
      );
    }
    return '';
  }

  public extraQueryVars(extraQueryVars:any = {}) {
    if (!! extraQueryVars && typeof extraQueryVars === 'object' && Object.keys(extraQueryVars).length > 0) {
      return (
        '&' +
        Object.keys(extraQueryVars)
          .map(key => key + '=' + extraQueryVars[key])
          .join('&')
      );
    }
    return '';
  }

  public sortingVars() {
    if (this.sortDetails) {
      return (
        '&' +
        Object.keys(this.sortDetails)
          .map(key => key + '=' + this.sortDetails[key])
          .join('&')
      );
    }
    return '';
  }

  public filterVars() {
    return this.filterDetails ? this.filterDetails : '';
  }

  public setFilterVars(filter: string) {
    this.filterDetails = filter.length > 0 ? filter : null;
  }

  // Debug this ... it's calling this.getRestName() which just returns 'this.endPoint'
  public getApiEndpoint() {
    const restName = !!this.endPoint ? this.endPoint : this.getRestName();
    return `${Environment.apiURL}${restName}`;
  }

  // Returns 'message'
  private getRestName(): string {
    return this.endPoint;
    // return dasherize(underscore(this.getName().replace('Service', '')));
  }

  public index(): string {
    if (this.noPluralForIndex === true) {
      return '/' + this.getRestName();
    }
    return '/' + pluralize(this.getRestName());
  }

  public cacheStrategy() {
    if (this.persistentCacheDuration > 0) {
      return 'persistent';
    }
    return false;
  }

  public getAll(url = null, extraQueryVars:any = {}) {
    const cacheKey = 'httpAll' + this.getRestName();
    if (this.cacheStrategy() === 'persistent') {
      const cacheItem = Cache.get(cacheKey);
      if (cacheItem !== null) {
        const observableCacheItems = of(cacheItem);
        return observableCacheItems;
      }
    }
    if (! url) {
      url = this.noPluralForIndex ? this.getApiEndpoint() : pluralize(this.getApiEndpoint());
    } else {
      url = Environment.apiURL + url;
    }
    url += this.paginateVars() + this.sortingVars() + this.filterVars() + this.extraQueryVars(extraQueryVars);
    const observable = this.http.get<object[]>(url, this.setServiceParams());
    if (this.cacheStrategy() === 'persistent') {
      observable.subscribe(allItems => Cache.set(cacheKey, allItems, this.persistentCacheDuration));
    }
    return observable;
  }

  public getAllWithId(id) {

    const cacheKey = 'httpAll' + this.getRestName();

    if (this.cacheStrategy() === 'persistent') {
      const cacheItem = Cache.get(cacheKey);
      if (cacheItem !== null) {
        const observableCacheItems = of(cacheItem);
        return observableCacheItems;
      }
    }

    const observable = this.http.get<object[]>(pluralize(this.getApiEndpoint().replace('{id}', id)));

    if (this.cacheStrategy() === 'persistent') {
      observable.subscribe(allItems => Cache.set(cacheKey, allItems, this.persistentCacheDuration));
    }

    return observable;
  }

  setServiceParams() {
    return {
      params: { modelTitle: this.modelTitle }
    };
  }

  public getOne(id: string): Observable<Object> {
    const endPoint = this.getApiEndpoint();
    const url = `${endPoint}/${id}`;
    return this.http.get<object>(url);
  }

  public getFromApi(url: string) {
    return this.http.get<object>(`${Environment.apiURL}${url}`);
  }

  public patchById(id: string, data: any = {}, url?: string, pkField?: string): Observable<Object> {

    if (url) {
      url = `${Environment.apiURL}${url}`;
      // If an ID and URL is supplied
      if (id) {
        url = `${url}/${id}`;
      }
    } else {
      const endPoint = this.getApiEndpoint();
      url = `${endPoint}/${id}`;
    }

    // Handle rogue models that use ID not UUID (for example 'Role')
    // For models that use ID, put the ID in a hidden field
    // Eg: <form-hidden [name]="'id'" [value]="role.id"></form-hidden>
    // ToDo ... Find out which models use ID and see if we can clean this method
    if (data.id) {
      data.id = id;
    } else {
      data.uuid = id;
    }

    // A quick hack to allow the PK field to be specified
    if (pkField === 'id') {
      delete data['uuid'];
      data.id = id;
    }

    if (this.expectReturnResultAfterSave) {
      url += '?return=true';
    }
    return this.http.patch<object>(url, data);
  }

  public create(data: Array<any>, url?: string): Observable<Object> {
    let endPoint = '';
    if (url) {
      endPoint = `${Environment.apiURL}${url}`;
    } else {
      endPoint = pluralize(this.getApiEndpoint());
    }
    if (this.expectReturnResultAfterSave) {
      endPoint += '?return=true';
    }
    return this.http.post<object>(endPoint, data);
  }

  public delete(id: string, groupId?: string) {
    const url = groupId ? `${this.getApiEndpoint().replace('{id}', groupId)}/${id}` : `${this.getApiEndpoint()}/${id}`;
    return this.http.delete(url);
  }

  /**
   * Sends a POST request to the API to duplicate a model
   *
   * @param id can be supplied as either a UUID or integer ID
   */
  public duplicate(id: string) {
    const url = `${this.getApiEndpoint()}/${id}/duplicate`;
    return this.http.post<object>(url, id);
  }

  /**
   * Sends a POST request to the API to duplicate many models
   * Posts to the plural endpoint - eg `events`
   *
   * @param data - the payload
   */
  public duplicateMany(data: Array<any>) {
    const url = pluralize(this.getApiEndpoint()) + '/duplicate';
    return this.http.post<object>(url, data);
  }

  /**
   * Sends a PATCH request to the API to sort items
   * @param data an array of IDs / UUIDs
   */
  public sortRecords(data: Array<any>, sortAction = 'sort', customUrl = null) {
    const url = pluralize(this.getApiEndpoint()) + '/' + sortAction;
    if (customUrl) {
      return this.http.patch<object>(Environment.apiURL + customUrl, data);
    }
    return this.http.patch<object>(url, data);
  }

  public getLoggedInUser() {
    return {};
  }

  public objectToUrlVars(data) {
    return Object.keys(data)
      .map(key => {
        if (this.isObject(data[key])) {
          return Object.keys(data[key])
            .map(nestedKey => '[' + key + '][' + nestedKey + ']=' + data[key][nestedKey])
            .join('&');
        } else {
          return key + '=' + data[key];
        }
      })
      .join('&');
  }

  private isObject(data) {
    return data && typeof data === 'object' && data.constructor === Object;
  }

  public setEndPoint(endPoint) {
    this.endPoint = endPoint;
  }

  public saveOrder(data)
  {
    const endPoint = this.getApiEndpoint().replace('{id}', data.id);
    var url = `${endPoint}/sort`;
    if (this.expectReturnResultAfterSave) {
      url += '?return=true';
    }
    return this.http.patch<object>(url, data);
  }
}
