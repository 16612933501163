import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { SidebarService } from '@services/sidebar.service';
import { BUNDLE_ITEMS_FILTER_FIELDS } from './filter-fields';
import { BundleItemService } from '@app/services/bundle-item.service';
@Component({
  selector: 'app-bundle-items-index',
  templateUrl: './bundle-items-index.component.html',
  styleUrls: ['./bundle-items-index.component.scss'],
  providers: [SidebarService]
})
export class BundleItemsIndexComponent extends BaseComponent implements OnInit {
  public dataSource: any;
  public data: any;
  public displayedColumns = ['select', 'featured_image', 'created_at', 'title', 'bundle', 'bundle_item_type', 'published', 'actions'];
  public showSearch = false;
  public dataLoaded = false;
  public fields: any = BUNDLE_ITEMS_FILTER_FIELDS;
  constructor(
    public bundleItemService: BundleItemService,
    public router: Router,
    public sidebarService: SidebarService,
    public state: State,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: bundleItemService,
      router,
      sidebarService,
      state,
      snackbarService,
      dialog
    });
    this.setPageVars({
      sidebar: 'bundleItems',
      listenForIndexUpdate: true,
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.modelServiceGetAll().then(() => {
      this.dataLoaded = true;
     }
    );
    this.nodes = [];
    this.nodes.push({
      label: 'Bundles',
      link: 'bundles',
      title: 'Bundles'
    },
    {
      label: 'Bundle Items',
      link: 'BundleItems',
      title: 'Bundle Items'
    });
   }

  public updatedStatus(): void {
    this.state.message({ ['BundleItemIndexUpdate']: true });
  }
}
