import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/services/snack-bar.service';
import { State } from '@app/state';
import { BaseComponent } from '@components/base.component';
import { DocumentService } from '@services/document.service';
import { SidebarService } from '@services/sidebar.service';
import { Subscription } from 'rxjs';
import { DOCUMENTS_FILTER_FIELDS } from './filter-fields';
@Component({
  selector: 'app-documents-index',
  templateUrl: './documents-index.component.html',
  styleUrls: ['./documents-index.component.scss', './documents-index-700.component.scss'],
  providers: [SidebarService]
})
export class DocumentsIndexComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() hideSidebar = false;

  public dataSource: any;
  public data: any;
  public displayedColumns = ['select', 'file_type', 'name', 'file_size', 'created_at', 'users', 'created_user', 'actions'];
  // public dataLoaded = false;
  public stateDataSubscription: Subscription;
  public fields: any = DOCUMENTS_FILTER_FIELDS;
  constructor(
    public documentService: DocumentService,
    public sidebarService: SidebarService,
    public state: State,
    public router: Router,
    public snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {
    super();
    this.setObjects({
      modelService: documentService,
      sidebarService,
      router,
      state,
      dialog
    });
    this.setPageVars({
      sideBar: 'documents',
      listenForIndexUpdate: true
    });

    this.modelServiceGetAll().then(() => (this.dataLoaded = true));
  }

  public updatedStatus(): void {
    this.state.message({ ['DocumentIndexUpdate']: true });
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
