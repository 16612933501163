import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class SidebarService {

  private subject = new Subject<any>();

  clearLinks() {
    this.subject.next();
  }

  getLinks(): Observable<any> {
    return this.subject.asObservable();
  }
}
