import { Injectable } from '@angular/core';
import { FilterLink } from '@app/models/view-models/filter-link';

@Injectable({
  providedIn: 'root'
})

export class SidebarFilterLink {
  public user: any = false;
  private links: FilterLink[] = [];
  constructor( ) {
    this.user = JSON.parse(localStorage.getItem('authUser'));
  }

  public buildScheduleFilterLinks(): FilterLink[]  {
    this.links = [];
    this.links.push(
      {
        label: 'Event Listing',
        title: 'Event Listing',
        icon: 'list',
        heading: 'Event Listing',
        route: '/events',
      },
      {
        label: 'Periodization Events Listing',
        title: 'Periodization Events Listing',
        icon: 'list',
        heading: 'Periodization Events Listing',
        route: '/periodization-events',
      },
      {
        label: 'Event Categories',
        title: 'Event Categories',
        icon: 'event_note',
        heading: 'Event Listing',
        route: '/event-categories',
      },
      {
        label: 'Calendars',
        title: 'Event Listing',
        icon: 'event',
        heading: 'Event Listing',
        route: '/calendars',
      }
    );
    return this.links;
  }

  public buildDocumentFilterLinks(): FilterLink[]  {
    this.links = [];
    this.links.push({
      label: 'My Documents',
      title: 'My Documents',
      icon: 'folder',
      heading: 'My Documents',
      endpoint: 'created-documents-and-folders',
    }, {
      label: 'Shared with me',
      title: 'Shared with me',
      icon: 'folder_shared',
      heading: 'Shared with me',
      endpoint: 'shared-documents-and-folders'
    });
    return this.links;
  }

  public buildMessageFilterLinks(): FilterLink[]  {
    this.links = [];
    this.links.push(
      {
        label: 'Message Responses',
        title: 'Message Responses',
        icon: 'sms',
        heading: 'Message Responses',
        route: '/message-responses',
      },
      {
        label: 'Delivery Reports',
        title: 'Delivery Reports',
        icon: 'message',
        heading: 'Delivery Reports',
        route: '/notifications',
      }
    );
    return this.links;
  }

  public buildBundlesFilterLinks(): FilterLink[]  {
    this.links = [];
    this.links.push({
      label: 'Bundle Items',
      title: 'Bundle Items',
      icon: 'view_quilt',
      heading: 'Bundle Items',
      route: '/bundle-items'
    });
    return this.links;
  }

  public buildDirectoryItemsFilterLinks(): FilterLink[]  {
    this.links = [];
    this.links.push({
      label: 'Categories',
      title: 'Categories',
      icon: 'list',
      heading: 'Categories',
      route: '/directory-categories',
    });
    return this.links;
  }

  public buildNotesFilterLinks(): FilterLink[]  {
    this.links = [];
    this.links.push({
      label: 'Categories',
      title: 'Categories',
      icon: 'list',
      heading: 'Categories',
      route: '/note-categories',
    });
    return this.links;
  }

  public buildArticlesFilterLinks(): FilterLink[]  {
    this.links = [];
    this.links.push(
      {
        label: 'Categories',
        title: 'Categories',
        icon: 'list',
        heading: 'Categories',
        route: '/article-categories',
      },
      {
        label: 'Tags',
        title: 'Tags',
        icon: 'label',
        heading: 'Tags',
        route: '/tags',
      }
    );
    return this.links;
  }
}

